<template>
  <v-dialog
    :value="campaignDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card style="background-color:#dedede;">
      <v-toolbar dark>
        <v-btn icon dark @click="exit()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >캠페인 {{ isDialogTypeRegister ? '등록' : '수정' }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <campaign-content-main />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CampaignContentMain from './CampaignContentMain.vue';
export default {
  components: {
    CampaignContentMain
  },
  computed: {
    ...mapGetters({
      campaignDialog: 'campaign/dialog',
      isDialogTypeRegister: 'campaign/isDialogTypeRegister'
    })
  },
  data() {
    return {
      editExitSwalTitle: '캠페인 수정 화면 닫기',
      registerExitSwalTitle:
        '지금 캠페인 등록을 닫으면<br> 설정한 내용이 모두 사라져요!'
    };
  },
  mounted() {
    const backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
      if (this.campaignDialog === true) {
        /* Blocking back button in menu route */
        next(false);
        this.exit();
      } else {
        /* allowing all other routes*/
        next(true);
      }
    });
    this.$once('hook:destroyed', () => {
      backButtonRouteGuard();
    });
  },
  methods: {
    ...mapActions({
      initCampaign: 'campaign/initCampaign',
      closeDialog: 'campaign/closeDialog'
    }),
    exit() {
      this.$Swal
        .fire({
          html: `<h3>${
            this.isDialogTypeRegister
              ? this.registerExitSwalTitle
              : this.editExitSwalTitle
          }</h3>`,
          showCancelButton: true,
          confirmButtonText: '닫기',
          cancelButtonText: '취소'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.closeDialog();
          }
        });
    },
    getCampaignsApi() {
      this.$emit('getCampaignsApi');
    }
  }
};
</script>
