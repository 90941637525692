<template>
  <v-dialog :value="dialog" width="800" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>
          노티아이 전용 계좌 안내
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <ul>
          <li class="title">
            <div style="display:inline-block;width:150px">예금주</div>
            {{ bankInfo.account }}
          </li>
          <li class="title">
            <div style="display:inline-block;width:150px">은행</div>
            {{ bankInfo.name }}
          </li>

          <li class="title">
            <div style="display:inline-block;width:150px">계좌번호</div>
            {{ bankInfo.accountNumber }}
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn v-on="{ ...onTooltip }" icon @click="handleCopyClick">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>복사하기</span>
            </v-tooltip>
          </li>
        </ul>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center" @click="handleCloseClick">
        <v-btn dark>
          확인완료
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { copyTextToClipboard } from '@/utils/tools';
export default {
  computed: {
    ...mapGetters({
      dialog: 'dialog/bankInfoDialog',
      bankInfo: 'config/bankInfo'
    })
  },
  methods: {
    ...mapActions({
      closeBankInfoDialog: 'dialog/closeBankInfoDialog'
    }),
    handleCloseClick() {
      this.closeBankInfoDialog();
    },
    async handleCopyClick() {
      const { accountNumber } = this.bankInfo;
      if (accountNumber) {
        const result = await copyTextToClipboard(accountNumber);

        if (result) {
          this.$Swal.fire({
            icon: 'success',
            title: '복사 완료',
            html: `<h3>${accountNumber}</h3>`,
            showConfirmButton: false,
            timer: 1000
          });
        }
      }
    }
  }
};
</script>

<style scoped></style>
