var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"obs4",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"pa-5",staticStyle:{"background-color":"white"}},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('TextFieldTitle',{attrs:{"title":_vm.Titles.rs4.impressionTime}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.campaign.impressionTime),callback:function ($$v) {_vm.$set(_vm.campaign, "impressionTime", $$v)},expression:"campaign.impressionTime"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.UnitOfTimeList,"item-text":"text","item-value":"value","item-disabled":"disable","outlined":"","label":"단위"},model:{value:(_vm.campaign.impressionTimeType),callback:function ($$v) {_vm.$set(_vm.campaign, "impressionTimeType", $$v)},expression:"campaign.impressionTimeType"}})],1)],1),_c('AlertMessage',{staticClass:"mt-3",attrs:{"border":"left","icon":"mdi-alert","color":"red","message":"위치 타겟을 설정한 경우 광고 노출 우선순위 값을 60 으로 설정해주세요.","localStorageKey":"impressionFrequencyAlert","dismissible":""}}),_c('TextFieldTitle',{attrs:{"title":_vm.Titles.rs4.impressionFrequency,"subTitle":_vm.SubTitles.rs4.impressionFrequency}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              numeric: true,
              min_value: Math.min.apply(Math, _vm.impressionFrequency),
              max_value: Math.max.apply(Math, _vm.impressionFrequency)
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"suffix":"순위","required":"","outlined":"","items":_vm.impressionFrequency,"hide-no-data":""},model:{value:(_vm.campaign.impressionFrequency),callback:function ($$v) {_vm.$set(_vm.campaign, "impressionFrequency", $$v)},expression:"campaign.impressionFrequency"}})]}}],null,true)})],1)],1),_c('TextFieldTitle',{attrs:{"title":_vm.Titles.rs4.adsLimitInterval,"subTitle":_vm.SubTitles.rs4.adsLimitInterval}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","suffix":"일","outlined":""},model:{value:(_vm.campaign.adsLimitInterval),callback:function ($$v) {_vm.$set(_vm.campaign, "adsLimitInterval", $$v)},expression:"campaign.adsLimitInterval"}})]}}],null,true)})],1)],1),_c('TextFieldTitle',{attrs:{"title":_vm.Titles.rs4.adsGoalCount,"subTitle":_vm.SubTitles.rs4.adsGoalCount}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.campaign.adsGoalCount),callback:function ($$v) {_vm.$set(_vm.campaign, "adsGoalCount", $$v)},expression:"campaign.adsGoalCount"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('h3',{staticClass:"mr-2 mb-0"},[_vm._v(" 실시간 SDK 전용 "),_c('v-switch',{staticClass:"ma-0",attrs:{"hide-details":"","color":"#F15F5F","inset":""},model:{value:(_vm.campaign.isOnlyRealtime),callback:function ($$v) {_vm.$set(_vm.campaign, "isOnlyRealtime", $$v)},expression:"campaign.isOnlyRealtime"}}),_c('v-chip',{staticClass:"mt-1",attrs:{"color":"#F15F5F","x-small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.campaign.isOnlyRealtime ? '활성' : '비활성'))])],1)])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('campaign-content-footer',{attrs:{"invalid":invalid}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }