<template>
  <v-container fluid>
    <v-row>
      <v-col cols="11" class="mx-auto">
        <v-row>
          <v-col>
            <campaign-content-head />
          </v-col>
        </v-row>

        <v-row class="mt-5 px-3">
          <v-col>
            <form @submit.prevent="submit">
              <transition name="fade">
                <!-- <campaign-admin :user.sync="campaign.user" /> -->
              </transition>
              <v-row v-if="currentStep === 1">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <campaign-register-step-1
                      :rs1.sync="campaign.rs1"
                      :rs2.sync="campaign.rs2"
                      :currentStep.sync="currentStep"
                    />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 2">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <campaign-register-step-2
                      :rs1="campaign.rs1"
                      :rs2.sync="campaign.rs2"
                    />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 3">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <campaign-register-step-3 :rs3.sync="campaign.rs3" />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 4">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <campaign-register-step-4 :rs4.sync="campaign.rs4" />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 5">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <campaign-register-step-5 :rs5.sync="campaign.rs5" />
                  </transition>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CampaignRegisterStep1 from './CampaignRegisterStep1.vue';
import CampaignRegisterStep2 from './CampaignRegisterStep2.vue';
import CampaignRegisterStep3 from './CampaignRegisterStep3.vue';
import CampaignRegisterStep4 from './CampaignRegisterStep4.vue';
import CampaignRegisterStep5 from './CampaignRegisterStep5.vue';
import CampaignContentHead from './CampaignContentHead.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CampaignRegisterStep1,
    CampaignRegisterStep2,
    CampaignRegisterStep3,
    CampaignRegisterStep4,
    CampaignRegisterStep5,
    CampaignContentHead
  },

  computed: {
    ...mapGetters({
      currentStep: 'campaign/currentStep',
      campaign: 'campaign/campaign'
    })
  },

  methods: {
    ...mapActions({
      setCampaign: 'campaign/setCampaign'
    }),
    submit() {
      this.$validator.validateAll();
    }
  }
};
</script>
<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.7s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
