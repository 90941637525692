<template>
  <v-dialog :value="dialog" max-width="350" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>프로모션 참여 상태</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-5">
        <v-row>
          <v-col cols="12" class="pt-3">
            <ValidationObserver :ref="refKey">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  max: 100
                }"
              >
                <v-radio-group
                  v-model="status"
                  class="d-inline-block mt-0"
                  hide-details
                >
                  <v-radio
                    v-for="(m, index) in statusList"
                    :key="index"
                    :value="m.value"
                    class="ma-3 align-center row"
                    :color="m.colorCode"
                    :disabled="m.value === 'W'"
                  >
                    <template v-slot:label>
                      <v-chip outlined label :color="m.color" class="px-5">
                        {{ m.text }}
                      </v-chip>
                      <div
                        v-if="status !== 'W' && m.value === 'W'"
                        class="pl-4 red--text text-caption"
                      >
                        ※ 해당 상태로 변경 불가
                      </div>
                    </template>
                  </v-radio>
                  <small v-if="errors.length" class="col pt-0 error--text">
                    {{ errors[0] }}
                  </small>
                </v-radio-group>
              </ValidationProvider>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          @click="handleChangeStatusClick"
          :disabled="loading || status === record.statusInfo.value"
        >
          변경
        </v-btn>
        <v-btn text @click="closeDialog" :disabled="loading"> 취소 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import {
  setPromotionStatusParticipateApi,
  setPromotionStatusNonParticipateApi,
  setPromotionStatusHoldApi
} from '@/api/admin/InquiryApi';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    },
    statusList: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({})
  },
  data() {
    return {
      refKey: 'inquiryStatusRef',
      loading: false,
      reply: '',
      status: ''
    };
  },
  mounted() {
    if (this.$refs[this.refKey]) {
      this.$refs[this.refKey].reset();
    }

    this.status = this.record?.statusInfo.value;
  },
  methods: {
    ...mapActions({}),
    closeDialog() {
      this.$emit('close');
    },
    async handleChangeStatusClick() {
      const valid = await this.$refs[this.refKey].validate();
      if (!valid) return;
      const id = this.record.id;

      const [error] =
        this.status === 'Y'
          ? // 참여
            await setPromotionStatusParticipateApi(id)
          : this.status === 'N' // 미참여
          ? await setPromotionStatusNonParticipateApi(id)
          : // 대기
            await setPromotionStatusHoldApi(id);

      if (error) {
        console.error(error);
      } else {
        this.$Swal.fire({
          icon: 'success',
          title: '상태가 변경되었습니다.',
          confirmButtonText: '확인'
        });
        this.$emit('index');
        this.closeDialog();
      }
    }
  }
};
</script>

<style></style>
