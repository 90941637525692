<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>코멘트 등록</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver :ref="refKey">
          <v-row class="mt-3">
            <v-col cols="12" class="pt-3">
              <h3>지점 코멘트</h3>

              <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  max: 100
                }"
              >
                <v-textarea
                  v-model="comment"
                  outlined
                  placeholder="코멘트를 입력해주세요.(최대 100글자)"
                  rows="4"
                  row-height="30"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                >
                </v-textarea>
              </ValidationProvider>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-row class="ma-0">
          <v-col cols="12" class="text-center">
            <v-btn
              class="mr-2"
              depressed
              :disabled="loading"
              @click="closeDialog"
            >
              닫기
            </v-btn>
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :disabled="loading"
              @click="handleStoreClick()"
            >
              저장
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { createPointOrderCommentApi } from '@/api/admin/PointAPI';

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      refKey: 'paymentCommentRef',
      comment: '',
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'payment/commentDialog',
      record: 'payment/record'
    })
  },
  watch: {
    dialog() {
      this.loading = false;
      this.init();
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'payment/closeCommentDialog'
    }),
    init() {
      this.comment = '';

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    },
    async handleStoreClick() {
      const { isValid } = await this.$refs[this.refKey].validateWithInfo();

      if (!isValid) return;

      this.loading = true;

      const [error] = await createPointOrderCommentApi(
        { text: this.comment },
        this.record.orderNo
      );

      if (error) {
        console.error(error);
      } else {
        this.$emit('refresh');
        this.close();
      }

      this.loading = false;
    },
    close() {
      this.init();
      this.closeDialog();
    }
  }
};
</script>
