<template>
  <div id="app">
    <v-app style="background-color: rgba(244, 244, 244, 1)">
      <div v-if="isPublicRoute">
        <router-view name="public"></router-view>
      </div>
      <div v-else>
        <MediaApp v-if="isMedia || isMediaGroup" />
        <AdvertiserApp v-else-if="isAdvertiser" />
        <AdminApp v-else-if="isAdmin || isSuperAdmin || isCampaignAdmin" />
        <PartnerApp v-if="isPartner" />
        <DidApp v-if="isDid" />
      </div>
      <v-snackbar
        :value="errors"
        :timeout="1200"
        dark
        centered
        content-class="text-center"
      >
        {{ errors }}
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AdminApp from './views/admin/AdminApp.vue';
import MediaApp from './views/media/MediaApp.vue';
import AdvertiserApp from './views/advertiser/AdvertiserApp.vue';
import PartnerApp from './views/partner/PartnerApp.vue';
import DidApp from './views/did/DidApp.vue';

export default {
  name: 'App',
  components: {
    AdminApp,
    MediaApp,
    AdvertiserApp,
    PartnerApp,
    DidApp
  },
  data() {
    return {
      publicRoutes: ['login', 'PaymentComplete']
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      errors: 'errors'
    }),
    isPublicRoute() {
      return this.publicRoutes.includes(this.$route.name);
    },
    isMediaGroup() {
      return (
        this.user && this.user.level === 1 && this.user.role === 'mediaGroup'
      );
    },
    isMedia() {
      return this.user && this.user.level === 5 && this.user.role === 'media';
    },
    isAdvertiser() {
      return (
        this.user && this.user.level === 2 && this.user.role === 'advertiser'
      );
    },
    isAdmin() {
      return this.user && this.user.level === 3 && this.user.role === 'admin';
    },
    isCampaignAdmin() {
      return this.user && this.user.level === 6 && this.user.role === 'admin';
    },
    isSuperAdmin() {
      return (
        this.user && this.user.level === 4 && this.user.role === 'superAdmin'
      );
    },
    isPartner() {
      return this.user && this.user.level === 8 && this.user.role === 'partner';
    },
    isDid() {
      return (
        this.user &&
        [9, 10].includes(this.user.level) &&
        this.user.role.includes('did')
      );
    }
  },
  methods: {
    ...mapActions({
      setError: 'setError'
    })
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('//cdn.jsdelivr.net/npm/@mdi/font@6.2.95/css/materialdesignicons.min.css');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

html,
body {
  font-family: 'Roboto', sans-serif;
}

#app {
  font-family: 'Roboto', sans-serif;
}
.swal2-input {
  width: 100% !important;
  margin: 1em auto !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 10px 24px !important;
}

.filters .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 0 5px !important;
}
</style>
