var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters mt-8"},[_c('ValidationObserver',{ref:_vm.refKey},[_c('v-row',{staticStyle:{"height":"100%","eoverflow":"auto"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("지점명")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"지점명을 입력해 주세요.","outlined":"","error-messages":errors,"required":"","dense":""},model:{value:(_vm.couponShopInfo.name),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "name", $$v)},expression:"couponShopInfo.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("브랜드 로고")]),(_vm.couponShopInfo.logoImageUrl)?_c('div',{staticClass:"row ma-0 align-end"},[_c('v-img',{staticClass:"col-auto mr-2",attrs:{"src":_vm.couponShopInfo.logoImageUrl ? _vm.couponShopInfo.logoImageUrl : '',"width":"100","height":"100"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleRemoveClick()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_c('ImageFileInput',{attrs:{"fileType":"image","fileLabel":"브랜드 로고","fileSizeMax":3},on:{"setImage":function (file) {
              _vm.couponShopInfo.logoImageFile = file;
            }}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.kakaoAddress)?_c('KakaoAddressForm',{attrs:{"popupKey":"user","business":"","dense":"","popupTitle":"지점 주소 검색","headerLabel":"지점 주소","hidePostCode":"","hideEextraAddress":"","address":_vm.kakaoAddress},on:{"submit":function (value) {
              _vm.couponShopInfo.address = value.address;
              _vm.couponShopInfo.detailAddress = value.detailAddress;
            }}}):_vm._e()],1),_c('h3',{staticClass:"col-12"},[_vm._v("지점 영업 시간")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"bizHourSttMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","label":"영업 시작 시간","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.couponShopInfo.bizHourStt),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "bizHourStt", $$v)},expression:"couponShopInfo.bizHourStt"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.bizHourSttMenu),callback:function ($$v) {_vm.bizHourSttMenu=$$v},expression:"bizHourSttMenu"}},[(_vm.bizHourSttMenu)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedMinutes,"format":"24hr","full-width":"","color":"secondary","scrollable":""},model:{value:(_vm.couponShopInfo.bizHourStt),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "bizHourStt", $$v)},expression:"couponShopInfo.bizHourStt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setBizHour()}}},[_vm._v(" 적용 ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"bizHourEndMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","label":"영업 종료 시간","dense":"","clearable":"","error-messages":errors},model:{value:(_vm.couponShopInfo.bizHourEnd),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "bizHourEnd", $$v)},expression:"couponShopInfo.bizHourEnd"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.bizHourEndMenu),callback:function ($$v) {_vm.bizHourEndMenu=$$v},expression:"bizHourEndMenu"}},[(_vm.bizHourEndMenu)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedMinutes,"format":"24hr","full-width":"","color":"secondary","scrollable":""},model:{value:(_vm.couponShopInfo.bizHourEnd),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "bizHourEnd", $$v)},expression:"couponShopInfo.bizHourEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setBizHour()}}},[_vm._v(" 적용 ")])],1):_vm._e()],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('h3',[_vm._v("연락처")]),_c('ValidationProvider',{attrs:{"vid":"tel","rules":"required|numeric|min:10|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{ref:"shop-tel",staticClass:"input_add notii-text-filed",attrs:{"type":"tel","placeholder":"연락처를 입력해주세요.","outlined":"","error-messages":errors,"required":"","persistent-hint":"","hint":"- 없이 번호만 입력하세요.","dense":"","counter":"","maxlength":"11"},model:{value:(_vm.couponShopInfo.tel),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "tel", $$v)},expression:"couponShopInfo.tel"}})]}}])})],1),_c('v-col',{staticClass:"pt-3",attrs:{"cols":"12"}},[_c('h3',[_vm._v("지점 코멘트 "),_c('span',{staticClass:"text-caption"},[_vm._v("(선택)")])]),_c('v-textarea',{staticClass:"input_add custom-margin notii-text-filed",attrs:{"height":"100px","outlined":"","no-resize":"","hide-details":""},model:{value:(_vm.couponShopInfo.description),callback:function ($$v) {_vm.$set(_vm.couponShopInfo, "description", $$v)},expression:"couponShopInfo.description"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#00d1b2","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.modeText)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }