import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import { DidCampaign } from './DidCampaign';

export class DidCampaignService {
  origin = null;
  id = null; // campaign id
  name = null; // campaign name
  advertiserId = null; // 광고주 id
  _branchIds = [];
  _settopBoxIds = [];
  campaignResourceId = null; // 소재 Id
  campaignResource = null;
  _scheduleDates = [];
  status = null;

  didCampaigns = []; // 캠페인 상세 정보 목록
  settopBoxes = []; // 선택한 지점 갯수
  branches = []; // 선택한 지점 갯수

  endDate = null; // 결제 성공 캠페인 종료일
  startDate = null; // 결제 성공 캠페인 시작일
  isExpired = null; // 캠페인 종료 여부 1,0
  createdAt = null;
  // 선택한 광고주 정보
  advertiser = null;
  // 선택한 매체 정보 list
  didBranchs = null;
  _mediaGroups = [];

  creditPoint = 0; // 유상 보증금
  freetPoint = 0; // 무상 보증금

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidCampaignService(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }
      if (key === 'didCampaigns') {
        this._branchIds = [];
        this._mediaGroupNames = [];

        if (val.length > 0) {
          this._branchIds = Array.from(
            new Set(val.map(item => item.didBranchId))
          );
        }
        val = val.map(item => {
          const campaign = new DidCampaign(item);
          this._mediaGroupNames.push(campaign.mediaGroupName);

          return campaign;
        });
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidCampaignService(this);
    }
  }
  get didMediaGroups() {
    return this._mediaGroupNames
      ? Array.from(new Set(this._mediaGroupNames))
      : [];
  }

  /** @description: 매체 ID 목록 */
  get didBranchIds() {
    return this._branchIds;
  }
  /** @description: 매체 ID 목록 */
  set didBranchIds(val) {
    this._branchIds = val;
    this._settopBoxIds = [];
    this.didCampaigns = [];
  }
  /** @description: 셋톱박스 ID 목록 */
  get didSettopBoxIds() {
    return this._settopBoxIds;
  }
  /** @description: 셋톱박스 ID 목록 */
  set didSettopBoxIds(val) {
    this._settopBoxIds = val;
    this.didCampaigns = [];
  }
  /** @description: 일정 목록 */
  get scheduleDates() {
    return this._scheduleDates;
  }
  /** @description: 회원 아이디: 리소스 목록 조회할떄 필요 */
  get userId() {
    return this.id ? this.advertiser?.userId : this.advertiser?.user_id;
  }
  /** @description: 일정 목록 */
  set scheduleDates(val) {
    this._scheduleDates = val;
    this.didCampaigns = this.didCampaigns.filter(item =>
      val.includes(item.scheduleDate)
    );
  }
  /** @description: 잔여 수량 */
  get ressourcePlayTime() {
    return this.campaignResource
      ? this.campaignResource.file_playtime / 1000
      : 0;
  }
  /** @description: 기존 결제 금액 */
  get costPoint() {
    return this.totalPoint + this.canclePoint;
  }
  /** @description: 승인금액 or 결제예상금액 */
  get totalPoint() {
    if (!this.didCampaigns.length) return 0;
    let res = 0;
    if (this.id) {
      res = parseInt(this.freePoint + this.creditPoint);

      // this.didCampaigns.map(
      //   item => (res = res + item.creditPoint + item.freePoint)
      // );
    } else {
      this.didCampaigns.map(item => (res = res + item.settopBoxCostPerDay));
    }

    return res;
  }
  /** @description: 취소 금액 */
  get canclePoint() {
    if (!this.didCampaigns.length) return 0;
    let res = 0;
    if (this.id) {
      this.didCampaigns
        .filter(item => item.status !== 'S')
        .map(item => (res = res + item.point));
    } else {
      this.didCampaigns.map(item => (res = res + item.settopBoxCostPerDay));
    }
    return res;
  }
  /** @description: 상태 정보 */
  get statusInfo() {
    const text =
      this.status === 'S'
        ? '성공'
        : this.status === 'C'
        ? '취소'
        : this.status === 'PC'
        ? '부분취소'
        : '-';
    const color =
      this.status === 'S'
        ? 'success'
        : this.status === 'C'
        ? 'error'
        : this.status === 'PC'
        ? 'orange'
        : 'secondary';

    return {
      text: text,
      value: this.status,
      color: color
    };
  }
  /** @description: 광고 소재 변경 가능여부 */
  get invalidChangeResource() {
    let res = true;
    // 상태가 성공이여야 하고
    if (this.status === 'C') {
      res = false;
    }
    // 캠페인 상세중에서,
    // 성공인 이면서, 아직 기간이 유효한 캠페인의 갯수가 1개 이상일떄
    const incalidDetails = this.didCampaigns.filter(
      item =>
        item.status === 'S' &&
        item.scheduleDate >= moment().format('YYYY-MM-DD')
    );

    if (incalidDetails.length <= 0) {
      res = false;
    }
    return res;
  }
  /** @description: 광고주 이름 */
  get advertiserName() {
    return this.advertiser ? this.advertiser?.name : '-';
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }
  /** @description: 캼패안 상세 페이로드 */
  get didCampaignsPayload() {
    return this.didCampaigns.length > 0
      ? this.didCampaigns.map(item => new DidCampaign(item).getCreatePayload())
      : [];
  }
  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.getUpdatePayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.id
      ? this.origin.getUpdatePayload(true)
      : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  /** *************************
   * 등록 payload
   ************************* **/
  getCreatePayload() {
    return {
      name: this.name,
      advertiserId: this.advertiserId,
      didCampaigns: this.didCampaignsPayload,
      campaignResourceId: this.campaignResourceId
    };
  }

  /** *************************
   * 리소스 수정 payload
   ************************* **/
  getUpdatePayload() {
    return {
      id: this.id,
      campaignResourceId: this.campaignResourceId
    };
  }

  /** *************************
   * 수정 payload
   * 해당 클래스는 수정이 우선 필요없다는 전제로 작업
   ************************* **/
  // getUpdatePayload(isChecked = false) {
  //   let changedValueKeys = [];

  //   let res = {
  //     id: this.id,
  //   };

  //   if (isChecked) return res;

  //   // 값 체크 용도
  //   changedValueKeys = Object.keys(res);
  //   const changedValues = this.changedValues(changedValueKeys, res);

  //   return {
  //     ...res,
  //     changedValues: changedValues
  //   };
  // }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.origin.getCreatePayload(true);
    // const origin = this.id
    //   ? this.origin.getUpdatePayload(true)
    //   : this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = `${changedObj[key]}` === `${val}`;

      if (typeof changedObj[key] === 'object' || typeof val === 'object') {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
