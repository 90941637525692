import { getCamelKeys } from '@/utils/functions';

export class AdvertiserGroupOption {
  id = null;
  name = null;
  icon = null;

  constructor(init) {
    if (!init) return;
    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      this[key] = val;
    }
  }

  get iconUrl() {
    return this.icon ? this.icon.url || '' : '';
  }
}
