<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center">
          <v-icon>mdi-cog-outline</v-icon>
          <h1 class="ml-2 mb-0">설정</h1>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="pa-5">
          <v-row class="mb-1">
            <v-col cols="12" class="py-0">
              <v-btn
                v-for="(menu, index) in leftMenuList"
                :key="index"
                style="outline:2px solid #000"
                class="ma-1 title"
                v-bind:class="{ 'black white--text': menu.value === tab }"
                large
                @click="handleMenuClick(menu.value)"
                :disabled="routerLoading"
                >{{ menu.text }}</v-btn
              >
              <v-btn
                v-for="(menu, index) in rightMenuList"
                :key="menu.value + index"
                style="outline:2px solid #000"
                class="float-right ma-1 title"
                v-bind:class="{ 'black white--text': menu.value === tab }"
                large
                @click="handleMenuClick(menu.value)"
                :disabled="routerLoading"
                >{{ menu.text }}</v-btn
              >
            </v-col>
          </v-row>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(menu, index) in menuList"
              :key="index"
              :value="menu.value"
            >
              <v-card outlined>
                <component v-bind:is="menu.component"></component>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    MemberMypage: () => import('./mypage/MemberMypage.vue'),
    MemberPayment: () => import('./payment/MemberPayment.vue'),
    MemberInquiry: () => import('./inquiry/MemberInquiry.vue'),
    MemberNotice: () => import('./notice/MemberNotice.vue'),
    MemberFaq: () => import('./faq/MemberFaq.vue')
  },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: 15,
        totalPage: 1
      },
      loading: true,
      routerLoading: false,
      leftMenuList: [
        {
          text: 'MY PAGE',
          value: 'mypage',
          component: 'MemberMypage'
        },
        {
          text: '결제 관리',
          value: 'payment',
          component: 'MemberPayment'
        },
        {
          text: '1:1문의',
          value: 'inquiry',
          component: 'MemberInquiry'
        }
      ],
      rightMenuList: [
        {
          text: 'FAQ',
          value: 'faq',
          component: 'MemberFaq'
        },
        {
          text: '공지사항',
          value: 'notice',
          component: 'MemberNotice'
        }
      ],
      tab: null
    };
  },

  computed: {
    menuList() {
      return [...this.leftMenuList, ...this.rightMenuList];
    }
  },
  watch: {
    $route(to) {
      const { menu } = to.params;
      if (this.tab !== menu) {
        this.tab = menu;
      }
    }
  },
  mounted() {
    this.initMenu();
  },
  methods: {
    initMenu() {
      const menu = this.$route.params.menu;
      if (this.menuList.find(v => v.value === menu)) {
        this.tab = menu;
      } else {
        // 잘못된 값일 경우 첫 번째 값으로 초기화 합니다.
        this.tab = this.menuList[0].value;
      }
    },
    async handleMenuClick(menu) {
      //   this.tab = menu;
      if (this.tab === menu) {
        return false;
      } else {
        this.routerLoading = true;
        const navigationResult = await this.$router.replace({
          name: 'AdvertiserMember',
          params: { menu: menu }
        });
        if (navigationResult) {
          this.tab = menu;
        }
        this.routerLoading = false;
      }
    }
  }
};
</script>

<style></style>
