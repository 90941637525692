<template>
  <v-card outlined>
    <v-card-title>
      예산
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col v-if="isCampaignTypeMessage" cols="12">
          <h3>
            {{ titles.cpm }}
            <information-hover
              :title="titles.cpm"
              :subTitle="
                `CPM(Cost Per Mile) - 1,000 회 도달당 비용. <br>최소 ${cpmMin}원 이상 설정해주세요.`
              "
            />
          </h3>
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, numeric: true, min_value_krw: cpmMin }"
          >
            <v-text-field
              v-model.number="budget.cpm"
              :error-messages="errors"
              clearable
              required
              outlined
              type="number"
              prefix="₩"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col v-else cols="12">
          <h3>
            {{ titles.cpc }}
            <information-hover
              :title="titles.cpc"
              :subTitle="
                `CPC(Cost Per Click) - 링크 클릭당 평균 비용. <br>최소 ${cpcMin}원 이상 설정해주세요.`
              "
            />
          </h3>
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, numeric: true, min_value_krw: cpcMin }"
          >
            <v-text-field
              v-model.number="budget.cpc"
              :error-messages="errors"
              clearable
              required
              outlined
              type="number"
              prefix="₩"
            ></v-text-field>
          </validation-provider>

          <h3>
            {{ titles.dailyAdvertisingBudget }}
            <information-hover
              :title="titles.dailyAdvertisingBudget"
              subTitle="일 예산은 CPC 비용보다 크거나 같게 설정해야 합니다.<br>일일 광고 지출 금액이 일 예산을 초과하면 광고가 중지되며 매일 자정에 초기화 됩니다."
            />
          </h3>
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required: true,
              numeric: true,
              max_krw: 10,
              min_value_krw: budget.cpc
            }"
          >
            <v-text-field
              v-model.number="budget.dailyAdvertisingBudget"
              :error-messages="errors"
              clearable
              required
              outlined
              type="number"
              @input="setBudgetTotal()"
              prefix="₩"
            ></v-text-field>
          </validation-provider>

          <h3 class="pb-2">
            {{ titles.totalAdvertisingBudget }}
            <information-hover
              :title="titles.totalAdvertisingBudget"
              subTitle="총 예산은 일 예산 보다 크거나 같게 설정해야 합니다.<br>전체 광고 지출 금액이 총 예산을 초과하면 광고가 중지 됩니다."
            />
            <v-tooltip right color="rgba(0,0,0,1)" v-if="issetDisplayPeriodEnd">
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  x-small
                  class="pa-2"
                  text
                  @click="setBudgetTotal()"
                  ><v-icon>mdi-calculator </v-icon> 자동계산</v-btn
                >
              </template>
              <span>일정과 일예산에 맞춰 자동계산 됩니다.</span>
            </v-tooltip>
          </h3>
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required: true,
              numeric: true,
              max_krw: 10,
              min_value_krw: budget.dailyAdvertisingBudget
            }"
          >
            <v-text-field
              v-model.number="budget.totalAdvertisingBudget"
              :error-messages="errors"
              clearable
              required
              outlined
              type="number"
              prefix="₩"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <h3>현재 계정 잔여 예산: {{ advertiserBudget | KRW }}</h3>
          <alert-message
            class="mt-3"
            :value="advertiserBudgetAlert"
            border="left"
            icon="mdi-alert"
            color="red"
            message="계정의 충전금이 부족해요. 캠페인이 중간에 끊기지 않도록 미리 충전해주세요!"
            localStorageKey="advertiserBudgetAlert"
          />
        </v-col>
        <v-col cols="12">
          <div>
            <h3>
              {{ titles.forecastReachCount }}
              <information-hover
                :title="titles.forecastReachCount"
                :subTitle="
                  `이 수치는 캠페인 집행 전 참고를 위한 지표로, 실제 광고 집행시의 도달 수와 상이 할 수 있으며 추후 변동 될 수 있습니다.`
                "
              />
              :

              <span v-if="isTagFilter">
                {{ targetTagsCount.toLocaleString() }} 건
              </span>
              <span v-else>
                <div class="d-inline-block" v-if="!forecastReachCountLoading">
                  {{ forecastReachCount.toLocaleString() }}건
                </div>

                <v-progress-circular
                  v-if="forecastReachCountLoading"
                  indeterminate
                  :width="3"
                  :size="20"
                  color="primary"
                ></v-progress-circular>

                <v-tooltip right v-if="!forecastReachCountLoading">
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      v-on="{ ...onTooltip }"
                      icon
                      x-small
                      @click="updateForecastReachCount()"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </template>
                  <span>새로고침</span>
                </v-tooltip>
              </span>
            </h3>
            <h3 v-if="forecastCpmBudget">
              예상 지출 비용: {{ forecastCpmBudget | KRW }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Titles } from '@/config/admin/campaign';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import AlertMessage from '@/components/AlertMessage.vue';
import InformationHover from '@/components/InformationHover.vue';
export default {
  components: {
    ValidationProvider,
    AlertMessage,
    InformationHover
  },
  props: ['schedule', 'budget'],
  data() {
    return {
      cpcMin: 100,
      cpmMin: 1000,
      placeholder: {
        displayPeriod: [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment()
            .add(1, 'month')
            .format('YYYY-MM-DD')
        ]
      },
      forecastReachCount: 0,
      forecastReachCountLoading: false
    };
  },
  computed: {
    ...mapGetters({
      cCampaign: 'campaign/campaign',
      cOriginCampaign: 'campaign/originCampaign'
    }),
    titles() {
      return Titles.rs5.budget;
    },
    advertiserBudget() {
      return this.cCampaign.rs1.user.advertiser.budget;
    },
    mediaGroupIds() {
      return this.cCampaign.rs1.user.mediaGroups.map(v => v.id);
    },
    oldMediaGroupIds() {
      return this.cOriginCampaign.mediaGroups.map(v => v.id);
    },
    advertiserBudgetAlert() {
      let condition = false;
      if (this.isCampaignTypeMessage) {
        condition = this.advertiserBudget < this.forecastCpmBudget;
      } else {
        condition = this.advertiserBudget < this.budget.totalAdvertisingBudget;
      }
      return condition || this.advertiserBudget === 0;
    },
    issetDisplayPeriodEnd() {
      return this.cCampaign.rs5.schedule?.issetDisplayPeriodEnd || false;
    },
    isCampaignTypeMessage() {
      return this.cCampaign.rs1.campaignType === 'message';
    },
    forecastCpmBudget() {
      if (
        this.isCampaignTypeMessage &&
        this.budget.cpm &&
        this.forecastReachCount
      ) {
        return (
          (this.budget.cpm / 1000) *
          (this.isTagFilter ? this.targetTagsCount : this.forecastReachCount)
        );
      } else {
        return null;
      }
    },
    isTagFilter() {
      return this.cCampaign.rs3.isTagFilter === true;
    },
    targetTagsCount() {
      return this.cCampaign.rs3?.targetTagsCount || 0;
    }
  },
  async mounted() {
    if (
      this.budget.forecastReachCount &&
      JSON.stringify(this.mediaGroupIds.sort((a, b) => a - b)) ===
        JSON.stringify(this.oldMediaGroupIds.sort((a, b) => a - b))
    ) {
      this.forecastReachCount = this.budget?.forecastReachCount || 0;
    } else {
      this.updateForecastReachCount();
    }
  },
  methods: {
    async updateForecastReachCount() {
      const forecastReachCount = await this.getForeacastReachCount();
      this.forecastReachCount = this.cCampaign.rs5.budget.forecastReachCount = forecastReachCount;
    },
    setBudgetTotal() {
      // 광고종료일 설정 옵션과 값이 존재하는 경우에만 처리
      if (this.issetDisplayPeriodEnd && this.schedule.displayPeriodEnd) {
        const totalBudget = this.getBudgetTotal();
        this.budget.totalAdvertisingBudget = totalBudget;
      }
    },
    getBudgetTotal() {
      const { displayPeriodStart, displayPeriodEnd } = this.schedule;
      const startDay = this.$moment(displayPeriodStart);
      const endDay = this.$moment(displayPeriodEnd);

      const diffDay = endDay.diff(startDay, 'days') + 1;
      const dailyBudget = this.budget?.dailyAdvertisingBudget || 0;

      return dailyBudget * diffDay;
      // return new Intl.NumberFormat('ko-KR', {
      //   style: 'currency',
      //   currency: 'KRW'
      // }).format(dailyBudget * diffDay);
    },
    async getForeacastReachCount() {
      try {
        this.forecastReachCountLoading = true;
        const params = {
          mediaGroupIds: this.mediaGroupIds
        };
        const { data } = await this.$axios.get(
          `/admin/campaign-forecast-reach`,
          {
            params: params
          }
        );
        return data.forecastReachCount;
      } catch (err) {
        console.error(err);
        return 0;
      } finally {
        this.forecastReachCountLoading = false;
      }
    }
  }
};
</script>

<style></style>
