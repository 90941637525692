import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';

export class DidCampaign {
  origin = null;
  id = null;
  didCampaignServiceId = null; // campaign id
  scheduleDate = null;
  didBranchId = null;
  didBranch = null;
  settopBoxId = null;
  settopBox = null;
  didProductId = null;
  point = 0;
  // creditPoint = 0; // 유상 보증금
  // freetPoint = 0; // 무상 보증금
  createdAt = null;
  status = '';

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidCampaign(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      if (key === 'settopBox') {
        // 상세 조회시 셋톱박스 안에 매체 정보가 있음
        if (val.branch) {
          this.didBranch = val.branch;
        }
      }
      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidCampaign(this);
    }
  }
  /** @description: 매체 이름 */
  get didMediaGroupName() {
    return this.didBranch ? this.didBranch?.didMediaGroup?.name : '-';
  }
  /** @description: 매체 이름 */
  get didBranchName() {
    return this.didBranch ? this.didBranch?.name : '-';
  }
  /** @description: 샛톱박스 이름 */
  get settopBoxName() {
    return this.settopBox ? this.settopBox?.name : '-';
  }
  /** @description: 샛톱박스 종알권 금액 */
  get settopBoxCostPerDay() {
    return this.settopBox ? this.settopBox?.costPerDay * 1 : 0;
  }
  /** @description: 샛톱박스 초당 단가 */
  get settopBoxCostPerSecond() {
    return this.settopBox ? this.settopBox?.costPerSecond * 1 : 0;
  }
  /** @description: 샛톱박스 기준 초 */
  get settopBoxCostStandardSecond() {
    return this.settopBox ? this.settopBox?.costStandardSecond * 1 : 0;
  }
  // /** @description: 보증금 */
  // get point() {
  //   return this.creditPoint * 1 + this.freetPoint * 1;
  // }
  /** @description: 상태 정보 */
  get statusInfo() {
    let text = '';
    let color = '';

    switch (this.status) {
      case 'S':
        text = '성공';
        color = 'success';
        break;
      case 'C':
        text = '취소(당일)';
        color = 'error';
        break;
      case 'R':
        text = '환불';
        color = 'purple';
        break;
      default:
        text = '-';
        color = 'secondary';
    }

    return {
      text: text,
      value: this.status,
      color: color
    };
  }
  /** @description: 취소 상태 여부 */
  get isStatusCancel() {
    return this.status === 'C';
  }

  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }
  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);
    const origin = this.origin.getCreatePayload(true);

    // const changed = this.id
    //   ? this.getUpdatePayload(true)
    //   : this.getCreatePayload(true);
    // let checkTempKeys = Object.keys(changed);
    // const origin = this.id
    //   ? this.origin.getUpdatePayload(true)
    //   : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  /** *************************
   * 등록 payload
   ************************* **/
  getCreatePayload() {
    return {
      scheduleDate: this.scheduleDate,
      didBranchId: this.didBranchId,
      settopBoxId: this.settopBoxId
    };
  }

  /** *************************
   * 수정 payload
   * 해당 클래스는 수정이 우선 필요없다는 전제로 작업
   ************************* **/
  // getUpdatePayload(isChecked = false) {
  //   let changedValueKeys = [];

  //   let res = {
  //     id: this.id,
  //     name: this.name,
  //     package: this.package,
  //     version: this.version,
  //     link: this.link
  //   };

  //   if (isChecked) return res;

  //   // 값 체크 용도
  //   changedValueKeys = Object.keys(res);
  //   const changedValues = this.changedValues(changedValueKeys, res);

  //   return {
  //     ...res,
  //     changedValues: changedValues
  //   };
  // }

  // changedValues(checkKeys = [], changedObj = this) {
  //   let checkTempKeys =
  //     checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
  //   let changes = [];

  //   const origin = this.origin.getCreatePayload(true);
  //   // const origin = this.id
  //   //   ? this.origin.getUpdatePayload(true)
  //   //   : this.origin.getUpdatePayload(true);

  //   for (const key of checkTempKeys) {
  //     if (key === 'origin') {
  //       continue;
  //     }

  //     const val = origin[key];
  //     let isSameData = `${changedObj[key]}` === `${val}`;

  //     if (typeof changedObj[key] === 'object' || typeof val === 'object') {
  //       isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
  //     }

  //     if (!isSameData) {
  //       changes.push({
  //         key: key,
  //         before: val,
  //         after: changedObj[key]
  //       });
  //     }
  //   }
  //   return changes;
  // }
}
