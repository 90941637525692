import moment from 'moment';

export class CouponShop {
  id = null;
  name = null;
  logoImageFile = null;
  address = null;
  bizHourStt = null;
  bizHourEnd = null;

  // options
  detailAddress = null;
  logoImage = null; // {id : '', url: ''}
  description = null;
  tel = null;

  // 현재는 사용 안하는 key
  brand = null;
  userId = null;
  company = null;
  category = null;
  homepageUrl = null;

  _origin = null;

  constructor(init) {
    if (init) {
      const initKeys = Object.keys(init).map(item => {
        let res = item;
        const regex = /^[a-z][A-Za-z]*$/;

        if (!regex.test(item)) {
          // key가 snake_case인경우 camelCase로 변환
          if (/[_-]/.test(item)) {
            res = this.convToCamel(item);
            // 초기화 obj에  변환한 key에 값 주입
            init[`${res}`] = init[item];
          }
        }
        return res;
      });

      for (const key in this) {
        if (initKeys.includes(key)) {
          let val = init[key];

          if (key === 'bizHourStt' || key === 'bizHourEnd') {
            val = val ? moment(val, 'HH:mm:00').format('HH:mm') : null;
          }

          this[key] = val;
        }
      }
    }

    this.setOrigin(init ?? this);
  }

  get logoImageUrl() {
    return this.logoImage ? this.logoImage.url : null;
  }

  get isSameData() {
    const req = {};

    for (const [key] of Object.entries(this._origin)) {
      req[key] = this[key];
    }
    return Object.is(req, this._origin);
  }

  setOrigin(orgData) {
    const temp = JSON.parse(JSON.stringify(orgData));
    this._origin = temp;
  }

  convToCamel(key) {
    var beforeKey = key.toLowerCase();
    var afterKey = '';
    var beforeKeyArr = beforeKey.split('_');

    if (beforeKeyArr.length < 2) {
      return beforeKeyArr;
    }

    for (let i = 0; i < beforeKeyArr.length; i++) {
      if (beforeKeyArr[i].length > 0) {
        if (i == 0) afterKey += beforeKeyArr[i].toLowerCase();
        else
          afterKey +=
            beforeKeyArr[i].toLowerCase().substr(0, 1).toUpperCase() +
            beforeKeyArr[i].substr(1, beforeKeyArr[i].length - 1);
      }
    }
    return afterKey;
  }
}
