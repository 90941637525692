import axios from 'axios';

export async function getUserPointApi(advertiserId) {
  try {
    const { data } = await axios.get(`/admin/adt/${advertiserId}/point`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getCampaignDepositPointsApi(params, userId) {
  try {
    const { data } = await axios.get(
      `/admin/user/${userId}/campaign-deposit-point`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getCampaignCostPointsApi(params, userId) {
  try {
    const { data } = await axios.get(
      `/admin/user/${userId}/campaign-cost-point`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPointApi(userId) {
  try {
    const { data } = await axios.get(`/admin/user/${userId}/point`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPointOrdersApi(params, userId) {
  try {
    const { data } = await axios.get(
      `/admin/user/${userId}/point-orders`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getFreePointOrdersApi(params, userId) {
  try {
    const { data } = await axios.get(
      `/admin/user/${userId}/free-point-orders`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createFreePointOrderApi(params, userId) {
  try {
    const { data } = await axios.post(
      `/admin/user/${userId}/free-point-order`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getTokenAPI(userId) {
  try {
    const { data } = await axios.get(`/admin/user/${userId}/token`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function changePasswordAPI(userId, params) {
  try {
    const { data } = await axios.patch(
      `/admin/user/${userId}/change-password`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

/** @description: 회원  사업자 유형(business_class)이  media인 회원 */
export async function getUserTypeMediaApi() {
  try {
    const { data } = await axios.get(`/admin/users/media`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

/** @description: 캠페인 생성 가능한 회원: 광고주 */
export async function getUserCampaignUserApi() {
  try {
    const { data } = await axios.get(`/admin/campaigns-users`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

/** @description: 회원 활성 상태 변경: 활성 */
export async function approveUserStatusApi(userId) {
  try {
    const { data } = await axios.patch(`/admin/user/${userId}/approve`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

/** @description: 회원 활성 상태 변경: 대기 */
export async function waitUserStatusApi(userId) {
  try {
    const { data } = await axios.patch(`/admin/user/${userId}/approve-wait`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}
