import axios from 'axios';

/**
 * @description: DID 캠페인 목록 전체 조회
 * */
export async function getDidCampaignServicesApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/campaign-services`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 캠페인 상세 조회
 * */
export async function getDidCampaignServiceApi(didCampaignServiceId) {
  try {
    const { data } = await axios.get(
      `/admin/did/campaign-service/${didCampaignServiceId}`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 캠페인 등록
 * */
export async function createDidCampaignServiceApi(params) {
  try {
    const { data } = await axios.post(
      `/admin/did/campaign-service/subscribe/daily`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 캠페인 일정 전체 취소
 * */
export async function cancelWholeDailySchedule(didCampaignServiceId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign/daily-schedule/${didCampaignServiceId}/cancel`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 캠페인 일정 부분 취소
 * */
export async function cancelManyDailySchedule(didCampaignServiceId, params) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign/daily-schedule/${didCampaignServiceId}/partial-cancel`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 캠페인 소재 변경
 * */
export async function updateDidCampaignResourceApi(
  didCampaignServiceId,
  params
) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign-service/${didCampaignServiceId}/resource`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 캠페인 일별 셋톱박스 목록
 * */
export async function getDidCampaignDailyScheduleApi(params) {
  try {
    const { data } = await axios.get(
      `/admin/did/campaign/daily-schedule`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 매체 스케줄 목록 조회
 * */
export async function getDidBranchScheduleApi(branchId, params) {
  try {
    const { data } = await axios.get(
      `/admin/did/campaigns/${branchId}/branch`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 셋톱박스 스케줄 조회
 * */
export async function getDidSettopBoxScheduleApi(settopBoxId, params) {
  try {
    const { data } = await axios.get(
      `/admin/did/campaigns/${settopBoxId}/settop-box`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 캠페인 추가
 * */
export async function addSubscribeDaily(didCampaignServiceId, params) {
  try {
    const { data } = await axios.put(
      `/admin/did/campaign-service/${didCampaignServiceId}/addSubscribe/daily`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
