import firebase from 'firebase/app';
import 'firebase/messaging';

const {
  VUE_APP_FCM_VAPID_KEY,
  VUE_APP_FCM_API_KEY,
  VUE_APP_FCM_AUTH_DOMAIN,
  VUE_APP_FCM_PROJECT_ID,
  VUE_APP_FCM_STORAGE_BUCKET,
  VUE_APP_FCM_MESSAGING_SENDER_ID,
  VUE_APP_FCM_APP_ID,
  VUE_APP_FCM_MEASUREMENT_ID
} = process.env;

const firebaseConfig = {
  apiKey: VUE_APP_FCM_API_KEY,
  authDomain: VUE_APP_FCM_AUTH_DOMAIN,
  projectId: VUE_APP_FCM_PROJECT_ID,
  storageBucket: VUE_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: VUE_APP_FCM_MESSAGING_SENDER_ID,
  appId: VUE_APP_FCM_APP_ID,
  measurementId: VUE_APP_FCM_MEASUREMENT_ID
};

let messaging = null;

// messaging.usePublicVapidKey(
//   VUE_APP_FCM_VAPID_KEY
// );

// 알림 수신을 위한 사용자 권한 요청
Notification.requestPermission().then(permission => {
  console.log('알림 수신을 위한 사용자 권한 요청 ', permission);
  if (permission !== 'granted') {
    // alert('알림을 허용해주세요');
  }
});

const init = () => {
  firebase.initializeApp(firebaseConfig);

  messaging = firebase.messaging();
};

const getFcmToken = async () => {
  if (!messaging) {
    console.log();
  }

  let token = '';
  await messaging
    .getToken({
      vapidKey: VUE_APP_FCM_VAPID_KEY
    })
    .then(currentToken => {
      if (currentToken) {
        // console.log('currentToken:', currentToken);
        token = currentToken;
      } else {
        console.log('No registration token available.');
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
  return token;
};

if (!messaging) {
  init();
}
export default {
  fcmMessage: messaging,
  getFcmToken: () => getFcmToken(),
  init: () => init()
};
