import AdminMedia from '@/views/admin/pages/media/AdminMedia';


// 매체 메뉴
const adminMedia = [{
    path: '/admin/media',
    name: 'AdminMedia',
    components: {
        auth: AdminMedia
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [4]
    }
}];



const superAdminRoutes = [
    ...adminMedia,
];


export default superAdminRoutes;