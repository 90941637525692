<template>
  <v-dialog v-model="dialog" max-width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>{{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 py-3">
        <ValidationObserver :ref="refKey">
          <!-- 소재 등록시 -->
          <v-row dense v-if="!campaignResource.id">
            <CustomGridItem title="회원 타입" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-btn-toggle
                    v-model="userType"
                    color="#00d1b2"
                    mandatory
                    @change="campaignResource.userId = ''"
                  >
                    <v-btn value="media" height="30px"> DID 매체 그룹</v-btn>
                    <v-btn value="advertiser" height="30px"> 광고주 </v-btn>
                  </v-btn-toggle>
                  <div v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <CustomGridItem title="이름" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    placeholder="소재 이름 (선택)"
                    v-model="campaignResource.name"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <CustomGridItem title="회원 계정" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="campaignResource.userId"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="
                      userType === 'advertiser' ? advertisers : mediaUsers
                    "
                    item-text="name"
                    item-value="id"
                    placeholder="회원 계정 선택"
                    required
                    outlined
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="캠페인 타입" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="campaignResource.campaignType"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="CampaignResourceTypeList"
                    placeholder="캠페인 타입 선택"
                    required
                    outlined
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="소재 타입" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="campaignResource.dataType"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="CampaignResourceDataTypeList"
                    placeholder="소재 타입 선택"
                    required
                    outlined
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <CustomGridItem
              title="소재"
              outlined
              :md="12"
              titleClass="col-12  py-3"
            >
              <template v-slot:content>
                <UploadResource
                  v-if="
                    campaignResource.campaignType && campaignResource.dataType
                  "
                  :fileType="campaignResource.dataType"
                  :campaignType="campaignResource.campaignType"
                  :maxCount="1"
                  @addImage="
                    v => {
                      campaignResource.file = v;
                    }
                  "
                />
                <v-row
                  v-else
                  class="subtitle-1 font-weight-bold ma-0 col"
                  justify="center"
                  align="center"
                >
                  캠페인 타입과, 소재 타입을 선택해 주세요.
                </v-row>
              </template>
            </CustomGridItem>
          </v-row>
          <!-- 소재 수정시 -->
          <v-row
            v-if="campaignResource.id"
            class="ma-0"
            justify="center"
            align="center"
          >
            <v-col
              :cols="12"
              class="pt-0"
              v-if="campaignResource.isExpired || campaignResource.isExtendable"
              :style="`color: ${
                campaignResource.isExpired
                  ? 'red'
                  : campaignResource.isExtendable
                  ? '#FF6D00'
                  : ''
              }`"
            >
              <v-icon
                :color="
                  campaignResource.isExpired
                    ? 'red'
                    : campaignResource.isExtendable
                    ? '#FF6D00'
                    : ''
                "
              >
                mdi-information-outline
              </v-icon>
              {{
                campaignResource.isExpired
                  ? '만료된 소재입니다.'
                  : campaignResource.isExtendable
                  ? '소재 만료일이 얼마남지 않았습니다. 만료일을 연장해 주세요.'
                  : ''
              }}
            </v-col>
            <video
              v-if="campaignResource.dataType === 'video'"
              class="col-12"
              preload="metadata"
              :src="`${campaignResource.url}#t=0.5`"
              controls
            ></video>
            <img v-else :src="campaignResource.url" class="col-12" />
            <CustomGridItem title="재생 시간(ms)" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    min: 0
                  }"
                >
                  <v-text-field
                    v-model="playtime"
                    placeholder="파일 사이즈"
                    outlined
                    color="green"
                    type="number"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <v-col cols="12" class="text-center"> </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn secondary outlined @click="handleCloseClick"> 닫기 </v-btn>
        <!-- 소재 등록시 -->
        <v-btn
          v-if="!campaignResource.id"
          color="secondary"
          class="ml-3"
          @click="handleSaveClick"
          :loading="dialogLoading"
          :disabled="
            dialogLoading ||
            (campaignResource.id ? !this.campaignResource.isChanged() : false)
          "
        >
          등록
        </v-btn>
        <!-- 소재 삭제는 사용중인 캠페인이 없는경우 가능 -->
        <template v-else>
          <v-btn color="error" class="ml-3" @click="handleDeleteClick">
            삭제
          </v-btn>
          <!-- 만료전 연장 가능기간 -->
          <v-btn
            v-if="!campaignResource.isExpired && campaignResource.isExtendable"
            color="secondary"
            class="ml-3"
            @click="handleValidClick"
          >
            연장
          </v-btn>
          <v-btn
            v-if="`${campaignResource.filePlaytime}` !== `${playtime}`"
            color="#00d1b2"
            class="white--text"
            depressed
            @click="handleChangePlaytimeClick"
          >
            변경
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import {
  uploadCampaignResource,
  deleteCampaignResource,
  extendCampaignResource,
  changeCampaignResourcePlaytime
} from '@/api/admin/CampaignResourceAPI';
import { getUserCampaignUserApi } from '@/api/admin/UserAPI';
import { getDidMediaGroupOptionsApi } from '@/api/admin/DidMediaGroupAPI';

import {
  CampaignResourceTypeList,
  CampaignResourceDataTypeList
} from '@/config/common';
import UploadResource from '@/components/did/UploadResource.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomGridItem,
    UploadResource
  },
  data() {
    return {
      CampaignResourceDataTypeList: CampaignResourceDataTypeList,
      CampaignResourceTypeList: CampaignResourceTypeList,
      refKey: 'campaignResourceDialog',
      advertisers: [],
      mediaUsers: [],
      userType: 'advertiser',
      playtime: 0 // 소재 재생시간(ms)
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'campaignResource/dialog',
      loading: 'campaignResource/loading',
      campaignResource: 'campaignResource/campaignResource'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return `캠페인 소재 ${!this.campaignResource.id ? '등록' : '상세'}`;
    },
    minEndTimeHour() {
      if (!this.campaignResource.scheduleOpenAt) return 0;
      const minHour = this.campaignResource.scheduleOpenAt?.split(':')[0];
      return Number(minHour) + 1;
    },
    // 업로드 파일 타입 : did이면 비디오 , 아니면 이미지
    fileType() {
      return this.campaignResource.campaignType === 'did' ? 'video' : 'image';
    }
  },
  watch: {
    dialog() {
      const root = this;

      root.loading.dialog = false;

      if (root.$refs[root.refKey]) {
        root.$refs[root.refKey].reset();
      }
    }
  },
  created() {
    const root = this;

    if (root.campaignResource.id) {
      root.playtime = root.campaignResource.filePlaytime;
    }

    root.fetchAdvertisers();
    root.fetchMediaUsersOptionss();
  },
  methods: {
    ...mapActions({
      closeDialog: 'campaignResource/closeDialog'
    }),
    init() {
      const root = this;

      root.advertisers = [];
      root.mediaUsers = [];
    },
    /**@description: 광고주 목록 */
    async fetchAdvertisers() {
      const root = this;

      const [error, data] = await getUserCampaignUserApi();
      if (error) {
        root.$axiosErrorAlert(error?.data?.message);
      } else {
        const { advertisers } = data;
        root.advertisers = advertisers.map(item => ({
          id: item.user_id,
          name: item.name
        }));
      }
    },
    /**@description: 매체시 목록 */
    async fetchMediaUsersOptionss() {
      const root = this;
      const [error, data] = await getDidMediaGroupOptionsApi();

      if (error) {
        root.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_media_groups } = data;
        root.mediaUsers = did_media_groups.map(item => ({
          name: item.name,
          id: item.user_id
        }));
      }
    },
    /**@description: 소재 제거 */
    async handleDeleteClick() {
      const root = this;

      root.$Swal
        .fire({
          icon: 'info',
          title: `${root.titleText} `,
          html: '<h3>해당 소재를 삭제하시겠습니까?</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소'
        })
        .then(async result => {
          if (!result.isConfirmed) return;

          const [error] = await deleteCampaignResource(
            root.campaignResource.id
          );
          if (error) {
            console.error(error);
            root.$axiosErrorAlert(error?.data?.message);
          } else {
            root.$emit('refresh');

            root.$Swal
              .fire({
                icon: 'success',
                html: '<h3>삭제가 완료되었습니다.</h3>',
                showConfirmButton: true,
                confirmButtonText: '확인'
              })
              .then(() => root.closeDialog());
          }
        });
    },
    // 소재 기간 연장
    async handleValidClick() {
      const root = this;
      root.$Swal
        .fire({
          icon: 'info',
          title: `${root.titleText} `,
          html: `<h3>해당 소재를 <span style="color:red">90일</span> 연장합니다.
          <br/> 연장하시겠습니까?</h3>`,
          showConfirmButton: true,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소'
        })
        .then(async result => {
          if (!result.isConfirmed) return;

          const [error] = await extendCampaignResource(
            root.campaignResource.id
          );

          if (error) {
            console.error(error);
            root.$axiosErrorAlert(error?.data?.message);
          } else {
            root.$emit('refresh');
            root.$Swal
              .fire({
                icon: 'success',
                html: '<h3>연장이 완료되었습니다.</h3>',
                showConfirmButton: true,
                confirmButtonText: '확인'
              })
              .then(() => root.closeDialog());
          }
        });
    },
    // 소재 재생시간 변경
    async handleChangePlaytimeClick() {
      const root = this;
      if (root.dialogLoading) return;

      const valid = await root.$refs[root.refKey].validate();
      console.log(valid);
      if (!valid) return;

      root.loading.dialog = true;

      const [error] = await changeCampaignResourcePlaytime(
        root.campaignResource.id,
        {
          filePlaytime: root.playtime
        }
      );

      if (error) {
        console.log(error);
        root.$axiosErrorAlert(error?.data?.message);
      } else {
        root.campaignResource.filePlaytime = root.playtime;
        root.$Swal.fire({
          icon: 'success',
          title: `소재 재생시간 변경 `,
          html: `<h3>소재 재생시간이 변경 되었습니다.</h3>`,
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
      }

      root.loading.dialog = false;
    },
    // 소재 업로드
    async handleSaveClick() {
      const root = this;

      if (root.dialogLoading) return;

      const valid = await root.$refs[root.refKey].validate();

      if (!valid) return;

      if (!root.campaignResource.file) {
        root.$Swal.fire({
          icon: 'error',
          html: '<h3>소재를 업로드해주세요.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      const param = root.campaignResource.getCreatePayload();

      root.loading.dialog = true;

      const formData = new FormData();

      formData.append('file', param.file.file);
      formData.append('dataType', param.dataType);
      formData.append('campaignType', param.campaignType);
      formData.append('name', param.name);

      const [error] = await uploadCampaignResource(param.userId, formData);

      if (error) {
        console.log(error);
        root.$axiosErrorAlert(error?.data?.message);
      } else {
        root.$emit('refresh');

        root.$Swal
          .fire({
            icon: 'success',
            title: `${root.titleText} `,
            html: `<h3>${root.titleText} 되었습니다.</h3>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          })
          .then(() => root.closeDialog());
      }

      root.loading.dialog = false;
    },
    /** @description: 닫기 */
    handleCloseClick() {
      const root = this;

      // 변경사항 있는경우 확인
      if (root.campaignResource.isChanged()) {
        root.$Swal
          .fire({
            icon: 'info',
            title: `${root.titleText} `,
            html: '<h3>변경 사항이 있습니다.<br/> 저장하지 않고 닫으시겠습니까?</h3>',
            showConfirmButton: true,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소'
          })
          .then(result => {
            if (result.isConfirmed) {
              root.closeDialog();
            }
          });
      } else {
        root.closeDialog();
      }
    },
    inputFocus(target) {
      target.select();
    }
  }
};
</script>
