<template>
  <ValidationObserver
    tag="form"
    ref="obs1"
    v-slot="{ invalid }"
    @submit.prevent="submit()"
  >
    <v-row class="pa-5" style="background-color:white;">
      <v-col sm="12" md="6">
        <CampaignUser :user.sync="rs1.user" />
        <h3>{{ titles.rs1.campaignName }}</h3>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{ required: true, max: campaignNameStringMax }"
        >
          <v-text-field
            v-model="rs1.campaignName"
            :counter="campaignNameStringMax"
            :error-messages="errors"
            :placeholder="placeholder.campaignName"
            required
            outlined
          >
          </v-text-field>
        </ValidationProvider>
        <h3>{{ titles.rs1.landingUrl }}</h3>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{
            required: true,
            regex: urlRegex
          }"
        >
          <v-text-field
            v-model="rs1.landingUrl"
            :error-messages="errors"
            required
            :placeholder="placeholder.landingUrl"
            outlined
          >
            <template v-slot:append-outer>
              <v-tooltip v-if="rs1.landingUrl && errors.length === 0" bottom>
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    v-on="{ ...onTooltip }"
                    icon
                    @click="openInNewTab(rs1.landingUrl)"
                  >
                    <v-icon v-on="onTooltip">mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>새탭에서 링크 열기</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </ValidationProvider>
        <alert-message
          border="left"
          icon="mdi-information"
          color="blue"
          message="광고 방식은 캠페인 등록 후 수정이 불가합니다."
          localStorageKey="campaignTypeAlert"
          dismissible
        />
        <h3>{{ titles.rs1.campaignType }}</h3>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <v-radio-group
            v-model="rs1.campaignType"
            class="d-inline-block"
            :error-messages="errors"
            :disabled="isDialogTypeEdit"
          >
            <div
              v-for="(m, index) in CampaignTypeList"
              :key="index"
              class="mb-5"
            >
              <v-radio :value="m.value" class="ma-0">
                <template v-slot:label>
                  <div>
                    <h4>
                      {{ m.text }}
                      <InformationHover
                        :title="m.text"
                        :subTitle="m.comment"
                        :image="m.previewImage"
                      />
                    </h4>
                    <h5>{{ m.comment }}</h5>
                  </div>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </ValidationProvider>
        <template v-if="allowCampaignTypes.includes(rs1.campaignType)">
          <h3>{{ titles.rs1.clickType }}</h3>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-radio-group
              v-model="rs2.push.clickType"
              class="d-inline-block"
              :error-messages="errors"
            >
              <div
                v-for="(m, index) in clickTypeList"
                :key="index"
                class="mb-5"
              >
                <v-radio :value="m.value" class="ma-0">
                  <template v-slot:label>
                    <div>
                      <h4>
                        {{ m.text }}
                        <InformationHover
                          v-if="m.info"
                          :title="m.text"
                          :subTitle="m.comment"
                          :image="m.previewImage"
                        />
                      </h4>
                      <h5>{{ m.comment }}</h5>
                    </div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </ValidationProvider>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="4">
        <CampaignContentFooter :invalid="invalid" />
      </v-col>
    </v-row>
  </ValidationObserver>
</template>
<script>
import {
  CampaignTypeList,
  Instructions,
  Titles
} from '@/config/admin/campaign';
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CampaignContentFooter from './CampaignContentFooter.vue';
import CampaignUser from './CampaignStep1User.vue';
import InformationHover from '@/components/InformationHover.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CampaignUser,
    CampaignContentFooter,
    InformationHover
  },
  props: ['rs1', 'rs2'],
  computed: {
    ...mapGetters({
      currentStep: 'campaign/currentStep',
      dialog: 'campaign/dialog',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit'
    }),
    placeholder() {
      return Instructions.rs1;
    },
    CampaignTypeList() {
      return CampaignTypeList;
    },
    titles() {
      return Titles;
    }
  },
  watch: {
    dialog: function(newVal) {
      if (newVal) {
        this.$refs.obs1.reset();
      }
    }
  },
  data() {
    return {
      urlRegex:
        'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
      allowCampaignTypes: ['push', 'message'],
      campaignNameStringMax: 100,
      clickTypeList: [
        {
          text: '브라우저 오픈 타입',
          value: 'direct',
          comment:
            '노티 광고 클릭 시 사용자가 설정해둔 기본 브 라우저로 웹사이트가 바로 열립니다.',
          previewImage: null,
          info: false
        },
        {
          text: '앱 내 페이지 오픈 타입',
          value: 'normal',
          comment:
            '노티 광고 클릭 시 앱 내 페이지에서 웹사이트 주소와 추가 정보를 노출합니다.',
          previewImage: require('@/assets/campaign/preview-push-inapp.png'),
          info: true
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'campaign/setCurrentStep'
    }),
    async submit() {
      const valid = await this.$refs.obs1.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    },
    openInNewTab(landingUrl) {
      window.open(landingUrl, '_blank');
    }
  }
};
</script>
