<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="800"
    scrollable
  >
    <v-card v-if="campaign">
      <v-card-text>
        <campaignPreview
          :showLandingUrl="campaign.isTypeCoupon ? false : true"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn color="primary" text @click="exit()">닫기</v-btn>
      </v-card-actions>

      <v-overlay :value="apiLoading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CampaignPreview from '../register/CampaignPreview.vue';
export default {
  components: { CampaignPreview },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      apiLoading: 'campaign/apiLoading'
    }),
    dialog: {
      get() {
        return this.openDialog;
      },
      set() {
        this.exit();
      }
    }
  },
  watch: {
    apiLoading(val) {
      if (!val && !this.campaign?.id) {
        this.$emit('closeDialog', true);
      }
    }
  },
  methods: {
    ...mapActions({
      initCampaign: 'campaign/initCampaign'
    }),
    exit() {
      this.initCampaign();
      this.$emit('closeDialog', true);
    }
  }
};
</script>

<style></style>
