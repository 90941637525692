<template>
  <v-dialog
    :value="dialog"
    max-width="1200"
    persistent
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>결제 상세내역</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5" style="max-height: 80vh">
        <a-descriptions bordered>
          <a-descriptions-item label="주문번호">
            {{ record.orderNo }}
          </a-descriptions-item>
          <a-descriptions-item label="신청날짜" :span="2">
            {{ record.orderAt }}
          </a-descriptions-item>
          <a-descriptions-item label="회원ID">
            {{ record.userName }}
          </a-descriptions-item>
          <a-descriptions-item label="회원 이메일" :span="2">
            {{ record.userEmail }}
          </a-descriptions-item>
          <a-descriptions-item label="상태">
            <a-tag small :color="record.statusColor">
              {{ record.statusText }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="결제 유형">
            {{ record.paymentType === 'pg' ? '온라인거래' : '계좌거래' }}
          </a-descriptions-item>
          <a-descriptions-item label="결제 방식">
            {{ record.paymentMethod }}
          </a-descriptions-item>

          <a-descriptions-item label="결제 금액">
            {{ record.amount * 1 }}
          </a-descriptions-item>
          <a-descriptions-item label="충전 포인트">
            {{ record.advertisingAmount }}
          </a-descriptions-item>
          <a-descriptions-item label="부가세액">
            {{ record.taxAmount }}
          </a-descriptions-item>
          <a-descriptions-item label="승인 금액">
            <strong class="primary--text">
              {{ (record.validAmount * 1).toLocaleString() }} 원
            </strong>
          </a-descriptions-item>
          <a-descriptions-item label="입금 승인일" :span="2">
            {{ record.confirmAt }}
          </a-descriptions-item>
          <a-descriptions-item label="취소 금액">
            <strong class="error--text">
              {{ (record.cancelAmount * 1).toLocaleString() }} 원
            </strong>
          </a-descriptions-item>
          <a-descriptions-item label="결제 취소일" :span="2">
            {{ record.cancelAt }}
          </a-descriptions-item>

          <template v-if="!isCreditcard">
            <!-- 직접입금일 경우 -->
            <a-descriptions-item label="입금자명">
              {{ record.accountName }}
            </a-descriptions-item>
            <a-descriptions-item label="세금계산서 발행요청일" :span="2">
              {{ record.taxInvoiceDate }}
            </a-descriptions-item>
          </template>

          <a-descriptions-item label="비고 및 실패사유">
            {{ record.remark }}
          </a-descriptions-item>

          <a-descriptions-item label="취소 내역" v-if="isCreditcard">
            <!-- 카드결제일 경우 -->
            <v-row
              style="max-height: 120px; overflow: auto"
              v-if="cancelList.length > 0"
            >
              <v-timeline align-top dense :loading="loading">
                <v-timeline-item
                  v-for="(cancel, idx) in cancelList"
                  :key="idx"
                  :color="'error'"
                  small
                >
                  <v-row class="pt-1" align="center">
                    <v-col cols="auto">
                      <strong class="mr-1">{{ cancel.createdDate }}</strong>

                      <strong class="text-caption">
                        {{ cancel.createdTime }}
                      </strong>
                    </v-col>
                    <v-col>
                      <strong>{{ cancel.amount }}</strong>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-row>
            <v-row class="justify-center pa-5" v-else>
              <InfoMessage description="내역이 없습니다." :iconSize="25" />
            </v-row>
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          bordered
          class="payment-order-comments mt-3"
          layout="vertical"
        >
          <a-descriptions-item :span="3" class="pa-0">
            <a-row slot="label" type="flex">
              <a-col class="text-h6" flex="auto">코멘트 내역</a-col>
              <a-col>
                <v-btn
                  color="#00d1b2"
                  class="mr-2"
                  depressed
                  outlined
                  :disabled="loading"
                  @click="fetchPointOrderComments"
                >
                  새로고침
                </v-btn>
                <v-btn
                  color="#00d1b2"
                  class="white--text"
                  depressed
                  :disabled="loading"
                  @click="openCommentDialog"
                >
                  코멘트 추가
                </v-btn>
              </a-col>
            </a-row>

            <h4 class="row justify-center pa-5" v-if="!comments.length">
              <InfoMessage
                description="등록된 코멘트가 없습니다."
                :iconSize="25"
              />
            </h4>

            <div v-else style="margin: 0; max-height: 250px">
              <v-timeline align-top dense :loading="loading">
                <v-timeline-item
                  v-for="(comment, idx) in comments"
                  :key="idx"
                  :color="'blue'"
                  small
                >
                  <v-card
                    class="elevation-2"
                    color="secondary"
                    outlined
                    style="max-width: 90%"
                  >
                    <v-card-text
                      class="row ma-0 py-2 px-3"
                      style="background-color: #fff"
                    >
                      <div class="col">
                        <v-chip label outlined small>
                          {{ comment.adminName }}
                        </v-chip>
                        <div
                          class="text-subTitle-2 ma-3"
                          v-html="comment.text"
                        />
                        <div class="mx-3" v-html="comment.createdAt" />
                      </div>
                      <v-row class="col-auto pr-0 ma-0" align="end">
                        <v-btn
                          color="red"
                          class="mr-2"
                          depressed
                          outlined
                          small
                          :disabled="loading"
                          @click="handleDeleteCommentClick(comment)"
                        >
                          삭제
                        </v-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-row class="pt-1">
                    <v-col cols="auto"> </v-col>
                    <v-col> </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </a-descriptions-item>
        </a-descriptions>
      </v-card-text>

      <v-card-actions>
        <v-row class="ma-0">
          <v-col cols="12" class="text-center">
            <v-btn class="mr-2" depressed @click="closeDialog"> 닫기 </v-btn>
            <v-btn
              v-if="
                record.status === 'W' && record.paymentMethod === '직접입금'
              "
              color="#00d1b2"
              class="white--text"
              depressed
              @click="handleConfirmClick()"
            >
              입금 확인
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <PaymentCommentStoreDialog @refresh="fetchPointOrderComments" />
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  confirmBankTransferAPI,
  getPointOrderCommentsApi,
  deletePointOrderCommentApi,
  getPaymentCancelsApi
} from '@/api/admin/PointAPI';
import { InfoMessage } from '@/components/common';
import PaymentCommentStoreDialog from './PaymentCommentStoreDialog';

export default {
  components: {
    PaymentCommentStoreDialog,
    InfoMessage
  },
  computed: {
    ...mapGetters({
      dialog: 'payment/dialog',
      record: 'payment/record'
    }),
    // 취소 || 부분취소인 상태값인가
    isCancelStatus() {
      return this.record && ['PC', 'C'].includes(this.record.status);
    },
    isCreditcard() {
      return this.record && this.record.paymentType === 'pg';
    }
  },
  watch: {
    dialog(v) {
      this.loading = v;
      if (v) {
        //
        this.fetchPointOrderComments();

        // 상태값이
        if (this.isCancelStatus && this.isCreditcard) {
          this.fetchPaymentCancels();
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      comments: [],
      cancelList: [] // 취소 내역
    };
  },
  methods: {
    ...mapActions({
      closeDialog: 'payment/closeDialog',
      openCommentDialog: 'payment/openCommentDialog'
    }),
    handleConfirmClick() {
      this.$Swal
        .fire({
          icon: 'info',
          title: '입금 확인',
          html: `<h3>사용자 계정으로 포인트를 충전합니다.<br>충전액: ${this.record.advertisingAmount}</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,

          preConfirm: async () => {
            const [error, data] = await confirmBankTransferAPI(
              this.record.orderNo
            );

            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(
                error?.data?.message || `요청 실패`
              );
              return false;
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Swal.fire({
              icon: 'success',
              title: '충전되었습니다.',
              timer: 1500,
              showConfirmButton: false
            });
            this.$emit('index');
            this.closeDialog();
          }
        });
    },
    // 취소 내역
    async fetchPaymentCancels() {
      this.loading = true;
      this.cancelList = [];

      if (!this.record.orderNo) return;

      const [error, data] = await getPaymentCancelsApi(this.record.orderNo);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const { cancels: items } = data;

        this.cancelList = items.map((item, index) => {
          return {
            key: index,
            id: item.id,
            createdDate: this.$moment(item.created_at).format('YY.MM.DD'),
            createdTime: this.$moment(item.created_at).format('HH:mm:ss'),
            amount: (item.amount * 1).toLocaleString() + '원'
          };
        });
      }

      this.loading = false;
    },
    async fetchPointOrderComments() {
      this.loading = true;
      this.comments = [];

      if (!this.record.orderNo) return;

      const [error, data] = await getPointOrderCommentsApi(this.record.orderNo);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const { comments: items } = data;

        this.comments = items.map((item, index) => {
          return {
            key: index,
            id: item.id,
            createdAt: this.$moment(item.created_at).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            text: item.text.replace(/\n/gi, '<br/>'),
            adminName: item.admin_name
          };
        });
      }

      this.loading = false;
    },
    handleDeleteCommentClick(item) {
      if (!item) return;

      this.$Swal
        .fire({
          icon: 'info',
          title: '코멘트 삭제',
          html: `<h3>삭제된 정보는 복구 불가능합니다.<br>${item.id}번 코멘트를 삭제 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,

          preConfirm: async () => {
            const [error, data] = await deletePointOrderCommentApi(item.id);

            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(
                error?.data?.message || `요청 실패`
              );
              return false;
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Swal.fire({
              icon: 'success',
              title: '삭제되었습니다.',
              timer: 1500,
              showConfirmButton: false
            });
            this.$emit('index');
            this.fetchPointOrderComments();
          }
        });
      //
    }
  }
};
</script>
<style lang="scss">
.payment-order-comments {
  .ant-descriptions-item-content {
    padding-top: 0 !important;
    overflow: auto;
    overflow-x: hidden;
  }
}
</style>
