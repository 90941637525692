import axios from 'axios';

export async function confirmPasswordApi(params) {
  try {
    const {
      data
    } = await axios.post(`/c/u/confirm-password`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateProfileApi(params, option = null) {
  try {
    const {
      data
    } = await axios.post(`/c/u/profile`, params, option);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getProfileApi(params) {
  try {
    const {
      data
    } = await axios.get(`/c/u/profile`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getInquiriesApi(params) {
  try {
    const {
      data
    } = await axios.get(`/c/u/inquiries`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createInquiryApi(inquiry) {
  try {
    const {
      data
    } = await axios.post(`
    `, inquiry);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function cancelInquiryApi(params) {
  try {
    const {
      data
    } = await axios.delete(`/c/u/inquiry`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getNoticesApi() {
  try {
    const {
      data
    } = await axios.get('/c/u/notices')
    return [null, data];
  } catch (error) {
    return [error]
  }
}

export async function getFaqsApi() {
  try {
    const {
      data
    } = await axios.get('/c/u/faqs')
    return [null, data];
  } catch (error) {
    return [error]
  }
}