<template>
  <v-row align="center" justify="center" class="mx-auto">
    <v-col cols="12">
      <a-card>
        <a-descriptions bordered class="py-0">
          <a-descriptions-item label="기간" :span="2">
            <FilterDatePicker
              :style="{ display: 'inline-block' }"
              :prefix="7"
              @setDateRanges="
                v => {
                  dateRanges = v;
                }
              "
              :isOpend="true"
              :defaultValue="dateRanges"
              @remove="dateRanges = day"
              @applyFilter="handleDateRanges()"
            />
          </a-descriptions-item>

          <a-descriptions-item label="지점" :span="1">
            <v-autocomplete
              v-model="filters.brchNos"
              :items="placeList"
              item-text="brch_nm"
              item-value="brch_no"
              hide-details
              clearable
              dense
              outlined
              multiple
              :placeholder="'지점 선택'"
              class="mr-2"
              @change="getTypeReportStatsApi"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>
                    {{ item.brch_nm }}
                    {{
                      filters.brchNos.length > 1
                        ? ` 외 ${filters.brchNos.length - 1} 개`
                        : ''
                    }}
                  </span>
                </v-chip>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleBrchNos">
                  <v-list-item-action>
                    <v-icon
                      :color="filters.brchNos.length > 0 ? 'primary' : ''"
                    >
                      {{ branchIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> 전체선택 </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
    </v-col>

    <v-col cols="12" :lg="12">
      <AdminMegaboxReportCharts
        :changeData="!loading"
        :campaignType="'megabox-coupon'"
        title="메가박스 쿠폰"
        :dateRanges="dateRanges"
        :chartData="chartStats"
      />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-text class="py-3">
          <Table
            v-if="columns && !loading"
            class="pa-3"
            style="white-space: nowrap"
            :data-source="stats"
            :columns="columns"
            :rowKey="
              (record, index) => {
                return `${record.date}`;
              }
            "
            :rowClassName="
              (record, index) => {
                if (index === 0) {
                  return 'table-summary-rows';
                }
              }
            "
            bordered
            :pagination="false"
            :loading="loading"
            size="small"
            :scroll="{
              x: true
            }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
          >
            <DayOfWeek
              v-for="(date, dateIndex) in dates"
              :key="dateIndex"
              :slot="date.value"
              :date="date.value"
            />
            <template
              v-for="(date, dateIndex) in dates"
              :slot="date.value"
              slot-scope="data, record"
            >
              <span
                :key="`${dateIndex}`"
                :class="`${getGapIcon(record, dateIndex).color}--text darken-3`"
              >
                <span class="title">{{ data.toLocaleString() }}</span>
                <div class="d-flex align-center justify-end">
                  <v-icon :color="getGapIcon(record, dateIndex).color">{{
                    getGapIcon(record, dateIndex).icon
                  }}</v-icon>
                  {{ getGapIcon(record, dateIndex).percent }}
                </div>
              </span>
            </template>

            <template slot="localeString" slot-scope="value">
              {{ value > 0 ? value.toLocaleString() : '-' }}
            </template>
          </Table>
        </v-card-text>
      </v-card>
    </v-col>
    <SpeedDialMenu
      v-if="stats.length > 0"
      :type="type"
      :headers="columns"
      :tableOptions="tableOptions"
      :filters="{
        dateRanges: filters.dateRanges
      }"
      :chartList="chartList"
      :issetLive="issetLive"
      :brchNos="filters.brchNos"
    />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminMegaboxReportCharts from './AdminMegaboxReportCharts.vue';
import DayOfWeek from '@/components/DayOfWeek.vue';
import SpeedDialMenu from './SpeedDialMenu.vue';
import { getReportStatsMegaboxCouponChartAPI } from '@/api/admin/report';
import { getMegaboxPlacesApi } from '@/api/common/PartnerAPI';
import FilterDatePicker from '@/components/FilterDatePicker.vue';
import { Table } from 'ant-design-vue';

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'megabox-coupon'
    }
  },
  components: {
    AdminMegaboxReportCharts,
    DayOfWeek,
    SpeedDialMenu,
    Table,
    FilterDatePicker
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    totalRowColSpan() {
      return 1;
    },
    chartList() {
      let resList = [
        {
          text: '쿠폰',
          value: 'coupon',
          options: ['click', 'impression']
        }
      ];
      return resList;
    },
    allBranch() {
      return this.filters.brchNos.length === this.placeList.length;
    },
    someBranch() {
      return this.filters.brchNos.length > 0 && !this.placeList;
    },
    branchIcon() {
      if (this.allBranch) return 'mdi-close-box';
      if (this.someBranch) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    days() {
      const days = [];
      const dateStart = this.$moment(this.dateRanges[0], 'YYYY-MM-DD');
      const dateEnd = this.$moment(this.dateRanges[1], 'YYYY-MM-DD');
      while (dateEnd.diff(dateStart, 'days') >= 0) {
        days.push(dateStart.format('D'));
        dateStart.add(1, 'days');
      }
      return days;
    },
    issetGapIcon() {
      return this.filterMeasureStandard === 'date';
    },
    issetLive() {
      return this.days.length > 31 ? false : true;
    }
  },
  data() {
    return {
      datePickerMenu: false,
      selectFilter: null,
      day: this.$moment().format('YYYY-MM-DD'),
      placeList: [],
      dates: [],
      dateRanges: [],
      columns: [],
      chartStats: [],
      stats: [],
      tableOptions: {},
      rawDataSwitch: false,
      loading: false,
      filters: {
        brchNos: [],
        dateRanges: []
      }
    };
  },
  async created() {
    this.dateRanges = [
      this.$moment(this.day, 'YYYY-MM-DD')
        .subtract(6, 'days')
        .format('YYYY-MM-DD'),
      this.$moment(this.day, 'YYYY-MM-DD').format('YYYY-MM-DD')
    ];

    await Promise.all([this.fetchMegaboxPlace()], this.handleDateRanges());
  },
  methods: {
    async fetchMegaboxPlace() {
      this.placeList = [];

      const [error, data] = await getMegaboxPlacesApi();
      if (error) {
        console.error(error);
      } else {
        const { megabox_places } = data;
        this.placeList = [...this.placeList, ...megabox_places];
      }
    },
    initStats() {
      this.stats = this.chartStats = [];
    },
    async getTypeReportStatsApi() {
      this.loading = true;
      this.initStats();

      const [error, data] = await getReportStatsMegaboxCouponChartAPI({
        params: this.filters
      });
      if (error) {
        console.error(error);
      } else {
        this.chartStats = data.stats;

        let ctrStats = []; // 기공할 테이블 데이터
        let apiCallCountSum = 0; // api 호출 횟수 합
        let impressionCountSum = 0; // 팝업 노출 합
        let reachCountSum = 0; // 예매한 회원 수 합

        this.dates.forEach(date => {
          const data = this.chartStats.find(v => v.date === date.value);

          let obj = {
            key: date,
            date: date.value,
            impression: 0,
            reach_count: 0
          };

          if (data) {
            obj = { ...data, key: date };
            apiCallCountSum += data.api_call_count;
            impressionCountSum += data.impression_count;
            reachCountSum += data.reach_count;
          }

          ctrStats = [...ctrStats, obj];
        });

        this.summaryStat = {
          date: '전체',
          api_call_count: apiCallCountSum,
          impression_count: impressionCountSum,
          reach_count: reachCountSum,
          summary: true
        };

        this.stats = [this.summaryStat, ...ctrStats];
      }
      this.loading = false;
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }

      this.dates.reverse();
    },
    async setHeaders() {
      this.setDates();
      this.columns = [
        {
          title: '날짜',
          dataIndex: 'date'
        },
        {
          title: 'API 호출',
          dataIndex: 'api_call_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '팝업 노출',
          dataIndex: 'impression_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '예약한 회원(명)',
          dataIndex: 'reach_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        }
      ];
    },
    getGapIcon(key, index) {
      if (this.stats.length === index + 1) {
        return '';
      }
      if (this.stats[index][key] > this.stats[index + 1][key]) {
        return {
          color: 'red',
          icon: `mdi-menu-up`
        };
      } else if (this.stats[index][key] < this.stats[index + 1][key]) {
        return {
          color: 'primary',
          icon: `mdi-menu-down`
        };
      } else {
        return {
          color: 'black',
          icon: `mdi-equal`
        };
      }
    },
    // 지점 전체 선택시
    toggleBrchNos() {
      this.$nextTick(() => {
        if (this.allBranch) {
          this.filters.brchNos = [];
        } else {
          this.filters.brchNos = this.placeList.slice().map(v => v.brch_no);
        }
        this.getTypeReportStatsApi();
      });
    },
    handleDateRanges() {
      if (
        JSON.stringify(this.dateRanges) ===
        JSON.stringify(this.filters.dateRanges)
      )
        return;
      this.filters.dateRanges = JSON.parse(JSON.stringify(this.dateRanges));

      this.setHeaders();
      this.getTypeReportStatsApi();
    }
  }
};
</script>

<style></style>
