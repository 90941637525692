<template>
  <v-dialog
    :value="true"
    :confirm-loading="loading"
    @cancel="close"
    :width="650"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>계정 활성여부 변경</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 filters">
        <ValidationObserver :ref="refKey">
          <v-row dense>
            <CustomGridItem title="계정 활성여부" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    confirmed_status: advertiser.status.value
                  }"
                >
                  <v-radio-group
                    v-model="form.status"
                    class="d-inline-block ma-0"
                    :hide-details="!errors.length"
                    :error-messages="errors"
                  >
                    <template v-for="(m, index) in approveStatusList">
                      <v-radio
                        v-if="!disabledStatusList.includes(m.value)"
                        :value="m.value"
                        :key="index"
                        :color="m.colorCode"
                        class="ma-3 d-inline-block"
                      >
                        <template v-slot:label>
                          <h4>
                            <a-tag class="mr-0" small :color="m.color">
                              {{ m.text }}
                            </a-tag>
                          </h4>
                        </template>
                      </v-radio>
                    </template>
                  </v-radio-group>
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              v-if="form.status === 'N'"
              title="승인 거절 사유"
              outlined
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true
                  }"
                >
                  <v-textarea
                    v-model="form.denyReason"
                    outlined
                    placeholder="거절 사유를 입력해주세요"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                  >
                  </v-textarea>
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="#00d1b2"
                class="white--text"
                depressed
                @click="updateAdvertiserStatus"
                :loading="loading"
              >
                변경
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import { mapGetters, mapActions } from 'vuex';
import { UserApproveStatusList } from '@/config/user';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CustomGridItem
  },
  props: {
    advertiser: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign/dialog'
    }),
    disabledStatusList() {
      let res = [];
      const { status } = this.advertiser;

      if (status) {
        // 대기상태에서는 거절, 승인 만 선택가능
        if (status.value === 'W') {
          res = ['S', 'W'];
        }
        // 승인상태에서는 중단만 선택 가능
        if (status.value === 'Y') {
          res = ['Y', 'W'];
        }
        // 중단상태에서는 승인만 선택 가능
        // if (status.value === 'S') {
        //   res = ['S', 'W'];
        // }
      }
      return res;
    },
    approveStatusDialog() {
      return this.dialog.approveStatus;
    },
    approveStatusUrl() {
      let url = '';
      if (this.form.status === 'Y') url = 'approve';
      else if (this.form.status === 'N') url = 'approve-deny';
      else if (this.form.status === 'W') url = 'approve-wait';
      else if (this.form.status === 'S') url = 'approve-stop';
      return url;
    }
  },
  watch: {
    dialog() {
      this.loading = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  data() {
    return {
      loading: false,
      refKey: 'appUpdateStatusForm',
      approveStatusList: UserApproveStatusList.filter(item => !!item.value),
      form: {
        userId: '',
        status: '',
        denyReason: ''
      }
    };
  },
  created() {
    if (this.advertiser) {
      this.form = {
        userId: this.advertiser.userId,
        status: this.advertiser.status?.value,
        denyReason: ''
      };
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'advertiser/closeDialog'
    }),
    async updateAdvertiserStatus() {
      const { isValid } = await this.$refs[this.refKey].validateWithInfo();

      if (!isValid) return;

      if (!this.approveStatusUrl) return;

      this.loading = true;

      const { data } = await this.$axios.patch(
        `/admin/user/${this.form.userId}/${this.approveStatusUrl}`,
        this.form
      );

      if (data.success) {
        this.$notification['success']({
          message: '변경 성공',
          description: data.message
        });

        this.close();
        this.$emit('refresh');
      } else {
        this.$axiosErrorAlert();
      }

      this.loading = false;
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
