<template>
  <v-dialog v-model="dialog" max-width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>{{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 py-3">
        <ValidationObserver :ref="refKey">
          <v-row dense>
            <v-col cols="12">
              <v-radio-group
                v-model="campaignResource.approveStatus"
                class="d-inline-block mt-0"
                label="게재 상태 선택"
              >
                <div
                  v-for="(m, index) in filterdApproveStatusList"
                  :key="index"
                  class="ma-3"
                >
                  <v-radio :value="m.value" class="ma-0" :color="m.color">
                    <template v-slot:label>
                      <div>
                        <h4>
                          <v-badge dot inline :color="m.color">
                            {{ m.text }}
                          </v-badge>
                        </h4>
                      </div>
                    </template>
                  </v-radio>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="campaignResource.approveStatus === 'D'" dense>
            <template v-if="!getApproveDenyReason">
              <v-col cols="12">
                <v-select
                  v-model="campaignResource.denyCode"
                  label="승인 거절 사유"
                  :items="ApproveDenyReasonList"
                  item-text="text"
                  item-value="value"
                  outlined
                  @input="setApproveDenyReason"
                ></v-select>
              </v-col>
              <v-col
                v-if="campaignResource.denyCode === 'D000'"
                cols="12"
                class="pb-0"
              >
                <v-textarea
                  v-model="campaignResource.denyReason"
                  outlined
                  placeholder="거절 사유를 입력해주세요"
                >
                </v-textarea>
              </v-col>
            </template>
            <v-col v-else cols="12">
              승인 거절 사유
              <p class="col error--text font-weight-bold">
                {{ getApproveDenyReason }}
              </p>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn @click="handleCloseClick"> 닫기 </v-btn>
        <v-btn
          color="secondary"
          @click.stop="handleSaveClick()"
          :loading="dialogLoading"
          :disabled="!campaignResource.isStatusChanged() || dialogLoading"
        >
          적용
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { ApproveDenyReasonList } from '@/config/admin/campaign';
import { CampaignResourceStatusList } from '@/config/common';
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import {
  updateCampaignResourceApprove,
  updateCampaignResourceDeny
} from '@/api/admin/CampaignResourceAPI';

export default {
  components: {
    ValidationObserver
  },
  data() {
    return {
      refKey: 'campaignResourceDialog'
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'campaignResource/approveStatusDialog',
      loading: 'campaignResource/loading',
      campaignResource: 'campaignResource/campaignResource'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return '캠페인 소재 상태 변경';
    },
    filterdApproveStatusList() {
      return CampaignResourceStatusList.filter(status => status.value !== 'W');
    },
    ApproveDenyReasonList() {
      return ApproveDenyReasonList;
    },
    getApproveDenyReason() {
      return this.campaignResource.approveDenyReason;
    }
  },
  watch: {
    dialog() {
      this.loading.dialog = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeApproveStatusDialog: 'campaignResource/closeApproveStatusDialog'
    }),
    /** @description: 상태 변경  */
    async handleSaveClick() {
      //  동일하면 변경 안해줌
      let msg = '';

      if (
        this.campaignResource.approveStatus === 'D' &&
        !this.campaignResource.denyReason
      ) {
        msg = '<h3>거절사유를 입력해 주세요.</h3>';
      }

      if (msg) {
        this.$Swal.fire({
          icon: 'error',
          html: msg,
          showConfirmButton: true,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소'
        });
        return;
      }

      this.loading.dialog = true;

      const resourceId = this.campaignResource.id;
      const [error] =
        this.campaignResource.approveStatus === 'A'
          ? await updateCampaignResourceApprove(resourceId)
          : await updateCampaignResourceDeny(resourceId, {
              denyCode: this.campaignResource.denyCode,
              denyReason: this.campaignResource.denyReason
            });

      if (error) {
        console.error(error);
      } else {
        this.$Swal
          .fire({
            icon: 'success',
            title: '변경사항이 적용되었습니다.',
            confirmButtonText: '확인'
          })
          .then(() => this.closeApproveStatusDialog());
        this.$emit('refresh');
      }

      this.loading.dialog = false;
    },
    // 거절 사유를 선택합니다.
    setApproveDenyReason(value) {
      this.campaignResource.denyReason = null;
      if (value !== 'D000') {
        // 거절 사유가 직접입력이 아닌경우
        this.campaignResource.denyReason = this.ApproveDenyReasonList.find(
          v => v.value === value
        ).text;
      }
    },
    /** @description: 닫기 */
    handleCloseClick() {
      // 변경사항 있는경우 확인
      this.closeApproveStatusDialog();
    },
    inputFocus(target) {
      target.select();
    }
  }
};
</script>
