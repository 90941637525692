<template>
  <div>
    <div class="d-flex align-center mb-3">
      <h3>{{ title }}</h3>
      <div class="d-inline-flex ml-auto">
        <image-upload-dialog
          :imageType="imageType"
          :campaignType="campaignType"
          @addImage="
            v => {
              images.unshift(v);
            }
          "
        />
      </div>
    </div>
    <v-card outlined v-if="images.length > 0">
      <v-card-text>
        <v-row dense>
          <v-col
            v-for="(image, index) in images"
            :key="index"
            cols="12"
            sm="12"
            :md="isImageTypeIcon ? 4 : 6"
          >
            <v-card>
              <v-img
                :src="image.url"
                class="mx-auto white--text"
                :height="isImageTypeIcon === 'icon' ? 70 : 100"
                contain
                @click="selectedImage(image)"
              >
              </v-img>

              <v-overlay absolute :value="imageId === image.id">
                <v-btn color="red lighten-1" @click="selectedImage(null)">
                  <v-icon x-large>mdi-check</v-icon>
                </v-btn>
              </v-overlay>

              <v-card-text>
                <v-row>
                  <v-col cols="10" @click="selectedImage(image)">
                    <h4>파일명: {{ image.name }}</h4>
                    <h4>해상도: {{ image.width }} X {{ image.height }}</h4>
                  </v-col>
                  <v-col cols="2">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="deleteImage(image, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="pagination.totalPage > 1">
          <v-col>
            <div class="d-flex">
              <v-pagination
                color="secondary"
                v-model="pagination.page"
                :length="pagination.totalPage"
                :total-visible="10"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-alert v-else border="left" colored-border color="secondary" elevation="2"
      >선택 할 수 있는 이미지가 없습니다. 이미지를 업로드해주세요.</v-alert
    >
  </div>
</template>
<script>
import ImageUploadDialog from './ImageUploadDialog.vue';

export default {
  components: {
    ImageUploadDialog
  },
  props: ['sImageId', 'campaignType', 'imageType', 'title'],
  data() {
    return {
      imageId: null,
      image: null,
      images: [],
      pagination: {
        page: 1,
        perPage: 6,
        totalPage: 1
      },
      searchString: null
    };
  },
  computed: {
    isImageTypeIcon() {
      return this.imageType === 'icon';
    }
  },
  watch: {
    sImageId(value) {
      this.imageId = value;
    },
    image: {
      deep: true,
      handler(newData) {
        this.$emit('setImage', newData);
      }
    },
    'pagination.page': function() {
      this.getImages();
    }
  },
  mounted() {
    const perPage = this.isImageTypeIcon ? 9 : 6;
    this.pagination.perPage = perPage;
    this.getImages().then(() => {
      this.imageId = this.sImageId;
    });
  },
  methods: {
    selectedImage(image) {
      if (image) {
        this.image = image;
        this.imageId = image.id;
      } else {
        this.image = {
          id: null,
          type: null,
          url: null
        };
        this.imageId = null;
      }
    },
    getImages(page = this.pagination.page) {
      return this.$axios
        .get('/utils/campaign-images', {
          params: {
            ss: this.searchString,
            page: page,
            perPage: this.pagination.perPage,
            campaignType: this.campaignType,
            imageType: this.imageType
          }
        })
        .then(res => res.data)
        .then(resData => {
          // console.log(resData);
          this.images = resData.images;
          const totalPage = parseInt(
            resData.total_count / this.pagination.perPage
          );
          this.pagination.totalPage =
            resData.total_count % this.pagination.perPage
              ? totalPage + 1
              : totalPage;
        })
        .catch(err => {
          console.error(err);
        });
    },
    deleteImage(image, index) {
      this.$Swal
        .fire({
          html: `<h3>선택하신 이미지를 삭제합니다.</h3>${image.name}`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await this.$axios
              .delete('/utils/campaign-images', {
                data: {
                  image: image
                }
              })
              .then(res => {
                if (!res.data.success) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.error(err);
                this.$Swal.showValidationMessage(
                  err?.data?.message || `요청 실패`
                );
                return true;
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            // this.$Swal.fire({
            //   html: '<h3>선택하신 이미지가 삭제 되었습니다.</h3>',
            //   icon: 'success',
            //   confirmButtonText: '확인'
            // });
            this.images.splice(index, 1);
          }
        });
    }
  }
};
</script>
