import moment from 'moment';

export default {
  namespaced: true,

  state: {
    media: null,
    placeholder: {
      name: null,
      mediaGroup: null
    },
    label: {
      name: '매체사 명',
      mediaGroup: '매체 그룹',
      uid: '로그인 아이디',
      password: '패스워드',
      phone: '전화',
      email: 'E-mail',
      mediaGroupType: '그룹 유형',
      profitRatio: '단일매체 매출 비율',
      directProfitRatio: '직매체 매출 비율',
      agencyProfitRatio: '대행사 매출 비율',
      package: '앱 패키지 명',
      storeUrl: '스토어 URL',
      sdkMediaIdx: 'SDK 매체 번호',
      serviceRestartInterval: '서비스 재시작 간격',
      settingRequestInterval: '세팅 요청 간격',
      weatherRequestInterval: '날씨 데이터 요청 간격',
      campaignRequestInterval: '광고 요청 간격',
      campaignRequestBackgroundInterval: '광고 요청 백그라운드 간격',
      campaignImpressionInterval: '광고 노출 간격',
      campaignImpressionUnlockCount: '스크린락 해제 개수',
      locationSavePeriod: '위치데이터 저장 기간',
      locationSaveBackgroundPeriod: '위치데이터 저장 백그라운드 기간',
      campaignInfoActivation: '광고 정보 활성화',
      campaignImpressionType: '광고 노출 타입',
      campaignAdxRate: 'ADX 광고 비율'
    },
    dialog: {
      store: false,
      detail: false
    },
    record: {},
    form: {},
    filters: {
      name: '',
      groupName: '',
      active: '',
      aggReachActive: '',
      sdkMediaIdx: '',
      dates: [moment().subtract(2, 'years'), moment()]
    }
  },
  getters: {
    media(state) {
      return state.media;
    },
    placeholder(state) {
      return state.placeholder;
    },
    label(state) {
      return state.label;
    },
    dialog(state) {
      return state.dialog;
    },
    form(state) {
      return state.form;
    },
    record(state) {
      return state.record;
    },
    filters(state) {
      return state.filters;
    }
  },
  mutations: {
    SET_Dialog(state, { dialog: dialog, type: type }) {
      state.dialog[type] = dialog;
    },
    SET_Record(state, record) {
      state.record = record;
    },
    SET_Form(state, form) {
      state.form = form;
    }
  },
  actions: {
    setMedia({ commit }, media) {
      commit('SET_Media', media);
    },
    initForm({ commit }) {
      const media = {
        name: null,
        active: false,
        aggReachActive: false,
        mediaGroup: null,
        mediaGroupId: null,
        mediaGroupType: null,
        package: null,
        sdkMediaIdx: null,
        storeUrl: null,
        user: {
          uid: null,
          phone: null,
          email: null,
          password: null
        },
        profitRatio: null,
        agencyProfitRatio: null,
        directProfitRatio: null,
        serviceRestartInterval: 24,
        serviceRestartIntervalType: 'h',
        settingRequestInterval: 6,
        settingRequestIntervalType: 'h',
        weatherRequestInterval: 1,
        weatherRequestIntervalType: 'h',
        campaignRequestInterval: 1,
        campaignRequestIntervalType: 'h',
        campaignRequestBackgroundInterval: 1,
        campaignRequestBackgroundIntervalType: 'h',
        campaignImpressionInterval: 20,
        campaignImpressionIntervalType: 's',
        campaignImpressionUnlockCount: 1,
        locationSavePeriod: 1,
        locationSavePeriodType: 'h',
        locationSaveBackgroundPeriod: 1,
        locationSaveBackgroundPeriodType: 'h',
        campaignInfoActivation: 0,
        campaignImpressionType: 1,
        campaignArrowActivation: 1,
        campaignAdxRate: 50
      };

      commit('SET_Form', media);
    },
    openDialog({ commit, dispatch }, { record: record, type: type }) {
      if (type === 'detail') {
        commit('SET_Form', record);
      }

      if (type === 'store') {
        dispatch('initForm');
      }

      commit('SET_Dialog', {
        dialog: true,
        type: type
      });
    },
    closeDialog({ commit }, type) {
      commit('SET_Dialog', {
        dialog: false,
        type: type
      });
    },
    hashingMedia({ dispatch }, media) {
      const { media_group: mediaGroup } = media;

      let user = {
        uid: null,
        phone: null,
        email: null
      };
      //대행사 매체 인경우 로그인 정보를 설정합니다.
      if (mediaGroup.type === 'A') {
        user = {
          uid: media.user?.uid || null,
          phone: media.user?.phone || null,
          email: media.user?.email || null
        };
      }

      const hashedMedia = {
        id: media.id,
        name: media.name,
        mediaGroup: {
          id: mediaGroup.id,
          name: mediaGroup.name,
          type: mediaGroup.type
        },
        profitRatio: media.profit_ratio || null,
        agencyProfitRatio: media.agency_profit_ratio || null,
        directProfitRatio: media.direct_profit_ratio || null,
        user: user,
        package: media.package,
        sdkMediaIdx: media.sdk_media_idx,
        storeUrl: media.store_url,
        serviceRestartInterval: media.service_restart_interval,
        serviceRestartIntervalType: media.service_restart_interval_type,
        settingRequestInterval: media.setting_request_interval,
        settingRequestIntervalType: media.setting_request_interval_type,
        weatherRequestInterval: media.weather_request_interval,
        weatherRequestIntervalType: media.weather_request_interval_type,
        campaignRequestInterval: media.campaign_request_interval,
        campaignRequestIntervalType: media.campaign_request_interval_type,
        campaignRequestBackgroundInterval:
          media.campaign_request_background_interval,
        campaignRequestBackgroundIntervalType:
          media.campaign_request_background_interval_type,
        campaignImpressionInterval: media.campaign_impression_interval,
        campaignImpressionIntervalType: media.campaign_impression_interval_type,
        campaignImpressionUnlockCount: media.campaign_impression_unlock_count,
        locationSavePeriod: media.location_save_period,
        locationSavePeriodType: media.location_save_period_type,
        locationSaveBackgroundPeriod: media.location_save_background_period,
        locationSaveBackgroundPeriodType:
          media.location_save_background_period_type,
        campaignInfoActivation: media.campaign_info_activation,
        campaignImpressionType: media.campaign_impression_type,
        campaignArrowActivation: media.campaign_arrow_activation,
        campaignAdxRate: media.campaign_adx_rate
      };
      dispatch('setMedia', hashedMedia);
    }
  }
};
