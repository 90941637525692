<template>
  <div>
    <v-btn
      v-if="isChange === false && imageId != null"
      text
      color="primary"
      @click="handleFilChangeClick"
      >{{ fileLabel }} {{ imageId !== null ? '변경' : '등록' }}하기</v-btn
    >
    <v-file-input
      v-else
      v-model="file"
      @change="handleFileChange"
      show-size
      @click:clear="handleRemoveClick"
      outlined
      :placeholder="`${fileLabel} 이미지를 선택하세요.`"
      persistent-placeholder
      dense
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      updateImage: '',
      image: '',
      file: null,
      isChange: false
    };
  },
  props: {
    imageId: {
      type: Number,
      default: null
    },
    fileLabel: {
      type: String,
      default() {
        return 'Image Form';
      }
    },
    fileType: {
      type: String,
      default() {
        return 'all';
      }
    },
    fileSizeMax: {
      type: Number,
      default() {
        return 5;
      }
    }
  },
  methods: {
    handleFileChange() {
      this.$nextTick(() => {
        const file = this.file;
        console.log(file);
        if (file == 'undefined' || file == null) return;

        const [nameError, nameErrorMessage] = this.validateFileType(file);
        if (nameError) {
          this.$Swal.fire({
            icon: 'error',
            html: '<h4>' + nameErrorMessage + '</h4>',
            showConfirmButton: true,
            confirmButtonText: '확인'
          });
          this.file = null;
          return;
        }
        const [sizeError, sizeErrorMessage] = this.validateFileSize(file);
        if (sizeError) {
          this.$Swal.fire({
            icon: 'error',
            html: `<h4>${sizeErrorMessage}</h4>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          });
          this.file = null;
          return;
        }
        this.$emit('setImage', file);
      });
    },
    handleRemoveClick() {
      this.updateImage = null;
      this.file = null;
      this.$emit('setImage', null);
    },
    validateFileType(file) {
      let error = false,
        message = null;

      const { name } = file;

      switch (this.fileType) {
        case 'image':
          if (/\.(jpe?g|png|gif)$/i.test(name) === false) {
            error = true;
            message =
              '<h3>이미지 ( .jpg .jpeg .png ) 파일만 업로드 가능합니다.</h3>';
          }
          break;
        case 'pdf':
          if (/\.(pdf)$/i.test(name) === false) {
            error = true;
            message = '<h3>PDF ( .pdf ) 파일만 업로드 가능합니다.</3>';
          }
          break;
        case 'all':
          if (/\.(jpe?g|png|gif|pdf)$/i.test(name) === false) {
            error = true;
            message =
              '<h3>확장자가 (.jpg .jpeg .png .gif .pdf ) 인 파일만 업로드 가능합니다.</h3>';
          }
          break;
      }

      return [error, message];
    },
    getImagePreviews() {
      /*
                  Iterate over all of the files and generate an image preview for each one.
                */
      for (let i = 0; i < this.files.length; i++) {
        /*
                      Ensure the file is an image file
                    */
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].file.name)) {
          /*
                          Create a new FileReader object
                        */
          let reader = new FileReader();

          /*
                          Add an event listener for when the file has been loaded
                          to update the src on the file preview.
                        */
          reader.addEventListener(
            'load',
            function() {
              this.$refs['preview'][0].src = reader.result;
            }.bind(this),
            false
          );

          /*
                          Read the data for the file in through the reader. When it has
                          been loaded, we listen to the event propagated and set the image
                          src to what was loaded from the reader.
                        */
          reader.readAsDataURL(this.files[i].file);
        } else {
          /*
                          We do the next tick so the reference is bound and we can access it.
                        */
          this.$nextTick(function() {
            this.$refs['preview' + parseInt(i)][0].src = '/images/pdf.png';
          });
        }
      }
    },
    validateFileSize(file) {
      const { size } = file;

      const fileSizeMax = this.fileSizeMax * 1024 * 1024;
      let error = false,
        message = null;

      if (size > fileSizeMax) {
        error = true;
        message = `${this.fileSizeMax}MB 이하의 파일만 등록 가능합니다.<br/>파일의 용량을 확인주세요.`;
      }
      return [error, message];
    },
    handleFilChangeClick() {
      this.isChange = true;
    }
  }
};
</script>
