var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","fab":"","small":!_vm.$isMobile,"x-small":_vm.$isMobile}},Object.assign({}, tooltipOn, dialogOn)),[_c('v-icon',[_vm._v("mdi-table-cog")])],1)]}}],null,true)},[_c('span',{staticClass:"headline"},[_vm._v("열 설정")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"#ddd"}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("열 설정")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"pa-3",staticStyle:{"overflow-y":"auto","max-height":"800px"},attrs:{"height":"100%"}},[_c('v-treeview',{attrs:{"items":_vm.columns,"item-text":"title","item-key":"dataIndex","selection-type":_vm.selectionType,"selectable":"","return-object":"","rounded":"","hoverable":"","activatable":"","open-all":"","multiple-active":"","open":_vm.open,"active":_vm.selectedItems},on:{"update:active":function (v) { return (_vm.selectedItems = v); }},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-draggable',{staticClass:"pa-3",staticStyle:{"overflow-y":"auto","max-height":"800px","border":"2px dashed #92b0b3"},attrs:{"id":"columnDraggable","disabled":_vm.loading,"list":_vm.selectedItems,"ghost-class":"ghost"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}}},[_c('transition-group',{attrs:{"type":"transition","name":'flip-list'}},_vm._l((_vm.selectedItems),function(item,index){return _c('v-sheet',{key:item.dataIndex,staticClass:"mb-3",style:({
                  cursor: 'pointer',
                  borderLeft: ("5px solid " + (item.color))
                }),attrs:{"rounded":"lg"}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-menu ")])],1),_c('v-list-item-content',[_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{staticClass:"ma-0 pa-0",staticStyle:{"width":"20px"},attrs:{"value":index + 1,"hide-details":"","min":0,"max":_vm.selectedItems.length},on:{"blur":function($event){return _vm.changeIndex($event, index)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changeIndex($event, index)}}}),_c('v-list-item-title',{domProps:{"textContent":_vm._s((". " + (item.title)))}})],1)]),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"},on:{"click":function($event){return _vm.remove(index)}}},[_vm._v("mdi-close")])],1)],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.initDefault()}}},[_vm._v(" 초기화 ")]),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 닫기 ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.store()}}},[_vm._v(" 적용 ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }