<template>
  <div>
    <v-row
      v-if="!selectedFilters.length"
      class="fill-height ma-0 justify-center align-center"
      style="height: 47px; background-color: rgba(0, 0, 0, 0.08)"
    >
      <a-spin :spinning="true" />
    </v-row>
    <v-autocomplete
      v-else
      v-model="selectedFilters"
      chips
      multiple
      hide-selected
      solo
      append-icon=""
      prefix="필터"
      :items="filterOptions"
      hide-no-data
      hide-details
      :menu-props="{
        closeOnContentClick: true,
        maxWidth: '300',
        nudgeRight: menuPropsOffsetLeft
      }"
      @focus="filterOptionsFocus"
      @click="filterOptionsFocus"
      :disabled="loading"
      dense
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          pill
          :close="data.item.isDeletable"
          @click="data.select;"
          @click:close="
            () => {
              remove(data.item);
              applyFilter();
            }
          "
        >
          <component
            v-if="data.item.value === 'dateRanges'"
            v-bind:is="data.item.component"
            @setDateRanges="
              v => {
                dateRanges = v;
              }
            "
            @applyFilter="applyFilter()"
            @remove="remove(data.item)"
            :title="data.item.text"
            :prefix="7"
            :isOpend="true"
            :defaultValue="dateRanges"
          >
          </component>
          <component
            v-else
            v-bind:is="data.item.component"
            :keyName="data.item.value"
            :title="data.item.text"
            :items="data.item.items"
            :defaultValue="data.item.defaultValue"
            :detail="data.item.detail"
            :default="data.item.default"
            :min="data.item.min"
            :max="data.item.max"
            :keyString="data.item.keyString"
            :textString="data.item.textString"
            @remove="remove(data.item)"
            :api="data.item.api"
            @callApi="callApi(data.item.callApi)"
            @applyFilter="applyFilter()"
            @changeValue="handleUpdateValue"
            :emptyMessage="data.item.emptyMessage"
            :isSavedFilter="isSavedFilter"
          />
        </v-chip>
        <v-icon
          v-if="
            filterOptions.length !== selectedFilters.length &&
            data.index + 1 === selectedFilters.length
          "
          class="py-2"
        >
          mdi-plus-circle
        </v-icon>
      </template>
      <template v-slot:item="data">
        <v-list-item-content style="width: 200px">
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:append-outer>
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn
              v-on="{ ...onTooltip }"
              icon
              @click="applyFilter(false)"
              :disabled="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span class="title">새로고침</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ActiveStatusList } from '@/config/admin/campaign';
import { AdvertiserTypeList } from '@/config/user';
import { CampaignTypeList } from '@/config/campaign';
import { CampaignFilter } from '@/model/admin/campaign';

export default {
  components: {
    FilterDatePicker: () => import('@/components/FilterDatePicker.vue'),
    FilterMenu: () => import('@/components/FilterMenuCampaign.vue')
  },
  props: {
    filter: {
      type: Array,
      required: false,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    // 숨김처리할 필터 옵션
    hiddenOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data() {
    return {
      menuPropsOffsetLeft: null,
      dateRanges: [],
      day: this.$moment().format('YYYY-MM-DD'),
      selectedFilters: [],
      isSavedFilter: false,
      filterOptions: [
        new CampaignFilter({
          default: true,
          value: 'dateRanges',
          text: '기간',
          isDeletable: false,
          defaultValue: this.filter.dateRanges || this.dateRanges,
          component: 'FilterDatePicker'
        }),
        new CampaignFilter({
          value: 'campaignTypes',
          default: true,
          detail: true,
          component: 'FilterMenu',
          min: 1,
          items: [...CampaignTypeList]
        }),
        new CampaignFilter({
          value: 'measureStandard',
          default: true,
          detail: true,
          isDeletable: false,
          component: 'FilterMenu',
          min: 1,
          max: 1,
          items: [
            {
              text: '일간',
              value: 'date'
            },
            {
              text: '광고유형',
              value: 'campaign_type'
            },
            {
              text: '매체',
              value: 'media_id'
            }
          ],
          defaultValue: ['date']
        }),
        new CampaignFilter({
          value: 'activeStatus',
          default: true,
          isDeletable: false,
          detail: true,
          component: 'FilterMenu',
          min: 1,
          defaultValue: [true],
          items: [...ActiveStatusList]
        }),
        new CampaignFilter({
          value: 'tagFilter',
          default: false,
          detail: true,
          component: 'FilterMenu',
          min: 1,
          items: [...ActiveStatusList]
        }),
        new CampaignFilter({
          value: 'advertiserIds',
          component: 'FilterMenu',
          textString: 'name',
          keyString: 'id'
          // callApi: `getAdvertiserGroupsApi`,
        }),
        new CampaignFilter({
          value: 'mediaGroupIds',
          component: 'FilterMenu',
          textString: 'name',
          keyString: 'id'
        }),
        new CampaignFilter({
          value: 'isAdmin',
          min: 1,
          detail: true,
          isDeletable: false,
          component: 'FilterMenu',
          defaultValue: [true, false],
          items: [
            {
              text: '내부',
              value: true
            },
            {
              text: '외부',
              value: false
            }
          ]
        }),
        new CampaignFilter({
          value: 'advertiserType',
          min: 1,
          component: 'FilterMenu',
          items: [...AdvertiserTypeList]
        })
      ]
    };
  },
  async created() {
    // 숨김처리할 필터 옵션 있는경우 필터
    if (this.hiddenOptions.length > 0) {
      this.filterOptions = this.filterOptions.filter(
        item => !this.hiddenOptions.includes(item.value)
      );
    }
    // 광고주, 미디어 그룹 api 호출 ( 현시점엔 무조건 없음 )
    await Promise.all([
      this.getAdvertisersApi(false),
      this.getMediaGroupsApi(false)
    ]);

    // 선택퇸 필터가 없는 advertiserIds
    if (this.filter.length > 0) {
      const selectedFilter = [];
      this.filter.map(v => {
        let keyText = v.key;
        if (v.key === 'dateRanges') {
          this.dateRanges = v.value;
          selectedFilter.push(keyText);
        } else {
          const filterOptionIdx = this.filterOptions.findIndex(
            item => item.value === keyText
          );

          if (filterOptionIdx >= 0) {
            this.filterOptions[filterOptionIdx].defaultValue = v.value;
          }

          if (v.value.length > 0) {
            selectedFilter.push(keyText);
          }
        }

        return keyText;
      });

      this.selectedFilters = selectedFilter;
      this.applyFilter(false);
      this.isSavedFilter = true;
    } else {
      this.applyFilter(false);
    }
  },
  methods: {
    filterOptionsFocus(e) {
      this.menuPropsOffsetLeft = e.target.offsetLeft;
    },
    //광고주 계정 목록
    // init : 초기화 여부
    async getAdvertisersApi(init = true) {
      try {
        const { data } = await this.$axios.get(`/admin/report-advertisers`);

        this.initFilterOptions('advertiserIds', data.advertisers, init);

        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    //매체 그룹 목록
    // init : 초기화 여부
    async getMediaGroupsApi(init = true) {
      try {
        const { data } = await this.$axios.get(`/admin/report-media-groups`);

        this.initFilterOptions('mediaGroupIds', data.mediaGroups, init);

        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    //선택 필터 항목 삭제
    remove(item) {
      const index = this.selectedFilters.indexOf(item.value);
      if (index >= 0) this.selectedFilters.splice(index, 1);
      this.setFilterDefaultValue(item.value, []);
    },
    // applyFilter(syncFilter = true) {
    applyFilter() {
      const dateRanges =
        this.filter.dateRanges && this.filter.dateRanges.length > 0
          ? this.filter.dateRanges
          : this.dateRanges;

      const applyFilterObj = {};

      this.filterOptions.map(item => {
        let val = this.getFilterDefaultValue(`${item.value}`);

        if (item.value === 'dateRanges') {
          val = dateRanges || this.dateRanges;
        }

        applyFilterObj[`${item.value}`] = val;
      });

      this.$emit('applyFilters', applyFilterObj);
    },
    // filteroption 값 초기화
    // key:  초기화 key, list: 해당 키 값, init = 세팅값 초기화 여부
    initFilterOptions(key, list = [], init = true) {
      const filterOption = this.filterOptions.find(v => v.value === key);

      if (!filterOption) return;

      filterOption.items = list;

      if (init) {
        filterOption.defaultValue = [];
      }

      //현재 선택되어있는 필터에서 해당 옵션 삭제
      const index = this.selectedFilters.indexOf(key);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
      }
    },
    // 동적으로 api 를 호출하기 위한 함수입니다.
    async callApi(apiName) {
      // api 가 존재하고 호출이 성공한 경우 applyFilter 호출하고 applyFilter 내부에서 filterOptions 를 setFilterApi를 통해 서버와 동기화 한다.
      if (apiName && (await this[apiName]())) {
        this.applyFilter();
      }
    },
    handleUpdateValue(changeVal) {
      const { key, value } = changeVal;
      const filterOption = this.filterOptions.find(v => v.value === key);
      filterOption.defaultValue = value;
    },
    // 기본값 반환
    getFilterDefaultValue(key) {
      const defaultVal = [];

      if (!key) return defaultVal;

      const filter = this.filterOptions.find(
        campaignFilter => campaignFilter.value === key
      );

      return filter.defaultValue;
    },
    // 기본값 반환
    setFilterDefaultValue(key, value) {
      if (!key) return;

      const filter = this.filterOptions.find(
        campaignFilter => campaignFilter.value === key
      );

      const defaultVal = filter.orignValue;

      filter.defaultValue = value ?? defaultVal;
    }
  }
};
</script>

<style></style>
