<template>
  <v-speed-dial
    v-model="speedDial"
    transition="scale-transition"
    direction="bottom"
  >
    <template v-slot:activator>
      <v-tooltip bottom :disabled="!disabledEditButton">
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn
            v-on="{ ...tooltipOn }"
            v-model="speedDial"
            color="secondary"
            fab
            small
          >
            <v-icon v-if="speedDial">
              mdi-close
            </v-icon>
            <v-icon v-else>mdi-hammer-wrench</v-icon>
          </v-btn>
        </template>
        <span class="headline">선택된 캠페인이 없습니다.</span>
      </v-tooltip>
    </template>
    <v-tooltip
      :color="event.color"
      left
      v-for="(event, i) in editEvents"
      :key="i"
    >
      <template #activator="{ on: onTooltip }" v-if="!disabledEditButton">
        <v-btn
          fab
          small
          v-on="onTooltip"
          :color="event.color"
          @click="event.function(event.params)"
          :disabled="disabledEditButton"
        >
          <v-icon>{{ event.icon }}</v-icon>
        </v-btn>
      </template>
      <span class="headline">{{ event.text }}</span>
    </v-tooltip>
  </v-speed-dial>
  <!-- <v-menu offset-y max-width="400" open-on-hover nudge-left="100%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-3 "
        fab
        small
        color="secondary"
        v-bind="attrs"
        v-on="on"
        :disabled="disabledEditButton"
        ><v-icon>mdi-hammer-wrench</v-icon>
      </v-btn>
    </template>
    <v-card width="200">
      <v-list rounded>
        <v-list-item
          v-for="(event, index) in editEvents"
          :key="index"
          inactive
          @click="event.function(event.params)"
        >
          <v-list-item-icon>
            <v-icon v-text="event.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-action>
            <v-list-item-title v-text="event.text"></v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu> -->
</template>

<script>
export default {
  props: {
    selectedCampaigns: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      speedDial: false,
      selectedItem: null,
      editEvents: [
        {
          text: '삭제',
          icon: 'mdi-delete-sweep',
          color: 'error',
          function: this.deleteCampaigns
        },
        {
          text: '복사',
          icon: 'mdi-content-duplicate',
          color: 'primary',
          function: this.duplicateCampaigns
        },
        {
          text: '광고중지',
          icon: 'mdi-play-pause',
          color: 'warning',
          function: this.updateActiveStatus,
          params: {
            activeStatus: false
          }
        },
        {
          text: '광고라이브',
          icon: 'mdi-play',
          color: 'success',
          function: this.updateActiveStatus,
          params: {
            activeStatus: true
          }
        }
      ]
    };
  },
  computed: {
    // 선택된 캠페인 여부에 따른 메뉴 비활성화
    disabledEditButton() {
      return this.selectedCampaigns.length === 0;
    }
  },
  methods: {
    // 캠페인 삭제
    deleteCampaigns() {
      const { selectedCampaigns } = this;

      // 도달이 0 보다 큰 경우 삭제 불가
      const filtered = selectedCampaigns.filter(
        campaign => campaign.reachCount > 0 || campaign.activeStatus
      );
      if (filtered.length > 0) {
        this.$Swal.fire({
          html: `<h3>${filtered[0].id}번 ${filtered[0].name}<br><small>라이브 상태 또는 삭제 할 수 없는 캠페인 입니다.</small></h3>`,
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return false;
      }

      const selectedCampaignsLength = selectedCampaigns.length;
      const title = `${selectedCampaigns[0].name} ${
        selectedCampaignsLength > 1
          ? `외 ${selectedCampaignsLength - 1} 개`
          : ''
      }`;
      this.$Swal
        .fire({
          html: `<h3>${title}<br>광고를 삭제 하시겠습니까?</h3><br><small>⚠️삭제된 캠페인은 복구 불가능합니다.</small>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          icon: 'error',
          iconHtml: '!',
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .delete('/admin/campaigns', {
                data: {
                  campaignIds: selectedCampaigns.map(v => v.id)
                }
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$emit('initSelectedCampaigns');
            this.$emit('getCampaigns');
          }
        });
    },
    // 캠페인 복제
    duplicateCampaigns() {
      const { selectedCampaigns } = this;
      const selectedCampaignsLength = selectedCampaigns.length;
      const title = `${selectedCampaigns[0].name} ${
        selectedCampaignsLength > 1
          ? `외 ${selectedCampaignsLength - 1} 개`
          : ''
      }`;
      this.$Swal
        .fire({
          html: `<h3>${title}<br>광고를 복사 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .post('/admin/campaigns-duplicate', {
                campaignIds: selectedCampaigns.map(v => v.id)
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$emit('initSelectedCampaigns');
            this.$emit('getCampaigns');
          }
        });
    },
    // 캠페인 상태 변경
    updateActiveStatus(params) {
      const selectedCampaigns = this.selectedCampaigns.filter(
        campaign => campaign.type !== 'message'
      );

      if (selectedCampaigns.length === 0) {
        this.$Swal.fire({
          html: `<h3>선택된 켐페인이 없습니다. <br/><small>(❗️메시지 유형은 선택에서 자동 제외됩니다.<br/>개별 업데이트로 진행해주세요.)</small></h3>`,
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return false;
      }
      const { activeStatus } = params;

      // // 상태 변경전 조건 처리
      // const filtered = selectedCampaigns.filter(campaign => {
      //   const status =
      //     campaign.messageDateTime === ''
      //       ? true
      //       : this.$moment().diff(
      //           this.$moment(`${campaign.messageDateTime}`).format(
      //             'YYYY-MM-DD HH:mm'
      //           ),
      //           'hours'
      //         ) > -3;

      //   return activeStatus === true && campaign.type === 'message' && status;
      // });
      // if (filtered.length > 0) {
      //   this.$Swal.fire({
      //     html: `<h3>${filtered[0].id}번 ${filtered[0].name}<br>유효하지 않은 광고 전송일시 입니다.</h3>`,
      //     icon: 'error',
      //     iconHtml: '!',
      //     confirmButtonText: '확인'
      //   });
      //   return false;
      // }
      // // 메시지 발송 24시간 전, 48시간 이내 인 경우 확인 합니다.
      // const activeFiltered = selectedCampaigns.filter(campaign => {
      //   const diff = this.$moment().diff(
      //     this.$moment(`${campaign.messageDateTime}`).format(
      //       'YYYY-MM-DD HH:mm'
      //     ),
      //     'hours'
      //   );

      //   return (
      //     activeStatus === false &&
      //     campaign.type === 'message' &&
      //     diff < 48 &&
      //     diff > -24
      //   );
      // });
      // if (activeFiltered.length > 0) {
      //   this.$Swal.fire({
      //     html: `<h3>${activeFiltered[0].id}번 ${activeFiltered[0].name}<br>종료 대기중인 캠페인입니다. <br/><small>즉시 비활성화는 관리자에게 문의하세요.</small></h3>`,
      //     icon: 'error',
      //     iconHtml: '!',
      //     confirmButtonText: '확인'
      //   });
      //   return false;
      // }

      const selectedCampaignsLength = selectedCampaigns.length;
      const title = `${selectedCampaigns[0].id}번 ${
        selectedCampaigns[0].name
      } ${
        selectedCampaignsLength > 1
          ? `외 ${selectedCampaignsLength - 1} 개`
          : ''
      }`;
      this.$Swal
        .fire({
          html: `<h3>${title}<br>광고를 ${
            activeStatus ? '라이브' : '중단'
          } 하시겠습니까?<br><small>❗️메시지 유형은 적용되지 않습니다.</small></h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .put('/admin/campaigns-active', {
                campaignIds: selectedCampaigns.map(v => v.id),
                activeStatus: activeStatus
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            selectedCampaigns.forEach(v => (v.activeStatus = activeStatus));
            this.$emit('initSelectedCampaigns');
          }
        });
    }
  }
};
</script>

<style></style>
