<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" lg="6">
        <h3>{{ titles.advertiser }}</h3>
        <validation-provider v-slot="{ errors }" rules="required">
          <v-autocomplete
            v-model="campaign.advertiser"
            :items="advertisers"
            :error-messages="errors"
            item-text="name"
            item-value="id"
            hide-no-data
            :placeholder="placeholder.advertiser"
            clearable
            required
            outlined
            return-object
            auto-select-first
            @change="handleAdvertiserChange"
            class="mr-2"
          >
          </v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" lg="6">
        <h3>{{ titles.advertiserGroup }}</h3>
        <validation-provider v-slot="{ errors }" rules="required">
          <v-autocomplete
            v-model="campaign.advertiserGroup"
            :items="advertiserGroups"
            :error-messages="errors"
            item-text="name"
            item-value="id"
            :placeholder="placeholder.advertiserGroup"
            no-data-text="계정에 광고 그룹이 존재 하지않습니다. 광고 그룹을 추가하세요."
            clearable
            required
            outlined
            return-object
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                outlined
                label
                pill
                style="border: none !important"
                @click="data.select"
              >
                <v-avatar tile left>
                  <v-img
                    v-if="data.item.icon"
                    :src="data.item.icon.url"
                    class="rounded-lg"
                  ></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item === 'object'">
                <v-list-item-avatar tile width="40">
                  <v-img
                    v-if="data.item.icon"
                    :src="data.item.icon.url"
                    class="rounded-lg"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" lg="6" v-if="!campaign.isTypeCoupon">
        <h3>{{ titles.mediaGroup }}</h3>
        <validation-provider v-slot="{ errors }" rules="required">
          <v-autocomplete
            v-model="campaign.mediaGroups"
            :items="mediaGroups"
            :error-messages="errors"
            item-text="name"
            hide-no-data
            :placeholder="placeholder.mediaGroup"
            clearable
            required
            outlined
            multiple
            return-object
            auto-select-first
            class="mr-2"
          >
          </v-autocomplete>
        </validation-provider>
      </v-col>
      <v-col cols="12" lg="6" v-if="!campaign.isTypeCoupon">
        <h3>{{ titles.tracker }}</h3>
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: false
          }"
        >
          <v-autocomplete
            v-model="campaign.tracker"
            :items="trackers"
            :error-messages="errors"
            item-text="name"
            hide-no-data
            :placeholder="placeholder.tracker"
            clearable
            required
            outlined
            return-object
            auto-select-first
          >
          </v-autocomplete>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { Instructions, Titles } from '@/config/admin/campaign';

export default {
  components: {
    ValidationProvider
  },
  model: {},
  data() {
    return {
      mediaGroups: [],
      advertisers: [],
      trackers: []
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      cAdvertiserGroups: 'campaign/advertiserGroups'
    }),
    titles() {
      return Titles.rs1;
    },
    advertiserGroups() {
      return this.cAdvertiserGroups ?? [];
    },
    placeholder() {
      return Instructions.rs1;
    }
  },
  async mounted() {
    const { mediaGroups, advertisers, trackers } = await this.getAdvertisers();
    this.mediaGroups = mediaGroups;
    this.advertisers = advertisers;
    this.trackers = trackers;
  },
  methods: {
    ...mapActions({
      getAdvertiserGroups: 'campaign/getAdvertiserGroups'
    }),
    async getAdvertisers() {
      try {
        const { data } = await this.$axios.get('/admin/campaigns-users');
        // console.log(`user`, data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    handleAdvertiserChange(advertiser) {
      this.campaign.advertiserGroup = null;
      const advertiserId = advertiser?.id || null;
      if (advertiserId) {
        this.getAdvertiserGroups(advertiserId);
      }
    }
    // getAdvertiserGroups(advertiserId = null) {
    //   if (!advertiserId) {
    //     return false;
    //   }

    //   return this.$axios
    //     .get('/admin/campaigns-advertiser-groups/' + advertiserId)
    //     .then(res => {
    //       console.log(res);
    //       const { advertiserGroups } = res.data;
    //       this.advertiserGroups = advertiserGroups ?? [];
    //     })
    //     .catch(err => {
    //       console.error(err);
    //     });
    // }
  }
};
</script>
