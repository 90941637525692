import axios from 'axios';

export async function createTagsApi(params) {
    try {
        const {
            data
        } = await axios.post(`/admin/tags`, params);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function updateTagApi(params) {
    try {
        const {
            data
        } = await axios.put('/admin/tag', params)
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function getMediaTagsApi(params) {
    try {
        const {
            data
        } = await axios.get('/admin/media-tags', {
            params: params
        })
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function getMediaTagRawApi(params = null) {
    try {
        const {
            data
        } = await axios.get(`/admin/media-tags-raw`, {
            params: params
        });
        return [null, data]
    } catch (error) {
        return [error]
    }
}