var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":"","persistent":"","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("셋톱박스 "+_vm._s(_vm.titleText))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleCloseClick}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"py-5"},[_c('ValidationObserver',{ref:_vm.refKey},[_c('v-row',{staticClass:"mb-5",attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"별명","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"placeholder":"ex) 엘리베이터 앞.. , 1번","focusable":""},model:{value:(_vm.settopBox.name),callback:function ($$v) {_vm.$set(_vm.settopBox, "name", $$v)},expression:"settopBox.name"}})]}}])})]},proxy:true}])}),(_vm.settopBox.id)?[_c('CustomGridItem',{attrs:{"title":"연결 상태","outlined":"","md":6,"dense":"","titleDescription":_vm.titleDescription.deviceConnectionStatusInfo},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.settopBox.deviceConnectionStatusInfo)?_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","color":_vm.settopBox.deviceConnectionStatusInfo.color,"outlined":""}},[_vm._v(" "+_vm._s(_vm.settopBox.deviceConnectionStatusInfo.text)+" ")]):_vm._e()]},proxy:true}],null,false,3573877747)}),_c('CustomGridItem',{attrs:{"title":"재생 상태","outlined":"","md":6,"dense":"","titleDescription":_vm.titleDescription.devicePlayStatusInfo},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.settopBox.devicePlayStatusInfo)?_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","color":_vm.settopBox.devicePlayStatusInfo.color,"outlined":""}},[_vm._v(" "+_vm._s(_vm.settopBox.devicePlayStatusInfo.text)+" ")]):_vm._e()]},proxy:true}],null,false,1362532961)})]:_vm._e(),_c('CustomGridItem',{attrs:{"title":"매체","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":!errors.length,"items":_vm.didBranches,"placeholder":"매체 선택","item-text":"name","item-value":"id","required":"","outlined":"","dense":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.settopBox.didBranchId),callback:function ($$v) {_vm.$set(_vm.settopBox, "didBranchId", $$v)},expression:"settopBox.didBranchId"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"UID","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"placeholder":"UID","focusable":""},model:{value:(_vm.settopBox.uid),callback:function ($$v) {_vm.$set(_vm.settopBox, "uid", $$v)},expression:"settopBox.uid"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"일간 구독 횟수","outlined":"","md":6},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"placeholder":"일간 최대 설정 가능한 구독구좌 횟수","focusable":""},model:{value:(_vm.settopBox.dailySubscribeCount),callback:function ($$v) {_vm.$set(_vm.settopBox, "dailySubscribeCount", $$v)},expression:"settopBox.dailySubscribeCount"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"일간 금액","outlined":"","md":6,"dense":"","titleDescription":_vm.titleDescription.costPerDay},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"placeholder":"원","type":"number","focusable":""},model:{value:(_vm.settopBox.costPerDay),callback:function ($$v) {_vm.$set(_vm.settopBox, "costPerDay", $$v)},expression:"settopBox.costPerDay"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"기준 시간 (초)","outlined":"","md":6},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"placeholder":"초","type":"number","focusable":""},model:{value:(_vm.settopBox.costStandardSecond),callback:function ($$v) {_vm.$set(_vm.settopBox, "costStandardSecond", $$v)},expression:"settopBox.costStandardSecond"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":("금액 (" + (_vm.settopBox.costStandardSecond) + "초)"),"outlined":"","md":6,"dense":"","titleDescription":_vm.titleDescription.costPerSecond},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"placeholder":"원","type":"number","focusable":""},model:{value:(_vm.settopBox.costPerSecond),callback:function ($$v) {_vm.$set(_vm.settopBox, "costPerSecond", $$v)},expression:"settopBox.costPerSecond"}})]}}])})]},proxy:true}])})],2),_c('h4',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" 앱 설정 "),_c('span',{staticClass:"info--text text-caption ml-2"},[_vm._v(" 셋톱박스에 내려보낼 앱 설정값입니다. ")])]),_c('v-row',{staticClass:"mb-5",attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"앱","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":!errors.length,"items":_vm.didApps,"placeholder":"앱 선택","item-text":"name","item-value":"id","required":"","outlined":"","dense":"","menu-props":{ bottom: true, offsetY: true }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-chip',{staticClass:"ml-1 mr-0 font-weight-bold",attrs:{"x-small":"","outlined":"","label":"","secondary":""}},[_c('i',[_vm._v("V . ")]),_vm._v(" "+_vm._s(item.version)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-chip',{staticClass:"ml-1 mr-0 font-weight-bold",attrs:{"x-small":"","outlined":"","label":"","secondary":""}},[_c('i',[_vm._v("V . ")]),_vm._v(" "+_vm._s(item.version)+" ")])]}}],null,true),model:{value:(_vm.settopBox.didAppId),callback:function ($$v) {_vm.$set(_vm.settopBox, "didAppId", $$v)},expression:"settopBox.didAppId"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"앱 업데이트 유무","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":!errors.length,"items":_vm.AppUpdateStatus,"placeholder":"앱 업데이트 유무 선택","required":"","outlined":"","dense":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.settopBox.appUpdateStatus),callback:function ($$v) {_vm.$set(_vm.settopBox, "appUpdateStatus", $$v)},expression:"settopBox.appUpdateStatus"}})]}}])})]},proxy:true}])}),(_vm.settopBox.id)?_c('CustomGridItem',{attrs:{"title":"앱 다운로드 링크","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',[_vm._v(" "+_vm._s(_vm.settopBox.appLink)+" ")])]},proxy:true}],null,false,2560712646)}):_vm._e()],1),_c('h4',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" 상세 설정 "),_c('span',{staticClass:"info--text text-caption ml-2"},[_vm._v(" 셋톱박스에 내려보낼 설정값입니다. ")])]),_c('v-row',{staticClass:"mb-5",attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"설정 통신 주기","outlined":"","md":7},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"type":"number","focusable":""},model:{value:(_vm.settopBox.config.configInterval),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "configInterval", $$v)},expression:"settopBox.config.configInterval"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"단위","outlined":"","md":5},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.TimeUnitOptions,"error-messages":errors,"hide-details":!errors.length,"item-text":"text","item-value":"value","item-disabled":"disable","outlined":"","dense":"","placeholder":"단위 선텍"},model:{value:(_vm.settopBox.config.configIntervalUnit),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "configIntervalUnit", $$v)},expression:"settopBox.config.configIntervalUnit"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"캠페인 통신 주기","outlined":"","md":7},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"type":"number","focusable":""},model:{value:(_vm.settopBox.config.campaignInterval),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "campaignInterval", $$v)},expression:"settopBox.config.campaignInterval"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"단위","outlined":"","md":5},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.TimeUnitOptions,"error-messages":errors,"hide-details":!errors.length,"item-text":"text","item-value":"value","item-disabled":"disable","outlined":"","dense":"","placeholder":"단위 선텍"},model:{value:(_vm.settopBox.config.campaignIntervalUnit),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "campaignIntervalUnit", $$v)},expression:"settopBox.config.campaignIntervalUnit"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"로그 통신 주기","outlined":"","md":7},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"type":"number","focusable":""},model:{value:(_vm.settopBox.config.logInterval),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "logInterval", $$v)},expression:"settopBox.config.logInterval"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"단위","outlined":"","md":5},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.TimeUnitOptions,"error-messages":errors,"hide-details":!errors.length,"item-text":"text","item-value":"value","item-disabled":"disable","outlined":"","dense":"","placeholder":"단위 선텍"},model:{value:(_vm.settopBox.config.logIntervalUnit),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "logIntervalUnit", $$v)},expression:"settopBox.config.logIntervalUnit"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"와이파이 재접속 주기","outlined":"","md":7},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomInput',{attrs:{"required":"","errorMessages":errors,"hideDetails":!errors.length,"type":"number","focusable":""},model:{value:(_vm.settopBox.config.wifircnInterval),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "wifircnInterval", $$v)},expression:"settopBox.config.wifircnInterval"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"단위","outlined":"","md":5},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.TimeUnitOptions,"error-messages":errors,"hide-details":!errors.length,"item-text":"text","item-value":"value","item-disabled":"disable","outlined":"","dense":"","placeholder":"단위 선텍"},model:{value:(_vm.settopBox.config.wifircnIntervalUnit),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "wifircnIntervalUnit", $$v)},expression:"settopBox.config.wifircnIntervalUnit"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"자동 재부팅 시간","outlined":"","dense":"","titleDescription":_vm.titleDescription.rebootTime},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-menu',{ref:"timePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":""},model:{value:(_vm.settopBox.config.rebootTime),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "rebootTime", $$v)},expression:"settopBox.config.rebootTime"}},'v-text-field',
                        _vm.$getAttributes(Object.assign({}, {errors: errors,
                          placeholder: '재부팅 시간',
                          prependInnerIcon: 'mdi-clock-outline',
                          label: '재부팅 시간',
                          disabled: _vm.dialogLoading,
                          persistentHint: true},
                          attrs))
                      ,false),on))]}}],null,true)})]}}]),model:{value:(_vm.rebootTimeDialog),callback:function ($$v) {_vm.rebootTimeDialog=$$v},expression:"rebootTimeDialog"}},[_c('TimePicker',{attrs:{"show":_vm.rebootTimeDialog,"minuteUnit":60},on:{"close":function($event){_vm.rebootTimeDialog = false}},model:{value:(_vm.settopBox.config.rebootTime),callback:function ($$v) {_vm.$set(_vm.settopBox.config, "rebootTime", $$v)},expression:"settopBox.config.rebootTime"}})],1)]},proxy:true}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"secondary":"","outlined":""},on:{"click":_vm.handleCloseClick}},[_vm._v(" 닫기 ")]),_c('v-btn',{attrs:{"color":"secondary","loading":_vm.dialogLoading,"disabled":_vm.dialogLoading ||
          (_vm.settopBox.id ? !this.settopBox.isChanged() : false)},on:{"click":function($event){return _vm.handleSaveClick()}}},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.dialogLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }