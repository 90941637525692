<template>
  <v-card sm="12" lg="6" flat>
    <v-card-title>광고 미리보기</v-card-title>
    <v-card-subtitle
      >광고는 모바일 기기에 따라 다르게 보여질 수 있습니다.</v-card-subtitle
    >
    <v-card-text>
      <div v-if="showLandingUrl">
        <h4>광고 URL</h4>
        <v-text-field
          id="landingUrl"
          :value="rs1.landingUrl"
          filled
          required
          outlined
          readonly
        >
          <template v-slot:append-outer>
            <v-tooltip v-if="rs1.landingUrl" bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  icon
                  @click="openInNewTab(rs1.landingUrl)"
                >
                  <v-icon v-on="onTooltip">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>새탭에서 링크 열기</span>
            </v-tooltip>
            <v-tooltip v-if="rs1.landingUrl" bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn v-on="{ ...onTooltip }" icon @click="copyToClipboard">
                  <v-icon>mdi-content-cut</v-icon>
                </v-btn>
              </template>
              <span>링크 복사하기</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </div>

      <div class="layout-mobile mx-auto">
        <transition name="component-fade" mode="in-out">
          <div v-if="rs1.campaignType === 'rolling' && rs2.rolling.imageUrl">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <v-img :src="previewHead"></v-img>
                <div class="box-img">
                  <v-img class="img" :src="rs2.rolling.imageUrl"></v-img>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3"
              >노티 미리보기
            </v-card-subtitle>
          </div>
        </transition>
        <transition name="component-fade" mode="in-out">
          <div v-if="allowCampaignTypes.includes(rs1.campaignType)">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <v-img :src="previewHead"></v-img>
                <div class="box-img">
                  <div class="pa-3" style="font-size:14px;">
                    <h3>{{ rs2.push.title ? rs2.push.title : '노티 제목' }}</h3>
                    <h4>
                      (광고)
                      {{ rs2.push.subTitle ? rs2.push.subTitle : '노티 내용' }}
                    </h4>
                    <v-img
                      v-if="rs2.push.messageImageUrl"
                      class="mt-2"
                      :src="rs2.push.messageImageUrl"
                    ></v-img>
                  </div>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3"
              >노티 미리보기
            </v-card-subtitle>
          </div>
        </transition>
        <transition name="component-fade" mode="in-out">
          <div v-if="allowCampaignTypes.includes(rs1.campaignType)">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <div class="title">
                  &lt; &nbsp;
                  {{
                    rs2.push.category
                      ? rs2.push.category
                      : '인앱 광고 노출 이름'
                  }}
                </div>
                <div class="content">
                  <div class="inner">
                    <div class="box-date">
                      <div class="line"></div>
                      <div class="date">
                        {{ $moment().format('LL') }} ({{
                          dayOfWeek[$moment().day()]
                        }})
                      </div>
                    </div>
                    <!-- 채팅 한 묶음 -->
                    <dl class="wrap-chat">
                      <dt class="box-user d-inline-flex align-center">
                        <v-img
                          :src="iconUrl"
                          class=" rounded-lg"
                          width="36"
                          height="36"
                        ></v-img>
                        <p class="name mb-0 ml-2" style="font-size:14px;">
                          {{ rs2.push.title ? rs2.push.title : '노티 제목' }}
                        </p>
                      </dt>
                      <dd class="box-txt">
                        <div class="txt-row pa-1">
                          <div class="txt">
                            <v-textarea
                              style="font-size:14px"
                              :value="
                                rs2.push.text
                                  ? rs2.push.text
                                  : '인앱 광고 메시지'
                              "
                              readonly
                              auto-grow
                              rows="1"
                              row-height="10"
                              solo
                              flat
                              hide-details
                            >
                            </v-textarea>
                          </div>
                          <v-img
                            v-if="rs2.push.imageUrl"
                            :src="rs2.push.imageUrl"
                          ></v-img>
                          <div class="time">
                            {{ $moment().format('LT') }}
                          </div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3"
              >인앱광고 미리보기</v-card-subtitle
            >
          </div>
        </transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    rs1: {
      type: Object,
      required: true
    },
    rs2: {
      type: Object,
      required: true
    },
    showLandingUrl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dayOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
      allowCampaignTypes: ['push', 'message'],
      previewHead: require('@/assets/campaign/preview-head.jpeg')
    };
  },
  computed: {
    iconUrl() {
      return (
        this.rs1.user?.advertiserGroup?.icon?.url ||
        'https://notii.azureedge.net/campaign/images/push_default_icon.png'
      );
    }
  },
  methods: {
    copyToClipboard() {
      const copy = document.querySelector('#landingUrl');
      copy.setAttribute('type', 'text');
      copy.select();
      try {
        document.execCommand('copy');
        this.$Swal.fire({
          icon: 'success',
          title: '복사 완료.',
          text: '' + copy.value,
          showConfirmButton: false,
          timer: 1200
        });
      } catch (err) {
        this.$Swal.fire({
          icon: 'error',
          title: '복사 오류',
          showConfirmButton: false,
          timer: 1000
        });
      }
      /* unselect the range */
      window.getSelection().removeAllRanges();
    },
    openInNewTab(landingUrl) {
      window.open(landingUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.7s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.layout-mobile {
  width: 50%;
  min-width: 350px;
  max-width: 90%;
  margin: auto;
}
.layout-mobile .box-android {
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-radius: 50px 50px 0 0;
  box-shadow: 1px 1px 5px 2px #efefef;
  text-align: center;
  max-width: 500px;
}
.layout-mobile .box-android span {
  display: inline-block;
  width: 120px;
  height: 10px;
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
}
.layout-mobile .box-android .area-content {
  min-height: 60px;
  margin: 10px 15px 0;

  outline: 3px solid #666;
  text-align: left;
}
.layout-mobile .box-android .area-content .box-img {
  padding: 3px;
}
.layout-mobile .box-android .area-content .box-img .img {
  width: 100%;
  max-height: 75px;
}
.layout-mobile .box-android .area-content > img {
  width: 100%;
}
.layout-mobile .box-ios {
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-radius: 50px 50px 0 0;
  box-shadow: 1px 1px 5px 2px #efefef;
  text-align: center;
}

/* 채팅창 레이아웃 미리보기 */
.area-content .title {
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  color: #333;
  font-size: 16px !important;
}
.area-content .content {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}
.area-content .inner {
  width: 90%;
  margin: auto;
}
.area-content .box-date {
  position: relative;
  padding: 20px 0;
}
.area-content .box-date .line {
  width: 100%;
  height: 2px;
  background-color: #ccc;
}
.area-content .box-date .date {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  padding: 0 10px;
  background-color: #efefef;
  white-space: nowrap;
}
q .area-content .wrap-chat {
  display: flex;
  padding-bottom: 10px;
}
.area-content .wrap-chat .box-user .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #666;
}
.area-content .wrap-chat .box-txt {
  margin-left: 5px;
  width: 100%;
}
.area-content .wrap-chat .box-txt .name {
  margin-bottom: 5px;
}
.area-content .wrap-chat .box-txt .txt {
  width: 100%;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
}
.area-content .wrap-chat .box-txt .time {
  font-size: 10px;
  color: #999;
}
</style>
