<template>
  <v-container fluid>
    <v-row>
      <v-col class="mx-auto" cols="12" md="6" sm="12">
        <validation-observer
          ref="advertiserGroupDialogContent"
          v-slot="{ invalid }"
        >
          <form @submit.prevent="submit" class="p-5">
            <v-card style="padding:30px">
              <h2 class="font-weight-bold py-2">기본 설정</h2>
              <v-row>
                <v-col :cols="6">
                  <h3>{{ titles.name }}</h3>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="advertiserGroup.name"
                      :counter="20"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col :cols="6">
                  <h3>{{ titles.advertiser }}</h3>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:20"
                  >
                    <v-autocomplete
                      v-model="advertiserGroup.advertiser"
                      :error-messages="errors"
                      :items="advertisers"
                      item-text="name"
                      item-value="id"
                      hide-no-data
                      required
                      outlined
                      clearable
                      auto-select-first
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{
                      required: false
                    }"
                  >
                    <image-upload
                      :sImageId="advertiserGroup.iconId"
                      @setImage="
                        v => {
                          advertiserGroup.iconId = v.id;
                          advertiserGroup.iconUrl = v.url;
                          advertiserGroup.iconType = v.type;
                        }
                      "
                      campaignType="push"
                      imageType="icon"
                      :title="titles.icon"
                    />
                    <v-input
                      type="hidden"
                      v-model="advertiserGroup.iconId"
                      :error-messages="errors"
                    ></v-input>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card>
            <v-btn
              color="secondary"
              class="mt-4 mr-4"
              type="submit"
              :disabled="invalid"
              large
            >
              {{ isRegisterDialog ? '등록' : '수정' }}하기
            </v-btn>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate';
import ImageUpload from '@/components/ImageUpload.vue';
setInteractionMode('eager');

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ImageUpload
  },
  data() {
    return {
      advertisers: []
    };
  },
  computed: {
    ...mapGetters({
      advertiserGroup: 'advertiserGroup/advertiserGroup',
      titles: 'advertiserGroup/titles',
      dialog: 'advertiserGroup/dialog'
    }),
    isRegisterDialog() {
      return this.dialog.register;
    }
  },
  watch: {
    dialog: {
      deep: true,
      handler() {
        this.$refs.advertiserGroupDialogContent.reset();
      }
    }
  },
  mounted() {
    this.getAdvertisers();
  },
  methods: {
    submit() {
      if (this.$refs.advertiserGroupDialogContent.validate()) {
        if (this.isRegisterDialog) {
          this.createAdvertiserGroup();
        } else {
          this.editAdvertiserGroup();
        }
      }
    },
    getAdvertisers() {
      return this.$axios
        .get(`/admin/advertiser-group/advertisers`)
        .then(res => {
          // console.log(res);
          const { advertisers, totalCount } = res.data;

          this.advertisers =
            totalCount > 0
              ? advertisers.map(advertiser => {
                  return {
                    id: advertiser.id,
                    name: advertiser.name
                  };
                })
              : [];
        })
        .catch(err => {
          console.error(err);
        });
    },
    createAdvertiserGroup() {
      return this.$axios
        .post('/admin/advertiser-group', this.advertiserGroup)
        .then(() => {
          // console.log(res);
          this.$router.go();
        })
        .catch(err => {
          this.$axiosErrorAlert();
          console.error(err);
        });
    },
    editAdvertiserGroup() {
      return this.$axios
        .put('/admin/advertiser-group', this.advertiserGroup)
        .then(() => {
          // console.log(res);
          this.$router.go();
        })
        .catch(err => {
          console.error(err);
          this.$axiosErrorAlert();
        });
    }
  }
};
</script>

<style></style>
