<template>
  <div class="d-inline-block float-right">
    <v-dialog v-model="reviewDialog" persistent max-width="1600px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="invalid"
          color="secondary"
          large
        >
          {{ dialogTitle }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">캠페인 {{ dialogTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-container class="px-5">
                <v-row>
                  <v-col cols="6">
                    <h3>{{ titles.rs1.advertiser }}</h3>
                    <v-text-field
                      :value="campaign.advertiser.name"
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h3>{{ titles.rs1.advertiserGroup }}</h3>
                    <v-text-field
                      :value="campaign.advertiserGroup.name"
                      outlined
                      filled
                      readonly
                    >
                      <template v-slot:prepend-inner>
                        <v-avatar tile left size="25">
                          <v-img
                            v-if="campaign.advertiserGroup.icon"
                            :src="campaign.advertiserGroup.icon.url"
                            class="rounded-lg"
                          ></v-img>
                        </v-avatar>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0" v-if="!campaign.isTypeCoupon">
                  <v-col cols="6">
                    <h3>{{ titles.rs1.mediaGroup }}</h3>
                    <v-text-field
                      :value="
                        campaign.mediaGroups
                          .map(mediaGroup => mediaGroup.name)
                          .join(', ')
                      "
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="campaign.tracker">
                    <h3>{{ titles.rs1.tracker }}</h3>
                    <v-text-field
                      :value="campaign.tracker.name"
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <h3>{{ titles.rs1.campaignName }}</h3>
                <v-text-field
                  :value="campaign.name"
                  outlined
                  filled
                  readonly
                ></v-text-field>
                <div v-if="!campaign.isTypeCoupon">
                  <h3>{{ titles.rs1.landingUrl }}</h3>
                  <v-text-field
                    :value="campaign.landingUrl"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </div>
                <div v-else>
                  <!-- 쿠폰광고일 경우  -->
                  <h3>{{ titles.rs2.coupon.couponLandingUrl }}</h3>
                  <v-text-field
                    :value="campaign.couponLandingUrl"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </div>

                <h3>{{ titles.rs1.campaignType }}</h3>

                <v-text-field
                  :value="couponTypeText"
                  outlined
                  filled
                  readonly
                ></v-text-field>

                <!-- <h3>{{ titles.displayPeriod }}</h3> -->
                <v-row v-if="campaign.isTypeMessage">
                  <v-col>
                    <h3>{{ titles.rs5.schedule.messageDateTime }}</h3>
                    <v-text-field
                      :value="`${campaign.messageDate}  ${campaign.messageTime}`"
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <template v-if="!campaign.isTypeCoupon">
                  <v-row class="mt-0">
                    <v-col cols="6">
                      <h3>광고 시작일</h3>
                      <v-text-field
                        prepend-inner-icon="mdi-calendar"
                        :value="campaign.displayPeriodStart"
                        readonly
                        outlined
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <h3>광고 종료일</h3>
                      <v-text-field
                        prepend-inner-icon="mdi-calendar"
                        :value="displayPeriodEndText"
                        readonly
                        outlined
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row v-if="!campaign.isTypeMessage" class="mt-0">
                    <v-col>
                      <h3>
                        {{ titles.rs4.impressionTime }}
                      </h3>
                      <v-text-field
                        :value="campaign.impressionTime"
                        :suffix="
                          campaign.impressionTimeType === 's' ? '초' : '분'
                        "
                        outlined
                        filled
                        readonly
                      ></v-text-field>
                      <h3>{{ titles.rs4.impressionFrequency }}</h3>
                      <v-text-field
                        :value="campaign.impressionFrequency"
                        outlined
                        suffix="순위"
                        filled
                        readonly
                      ></v-text-field>
                      <h3>{{ titles.rs4.adsLimitInterval }}</h3>
                      <v-text-field
                        :value="campaign.adsLimitInterval"
                        outlined
                        suffix="일"
                        filled
                        readonly
                      ></v-text-field>
                      <h3>{{ titles.rs4.adsGoalCount }}</h3>
                      <v-text-field
                        :value="campaign.adsGoalCount"
                        outlined
                        filled
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>

                <!-- <div v-if="campaign.isCostTypeCPMR || campaign.isCostTypeCPMI">
                  <h3>{{ titles.rs5.budget.cpm }}</h3>
                  <v-text-field
                    :value="campaign.cpm | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                  <h3>{{ titles.rs5.budget.advertisingReachCount }}</h3>
                  <v-text-field
                    :value="
                      Number(campaign.advertisingReachCount).toLocaleString()
                    "
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </div> -->
                <div v-else>
                  <!-- <h3>{{ titles.rs5.budget.cpc }}</h3>
                  <v-text-field
                    :value="campaign.cpc | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                  <h3>{{ titles.rs5.budget.dailyAdvertisingBudget }}</h3>
                  <v-text-field
                    :value="campaign.dailyAdvertisingBudget | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                  <h3>{{ titles.rs5.budget.totalAdvertisingBudget }}</h3>
                  <v-text-field
                    :value="campaign.totalAdvertisingBudget | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field> -->
                </div>

                <!-- <p>{{ campaign.rs3.apps }}</p>
            <p>{{ campaign.rs3.locations }}</p> -->

                <v-row v-if="campaign.targetTags.length > 0">
                  <v-col cols="12" v-if="campaign.isTagFilter">
                    <h3># 태그 타겟</h3>
                    <h3>
                      태그 타겟 모드:
                      {{
                        TargetTagsModeList.find(
                          v => v.value === campaign.targetTagsMode
                        ).text
                      }}
                    </h3>
                    <h3>
                      태그 타겟 필터 유형:
                      {{
                        TargetTagsTypeList.find(
                          v => v.value === campaign.targetTagsType
                        ).text
                      }}
                      <v-chip label outlined color="error ma-2">
                        <span> 예상 도달 수: </span>
                        <span class="title">
                          {{ campaign.targetTagsCount.toLocaleString() }}</span
                        >
                      </v-chip>
                    </h3>
                    <v-chip
                      v-for="(tag, index) in campaign.targetTags"
                      :key="index"
                      label
                      outlined
                      color="error ma-2"
                    >
                      <v-icon left> mdi-pound </v-icon>
                      <span class="title">{{ tag.name }}</span>
                    </v-chip>
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <h3># 수집 태그</h3>
                    <v-chip
                      v-for="(tag, index) in campaign.tags"
                      :key="index"
                      label
                      outlined
                      color="primary darken-3 ma-2"
                    >
                      <v-icon left> mdi-pound </v-icon>
                      <span class="title">{{ tag.name }}</span>
                    </v-chip>
                    <v-divider />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" lg="6">
              <v-container class="px-3">
                <CampaignPreview />
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="reviewDialog = loading = false"
            :disabled="loading"
            large
          >
            이전
          </v-btn>
          <v-btn
            color="secondary"
            @click="handleCompleteClick()"
            :loading="loading"
            large
          >
            완료
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CampaignPreview from './CampaignPreview.vue';
import {
  CampaignTypeList,
  TargetTagsTypeList,
  TargetTagsModeList,
  Titles
} from '@/config/admin/campaign';
import {
  createCampaignCouponAPI,
  updateCampaignCouponAPI
} from '@/api/admin/CampaignAPI';
export default {
  components: {
    CampaignPreview
  },
  props: ['invalid'],
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      isDialogTypeRegister: 'campaign/isDialogTypeRegister'
    }),
    titles() {
      return Titles;
    },
    CampaignTypeList() {
      return CampaignTypeList;
    },
    dialogTitle() {
      return this.isDialogTypeRegister ? '등록' : '수정';
    },
    TargetTagsTypeList() {
      return TargetTagsTypeList;
    },
    TargetTagsModeList() {
      return TargetTagsModeList;
    },
    displayPeriodEndText() {
      const campaign = this.campaign;
      const issetDisplayPeriodEnd =
        this.campaign.issetDisplayPeriodEnd || false;

      if (this.campaign.isTypeMessage) {
        return campaign.displayPeriodEnd;
      } else {
        return issetDisplayPeriodEnd ? campaign.displayPeriodEnd : '설정안함';
      }
    },
    couponTypeText() {
      let subText = '';
      if (this.campaign.isTypeText) {
        subText =
          this.campaign.pushClickType === 'direct' ? ' - 다이렉트' : ' - 인앱';
      }

      if (this.campaign.isTypeCoupon) {
        subText = this.campaign.couponLandingUrl
          ? ' - 링크이동'
          : ' - 다운로드';
      }

      return (
        this.CampaignTypeList.find(v => v.value === this.campaign.type).text +
        subText
      );
    }
  },
  data() {
    return {
      reviewDialog: false,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      closeDialog: 'campaign/closeDialog'
    }),
    async createCampaign() {
      this.loading = true;
      if (this.isDialogTypeRegister) {
        // 캠페인 등록
        await this.$axios
          .post('/admin/campaigns', this.campaign.getCreatePayload())
          .then(() => {
            // console.log(res);
            this.reviewDialog = false;
            this.$root.$emit('getCampaignsApiEvent');
            this.closeDialog();
          })
          .catch(err => {
            this.loading = false;
            this.$axiosErrorAlert();
            console.error(err);
          });
      } else {
        // 캠페인 수정
        await this.$axios
          .put('/admin/campaigns', this.campaign.getUpdatePayload())
          .then(() => {
            // console.log(res);
            this.reviewDialog = false;
            this.$root.$emit('getCampaignsApiEvent');
            this.closeDialog();
          })
          .catch(err => {
            this.loading = false;
            this.$axiosErrorAlert();
            console.error(err);
          });
      }
    },
    async handleCompleteClick() {
      // 변경 내용없는 경우는 저장요청 안함
      if (!!this.campaign.id && !this.campaign.isChanged()) {
        this.$Swal.fire({
          icon: 'error',
          html: '<h3>변경된 내용이 없습니다.</h3>',
          confirmButtonText: '확인'
        });
        return;
      }
      if (this.campaign.isTypeCoupon) {
        // 쿠폰
        if (this.isDialogTypeRegister) {
          // 쿠폰 등록
          this.createCoupon(this.campaign.getCreatePayload());
        } else {
          // 쿠폰 수정
          this.updateCoupon(this.campaign.getUpdatePayload());
        }
      } else {
        // SDK
        this.createCampaign();
      }
    },
    async createCoupon(newCampaign) {
      this.loading = true;

      const [error] = await createCampaignCouponAPI(newCampaign);
      if (error) {
        console.error(error);
      } else {
        this.reviewDialog = false;
        this.$root.$emit('getCampaignsApiEvent');
        this.closeDialog();
      }
      this.loading = false;
    },
    async updateCoupon(newCampaign) {
      this.loading = true;

      const [error] = await updateCampaignCouponAPI(
        newCampaign,
        newCampaign.id
      );
      if (error) {
        console.error(error);
      } else {
        this.reviewDialog = false;
        this.$root.$emit('getCampaignsApiEvent');
        this.closeDialog();
      }
      this.loading = false;
    }
  }
};
</script>
