<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="카테고리">
        <a-select
          :style="{ minWidth: '160px' }"
          v-model="filters.categories"
          mode="multiple"
        >
          <a-select-option
            v-for="item in BranchCategories"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="상호">
        <a-select
          :style="{ minWidth: '160px' }"
          v-model="filters.companyCodes"
          mode="multiple"
        >
          <a-select-option
            v-for="item in CompanyCodes"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="활성 여부">
        <a-select :style="{ minWidth: '160px' }" v-model="filters.isActive">
          <a-select-option value=""> 전체 </a-select-option>
          <a-select-option
            v-for="item in ActiveOptions"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="매체사 그룹">
        <v-autocomplete
          v-model="filters.didMediaGroupIds"
          :items="mediaGroups"
          item-text="name"
          item-value="id"
          multiple
          outlined
          clearable
          placeholder="매체사 그룹을 선택하세요"
          no-data-text="선택가능한 매체사 그룹이 없습니다."
          hide-details
          class="custom-filter-selectbox"
          dense
        >
          <template v-slot:prepend-item v-if="mediaGroups.length > 0">
            <v-list-item ripple @click="toggleMediaGroups">
              <v-list-item-content>
                <v-list-item-title> 전체선택 </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="data">
            <v-chip
              v-if="data.index === 0"
              v-bind="data.attrs"
              :input-value="data.selected"
              outlined
              label
              pill
              style="border: none !important"
              @click="data.select"
            >
              <v-chip small>
                {{ data.item.name }}
                {{
                  filters.didMediaGroupIds.length > 1
                    ? ` 외 ${filters.didMediaGroupIds.length - 1} 개`
                    : ''
                }}
              </v-chip>
            </v-chip>
          </template>
        </v-autocomplete>
      </a-descriptions-item>

      <a-descriptions-item label="운영 요일" :span="2">
        <v-row class="ma-0">
          <v-btn-toggle
            v-model="filters.scheduleDayIds"
            color="#00d1b2"
            multiple
          >
            <v-btn
              v-for="day in Week"
              :key="day.value"
              :value="day.value"
              height="30px"
              class="px-5"
            >
              {{ day.text }}
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </a-descriptions-item>

      <a-descriptions-item label="검색" :span="3">
        <a-input
          v-model="filters.name"
          @pressEnter="$emit('search')"
          placeholder="검색할 매체명을 입력해 주세요."
        />
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>

      <v-btn
        class="ml-3"
        color="#00d1b2"
        outlined
        @click="handleInitClick()"
        :disabled="loading"
      >
        초기화
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getDidMediaGroupOptionsApi } from '@/api/admin/DidMediaGroupAPI';
import {
  BranchCategories,
  CompanyCodes,
  Week,
  ActiveOptions
} from '@/config/admin/campaign-did';

export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      ActiveOptions: ActiveOptions,
      BranchCategories: BranchCategories,
      CompanyCodes: CompanyCodes,
      Week: Week,
      mediaGroups: [],
    };
  },
  computed: {
    ...mapGetters({
      filters: 'didBranch/filters'
    })
  },
  created() {
    this.fetchMediaGroupOptions();
  },
  methods: {
    ...mapActions({
      initFilters: 'didBranch/initFilters'
    }),
    // 매체사 그룹 목록
    async fetchMediaGroupOptions() {
      if (this.dialogLoading) return;
      this.dialogLoading = true;
      const [error, data] = await getDidMediaGroupOptionsApi();

      if (error) {
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_media_groups } = data;
        this.mediaGroups = did_media_groups.map(group => ({
          ...group,
          name: `${group.name} (${group.type === 'A' ? '대행사' : '직매체'})`
        }));
      }
      this.dialogLoading = false;
    },
    toggleMediaGroups() {
      this.$nextTick(() => {
        if (this.filters.didMediaGroupIds.length === (this.mediaGroups.map(item=>item.name)).length
        ) {
          this.filters.didMediaGroupIds = [];
        } else {
          this.filters.didMediaGroupIds = this.mediaGroups.map(item => item.id);
        }
      });
    },
    handleInitClick() {
      this.initFilters();
      this.$emit('search');
    }
  }
};
</script>
