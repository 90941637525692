import moment from 'moment';
import { CampaignTypeList } from '@/config/admin/campaign';
import { getCamelKeys } from '@/utils/functions';
import {
  AdvertiserOption,
  AdvertiserGroupOption,
  CampaignTypePush,
  CouponConfig
} from '@/model/common';

export class Campaign {
  origin = null;

  _advertiser = null;
  _advertiserGroup = null;
  _campaignTypePush = null;

  key = null;
  id = null;
  userId = null;
  mediaGroups = [];
  tracker = '';
  name = null;
  type = null;
  landingUrl = null;

  // push -> click_type
  pushClickType = null;

  /** *************************
   * rs2
   ************************* **/
  // push
  pushSubTitle = null;
  pushCategory = null;
  pushTitle = null;
  pushText = null;
  messageDate = null;
  messageTime = null;
  // image
  pushImageId = null;
  pushImageUrl = null;
  pushImageType = null;
  // push-> icon
  pushIconId = null;
  pushIconUrl = null;
  pushIconType = null;
  //  push-> message_image
  messageImageId = null;
  messageImageUrl = null;
  messageImageType = null;
  //  rolling-> image
  rollingImageId = null;
  rollingImageUrl = null;
  rollingImageType = null;

  /** *************************
   * coupon
   ************************* **/
  // couponConfig = null;
  _couponConfig = null;
  couponShop = null;
  couponDescription = '최대';
  // cp ->couponConfig
  couponPrice = null;
  couponExpiresAt = null;
  couponConfigId = null;
  couponBranches = [];
  couponShopImageUrl = null;
  couponLandingUrl = null;
  maxUses = 1;
  // cp -> couponShop
  couponShopId = null;
  couponShopName = null;
  couponShopAddress = null;

  /** *************************
   * rs3
   ************************* **/
  targetApps = [];
  targetAppsType = 'nontarget';
  targetLocations = [];
  targetLocationsType = 'R'; // 위치타겟 정보 (실시간)
  tags = [];
  targetTags = [];
  targetTagsMode = null;
  targetTagsType = null;
  targetTagsRange = 30;
  targetTagsClick = 1;
  targetTagsCount = 0;
  isTagFilter = false;
  isOnlyRealtime = false;

  /** *************************
   * rs4
   ************************* **/
  impressionTime = 10; //광고노출시간
  impressionTimeType = 's';
  impressionFrequency = 1; //광고노출우선순위
  adsGoalCount = 2; //광고 미노출 최대 클릭 수
  adsLimitInterval = 2; //광고 클릭 후 재노출 기간

  /** *************************
   * rs5
   ************************* **/

  displayPeriodStart = null;
  displayPeriodEnd = null;
  _issetDisplayPeriodEnd = false;
  costTypes = [];
  dailyAdvertisingBudget = 0;
  totalAdvertisingBudget = 0;
  cpc = 0;
  cpmr = 0;
  cpmi = 0;
  advertisingReachCount = 0;
  forecastReachCount = 0;
  sReachCount = 0;
  creditDeposit = 0;
  freeDeposit = 0;
  approveStatus = null;
  approveDenyReason = '';
  advertiserGroupName = null;
  advertiserGroupIconUrl = null;
  advertiserName = null;
  advertiserId = null;
  activeStatus = false;
  couponBranchApiCallCount = 0;
  couponBranchPopupCount = 0;

  targetLocationsCount = 0;

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new Campaign(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      if (key === 'origin') continue;

      if (val !== null) {
        switch (key) {
          case 'issetDisplayPeriodEnd':
            this.issetDisplayPeriodEnd = val;

            if (!val && covObject.displayPeriodEnd === '9999-12-31') {
              this.displayPeriodEnd = null;
            }
            break;
          case 'tracker':
            this.tracker = val ?? null;
            this.trackerName = val ? val.name || '' : '';
            this.trackerId = val ? val.id || null : null;
            break;
          case 'costTypes':
            if (val) {
              // this.costTypes = Array.isArray(val) ? val : [];
              this.costTypes =
                val.length > 0
                  ? val.map(v => (typeof v !== 'object' ? v : v.id))
                  : [];
              // this.costTypeIds =
              //   this.costTypes.length > 0 ? this.costTypes.map(v => v.id) : [];
            } else {
              this.costTypes = [];
            }
            break;
          case 'campaignTypeRolling': {
            this.campaignTypeRolling = val;

            // 이미지 정보가 있으면
            const { image } = val;

            if (image) {
              const keys = ['id', 'url', 'type'];
              const orgKeys = [
                'rollingImageId',
                'rollingImageUrl',
                'rollingImageType'
              ];
              this.setDataByKey(image, keys, orgKeys);
            }
            break;
          }
          case 'sCtrReach':
            this.ctrReach = val;
            this.sCtrReach = val;
            break;
          case 'frequency':
            this.frequency = val.toLocaleString();
            break;
          case 'createdAt':
            this[key] = moment(val).format('YYYY-MM-DD HH:mm');
            break;
          default:
            this[key] = val;
        }
      }
    }

    this.key = this.id;

    if (this.origin) {
      this.targetTagsCount = this.getTargetTagsCount(
        this.targetTagsMode,
        this.targetTagsCount,
        this.forecastReachCount
      );
    }

    if (this.approveStatus === 'N') {
      this.approveDenyReason = this.approveDenyReason ?? '';
    }
    if (this.messageDate && this.messageTime) {
      this.messageDateTime = moment(
        `${this.messageDate} ${this.messageTime}`
      ).format('YYYY-MM-DD HH:mm ');
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new Campaign(this);
    }
  }

  /** *************************
   * 켐페인 타입 체크
   ************************* **/
  get isTypeImage() {
    return this.type === 'rolling';
  }
  get isTypeText() {
    return this.type === 'push';
  }
  get isTypeMessage() {
    return this.type === 'message';
  }
  get isTypeCoupon() {
    return this.type === 'coupon';
  }
  /** *************************
   * @description: 타입이 텍스트 광고 OR 이미지 광고
   ************************* **/
  get isTypeDA() {
    return this.isTypeText || this.isTypeImage;
  }
  /** *************************
   * 과금 유형 체크
   ************************* **/
  get isCostTypeCPMR() {
    return this.costTypes.includes(3);
  }
  get isCostTypeCPMI() {
    return this.costTypes.includes(2);
  }
  get isCostTypeCPC() {
    return this.costTypes.includes(1);
  }
  /** *************************
   * 타겟팅 체크
   ************************* **/
  get isTargetAppsTypeDetarget() {
    return this.targetAppsType === 'detarget'; // 디타겟팅
  }
  get isTargetAppsTypeNontarget() {
    return this.targetAppsType === 'nontarget'; // 논타겟팅
  }
  /** *************************
   * 캠페인 유형 이름
   ************************* **/
  get typeText() {
    return CampaignTypeList.find(v => v.value === this.type)?.text || '';
  }
  /** *************************
   * 캠페인 미디어 아이디
   ************************* **/
  get mediaGroupIds() {
    return this.mediaGroups.map(v => v.id);
  }
  get targetTagIds() {
    return this.targetTags.map(v => v.id);
  }
  get costMax() {
    return Math.max(this.cpc, this.cpmr, this.cpmi);
  }
  get willDeposit() {
    if (this.isTypeMessage && this.cpmr && this.advertisingReachCount) {
      return (this.cpmr / 1000) * this.advertisingReachCount;
    } else {
      return this.totalAdvertisingBudget;
    }
  }
  get coupounShopInfo() {
    return this.couponShop || null;
  }
  get couponPublish() {
    return (this.totalAdvertisingBudget / 100).toLocaleString();
  }
  get couponDescriptionPreview() {
    const couponDescriptionString = this.couponPrice ? '최대' : '';
    // [3.29] 메가박스에서 Description 디자인때문에 안쓴다고 함
    // this.couponDescription && this.couponDescription.length > 0
    //   ? this.couponDescription + ' '
    //   : '';
    const couponPriceString = this.couponPrice
      ? `${Number(this.couponPrice).toLocaleString()}원 할인`
      : '';

    return `${couponDescriptionString}${couponPriceString}`;
  }
  get targetLocationsText() {
    return this.targetLocations.length > 0 ? '활성' : '비활성';
  }

  /** *************************
   * GET , SET
   ************************* **/
  get advertiser() {
    return this._advertiser;
  }
  set advertiser(val) {
    this._advertiser = new AdvertiserOption(val);
    this.advertiserName = this._advertiser?.name;
    this.advertiserId = this._advertiser.id || '';
    this.userId = this._advertiser.userId || '';
  }
  get advertiserGroup() {
    return this._advertiserGroup;
  }
  set advertiserGroup(val) {
    this._advertiserGroup = new AdvertiserGroupOption(val);
    this.advertiserGroupName = this._advertiserGroup?.name;
    this.advertiserGroupIconUrl = this._advertiserGroup?.iconUrl || '';
  }
  get campaignTypePush() {
    return new CampaignTypePush(this._campaignTypePush);
  }
  set campaignTypePush(val) {
    this._campaignTypePush = new CampaignTypePush(val);

    const keys = [
      'pushClickType',
      'pushSubTitle',
      'pushCategory',
      'pushTitle',
      'pushText',
      'messageDate',
      'messageTime',
      'pushImageId',
      'pushImageUrl',
      'pushImageType',
      'pushIconId',
      'pushIconUrl',
      'pushIconType',
      'messageImageId',
      'messageImageUrl',
      'messageImageType'
    ];

    this.setDataByKey(this._campaignTypePush, keys);
  }

  get issetDisplayPeriodEnd() {
    return this._issetDisplayPeriodEnd;
  }
  set issetDisplayPeriodEnd(val) {
    this._issetDisplayPeriodEnd = val;
    if (!val) {
      this.displayPeriodEnd = null;
    }
  }
  get couponConfig() {
    return new CouponConfig(this._couponConfig);
  }
  set couponConfig(val) {
    this._couponConfig = new CouponConfig(val);

    const keys = [
      'couponShop',
      'couponPrice',
      'couponExpiresAt',
      'couponConfigId',
      'couponBranches',
      'couponShopImageUrl',
      'couponLandingUrl',
      'maxUses',
      'couponShopId',
      'couponShopName',
      'couponShopAddress'
    ];

    this.setDataByKey(this._couponConfig, keys);
  }
  get couponConfigPayload() {
    return {
      couponBranches: this.couponBranches,
      couponConfigId: this.couponConfigId,
      couponExpiresAt: this.couponExpiresAt,
      couponPrice: this.couponPrice,
      couponShopId: this.couponShopId,
      couponLandingUrl: this.couponLandingUrl,
      maxUses: this.maxUses
    };
  }
  get campaignTypePushPayload() {
    return {
      pushClickType: this.pushClickType,
      pushSubTitle: this.pushSubTitle,
      pushCategory: this.pushCategory,
      pushTitle: this.pushTitle,
      pushText: this.pushText,
      pushImageId: this.pushImageId,
      pushImageUrl: this.pushImageUrl,
      pushImageType: this.pushImageType,
      messageImageId: this.messageImageId,
      messageImageUrl: this.messageImageUrl,
      messageImageType: this.messageImageType
    };
  }
  get targetTagsPayload() {
    return {
      isTagFilter: this.isTagFilter,
      targetTagsType: this.isTagFilter ? this.targetTagsType : null,
      targetTagsRange: this.isTagFilter ? this.targetTagsRange : null,
      targetTagsCount: this.isTagFilter ? this.targetTagsCount : null,
      targetTagsClick: this.isTagFilter ? this.targetTagsClick : null,
      targetTagsMode: this.isTagFilter ? this.targetTagsMode : null
    };
  }

  get remainDeposit() {
    return this.creditDeposit + this.freeDeposit;
  }

  /** *************************
   * 값 변경 여부 확인 (캠페인 수정 확인)
   ************************* **/
  isChanged() {
    const changedCampaign = this.getUpdatePayload(true);
    let checkTempKeys = Object.keys(changedCampaign);
    const origin = this.origin.getUpdatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = changedCampaign[key] !== val;

        if (
          typeof changedCampaign[key] === 'object' ||
          typeof val === 'object'
        ) {
          isSameData =
            JSON.stringify(changedCampaign[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  changedValues(checkKeys = [], changedCampaign = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedCampaign);
    let changes = [];
    const origin = this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = changedCampaign[key] === val;

      if (typeof changedCampaign[key] === 'object' || typeof val === 'object') {
        isSameData =
          JSON.stringify(changedCampaign[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedCampaign[key]
        });
      }
    }
    return changes;
  }

  initBudget() {
    this.costTypes = [];
    this.cpc = null;
    this.cpmr = null;
    this.cpmi = null;
    this.dailyAdvertisingBudget = null;
    this.totalAdvertisingBudget = null;
    this.forecastReachCount = null;
    this.advertisingReachCount = null;
  }

  // 태그 타겟 모드에 따른 모수를 계산합니다.
  getTargetTagsCount = (mode, targetTagsCount = 0, forecastReachCount = 0) => {
    if (mode === 'T') {
      // 태그 타겟인 경우, 원래 값 리턴
      return targetTagsCount;
    } else if (mode === 'D') {
      // 태그 디타겟인 경우, 예상 도달 수 에서 태그타겟 모수 뺀값을 리턴
      const count = forecastReachCount - targetTagsCount;
      return count > 0 ? count : 0;
    } else {
      return 0;
    }
  };

  // this 값에 값을 넣습니다.
  // dataKeys : dataObj내에 키 이름 목록
  // originKeys : this 키 이름 목록
  setDataByKey(dataObj, dataKeys = [], originKeys = []) {
    let orgKeys = originKeys;

    if (!originKeys.length && dataKeys.length > 0) {
      orgKeys = dataKeys;
    }
    dataKeys.forEach((key, index) => {
      this[orgKeys[index]] = dataObj[key];
    });
  }

  getCreatePayload() {
    let res = {};
    // 메시지 타입인 경우 무조건 true 처리
    const issetDisplayPeriodEnd = this.isTypeMessage
      ? true
      : this.issetDisplayPeriodEnd;

    // 광고 종료일 설정 유무에 따라 지정날짜로 변경 ( 메시지 타입 제외 )
    const displayPeriodEnd = this.isTypeMessage
      ? this.displayPeriodEnd
      : issetDisplayPeriodEnd
      ? this.displayPeriodEnd
      : '9999-12-31';

    res = {
      campaignName: this.name,
      campaignType: this.type,
      displayPeriodStart: this.displayPeriodStart,
      displayPeriodEnd: displayPeriodEnd,
      issetDisplayPeriodEnd: issetDisplayPeriodEnd,
      advertiserId: this.advertiser.id,
      advertiserGroupId: this.advertiserGroup?.id,
      totalAdvertisingBudget: this.isTypeMessage
        ? 0
        : Number(this.totalAdvertisingBudget),
      cpc: this.isCostTypeCPC ? Number(this.cpc) : 0,
      dailyAdvertisingBudget: this.isTypeMessage
        ? 0
        : Number(this.dailyAdvertisingBudget)
    };

    if (this.isTypeCoupon) {
      // 쿠폰 생성시
      res = {
        ...res,
        ...this.couponConfigPayload
      };
    } else {
      // SDK 생성시
      res = {
        ...res,
        landingUrl: this.landingUrl,
        impressionTime: this.impressionTime,
        impressionTimeType: this.impressionTimeType,
        impressionFrequency: this.impressionFrequency,
        adsGoalCount: this.adsGoalCount,
        adsLimitInterval: this.adsLimitInterval,
        targetApps: this.targetApps,
        targetAppsType: this.targetAppsType,
        targetLocations: this.targetLocations,
        targetLocationsType:
          this.targetLocations.length > 0 ? this.targetLocationsType : null,
        trackerId: this.tracker?.id || null,
        cpc: this.isCostTypeCPC ? Number(this.cpc) : 0,
        cpmr: this.isCostTypeCPMR ? Number(this.cpmr) : 0,
        cpmi: this.isCostTypeCPMI ? Number(this.cpmi) : 0,
        advertisingReachCount: this.isTypeMessage
          ? Number(this.advertisingReachCount)
          : 0,
        forecastReachCount: this.forecastReachCount,
        isOnlyRealtime: this.isOnlyRealtime ?? false,
        targetTagIds: this.isTagFilter ? this.targetTagIds : [],
        mediaGroupIds: this.mediaGroupIds,
        tagIds: this.tags.map(v => v.id),
        costTypeIds: this.costTypes,
        rollingImageId: this.rollingImageId,
        messageDate: this.messageDate,
        messageTime: this.messageTime,

        ...this.targetTagsPayload,
        ...this.campaignTypePushPayload
      };
    }

    return res;
  }

  getUpdatePayload(isChecked = false) {
    let res = {};
    let changedValueKeys = [];
    // 메시지 타입인 경우 무조건 true 처리
    const issetDisplayPeriodEnd = this.isTypeMessage
      ? true
      : this.issetDisplayPeriodEnd;

    // 광고 종료일 설정 유무에 따라 지정날짜로 변경 ( 메시지 타입 제외 )
    const displayPeriodEnd = this.isTypeMessage
      ? this.displayPeriodEnd
      : issetDisplayPeriodEnd
      ? this.displayPeriodEnd
      : '9999-12-31';

    res = {
      id: this.id,
      campaignName: this.name,
      campaignType: this.type,
      advertiserId: this.advertiser.id,
      advertiserGroupId: this.advertiserGroup?.id,
      displayPeriodStart: this.displayPeriodStart,
      cpc: this.isCostTypeCPC ? Number(this.cpc) : 0,
      totalAdvertisingBudget: this.isTypeMessage
        ? 0
        : Number(this.totalAdvertisingBudget),
      displayPeriodEnd: displayPeriodEnd,
      issetDisplayPeriodEnd: issetDisplayPeriodEnd
    };

    if (this.isTypeCoupon) {
      // 쿠폰 생성시
      res = {
        ...res,
        ...this.couponConfigPayload
      };
    } else {
      // SDK 생성시
      res = {
        ...res,
        landingUrl: this.landingUrl,
        trackerId: this.tracker?.id || null,
        // 단가 및 예산 수정 안함
        cpmr: this.isCostTypeCPMR ? Number(this.cpmr) : 0,
        cpmi: this.isCostTypeCPMI ? Number(this.cpmi) : 0,
        dailyAdvertisingBudget: this.isTypeMessage
          ? 0
          : Number(this.dailyAdvertisingBudget),
        forecastReachCount: this.forecastReachCount,
        targetApps: this.targetApps,
        targetAppsType: this.targetAppsType,
        targetLocations: this.targetLocations,
        isOnlyRealtime: this.isOnlyRealtime ?? false,
        costTypeIds: this.costTypes,
        tagIds: this.tags.map(v => v.id),
        targetTagIds: this.isTagFilter ? this.targetTagIds : [],
        mediaGroupIds: this.mediaGroupIds,
        rollingImageId: this.rollingImageId,
        impressionTime: this.impressionTime,
        impressionTimeType: this.impressionTimeType,
        impressionFrequency: this.impressionFrequency,
        adsGoalCount: this.adsGoalCount,
        adsLimitInterval: this.adsLimitInterval,
        messageDate: this.messageDate,
        messageTime: this.messageTime,
        ...this.targetTagsPayload,
        ...this.campaignTypePushPayload
      };
    }

    if (isChecked) {
      // 값 체크 용도
      return res;
    }

    changedValueKeys = Object.keys(res);
    const changedValues = this.changedValues(changedValueKeys, res);

    return {
      ...res,
      changedValues: changedValues
    };
  }
}
