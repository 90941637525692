<template>
  <div>
    <h3>{{ title }}</h3>
    <span>{{ subTitle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '제목'
    },
    subTitle: {
      type: String,
      default: null
    }
  }
};
</script>

<style></style>
