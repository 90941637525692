var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-observer',{ref:"tagDialogContent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"p-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticStyle:{"padding":"30px"}},[_c('h2',{staticClass:"font-weight-bold py-2"},[_vm._v("기본 설정")]),_vm._l((_vm.tags),function(tag,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.label.name,"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"error-messages":errors,"label":_vm.label.name,"required":"","outlined":""},model:{value:(tag.name),callback:function ($$v) {_vm.$set(tag, "name", $$v)},expression:"tag.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","lg":"3"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.tagTypes,"item-text":"text","item-value":"value","label":"태그 유형","outlined":"","error-messages":errors},model:{value:(tag.tagTypeId),callback:function ($$v) {_vm.$set(tag, "tagTypeId", $$v)},expression:"tag.tagTypeId"}})]}}],null,true)}),(index > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pb-6",attrs:{"icon":""},on:{"click":function($event){return _vm.handleDeleteTagClick(index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(" 삭제")])]):_vm._e()],1)])],1)}),(_vm.dialog.register)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.handleAddTagClick()}}},[_c('v-icon',[_vm._v("mdi-playlist-plus")]),_vm._v("입력 폼 추가 ")],1):_vm._e()],2),(_vm.dialog.register)?_c('v-btn',{staticClass:"mt-4 mr-4",attrs:{"disabled":invalid,"color":"secondary","large":"","loading":_vm.loading},on:{"click":function($event){return _vm.handleRegisterClick()}}},[_vm._v(" 등록하기 ")]):_c('v-btn',{staticClass:"mt-4 mr-4",attrs:{"disabled":invalid,"color":"secondary","large":"","loading":_vm.loading},on:{"click":function($event){return _vm.handleUpdateClick()}}},[_vm._v(" 수정하기 ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }