<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn dark @click="openAdvertiserGroupDialog()">
              광고 그룹 등록
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selectedAdvertiserGroup"
          :headers="headers"
          :items="advertiserGroup"
          item-key="id"
          multi-sort
          :loading="loading"
          hide-default-footer
          class="pa-3 elevation-2"
          style="white-space:nowrap"
          loading-text="데이터 로딩..."
          no-data-text="데이터가 없습니다."
          mobile-breakpoint="0"
          dense
        >
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-inline-flex align-center">
              <v-list-item-avatar tile width="50" height="50">
                <img
                  v-if="item.iconUrl"
                  :src="item.iconUrl"
                  class="rounded-lg"
                  style="border: 1px solid #ddd;"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
              </v-list-item-content>
            </div>
            <!-- <div class="d-inline-flex align-center">
              <v-img
                :src="item.iconUrl"
                height="50"
                width="50"
                class="rounded-circle ma-2"
              ></v-img>
              <span>{{ item.name }}</span>
            </div> -->
          </template>
          <template v-slot:[`item.functions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  icon
                  @click="openAdvertiserGroupDialog(item.origin)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>수정</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
        ></v-pagination>
      </v-col>
    </v-row>
    <advertiser-group-dialog
      :openDialog="advertiserGroupDialog"
      @closeDialog="advertiserGroupDialog = false"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import AdvertiserGroupDialog from './components/AdvertiserGroupDialog.vue';
// import DatePicker from '@/components/DatePicker';
export default {
  components: {
    // DatePicker
    MenuTitle,
    AdvertiserGroupDialog
  },
  data() {
    return {
      advertiserGroup: [],
      selectedAdvertiserGroup: [],
      advertiserGroupDialog: false,
      dateRanges: null,
      searchString: null,
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      headers: [],
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'advertiserGroup/dialog'
    })
  },
  watch: {
    'pagination.page': function() {
      this.getAdvertiserGroup();
    }
  },
  created() {
    this.setHeaders();
    this.getAdvertiserGroup();
  },
  methods: {
    ...mapActions({
      initAdvertiserGroup: 'advertiserGroup/initAdvertiserGroup',
      hashingAdvertiserGroup: 'advertiserGroup/hashingAdvertiserGroup'
    }),
    setHeaders() {
      const headers = [
        { text: '번호', value: 'id' },
        { text: '광고 그룹 명', value: 'name' },
        { text: '광고주 명', value: 'advertiserName' },
        { text: '기능', value: 'functions' }
      ];

      this.headers = headers;
    },
    getAdvertiserGroup(page = this.pagination.page) {
      return this.$axios
        .get('/admin/advertiser-group', {
          params: {
            ss: this.searchString,
            page: page,
            perPage: this.pagination.perPage
          }
        })
        .then(res => {
          // console.log(res);
          const { advertiserGroups, totalCount } = res.data;
          this.advertiserGroup =
            totalCount > 0 ? this.hashing(advertiserGroups) : [];

          const { perPage } = this.pagination;
          const totalPage = parseInt(totalCount / perPage);
          this.pagination.totalPage =
            totalCount % perPage ? totalPage + 1 : totalPage;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hashing(advertiserGroup) {
      const hashedAdvertiserGroup = advertiserGroup.map(adg => {
        const { advertiser } = adg;

        return {
          origin: adg,
          id: adg.id,
          name: adg.name,
          advertiserName: advertiser.name,
          iconId: adg.icon?.id || null,
          iconUrl: adg.icon?.url || null,
          iconType: adg.icon?.type || null
        };
      });
      return hashedAdvertiserGroup;
    },
    openAdvertiserGroupDialog(advertiserGroup = null) {
      this.advertiserGroupDialog = true;
      if (advertiserGroup) {
        this.hashingAdvertiserGroup(advertiserGroup);
        this.dialog.edit = true;
      } else {
        this.initAdvertiserGroup();
        this.dialog.register = true;
      }
    }
  }
};
</script>
