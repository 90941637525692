<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ReportFilter
          :campaignTypes="campaignTypeList"
          @applyFilters="applyFilters"
          :loading="loading"
        />
      </v-col>
      <v-col cols="12">
        <MediaReportCharts
          title="매출 추이"
          :dateRanges="dateRanges"
          :stats="chartStats"
          :apiLoading="loading"
        />
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col>
        <v-card class="pa-5">
          <div class="d-flex align-center">
            <div
              style="width: 50px"
              class="d-flex ml-auto align-center justify-space-around"
            >
              <ReportExcelExport :columns="columns" :stats="stats" />
            </div>
          </div>
        </v-card>
        <v-simple-table
          v-if="!loading"
          class="pa-3 elevation-2"
          style="white-space: nowrap"
        >
          <thead>
            <tr style="background-color: rgba(244, 244, 244, 1)">
              <th class="text-center">기간</th>
              <th class="text-center">노출</th>
              <th class="text-center">푸시전송</th>
              <th class="text-center">클릭</th>
              <th class="text-center">예상매출</th>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color: rgba(244, 244, 244, 1)">
              <td class="text-center">Total</td>
              <td class="text-center">
                {{ sumField('impression_count').toLocaleString() }}
              </td>
              <td class="text-center">
                {{ sumField('reach_count').toLocaleString() }}
              </td>
              <td class="text-center">
                {{ sumField('click_count').toLocaleString() }}
              </td>
              <td class="text-center">
                <!-- {{ sumField('sales') | KRW }} -->

                ₩ {{ sumField('sales').toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-card>
          <v-row>
            <v-col>
              <Table
                v-if="columns && !loading"
                class="px-3"
                style="white-space: nowrap"
                :columns="columns"
                :data-source="stats"
                bordered
                :pagination="{
                  pageSize: 20,
                  position: 'bottom',
                  size: 'large'
                }"
                :scroll="{
                  x: 800
                }"
                :loading="loading"
                size="small"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
              >
                <template slot="type" slot-scope="text">
                  <Tag
                    v-if="campaignTypeList.find(v => v.value === text)"
                    :color="campaignTypeList.find(v => v.value === text).color"
                  >
                    {{ campaignTypeList.find(v => v.value === text).text }}
                  </Tag>
                  <span v-else>{{ text }}</span>
                </template>
                <template slot="money" slot-scope="text">
                  <!-- {{ text | KRW }} -->
                  ₩ {{ Number(text).toLocaleString() }}
                </template>
                <template slot="localeString" slot-scope="text">
                  {{ text == 0 ? '-' : Number(text).toLocaleString() }}
                </template>
                <template slot="percent" slot-scope="text">
                  {{ text }} %
                </template>
              </Table>
              <v-skeleton-loader v-else type="table"> </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MenuTitle from '@/components/MenuTitle.vue';
import MediaReportCharts from './components/MediaReportCharts.vue';
import ReportFilter from './components/ReportFilter.vue';
import { Table, Tag } from 'ant-design-vue';
import ReportExcelExport from './components/ReportExcelExport.vue';

export default {
  components: {
    MenuTitle,
    MediaReportCharts,
    ReportFilter,
    Table,
    Tag,
    ReportExcelExport
  },
  data() {
    return {
      campaignTypeList: [
        {
          text: '이미지',
          value: 'rolling',
          options: ['click', 'impression'],
          color: 'magenta'
        },
        {
          text: '텍스트',
          value: 'push',
          options: ['click', 'impression']
        },
        {
          text: '메시지',
          value: 'message',
          options: ['click', 'impression'],
          color: 'blue'
        }
      ],
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        totalCount: 0
      },
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      columns: [],
      stats: [],
      chartStats: [],
      totalStats: null,
      loading: true,
      tableOptions: {},
      filters: null,
      pushUnitPrice: 8 // 푸시 단가
    };
  },
  mounted() {
    this.setHeaders();
  },
  methods: {
    async applyFilters(filters) {
      // console.log(filters);
      this.filters = filters;
      // 종료일이 현재일과 같거나 큰 경우 현재에서 11시간을 뺀 시간을 마지막일로 정합니다.
      const endDate =
        this.$moment().format('YYYY-MM-DD') <= filters.dateRanges[1]
          ? this.$moment()
              .subtract(1, 'days')
              .subtract(this.$mediaHour, 'hours')
              .format('YYYY-MM-DD')
          : filters.dateRanges[1];

      filters.dateRanges[1] = endDate;
      this.dateRanges = filters.dateRanges;

      if (filters.campaignTypes.length === 0 || filters.mediaIds.length === 0) {
        this.initStats();
      } else {
        const { stats, ratio } = await this.getSalesApi(1);
        const tempStats = stats.map(item => ({
          ...item,
          // 예상 매출
          sales:
            item.date > '2023-01-31'
              ? (
                  (item.impression_count +
                    item.reach_count * this.pushUnitPrice) *
                  ratio
                ).toFixed(2)
              : item.sales,
          children: item.children.map(ch => ({
            ...ch,
            sales:
              ch.date > '2023-01-31'
                ? (
                    (ch.impression_count +
                      ch.reach_count * this.pushUnitPrice) *
                    ratio
                  ).toFixed(2)
                : ch.sales
          }))
        }));
        // 광고유형 텍스트 변환
        this.stats = tempStats;
        this.chartStats = tempStats;
        // this.stats = stats.map(stat => {
        //   stat.children = stat.children.map(child => {
        //     child.media_name = this.campaignTypeList.find(
        //       v => v.value === child.media_name
        //     ).text;
        //     return child;
        //   });
        //   return stat;
        // });
      }
    },
    initStats() {
      this.stats = [];
      this.totalStats = null;
      this.chartStats = [];
    },
    async getSalesApi(page = this.pagination.page) {
      this.loading = true;
      this.stats = [];
      this.pagination.page = page;
      const params = {
        ss: this.searchString,
        page: page,
        perPage: this.pagination.perPage,
        filters: this.filters
      };

      try {
        const { data } = await this.$axios.get('/media/report-sales', {
          params: params
        });
        // console.log(data);
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    setHeaders() {
      const columns = [
        {
          title: '일',
          dataIndex: 'date',
          sorter: (a, b) => new Date(a.date) - new Date(b.date)
        },
        {
          title: '매체',
          dataIndex: 'media_name',
          align: 'center',
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          title: '노출',
          dataIndex: 'impression_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          sorter: (a, b) => a.impression_count - b.impression_count
        },
        {
          title: '푸시전송',
          dataIndex: 'reach_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          sorter: (a, b) => a.reach_count - b.reach_count
        },
        // {
        //   title: '클릭',
        //   dataIndex: 'click_count',
        //   align: 'right',
        //   scopedSlots: {
        //     customRender: 'localeString'
        //   },
        //   sorter: (a, b) => a.click_count - b.click_count
        // },
        {
          title: '예상 매출',
          dataIndex: 'sales',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          sorter: (a, b) => a.sales - b.sales
        }
      ];

      this.columns = columns;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.stats.reduce((a, b) => a + (Number(b[key]) || 0), 0);
    },
    sumCTR(impression, click) {
      return ((click / impression) * 100).toFixed(3);
    }
  }
};
</script>
