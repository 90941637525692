<template>
  <div v-if="authenticated">
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawer"
      app
      clipped
      :mini-variant="!$isMobile && !mini"
      mobile-breakpoint="600"
    >
      <v-list nav dense>
        <div v-for="(menu, mIndex) in menus" :key="mIndex">
          <v-list-group
            v-if="menu.items"
            v-model="menu.active"
            sub-group
            color="dark"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="menu.text"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(item, i) in menu.items"
              :key="i"
              link
              :to="item.link"
              exact
            >
              <v-list-item-icon>
                <v-tooltip right color="rgba(0,0,0,1)" :disabled="mini">
                  <template #activator="{ on: onTooltip }">
                    <v-icon v-on="{ ...onTooltip }">{{ item.icon }}</v-icon>
                  </template>
                  <span class="title">{{ item.text }}</span>
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else link :to="menu.link" exact>
            <v-list-item-icon>
              <v-tooltip right color="rgba(0,0,0,1)" :disabled="mini">
                <template #activator="{ on: onTooltip }">
                  <v-icon v-on="{ ...onTooltip }">{{ menu.icon }}</v-icon>
                </template>
                <span class="title">{{ menu.text }}</span>
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="authenticated" app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="openNav()" />
      <v-toolbar-title>
        <v-img max-height="50" max-width="100" :src="$mainLogoPath"></v-img>
      </v-toolbar-title>

      <v-spacer />

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-avatar color="primary" size="48">
              <span class="white--text headline">
                {{ user.name.slice(0, 2) }}
              </span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title"
                >{{ user.name }} 님</v-list-item-title
              >
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item @click="logout" :disabled="loading">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view name="auth" />
    </v-main>

    <!-- <v-footer v-if="authenticated" app>
      <span>&copy;TDI 2021</span>
    </v-footer> -->

    <v-snackbar :value="errors" :timeout="2000">
      {{ errors }}
      <v-btn color="blue" text @click="errors = false">
        닫기
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      errors: 'errors',
      menus: 'menus/menus'
    })
  },
  data: () => ({
    drawer: null,
    mini: true,
    items: [{ icon: 'mdi-view-dashboard', text: '대시보드', link: '/admin' }],
    breadcrumbs: [],
    loading: false
  }),
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),

    logout() {
      this.loading = true;
      this.logoutAction().then(() => {
        this.loading = false;
        this.$router.replace({
          name: 'login'
        });
      });
    },
    openNav() {
      this.drawer = !this.drawer;

      // // pc, 모바일 화면에 따른 nav 기능 을 지정합니다.
      // if (!this.$isMobile) {
      //   // pc 화면인경우 아이콘을 단축시킵니다.
      //   this.mini = !this.mini;
      // } else {
      //   // 모바일 화면인 경우 숨김 처리를 설정합니다.
      //   this.drawer = !this.drawer;
      // }
    }
  }
};
</script>
