import axios from 'axios';
import store from '../store';

// user 별 vuex store
import media from '../store/media';
import advertiser from '../store/advertiser';
import admin from '../store/admin';
import campaignAdmin from '../store/campaignAdmin';
import superAdmin from '../store/superAdmin';
import partner from '../store/partner';
import did from '../store/did';

const userStore = {
  1: media,
  2: advertiser,
  3: admin,
  4: superAdmin,
  5: media,
  6: campaignAdmin,
  8: partner,
  9: did,
  10: did
};

store.subscribe(mutation => {
  switch (mutation.type) {
    case 'auth/SET_TOKEN':
      if (mutation.payload) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${mutation.payload}`;
        localStorage.setItem('token', mutation.payload);
      } else {
        axios.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('token', null);
      }
      break;
    case 'auth/SET_USER': {
      // 유저별 vuex store module 처리
      if (mutation.payload) {
        const { level: userLevel } = mutation.payload;

        if (userStore[userLevel] == undefined) {
          break;
        }
        // 모듈 등록
        Object.keys(userStore[userLevel]).forEach(module => {
          store.registerModule(module, userStore[userLevel][module]);
        });
        // 등록한 모듈명 localStorage 설정
        localStorage.setItem('module', JSON.stringify(userStore[userLevel]));
      } else {
        const module = JSON.parse(localStorage.getItem('module'));
        if (module) {
          // 모듈 등록 해제
          Object.keys(module).forEach(module => {
            if (store.hasModule(module)) {
              store.unregisterModule(module);
            }
          });
          localStorage.removeItem('module', null);
        }
      }
      break;
    }
  }
});
