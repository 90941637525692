<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row align="center" justify="end" class="ma-0">
      <v-col cols="auto">
        <v-btn-toggle
          v-model="filterRangeDays"
          color="primary"
          dense
          mandatory
          borderless
          @change="fetchChart('campaign')"
        >
          <v-btn :value="7" text :disabled="loading"> 일주일 </v-btn>
          <v-btn :value="30" text :disabled="loading"> 한달</v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="auto" style="min-width: 250px">
        <v-menu
          :disabled="loading"
          ref="filterEndDate"
          v-model="showDatePicker"
          :close-on-content-click="false"
          :return-value.sync="filterEndDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterDateRangeText"
              prepend-icon="mdi-calendar"
              dense
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
              style="width: auto"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="filterEndDate"
            no-title
            scrollable
            locale="ko-kr"
            :min="minDay"
            :max="today"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDatePicker = false">
              취소
            </v-btn>
            <v-btn
              text
              :disabled="loading"
              color="primary"
              @click="
                () => {
                  $refs.filterEndDate.save(filterEndDate);
                  fetchChart('campaign');
                }
              "
            >
              적용
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="auto" class="pl-0 mr-2">
        <v-btn :disabled="loading" icon small @click="fetchChart()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- 캠페인 관련 차트 정보 -->
    <v-row class="ma-0">
      <!-- 캠페인 유형별 활성 현황 -->
      <v-col :cols="12">
        <v-card class="pa-2 mx-auto" outlined>
          <v-col class="text-subtitle-1 font-weight-bold" cols="auto">
            ● 캠페인 유형별 활성 현황
          </v-col>

          <v-row
            class="ma-0 d-flex justify-space-between"
            v-if="campaignActive.length > 0"
          >
            <v-card
              class="d-flex ma-2 col pa-0 flex-wrap"
              max-width="400"
              min-width="200"
              v-for="(campaignType, typeIndex) in campaignActive"
              :key="`typeIndex${typeIndex}`"
            >
              <v-col
                class="text-subtitle-1 font-weight-bold px-5 align-center row ma-0 justify-center"
                :style="`background-color: ${
                  typeColors[campaignType.name]
                }; color: white; width: 115px`"
                cols="auto"
              >
                {{ campaignType.name }}
              </v-col>
              <v-divider vertical />
              <v-col
                class="text-right"
                :style="`border: 2px solid ${typeColors[campaignType.name]}`"
              >
                <div class="text-subtitle-1">{{ campaignType.value }} 건</div>
                <div class="text-subtitle-2">({{ campaignType.percent }})</div>
              </v-col>
            </v-card>
          </v-row>
          <v-row v-else justify="center" align="center" class="ma-0">
            <v-col class="text-center">
              <v-skeleton-loader
                v-show="loading || !charts[0].content"
                type="card"
                style="width: 100%; height: 100%"
              >
              </v-skeleton-loader>
              <h3 v-if="!(loading || !charts[0].content)" class="text-h6">
                해당기간에 활성 상태인 캠페인이 없습니다.
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 캠페인 차트 목록-->
      <v-col
        v-for="(chart, chartIndex) in getChartByGroup('campaign')"
        cols="12"
        :md="chart.cols ? chart.cols : 6"
        :key="`chartIndex${chartIndex}`"
      >
        <v-card class="pa-2 mx-auto" outlined>
          <v-col v-if="chart.title" class="text-subtitle-1 font-weight-bold">
            ● {{ chart.title }}
          </v-col>
          <v-col :cols="12" style="position: relative" class="pt-0 px-0">
            <div
              :ref="`${chart.key}`"
              style="
                width: 100%;
                margin: 0 auto;
                min-height: 300px;
                max-height: 1000px;
              "
            ></div>
            <v-skeleton-loader
              v-show="!chart.content"
              type="card"
              style="
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                margin-top: 15px;
              "
            >
            </v-skeleton-loader>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <!-- 광고주 회원가입 & 결제 금액 차트  -->
    <v-row class="ma-0">
      <!-- 캠페인 차트 목록-->
      <v-col
        v-for="(chart, chartIndex) in getChartByGroup('user')"
        cols="12"
        :md="chart.cols ? chart.cols : 6"
        :key="`chartIndex${chartIndex}`"
      >
        <v-card class="pa-2 mx-auto" outlined>
          <v-col v-if="chart.title" class="text-subtitle-1 font-weight-bold">
            ● {{ chart.title }}
          </v-col>
          <v-col :cols="12" style="position: relative" class="pt-0 px-0">
            <div
              :ref="`${chart.key}`"
              style="width: 100%; min-height: 300px; max-height: 1000px"
            ></div>
            <v-skeleton-loader
              v-show="!chart.content"
              type="card"
              style="
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                margin-top: 15px;
              "
            >
            </v-skeleton-loader>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'echarts-wordcloud';
import MenuTitle from '@/components/MenuTitle.vue';
import { getCampaignDataAPI, getUserDataAPI } from '@/api/admin/DashboardAPI';
export default {
  components: { MenuTitle },
  computed: {
    chartGroups() {
      return Array.from(new Set(this.charts.map(chart => chart.group)));
    },
    minDay() {
      return this.$moment().subtract(1, 'years').format('YYYY-MM-DD');
    },
    filterDateRange() {
      return [
        this.$moment(this.filterEndDate)
          .subtract(this.filterRangeDays, 'days')
          .format('YYYY-MM-DD'),
        this.filterEndDate
      ];
    },
    filterDateRangeText() {
      return this.filterDateRange.join(' ~ ');
    }
  },
  data() {
    return {
      loading: false,
      showDatePicker: false,
      today: this.$moment().format('YYYY-MM-DD'),
      filterEndDate: this.$moment().format('YYYY-MM-DD'),
      filterRangeDays: 7,
      campaignActive: [],
      charts: [
        {
          group: 'campaign',
          key: 'proportion',
          title: '캠페인 유형별 집계 비율',
          type: 'pie',
          unit: '건',
          totalTitleText: '합계',
          content: null
        },
        {
          group: 'campaign',
          key: 'expenditure',
          title: '캠페인 유형별 지출 금액 비율',
          type: 'pie',
          unit: '원',
          totalTitleText: '합계',
          content: null
        },
        {
          group: 'campaign',
          key: 'click',
          title: '캠페인 유형별 클릭 수',
          type: 'bar',
          unit: '회',
          totalTitleText: '합계',
          dataKeys: [''],
          customColors: true,
          content: null,
          itemStyle: {
            borderRadius: 5,
            opacity: 0.8
          }
        },
        {
          group: 'campaign',
          key: 'impression',
          title: '캠페인 유형별 노출 수',
          type: 'bar',
          unit: '회',
          totalTitleText: '합계',
          dataKeys: [''],
          customColors: true,
          content: null,
          itemStyle: {
            borderRadius: 5,
            opacity: 0.8
          }
        },
        {
          group: 'campaign',
          key: 'ctr',
          title: '캠페인 유형별 CTR',
          type: 'bar',
          dataKeys: [''],
          customColors: true,
          content: null,
          unit: '%',
          itemStyle: {
            borderRadius: 5,
            opacity: 0.8
          }
        },
        {
          group: 'campaign',
          key: 'tag',
          title: '캠페인 태그 현황',
          type: 'wordCloud',
          unit: '회',
          content: null
        },

        {
          group: 'user',
          key: 'register',
          title: '월간 회원가입 수',
          type: 'bar',
          unit: '명',
          totalTitleText: '누적 합계',
          dataKeys: ['회원 수'],
          content: null,
          itemStyle: {
            borderRadius: 3,
            opacity: 0.8
          }
        },
        {
          group: 'user',
          key: 'payment',
          title: '일간 결제 금액',
          type: 'bar',
          unit: '원',
          totalTitleText: '누적 합계',
          dataKeys: ['지출금액'],
          content: null,
          itemStyle: {
            borderRadius: 2,
            opacity: 0.8
          }
        }
      ],
      typeColors: {
        '푸시 메시지': '#5470c6',
        이미지: '#91cc76',
        텍스트: '#fac858',
        쿠폰: '#ee6666'
      }
    };
  },

  mounted() {
    this.fetchChart();
  },
  created() {
    window.addEventListener('resize', this.resizeChart);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
  },
  methods: {
    async fetchChart(groupName) {
      if (groupName === 'campaign' || !groupName) {
        this.campaignActive = [];
      }
      this.charts.forEach(chart => {
        if (chart.group === groupName || !groupName) {
          this.initChart(chart.key);
          chart.content = null;
        }
      });

      if (!groupName) {
        this.chartGroups.forEach(async group => {
          const chartData = await this.setChart(group);

          const drawCharts = this.getChartByGroup(group);

          drawCharts.forEach(async chart => {
            await this.initChart(chart.key);

            if (chartData[chart.key]) {
              this.drawChart(chart.key, chartData[chart.key]);
            }
          });
        });
      } else {
        const chartData = await this.setChart(groupName);

        this.charts.forEach(chart => {
          if (chart.group === groupName && chartData[chart.key]) {
            this.drawChart(chart.key, chartData[chart.key]);
          }
        });
      }
    },
    getChartByGroup(groupName) {
      if (!groupName) return [];
      return this.charts.filter(chart => chart.group === groupName);
    },
    initChart(targetKey) {
      if (!targetKey) {
        this.charts.forEach(chart => {
          const currentChart = chart.content;
          if (currentChart != null && currentChart != undefined) {
            currentChart.content.dispose();
          }
        });
      } else {
        const currentChart = this.charts.find(
          chart => chart.key === targetKey
        )?.content;
        if (currentChart != null && currentChart != undefined) {
          currentChart.dispose();
        }
      }
    },
    resizeChart() {
      this.charts.forEach(chart => {
        const currentChart = chart.content;

        if (currentChart !== null && currentChart !== undefined) {
          currentChart.resize();
        }
      });
    },
    async setChart(groupName) {
      if (!groupName) return;
      return await this.getChartData(groupName);
    },
    async getChartData(groupName) {
      if (!groupName) return;
      // api 통신으로 데이터 조회
      let res = null;
      this.loading = true;

      if (groupName === 'campaign') {
        const params = {
          params: {
            startDate: this.filterDateRange[0],
            endDate: this.filterDateRange[1]
          }
        };
        const [error, data] = await getCampaignDataAPI(params);
        if (error) {
          console.error(error);
        } else {
          res = data;

          if (res.active) {
            this.campaignActive = res.active;

            const totalValue = this.campaignActive.reduce((res, cur) => {
              return res + parseInt(cur.value);
            }, 0);

            this.campaignActive = this.campaignActive.map(type => ({
              ...type,
              value: `${parseInt(type.value).toLocaleString()}`,
              percent: `${((parseInt(type.value) / totalValue) * 100).toFixed(
                2
              )}%`
            }));
          }
        }
      } else if (groupName === 'user') {
        const [error, data] = await getUserDataAPI();
        if (error) {
          console.error(error);
        } else {
          res = data;
        }
      }

      return res;
    },
    async drawChart(targetKey, chartData) {
      if (!targetKey) return;

      const currentChart = this.charts.find(chart => chart.key === targetKey);

      if (!currentChart) return;
      // 초기화
      const { $echarts } = this;
      const { title, type, dataKeys } = currentChart;

      // 차트 생성
      const chartDom = this.$refs[`${targetKey}`][0];
      const myChart = $echarts.init(chartDom);

      let option,
        xAxisData,
        titleData,
        yAxisData = null;
      let tooltipData = {
        trigger: 'item',
        formatter: ({ name, value, color }) =>
          `${name} <br/> <span style="color: ${color}">●</span>  <b>${value.toLocaleString()}</b> ${
            currentChart.unit ?? ''
          }`
      };
      let legendData = {
        data: [],
        top: 'bottom'
      };
      let seriesData = [];

      // 전체 합계
      if (currentChart.totalTitleText) {
        titleData = {
          left: 'right',
          top: '0',
          subtext: `${currentChart.totalTitleText} ( ${chartData
            .reduce((res, cur) => {
              return res + cur.value;
            }, 0)
            .toLocaleString()}  ${currentChart.unit ?? ''})`
        };
      }

      if (type === 'pie') {
        // 파이 차트
        legendData.data = chartData?.map(data => data.name) ?? [];

        seriesData = [
          {
            name: title,
            type: type,
            radius: ['15%', '50%'],
            center: ['50%', '50%'],
            itemStyle: { borderRadius: 5 },
            ...currentChart.options,
            label: {
              alignTo: 'labelLine',
              formatter: '{name|{b}}\n{time|{d} %}',
              minMargin: 10,
              lineHeight: 15,
              rich: {
                name: {
                  fontWeight: 'bold'
                },
                time: {
                  fontSize: 10,
                  color: '#999'
                }
              }
            },
            data: chartData
          }
        ];
      } else if (type == 'bar') {
        legendData.data = dataKeys;

        xAxisData = [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              rotate: -30,
              margin: 25,
              padding: [10, 0, 0, 0],
              align: 'center'
            },
            data: chartData?.map(data => data.name) ?? []
          }
        ];

        yAxisData = [
          {
            type: 'value',
            axisLabel: {
              margin: 22,
              padding: [10, 0, 0, 0],
              align: 'center'
            }
          }
        ];

        seriesData = dataKeys.map(dataKey => {
          const data =
            chartData?.map(data =>
              currentChart.itemStyle
                ? {
                    value: data.value,
                    itemStyle: {
                      ...currentChart.itemStyle,
                      color: currentChart.customColors
                        ? this.typeColors[data.name]
                        : undefined
                    }
                  }
                : data.value
            ) ?? [];
          return {
            name: dataKey,
            type: type,
            data: data
          };
        });
      } else if (type == 'treemap') {
        seriesData = [
          {
            type: type,
            data: chartData,
            width: '90%',
            height: '90%',
            breadcrumb: { show: false },
            roam: false,
            nodeClick: false,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0,
              gapWidth: 1,
              borderRadius: 3
            },
            levels: [
              {
                colorSaturation: [0.35, 0.5]
              }
            ]
          }
        ];
      } else if (type == 'wordCloud') {
        seriesData = [
          {
            type: type,
            shape: 'rectangle',
            data: chartData,
            left: 'center',
            top: 'center',
            right: null,
            bottom: null,
            sizeRange: [12, 60],
            drawOutOfBound: false,

            // keepAspect: false,
            // rotationRange: [-90, 90],
            // rotationStep: 45,
            // gridSize: 8,
            keepAspect: false,
            rotationRange: [0, 0],
            gridSize: 8,
            textStyle: {
              fontWeight: 'bold',
              color: function () {
                return (
                  'rgb(' +
                  [
                    Math.round(Math.random() * 238) + 50,
                    Math.round(Math.random() * 102),
                    Math.round(Math.random() * 102) + 50
                  ].join(',') +
                  ')'
                );
              }
            },
            emphasis: {
              textStyle: {
                color: '#000099'
              }
            },
            width: '100%',
            height: '100%'
          }
        ];
      }
      option = {
        title: titleData,
        tooltip: tooltipData,
        legend: legendData,
        series: seriesData,
        xAxis: xAxisData,
        yAxis: yAxisData,

        grid: {
          left: '60px',
          right: '20px'
        }
      };
      this.loading = false;

      currentChart.content = myChart;
      option && myChart.setOption(option);
    }
  }
};
</script>
