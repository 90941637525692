<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row
      class="message-date-wrap ma-0"
      style="min-height: calc(100vh - 150px)"
    >
      <v-col cols="12">
        <v-card class="pa-5 overflow-auto" height="100%">
          <v-row class="mb-3" style="min-height: 100%; min-width: 620px">
            <v-col cols="12">
              <v-col cols="12" class="row ma-0 mb-3">
                <div class="col col-auto">
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                </div>
                <div class="col row align-center justify-center">
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <div
                    v-if="$refs.calendar"
                    class="col-auto text-h6 font-weight-black"
                  >
                    {{ $refs.calendar.title }}
                  </div>

                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </div>
                <div class="col-auto">
                  <v-btn outlined class="mr-4" style="visibility: hidden">
                  </v-btn>

                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span> {{ currentMonth }}월 </span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in 12"
                        :key="index"
                        :disabled="item === currentMonth"
                        @click="moveMonth(item)"
                      >
                        <v-list-item-title> {{ item }}월 </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    class="ml-2 px-0"
                    style="min-width: 40px"
                    outlined
                    @click="getCampaignsApi"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-calendar
                ref="calendar"
                v-model="focusDate"
                color="primary"
                style="height: 90%; max-height: 90%"
                :event-more="false"
                :events="messageDateEvents"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @change="updateRange"
                :loading="loading"
              >
                <template v-slot:day-label="{ day, date }">
                  {{ day }}

                  <span
                    v-if="eventCouont(date) > 0"
                    class="ml-1 grey--text text-caption darken-4 font-weight-bold"
                  >
                    ( {{ eventCouont(date) }} )
                  </span>
                </template>

                <template v-slot:event="{ eventParsed }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        :class="`event-item px-1 ${eventParsed.input.color}`"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <strong class="mr-1">
                          {{ eventParsed.start.time }}
                        </strong>
                        {{ eventParsed.input.name }}
                      </div>
                    </template>
                    <span>
                      <v-chip class="mr-1" color="white" outlined small dark>
                        {{ eventParsed.input.campaign_id }}
                      </v-chip>
                      {{ eventParsed.input.name }}
                    </span>
                  </v-tooltip>
                </template>
              </v-calendar>

              <template v-if="selectedOpenDialog">
                <v-menu
                  v-model="selectedOpenDialog"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    max-width="500px"
                    flat
                  >
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-chip outlined class="mr-3">
                        {{ selectedEvent.category }}
                      </v-chip>
                      <v-toolbar-title
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-btn icon @click="selectedOpenDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-chip outlined class="mr-3" small label>
                        캠페인 ID : {{ selectedEvent.campaign_id }}
                      </v-chip>

                      <div class="text-subtitle-1 font-weight-black">
                        {{ selectedEvent.sub_title }}
                      </div>
                      <div class="text-subtitle-2 my-3">
                        {{ selectedEvent.text }}
                      </div>
                      <div v-if="selectedEvent.image_id">
                        <v-img :src="selectedEvent.image.url" width="100%" />
                      </div>
                      <div class="font-weight-black mt-3 text-right">
                        {{
                          $moment(selectedEvent.start).format(
                            'YYYY-MM-DD HH:mm'
                          )
                        }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-col>

            <template v-if="loading">
              <v-row
                class="calendar-dim fill-height ma-0"
                align="center"
                justify="center"
                background-color="primary"
              >
                <v-progress-circular
                  indeterminate
                  color="secondary lighten-1"
                />
              </v-row>
            </template>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MenuTitle from '@/components/MenuTitle.vue';
import { getCampaignsApi } from '@/api/admin/CampaignAPI';

export default {
  components: {
    MenuTitle
  },
  computed: {},
  data() {
    return {
      loading: false,
      selectedOpenDialog: false,
      selectedElement: null,
      selectedEvent: {},
      focusDate: '',
      filters: {
        dateRanges: ['2021-04-01', '2022-04-05'],
        campaignTypes: ['message'],
        activeStatus: [true, false],
        isAdmin: [true],
        approveStatus: ['Y', 'S']
      },
      messageDateEvents: [],
      type: 'month',
      typeToLabel: {
        month: 'Month'
      },
      currentMonth: '',
      colors: [
        'indigo lighten-1',
        'indigo darken-1',
        'indigo darken-4',
        'deep-purple lighten-1',
        'deep-purple darken-1',
        'deep-purple darken-4',
        'blue darken-1',
        'teal',
        'teal darken-1',
        'teal darken-4',
        'green',
        'green darken-2',
        'green darken-4',
        'orange lighten-1',
        'orange darken-2',
        'orange darken-4'
        // 'blue darken-3',
        // 'blue darken-4',
        // 'cyan darken-1',
        // 'cyan darken-3',
        // 'cyan darken-4',
        // 'yellow darken-2',
        // 'yellow darken-3',
        // 'yellow darken-4',
        // 'amber darken-1',
        // 'amber darken-3',
        // 'deep-orange lighten-1',
        // 'deep-orange darken-1',
        // 'deep-orange darken-4',
        // 'deep-orange accent-4',
      ]
    };
  },
  mounted() {
    // const currentMonth = this.$moment().format('YYYY-MM');

    // this.updateRange({
    //   start: {
    //     date: `${currentMonth}-01`
    //   },
    //   end: {
    //     date: this.$moment().format('YYYY-MM-DD')
    //   }
    // });

    if (this.$refs.calendar) {
      this.currentMonth = this.$refs.calendar.days[0].month;
    }
  },
  methods: {
    async getCampaignsApi() {
      this.loading = true;
      this.messageDateEvents = [];

      const params = {
        pagination: 'N',
        filters: this.filters,
        isAdmin: true
      };

      const [error, data] = await getCampaignsApi({ params: params });

      if (error) {
        console.error(error);
      } else {
        const { campaigns } = data;
        const campaignIdList = [];

        let currentDate = '';
        let colorIndex = 0;
        const events = [];

        campaigns.map(campaign => {
          const { campaign_type_push } = campaign;
          if (!campaign_type_push) return;

          colorIndex = campaignIdList.indexOf(campaign_type_push.campaign_id);

          if (colorIndex < 0) {
            campaignIdList.push(campaign_type_push.campaign_id);
            colorIndex = campaignIdList.length - 1;
          }

          currentDate = campaign_type_push.message_date;

          events.push({
            name: campaign_type_push.title,
            start: currentDate + ' ' + campaign_type_push.message_time,
            end: currentDate + ' ' + campaign_type_push.message_time,
            color: this.getEventColor(colorIndex),
            timed: true,
            ...campaign_type_push
          });
        });

        this.messageDateEvents = events;
      }

      this.loading = false;
    },
    getEventColor(idx) {
      let colorIndex = idx || 0;

      const colorLeng = this.colors.length;
      if (idx / colorLeng >= 1) {
        colorIndex = idx % colorLeng;
      }

      return this.colors[colorIndex];
    },
    setToday() {
      this.focusDate = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    moveMonth(month) {
      if (parseInt(this.currentMonth) === month) return;

      this.$refs.calendar.move(month - parseInt(this.currentMonth));
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // console.log(this.selectedEvent);
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpenDialog = true))
        );
      };

      if (this.selectedOpenDialog) {
        this.selectedOpenDialog = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    eventCouont(date) {
      const res = this.messageDateEvents.filter(
        item => item && item.message_date && item.message_date === date
      );
      return res.length;
    },
    updateRange(date) {
      const { start, end } = date;

      this.currentMonth = start.month;

      this.filters.dateRanges = [
        this.$moment(`${start.date}`, 'YYYY-MM-DD')
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        this.$moment(`${end.date}`, 'YYYY-MM-DD')
          .add(7, 'days')
          .format('YYYY-MM-07')
      ];

      this.getCampaignsApi();
    }
  }
};
</script>
<style lang="scss">
.message-date-wrap {
  .event-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .v-calendar-weekly__day > .v-event {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .v-calendar-weekly__day {
    &::-webkit-scrollbar {
      display: none;
    }

    overflow: auto !important;
    scrollbar-width: none; /* Firefox */

    .v-calendar-weekly__day-label {
      padding: 5px;
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #fff;
      margin: 0;
    }
  }

  .calendar-dim {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
}
</style>
