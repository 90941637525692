<template>
  <div v-if="authenticated">
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawer"
      app
      clipped
      :mini-variant="!$isMobile && !mini"
      mobile-breakpoint="600"
    >
      <div class="mt-5 text-center d-inline-block d-sm-none" style="width:100%">
        <UserPointButton />
      </div>
      <v-list nav dense>
        <div v-for="(menu, mIndex) in menus" :key="mIndex">
          <v-list-group
            v-if="menu.items"
            v-model="menu.active"
            sub-group
            color="dark"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="menu.text"
                  class="font-weight-bold"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(item, i) in menu.items"
              :key="i"
              link
              :to="item.link"
              exact
            >
              <v-list-item-icon>
                <v-tooltip right color="rgba(0,0,0,1)" :disabled="mini">
                  <template #activator="{ on: onTooltip }">
                    <v-icon v-on="{ ...onTooltip }">{{ item.icon }}</v-icon>
                  </template>
                  <span class="title">{{ item.text }}</span>
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else link :to="menu.link" exact>
            <v-list-item-icon>
              <v-tooltip right color="rgba(0,0,0,1)" :disabled="mini">
                <template #activator="{ on: onTooltip }">
                  <v-icon v-on="{ ...onTooltip }">{{ menu.icon }}</v-icon>
                </template>
                <span class="title">{{ menu.text }}</span>
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <BottomMenu />
    </v-navigation-drawer>

    <v-app-bar v-if="authenticated" app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="openNav()" />
      <v-toolbar-title>
        <v-img width="100" min-width="100" :src="$mainLogoPath"></v-img>
      </v-toolbar-title>

      <v-spacer />
      <div style="width:200px">
        <UserPointButton class="mr-lg-5 d-sm-block  d-none" />
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <v-list width="300" max-width="400">
          <v-list-item>
            <v-list-item-avatar color="primary" size="48">
              <span class="white--text headline">
                {{ user.name.slice(0, 2) }}
              </span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title"
                >{{ user.name }}님 반가워요😊</v-list-item-title
              >
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item
            v-for="(menu, index) in memberMenus"
            :key="index"
            link
            :to="{
              name: menu.name,
              params: menu.params
            }"
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout" :disabled="loading">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <div style="min-height:95vh">
        <router-view name="auth" />
      </div>
      <v-footer v-if="authenticated" padless inset>
        <v-card color="#efefef" flat tile width="100%">
          <v-divider></v-divider>
          <v-card-text class="pa-2">
            <div>
              <ul class="list">
                <li>주식회사 티디아이</li>
                <li>대표자 : 이승주</li>
                <li>통신판매업신고 : 제 2012-부산해운-004호</li>
                <li>사업자번호 : 617-81-95610</li>
                <li>
                  연락처 : 070-5097-1656
                </li>
                <li>
                  주소 : 서울특별시 서초구 반포대로 20길 29 (서초동, TDI 타워)
                </li>
              </ul>
              <address class="copyright">
                © Noti-i. All Rights Reserved.
              </address>
            </div>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-main>

    <v-snackbar :value="errors" :timeout="2000">
      {{ errors }}
      <v-btn color="blue" text @click="errors = false">
        닫기
      </v-btn>
    </v-snackbar>
    <RechargeDialog v-if="rechargeDialog" />
    <CreditCardDialog v-if="creditCardDialog" />
    <BankTransferDialog v-if="bankTransferDialog" />
    <BankInfoDialog v-if="bankInfoDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BottomMenu from '@/components/menu/BottomMenu.vue';
import UserPointButton from '@/components/user/UserPointButton.vue';
import RechargeDialog from '@/components/payment/RechargeDialog.vue';
import CreditCardDialog from '@/components/payment/CreditCardDialog.vue';
import BankTransferDialog from '@/components/payment/BankTransferDialog.vue';
import BankInfoDialog from '@/components/payment/BankInfoDialog.vue';

export default {
  components: {
    BottomMenu,
    UserPointButton,
    RechargeDialog,
    CreditCardDialog,
    BankTransferDialog,
    BankInfoDialog
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      errors: 'errors',
      menus: 'menus/menus',
      creditCardDialog: 'dialog/creditCardDialog',
      rechargeDialog: 'dialog/rechargeDialog',
      bankTransferDialog: 'dialog/bankTransferDialog',
      bankInfoDialog: 'dialog/bankInfoDialog'
    })
  },
  data: () => ({
    drawer: null,
    mini: true,
    items: [{ icon: 'mdi-view-dashboard', text: '대시보드', link: '/admin' }],
    breadcrumbs: [],
    loading: false,
    memberMenus: [
      {
        text: 'My Page',
        icon: 'mdi-account',
        name: 'AdvertiserMember',
        params: {
          menu: 'mypage'
        }
      },
      {
        text: '결제관리',
        icon: 'mdi-credit-card-settings-outline',
        name: 'AdvertiserMember',
        params: {
          menu: 'payment'
        }
      },
      {
        text: '1:1 문의',
        icon: 'mdi-account-question',
        name: 'AdvertiserMember',
        params: {
          menu: 'inquiry'
        }
      },
      {
        text: '공지사항',
        icon: 'mdi-bullhorn',
        name: 'AdvertiserMember',
        params: {
          menu: 'notice'
        }
      }
    ]
  }),
  mounted() {
    this.loadUserPoint();
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout',
      loadUserPoint: 'user/loadUserPoint'
    }),

    logout() {
      this.loading = true;
      this.logoutAction().then(() => {
        this.loading = false;
        this.$router.replace({
          name: 'login'
        });
      });
    },
    openNav() {
      this.drawer = !this.drawer;

      // // pc, 모바일 화면에 따른 nav 기능 을 지정합니다.
      // if (!this.$isMobile) {
      //   // pc 화면인경우 아이콘을 단축시킵니다.
      //   this.mini = !this.mini;
      // } else {
      //   // 모바일 화면인 경우 숨김 처리를 설정합니다.
      //   this.drawer = !this.drawer;
      // }
    }
  }
};
</script>
<style scoped>
.list {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.list li {
  float: left;
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin-right: 15px;
}
.copyright {
  clear: both;
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
</style>
