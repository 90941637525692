var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-spacer',{staticClass:"py-3"}),_c('h1',[_vm._v("광고 매출 통계")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MediaHomeCharts',{attrs:{"title":"","dateRanges":_vm.dateRanges,"stats":_vm.chartStats,"loading":_vm.loading}})],1)],1),_c('v-spacer',{staticClass:"py-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h1',[_vm._v("매체별 매출 요약")]),_c('v-card',{staticClass:"overflow-y-auto",staticStyle:{"background-color":"rgba(255, 255, 255, 0)"},attrs:{"min-height":"480","max-height":"480","flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.mediaSummary),function(media,index){return _c('v-col',{key:index,attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"ma-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(media.name)+" ")]),_c('v-card-text',[_c('p',{staticClass:"ma-0"},[_vm._v("이번달 매출: "+_vm._s(_vm._f("KRW")(media.sales)))]),_c('p',[_vm._v(" 노티 라이브: "+_vm._s(Number(media.reach_count).toLocaleString())+" ")])])],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("공지사항")]),(!_vm.noticeLoading)?_c('v-card',{staticClass:"pa-3",attrs:{"min-height":"200","max-height":"280"}},[_c('Table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"columns":_vm.columns,"bordered":"","pagination":false,"data-source":_vm.notices,"locale":{
                emptyText: '등록된 공지사항이 없습니다.'
              },"size":"small","customRow":function (record) {
                  return {
                    on: {
                      click: function (event) {
                        _vm.$router.push({
                          name: 'MediaNotice',
                          query: { nid: record.key }
                        });
                      }
                    }
                  };
                }},scopedSlots:_vm._u([{key:"noticeTitle",fn:function(value, record){return [(record.isUrgent)?_c('v-chip',{attrs:{"color":"error","small":"","label":"","outlined":""}},[_vm._v("긴급")]):_vm._e(),_vm._v(" "+_vm._s(value)+" ")]}}],null,false,166399995)})],1):_c('v-skeleton-loader',{attrs:{"type":"card"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("1:1 문의")]),(!_vm.inquiryLoading)?_c('v-card',{staticClass:"d-flex justify-center pa-5 mb-5 align-center",attrs:{"min-height":"200","max-height":"200"}},[(_vm.inquiries.length > 0)?_c('v-expansion-panels',{attrs:{"readonly":"","focusable":""}},_vm._l((_vm.inquiries),function(inquiry,index){return _c('v-expansion-panel',{key:index,on:{"click":function($event){return _vm.$router.push({
                    name: 'MediaInquiry',
                    query: { panel: index }
                  })}}},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(inquiry.reply !== null)?_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle ")]),_c('span',{staticClass:"ml-2"},[_vm._v("답변완료")])],1):_vm._e(),(inquiry.reply === null)?_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-circle-slice-6 ")]),_c('span',{staticClass:"ml-2"},[_vm._v("답변대기")])],1):_vm._e()]},proxy:true}],null,true)},[_c('div',[_c('h3',[_vm._v(" "+_vm._s(inquiry.created_at.substr(0, 10).replaceAll('-', '.'))+" ")]),_c('div',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(inquiry.content)+" ")])])])],1)}),1):_c('div',[_c('InquiryDialog',{attrs:{"title":"1:1 문의하기"},on:{"getInquiryApi":function($event){return _vm.getInquiryApi()}}})],1)],1):_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }