import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import {
  SettopBoxStatus,
  SettopBoxCableStatus
} from '@/config/admin/campaign-did';
import { DidSettopBoxConfig } from './DidSettopBoxConfig';
import { DidApp } from './DidApp';

export class DidSettopBox {
  origin = null;
  id = null;
  name = null;
  uid = null;
  macAddress = null;
  dailySubscribeCount = 0;
  costPerDay = 0; // 일간 상품 단위 금액
  costStandardSecond = 0; // 단위 초
  costPerSecond = null; //  단위 금액
  userId = null;
  didBranchId = null;
  appUpdateStatus = 'N';
  //  수정 no
  devicePlayStatus = 'N'; // 재생 상태
  deviceConnectionStatus = 'N'; // 연결 상태
  deviceUpdatedAt = null; // 기기 업데이트 시간

  config = new DidSettopBoxConfig(undefined, true);
  didAppId = null;
  app = new DidApp(undefined, true);
  branch = null;

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidSettopBox(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (key === 'config') {
        val = new DidSettopBoxConfig(val, true);
      }
      if (key === 'app') {
        val = new DidApp(val, true);
      }
      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidSettopBox(this);
    }
  }

  /** @description: 회원 설정 상태  */
  get configStatus() {
    return this.config.status === 'Y' ? 1 : 0;
  }
  /** @description: 회원 설정 상태  */
  set configStatus(val) {
    this.config.status = val ? 'Y' : 'N';
  }
  //  상태 정보
  get statusInfo() {
    return (
      SettopBoxStatus.find(status => status.value === this.config.status) ??
      SettopBoxStatus[SettopBoxStatus.length - 1]
    );
  }
  //  재생 상태 정보
  get devicePlayStatusInfo() {
    return (
      SettopBoxStatus.find(status => status.value === this.devicePlayStatus) ??
      SettopBoxStatus[SettopBoxStatus.length - 1]
    );
  }
  //  연결 상태 정보
  get deviceConnectionStatusInfo() {
    return (
      SettopBoxCableStatus.find(
        status => status.value === this.deviceConnectionStatus
      ) ?? SettopBoxCableStatus[SettopBoxCableStatus.length - 1]
    );
  }
  /** @description: 앱 다운로드 링크 */
  get appLink() {
    return this.app ? this.app?.link : '-';
  }
  /** @description: 지점 이름 */
  get didBranchName() {
    return this.branch?.name;
  }
  /** @description: 매체 그룹 이름 */
  get didMediaGroupName() {
    return this.branch?.didMediaGroup?.name;
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }

  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.getUpdatePayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.id
      ? this.origin.getUpdatePayload(true)
      : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;
        if (['config', 'app'].includes(key)) {
          isSameData = this[key].isChanged();
        } else if (
          typeof changed[key] === 'object' ||
          typeof val === 'object'
        ) {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  getCreatePayload() {
    // let changedValueKeys = [];
    return {
      didBranchId: this.didBranchId,
      appUpdateStatus: this.appUpdateStatus,
      name: this.name,
      uid: this.uid,
      // macAddress: this.macAddress,
      dailySubscribeCount: this.dailySubscribeCount,
      costPerSecond: this.costPerSecond,
      costStandardSecond: this.costStandardSecond,
      didAppId: this.didAppId,
      config: this.config.getCreatePayload(),
      costPerDay: this.costPerDay
    };

    // // 값 체크 용도
    // if (isChecked) return res;

    // // changedValueKeys = Object.keys(res);
    // // const changedValues = this.changedValues(changedValueKeys, res);

    // return {
    //   ...res
    //   // changedValues: changedValues
    // };
  }

  getUpdatePayload(isChecked = false) {
    const res = {
      id: this.id,
      didBranchId: this.didBranchId,
      appUpdateStatus: this.appUpdateStatus,
      name: this.name,
      uid: this.uid,
      // macAddress: this.macAddress,
      dailySubscribeCount: this.dailySubscribeCount,
      costPerSecond: this.costPerSecond,
      costStandardSecond: this.costStandardSecond,
      didAppId: this.didAppId,
      config: this.config.getUpdatePayload(),
      costPerDay: this.costPerDay
    };

    // 값 체크 용도
    if (isChecked) return res;

    return {
      ...res,
      newUid: this.uid !== this.origin?.uid ? this.uid : ''
    };
  }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = changedObj[key] === val;

      if (['config', 'app'].includes(key)) {
        isSameData = this[key].isChanged();
      } else if (
        typeof changedObj[key] === 'object' ||
        typeof val === 'object'
      ) {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
