<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="재생 상태">
        <a-select
          :style="{ minWidth: '200px' }"
          v-model="filters.devicePlayStatus"
          mode="multiple"
        >
          <a-select-option
            v-for="item in SettopBoxStatus"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="연결 상태">
        <a-select
          :style="{ minWidth: '200px' }"
          v-model="filters.deviceConnectionStatus"
          mode="multiple"
        >
          <a-select-option
            v-for="item in SettopBoxCableStatus"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="설정 상태">
        <a-select
          :style="{ minWidth: '200px' }"
          v-model="filters.status"
          mode="multiple"
        >
          <a-select-option
            v-for="item in SettopBoxConfigStatus"
            :key="item.value"
            :value="item.value"
            multiple
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="매체">
        <a-select :style="{ minWidth: '200px' }" v-model="filters.didBranchId">
          <a-select-option value=""> 전체 </a-select-option>
          <a-select-option
            v-for="item in didBranches"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="검색" :span="2">
        <a-input-group compact>
          <a-select style="width: 150px" v-model="filters.searchKey">
            <a-select-option value="name"> 이름 </a-select-option>
            <a-select-option value="uid"> UID </a-select-option>
            <!-- <a-select-option value="macAddress"> Mac주소 </a-select-option> -->
          </a-select>

          <a-input-search
            v-model="filters.searchText"
            @search="$emit('search')"
            style="width: calc(100% - 150px); max-width: 1000px"
          />
        </a-input-group>
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>

      <v-btn
        class="ml-3"
        color="#00d1b2"
        outlined
        @click="handleInitClick()"
        :disabled="loading"
      >
        초기화
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  SettopBoxStatus,
  SettopBoxCableStatus
} from '@/config/admin/campaign-did';

export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      SettopBoxStatus: SettopBoxStatus,
      SettopBoxCableStatus: SettopBoxCableStatus,
      SettopBoxConfigStatus: SettopBoxStatus.filter(
        item => item.value !== 'UNKNOWN'
      )
    };
  },
  computed: {
    ...mapGetters({
      filters: 'didSettopBox/filters',
      didBranches: 'didSettopBox/didBranches'
    })
  },
  methods: {
    ...mapActions({
      initFilters: 'didSettopBox/initFilters'
    }),
    handleInitClick() {
      this.initFilters();
      this.$emit('search');
    }
  }
};
</script>
