<template>
  <v-row class="align-center">
    <v-col>
      <h2>
        {{ currentStepText }}
        <span v-if="currentStep === 2 && didCampaignService.type">
          |
          {{ didCampaignService.typeText }}</span
        >
      </h2>
    </v-col>

    <v-col cols="12" lg="auto" xl="4" class="ml-auto">
      <v-stepper
        :value="currentStep"
        style="background-color: transparent; box-shadow: none"
      >
        <v-stepper-header class="row ma-0 align-center">
          <v-row
            v-for="step in campaignSteps"
            :key="`${step.value}-step`"
            class="pa-0 ma-0"
            justify="center"
            align="center"
          >
            <v-stepper-step
              :complete="currentStep > step.value"
              :step="step.value"
              color="secondary"
              :editable="didCampaignService.id"
              :rules="[() => step.isRules]"
              @click="validateStep(step.value)"
            >
              {{ step.title }}
              <small v-if="!step.isRules">미완료</small>
            </v-stepper-step>

            <v-divider
              v-if="step.value !== campaignSteps.length"
              style="min-width: 50px"
            />
          </v-row>
        </v-stepper-header>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentStep: 'didCampaignService/currentStep',
      didCampaignService: 'didCampaignService/didCampaignService',
      isDialogTypeEdit: 'didCampaignService/isDialogTypeEdit',
      campaignSteps: 'didCampaignService/didCampaignServiceSteps'
    }),
    currentStepText() {
      return this.campaignSteps[this.currentStep - 1].title;
    }
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'didCampaignService/setCurrentStep'
    }),
    /**@description: 수정시 단계롤 바로 건너 뛸때, validation 체크를 진행합니다. */
    async validateStep(step) {
      // 현재 컴포넌트의 부모컴포넌트에서 자식컴포넌트를 조회하여 설정한 refs 를 가져옵니다.

      const refs =
        this.$parent.$children.find(
          v => v.$refs[`obs${this.currentStep}`] != undefined
        )?.$refs || null;

      if (refs === null) {
        return false;
      }

      // 설정한 vee-validate observer 의 refs 를 가져와 validate 를 진행합니다.
      const valid = await refs[`obs${this.currentStep}`].validate();
      if (valid) {
        this.setCurrentStep(step);
      }
    }
  }
};
</script>

<style></style>
