import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
// import campaign from './admin/campaign'
// import media from './admin/media'
// import advertiser from './admin/advertiser'
// import mediaGroup from './admin/media-group'
// import advertiserGroup from './admin/advertiser-group'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        errors: false,
    },

    getters: {
        errors(state) {
            return state.errors
        },
        userRoleList(state) {
            return state.userRoleList
        }
    },

    mutations: {
        SET_ERROR(state, errors) {
            state.errors = errors
        },

    },

    actions: {
        setError({
            commit
        }, errors) {
            commit('SET_ERROR', errors)
        },
    },

    modules: {
        auth,
        // campaign,
        // media,
        // mediaGroup,
        // advertiser,
        // advertiserGroup
    }
})