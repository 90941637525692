<template>
  <v-bottom-sheet v-if="campaign" v-model="sheet" inset persistent>
    <v-card flat height="600px" width="100%">
      <v-toolbar flat dark>
        <v-toolbar-title
          >{{ campaign.id }}번 {{ campaign.name }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('closeBottomSheet')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-row dense>
          <v-col cols="12">
            <DatePicker
              ref="datePicker"
              class="ml-auto"
              title="기간"
              :prefix="3"
              @setDateRanges="
                v => {
                  dateRanges = v;
                }
              "
            />
          </v-col>
          <v-col cols="12">
            <v-simple-table
              v-if="summary && !loading"
              class="pa-1 elevation-1"
              style="white-space:nowrap"
            >
              <thead>
                <tr style="">
                  <th class="text-center" style="width:50px">기간</th>
                  <th class="text-right">노출</th>
                  <th class="text-right">도달</th>
                  <th class="text-right">클릭</th>
                  <th class="text-right">CTR</th>
                </tr>
              </thead>
              <tbody>
                <tr style="">
                  <td class="text-center">Total</td>
                  <td class="text-right">
                    {{ summary.impression.toLocaleString() }}
                  </td>
                  <td class="text-right">
                    {{ summary.reach.toLocaleString() }}
                  </td>
                  <td class="text-right">
                    {{ summary.click.toLocaleString() }}
                  </td>
                  <td class="text-right">
                    {{ summary.ctr }}
                    %
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <CampaignDetailChart
              v-if="!loading"
              title="일일 차트"
              :stats="stats"
              :dateRanges="dateRanges"
            />
            <v-skeleton-loader
              v-show="loading"
              type="image, image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import CampaignDetailChart from './CampaignDetailChart.vue';
export default {
  components: {
    DatePicker,
    CampaignDetailChart
  },
  props: {
    sheet: {
      type: Boolean,
      required: true
    },
    campaign: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: true,
      dateRanges: [],
      chartRef: 'bottomSheetChart',
      stats: [],
      summary: null
    };
  },
  watch: {
    async dateRanges(value) {
      if (value) {
        const { stats, reach } = await this.getCampaignDetailApi();
        this.stats = stats;

        const impression = stats
          .filter(v => v.type === 'impression')
          .reduce((acc, cur) => {
            return acc + cur.count;
          }, 0);

        const click = stats
          .filter(v => v.type === 'click')
          .reduce((acc, cur) => {
            return acc + cur.count;
          }, 0);

        const ctr =
          impression == 0
            ? 0
            : parseFloat(((click / impression) * 100).toFixed(2));
        this.summary = {
          reach: reach,
          impression: impression,
          click: click,
          ctr: ctr
        };
      }
    }
  },
  methods: {
    async getCampaignDetailApi() {
      if (this.campaign == null) {
        return false;
      }
      this.loading = true;
      try {
        const { data } = await this.$axios.get(
          `/admin/campaign-detail/${this.campaign.id}`,
          {
            params: {
              dateRanges: this.dateRanges
            }
          }
        );
        console.log(data);
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }
  }
};
</script>

<style></style>
