<template>
  <validation-observer ref="reportFilters">
    <v-card class="pa-5 my-5">
      <v-row align="center">
        <v-col cols="12" lg="3">
          <div class="d-flex align-center">
            <span
              style="min-width:80px; white-space:nowrap;"
              class="text-right mr-2"
            >
              매체 그룹
            </span>
            <validation-provider style="width:100%;" v-slot="{ errors }">
              <v-autocomplete
                v-model="mediaGroupIds"
                ref="mediaGroupAC"
                outlined
                hide-no-data
                hide-details
                full-width
                multiple
                clearable
                placeholder="매체 그룹을 선택하세요."
                :items="mediaGroups"
                item-text="name"
                item-value="id"
                :disabled="loading"
                :error-messages="errors"
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span v-if="mediaGroupIds.length === mediaGroups.length"
                      >전체선택</span
                    >
                    <span v-else
                      >{{ item.name }}
                      {{
                        mediaGroupIds.length > 1
                          ? ` 외 ${mediaGroupIds.length - 1} 개`
                          : ''
                      }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleMediaGroup">
                    <v-list-item-action>
                      <v-icon
                        :color="mediaGroupIds.length > 0 ? 'primary' : ''"
                      >
                        {{ mediaGroupIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        전체선택
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </v-col>
        <v-col cols="12" lg="3">
          <div class="d-flex align-center">
            <span
              style="min-width:80px; white-space:nowrap;"
              class="text-right mr-2"
            >
              매체
            </span>
            <validation-provider style="width:100%;" v-slot="{ errors }">
              <v-autocomplete
                v-model="mediaIds"
                ref="mediaAC"
                outlined
                hide-no-data
                hide-details
                full-width
                multiple
                clearable
                placeholder="매체를 선택하세요."
                :items="medias"
                item-text="name"
                dense
                item-value="id"
                :disabled="loading"
                :error-messages="errors"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span v-if="mediaIds.length === medias.length"
                      >전체선택</span
                    >
                    <span v-else
                      >{{ item.name }}
                      {{
                        mediaIds.length > 1
                          ? ` 외 ${mediaIds.length - 1} 개`
                          : ''
                      }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleMedia">
                    <v-list-item-action>
                      <v-icon :color="mediaIds.length > 0 ? 'primary' : ''">
                        {{ mediaIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        전체선택
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="ml-auto text-lg-right">
          <div class="d-inline-flex align-center ">
            <date-picker
              ref="datePicker"
              class="ml-auto"
              title="기간"
              :prefix="3"
              @setDateRanges="
                v => {
                  dateRanges = v;
                  emitFilters();
                }
              "
            ></date-picker>
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  icon
                  @click="emitFilters"
                  :disabled="loading"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span class="title">새로고침</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DatePicker from '@/components/DatePicker.vue';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    DatePicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mediaGroupIds: [],
      mediaGroups: [],
      mediaIds: [],
      medias: [],
      dateRanges: [],
      selectedLiveType: 'media'
    };
  },
  computed: {
    allMedia() {
      return this.mediaIds.length === this.filteredMedias.length;
    },
    someMedia() {
      return this.mediaIds.length > 0 && !this.allMedia;
    },
    filteredMedias() {
      return this.$refs.mediaAC?.filteredItems || this.medias;
    },
    mediaIcon() {
      if (this.allMedia) return 'mdi-close-box';
      if (this.someMedia) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    filteredMediaGroups() {
      return this.$refs.mediaGroupAC?.filteredItems || this.mediaGroups;
    },
    allMediaGroup() {
      return this.mediaGroupIds.length === this.filteredMediaGroups.length;
    },
    someMediaGroup() {
      return this.mediaGroupIds.length > 0 && !this.allMediaGroup;
    },
    mediaGroupIcon() {
      if (this.allMediaGroup) return 'mdi-close-box';
      if (this.someMediaGroup) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    mediaIds(newVal) {
      if (newVal) {
        this.emitFilters();
      }
    },
    mediaGroupIds(newVal) {
      if (newVal.length > 0) {
        this.setMedias();
      } else {
        this.medias = [];
        this.mediaIds = [];
      }
    }
  },
  async mounted() {
    // 매체그룹
    const mediaGroups = await this.getMediaGroups();
    this.mediaGroups = mediaGroups;
    this.mediaGroupIds = mediaGroups.map(m => m.id);
  },
  methods: {
    async setMedias() {
      const medias = await this.getMedias();
      this.medias = medias;
      this.mediaIds = medias.map(m => m.id);
    },
    async getMediaGroups() {
      try {
        const { data } = await this.$axios.get('/admin/report-media-groups');
        return data?.mediaGroups || [];
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    async getMedias() {
      try {
        const { data } = await this.$axios.get('/admin/report-medias', {
          params: {
            mediaGroupIds: this.mediaGroupIds
          }
        });
        return data?.medias || [];
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    toggleMediaGroup() {
      this.$nextTick(() => {
        if (this.allMediaGroup) {
          this.mediaGroupIds = [];
        } else {
          this.mediaGroupIds = this.filteredMediaGroups.slice().map(v => v.id);
        }
      });
    },
    toggleMedia() {
      this.$nextTick(() => {
        if (this.allMedia) {
          this.mediaIds = [];
        } else {
          this.mediaIds = this.filteredMedias.slice().map(v => v.id);
        }
      });
    },
    emitFilters() {
      const filters = {
        liveType: this.selectedLiveType,
        mediaIds: this.mediaIds,
        dateRanges: this.dateRanges
      };
      this.$emit('applyFilters', filters);
    }
  }
};
</script>

<style></style>
