import { getCamelKeys } from '@/utils/functions';

export class CouponConfig {
  _couponShop = null;

  price = null;
  expiresAt = null;
  id = null;
  branches = null;
  image = null;
  landingUrl = null;
  image = null;
  maxUses = null;

  constructor(init) {
    if (!init) return;
    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      this[key] = val;
    }
  }

  get couponPrice() {
    return this.price;
  }

  set couponPrice(val) {
    this.price = val;
  }

  get couponExpiresAt() {
    return this.expiresAt;
  }

  set couponExpiresAt(val) {
    this.expiresAt = val;
  }

  get couponConfigId() {
    return this.id;
  }

  set couponConfigId(val) {
    this.id = val;
  }

  get couponBranches() {
    return this.branches;
  }

  set couponBranches(val) {
    this.branches = val;
  }

  get couponShopImageUrl() {
    return this.image;
  }

  set couponShopImageUrl(val) {
    this.image = val;
  }

  get couponLandingUrl() {
    return this.landingUrl;
  }

  set couponLandingUrl(val) {
    this.landingUrl = val;
  }

  get couponShop() {
    return this._couponShop;
  }

  set couponShop(val) {
    this._couponShop = val;
    this.couponShopId = val?.id || null;
    this.couponShopName = val?.name || null;
    this.couponShopAddress = val?.address || null;
  }
}
