import moment from 'moment';
import { getPointsApi } from '@/api/advertiser/UserAPI';
import { PointTypeFlags, PointStatusFlags } from '@/config/charge';

export default {
  namespaced: true,

  state: {
    loading: false,
    buckets: [],
    columns: [
      {
        title: '날짜',
        align: 'center',
        dataIndex: 'transactionAt'
      },
      {
        title: '주문번호',
        dataIndex: 'orderNo',
        align: 'center'
      },
      {
        title: '충전 포인트',
        dataIndex: 'amount',
        align: 'right'
      },
      {
        title: '유상/무상',
        dataIndex: 'typeFlag',
        align: 'center',
        scopedSlots: {
          customRender: 'typeFlag'
        }
      },
      {
        title: '비고',
        dataIndex: 'remark',
        align: 'center'
      }
    ],
    payload: {
      page: 1,
      perPage: 20,
      statusFlag: '+',
      code: ['CD000'],
      dates: [],
      orderNo: '',
      typeFlag: ['C', 'F']
    },
    pagination: {
      totalPage: 1,
      total: 0
    }
  },
  getters: {
    columns(state) {
      return state.columns;
    },
    payload(state) {
      return state.payload;
    },
    pagination(state) {
      return state.pagination;
    },
    buckets(state) {
      return state.buckets;
    },
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    SET_BUCKETS(state, value) {
      state.buckets = value;
    },
    SET_PAGINATION(state, value) {
      state.pagination = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async index({ commit, getters }) {
      commit('SET_LOADING', true);

      const payload = {
        params: getters.payload
      };

      const [error, data] = await getPointsApi(payload);
      if (error) {
        console.error(error);
      } else {
        const { points, last_page, total } = data;

        const items = points.map((item, index) => {
          return {
            key: index,
            transactionAt: moment(item.transaction_at).format('YYYY-MM-DD'),
            amount: Number(item.amount).toLocaleString(),
            typeFlag:
              PointTypeFlags.find(v => v.value === item.type_flag)?.text ||
              null,
            orderNo: item?.order_no || '-',
            remark: item.remark,
            statusFlag:
              PointStatusFlags.find(v => v.value === item.status_flag)?.text ||
              null
          };
        });

        commit('SET_PAGINATION', {
          totalPage: last_page,
          total: total
        });

        commit('SET_BUCKETS', items);
        commit('SET_LOADING', false);
      }
    }
  }
};
