var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":"","persistent":"","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("앱 "+_vm._s(_vm.titleText))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleCloseClick}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5 py-3"},[_c('ValidationObserver',{ref:_vm.refKey},[_c('v-row',{attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"앱 이름","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!errors.length,"placeholder":"앱 이름","required":"","outlined":"","clearable":"","dense":""},on:{"focus":function($event){return _vm.inputFocus($event.target)}},model:{value:(_vm.app.name),callback:function ($$v) {_vm.$set(_vm.app, "name", $$v)},expression:"app.name"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"패키지 이름","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!errors.length,"placeholder":"패키지 이름","required":"","outlined":"","clearable":"","dense":""},on:{"focus":function($event){return _vm.inputFocus($event.target)}},model:{value:(_vm.app.package),callback:function ($$v) {_vm.$set(_vm.app, "package", $$v)},expression:"app.package"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"버전","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!errors.length,"placeholder":"버전","required":"","outlined":"","clearable":"","dense":""},on:{"focus":function($event){return _vm.inputFocus($event.target)}},model:{value:(_vm.app.version),callback:function ($$v) {_vm.$set(_vm.app, "version", $$v)},expression:"app.version"}})]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"버전 코드","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ numeric: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":!errors.length,"placeholder":"버전 코드","required":"","outlined":"","clearable":"","dense":"","type":"number"},on:{"focus":function($event){return _vm.inputFocus($event.target)}},model:{value:(_vm.app.versionCode),callback:function ($$v) {_vm.$set(_vm.app, "versionCode", $$v)},expression:"app.versionCode"}})]}}])})]},proxy:true}])}),(!_vm.app.id)?_c('CustomGridItem',{attrs:{"title":".apk 파일 업로드","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"color":"accent-4","placeholder":".apk 형식의 파일을 업로드해 주세요.","prepend-inner-icon":"mdi-paperclip","prepend-icon":"","error-messages":errors,"hide-details":!errors.length,"persistent-placeholder":"","outlined":"","show-size":"","dense":"","accept":"application/vnd.android.package-archive"},on:{"click:clear":_vm.handleRemoveClick,"change":_vm.handleFileChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}],null,false,1878011795)})]},proxy:true}],null,false,2921026346)}):_vm._e(),(_vm.app.id)?_c('CustomGridItem',{attrs:{"outlined":"","md":12,"titleClass":"col-12"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',{staticClass:"title-text ma-0",attrs:{"align":"center"}},[_vm._v(" 링크 "),_c('v-spacer'),(_vm.app.link)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.copyToClipboard('appLink')}}},Object.assign({}, onTooltip)),[_c('v-icon',{attrs:{"size":"medium"}},[_vm._v("mdi-content-duplicate")]),_vm._v(" 복사 ")],1)]}}],null,false,1122339672)},[_c('span',[_vm._v("링크 복사하기")])]):_vm._e()],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-col',[_vm._v(" "+_vm._s(_vm.app.link || '-')+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.app.link),expression:"app.link"}],attrs:{"type":"text","id":"appLink"},domProps:{"value":(_vm.app.link)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.app, "link", $event.target.value)}}})])]},proxy:true}],null,false,946229777)}):_vm._e(),(_vm.app.id)?_c('CustomGridItem',{attrs:{"title":"갱신일","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',[_vm._v(" "+_vm._s(_vm.app.updatedAt ? _vm.app.updatedAt : '-')+" ")])]},proxy:true}],null,false,3769664541)}):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"secondary":"","outlined":""},on:{"click":_vm.handleCloseClick}},[_vm._v(" 닫기 ")]),_c('v-btn',{attrs:{"color":"secondary","loading":_vm.dialogLoading,"disabled":_vm.dialogLoading || (_vm.app.id ? !this.app.isChanged() : false)},on:{"click":_vm.handleSaveClick}},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.dialogLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }