<template>
  <v-dialog :value="dialog" max-width="900" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>CRM 관리 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="">
        <ValidationObserver ref="notificationsDialogRef">
          <div class="d-flex ma-0">
            <v-overlay :value="loading">
              <v-progress-circular indeterminate size="64" />
            </v-overlay>

            <v-col class="row ma-0 flex-column">
              <v-row class="ma-0 pb-2" justify="end">
                <v-btn
                  small
                  color="secondary"
                  @click="handleNotificationAddClick"
                >
                  추가
                </v-btn>
              </v-row>
              <v-list
                class=""
                style="
                  width: 100%;
                  height: 40vh;
                  border: 1px solid #999;
                  border-radius: 5px;
                  max-height: 500px;
                  overflow: auto;
                "
              >
                <v-list-item-group
                  v-model="selectedIdx"
                  active-class="pink--text"
                >
                  <template v-for="(item, index) in notifications">
                    <v-list-item :key="`item${index}`">
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold d-flex align-center"
                        >
                          {{ item.title }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="pt-2 overflow-eclips">
                          {{ item.body }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      v-if="index < notifications.length - 1"
                      :key="`divider${index}`"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-divider
              v-if="notification && notification.id !== null"
              class="mx-2"
              vertical
            ></v-divider>

            <v-col
              style="min-width: 300px"
              v-if="notification && notification.id !== null"
            >
              <ValidationProvider v-slot="{ errors }" rules="required">
                <div class="mx-0 my-3 d-flex justify-center align-center">
                  <div class="col pa-0 pr-3">
                    <v-text-field
                      v-model="notification.title"
                      label="제목"
                      class="mr-3 col"
                      hide-details
                      required
                      outlined
                      dense
                    />
                  </div>
                </div>
                <div v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-textarea
                  v-model="notification.body"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                  outlined
                  label="내용"
                  flat
                  class="text-subtitle-2 pa-0"
                  rows="12"
                />
              </ValidationProvider>
              <v-col class="pa-0 py-3 text-right">
                <v-btn
                  small
                  secondary
                  outlined
                  class="mr-2"
                  @click="
                    () => {
                      selectedIdx = undefined;
                      selectNotification(null);
                    }
                  "
                  :disabled="loading"
                >
                  취소
                </v-btn>

                <v-btn
                  v-if="!!notification.id && !notification.is_default"
                  small
                  color="red"
                  outlined
                  class="mr-2"
                  @click="deleteNotification(notification.id)"
                  :disabled="loading"
                >
                  삭제
                </v-btn>

                <v-btn
                  small
                  color="secondary"
                  @click="handleSaveClick()"
                  :loading="loading"
                >
                  {{ buttonText }}
                </v-btn>
              </v-col>
            </v-col>
          </div>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center ma-0">
        <v-btn secondary outlined @click="closeDialog" :disabled="loading">
          취소
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import {
  createNotificationApi,
  updateNotificationApi,
  getNotificationsApi,
  deleteNotificationApi
} from '@/api/admin/NotificationsAPI';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      notification: 'notification/notification',
      dialog: 'notification/dialog'
    }),
    buttonText() {
      return this.notification.id ? '저장' : '등록';
    }
  },
  watch: {
    selectedIdx(val) {
      const req = val !== null ? this.notifications[val] : null;
      this.selectNotification(req);
    }
  },
  data() {
    return {
      loading: false,
      selectedIdx: null,
      notifications: []
    };
  },
  mounted() {
    this.fetchNotifications();
  },
  methods: {
    ...mapActions({
      closeDialog: 'notification/closeDialog',
      selectNotification: 'notification/selectNotification'
    }), // notification 목록 조회
    async fetchNotifications() {
      this.loading = true;

      const params = {
        params: {
          pagination: 'N'
        }
      };

      const [error, data] = await getNotificationsApi(params);

      if (error) {
        console.error(error);
      } else {
        const { notifications } = data;
        this.notifications = notifications;
      }
      this.loading = false;
    },
    async handleSaveClick() {
      const valid = await this.$refs.notificationsDialogRef.validate();
      if (!valid) return;

      this.loading = true;

      const params = {
        title: this.notification.title,
        body: this.notification.body
      };

      const [error] = await (this.notification.id
        ? updateNotificationApi(this.notification.id, params)
        : createNotificationApi(params));

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        this.$Swal.fire({
          html: `<h3>CRM이 ${this.buttonText}되었습니다.</h3>`,
          icon: 'success',
          confirmButtonText: '확인'
        });
        this.selectedIdx = undefined;
        this.selectNotification(null);
        this.fetchNotifications();
        // this.closeDialog();
        // this.$emit('index');
      }

      this.loading = false;
    },
    async handleSelectedAll() {
      this.loading = true;

      if (this.isSelectAll) {
        await Promise.all(
          (this.notification.users = this.users.map(user => user.id)),
          Array.from(new Set(this.notification.users))
        );
      } else {
        this.notification.users = [];
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    handleImmediately() {
      if (this.notification.immediately) {
        this.notification.reserved_at = null;
        this.reserveDate = null;
        this.reserveTime = null;
      } else {
        this.reserveDate = this.today;
        this.reserveTime = '00:00';
      }
    },
    // 회원 선택시 체크
    handleNotificationAddClick() {
      this.selectedIdx = undefined;

      setTimeout(() => {
        this.selectNotification({
          id: 0,
          title: '',
          body: ''
        });
      }, 50);
    },
    async deleteNotification(id) {
      if (!id) return;

      this.loading = true;

      const [error] = await deleteNotificationApi(id);
      if (error) {
        console.error(error);
      } else {
        this.selectedIdx = undefined;
        this.selectNotification(null);
        this.fetchNotifications();
      }

      this.loading = false;
    }
  }
};
</script>

<style>
.overflow-eclips {
  max-width: 350px;
  background-color: efefef;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
