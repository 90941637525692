var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" 예산 ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[(_vm.isCampaignTypeMessage)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.cpm)+" "),_c('information-hover',{attrs:{"title":_vm.titles.cpm,"subTitle":("CPM(Cost Per Mile) - 1,000 회 도달당 비용. <br>최소 " + _vm.cpmMin + "원 이상 설정해주세요.")}})],1),_c('validation-provider',{attrs:{"rules":{ required: true, numeric: true, min_value_krw: _vm.cpmMin }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","required":"","outlined":"","type":"number","prefix":"₩"},model:{value:(_vm.budget.cpm),callback:function ($$v) {_vm.$set(_vm.budget, "cpm", _vm._n($$v))},expression:"budget.cpm"}})]}}],null,false,1472628086)})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.cpc)+" "),_c('information-hover',{attrs:{"title":_vm.titles.cpc,"subTitle":("CPC(Cost Per Click) - 링크 클릭당 평균 비용. <br>최소 " + _vm.cpcMin + "원 이상 설정해주세요.")}})],1),_c('validation-provider',{attrs:{"rules":{ required: true, numeric: true, min_value_krw: _vm.cpcMin }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","required":"","outlined":"","type":"number","prefix":"₩"},model:{value:(_vm.budget.cpc),callback:function ($$v) {_vm.$set(_vm.budget, "cpc", _vm._n($$v))},expression:"budget.cpc"}})]}}])}),_c('h3',[_vm._v(" "+_vm._s(_vm.titles.dailyAdvertisingBudget)+" "),_c('information-hover',{attrs:{"title":_vm.titles.dailyAdvertisingBudget,"subTitle":"일 예산은 CPC 비용보다 크거나 같게 설정해야 합니다.<br>일일 광고 지출 금액이 일 예산을 초과하면 광고가 중지되며 매일 자정에 초기화 됩니다."}})],1),_c('validation-provider',{attrs:{"rules":{
            required: true,
            numeric: true,
            max_krw: 10,
            min_value_krw: _vm.budget.cpc
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","required":"","outlined":"","type":"number","prefix":"₩"},on:{"input":function($event){return _vm.setBudgetTotal()}},model:{value:(_vm.budget.dailyAdvertisingBudget),callback:function ($$v) {_vm.$set(_vm.budget, "dailyAdvertisingBudget", _vm._n($$v))},expression:"budget.dailyAdvertisingBudget"}})]}}])}),_c('h3',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.titles.totalAdvertisingBudget)+" "),_c('information-hover',{attrs:{"title":_vm.titles.totalAdvertisingBudget,"subTitle":"총 예산은 일 예산 보다 크거나 같게 설정해야 합니다.<br>전체 광고 지출 금액이 총 예산을 초과하면 광고가 중지 됩니다."}}),(_vm.issetDisplayPeriodEnd)?_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-2",attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.setBudgetTotal()}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-calculator ")]),_vm._v(" 자동계산")],1)]}}],null,false,1310970899)},[_c('span',[_vm._v("일정과 일예산에 맞춰 자동계산 됩니다.")])]):_vm._e()],1),_c('validation-provider',{attrs:{"rules":{
            required: true,
            numeric: true,
            max_krw: 10,
            min_value_krw: _vm.budget.dailyAdvertisingBudget
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","required":"","outlined":"","type":"number","prefix":"₩"},model:{value:(_vm.budget.totalAdvertisingBudget),callback:function ($$v) {_vm.$set(_vm.budget, "totalAdvertisingBudget", _vm._n($$v))},expression:"budget.totalAdvertisingBudget"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("현재 계정 잔여 예산: "+_vm._s(_vm._f("KRW")(_vm.advertiserBudget)))]),_c('alert-message',{staticClass:"mt-3",attrs:{"value":_vm.advertiserBudgetAlert,"border":"left","icon":"mdi-alert","color":"red","message":"계정의 충전금이 부족해요. 캠페인이 중간에 끊기지 않도록 미리 충전해주세요!","localStorageKey":"advertiserBudgetAlert"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.forecastReachCount)+" "),_c('information-hover',{attrs:{"title":_vm.titles.forecastReachCount,"subTitle":"이 수치는 캠페인 집행 전 참고를 위한 지표로, 실제 광고 집행시의 도달 수와 상이 할 수 있으며 추후 변동 될 수 있습니다."}}),_vm._v(" : "),(_vm.isTagFilter)?_c('span',[_vm._v(" "+_vm._s(_vm.targetTagsCount.toLocaleString())+" 건 ")]):_c('span',[(!_vm.forecastReachCountLoading)?_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.forecastReachCount.toLocaleString())+"건 ")]):_vm._e(),(_vm.forecastReachCountLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":3,"size":20,"color":"primary"}}):_vm._e(),(!_vm.forecastReachCountLoading)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.updateForecastReachCount()}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1614542274)},[_c('span',[_vm._v("새로고침")])]):_vm._e()],1)],1),(_vm.forecastCpmBudget)?_c('h3',[_vm._v(" 예상 지출 비용: "+_vm._s(_vm._f("KRW")(_vm.forecastCpmBudget))+" ")]):_vm._e()])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }