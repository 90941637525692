import {
    getUserPointApi
} from "@/api/advertiser/UserAPI"

export default {
    namespaced: true,

    state: {
        creditPoint: null,
        nonsettleFreePoint: null,
        totalPoint: null,
        apiLoading: true,
        myPageAuth: false,
    },
    getters: {
        creditPoint(state) {
            return state.creditPoint
        },
        totalPoint(state) {
            return state.totalPoint
        },
        nonsettleFreePoint(state) {
            return state.nonsettleFreePoint
        },
        apiLoading(state) {
            return state.apiLoading
        }
    },
    mutations: {
        SET_CreditPoint(state, creditPoint) {
            state.creditPoint = creditPoint
        },
        SET_TotalPoint(state, totalPoint) {
            state.totalPoint = totalPoint
        },
        SET_NonsettleFreePoint(state, nonsettleFreePoint) {
            state.nonsettleFreePoint = nonsettleFreePoint
        },
        SET_ApiLoading(state, apiLoading) {
            state.apiLoading = apiLoading
        }
    },
    actions: {
        async loadUserPoint({
            commit
        }) {
            commit('SET_ApiLoading', true)
            const [error, data] = await getUserPointApi()
            if (error) {
                console.log(error)
            } else {
                // console.log(data);
                const {
                    credit_point,
                    total_point,
                    nonsettle_free_point
                } = data;

                commit('SET_CreditPoint', credit_point);
                commit('SET_TotalPoint', total_point);
                commit('SET_NonsettleFreePoint', nonsettle_free_point);
            }
            commit('SET_ApiLoading', false)
        }
    }
}