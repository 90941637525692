<template>
  <v-dialog
    v-model="scheduleDialog"
    max-width="700"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>{{ title }} 스케줄</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 py-3">
        <v-row dense>
          <CustomGridItem :title="`${title} 명`" outlined :md="12">
            <template v-slot:content>
              <v-col class="pa-2">
                {{ name }}
              </v-col></template
            >
          </CustomGridItem>

          <CustomGridItem title="조회기간" outlined :md="12">
            <template v-slot:content>
              <v-menu
                v-model="dateMenus"
                ref="dateMenus"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datesFormat"
                    prepend-icon="mdi-calendar"
                    chips
                    hide-details
                    dense
                    outlined
                    readonly
                    v-on="on"
                  />
                  <input v-model="dates" hidden />
                </template>
                <v-date-picker
                  v-model="dates"
                  color="primary lighten-1"
                  locale="ko-KR"
                  multiple
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn color="primary lighten-1" @click="fetchDidCampaigns">
                    적용
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </template>
          </CustomGridItem>

          <v-col cols="12" class="pa-0 mt-3">
            <a-table
              :loading="loading"
              class="table-striped-rows"
              style="white-space: nowrap"
              size="small"
              rowKey="id"
              :columns="columns"
              bordered
              :data-source="tableData"
              :scroll="{ x: true }"
              :locale="{
                emptyText: '데이터가 없습니다.'
              }"
              @change="
                (pagination, sorter, filter) => {
                  handlePageChange(pagination);
                }"
            >
              <template slot="no" slot-scope="value, record, index">
                {{((currentPage - 1) * pageSize) +  (index + 1 )}}
              </template>

              <template slot="StatusInfo" slot-scope="value">
                <v-chip v-if="value" label small :color="value.color" outlined>
                  {{ value.text }}
                </v-chip>
              </template>

              <template slot="didCampaigns" slot-scope="value">
                <v-tooltip right>
                  <template #activator="{ on: onTooltip }">
                    <div :class="`event-item px-1 `" v-on="{ ...onTooltip }">
                      {{ value.length }}개
                    </div>
                  </template>
                  <span v-for="(campaign, index) in value" :key="index">
                    <br v-if="index > 0" />
                    <v-chip class="ma-1 subtitle-2" color="white" label>
                      <strong> {{ campaign.service_name }} </strong>
                      <span class="ml-1 caption">
                        (
                        {{ campaign.schedule_date }})
                      </span>
                    </v-chip>
                  </span>
                </v-tooltip>
              </template>
            </a-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn secondary outlined @click="handleCloseClick"> 닫기 </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import CustomGridItem from '@/components/CustomGridItem.vue';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    scheduleDialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CustomGridItem
  },
  data() {
    return {
      defaultDates: [this.$moment().format('YYYY-MM-DD')],
      dates: [this.$moment().format('YYYY-MM-DD')],
      dateMenus: false,
      searchMaxDate: this.$moment().add(1, 'years').format('YYYY-MM-DD'),
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    datesFormat() {
      return this.dates.length > 1
        ? `${this.dates[0]} 외 ${this.dates.length - 1} 일`
        : `${this.dates}`;
    }
  },
  watch: {
    dialog() {
      // this.loading = false;
    }
  },
  mounted() {},
  methods: {
    ...mapActions({}),
    /** @description: 닫기 */
    handleCloseClick() {
      this.$emit('close');
    },
    fetchDidCampaigns() {
      this.$refs.dateMenus.save(this.dates);

      if (this.loading) return;
      this.$emit('fetchData', Array.from(new Set(this.dates)));
    }, 
    handlePageChange(page) {
      this.currentPage = page.current
      this.pageSize = page.pageSize
    }
  }
};
</script>
