<template>
  <validation-observer
    tag="form"
    ref="obs2"
    v-slot="{ invalid }"
    @submit.prevent="submit()"
  >
    <v-row class="pa-5" style="background-color:white;">
      <v-col sm="12" lg="6">
        <template v-if="rs1.campaignType" class="mx-auto">
          <campaign-type-rolling
            v-if="rs1.campaignType === 'rolling'"
            :rolling.sync="rs2.rolling"
          />

          <campaign-type-push
            v-if="allowCampaignTypes.includes(rs1.campaignType)"
            :push.sync="rs2.push"
          />
        </template>
      </v-col>
      <v-col sm="12" lg="6">
        <campaign-preview :rs1="rs1" :rs2="rs2" />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="4">
        <campaign-content-footer :invalid="invalid" />
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import CampaignTypePush from './CampaignStep2TypePush.vue';
import CampaignTypeRolling from './CampaignStep2TypeRolling.vue';
import CampaignContentFooter from './CampaignContentFooter.vue';
import { mapActions, mapGetters } from 'vuex';
import CampaignPreview from './CampaignPreview.vue';

export default {
  components: {
    ValidationObserver,
    CampaignTypePush,
    CampaignTypeRolling,
    CampaignContentFooter,
    CampaignPreview
  },
  props: ['rs1', 'rs2'],
  data() {
    return {
      allowCampaignTypes: ['push', 'message']
    };
  },
  computed: {
    ...mapGetters({
      currentStep: 'campaign/currentStep',
      dialog: 'campaign/dialog'
    })
  },
  watch: {
    dialog: function(newVal) {
      if (newVal) {
        this.$refs.obs2.reset();
      }
    }
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'campaign/setCurrentStep'
    }),
    submit() {
      const valid = this.$refs.obs2.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    }
  }
};
</script>
