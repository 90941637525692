<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row v-show="!loading">
      <v-col cols="12">
        <v-card class="pa-3">
          <NoticeTable :notices="notices" />
        </v-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getMediaNoticeApi()"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import NoticeTable from './components/NoticeTable.vue';
import { getNoticesApi } from '@/api/media/UserAPI';
export default {
  components: {
    MenuTitle,
    NoticeTable
  },
  computed: {
    ...mapGetters({
      userRoleList: 'userRoleList'
    })
  },
  data() {
    return {
      notices: [],
      pagination: {
        page: 1,
        perPage: 15,
        totalPage: 1
      },
      loading: true
    };
  },
  async mounted() {
    await this.fetchNotices();
  },
  methods: {
    async fetchNotices(page = this.pagination.page) {
      this.loading = true;

      const params = {
        params: {
          page: page,
          perPage: this.pagination.perPage,
          limit: 10
        }
      };

      const [error, data] = await getNoticesApi(params);
      console.log(data);
      if (error) {
        console.error(error);
      } else {
        const { notices, total_count } = data;
        const { perPage } = this.pagination;
        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;

        this.notices = notices.data.map(notice => {
          return {
            key: notice.id,
            category: notice.category,
            startAt: notice.start_at.substr(0, 10),
            content: notice.content,
            title: notice.title,
            isUrgent: notice.is_urgent
          };
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style></style>
