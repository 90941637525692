<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      :rules="{
        required: true
      }"
    >
      <v-input
        type="hidden"
        v-model="campaign.rollingImageId"
        :error-messages="errors"
      ></v-input>
      <image-upload
        :sImageId="campaign.rollingImageId"
        @setImage="
          v => {
            campaign.rollingImageId = v.id;
            campaign.rollingImageUrl = v.url;
            campaign.rollingImageType = v.type;
          }
        "
        campaignType="rolling"
        imageType="image"
        :title="titles.image"
      />
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import ImageUpload from '@/components/ImageUpload.vue';
export default {
  components: {
    ValidationProvider,
    ImageUpload
  },
  data() {
    return {
      titles: {
        image: '광고 이미지',
        noImage: '선택 할 수 있는 이미지가 없습니다. 이미지를 업로드해주세요.'
      }
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign'
    })
  }
};
</script>
