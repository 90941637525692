<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row class="ma-0 mb-3 justify-end">
      <v-btn
        color="secondary"
        class="mr-2"
        outlined
        depressed
        :loading="loading"
        @click="openNotificationDialog()"
      >
        CRM 관리
      </v-btn>

      <v-btn
        color="secondary"
        class="white--text"
        depressed
        :loading="loading"
        @click="handleOpenDialogClick()"
      >
        등록
      </v-btn>
    </v-row>

    <v-row v-show="!loading">
      <v-col cols="12">
        <a-card>
          <v-row class="ma-0">
            <v-col>
              <div class="filters mt-5">
                <a-descriptions bordered>
                  <a-descriptions-item label="상태" :span="isMobile ? 2 : 1">
                    <a-select
                      v-model="filters.status"
                      :style="{ minWidth: '200px' }"
                      mode="multiple"
                    >
                      <a-select-option value="S"> 발송 완료 </a-select-option>
                      <a-select-option value="W"> 발송 대기 </a-select-option>
                      <a-select-option value="C"> 발송 취소 </a-select-option>
                      <a-select-option value="F"> 발송 실패 </a-select-option>
                    </a-select>
                  </a-descriptions-item>

                  <a-descriptions-item :span="isMobile ? 3 : 1">
                    <template slot="label">검색</template>

                    <a-input-search
                      v-model="filters.searchString"
                      @search="getNotificationPsushApi()"
                      style="min-width: 500px"
                      placeholder="목적, 제목, 내용으로 검색하세요."
                    />
                  </a-descriptions-item>
                </a-descriptions>

                <div class="text-center mt-3">
                  <v-btn
                    color="#00d1b2"
                    class="white--text"
                    depressed
                    :loading="loading"
                    @click="getNotificationPsushApi()"
                  >
                    검색
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <a-table
            :columns="columns"
            :data-source="notificationPushes"
            :loading="loading"
            bordered
            :scroll="{ x: true }"
            scrollToFirstRowOnChange
            size="small"
            :pagination="false"
            class="mb-5 inquiry-table"
            rowKey="id"
            :locale="{
              emptyText: 'CRM 내역이 없습니다'
            }"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      openDialog(record);
                    }
                  }
                };
              }
            "
          >
            <span slot="body" slot-scope="text">
              <div
                style="
                  max-width: 500px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ text }}
              </div>
            </span>
            <span slot="users" slot-scope="text"> {{ text.length }}명 </span>
            <span slot="reserved_at" slot-scope="text">
              {{ text ? $moment(text).format('YYYY-MM-DD HH:mm') : '' }}
            </span>
            <span slot="send_at" slot-scope="text">
              {{ text ? $moment(text).format('YYYY-MM-DD HH:mm') : '' }}
            </span>
            <span slot="createdAt" slot-scope="text">
              {{ $moment(text).format('YYYY-MM-DD') }}
            </span>

            <span slot="status" slot-scope="text">
              <v-chip
                v-if="text"
                class="ml-2"
                label
                small
                :color="statusInfo(text).color"
                outlined
              >
                {{ statusInfo(text).text }}
              </v-chip>
            </span>
          </a-table>
        </a-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="article, actions, article, actions, article, actions"
    ></v-skeleton-loader>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getNotificationPsushApi()"
        ></v-pagination>
      </v-col>
    </v-row>
    <NotificationPushDialog v-if="dialog" @index="getNotificationPsushApi()" />
    <NotificationDialog
      v-if="notificationDialog"
      @index="getNotificationPsushApi()"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import { getNotificationPsushApi } from '@/api/admin/NotificationsAPI';
import NotificationPushDialog from './components/NotificationPushDialog.vue';
import NotificationDialog from './components/NotificationDialog.vue';

export default {
  components: {
    MenuTitle,
    NotificationPushDialog,
    NotificationDialog
  },
  computed: {
    ...mapGetters({
      filters: 'notificationPushes/filters',
      dialog: 'notificationPushes/dialog',
      notificationDialog: 'notification/dialog',
      targetOptions: 'notificationPushes/targetOptions',
      statusOptions: 'notificationPushes/statusOptions'
    })
  },
  data() {
    return {
      columns: [
        {
          title: 'NO',
          align: 'center',
          dataIndex: 'id',
          key: 'id',
          width: 80
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {
            customRender: 'title'
          },
          width: 200
        },
        {
          title: '내용',
          dataIndex: 'body',
          key: 'body',
          scopedSlots: {
            customRender: 'body'
          }
        },
        {
          title: '상태',
          dataIndex: 'status',
          align: 'center',
          key: 'status',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '발송대상',
          dataIndex: 'users',
          align: 'center',
          key: 'users',
          scopedSlots: {
            customRender: 'users'
          },
          width: 80
        },
        {
          title: '메모',
          dataIndex: 'description',
          key: 'description',
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          title: '예약일시',
          dataIndex: 'reserved_at',
          align: 'center',
          key: 'reserved_at',
          scopedSlots: {
            customRender: 'reserved_at'
          },
          width: 150
        },
        {
          title: '발송일시',
          dataIndex: 'send_at',
          align: 'center',
          key: 'send_at',
          scopedSlots: {
            customRender: 'send_at'
          },
          width: 150
        },
        {
          title: '등록일시',
          dataIndex: 'created_at',
          align: 'center',
          key: 'created_at',
          scopedSlots: {
            customRender: 'createdAt'
          },
          width: 150
        }
      ],
      data: [],
      panel: [],
      notificationPushes: [],
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      loading: true,
      isMobile: false
    };
  },
  async mounted() {
    window.addEventListener(
      'resize',
      () => (this.isMobile = window.innerWidth < 1500)
    );

    await this.getNotificationPsushApi();
  },
  beforeDestroy() {
    window.removeEventListener(
      'resize',
      () => (this.isMobile = window.innerWidth < 1500)
    );
  },
  methods: {
    ...mapActions({
      openDialog: 'notificationPushes/openDialog',
      openNotificationDialog: 'notification/openDialog'
    }),
    async getNotificationPsushApi() {
      this.loading = true;

      const params = {
        ...this.filters,
        page: this.pagination.page,
        perPage: this.pagination.perPage
      };

      const [error, data] = await getNotificationPsushApi({
        params: params
      });

      if (error) {
        console.error(error);
      } else {
        const { totalCount, notificationPushes } = data;
        this.notificationPushes = notificationPushes.map(push => ({
          ...push,
          users: JSON.parse(push.users),
          target: ''
        }));
        // const totalCount = this.notifications.length;
        const { perPage } = this.pagination;
        const totalPage = parseInt(totalCount / perPage);
        this.pagination.totalPage =
          totalCount % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    },
    getTarget(key) {
      const option = this.targetOptions.find(option => option.id === key);
      console.log(option);
      if (!option || !option.value) return [];
      return option.value.split('+');
    },
    handleOpenDialogClick(record) {
      this.openDialog(record ?? null);
    },
    statusInfo(status) {
      return this.statusOptions.find(option => option.value === status);
    }
  }
};
</script>

<style>
.inquiry-table td {
  max-width: 650px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
