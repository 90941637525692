import moment from 'moment';
import { getUserPointDayApi } from '@/api/advertiser/UserAPI';
import { CampaignTypeList } from '@/config/campaign';
import { PointCodeFlags, PointStatusFlags } from '@/config/charge';

export default {
  namespaced: true,

  state: {
    loading: false,
    buckets: [],
    columns: [
      {
        title: '날짜',
        align: 'center',
        dataIndex: 'transactionAt'
      },
      {
        title: '캠페인명',
        dataIndex: 'campaignName',
        align: 'center'
      },
      {
        title: '광고 유형',
        dataIndex: 'campaignType',
        align: 'center'
      },
      {
        title: '사용 유형',
        dataIndex: 'code',
        align: 'center'
      },
      {
        title: '구분',
        dataIndex: 'statusFlag',
        align: 'center',
        scopedSlots: {
          customRender: 'statusFlag'
        }
      },
      {
        title: '금액',
        dataIndex: 'sumAmount',
        align: 'right'
      },
      {
        title: '상세',
        dataIndex: 'actions',
        align: 'center',
        scopedSlots: {
          customRender: 'actions'
        }
      }
    ],
    payload: {
      page: 1,
      perPage: 20,
      codes: ['CC000', 'CD000', 'CDR00'],
      campaignName: '',
      campaignType: ['push', 'message', 'rolling'],
      statusFlag: ['+', '-'],
      amount: [0, 100000000],
      dates: []
    },
    pagination: {
      totalPage: 1,
      total: 0
    }
  },
  getters: {
    columns(state) {
      return state.columns;
    },
    payload(state) {
      return state.payload;
    },
    pagination(state) {
      return state.pagination;
    },
    buckets(state) {
      return state.buckets;
    },
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    SET_BUCKETS(state, value) {
      state.buckets = value;
    },
    SET_PAGINATION(state, value) {
      state.pagination = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async index({ commit, getters }) {
      commit('SET_LOADING', true);

      const payload = {
        params: getters.payload
      };

      const [error, data] = await getUserPointDayApi(payload);
      if (error) {
        console.error(error);
      } else {
        const { points, last_page, total } = data;

        const items = points.map((item, index) => {
          const campaign = item.campaign
            ? item?.campaign
            : item?.did_campaign_service
            ? item?.did_campaign_service
            : null;

          return {
            key: index,
            transactionAt: moment(item.transaction_at).format('YYYY-MM-DD'),
            sumAmount: (item.sum_amount * 1).toLocaleString(),
            statusFlag:
              PointStatusFlags.find(v => v.value === item.status_flag) || null,
            code: PointCodeFlags.find(v => v.value === item.code)?.text || null,
            campaignName: campaign?.name,
            campaignId: campaign?.id,
            campaignType: item?.did_campaign_service
              ? 'DID'
              : CampaignTypeList.find(
                  v => v.value === item?.campaign?.type || null
                )?.text || null
          };
        });

        commit('SET_PAGINATION', {
          totalPage: last_page,
          total: total
        });

        commit('SET_BUCKETS', items);
        commit('SET_LOADING', false);
      }
    }
  }
};
