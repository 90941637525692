import { getCamelKeys } from '@/utils/functions';

export class CampaignTypePush {
  clickType = null;
  subTitle = null;
  category = null;
  title = null;
  text = null;

  messageDate = null;
  messageTim = null;

  _image = null;
  // image
  pushImageId = null;
  pushImageUrl = null;
  pushImageType = null;

  _icon = null;
  // icon
  pushIconId = null;
  pushIconUrl = null;
  pushIconType = null;

  _messageImage = null;
  // messageImage
  messageImageId = null;
  messageImageUrl = null;
  messageImageType = null;

  constructor(init) {
    if (!init) return;
    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      this[key] = val;
    }
  }

  get pushClickType() {
    return this.clickType;
  }

  set pushClickType(val) {
    this.clickType = val;
  }

  get pushSubTitle() {
    return this.subTitle;
  }

  set pushSubTitle(val) {
    this.subTitle = val;
  }

  get pushCategory() {
    return this.category;
  }

  set pushCategory(val) {
    this.category = val;
  }

  get pushTitle() {
    return this.title;
  }

  set pushTitle(val) {
    this.title = val;
  }

  get pushText() {
    return this.text;
  }

  set pushText(val) {
    this.text = val;
  }

  get image() {
    return this._image;
  }

  set image(val) {
    this._image = val;
    this.pushImageId = val?.id || null;
    this.pushImageUrl = val?.url || null;
    this.pushImageType = val?.type || null;
  }

  get icon() {
    return this._image;
  }

  set icon(val) {
    this._icon = val;
    this.pushIconId = val?.id || null;
    this.pushIconUrl = val?.url || null;
    this.pushIconType = val?.type || null;
  }

  get messageImage() {
    return this._image;
  }

  set messageImage(val) {
    this._messageImage = val;
    this.messageImageId = val?.id || null;
    this.messageImageUrl = val?.url || null;
    this.messageImageType = val?.type || null;
  }
}
