<template>
  <v-dialog v-model="dialog" width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>충전하기</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5 pa-3">
        <v-row>
          <v-col>
            <div
              class="d-flex align-center justify-center"
              style="height: 120px; outline: 2px solid #ddd"
            >
              <div>
                <div class="mb-3" style="font-size: 18px">
                  {{ userNameTitle }}
                </div>
                <div
                  style="font-size: 30px; font-weight: bold; text-align: right"
                >
                  {{ userPointText }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="ma-0 mt-3 text-subtitle-1 font-weight-bold"
          justify="center"
        >
          충전 수단을 선택하세요.
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              width="100%"
              height="100"
              outlined
              @click="handleCreditCardClick()"
            >
              <div class="d-flex" style="flex-direction: column">
                <v-icon large>mdi-credit-card-outline</v-icon>
                <span>신용/체크 카드</span>
                <div class="caption">(VAT 포함)</div>
              </div>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              height="100"
              outlined
              @click="handleBankTransferClick()"
            >
              <div class="d-flex" style="flex-direction: column">
                <v-icon large>mdi-bank</v-icon>
                <span>직접입금</span>
                <div class="caption">(VAT 포함)</div>
              </div>
            </v-btn>
          </v-col>
        </v-row>
        <hr class="ma-5" style="border: none; border: 2px dashed #ddd" />
        <v-row>
          <v-col>
            <ul>
              <li>
                최소 충전 금액은 30,000원 입니다. (전체 카드사 적용, VAT 별도)
              </li>
              <li class="red--text">
                주의: 경우에 따라 충전한 금액이 모두 소진된 이후에도 추가적인
                과금 (마이너스 잔액) 이 발생할 수 있습니다.
              </li>
              <li>
                본 결제 시스템은 노티아이 진행을 위한 충전 목적으로만 사용
                가능합니다. 충전 이외의 기타 다른 목적으로 오용될 경우 약관
                위반으로 인한 불이익이 발생 할 수 있으니 올바른 이용
                부탁드립니다.
              </li>
            </ul>
          </v-col>
        </v-row>

        <hr class="ma-3" style="border: none; border: 2px dashed #ddd" />
      </v-card-text>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {},
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      dialog: 'dialog/rechargeDialog',
      totalPoint: 'user/totalPoint'
    }),
    userNameTitle() {
      return `${this.user.name}님의 잔액`;
    },
    userPointText() {
      const point = this.totalPoint;
      return `${point == null ? 'N/A' : point.toLocaleString() + ' 원'}`;
    }
  },
  methods: {
    ...mapActions({
      openCreditCardDialog: 'dialog/openCreditCardDialog',
      closeRechargeDialog: 'dialog/closeRechargeDialog',
      openBankTransferDialog: 'dialog/openBankTransferDialog'
    }),
    handleCloseClick() {
      this.closeRechargeDialog();
    },
    handleCreditCardClick() {
      this.closeRechargeDialog();
      this.openCreditCardDialog();
    },
    handleBankTransferClick() {
      if (!this.user.isset_business_image) {
        this.$Swal
          .fire({
            icon: 'info',
            title: '직접입금 사용 안내',
            html: `<h3>사업자등록증 사본을 등록후 이용해주세요.<br />
          [설정]>[MYpage]>[사업자등록증 사본]
          항목에서 등록이 가능 합니다.</h3>`,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소',
            showLoaderOnConfirm: true
          })
          .then(result => {
            if (result.isConfirmed) {
              this.closeRechargeDialog();

              if (this.$route.name === 'AdvertiserMember') return;
              this.$router.push({ name: 'AdvertiserMember' });
            }
          });
        return;
      }
      this.closeRechargeDialog();
      this.openBankTransferDialog();
    }
  }
};
</script>

<style></style>
