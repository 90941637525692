var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":"","persistent":"","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("매체 "+_vm._s(_vm.titleText))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleCloseClick}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5 py-3"},[_c('ValidationObserver',{ref:_vm.refKey},[_c('v-row',{attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"매체 이름","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({model:{value:(_vm.branch.name),callback:function ($$v) {_vm.$set(_vm.branch, "name", $$v)},expression:"branch.name"}},'v-text-field',
                    _vm.$getAttributes({
                      errors: errors,
                      placeholder: '매체 이름'
                    })
                  ,false))]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"매체사 그룹","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',_vm._b({on:{"change":_vm.handleDidMediaGroupId},model:{value:(_vm.branch.didMediaGroupId),callback:function ($$v) {_vm.$set(_vm.branch, "didMediaGroupId", $$v)},expression:"branch.didMediaGroupId"}},'v-select',
                    _vm.$getAttributes(
                      {
                        errors: errors,
                        items: _vm.didMediaGroups,
                        itemText: 'name',
                        itemValue: 'id',
                        placeholder: '매체사 그룹 선택',
                        readonly: !!_vm.branch.id
                      },
                      'select'
                    )
                  ,false))]}}])})]},proxy:true}])}),(_vm.branch.id)?_c('CustomGridItem',{attrs:{"title":"활성여부","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',[_c('v-chip',{staticClass:"font-weight-bold px-5 white--text",attrs:{"label":"","color":_vm.branch.isActive ? '#00d1b2' : 'error'}},[_vm._v(" "+_vm._s(_vm.branch.isActive ? '활성' : '비활성')+" ")])],1)]},proxy:true}],null,false,2518503089)}):_vm._e(),_c('CustomGridItem',{attrs:{"title":"카테고리","outlined":"","md":6},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',_vm._b({model:{value:(_vm.branch.category),callback:function ($$v) {_vm.$set(_vm.branch, "category", $$v)},expression:"branch.category"}},'v-select',
                    _vm.$getAttributes(
                      {
                        errors: errors,
                        items: _vm.BranchCategories,
                        placeholder: '카테고리 선택'
                      },
                      'select'
                    )
                  ,false))]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"상호","outlined":"","md":6},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',_vm._b({attrs:{"placeholder":"상호 선텍"},model:{value:(_vm.branch.companyCode),callback:function ($$v) {_vm.$set(_vm.branch, "companyCode", $$v)},expression:"branch.companyCode"}},'v-select',
                    _vm.$getAttributes(
                      {
                        errors: errors,
                        items: _vm.CompanyCodes,
                        placeholder: '상호 선택'
                      },
                      'select'
                    )
                  ,false))]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"지역 1","outlined":"","md":6},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',_vm._b({on:{"change":function($event){_vm.branch.area2 = null}},model:{value:(_vm.branch.regionDepth1Id),callback:function ($$v) {_vm.$set(_vm.branch, "regionDepth1Id", $$v)},expression:"branch.regionDepth1Id"}},'v-select',
                    _vm.$getAttributes(
                      {
                        errors: errors,
                        items: _vm.regionDepth1Id,
                        placeholder: '특별·광역시, 도 선택',
                        itemText: 'name',
                        itemValue: 'id'
                      },
                      'select'
                    )
                  ,false))]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"지역 2","outlined":"","md":6},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',_vm._b({model:{value:(_vm.branch.regionDepth2Id),callback:function ($$v) {_vm.$set(_vm.branch, "regionDepth2Id", $$v)},expression:"branch.regionDepth2Id"}},'v-select',
                    _vm.$getAttributes(
                      {
                        errors: errors,
                        items: _vm.filteredAreaDepth2,
                        placeholder: '시, 구 선택',
                        itemText: 'name',
                        itemValue: 'id',
                        disabled: !_vm.branch.regionDepth1Id
                      },
                      'select'
                    )
                  ,false))]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"운영 요일","outlined":"","md":12,"titleClass":"col-12  py-3"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [[_c('v-col',{staticClass:"py-1"},[_c('v-btn-toggle',{staticClass:"ma-0 row",staticStyle:{"border-width":"2px"},attrs:{"multiple":""},model:{value:(_vm.branch.scheduleDayIds),callback:function ($$v) {_vm.$set(_vm.branch, "scheduleDayIds", $$v)},expression:"branch.scheduleDayIds"}},_vm._l((_vm.Week),function(day,index){return _c('v-btn',{key:day.value,staticClass:"col font-weight-bold",style:(("border-color: #00d1b2!important;\n                        border-width: 2px;\n                        " + (!index ? '' : 'border-left-width: 0px'))),attrs:{"value":day.value,"disabled":_vm.dialogLoading,"color":"#00d1b2","outlined":"","dense":""}},[_vm._v(" "+_vm._s(day.text)+" ")])}),1)],1),(errors.length)?_c('v-col',{staticClass:"red--text py-0 px-5",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]]}}])})]},proxy:true}])}),_c('CustomGridItem',{attrs:{"title":"운영 시간","outlined":"","md":12,"titleClass":"col-12 py-3"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"timePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({model:{value:(_vm.branch.scheduleOpenAt),callback:function ($$v) {_vm.$set(_vm.branch, "scheduleOpenAt", $$v)},expression:"branch.scheduleOpenAt"}},'v-text-field',
                            _vm.$getAttributes(Object.assign({}, {errors: errors,
                              placeholder: '매체 이름',
                              prependInnerIcon: 'mdi-clock-outline',
                              label: '시작 시간',
                              disabled: _vm.dialogLoading,
                              persistentHint: true},
                              attrs))
                          ,false),on))]}}],null,true)})]}}]),model:{value:(_vm.openAtDialog),callback:function ($$v) {_vm.openAtDialog=$$v},expression:"openAtDialog"}},[_c('TimePicker',{attrs:{"show":_vm.openAtDialog},on:{"close":function($event){_vm.openAtDialog = false}},model:{value:(_vm.branch.scheduleOpenAt),callback:function ($$v) {_vm.$set(_vm.branch, "scheduleOpenAt", $$v)},expression:"branch.scheduleOpenAt"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"timePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({model:{value:(_vm.branch.scheduleCloseAt),callback:function ($$v) {_vm.$set(_vm.branch, "scheduleCloseAt", $$v)},expression:"branch.scheduleCloseAt"}},'v-text-field',
                            _vm.$getAttributes(Object.assign({}, {errors: errors,
                              prependInnerIcon: 'mdi-clock-outline',
                              label: '종료 시간',
                              disabled: _vm.dialogLoading,
                              persistentHint: true},
                              attrs))
                          ,false),on))]}}],null,true)})]}}]),model:{value:(_vm.closeAtDialog),callback:function ($$v) {_vm.closeAtDialog=$$v},expression:"closeAtDialog"}},[_c('TimePicker',{attrs:{"show":_vm.closeAtDialog,"max":25},on:{"close":function($event){_vm.closeAtDialog = false}},model:{value:(_vm.branch.scheduleCloseAt),callback:function ($$v) {_vm.$set(_vm.branch, "scheduleCloseAt", $$v)},expression:"branch.scheduleCloseAt"}})],1)],1)],1)]},proxy:true}])}),(_vm.isSuperAdmin)?[_c('h3',{staticClass:"font-weight-bold mt-4"},[_vm._v("매체비율 설정")]),(_vm.branch.type === 'A')?[_c('CustomGridItem',{attrs:{"title":"단일매체 매출 비율","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',_vm._b({model:{value:(_vm.branch.profitRatio),callback:function ($$v) {_vm.$set(_vm.branch, "profitRatio", $$v)},expression:"branch.profitRatio"}},'v-text-field',
                        _vm.$getAttributes({
                          errors: errors,
                          placeholder: '직매체 매출 비율을 입력해 주세요.',
                          disabled: _vm.dialogLoading
                        })
                      ,false))]}}],null,false,3919435146)})]},proxy:true}],null,false,1951051325)}),_c('CustomGridItem',{attrs:{"title":"대행사(매체그룹) 매출 비율","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',_vm._b({model:{value:(_vm.branch.agencyProfitRatio),callback:function ($$v) {_vm.$set(_vm.branch, "agencyProfitRatio", $$v)},expression:"branch.agencyProfitRatio"}},'v-text-field',
                        _vm.$getAttributes({
                          errors: errors,
                          placeholder: '직매체 매출 비율을 입력해 주세요.',
                          disabled: _vm.dialogLoading
                        })
                      ,false))]}}],null,false,4215320221)})]},proxy:true}],null,false,1583696619)})]:_vm._e(),(_vm.branch.type === 'D')?[_c('CustomGridItem',{attrs:{"title":"직매체 매출 비율","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',_vm._b({model:{value:(_vm.branch.directProfitRatio),callback:function ($$v) {_vm.$set(_vm.branch, "directProfitRatio", $$v)},expression:"branch.directProfitRatio"}},'v-text-field',
                        _vm.$getAttributes({
                          errors: errors,
                          placeholder: '직매체 매출 비율을 입력해 주세요.',
                          disabled: _vm.dialogLoading
                        })
                      ,false))]}}],null,false,2484671175)})]},proxy:true}],null,false,3719259194)})]:_vm._e()]:_vm._e(),(_vm.branch.type === 'A')?[_c('h3',{staticClass:"font-weight-bold mt-4"},[_vm._v("계정 정보")]),_c('CustomGridItem',{attrs:{"title":"로그인 아이디","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [(!_vm.branch.id)?[_c('v-text-field',_vm._b({model:{value:(_vm.branch.user.uid),callback:function ($$v) {_vm.$set(_vm.branch.user, "uid", $$v)},expression:"branch.user.uid"}},'v-text-field',
                        _vm.$getAttributes({
                          errors: errors,
                          placeholder: '로그인 아이디를 입력해 주세요.',
                          disabled: _vm.dialogLoading
                        })
                      ,false))]:_c('v-col',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.branch.userUid))])]}}],null,false,3399560874)})]},proxy:true}],null,false,3716796150)}),(!_vm.branch.id)?_c('CustomGridItem',{attrs:{"title":"비밀번호","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{
                    required: true,
                    min: 8
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',_vm._b({model:{value:(_vm.branch.user.password),callback:function ($$v) {_vm.$set(_vm.branch.user, "password", $$v)},expression:"branch.user.password"}},'v-text-field',
                      _vm.$getAttributes({
                        errors: errors,
                        placeholder: '비밀번호를 입력해 주세요.',
                        type: 'password',
                        disabled: _vm.dialogLoading
                      })
                    ,false))]}}],null,false,298125135)})]},proxy:true}],null,false,1235931015)}):_vm._e(),_c('CustomGridItem',{attrs:{"title":"이메일","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [(!_vm.branch.id)?[_c('v-text-field',_vm._b({model:{value:(_vm.branch.user.email),callback:function ($$v) {_vm.$set(_vm.branch.user, "email", $$v)},expression:"branch.user.email"}},'v-text-field',
                        _vm.$getAttributes({
                          errors: errors,
                          placeholder: '이메일을 입력해 주세요.',
                          type: 'email',
                          disabled: _vm.dialogLoading
                        })
                      ,false))]:_c('v-col',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.branch.userEmail))])]}}],null,false,1670331710)})]},proxy:true}],null,false,3052190903)}),_c('CustomGridItem',{attrs:{"title":"연락처","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [(!_vm.branch.id)?[_c('v-text-field',_vm._b({model:{value:(_vm.branch.user.phone),callback:function ($$v) {_vm.$set(_vm.branch.user, "phone", $$v)},expression:"branch.user.phone"}},'v-text-field',
                        _vm.$getAttributes({
                          errors: errors,
                          placeholder: '연락처를 입력해 주세요.',
                          type: 'phone',
                          disabled: _vm.dialogLoading
                        })
                      ,false))]:_c('v-col',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.branch.userPhone)+" ")])]}}],null,false,589737076)})]},proxy:true}],null,false,417776374)})]:_vm._e(),_c('h3',{staticClass:"font-weight-bold mt-3"},[_vm._v("기본 영상")]),(_vm.branch.didMediaGroupId)?_c('CustomGridItem',{attrs:{"title":"기본영상","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"subtitle-1 font-weight-bold pl-5"},[_vm._v(" "+_vm._s(_vm.branch.defaultResourceCount > 0 ? ((_vm.branch.defaultResourceCount) + " 개") : '기본영상을 선택해 주세요.')+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('PreviewResourceDialog',{attrs:{"createdInit":false}})],1)],1)]},proxy:true}],null,false,2804607142)}):_vm._e()],2)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"secondary":"","outlined":""},on:{"click":_vm.handleCloseClick}},[_vm._v(" 닫기 ")]),_c('v-btn',{attrs:{"color":"secondary","loading":_vm.dialogLoading,"disabled":_vm.dialogLoading || (_vm.branch.id ? !this.branch.isChanged() : false)},on:{"click":_vm.handleSaveClick}},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.dialogLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }