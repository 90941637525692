import axios from 'axios';

/**
 * @description: DID 스케줄 목록 전체 조회
 * */
export async function getDidSchedulesApi(params) {
  try {
    const { data } = await axios.get(
      `/admin/did/campaign/schedules/search`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
