export class KakaoAddress {
    constructor(address, postcode, detailAddress, extraAddress) {
        this.address = address;
        this.postcode = postcode;
        this.detailAddress = detailAddress;
        this.extraAddress = extraAddress;
    }

    // get address() {
    //     return this.address;
    // }
    // set address(address) {
    //     this.address = address
    // }
    // get postcode() {
    //     return this.postcode
    // }
    // set postcode(postcode) {
    //     this.postcode = postcode
    // }
    // get detailAddress() {
    //     return this.detailAddress
    // }
    // set detailAddress(detailAddress) {
    //     this.detailAddress = detailAddress;
    // }
    // get extraAddress() {
    //     return this.extraAddress
    // }
    // set extraAddress(extraAddress) {
    //     this.extraAddress = extraAddress
    // }
}