<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn depressed dark @click="openDialog()"> 셋톱박스 등록 </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <DidSettopBoxFilters
            :filters="filters"
            :loading="indexLoading"
            @search="fetchDidSettopBoxes"
          />

          <a-table
            :loading="indexLoading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            rowKey="id"
            :columns="columns"
            bordered
            :data-source="didSettopBoxes"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleRowClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="status" slot-scope="value">
              <v-chip
                class="ml-2"
                label
                small
                :color="value ? value.color : ''"
                outlined
              >
                {{ value ? value.text : '-' }}
              </v-chip>
            </template>

            <template slot="switch" slot-scope="value, record">
              <div class="d-flex align-center justify-center">
                <v-switch
                  v-model="record.configStatus"
                  class="ma-0 ml-3"
                  color="success"
                  inset
                  hide-details
                  readonly
                  @click.stop="updateConfigStatus(record)"
                />
              </div>
            </template>

            <template slot="rebootAction" slot-scope="value, record">
              <div class="d-flex align-center justify-center">
                <v-tooltip bottom color="rgba(0,0,0,1)">
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn
                      v-on="{ ...tooltipOn }"
                      small
                      color="secondary"
                      @click.stop="handleRebootClick(record)"
                    >
                      리부팅
                    </v-btn>
                  </template>
                  <span class="title">셋톱박스 리부팅</span>
                </v-tooltip>
              </div>
            </template>

            <template slot="updatedAt" slot-scope="value, record">
              {{ record.updatedDate }} <br />
              {{ record.updatedTime }}
            </template>

            <template slot="localeString" slot-scope="text">
              {{ text.toLocaleString() }}
            </template>

            <template slot="actions" slot-scope="value, record">
              <v-tooltip bottom color="rgba(0,0,0,1)">
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn
                    v-on="{ ...tooltipOn }"
                    type="primary"
                    small
                    icon
                    @click.stop="handleShowScheduleClick(record)"
                  >
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-btn>
                </template>
                <span class="title">매체 캠페인 스케줄</span>
              </v-tooltip>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalCount"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <DidSettopBoxDialog v-if="dialog" @refresh="fetchDidSettopBoxes" />
    <DidScheduleDialog
      v-if="scheduleDialog"
      :columns="scheduleColumns"
      :scheduleDialog="scheduleDialog"
      :loading="loading.dialog"
      :title="'셋톱박스'"
      :name="selectSettopBoxName"
      :tableData="scheduleTableData"
      @fetchData="date => fetchDidSchedules(selectSettopBoxId, date)"
      @close="handleCloseClick"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import DidSettopBoxDialog from './components/dialog/DidSettopBoxDialog';
import DidSettopBoxFilters from './components/AdminDidSettopBoxFilters';
import MenuTitle from '@/components/MenuTitle.vue';
import DidScheduleDialog from '@/components/did/DidScheduleDialog';
import { getDidSettopBoxScheduleApi } from '@/api/admin/DidCampaignAPI';
import {
  getDidSettopBoxesApi,
  getDidSettopBoxApi,
  updateDidSettopBoxActiveApi,
  updateDidSettopBoxInactiveApi
} from '@/api/admin/DidSettopBoxAPI';
import { DidSettopBox } from '@/model/admin/did';
import { Pagination } from '@/model/Pagination';

export default {
  components: {
    MenuTitle,
    DidSettopBoxDialog,
    DidSettopBoxFilters,
    DidScheduleDialog
  },
  data() {
    return {
      didSettopBoxes: [],
      pagination: new Pagination(1, 20),
      selectSettopBoxId: '',
      selectSettopBoxName: '',
      scheduleColumns: [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id',
          scopedSlots: {
            customRender: 'no'
          }
        },
        {
          title: '캠페인 명',
          align: 'left',
          width: 250,
          dataIndex: 'name'
        },
        {
          title: '재생일',
          align: 'center',
          dataIndex: 'scheduleDate'
        },
        {
          title: '재생시간 (초)',
          align: 'center',
          dataIndex: 'resourcePlayTime',
          scopedSlots: {
            customRender: 'resourcePlayTime'
          }
        }
      ],
      scheduleTableData: []
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didSettopBox/dialog',
      scheduleDialog: 'didSettopBox/scheduleDialog',
      filters: 'didSettopBox/filters',
      filterParams: 'didSettopBox/filterParams',
      loading: 'didSettopBox/loading',
      didBranches: 'didSettopBox/didBranches'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '매체 그룹',
          align: 'center',
          dataIndex: 'didMediaGroupName',
          width: '150px',
          scopedSlots: {
            customRender: 'didMediaGroupName'
          }
        },
        {
          title: '매체',
          align: 'center',
          dataIndex: 'didBranchName',
          width: '150px',
          scopedSlots: {
            customRender: 'didBranchName'
          }
        },
        {
          title: '이름',
          align: 'center',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '재생 상태',
          align: 'center',
          dataIndex: 'devicePlayStatusInfo',
          scopedSlots: {
            customRender: 'status'
          }
        },

        {
          title: '연결 상태',
          align: 'center',
          dataIndex: 'deviceConnectionStatusInfo',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '리부팅',
          align: 'center',
          dataIndex: 'rebootAction',
          scopedSlots: {
            customRender: 'rebootAction'
          }
        },
        {
          title: '설정 상태',
          align: 'center',
          dataIndex: 'statusInfo',
          scopedSlots: {
            customRender: 'switch'
          }
        },

        {
          title: 'UID',
          align: 'center',
          dataIndex: 'uid',
          width: '150px',
          scopedSlots: {
            customRender: 'uid'
          }
        },
        // {
        //   title: 'Mac 주소',
        //   align: 'center',
        //   width: '150px',
        //   dataIndex: 'macAddress'
        // },
        {
          title: '기준 시간(초)',
          align: 'center',
          dataIndex: 'costStandardSecond',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '기준 단가',
          align: 'center',
          dataIndex: 'costPerSecond',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '최대 구독 구좌 (일별)',
          align: 'center',
          dataIndex: 'dailySubscribeCount',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '최종 업데이트 일시',
          align: 'center',
          dataIndex: 'updatedAt',
          scopedSlots: {
            customRender: 'updatedAt'
          }
        },
        { title: '등록일', align: 'center', dataIndex: 'createdDate' },
        {
          title: '기능',
          align: 'center',
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ];
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchDidSettopBoxes();
    }
  },
  mounted() {
    this.loading.index = false;
    if (!this.didBranches.length) {
      this.fetchDidBranchOptions();
    }

    this.fetchDidSettopBoxes();
  },
  methods: {
    ...mapActions({
      openDialog: 'didSettopBox/openDialog',
      closeDialog: 'didSettopBox/closeDialog',
      openScheduleDialog: 'didSettopBox/openScheduleDialog',
      fetchDidBranchOptions: 'didSettopBox/fetchDidBranchOptions'
    }),
    /** @description: 셋톱박스 목록 조회 */
    async fetchDidSettopBoxes() {
      if (this.indexLoading) return;

      this.loading.index = true;
      const { perPage } = this.pagination;

      const [error, data] = await getDidSettopBoxesApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          filters: this.filterParams,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { settop_boxes, total_count } = data;

        this.didSettopBoxes = settop_boxes.map(branch => {
          return new DidSettopBox(branch);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading.index = false;
    },
    /** @description: 셋톱박스 상세 조회 */
    async handleRowClick(record) {
      this.loading.index = true;

      if (!record) return;

      const [error, data] = await getDidSettopBoxApi(record.id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { settop_box } = data;

        this.openDialog(settop_box);
      }

      this.loading.index = false;
    },
    /** 셋톱박스 스케줄 다이얼로그  */
    handleShowScheduleClick(record) {
      if (!record) return;
      this.selectSettopBoxId = record.id;
      this.selectSettopBoxName = record.name;
      this.openScheduleDialog();
      this.fetchDidSchedules(record.id);
    },
    /** 셋톱박스 스케줄 정보 초기화 */
    handleCloseClick() {
      this.closeDialog('Schedule');

      this.selectSettopBoxId = '';
      this.selectSettopBoxName = '';
      this.scheduleTableData = [];
    },
    /** 셋톱박스 스케줄 목록 조회 */
    async fetchDidSchedules(selectSettopBoxId, dates) {
      const searchDate =
        !dates || !dates.length ? [this.$moment().format('YYYY-MM-DD')] : dates;

      this.loading.dialog = true;
      const [error, data] = await getDidSettopBoxScheduleApi(
        selectSettopBoxId,
        {
          params: {
            dates: [searchDate]
          }
        }
      );

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        this.scheduleTableData = data.schedules.map(item => ({
          id: item.id,
          resourcePlayTime: item.resource_play_time,
          scheduleDate: item.schedule_date,
          name: item.name
        }));
      }
      this.loading.dialog = false;
    },
    /**@description: 설정 상태 변경 */
    updateConfigStatus(item) {
      const html = `<h3>${item.name} 셋톱박스<br/> 설정 상태를 ${
        !item.configStatus ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;
      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            const [error, data] = !item.configStatus
              ? await updateDidSettopBoxActiveApi(item.id) // 활성
              : await updateDidSettopBoxInactiveApi(item.id); // 비활성
            if (error) {
              this.$Swal.showValidationMessage(
                error?.data?.message ?? '요청 실패'
              );
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.configStatus = !item.configStatus;
          }
        });
    },
    /**@description: 셋톱박스 리부팅 버튼 */
    async handleRebootClick(item) {
      if (!item) return;

      const html = `<h3>${item.name} 셋톱박스<br/>를 리부팅 하시겠습니까?</h3>`;
      this.$Swal.fire({
        html: html,
        confirmButtonText: '확인',
        showCancelButton: true,
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          this.loading.dialog = true;
          try {
            const response = await this.$axios.get(
              `/admin/did/settop-box/${item.id}/reboot`
            );
            const { success } = response.data;
            if (success) {
              this.$Swal.fire({
                icon: 'success',
                title: '셋톱박스 리부팅',
                html: `<h3>셋톱박스가 리부팅 되었습니다.</h3>`,
                confirmButtonText: '확인'
              });
            } else {
              console.error('셋톱박스 리부팅 오류::', response);
              this.$axiosErrorAlert('잠시 후 다시 시도해 주세요.(리부팅 실패)');
            }
          } catch (error) {
            console.error('셋톱박스 리부팅 오류::', error);
            this.$axiosErrorAlert(
              `${
                error.data?.message ?? '잠시 후 다시 시도해 주세요.'
              }(리부팅 실패)`
            );
          } finally {
            this.loading.dialog = false;
          }
        }
      });
    }
  }
};
</script>
