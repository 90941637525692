import axios from 'axios';

/**
 * @description: DID 캠페인 목록 전체 조회
 * */
export async function getDidCampaignServicesApi(params) {
  try {
    const { data } = await axios.get(`/did/campaign-services`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 캠페인 상세 조회
 * */
export async function getDidCampaignServiceApi(didCampaignServiceId) {
  try {
    const { data } = await axios.get(
      `/did/campaign-service/${didCampaignServiceId}`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
