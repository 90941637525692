<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn
              depressed
              dark
              @click="openDialog({ record: {}, type: 'store' })"
            >
              매체 등록
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <media-filters
            :filters="filters"
            :loading="loading"
            @search="getMedias"
          />

          <a-table
            :loading="loading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            :columns="columns"
            bordered
            :data-source="medias"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      openDialog({ record: record, type: 'detail' });
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="isActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isActive"
                  color="info"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleActiveSwitch(record)"
                />
              </div>
            </template>
            <template slot="isCampaignImageActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isCampaignImageActive"
                  color="success"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleCampaignImageSwitch(record)"
                />
              </div>
            </template>
            <template slot="isCampaignTextActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isCampaignTextActive"
                  color="success"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleCampaignTextSwitch(record)"
                />
              </div>
            </template>
            <template slot="isCampaignMessageActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isCampaignMessageActive"
                  color="success"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleCampaignMessageSwitch(record)"
                />
              </div>
            </template>
            <template slot="aggReachActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.aggReachActive"
                  color="info"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleAggReachSwitch(record)"
                />
              </div>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <media-store-dialog @refresh="getMedias" />
    <media-detail-dialog @refresh="getMedias" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import MediaStoreDialog from './components/dialog/MediaStoreDialog';
import MediaDetailDialog from './components/dialog/MediaDetailDialog';
import MediaFilters from './AdminMediaFilters';
import {
  updateMediaActiveApi,
  updateMediaAggReachActiveApi,
  inactiveCampaignMessageApi,
  activeCampaignMessageApi,
  inactiveCampaignImageApi,
  activeCampaignImageApi,
  inactiveCampaignTextApi,
  activeCampaignTextApi
} from '@/api/admin/MediaAPI';

export default {
  components: {
    MenuTitle,
    MediaStoreDialog,
    MediaDetailDialog,
    MediaFilters
  },
  data() {
    return {
      medias: [],
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        total: 0
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'media/dialog',
      filters: 'media/filters'
    }),

    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '광고 송출 상태',
          align: 'center',
          dataIndex: 'isActive',
          scopedSlots: {
            customRender: 'isActive'
          }
        },
        {
          title: '이미지 광고 송출',
          align: 'center',
          dataIndex: 'isCampaignImageActive',
          scopedSlots: {
            customRender: 'isCampaignImageActive'
          }
        },
        {
          title: '텍스트 광고 송출',
          align: 'center',
          dataIndex: 'isCampaignTextActive',
          scopedSlots: {
            customRender: 'isCampaignTextActive'
          }
        },
        {
          title: '메시지 광고 송출',
          align: 'center',
          dataIndex: 'isCampaignMessageActive',
          scopedSlots: {
            customRender: 'isCampaignMessageActive'
          }
        },
        {
          title: '라이브 집계 상태',
          align: 'center',
          dataIndex: 'aggReachActive',
          scopedSlots: {
            customRender: 'aggReachActive'
          }
        },
        { title: '매체명', dataIndex: 'name' },
        { title: '매체그룹명', dataIndex: 'mediaGroupName' },
        { title: 'SDK 매체번호', dataIndex: 'sdkMediaIdx', align: 'center' },
        { title: '등록일', align: 'center', dataIndex: 'createdAt' }
      ];
    }
  },
  watch: {
    'pagination.page': function () {
      this.getMedias();
    }
  },
  mounted() {
    this.getMedias();
  },
  methods: {
    ...mapActions({
      openDialog: 'media/openDialog'
    }),
    getMedias() {
      this.loading = true;
      return this.$axios
        .get('/admin/medias', {
          params: {
            page: this.pagination.page,
            perPage: this.pagination.perPage,
            sdkMediaIdx: this.filters.sdkMediaIdx,
            name: this.filters.name,
            aggReachActive: this.filters.aggReachActive,
            active: this.filters.active,
            groupName: this.filters.groupName,
            dates: this.filters.dates.map(date => {
              return date.format('YYYY-MM-DD');
            }),
            pagination: 'Y'
          }
        })
        .then(res => {
          const { data, last_page, total } = res.data.medias;
          console.log(data);
          this.hashing(data);

          this.pagination.totalPage = last_page;
          this.pagination.total = total;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hashing(medias) {
      const hashedMedias = medias.map((media, index) => {
        return {
          key: index,
          id: media.id,
          isActive: media.is_active,
          isCampaignImageActive: media.is_campaign_image_active,
          isCampaignTextActive: media.is_campaign_text_active,
          isCampaignMessageActive: media.is_campaign_message_active,
          uid: media?.user?.uid || null,
          email: media?.user?.email || null,
          phone: media?.user?.phone || null,
          aggReachActive: media.is_agg_reach_active,
          name: media.name,
          createdAt: this.$moment(media.created_at).format('YYYY-MM-DD'),
          mediaGroupId: media?.media_group?.id || null,
          mediaGroupName: media?.media_group?.name || null,
          mediaGroupType: media?.media_group?.type || null,
          profitRatio: media.profit_ratio || null,
          agencyProfitRatio: media.agency_profit_ratio || null,
          directProfitRatio: media.direct_profit_ratio || null,
          package: media.package,
          sdkMediaIdx: media.sdk_media_idx,
          storeUrl: media.store_url,
          serviceRestartInterval: media.service_restart_interval,
          serviceRestartIntervalType: media.service_restart_interval_type,
          settingRequestInterval: media.setting_request_interval,
          settingRequestIntervalType: media.setting_request_interval_type,
          weatherRequestInterval: media.weather_request_interval,
          weatherRequestIntervalType: media.weather_request_interval_type,
          campaignRequestInterval: media.campaign_request_interval,
          campaignRequestIntervalType: media.campaign_request_interval_type,
          campaignRequestBackgroundInterval:
            media.campaign_request_background_interval,
          campaignRequestBackgroundIntervalType:
            media.campaign_request_background_interval_type,
          campaignImpressionInterval: media.campaign_impression_interval,
          campaignImpressionIntervalType:
            media.campaign_impression_interval_type,
          campaignImpressionUnlockCount: media.campaign_impression_unlock_count,
          locationSavePeriod: media.location_save_period,
          locationSavePeriodType: media.location_save_period_type,
          locationSaveBackgroundPeriod: media.location_save_background_period,
          locationSaveBackgroundPeriodType:
            media.location_save_background_period_type,
          campaignInfoActivation: media.campaign_info_activation,
          campaignImpressionType: media.campaign_impression_type,
          campaignArrowActivation: media.campaign_arrow_activation,
          campaignAdxRate: media.campaign_adx_rate,
          campaignImpressionBlockStart: media.campaign_impression_block_start,
          campaignImpressionBlockEnd: media.campaign_impression_block_end
        };
      });

      this.medias = hashedMedias;
    },
    handleActiveSwitch(item) {
      const html =
        `<h3>${item.id}번 ${item.name} <br> 매체의 광고 송출을 ${
          !item.isActive ? '활성' : '비활성'
        } 하시겠습니까?</h3>` +
        `${
          !item.isActive === true
            ? '<br><small>❗️해당 매체 그룹이 비활성화 되어있는 경우, 자동으로 매체 그룹이 활성화 됩니다.</small>'
            : ''
        }`;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const params = {
              mediaIds: [item.id],
              isActive: !item.isActive
            };
            const [error, data] = await updateMediaActiveApi(params);
            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isActive = !item.isActive;
          }
        });
    },
    handleAggReachSwitch(item) {
      const html = `<h3>${item.id}번 ${item.name} <br> 매체의 라이브 집계를  ${
        !item.isAggReachActive ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const params = {
              mediaIds: [item.id],
              isAggReachActive: !item.isAggReachActive
            };

            const [error, data] = await updateMediaAggReachActiveApi(params);
            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isAggReachActive = !item.isAggReachActive;
          }
        });
    },

    handleCampaignImageSwitch(item) {
      const html = `<h3>${item.id}번 ${
        item.name
      } <br> 매체의 이미지 광고 송출을  ${
        !item.isCampaignImageActive ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;

      const active = !item.isCampaignImageActive;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            let response = null;
            if (active) {
              response = await activeCampaignImageApi(item.id);
            } else {
              response = await inactiveCampaignImageApi(item.id);
            }
            const [error, data] = response;
            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isCampaignImageActive = active;
          }
        });
    },
    handleCampaignTextSwitch(item) {
      const html = `<h3>${item.id}번 ${
        item.name
      } <br> 매체의 텍스트 광고 송출을  ${
        !item.isCampaignTextActive ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;

      const active = !item.isCampaignTextActive;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            let response = null;
            if (active) {
              response = await activeCampaignTextApi(item.id);
            } else {
              response = await inactiveCampaignTextApi(item.id);
            }
            const [error, data] = response;
            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isCampaignTextActive = active;
          }
        });
    },
    handleCampaignMessageSwitch(item) {
      const html = `<h3>${item.id}번 ${
        item.name
      } <br> 매체의 메시지 광고 송출을  ${
        !item.isCampaignMessageActive ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;

      const active = !item.isCampaignMessageActive;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            let response = null;
            if (active) {
              response = await activeCampaignMessageApi(item.id);
            } else {
              response = await inactiveCampaignMessageApi(item.id);
            }
            const [error, data] = response;
            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isCampaignMessageActive = active;
          }
        });
    }
  }
};
</script>
