<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <SearchFilter @applyFilters="applyFilters" :loading="loading" />
      </v-col>
      <v-col cols="12">
        <v-simple-table
          v-if="!loading && summary"
          class="pa-3 elevation-2"
          style="white-space:nowrap"
        >
          <thead>
            <tr style="background-color:rgba(244,244,244,1)">
              <th class="text-center">요약</th>
              <th class="text-right">도달</th>
              <th class="text-right">노출</th>
              <th class="text-right">클릭</th>
              <th class="text-right">CTR_도달</th>
              <th class="text-right">CTR_노출</th>
              <th class="text-right">빈도</th>
              <th class="text-right">CPC</th>
              <th class="text-right">CPM</th>
              <th class="text-right">지출금액</th>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color:rgba(244,244,244,1)">
              <td class="text-center">캠페인 {{ campaigns.length }} 건</td>
              <td class="text-right">
                {{ summary.reach_count.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ summary.impression_count.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ summary.click_count.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ summary.ctr_reach.toFixed(3) }}
                %
              </td>
              <td class="text-right">
                {{ summary.ctr.toFixed(3) }}
                %
              </td>
              <td class="text-right">
                {{ summary.frequency }}
              </td>
              <td class="text-right">{{ summary.cpc | KRW }}</td>
              <td class="text-right">{{ summary.cpm | KRW }}</td>
              <td class="text-right">
                {{ summary.expenditure | KRW }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <div class="d-flex align-center">
            <CampaignSearchForm
              @setSearchString="
                v => {
                  searchString = v;
                  getCampaignsApi(1);
                }
              "
            />
            <div
              style="width: 150px"
              class="d-flex ml-auto align-center justify-space-around"
            >
              <CampaignExcelExport
                :headers="columns"
                :campaigns="originCampaigns"
                :filters="filters"
              />
              <ColumnFilterDialog
                :originHeaders="
                  originHeaders.filter(v => v.customOptions.group !== 'default')
                "
                :fixedColumns="fixedColumns"
                :currentHeaders="
                  columns.filter(v => v.customOptions.group !== 'default')
                "
                @store="setColumnsApi"
              />

              <CampaignEditBulk
                :selectedCampaigns="selectedCampaigns"
                @initSelectedCampaigns="initSelect"
                @getCampaigns="getCampaignsApi(1)"
              />
            </div>
          </div>
        </v-card>

        <v-card>
          <v-row>
            <v-col>
              <Table
                class="px-3 table-striped-rows"
                style="white-space:nowrap"
                :columns="columns"
                :data-source="campaigns"
                :row-selection="{
                  selectedRowKeys: selectedCampaignKeys,
                  onChange: onSelectChange
                }"
                bordered
                :loading="loading"
                :scroll="{ x: true }"
                :pagination="{
                  defaultPageSize: 10,
                  position: 'bottom',
                  size: 'large',
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100']
                }"
                size="small"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
                :customRow="
                  record => {
                    return {
                      on: {
                        click: event => {
                          this.selectedCampaignKeys = [record.id];
                          this.selectedCampaigns = [record];
                        },
                        mouseenter: event => {
                          hoverItem = record.id;
                        },
                        mouseleave: event => {
                          hoverItem = null;
                        }
                      }
                    };
                  }
                "
              >
                <template slot="name" slot-scope="value, record">
                  <div class="sub-title-1" style="min-height:20px !important">
                    {{ value }}
                  </div>

                  <div class="d-inline-flex" style="min-height:20px !important">
                    <v-btn
                      class="font-light text-decoration-underline px-0"
                      text
                      small
                      @click="
                        () => {
                          hashingCampaign(record);

                          campaignPreviewDialog = true;
                          campaignPreviewDialogItem = record;
                        }
                      "
                      >미리보기 >
                    </v-btn>
                    <div v-if="hoverItem === record.id">
                      <v-tooltip bottom color="rgba(0,0,0,1)">
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            class="ml-2"
                            v-on="{ ...onTooltip }"
                            icon
                            small
                            @click="openCampaignDialog(record)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span class="title">수정</span>
                      </v-tooltip>
                      <v-tooltip bottom color="rgba(0,0,0,1)">
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            class="ml-2"
                            v-on="{ ...onTooltip }"
                            icon
                            small
                            @click="showDetail(record)"
                          >
                            <v-icon>mdi-chart-areaspline-variant</v-icon>
                          </v-btn>
                        </template>
                        <span class="title">일간 차트</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                <template slot="activeStatus" slot-scope="value, record">
                  <div class="d-flex justify-center">
                    <v-switch
                      v-model="record.activeStatus"
                      color="success"
                      class="mt-0"
                      inset
                      readonly
                      hide-details
                    ></v-switch>
                  </div>
                </template>
                <template slot="approveStatus" slot-scope="value, record">
                  <div
                    class="d-inline-flex align-center"
                    style="min-height:30px !important"
                  >
                    <v-badge
                      left
                      dot
                      inline
                      :color="
                        ApproveStatusList.find(v => v.value === value).colorCode
                      "
                      >{{ ApproveStatusList.find(v => v.value === value).text }}
                    </v-badge>
                    <div style="min-width:40px;">
                      <v-tooltip
                        v-if="hoverItem === record.id"
                        bottom
                        color="rgba(0,0,0,1)"
                      >
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            class="ml-2"
                            v-on="{ ...onTooltip }"
                            icon
                            small
                            @click.stop="openApprovedStatusDialog(record)"
                          >
                            <v-icon>mdi-sync</v-icon>
                          </v-btn>
                        </template>
                        <span class="title">게재 상태 변경</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                <template slot="advertiserGroupName" slot-scope="value, record">
                  <v-chip outlined label pill style="border:none !important">
                    <v-avatar tile left>
                      <v-img
                        v-if="record.advertiserGroupIconUrl"
                        :lazy-src="
                          `https://notii.azureedge.net/campaign/images/push_default_icon.png`
                        "
                        :src="record.advertiserGroupIconUrl"
                        class="rounded-lg"
                        style="border:1px solid #ddd"
                      ></v-img>
                    </v-avatar>
                    <span>{{ value }}</span>
                  </v-chip>
                </template>
                <template slot="type" slot-scope="value">
                  {{ CampaignTypeList.find(v => v.value === value).text }}
                </template>
                <!-- 매체그룹 -->
                <template slot="mediaGroups" slot-scope="value">
                  <div v-for="(mediaGroup, index) in value" :key="index">
                    {{ mediaGroup.name }}
                  </div>
                </template>
                <template slot="money" slot-scope="text">
                  {{ text | KRW }}
                </template>

                <template slot="localeString" slot-scope="text">
                  {{ text ? text.toLocaleString() : '-' }}
                </template>

                <template slot="ctr" slot-scope="text">
                  {{ Number(text).toFixed(3) }}
                  %
                </template>
                <template slot="percent" slot-scope="text">
                  {{ text }} %
                </template>

                <template slot="impressionTime" slot-scope="text, item">
                  {{ text }}
                  {{
                    UnitOfTimeList.find(
                      v => v.value === item.impressionTimeType
                    ).text
                  }}
                </template>
                <template slot="messageDateTime" slot-scope="text, record">
                  <div style="position:relative">
                    <p class="ma-0">{{ text }}</p>
                    <small
                      v-if="record.activeStatus"
                      class="red--text"
                      style="position:absolute"
                    >
                      {{
                        $moment().format('YYYY-MM-DD HH:mm:ss') > text
                          ? ''
                          : `전송 ${$moment(
                              text,
                              'YYYY-MM-DD hh:mm:ss'
                            ).toNow()}`
                      }}
                    </small>
                  </div>
                </template>

                <template slot="displayPeriodEnd" slot-scope="text, item">
                  <div
                    style="position:relative"
                    v-if="item.issetDisplayPeriodEnd"
                  >
                    <p class="ma-0">{{ text }}</p>
                    <small
                      v-if="item.activeStatus"
                      class="red--text"
                      style="position:absolute"
                    >
                      {{
                        $moment().format('YYYY-MM-DD HH:mm:ss') >
                        `${text} 23:59:59`
                          ? ''
                          : `종료 ${$moment(
                              `${text} 23:59:59`,
                              'YYYY-MM-DD HH:mm:ss'
                            ).toNow()}`
                      }}
                    </small>
                  </div>

                  <div v-else>설정안함</div>
                </template>
                <template slot="targetTags" slot-scope="tags, record">
                  <v-menu
                    v-if="tags.length > 0"
                    open-on-hover
                    open-delay="100"
                    close-delay="100"
                    bottom
                    offset-y
                    nudge-left=""
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 font-weight-bold"
                        label
                        :color="
                          `${record.isTagFilter ? 'success' : 'secondary'}`
                        "
                        small
                      >
                        <span
                          ># {{ tags[0].name }}
                          {{
                            tags.length > 1 ? `외 ${tags.length - 1}개` : ''
                          }}</span
                        >
                      </v-chip>
                    </template>
                    <v-card
                      width="300"
                      min-height="150"
                      max-height="300"
                      class="overflow-y-auto"
                    >
                      <!-- <v-card-title>
                        <span>
                          최대 예상 태그 타겟 모수:
                          <br />
                          <span v-if="record.targetTagsType === 'A'">
                            {{
                              Math.min(
                                ...tags.map(v => v.latest_count)
                              ).toLocaleString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              tags
                                .reduce((acc, cur) => {
                                  return acc + cur.latest_count;
                                }, 0)
                                .toLocaleString()
                            }}
                          </span>
                        </span>
                      </v-card-title> -->
                      <v-card-text>
                        <v-chip
                          v-for="(tag, index) in tags"
                          :key="index"
                          class="ma-1"
                          label
                          outlined
                          color="error"
                        >
                          <v-icon>mdi-pound</v-icon>
                          <span>
                            {{ tag.name }}
                          </span>
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
                <template slot="tags" slot-scope="tags">
                  <v-menu
                    v-if="tags.length > 0"
                    open-on-hover
                    open-delay="100"
                    close-delay="100"
                    bottom
                    offset-y
                    nudge-left=""
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 font-weight-bold"
                        label
                        color="default"
                        small
                      >
                        <span
                          ># {{ tags[0].name }}
                          {{
                            tags.length > 1 ? `외 ${tags.length - 1}개` : ''
                          }}</span
                        >
                      </v-chip>
                    </template>
                    <v-card
                      width="300"
                      min-height="150"
                      max-height="300"
                      class="overflow-y-auto"
                    >
                      <v-card-text>
                        <v-chip
                          v-for="(tag, index) in tags"
                          :key="index"
                          class="ma-1"
                          label
                          outlined
                          color="primary darken-3"
                        >
                          <v-icon>mdi-pound</v-icon>
                          <span>
                            {{ tag.name }}
                          </span>
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
                <template slot="targetAppsType" slot-scope="type, record">
                  <v-menu
                    open-on-hover
                    open-delay="100"
                    close-delay="100"
                    bottom
                    auto
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 font-weight-bold"
                        label
                        :color="
                          `${
                            TargetAppsTypeList.find(v => v.value === type).color
                          }`
                        "
                        small
                      >
                        <span>
                          {{
                            TargetAppsTypeList.find(v => v.value === type).text
                          }}
                          {{
                            record.targetApps.length > 0
                              ? `${record.targetApps.length}개`
                              : ''
                          }}</span
                        >
                      </v-chip>
                    </template>
                    <v-card
                      v-if="record.targetApps.length > 0"
                      min-width="300"
                      max-width="500"
                      min-height="150"
                    >
                      <v-card-title>
                        {{
                          TargetAppsTypeList.find(v => v.value === type).text
                        }}
                        {{ `${record.targetApps.length}개` }}
                      </v-card-title>
                      <v-card-text>
                        <v-chip
                          v-for="(app, index) in record.targetApps"
                          :key="index"
                          label
                          color="secondary"
                          class="px-3 py-5 ma-1"
                          style="height:40px;"
                        >
                          <v-img
                            :src="app.image"
                            class="rounded-lg"
                            width="30"
                          ></v-img>
                          <span class="ml-2 font-weight-bold">{{
                            app.name
                          }}</span>
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
                <template slot="targetTagsType" slot-scope="type, record">
                  <p class="ma-0">
                    {{
                      record.targetTagsMode !== null
                        ? TargetTagsModeList.find(
                            v => v.value === record.targetTagsMode
                          ).text
                        : ''
                    }}
                  </p>

                  {{
                    type !== null
                      ? TargetTagsTypeList.find(v => v.value === type).text
                      : ''
                  }}
                </template>
                <template slot="targetTagsCount" slot-scope="count">
                  {{ count > 0 ? count.toLocaleString() : '-' }}
                </template>
              </Table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CampaignDialog />
    <CampaignDetail
      v-if="bottomSheet"
      :sheet="bottomSheet"
      :campaign="selectedCampaign"
      @closeBottomSheet="bottomSheet = false"
    />
    <CampaignPreviewDialog
      :openDialog="campaignPreviewDialog"
      @closeDialog="campaignPreviewDialog = false"
    />
    <ApprovedStatusDialog
      v-if="approveStatusDialog"
      :dialog="approveStatusDialog"
      :campaign="selectedCampaign"
      @close="approveStatusDialog = false"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import CampaignDialog from '../campaign/components/register/CampaignDialog.vue';
import CampaignPreviewDialog from './components/index/CampaignPreviewDialog.vue';
import ColumnFilterDialog from '../../../../components/ColumnFilterDialog.vue';
import CampaignEditBulk from './components/index/CampaignEditBulk.vue';
import CampaignExcelExport from './components/index/CampaignExcelExport.vue';
import CampaignSearchForm from './components/index/CampaignSearchForm.vue';
import SearchFilter from './components/index/SearchFilter.vue';
import CampaignDetail from './components/index/CampaignDetail.vue';
import ApprovedStatusDialog from './components/index/ApprovedStatusDialog.vue';
import { UnitOfTimeList } from '@/config/common';
import {
  ApproveStatusList,
  CampaignTypeList,
  TargetAppsTypeList,
  TargetTagsTypeList,
  TargetTagsModeList
} from '@/config/admin/campaign';

import { Table } from 'ant-design-vue';

export default {
  components: {
    MenuTitle,
    CampaignDialog,
    CampaignPreviewDialog,
    ColumnFilterDialog,
    CampaignEditBulk,
    CampaignExcelExport,
    CampaignSearchForm,
    Table,
    SearchFilter,
    CampaignDetail,
    ApprovedStatusDialog
  },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
        totalCount: 0
      },
      hoverItem: null,
      campaignMediaGroupBuckets: [],
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      searchString: null,
      campaignPreviewDialog: false,
      campaignPreviewDialogItem: null,
      columns: [],
      originHeaders: [],
      campaigns: [],
      originCampaigns: [],
      activeCampaignIds: null,
      selectedCampaigns: [],
      selectedCampaignKeys: [],
      loading: false,
      tableOptions: null,
      editFunctionList: [
        {
          text: ''
        }
      ],
      filters: null,
      summary: null,
      bottomSheet: false, // 캠페인 차트 bottomSheet 모델
      selectedCampaign: null,
      approveStatusDialog: false // 게재 상태 변경 모달 모델
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isSuperAdmin: 'auth/isSuperAdmin'
    }),

    fixedColumns() {
      return this.originHeaders.filter(
        v => v.customOptions.group === 'default'
      );
    },
    ApproveStatusList() {
      return ApproveStatusList;
    },
    CampaignTypeList() {
      return CampaignTypeList;
    },
    TargetAppsTypeList() {
      return TargetAppsTypeList;
    },
    TargetTagsTypeList() {
      return TargetTagsTypeList;
    },
    TargetTagsModeList() {
      return TargetTagsModeList;
    },
    UnitOfTimeList() {
      return UnitOfTimeList;
    }
  },
  async created() {
    this.dateRanges = [
      this.$moment(this.day, 'YYYY-MM-DD')
        .subtract(6, 'days')
        .format('YYYY-MM-DD'),
      this.$moment(this.day, 'YYYY-MM-DD').format('YYYY-MM-DD')
    ];

    await this.initColumns();
    const columns = await this.getColumnsApi();
    if (columns) {
      this.columns = columns;
    }
  },
  mounted() {
    //하위 컴포넌트에서 해당 이벤트 호출을 위해 on 이벤트를 등록합니다.
    const thisInstance = this;
    this.$root.$on('getCampaignsApiEvent', function() {
      thisInstance.getCampaignsApi();
    });
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // console.log(sorter);
      if (sorter.field && sorter.order) {
        this.tableOptions = {
          sorter: sorter
        };
        this.getCampaignsApi(1);
      } else {
        this.tableOptions = null;
      }
    },
    ...mapActions({
      hashingCampaign: 'campaign/hashingCampaign',
      initCampaign: 'campaign/initCampaign',
      setTab: 'campaign/setTab',
      openDialog: 'campaign/openDialog',
      setCampaignId: 'campaign/setCampaignId',
      setDialogTypeEdit: 'campaign/setDialogTypeEdit',
      setDialogTypeRegister: 'campaign/setDialogTypeRegister'
    }),
    //선택 초기화
    initSelect() {
      this.selectedCampaigns = [];
      this.selectedCampaignKeys = [];
    },
    //필터 적용 후 캠페인
    applyFilters(filters) {
      this.filters = filters;

      this.getCampaignsApi(1);
    },
    init() {
      this.campaigns = [];
      this.summary = null;
    },
    getCampaignsApi(page = this.pagination.page) {
      this.loading = true;
      this.initSelect();
      this.campaigns = [];
      const params = {
        ss: this.searchString,
        page: page,
        perPage: this.pagination.perPage,
        filters: this.filters,
        tableOptions: this.tableOptions
      };

      return this.$axios
        .get('/admin/campaigns-media', {
          params: params
        })
        .then(res => {
          // console.log(res);
          const { campaigns, totalCount, summary } = res.data;
          const { hashedCampaigns, activeCampaignIds } = this.hashing(
            campaigns
          );
          this.originCampaigns = campaigns;
          this.campaigns = hashedCampaigns;
          this.activeCampaignIds = activeCampaignIds;
          this.summary = summary;

          const { perPage } = this.pagination;

          const totalPage = parseInt(totalCount / perPage);
          this.pagination.totalCount = totalCount;
          this.pagination.totalPage =
            totalCount % perPage ? totalPage + 1 : totalPage;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setColumnsApi(columns) {
      this.columns = columns;
      try {
        const data = {
          visitor_id: this.user.visitorId,
          view: 'campaigns-ad',
          type: 'columns',
          contents: columns
        };
        await this.$axios.post(`menus/user`, data);
      } catch (err) {
        console.error(err);
      }
    },
    async getColumnsApi() {
      try {
        const { data } = await this.$axios.get(`menus/user`, {
          params: {
            visitor_id: this.user.visitorId,
            view: 'campaigns-ad',
            type: 'columns'
          }
        });
        return data.contents;
      } catch (err) {
        console.error(err);
      }
    },
    initColumns() {
      const initColumns = [
        {
          title: '번호',
          dataIndex: 'id',
          width: 50,
          fixed: this.$isMobile ? false : true,
          key: 'id',
          customOptions: { group: 'default' }
        },
        {
          title: '상태',
          dataIndex: 'activeStatus',
          key: 'activeStatus',
          fixed: this.$isMobile ? false : true,
          width: 80,
          scopedSlots: {
            customRender: 'activeStatus'
          },
          customOptions: { group: 'default' }
        },
        {
          title: '캠페인명',
          dataIndex: 'name',
          key: 'name',
          fixed: this.$isMobile ? false : true,
          width: 200,
          scopedSlots: {
            customRender: 'name'
          },
          customOptions: { group: 'default' }
        },
        {
          title: '게재',
          dataIndex: 'approveStatus',
          key: 'approveStatus',
          fixed: this.$isMobile ? false : true,
          width: 80,
          scopedSlots: {
            customRender: 'approveStatus'
          },
          customOptions: { group: 'default' }
        },
        {
          title: '도달',
          dataIndex: 'reachCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.reachCount - b.reachCount
        },
        {
          title: '노출',
          dataIndex: 'impressionCount',
          key: 'impressionCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.impressionCount - b.impressionCount
        },
        {
          title: '클릭',
          dataIndex: 'clickCount',
          key: 'clickCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.clickCount - b.clickCount
        },
        {
          title: 'CTR_도달',
          dataIndex: 'ctrReach',
          key: 'ctrReach',
          align: 'right',
          scopedSlots: {
            customRender: 'ctr'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.ctrReach - b.ctrReach
        },
        {
          title: 'CTR_노출',
          dataIndex: 'ctr',
          key: 'ctr',
          align: 'right',
          scopedSlots: {
            customRender: 'ctr'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.ctr - b.ctr
        },
        {
          title: '빈도',
          dataIndex: 'frequency',
          key: 'frequency',
          align: 'right',
          customOptions: { group: 'report' },
          sorter: (a, b) => a.frequency - b.frequency
        },
        {
          title: '지출금액',
          dataIndex: 'expenditure',
          key: 'expenditure',
          align: 'right',
          customOptions: { group: 'report' },
          scopedSlots: {
            customRender: 'money'
          },
          sorter: (a, b) => a.expenditure - b.expenditure
        },
        {
          title: '#태그 타겟',
          dataIndex: 'targetTags',
          key: 'targetTags',
          scopedSlots: { customRender: 'targetTags' },
          customOptions: { group: 'setting' }
        },

        {
          title: '#최대 예상 타겟 모수',
          dataIndex: 'targetTagsCount',
          key: 'targetTagsCount',
          align: 'right',
          width: '100',
          scopedSlots: { customRender: 'localeString' },
          customOptions: { group: 'setting' }
        },
        {
          title: '#태그 타겟 필터',
          dataIndex: 'targetTagsType',
          key: 'targetTagsType',
          scopedSlots: { customRender: 'targetTagsType' },

          customOptions: { group: 'setting' }
        },
        {
          title: '#수집 태그',
          dataIndex: 'tags',
          key: 'tags',
          scopedSlots: { customRender: 'tags' },
          customOptions: { group: 'setting' }
        },
        {
          title: '앱 타겟 유형',
          dataIndex: 'targetAppsType',
          key: 'targetAppsType',
          scopedSlots: { customRender: 'targetAppsType' },
          customOptions: { group: 'setting' }
        },
        {
          title: '위치 타겟',
          dataIndex: 'targetLocations',
          key: 'targetLocations',
          scopedSlots: { customRender: 'targetLocations' },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고그룹',
          dataIndex: 'advertiserGroupName',
          key: 'advertiserGroupName',
          scopedSlots: { customRender: 'advertiserGroupName' },
          customOptions: { group: 'setting' }
        },

        {
          title: '광고주',
          dataIndex: 'advertiserName',
          key: 'advertiserName',
          customOptions: { group: 'setting' }
        },
        {
          title: '매체그룹',
          dataIndex: 'mediaGroups',
          key: 'mediaGroups',
          scopedSlots: { customRender: 'mediaGroups' },
          customOptions: { group: 'setting' }
        },
        {
          title: '트래커',
          dataIndex: 'tracker',
          key: 'tracker',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 유형',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: {
            customRender: 'type'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 노출 시간',
          dataIndex: 'impressionTime',
          align: 'center',
          scopedSlots: {
            customRender: 'impressionTime'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 노출 우선 순위',
          dataIndex: 'impressionFrequency',
          key: 'impressionFrequency',
          align: 'center',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 미노출 최대 클릭 수',
          dataIndex: 'adsGoalCount',
          align: 'center',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 클릭 후 재노출 기간 (일간)',
          dataIndex: 'adsLimitInterval',
          key: 'adsLimitInterval',
          align: 'center',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 전송일시',
          dataIndex: 'messageDateTime',
          key: 'messageDateTime',
          scopedSlots: {
            customRender: 'messageDateTime'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 시작일',
          dataIndex: 'displayPeriodStart',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 종료일',
          dataIndex: 'displayPeriodEnd',
          key: 'displayPeriodEnd',
          scopedSlots: {
            customRender: 'displayPeriodEnd'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 생성일',
          dataIndex: 'createdAt',
          key: 'createdAt',
          customOptions: { group: 'setting' }
        },
        {
          title: '일 예산',
          dataIndex: 'dailyAdvertisingBudget',
          key: 'dailyAdvertisingBudget',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '총 예산',
          dataIndex: 'totalAdvertisingBudget',
          key: 'totalAdvertisingBudget',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: 'CPC',
          dataIndex: 'cpc',
          key: 'cpc',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.cpc - b.cpc
        },
        {
          title: 'CPM',
          dataIndex: 'cpm',
          key: 'cpm',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.cpm - b.cpm
        }
      ];
      this.originHeaders = initColumns;
      this.columns = initColumns;
    },

    hashing(campaigns) {
      const activeCampaignIds = [];
      const hashedCampaigns = campaigns.map(campaign => {
        activeCampaignIds.push(campaign.id);
        const {
          media_groups: mediaGroups,
          campaign_type_push: push,
          advertiser_group: advertiserGroup,
          tracker: tracker,
          advertiser
        } = campaign;

        const messageDateTime =
          (push?.message_date || false) && (push?.message_time || false)
            ? this.$moment(`${push.message_date} ${push.message_time}`).format(
                'YYYY-MM-DD HH:mm'
              )
            : '';

        return {
          origin: campaign,
          key: campaign.id,
          id: campaign.id,
          advertiserGroupName: advertiserGroup?.name || null,
          advertiserName: advertiser?.name || null,
          advertiserGroupIconUrl: advertiserGroup?.icon?.url || null,
          approveStatus: campaign.approve_status,
          mediaGroups: mediaGroups,
          tracker: tracker?.name || null,
          activeStatus: campaign.active_status,
          name: campaign.name,
          type: campaign.type,
          impressionTime: campaign.impression_time,
          impressionTimeType: campaign.impression_time_type,
          impressionFrequency: campaign.impression_frequency,
          adsGoalCount: campaign.ads_goal_count,
          adsLimitInterval: campaign.ads_limit_interval,
          messageDateTime: messageDateTime,
          displayPeriodStart: campaign.display_period_start,
          issetDisplayPeriodEnd: campaign.isset_display_period_end,
          displayPeriodEnd: campaign.display_period_end,
          dailyAdvertisingBudget: campaign.daily_advertising_budget,
          totalAdvertisingBudget: campaign.total_advertising_budget,
          reachCount: campaign.reach_count,
          impressionCount: campaign.impression_count,
          clickCount: campaign.click_count,
          ctr: campaign.ctr,
          ctrReach: campaign.ctr_reach,
          frequency: campaign.frequency.toLocaleString(),
          cpc: campaign.cpc,
          cpm: campaign.cpm,
          expenditure: campaign.expenditure,
          createdAt: this.$moment(campaign.created_at).format('YYYY-MM-DD'),
          tags: campaign.tags,
          targetTags: campaign.target_tags,
          targetTagsMode: campaign.target_tags_mode,
          targetTagsType: campaign.target_tags_type,
          isTagFilter: campaign.is_tag_filter,
          targetTagsCount: campaign.target_tags_count,
          targetAppsType: campaign.target_apps_type,
          targetApps: campaign.target_apps,
          targetLocations:
            campaign.target_locations.length > 0 ? '활성' : '비활성'
        };
      });

      return {
        hashedCampaigns: hashedCampaigns,
        activeCampaignIds: activeCampaignIds
      };
    },
    openCampaignDialog(campaign = null) {
      // console.log(campaign);
      this.openDialog();
      if (campaign) {
        this.setDialogTypeEdit();
        this.hashingCampaign(JSON.parse(JSON.stringify(campaign)));
      } else {
        this.setDialogTypeRegister();
        this.initCampaign();
      }
    },
    showDetail(record) {
      this.bottomSheet = true;
      this.selectedCampaign = record;
    },
    moveToReport(campaignId) {
      this.setCampaignId(campaignId);
      this.$router.push({
        name: 'AdminSubReport',
        params: {
          campaignIds: [campaignId]
        }
      });
    },
    updateActiveStatus(item) {
      //기본 설정 값 유효성 체크

      /**
       * 조건
       * 1. 현재 상태가 비활성 상태
       * 2. message 타입이면서 광고전송일시(messageDateTime) 가 설정되어있지않은 경우
       * 3. 또는 광고전송일시가 3시간이상 남은경우
       */

      if (item.type === 'message') {
        // 메시지 전송 시간 계산
        const diff = this.$moment().diff(
          this.$moment(`${item.messageDateTime}`).format('YYYY-MM-DD HH:mm'),
          'hours'
        );
        // console.log(`diff`, diff);
        const status = item.messageDateTime === '' ? true : diff > -3;
        if (item.activeStatus === false && status) {
          this.$Swal.fire({
            html: '<h3>유효하지 않은 광고 전송일시 입니다.</h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }

        // 메시지 발송 24시간 전, 48시간 이내 인 경우 확인 합니다.
        if (item.activeStatus === true && diff < 48 && diff > -24) {
          this.$Swal.fire({
            html:
              '<h3>종료 대기중인 캠페인입니다. <br/><small>즉시 비활성화는 관리자에게 문의하세요.</small></h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }
      }

      this.$Swal
        .fire({
          html: `<h3>${item.id}번 ${item.name} <br> 광고를 ${
            !item.activeStatus ? '라이브' : '중단'
          } 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .put('/admin/campaigns-active', {
                campaignIds: [item.id],
                activeStatus: !item.activeStatus
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.activeStatus = !item.activeStatus;
          }
        });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedCampaignKeys = selectedRowKeys;
      this.selectedCampaigns = selectedRows;
    },
    openApprovedStatusDialog(record) {
      this.approveStatusDialog = true;
      this.selectedCampaign = record;
    }
  }
};
</script>
<style>
.table-striped-rows tr:nth-child(2n) td {
  background-color: #f5f5f5;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
</style>
