export function convertFormData(objectData) {
  const formData = new FormData();

  Object.keys(objectData).forEach(v => {
    if (objectData[v] != undefined) {
      formData.append(v, objectData[v]);
    }
  });

  return formData;
}

// ObjectData key => snake case -> camel case
export function getCamelKeys(ObjectData) {
  const newData = {};

  Object.keys(ObjectData).map(item => {
    let res = item;
    const regex = /^[a-z][A-Za-z]*$/;
    let itemVal = ObjectData[item];

    if (itemVal && typeof itemVal === 'object') {
      if (!Array.isArray(itemVal)) itemVal = getCamelKeys(itemVal);
    }
    const checkKey =
      item.charAt(0) === '_' ? item.substr(1, item.length - 1) : item;

    if (!regex.test(checkKey)) {
      // key가 snake_case인경우 camelCase로 변환
      if (/[_-]/.test(checkKey)) {
        res = convToCamel(checkKey);
      }
      // 초기화 obj에  변환한 key에 값 주입
      newData[`${res}`] = itemVal;
    } else {
      newData[`${res}`] = itemVal;
    }
    return res;
  });
  return newData;
}

// snake case -> camel case
export function convToCamel(key) {
  var beforeKey = key.toLowerCase();
  var afterKey = '';
  var beforeKeyArr = beforeKey.split('_');

  if (beforeKeyArr.length < 2) {
    return beforeKeyArr;
  }

  for (let i = 0; i < beforeKeyArr.length; i++) {
    if (beforeKeyArr[i].length > 0) {
      if (i == 0) afterKey += beforeKeyArr[i].toLowerCase();
      else
        afterKey +=
          beforeKeyArr[i].toLowerCase().substr(0, 1).toUpperCase() +
          beforeKeyArr[i].substr(1, beforeKeyArr[i].length - 1);
    }
  }
  return afterKey;
}
