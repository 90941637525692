import axios from 'axios';

export async function getReportStatsCouponAPI(params) {
  try {
    const { data } = await axios.get(`partner/report-stats/coupon`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsCouponChartAPI(params) {
  try {
    const { data } = await axios.get(
      `partner/report-stats-chart/coupon`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
