import axios from 'axios';
export default {
    namespaced: true,

    state: {
        testMode: false,
        advertiserId: null,
        advertiserGroups: null,
        dialog: false,
        dialogType: null,
        campaign: null,
        campaignId: null,
        tab: 0,
        originCampaign: null,
        currentStep: 1,
    },

    getters: {
        currentStep(state) {
            return state.currentStep
        },
        campaign(state) {
            return state.campaign
        },
        originCampaign(state) {
            return state.originCampaign
        },
        dialog(state) {
            return state.dialog
        },
        dialogType(state) {
            return state.dialogType
        },
        tab(state) {
            return state.tab
        },
        campaignId(state) {
            return state.campaignId
        },
        advertiserGroups(state) {
            return state.advertiserGroups
        },
        advertiserId(state) {
            return state.advertiserId
        },
        isDialogTypeRegister(state) {
            return state.dialogType === 'R'
        },
        isDialogTypeEdit(state) {
            return state.dialogType === 'E'
        }
    },

    mutations: {
        SET_CurrentStep(state, currentStep) {
            state.currentStep = currentStep
        },
        SET_Campaign(state, campaign) {
            state.campaign = campaign
        },
        SET_CampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        SET_OriginCampaign(state, originCampaign) {
            state.originCampaign = originCampaign
        },
        SET_Dialog(state, dialog) {
            state.dialog = dialog
        },
        SET_DialogType(state, dialogType) {
            state.dialogType = dialogType
        },
        SET_Tab(state, tab) {
            state.tab = tab
        },
        SET_AdvertiserGroups(state, advertiserGroups) {
            state.advertiserGroups = advertiserGroups
        },
        SET_AdvertiserId(state, advertiserId) {
            state.advertiserId = advertiserId;
        }
    },

    actions: {
        setCurrentStep({
            commit
        }, currentStep) {
            commit('SET_CurrentStep', currentStep)
        },
        setTab({
            commit
        }, tab) {
            commit('SET_Tab', tab)
        },
        initTab({
            commit
        }) {
            const tab = 0
            commit('SET_Tab', tab);
        },
        setCampaign({
            commit
        }, campaign) {
            commit('SET_Campaign', campaign)
        },
        setCampaignId({
            commit
        }, campaignId) {
            commit('SET_CampaignId', campaignId)
        },
        setOriginCampaign({
            commit
        }, originCampaign) {
            commit('SET_OriginCampaign', originCampaign)
        },
        setDialogTypeRegister({
            commit
        }) {
            commit('SET_DialogType', 'R')
        },
        setDialogTypeEdit({
            commit
        }) {
            commit('SET_DialogType', 'E')
        },
        openDialog({
            commit
        }) {
            commit('SET_Dialog', true)
        },
        closeDialog({
            commit,
            dispatch
        }) {
            commit('SET_Dialog', false);
            dispatch('initCampaign');
        },
        initCampaign({
            commit,
            state
        }) {
            const testMode = state.testMode;
            const campaign = {
                id: null,
                rs1: {
                    user: {
                        advertiser: testMode ? {
                            id: 1,
                            name: '티디아이'
                        } : null,
                        mediaGroups: testMode ? [{
                            id: 1,
                            name: '티디아이'
                        }] : [],
                        advertiserGroup: null,
                        tracker: null,
                    },
                    landingUrl: testMode ? 'https://tdi9.com' : null,
                    campaignName: testMode ? '테스트' : null,
                    campaignType: testMode ? 'push' : null,
                },
                rs2: {
                    push: {
                        clickType: testMode ? 'normal' : null,
                        subTitle: testMode ? '테스트 subTitle' : null,
                        category: testMode ? '건강/운동' : null,
                        title: testMode ? '테스트 title' : null,
                        text: testMode ? '테스트 text' : null,
                        messageImageId: null,
                        messageImageUrl: null,
                        messageImageType: null,
                        imageId: null,
                        imageUrl: null,
                        imageType: null,
                        messageDate: null,
                        messageTime: null
                    },
                    rolling: {
                        imageId: null,
                        imageUrl: null,
                        imageType: null,
                    },
                    message: {
                        clickType: testMode ? 'normal' : null,
                        subTitle: testMode ? '테스트 subTitle' : null,
                        category: testMode ? '건강/운동' : null,
                        title: testMode ? '테스트 title' : null,
                        text: testMode ? '테스트 text' : null,
                        messageImageId: null,
                        messageImageUrl: null,
                        messageImageType: null,
                        imageId: null,
                        imageUrl: null,
                        imageType: null,
                        iconId: null,
                        iconUrl: null,
                        iconType: null,
                    },
                },
                rs3: {
                    apps: [],
                    appsType: 'nontarget',
                    locations: [],
                    tags: [],
                    targetTags: [],
                    targetTagsType: null,
                    targetTagsMode: null,
                    targetTagsCount: null,
                    targetTagsRange: 30,
                    targetTagsClick: 1,
                    isTagFilter: false
                },
                rs4: {
                    impressionTime: 10, //광고노출시간
                    impressionTimeType: 's',
                    impressionFrequency: 1, //광고노출우선순위
                    adsGoalCount: 2, //광고 미노출 최대 클릭 수
                    adsLimitInterval: 2 //광고 클릭 후 재노출 기간
                },
                rs5: {
                    schedule: {
                        displayPeriodStart: testMode ? '2021-04-01' : null,
                        displayPeriodEnd: testMode ? '2021-05-30' : null,
                        issetDisplayPeriodEnd: true,
                        messageDate: null,
                        messageTime: null,
                    },
                    budget: {
                        dailyAdvertisingBudget: null,
                        totalAdvertisingBudget: null,
                        cpc: null,
                        cpm: null,
                        forecastReachCount: null
                    }
                },
            };
            commit('SET_Campaign', campaign);
            commit('SET_CurrentStep', 1);
        },
        hashingCampaign({
            dispatch
        }, campaign) {
            dispatch('setOriginCampaign', campaign);

            const {
                origin: cp,
                mediaGroups
            } = campaign;

            // 캠페인 타입, 광고 그룹
            const {
                campaign_type_push: push,
                campaign_type_rolling: rolling,
                campaign_type_message: message,
                advertiser_group: advertiserGroup,
                tracker: tracker
            } = cp;

            // 광고주
            const {
                advertiser
            } = advertiserGroup;

            // 캠페인 생성
            const newCampaign = {
                id: campaign.id,
                rs1: {
                    user: {
                        advertiser: advertiser,
                        mediaGroups: mediaGroups,
                        advertiserGroup: advertiserGroup,
                        tracker: tracker
                    },
                    campaignName: cp.name,
                    campaignType: cp.type,
                    landingUrl: cp.landing_url
                },
                rs2: {
                    push: {
                        clickType: push?.click_type || null,
                        subTitle: push?.sub_title || null,
                        category: push?.category || null,
                        title: push?.title || null,
                        text: push?.text || null,
                        imageId: push?.image?.id || null,
                        imageUrl: push?.image?.url || null,
                        imageType: push?.image?.type || null,
                        iconId: push?.icon?.id || null,
                        iconUrl: push?.icon?.url || null,
                        iconType: push?.icon?.type || null,
                        messageImageId: push?.message_image?.id || null,
                        messageImageUrl: push?.message_image?.url || null,
                        messageImageType: push?.message_image?.type || null,
                        messageDate: push?.message_date || null,
                        messageTime: push?.message_time || null
                    },
                    rolling: {
                        imageId: rolling?.image?.id || null,
                        imageUrl: rolling?.image?.url || null,
                        imageType: rolling?.image?.type || null
                    },
                    message: {
                        clickType: message?.click_type || null,
                        subTitle: message?.sub_title || null,
                        category: message?.category || null,
                        title: message?.title || null,
                        text: message?.text || null,
                        imageId: message?.image?.id || null,
                        imageUrl: message?.image?.url || null,
                        imageType: message?.image?.type || null,
                        iconId: message?.icon?.id || null,
                        iconUrl: message?.icon?.url || null,
                        iconType: message?.icon?.type || null,
                    },
                },
                rs3: {
                    apps: cp?.target_apps || [],
                    appsType: cp?.target_apps_type || 'nontarget',
                    locations: cp?.target_locations || [],
                    tags: cp?.tags || [],
                    targetTags: cp?.target_tags || [],
                    targetTagsMode: cp?.target_tags_mode || null,
                    targetTagsType: cp?.target_tags_type || null,
                    targetTagsRange: cp?.target_tags_range || 30,
                    targetTagsClick: cp?.target_tags_click || 1,
                    targetTagsCount: cp?.target_tags_count || null,
                    isTagFilter: cp?.is_tag_filter || false,
                },
                rs4: {
                    // impressionInterval: null, // 광고노출주기
                    // impressionIntervalType: 's',
                    impressionTime: cp.impression_time, //광고노출시간
                    impressionTimeType: cp.impression_time_type,
                    impressionFrequency: cp.impression_frequency, //광고노출우선순위
                    adsGoalCount: cp.ads_goal_count, //광고 미노출 최대 클릭 수
                    adsLimitInterval: cp.ads_limit_interval //광고 클릭 후 재노출 기간
                },
                rs5: {
                    schedule: {
                        displayPeriodStart: cp.display_period_start || null,
                        displayPeriodEnd: cp.isset_display_period_end ? cp?.display_period_end || null : null,
                        issetDisplayPeriodEnd: cp.isset_display_period_end,
                        messageDate: push?.message_date || null,
                        messageTime: push?.message_time || null
                    },
                    budget: {
                        dailyAdvertisingBudget: cp?.daily_advertising_budget || 0,
                        totalAdvertisingBudget: cp?.total_advertising_budget || 0,
                        cpc: cp?.cpc || 0,
                        cpm: cp?.cpm || 0,
                        forecastReachCount: cp?.forecastReachCount || null
                    }
                }
            };
            //dispatch('getAdvertiserGroups', advertiser.id)
            dispatch('setCampaign', newCampaign);
        },
        getAdvertiserGroups({
            commit,
            state
        }, advertiserId = null) {
            if (!advertiserId) {
                return false;
            }

            if (state.advertiserId === advertiserId && state.advertiserGroups !== null) {
                return false;
            }

            return axios
                .get('/admin/campaigns-advertiser-groups/' + advertiserId)
                .then(res => {
                    // console.log(`test`, res);
                    const {
                        advertiserGroups
                    } = res.data;
                    commit('SET_AdvertiserGroups', advertiserGroups ?? null);
                    commit('SET_AdvertiserId', advertiserId);
                })
                .catch(err => {
                    console.error(err);
                });
        },
    }
};