<template>
  <div class="filters mt-8" v-if="dialog">
    <a-spin :spinning="loading">
      <ValidationObserver :ref="refKey">
        <form
          @submit.prevent="executeRecaptcha"
          autocomplete="off"
          aria-autocomplete="off"
        >
          <h3 class="font-weight-bold">기본 정보</h3>
          <v-row class="pb-5" dense>
            <CustomGridItem title="타입" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <!-- <v-select
                    v-model="form.role"
                    placeholder="권한 선택"
                    outlined
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="roleList"
                    item-text="text"
                    item-value="value"
                    required
                    dense
                  /> -->
                  <v-select
                    v-model="form.type"
                    placeholder="타입 선택"
                    outlined
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="AdvertiserTypeList"
                    item-text="text"
                    item-value="value"
                    required
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem outlined :md="6">
              <template v-slot:title>
                <div class="title-text">
                  이메일
                  <v-tooltip right>
                    <template #activator="{ on: onTooltip }">
                      <v-btn v-on="{ ...onTooltip }" icon>
                        <v-icon small dense>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>

                    <div class="text-subtitle-2">
                      · 홈텍스에 등록된 이메일 주소를 권장합니다.
                    </div>
                    <div class="text-subtitle-2 whtie--text"></div>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                  <v-text-field
                    v-model="form.email"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="이메일 주소를 입력하세요."
                    required
                    outlined
                    dense
                    @blur="handleEmailInput"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="ID" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 100 }"
                >
                  <v-text-field
                    v-model="form.uid"
                    :counter="100"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="ID"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="비밀번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    min: 8,
                    max: 16
                  }"
                >
                  <v-text-field
                    v-model="form.password"
                    placeholder="영문/숫자/기호 혼합 8자리 이상 입력"
                    outlined
                    color="green"
                    autocomplete="new-password"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    dense
                    counter
                    :append-icon="isPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPassword ? 'text' : 'password'"
                    @click:append="isPassword = !isPassword"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="광고주명" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 100 }"
                >
                  <v-text-field
                    v-model="form.name"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="광고주명"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="전화번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|numeric|min:10|max:11"
                >
                  <v-text-field
                    v-model="form.phone"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    required
                    outlined
                    dense
                    counter
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="담당자명" outlined :md="6">
              <template v-slot:content>
                <v-text-field
                  v-model="form.managerName"
                  hide-details
                  placeholder="담당자명"
                  outlined
                  dense
                />
              </template>
            </CustomGridItem>

            <CustomGridItem title="담당자 연락처" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|numeric|min:10|max:11"
                >
                  <v-text-field
                    v-model="form.managerTel"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    required
                    outlined
                    dense
                    counter
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>

          <h3 class="font-weight-bold mt-5">
            회사 정보
            <span class="caption red--text ml-2">
              ※ 사업자등록증에 표기된 내용으로 입력해주세요.
            </span>
          </h3>
          <v-row class="pb-5" dense>
            <CustomGridItem title="대표자 명" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 20 }"
                >
                  <v-text-field
                    v-model="form.businessPresident"
                    :counter="20"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="대표자 명"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <CustomGridItem title="사업자 상호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 20 }"
                >
                  <v-text-field
                    v-model="form.businessName"
                    :counter="20"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="사업자 상호"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="사업자 등록번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|digits:10"
                >
                  <v-text-field
                    v-model="form.businessNum"
                    :counter="10"
                    type="tel"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="업태" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="form.businessType"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="업태"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="업종" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="form.businessCategory"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="업종"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="사업장 주소" titleClass="col-3 " outlined>
              <template v-slot:content>
                <div class="pa-3">
                  <KakaoAddressForm
                    popupKey="user"
                    business
                    popupTitle="사업장 주소 검색"
                    dense
                    @submit="
                      value => {
                        form.businessPostcode = value.postcode;
                        form.businessAddress = value.address;
                        form.businessDetailAddress = value.detailAddress;
                        form.businessExtraAddress = value.extraAddress;
                      }
                    "
                  />
                </div>
              </template>
            </CustomGridItem>
            <CustomGridItem title="세금계산서 이메일" outlined :md="6">
              <template v-slot:title>
                <div class="title-text">
                  세금계산서 이메일
                </div>
              </template>
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                  <v-text-field
                    v-model="form.businessTaxEmail"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="세금계산서 이메일 주소를 입력하세요."
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>

          <h3 class="font-weight-bold mt-5">결제정보</h3>
          <v-row class="pb-3" dense>
            <CustomGridItem title="은행" titleClass="col-3" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: false }"
                >
                  <v-select
                    v-model="form.bankName"
                    placeholder="은행 선택"
                    outlined
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="BankList"
                    required
                    dense
                    style="max-width: 300px"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="계좌번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }" rules="numeric">
                  <v-text-field
                    v-model="form.bankAccountNum"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    type="tel"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="예금주" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: false }"
                >
                  <v-text-field
                    v-model="form.bankAccountHolder"
                    :counter="100"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="예금주"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>
          <v-row class="mt-5" dense>
            <CustomGridItem title="추천인 코드" titleClass="col-3" outlined>
              <template v-slot:content>
                <v-text-field
                  v-model="form.referrerCode"
                  hide-details
                  placeholder="추천인 코드"
                  outlined
                  dense
                />
              </template>
            </CustomGridItem>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="#00d1b2"
                class="white--text"
                depressed
                :loading="loading"
                @click="submit"
              >
                등록
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { BankList } from '@/config/charge';
import { AdvertiserTypeList } from '@/config/user';
import { validateEmailApi } from '@/api/common/register';
import { createAdvertiserAPI } from '@/api/admin/AdvertiserAPI';
import KakaoAddressForm from '@/components/KakaoAddressForm.vue';
import CustomGridItem from '@/components/CustomGridItem.vue';
import { KakaoAddress } from '@/model/register/KakaoAddress';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    KakaoAddressForm,
    CustomGridItem
  },
  props: {
    dialog: {
      type: Boolean
    }
  },
  data() {
    return {
      kakaoAddress: null,
      isPassword: false,
      BankList: BankList,
      loading: false,
      refKey: 'storeForm',
      AdvertiserTypeList: AdvertiserTypeList
    };
  },
  computed: {
    ...mapGetters({
      form: 'advertiser/form'
    })
  },
  watch: {
    dialog() {
      this.loading = false;
      this.kakaoAddress = new KakaoAddress();

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'advertiser/closeDialog'
    }),
    async handleEmailInput(e) {
      const email = e.target.value;

      if (email.length === 0) return false;

      this.loading = true;

      const params = {
        email: this.form.email
      };

      const [error, data] = await validateEmailApi(params);
      if (error) {
        console.error(error);
        this.form.email = null;
      } else {
        const { success, message } = data;
        if (success === false) {
          this.$Swal.fire({
            icon: 'error',
            html: message,
            confirmButtonText: '확인'
          });

          this.form.email = null;
        }
      }
      this.loading = false;
    },
    async submit() {
      const { isValid } = await this.$refs[this.refKey].validateWithInfo();

      if (!isValid) {
        this.$Swal.fire({
          icon: 'error',
          html: `입력 항목을 확인해주세요.`,
          confirmButtonText: '확인'
        });
        return false;
      }

      this.loading = true;

      const [error] = await createAdvertiserAPI(this.form);
      let type = 'error';
      let message = '등록 실패';

      if (error) {
        message = error?.data?.message || '요청 실패';
      } else {
        type = 'success';
        message = '등록 성공';

        this.closeDialog('store');
        this.$emit('refresh');
      }
      this.$notification[type]({
        message: message
      });

      this.loading = false;
    }
  }
};
</script>

<style>
.ant-form-item {
  margin-bottom: 0 !important;
}
</style>
