var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"depressed":"","dark":""},on:{"click":function($event){return _vm.openDialog({ record: {}, type: 'store' })}}},[_vm._v(" 광고주 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('AdvertiserFilters',{attrs:{"filters":_vm.filters,"loading":_vm.loading},on:{"search":function($event){return _vm.fetchAdvertisers(1)}}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.loading,"size":"small","columns":_vm.columns,"bordered":"","data-source":_vm.advertisers,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.openDialog({
                      record: Object.assign({}, record,
                        {advertiserId: record.id}),
                      type: 'detail'
                    });
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"status",fn:function(value, record){return [_c('v-row',{staticClass:"row ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-auto pa-0"},[_c('v-badge',{attrs:{"left":"","dot":"","inline":"","color":record.status.color}},[_vm._v(" "+_vm._s(record.status.text)+" ")])],1),_c('v-col',{staticClass:"col-auto pa-0",staticStyle:{"min-width":"30px","text-align":"right"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleApproveStatusClick(record)}}},Object.assign({}, onTooltip)),[_c('v-icon',{attrs:{"size":"17px"}},[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("계정 활성여부 변경")])])],1)],1)]}},{key:"role",fn:function(value, record){return [_vm._v(" "+_vm._s(record.role === 'cashnote' ? '캐시노트' : '일반 관리자')+" ")]}},{key:"actions",fn:function(value, record){return [_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleUserIFrameClick(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("사용자 화면")])])]}},{key:"point",fn:function(value, record){return [_c('v-menu',{attrs:{"open-on-hover":"","open-delay":"100","close-delay":"100","bottom":"","offset-y":"","nudge-left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-0 font-weight-bold",attrs:{"label":"","color":"secondary","small":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(_vm._f("KRW")(Number(value)))+" ")])])]}}],null,true)},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"min-width":"200","min-height":"150","max-height":"300"}},[_c('v-card-title',[_vm._v(" 포인트 상세 내역 ")]),_c('v-card-text',{staticClass:"text-right"},[_c('div',{staticClass:"mb-3"},[_c('v-chip',{attrs:{"label":""}},[_c('span',[_vm._v(" 유상포인트: "+_vm._s(_vm._f("KRW")(Number(record.creditPoint)))+" ")])])],1),_c('div',[_c('v-chip',{attrs:{"label":""}},[_c('span',[_vm._v(" 무상포인트: "+_vm._s(_vm._f("KRW")(record.freePoint))+" ")])])],1)])],1)],1)]}}])}),(_vm.hasAdvertisers)?_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('a-pagination',{attrs:{"defaultPageSize":10,"page-size":_vm.pagination.perPage,"size":"large","show-size-changer":true,"pageSizeOptions":['10', '20', '50', '100'],"total":_vm.pagination.totalCount},on:{"update:pageSize":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"change":function($event){return _vm.fetchAdvertisers()},"showSizeChange":function($event){return _vm.fetchAdvertisers(1)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)],1)],1),_c('AdvertiserStoreDialog',{on:{"refresh":_vm.fetchAdvertisers}}),_c('AdvertiserDetailDialog',{on:{"refresh":_vm.fetchAdvertisers}}),(_vm.selectedAdvertiser)?_c('AdvertiserUpdateStatusDialog',{attrs:{"advertiser":_vm.selectedAdvertiser},on:{"refresh":_vm.fetchAdvertisers,"close":_vm.handleClosedApproveStatusDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }