import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import { UserApproveStatusList } from '@/config/user';
export class Advertiser {
  key = null;
  origin = null;
  id = null;
  userId = null;
  createdAt = null;
  type = null;

  // user 정보
  user = null;
  name = null;
  uid = null;
  phone = null;
  email = null;
  role = null;
  status = null;
  denyReason = null;
  creditPoint = 0;
  freePoint = 0;
  totalPoint = 0;
  user = null;

  constructor(init) {
    if (!init) return;

    this.origin = JSON.parse(JSON.stringify(init));

    const covObject = getCamelKeys(init);
    // const initKeys = Object.keys(covObject);

    for (const key in covObject) {
      let val = covObject[key] ?? null;

      if (key === 'createdAt') {
        val = moment(val).format('YYYY.MM.DD HH:mm');
      }

      this[key] = val;
    }

    if (this.user) {
      const {
        approveStatusFlag,
        name,
        uid,
        email,
        phone,
        role,
        denyReason,
        creditPoint,
        freePoint
      } = this.user;

      let status =
        UserApproveStatusList.find(v => {
          return v.value === approveStatusFlag;
        }) || null;

      this.status = status;

      this.name = name;
      this.uid = uid;
      this.email = email;
      this.phone = phone;
      this.role = role;
      this.denyReason = denyReason;
      this.creditPoint = Number(creditPoint);
      this.freePoint = Number(freePoint);
      this.totalPoint = Number(creditPoint) + Number(freePoint);
    }
  }
}
