import menus from '@/store/did/menus';
import user from './user';
import dialog from './dialog';
import config from './config';
import point from './point';
import didCampaignService from './did-campaign-service';

export default {
  menus,
  user,
  dialog,
  config,
  point,
  didCampaignService
};
