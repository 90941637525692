var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800","scrollable":"","persistent":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.initDialog()}}},Object.assign({}, dialogOn)),[_vm._v(_vm._s(_vm.title))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"inquiryDialogObs"},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("1:1 문의 등록")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5 py-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.categoryList,"label":"문의 유형","required":"","outlined":""},model:{value:(_vm.inquiry.category),callback:function ($$v) {_vm.$set(_vm.inquiry, "category", $$v)},expression:"inquiry.category"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"문의 내용","required":"","outlined":"","clearable":""},model:{value:(_vm.inquiry.content),callback:function ($$v) {_vm.$set(_vm.inquiry, "content", $$v)},expression:"inquiry.content"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","label":"연락받을 메일 주소","outlined":"","clearable":""},model:{value:(_vm.inquiry.email),callback:function ($$v) {_vm.$set(_vm.inquiry, "email", $$v)},expression:"inquiry.email"}})]}}])})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 등록 ")]),_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 취소 ")])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }