<template>
  <v-col class="pb-0" cols="auto">
    <v-btn
      color="secondary"
      small
      :disabled="loading"
      @click="() => (changeResourceDialog = true)"
    >
      소재 변경
    </v-btn>

    <v-dialog
      v-model="changeResourceDialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-toolbar flat dark>
          <v-toolbar-title>캠페인 리소스 선택</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="handleCancleChangeResourceClick">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="py-3 px-0">
          <PreviewResource
            v-if="changeResourceDialog"
            :selectedId="tempCampaignResourceId ? [tempCampaignResourceId] : []"
            :userId="didCampaignService.userId"
            :originId="didCampaignService.id"
            @setResource="setResource"
            :campaignType="'did'"
          />

          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>

            <v-btn
              color="secondary"
              @click="handleCancleChangeResourceClick"
              :loading="loading"
              outlined
            >
              취소
            </v-btn>
            <v-btn
              color="secondary"
              @click="handleChangeResourceClick"
              :loading="loading"
              :disabled="loading"
            >
              적용
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>

      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CampaignResource } from '@/model/common';
import PreviewResource from '@/components/did/PreviewResource.vue';
import { updateDidCampaignResourceApi } from '@/api/admin/DidCampaignAPI';
export default {
  components: { PreviewResource },
  data() {
    return {
      loading: false,
      changeResourceDialog: false,
      tempCampaignResourceId: null,
      tempCampaignResource: null
    };
  },
  computed: {
    ...mapGetters({
      didCampaignService: 'didCampaignService/didCampaignService'
    })
  },
  watch: {
    changeResourceDialog(newVal) {
      if (newVal) {
        this.init();
      }
      this.changeResourceDialog = newVal;
    }
  },
  mounted() {
    this.changeResourceDialog = false;
    this.init();
  },
  methods: {
    ...mapActions({
      closeDialog: 'didCampaignService/closeDialog'
    }),
    init() {
      this.loading = false;
      this.tempCampaignResource = this.didCampaignService.campaignResource;
      this.tempCampaignResourceId = this.didCampaignService.campaignResourceId;
    },
    setResource(resource) {
      this.tempCampaignResourceId = resource ? resource.id : null;
      this.tempCampaignResource = resource
        ? new CampaignResource(resource)
        : null;
    },
    /**@description: 소재 취소 */
    handleCancleChangeResourceClick() {
      // 원래 값으로 돌려놓고
      this.didCampaignService.campaignResourceId =
        this.didCampaignService.origin.campaignResourceId;
      this.didCampaignService.campaignResource =
        this.didCampaignService.origin.campaignResource;
      this.changeResourceDialog = false;
    },
    /**@description: 소재 변경시 */
    async handleChangeResourceClick() {
      if (this.loading) return;

      if (
        !this.tempCampaignResourceId ||
        this.didCampaignService.origin.campaignResourceId ===
          this.tempCampaignResourceId
      ) {
        this.$Swal.fire({
          icon: 'info',
          html: '<h3>변경 사항이 없습니다.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      this.loading = true;

      this.$Swal
        .fire({
          icon: 'info',
          html: `<h3>소재를 변경하시겠습니까?<br/>변경된 소재는 셋톱박스 갱신 후 반영됩니다. </h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.loading = true;

            const [error] = await updateDidCampaignResourceApi(
              this.didCampaignService.id,
              {
                campaignResourceId: this.tempCampaignResourceId
              }
            );

            this.loading = false;

            if (error) {
              console.error(error);
              this.$axiosErrorAlert(error?.data?.message);
            } else {
              // 저장후에 반영
              this.didCampaignService.campaignResourceId =
                this.didCampaignService.origin.campaignResourceId =
                  this.tempCampaignResourceId;
              this.didCampaignService.campaignResource =
                this.didCampaignService.origin.campaignResource =
                  new CampaignResource(this.tempCampaignResource);

              this.$Swal
                .fire({
                  icon: 'success',
                  html: '소재가 변경 되었습니다.',
                  confirmButtonText: '확인'
                })
                .then(() => {
                  // 현재 모달 닫기
                  this.changeResourceDialog = false;
                });
            }
          }
        });

      this.loading = false;
    }
  }
};
</script>
