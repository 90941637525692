export const UnitOfTimeList = [
  {
    text: '초',
    value: 's'
  },
  {
    text: '분',
    value: 'm'
  },
  {
    text: '시간',
    value: 'h'
  },
  {
    text: '일',
    value: 'd'
  }
];

export const CampaignResourceTypeList = [
  {
    text: 'DID',
    value: 'did',
    color: 'purple'
  }
  // 회의 결과: 우선 보류 (2022.09.05)
  // {
  //   text: '이미지',
  //   value: 'rolling',
  //   color: 'orange'
  // },
  // {
  //   text: '푸시',
  //   value: 'push',
  //   color: 'primary'
  // }
];

export const CampaignResourceDataTypeList = [
  {
    text: '동영상',
    value: 'video',
    color: 'purple'
  }
  // 회의 결과: 우선 보류 (2022.09.05)
  // {
  //   text: '이미지',
  //   value: 'image',
  //   color: 'orange'
  // }
];

export const CampaignResourceStatusList = [
  {
    text: '승인',
    value: 'A',
    comment: '캠페인이 즉시 활성화 됩니다.',
    color: 'blue'
  },
  {
    text: '대기',
    value: 'W',
    color: 'orange'
  },
  {
    text: '거절',
    value: 'D',
    color: 'red'
  }
];

export const BusinessClassList = [
  {
    text: '광고주',
    value: 'advertiser',
    color: 'blue'
  },
  {
    text: '매체사',
    value: 'media',
    color: 'orange'
  }
];
