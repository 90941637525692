<template>
  <v-dialog :value="dialog" width="900" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>
          충전하기 (
          <span class="font-weight-bold">카드결제</span>
          )
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <h2>충전 금액</h2>
        <ValidationObserver ref="creditCardRef">
          <v-card flat outlined class="mb-5 pa-3">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{
                required: true,
                min_value_krw: 30000
              }"
            >
              <v-text-field
                v-model="formattedAmount"
                label="충전금액"
                outlined
                hide-details=""
                :error-messages="errors"
                suffix="원"
                clearable
                @click:clear="clearAmount()"
                @change="handleAmountChange"
                :disabled="!isDirectInput"
                :class="{
                  input_won: !isDirectInput === false,
                  'input_onlyView-border': !isDirectInput === true
                }"
              >
              </v-text-field>
            </ValidationProvider>
            <div class="mt-2 mb-4">
              <v-btn small outlined class="ma-1" @click="clearAmount()">
                초기화
              </v-btn>
              <v-btn
                v-for="(price, index) in priceList"
                :key="index"
                small
                outlined
                class="ma-1"
                @click="handleAddAmountClick(price, index)"
                :class="{ selected: selected == index }"
              >
                {{ price.text }}
              </v-btn>
            </div>

            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="부가세"
                  :value="taxAmount.toLocaleString()"
                  outlined
                  readonly
                  filled
                  suffix="원"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="결제 금액"
                  :value="advertisingAmount.toLocaleString()"
                  outlined
                  readonly
                  filled
                  suffix="원"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col :cols="12" class="py-0">
                <ul class="ul_attention mt-3 mb-4 red--text">
                  <li>충전 최소 금액은 30,000원(부가세 미포함)입니다.</li>
                  <li>충전 금액은 부가세 포함한 비용으로 결제됩니다.</li>
                  <li>부가세를 제외한 광고 금액을 확인하세요.</li>
                  <li>직접입력 시, 충전 단위는 10,000원 입니다.</li>
                </ul>
              </v-col>
            </v-row>
          </v-card>

          <h2>결제 수단 및 약관 동의</h2>
          <div
            class="d-flex justify-space-between align-center rounded-lg my-3 px-3"
            style="outline: 1px solid #ddd"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: { allowFalse: false } }"
            >
              <v-checkbox
                v-model="policy1"
                dense
                class="rounded-circle mt-0 py-3"
                :error-messages="errors"
                readonly
                hide-details
                @click="handlePolicyClick(policy1)"
              >
                <template v-slot:label>
                  <div class="d-inline-block">
                    결제 정보 수집 및 이용 약관 동의하기
                  </div>
                </template>
              </v-checkbox>
            </ValidationProvider>
            <PolicyPaymentDialog
              @agreePolicy="policy1 = true"
              title="결제 정보 수집 및 이용 약관 동의하기"
            />
          </div>
          <div
            class="d-flex justify-space-between align-center rounded-lg px-3"
            style="outline: 1px solid #ddd"
          >
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: { allowFalse: false } }"
            >
              <v-checkbox
                v-model="policy2"
                :error-messages="errors"
                class="mt-0 py-3"
                dense
                ripple
                hide-details
              >
                <template v-slot:label class="d-inline-block">
                  <div>위 구매 조건 확인 및 결제 진행 동의하기</div>
                </template>
              </v-checkbox>
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn dark large @click="submit()" :loading="loading">
          충전요청
        </v-btn>
        <v-btn large outlined @click="handleCloseClick()"> 취소 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import PolicyPaymentDialog from '@/components/payment/PolicyPaymentDialog.vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    PolicyPaymentDialog
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/creditCardDialog'
    }),
    taxAmount() {
      return Math.round(this.amount / 10);
    },
    advertisingAmount() {
      return Math.round(this.amount + this.taxAmount);
    },
    isDirectInput() {
      return this.priceList[this.selected].text === '직접입력';
    }
  },
  data() {
    return {
      amount: null,
      formattedAmount: null,
      loading: false,
      policy1: false,
      policy2: false,
      selected: 0,
      priceList: [
        {
          text: '30,000원',
          value: 30000
        },
        {
          text: '50,000원',
          value: 50000
        },
        {
          text: '100,000원',
          value: 100000
        },
        {
          text: '300,000원',
          value: 300000
        },
        {
          text: '500,000원',
          value: 500000
        },
        {
          text: '직접입력',
          value: null
        }
      ]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      closeCreditCardDialog: 'dialog/closeCreditCardDialog'
    }),
    init() {
      this.$refs.creditCardRef.reset();
      this.clearAmount();
      this.handleAddAmountClick(this.priceList[this.selected], this.selected);
      this.policy1 = false;
      this.policy2 = false;
    },
    handleAmountChange(price) {
      if (price == null) {
        return false;
      }

      let amount = Number(price.replaceAll(',', ''));
      if (isNaN(amount)) {
        this.amount = null;
        this.formattedAmount = null;
      } else {
        // 천원이하 반올림처리
        if (amount > 10000) {
          amount = Math.round(amount / 10000) * 10000;
        }
        this.amount = amount;
        this.formattedAmount = amount.toLocaleString();
      }
    },
    handleAddAmountClick(price, index) {
      this.selected = index;

      if (this.isDirectInput) {
        this.formattedAmount = null;
        this.amount = null;
      } else {
        this.amount = price.value;
        this.formattedAmount = this.amount.toLocaleString();
      }
    },
    clearAmount() {
      this.amount = null;
      this.formattedAmount = null;
    },
    async submit() {
      const valid = await this.$refs.creditCardRef.validate();
      if (valid) {
        this.createPaymentRequestApi();
      } else {
        this.$Swal.fire({
          icon: 'error',
          html: '입력 항목을 다시한번 확인해주세요.',
          timer: 1000,
          showConfirmButton: false
        });
      }
    },
    async createPaymentRequestApi() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/payletter/payment-request`, {
          amount: this.advertisingAmount
        });
        if (data.payment.online_url) {
          window.open(
            data.payment.online_url,
            '_blank',
            'width=300, height=300'
          );
        }
        this.handleCloseClick();
      } catch (err) {
        console.error(err);
        const { message } = err.data;
        this.$Swal.fire({
          icon: 'error',
          html: `<h3>${message ? message : '잘못된 요청입니다.'}</h3>`,
          confirmButtonText: '확인'
        });
      } finally {
        this.loading = false;
      }
    },
    handlePolicyClick(policy) {
      if (policy) {
        // 이미 동의한 경우
        this.policy1 = !policy;
      } else {
        this.$Swal.fire({
          icon: 'info',
          html: '약관 내용을 확인 후 동의해주세요.',
          confirmButtonText: '확인'
        });
      }
    },
    handleCloseClick() {
      this.closeCreditCardDialog();
    }
  }
};
</script>

<style></style>
