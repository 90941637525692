import axios from 'axios';

/**
 * @description: 캠페인 소재 목록 조회
 * @param:
 * [ss] => 검색어
 * [page] => 페이지
 * [perPage] => 한페이지에 보여질 갯수
 * [campaignType] => 캠페인 타입
 * */
export async function getCampaignResourcesApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/campaign-resources`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: 캠페인 소재 상세 조회
 * */
export async function getCampaignResourceApi(resourceId) {
  try {
    const { data } = await axios.get(
      `/admin/did/campaign-resource/${resourceId}`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: 캠페인 소재 등록
 * @param: FormData
 * */
export async function uploadCampaignResource(userId, params) {
  try {
    const { data } = await axios.post(
      `/admin/did/campaign-resource/${userId}`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: 캠페인 소재삭제
 * @param:
 *  [resourceId]: resource Id
 * */
export async function deleteCampaignResource(resourceId) {
  try {
    const { data } = await axios.delete(
      `/admin/did/campaign-resource/${resourceId}`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: 캠페인 소재 만료일 연장(90일)
 * @param:
 *  [resourceId]: resource Id
 * */
export async function extendCampaignResource(resourceId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign-resource/${resourceId}/extend`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: 캠페인 소재 상태 승인
 * @param:
 *  [resourceId]: resource Id
 * */
export async function updateCampaignResourceApprove(resourceId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign-resource/${resourceId}/approve/commit`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: 캠페인 소재 상태 거절
 *  * @param:
 *  [resourceId]: resource Id
 * */
export async function updateCampaignResourceDeny(resourceId, param) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign-resource/${resourceId}/approve/deny`,
      param
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: 캠페인 소재 재생시간 변경
 * @param:
 *  [resourceId]: resource Id
 * */
export async function changeCampaignResourcePlaytime(resourceId, param) {
  try {
    const { data } = await axios.patch(
      `/admin/did/campaign-resource/${resourceId}/playtime`,
      param
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
