var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"depressed":"","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v(" 소재 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('CampaignResourceFilters',{attrs:{"filters":_vm.filters,"loading":_vm.indexLoading},on:{"search":_vm.fetchCampaignResources}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.indexLoading,"size":"small","rowKey":"id","columns":_vm.columns,"bordered":"","data-source":_vm.campaignResources,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleRowClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(value, record, index){return [_vm._v(" "+_vm._s(_vm.pagination.total - (_vm.pagination.perPage * _vm.pagination.page - _vm.pagination.perPage) - index)+" ")]}},{key:"approveStatusInfo",fn:function(value, record){return [_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"min-height":"30px !important"}},[_c('v-badge',{attrs:{"left":"","dot":"","inline":"","color":value.color}},[_vm._v(" "+_vm._s(value.text)+" ")]),(!record.isExpired && value.value === 'W')?_c('div',{staticStyle:{"min-width":"40px"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleApproveStatusClick(record)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("게재 상태 변경")])])],1):_vm._e()],1)])]}},{key:"validDate",fn:function(value, record){return [_c('div',{style:(("color:" + (record.isExpired
                  ? 'red'
                  : record.isExtendable
                  ? '#FF6D00'
                  : '')))},[_vm._v(" "+_vm._s(value)+" "),(record.isExpired || record.isExtendable)?_c('div',[_c('v-icon',{attrs:{"small":"","color":record.isExpired
                      ? 'red'
                      : record.isExtendable
                      ? '#FF6D00'
                      : ''}},[_vm._v(" mdi-information-outline ")]),_vm._v(" "+_vm._s(record.isExpired ? '만료됨' : record.isExtendable ? '연장필요' : '')+" ")],1):_vm._e()])]}},{key:"typeInfo",fn:function(value){return [(value)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":value.color}},[_vm._v(" "+_vm._s(value.text)+" ")]):[_vm._v(" - ")]]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalCount)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":_vm.pagination.perPage,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1),(_vm.dialog)?_c('CampaignResourceDialog',{on:{"refresh":_vm.fetchCampaignResources}}):_vm._e(),(_vm.approveStatusDialog)?_c('ApproveStatusDialog',{on:{"refresh":_vm.fetchCampaignResources}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }