export default {
  namespaced: true,
  state: {
    filters: {
      status: 'wait',
      category: '',
      searchString: ''
    },
    dialog: false,
    inquiry: null
  },
  getters: {
    filters(state) {
      return state.filters;
    },
    dialog(state) {
      return state.dialog;
    },
    inquiry(state) {
      return state.inquiry;
    }
  },
  mutations: {
    SET_Inquiry(state, inquiry) {
      state.inquiry = inquiry ?? null;
    },
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    }
  },
  actions: {
    openDialog({ commit }, inquiry) {
      commit('SET_Dialog', true);

      if (inquiry) {
        commit('SET_Inquiry', inquiry);
      }
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
      commit('SET_Inquiry', null);
    }
  }
};
