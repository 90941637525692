import moment from 'moment';
import { getAdvertiserAPI } from '@/api/admin/AdvertiserAPI';
import { Advertiser } from '@/model/admin/Advertiser';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      store: false
    },
    form: {},
    dialog: {
      store: false,
      detail: false,
      approveStatus: false
    },
    record: {},
    filters: {
      email: '',
      name: '',
      uid: '',
      status: '',
      phone: '',
      dates: [moment().subtract(2, 'years'), moment()]
    }
  },
  getters: {
    form(state) {
      return state.form;
    },
    dialog(state) {
      return state.dialog;
    },
    record(state) {
      return state.record;
    },
    filters(state) {
      return state.filters;
    },
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    SET_Dialog(state, { dialog: dialog, type: type }) {
      state.dialog[type] = dialog;
    },
    SET_Record(state, record) {
      state.record = record;
    },
    SET_Form(state, form) {
      state.form = form;
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    }
  },
  actions: {
    initForm({ commit }) {
      const form = {
        status: '',
        type: null,
        name: null,
        uid: '',
        role: 'advertiser',
        password: null,
        phone: null,
        email: null,
        managerName: null,
        managerTel: null,
        businessName: null,
        businessNum: null,
        businessType: null,
        businessCategory: null,
        businessAddress: null
      };
      commit('SET_Form', form);
    },
    async openDialog({ commit, dispatch }, { record: record, type: type }) {
      if (type === 'detail') {
        const { advertiserId } = record;

        if (!advertiserId) return;

        await dispatch('getAdvertiser', advertiserId);
        // commit('SET_Record', record);
      }

      if (type === 'store') {
        dispatch('initForm');
      }

      if (type === 'approveStatus') {
        dispatch('initForm');
      }

      commit('SET_Dialog', {
        dialog: true,
        type: type
      });
    },
    closeDialog({ commit }, type) {
      commit('SET_Dialog', {
        dialog: false,
        type: type
      });
    },
    async getAdvertiser({ commit }, advertiserId) {
      let newAdvertiser = null;

      if (advertiserId) {
        const [error, data] = await getAdvertiserAPI(advertiserId);
        if (error) {
          console.error(error);
        } else {
          const { advertiser } = data;

          newAdvertiser = new Advertiser(advertiser);
        }
        commit('SET_Record', newAdvertiser);
      }
    }
  }
};
