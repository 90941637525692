var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authenticated)?_c('div',[(_vm.authenticated)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","mini-variant":!_vm.$isMobile && !_vm.mini,"mobile-breakpoint":"600"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"mt-5 text-center d-inline-block d-sm-none",staticStyle:{"width":"100%"}},[_c('UserPointButton')],1),_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.menus),function(menu,mIndex){return _c('div',{key:mIndex},[(menu.items)?_c('v-list-group',{attrs:{"sub-group":"","color":"dark"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(menu.text)}})],1)]},proxy:true}],null,true),model:{value:(menu.active),callback:function ($$v) {_vm.$set(menu, "active", $$v)},expression:"menu.active"}},_vm._l((menu.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":item.link,"exact":""}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)","disabled":_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, onTooltip)),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.text))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)}),1):_c('v-list-item',{attrs:{"link":"","to":menu.link,"exact":""}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)","disabled":_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, onTooltip)),[_vm._v(_vm._s(menu.icon))])]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(menu.text))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(menu.text))])],1)],1)],1)}),0),_c('BottomMenu')],1):_vm._e(),(_vm.authenticated)?_c('v-app-bar',{attrs:{"app":"","clipped-left":"","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openNav()}}}),_c('v-toolbar-title',[_c('v-img',{attrs:{"max-height":"50","max-width":"100","src":_vm.$mainLogoPath}})],1),_c('v-spacer'),_c('div',{staticStyle:{"width":"200px"}},[_c('UserPointButton',{staticClass:"mr-lg-5 d-sm-block d-none"})],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,2166920326)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"primary","size":"48"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(" "+_vm._s(_vm.user.name.slice(0, 2))+" ")])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.user.name)+" 님 반가워요😊 ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1),_c('v-divider',{staticClass:"my-2"}),_vm._l((_vm.memberMenus),function(menu,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":{
            name: menu.name,
            params: menu.params
          },"exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menu.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(menu.text))])],1)],1)}),_c('v-list-item',{attrs:{"disabled":_vm.loading},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("로그아웃")])],1)],1)],2)],1)],1):_vm._e(),_c('v-main',[_c('div',{staticStyle:{"min-height":"95vh"}},[_c('router-view',{attrs:{"name":"auth"}})],1),(_vm.authenticated)?_c('v-footer',{attrs:{"padless":"","inset":""}},[_c('v-card',{attrs:{"color":"#efefef","flat":"","tile":"","width":"100%"}},[_c('v-divider'),_c('v-card-text',{staticClass:"pa-2"},[_c('div',[_c('ul',{staticClass:"list"},[_c('li',[_vm._v("주식회사 티디아이")]),_c('li',[_vm._v("대표자 : 이승주")]),_c('li',[_vm._v("통신판매업신고 : 제 2012-부산해운-004호")]),_c('li',[_vm._v("사업자번호 : 617-81-95610")]),_c('li',[_vm._v("연락처 : 070-5097-1656")]),_c('li',[_vm._v(" 주소 : 서울특별시 서초구 반포대로 20길 29 (서초동, TDI 타워) ")])]),_c('address',{staticClass:"copyright"},[_vm._v(" © Noti-i. All Rights Reserved. ")])])])],1)],1):_vm._e()],1),_c('v-snackbar',{attrs:{"value":_vm.errors,"timeout":2000}},[_vm._v(" "+_vm._s(_vm.errors)+" "),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.errors = false}}},[_vm._v(" 닫기 ")])],1),(_vm.rechargeDialog)?_c('RechargeDialog'):_vm._e(),(_vm.creditCardDialog)?_c('CreditCardDialog'):_vm._e(),(_vm.bankTransferDialog)?_c('BankTransferDialog'):_vm._e(),(_vm.bankInfoDialog)?_c('BankInfoDialog'):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }