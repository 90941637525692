<template>
  <div
    class="d-flex justify-center align-center"
    style="width:100%;flex-direction: column;position:absolute; bottom:30px"
  >
    <v-btn
      class="ma-2 title font-weight-bold"
      dark
      large
      style="width:90%"
      v-for="(bottomMenu, index) in bottomMenus"
      :key="index"
      link
      :to="{ name: bottomMenu.name, params: bottomMenu.params }"
      >{{ bottomMenu.text }}</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      bottomMenus: 'menus/bottomMenus'
    })
  }
};
</script>

<style></style>
