export const ActiveOptions = [
  {
    text: '활성',
    value: 1,
    color: 'green'
  },
  {
    text: '비활성',
    value: 0,
    color: 'red'
  }
];

export const BranchCategories = [
  {
    text: '영화관',
    value: 'cinema'
    // color: 'red'
  }
  // {
  //   text: '편의점',
  //   value: 'C',
  //   color: 'green'
  // }
];

export const AppUpdateStatus = [
  {
    text: '업데이트',
    value: 'Y',
    color: 'blue'
  },
  {
    text: '중지',
    value: 'N',
    color: 'red'
  }
];

export const SettopBoxStatus = [
  {
    text: '재생',
    value: 'Y',
    color: 'blue'
  },
  {
    text: '중지',
    value: 'N',
    color: 'red'
  },
  {
    text: '알수없음',
    value: 'UNKNOWN'
  }
];

export const SettopBoxCableStatus = [
  {
    text: '연결',
    value: 'Y',
    color: 'green'
  },
  {
    text: '미연결',
    value: 'N',
    color: 'orange'
  },
  {
    text: '알수없음',
    value: 'UNKNOWN'
  }
];

export const TimeUnitOptions = [
  {
    text: '초',
    value: 's'
  },
  {
    text: '분',
    value: 'm'
  },
  {
    text: '시간',
    value: 'h'
  }
];

export const CompanyCodes = [
  {
    text: '메가박스',
    value: 'megabox'
  }
  // {
  //   text: '롯데시네마',
  //   value: 'lottecinema'
  // }
];

export const Week = [
  {
    text: '월',
    value: 1
  },
  {
    text: '화',
    value: 2
  },
  {
    text: '수',
    value: 3
  },
  {
    text: '목',
    value: 4
  },
  {
    text: '금',
    value: 5
  },
  {
    text: '토',
    value: 6
  },
  {
    text: '일',
    value: 7
  }
];

export const CouponTypeList = [
  {
    text: '다운로드',
    value: 'download'
  },
  {
    text: '링크이동',
    value: 'url'
  }
];
