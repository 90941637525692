import DidCampaign from '@/views/did/pages/campaign/DidCampaignService.vue';
// import didMember from '@/views/did/pages/member/didMember.vue';

const member = [
  // {
  //   path: '/did/member/:menu',
  //   name: 'didMember',
  //   components: {
  //     auth: didMember
  //   },
  //   meta: {
  //     requiresAuth: true,
  //     requiresAuthLevel: [9, 10]
  //   }
  // }
];

const campaign = [
  {
    path: '/did/campaign',
    name: 'DidCampaign',
    components: {
      auth: DidCampaign
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [9, 10]
    }
  }
];

const routes = [...campaign, ...member];

export default routes;
