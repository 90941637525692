<template>
  <v-dialog
    v-model="dialog.store"
    max-width="1200"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>광고주 등록</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <AdvertiserStoreForm
          :dialog="dialog.store"
          @refresh="$emit('refresh')"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdvertiserStoreForm from '../AdvertiserStoreForm';

export default {
  components: {
    AdvertiserStoreForm,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      dialog: 'advertiser/dialog',
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'advertiser/closeDialog',
    }),
    close() {
      this.closeDialog('store');
    },
  },
};
</script>
