<template>
  <div>
    <a-descriptions :style="{ marginTop: '1rem' }">
      <div slot="title" class="row ma-0">
        <span class="col pa-0">지점 목록</span>
        <a-button class="col-auto" @click="openCreateDialog" type="primary">
          지점 등록
        </a-button>
      </div>
    </a-descriptions>

    <a-table
      :columns="columns"
      :data-source="couponShopList"
      :loading="loading"
      bordered
      :scroll="{ x: true }"
      scrollToFirstRowOnChange
      size="small"
      :pagination="false"
      class="mb-5"
      rowKey="key"
      :locale="{
        emptyText: '등록된 지점이 없습니다.'
      }"
    >
      <span slot="no" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>

      <span slot="biz_hour" slot-scope="text, record">
        <a-tag> {{ record.bizHourStt }} ~ {{ record.bizHourEnd }} </a-tag>
      </span>

      <template slot="actions" slot-scope="value, record">
        <v-tooltip
          v-for="(item, index) in actionBtnList"
          :key="index"
          bottom
          :color="item.color"
        >
          <template #activator="{ on: onTooltip }">
            <v-btn
              small
              icon
              v-on="{ ...onTooltip }"
              @click="item.click(record)"
              :class="`${index > 0 ? 'ml-3' : ''}`"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.label }}</span>
        </v-tooltip>
      </template>
    </a-table>

    <div :style="{ marginBottom: '1rem', textAlign: 'right' }">
      <v-dialog
        v-model="showDialog"
        :confirm-loading="loading"
        @cancel="close"
        :width="650"
        @click:outside="close"
        @keydown.esc="close"
      >
        <v-card v-if="couponShopInfo" class="theme--dark">
          <v-card-title class="py-0 px-3 theme--dark">
            <div class="col text-h6">
              지점 {{ couponShopInfo.id ? '변경' : '등록' }}
            </div>
            <v-btn icon dark depressed @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text
            style="max-height: 75vh; background: #fff; overflow: auto"
          >
            <AdvertiserCouponShopForm :data="couponShopInfo" @close="close" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CouponShop } from '@/model/admin/CouponShop';
import AdvertiserCouponShopForm from './AdvertiserCouponShopForm.vue';
import {
  getCouponShopsApi,
  deleteCouponShopApi
} from '@/api/admin/CouponShopAPI';
export default {
  components: { AdvertiserCouponShopForm },
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    })
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      couponShopInfo: null,
      couponShopList: [],
      columns: [
        {
          title: '번호',
          dataIndex: 'no',
          key: 'no',
          scopedSlots: { customRender: 'no' },
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '지점명',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          ellipsis: true
        },
        {
          title: '지점 주소',
          dataIndex: 'address',
          key: 'address',
          align: 'center',
          ellipsis: true
        },
        {
          title: '연락처',
          dataIndex: 'tel',
          key: 'tel',
          align: 'center',
          ellipsis: true
        },
        {
          title: '영업 시간',
          dataIndex: 'biz_hour',
          key: 'biz_hour',
          scopedSlots: { customRender: 'biz_hour' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: {
            customRender: 'actions'
          },
          align: 'center',
          width: 150
        }
      ],
      actionBtnList: [
        {
          label: '변경',
          icon: 'mdi-pencil',
          color: '',
          click: item => this.openEditDialog(item)
        },
        {
          label: '삭제',
          icon: 'mdi-trash-can-outline',
          color: 'red',
          click: item => this.handleDeleteClick(item)
        }
      ]
    };
  },
  watch: {
    advertiser() {
      this.getCouponShopList();
    }
  },
  mounted() {
    this.getCouponShopList();
  },
  methods: {
    async getCouponShopList() {
      this.loading = true;
      this.couponShopList = [];

      const [error, data] = await getCouponShopsApi(
        this.advertiser.origin.user.id
      );

      if (error) {
        console.error(error);
      } else {
        const { coupon_shops } = data;

        if (coupon_shops && coupon_shops.length > 0) {
          this.couponShopList = coupon_shops.map(couponShop => {
            const newCouponShop = new CouponShop(couponShop);
            newCouponShop.key = newCouponShop.id;

            return newCouponShop;
          });
        }
      }

      this.loading = false;
    },
    async handleDeleteClick(item) {
      this.$Swal
        .fire({
          html: `<h3>${item.name} 지점을 삭제 하시겠습니까?</h3><br><span style='color:#ff7451'>⚠️삭제된 정보는 복구 불가능합니다.</span>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          icon: 'error',
          iconHtml: '!',
          preConfirm: async () => {
            this.loading = true;

            const [error, data] = await deleteCouponShopApi(item.id);

            if (error) {
              const message = error?.data?.message || null;
              this.$Swal.showValidationMessage(message ?? `요청 실패`);
            } else {
              return data;
            }
          }
        })
        .then(result => {
          if (result.isConfirmed) {
            this.close(true);
          }
        });

      this.loading = false;
    },
    openCreateDialog() {
      this.showDialog = true;
      this.couponShopInfo = new CouponShop({
        userId: this.advertiser.origin.user.id
      });
    },
    openEditDialog(item) {
      this.showDialog = true;
      this.couponShopInfo = new CouponShop(item);
    },
    close(refresh) {
      this.showDialog = false;
      this.couponShopInfo = null;

      if (refresh && typeof refresh === 'boolean') {
        this.getCouponShopList();
      }
    }
  }
};
</script>
