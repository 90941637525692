import { DidSchedule } from '@/model/admin/did';
import moment from 'moment';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      dialog: false
    },
    schedule: new DidSchedule(),
    dialog: false,
    filters: {
      campaignName: '',
      dates: [
        moment().subtract(1, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      didBranchIds: [],
      didMediaGroupIds: [],
      settopBoxIds: []
    },
    selectedData: {
      didMediaGroupId: null,
      didBranchId: null,
      didSettopBoxId: null
    }
  },
  getters: {
    schedule(state) {
      return state.schedule;
    },
    dialog(state) {
      return state.dialog;
    },
    loading(state) {
      return state.loading;
    },
    filters(state) {
      return state.filters;
    },
    filterParams(state) {
      return {
        ...state.filters,
        didBranchIds:
          state.filters.didBranchIds.length > 0
            ? state.filters.didBranchIds
            : null,
        didMediaGroupIds:
          state.filters.didMediaGroupIds.length > 0
            ? state.filters.didMediaGroupIds
            : null,
        settopBoxIds:
          state.filters.settopBoxIds.length > 0
            ? state.filters.settopBoxIds
            : null
      };
    },
    selectedData(state) {
      return state.selectedData;
    },
    selectedStep(state) {
      const { didMediaGroupId, didBranchId, didSettopBoxId } =
        state.selectedData;

      if (!didMediaGroupId) return 1;
      if (!didBranchId) return 2;
      if (!didSettopBoxId) return 3;
      return 4;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_Schedule(state, schedule) {
      state.schedule = new DidSchedule(schedule, true);
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_Filters(state) {
      state.filters = {
        dates: [
          moment().subtract(1, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        campaignName: '',
        didBranchIds: [],
        didMediaGroupIds: [],
        settopBoxIds: []
      };
    },
    SET_SelectedData(state) {
      state.selectedData = {
        didMediaGroupId: null,
        didBranchId: null,
        didSettopBoxId: null
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    initSelectedData({ commit }) {
      commit('SET_SelectedData');
    },
    async openDialog({ commit }, schedule = undefined) {
      commit('SET_Schedule', new DidSchedule());

      if (schedule) {
        commit('SET_Schedule', schedule);
      }

      commit('SET_Dialog', true);
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
    }
  }
};
