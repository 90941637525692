<template>
  <div class="py-5">
    <v-btn class="mr-4" @click="back()" v-if="currentStep !== 1" large>
      이전
    </v-btn>
    <campaign-review v-if="Steps.length === currentStep" :invalid="invalid" />
    <v-btn
      v-else
      color="secondary"
      class="mr-4 text--white"
      @click="nextStep()"
      :disabled="invalid"
      large
    >
      다음
    </v-btn>
    <!-- <v-btn class="mr-4 float-right" :disabled="invalid" @click="save()">
      저장
    </v-btn> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Steps } from '@/config/admin/campaign';

import CampaignReview from './CampaignReview.vue';
export default {
  components: {
    CampaignReview
  },
  props: ['invalid'],
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      currentStep: 'campaign/currentStep'
    }),
    Steps() {
      return Steps;
    }
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'campaign/setCurrentStep'
    }),
    save() {},
    back() {
      const step = this.currentStep;
      const { rs1 } = this.campaign;
      if (step === 5 && rs1.campaignType === 'message') {
        this.setCurrentStep(step - 2);
      } else {
        this.setCurrentStep(step - 1);
      }
    },
    nextStep() {
      const step = this.currentStep;
      const { rs1 } = this.campaign;
      if (step === 3 && rs1.campaignType === 'message') {
        this.setCurrentStep(step + 2);
      } else {
        this.setCurrentStep(step + 1);
      }
    }
  }
};
</script>

<style></style>
