export const UserRoleList = [
  {
    text: '전체',
    value: 'ALL'
  },
  {
    text: '매체',
    value: 'media'
  },
  {
    text: '매체그룹',
    value: 'mediaGroup'
  },
  {
    text: '광고주',
    value: 'advertiser'
  },
  {
    text: '캐시노트',
    value: 'cashnote'
  }
];

export const UserApproveStatusList = [
  {
    text: '전체',
    value: ''
  },
  {
    text: '승인',
    value: 'Y',
    color: 'green'
  },
  {
    text: '대기',
    value: 'W',
    color: 'orange'
  },
  {
    text: '거절',
    value: 'N',
    color: 'red'
  },
  {
    text: '중단',
    value: 'S',
    color: 'purple'
  }
];

export const MediaGroupTypeList = [
  {
    text: '전체',
    value: '',
    color: ''
  },
  {
    text: '직매체',
    value: 'D',
    color: 'blue'
  },
  {
    text: '대행사',
    value: 'A',
    color: 'purple'
  }
];

export const AdvertiserTypeList = [
  {
    text: '내부 생성 광고주',
    value: 'test'
  },
  {
    text: '외부 생성 광고주',
    value: 'console'
  },
  {
    text: '캐시노트 광고주',
    value: 'cashnote'
  },
  {
    text: '노드 광고주',
    value: 'node'
  }
];
