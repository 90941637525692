export default {
    namespaced: true,
    state: {
        advertiserGroup: null,
        titles: {
            name: '광고 그룹 명',
            icon: '아이콘 이미지를 업로드하세요(선택사항)',
            advertiser: '광고주'
        },
        dialog: {
            register: false,
            edit: false,
        },
    },
    getters: {
        advertiserGroup(state) {
            return state.advertiserGroup
        },
        titles(state) {
            return state.titles
        },
        label(state) {
            return state.label
        },
        dialog(state) {
            return state.dialog
        }
    },
    mutations: {
        SET_AdvertiserGroup(state, advertiserGroup) {
            state.advertiserGroup = advertiserGroup
        },
        SET_Dialog(state, dialog) {
            state.dialog = dialog
        }
    },
    actions: {
        setAdvertiserGroup({
            commit
        }, advertiserGroup) {
            commit('SET_AdvertiserGroup', advertiserGroup)
        },
        initAdvertiserGroup({
            commit,
            dispatch
        }) {
            const advertiserGroup = {
                id: null,
                name: null,
                advertiser: null,
                iconId: null,
                iconUrl: null,
                iconType: null,
            };
            commit('SET_AdvertiserGroup', advertiserGroup);
            dispatch('initDialog');

        },
        initDialog({
            commit
        }) {
            const dialog = {
                register: false,
                edit: false
            }

            commit('SET_Dialog', dialog);
        },
        hashingAdvertiserGroup({
            dispatch
        }, advertiserGroup) {
            const newAdvertiserGroup = {
                id: advertiserGroup.id,
                name: advertiserGroup.name,
                advertiser: advertiserGroup.advertiser.id,
                iconId: advertiserGroup?.icon?.id || null,
                iconUrl: advertiserGroup?.icon?.url || null,
                iconType: advertiserGroup?.icon?.type || null,
            };
            dispatch('setAdvertiserGroup', newAdvertiserGroup);
        }
    }
};