<template>
  <v-row>
    <v-col>
      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        max-width="800"
        scrollable
      >
        <v-card>
          <v-card-text>
            <campaign-preview :rs1="rs1" :rs2="rs2" :showLandingUrl="true" />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="primary" text @click="exit()">닫기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CampaignPreview from '../../../campaign/components/register/CampaignPreview.vue';
export default {
  components: { CampaignPreview },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign'
    }),
    rs1() {
      return this.campaign?.rs1 || null;
    },

    rs2() {
      return this.campaign?.rs2 || null;
    },

    dialog: {
      get() {
        return this.openDialog;
      },
      set() {
        this.exit();
      }
    }
  },
  methods: {
    ...mapActions({
      initCampaign: 'campaign/initCampaign'
    }),
    exit() {
      this.initCampaign();
      this.$emit('closeDialog', true);
    }
  }
};
</script>

<style></style>
