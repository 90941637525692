import { getCamelKeys } from '@/utils/functions';

export class AdvertiserOption {
  id = null;
  name = null;
  userId = null;

  constructor(init) {
    if (!init) return;
    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      this[key] = val;
    }
  }
}
