import PartnerReport from '@/views/partner/pages/report/PartnerReport.vue';
import PartnerMember from '@/views/partner/pages/member/PartnerMember.vue';

const partnerMember = [
  {
    path: '/partner/member/:menu',
    name: 'PartnerMember',
    components: {
      auth: PartnerMember
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [8]
    }
  }
];

const advertiserReport = [
  {
    path: '/partner/report',
    name: 'PartnerReport',
    components: {
      auth: PartnerReport
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [8]
    }
  }
];

const routes = [...advertiserReport, ...partnerMember];

export default routes;
