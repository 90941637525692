import axios from 'axios';

export async function getMediaLiveRawApi(params) {
  try {
    const { data } = await axios.get(`/admin/report-media/reach/raw`, {
      params: params
    });
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getTypeReportStatsRawApi(params) {
  try {
    const { data } = await axios.get(`/admin/report-stats/type/raw`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsTypeAPI(params) {
  try {
    const { data } = await axios.get(`/admin/report-stats/type`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsCouponAPI(params) {
  try {
    const { data } = await axios.get(`/admin/report-stats/coupon`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsTypeChartAPI(params) {
  try {
    const { data } = await axios.get(`/admin/report-stats-chart/type`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsCouponChartAPI(params) {
  try {
    const { data } = await axios.get(
      `/admin/report-stats-chart/coupon`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsMegaboxCouponChartAPI(params) {
  try {
    const { data } = await axios.get(
      `/admin/report-stats-chart/megabox-coupon`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getReportStatsCouponRawApi(params) {
  try {
    const { data } = await axios.get(`/admin/report-stats/coupon/raw`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
// 매체그룹 콤보 목록
// return [{ id: '', name :''} ...]
export async function getMediaGroupsComboAPI() {
  try {
    const { data } = await axios.get(`/admin/report-media-groups`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
// 매체 콤보 목록
// return [{ id: '', name :''} ...]
export async function getMediasComboAPI(params) {
  try {
    const { data } = await axios.get(`/admin/report-medias`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// export async function getReportStatsMegaboxCouponRawAPI(params) {
//   try {
//     const { data } = await axios.get(
//       `/admin/report-stats/megabox-coupon/raw`,
//       params
//     );
//     return [null, data];
//   } catch (error) {
//     return [error];
//   }
// }
