<template>
  <div>
    <div :style="{ marginBottom: '1rem', textAlign: 'right' }">
      <a-button
        type="primary"
        :style="{ marginRight: '10px' }"
        @click="editDialog = true"
      >
        매체그룹 정보 변경
      </a-button>

      <v-dialog
        v-model="editDialog"
        :confirm-loading="loading"
        @cancel="close"
        :width="650"
        @click:outside="close"
        @keydown.esc="close"
      >
        <v-card>
          <v-toolbar flat dark>
            <v-toolbar-title>매체그룹 정보 변경</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text class="mt-5 filters">
            <a-alert
              message="비밀번호는 4자리 이상 입력해주세요."
              type="info"
              show-icon
              class="mb-5"
            />

            <a-spin :spinning="loading">
              <a-form-model ref="updateForm" :model="form" :rules="rules">
                <a-descriptions bordered>
                  <a-descriptions-item label="계정활성 여부" :span="3">
                    <a-switch v-model="approve" />
                  </a-descriptions-item>

                  <a-descriptions-item label="매체그룹 유형" :span="3">
                    <a-select
                      :style="{ minWidth: '150px' }"
                      v-model="form.type"
                    >
                      <a-select-option
                        v-for="(item, index) in mediaGroupTypeList"
                        :key="index"
                        :value="item.value"
                      >
                        <a-tag :color="item.color"> {{ item.text }} </a-tag>
                      </a-select-option>
                    </a-select>
                  </a-descriptions-item>

                  <a-descriptions-item label="변경할 비밀번호" :span="3">
                    <a-form-model-item ref="password" prop="password">
                      <a-input
                        v-model="form.password"
                        type="password"
                        placeholder="미 입력시 변경되지 않습니다."
                      />
                    </a-form-model-item>
                  </a-descriptions-item>
                </a-descriptions>

                <v-row>
                  <v-col cols="12" class="text-center mt-5 mb-2">
                    <v-btn
                      color="#00d1b2"
                      class="white--text"
                      depressed
                      @click="update"
                      :loading="loading"
                    >
                      변경
                    </v-btn>
                  </v-col>
                </v-row>
              </a-form-model>
            </a-spin>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <a-descriptions title="계정정보" bordered class="mb-8">
      <a-descriptions-item label="계정 활성 여부" :span="2">
        <a-tag :color="record.approve === 'Y' ? 'green' : 'red'">
          {{ record.approve === 'Y' ? '활성' : '비활성' }}
        </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="등록일">
        {{ createdAt }}
      </a-descriptions-item>

      <a-descriptions-item label="ID" :span="2">
        {{ record.uid }}
      </a-descriptions-item>

      <a-descriptions-item label="이메일">
        {{ record.email }}
      </a-descriptions-item>

      <a-descriptions-item label="연락처" :span="2">
        {{ record.phone }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="매체그룹 정보" bordered class="mb-8">
      <a-descriptions-item label="광고 송출 상태">
        <a-tag :color="record.active.color"> {{ record.active.text }} </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="매체그룹 유형" :span="2">
        <a-tag small :color="record.type.color">
          {{ record.type.text }}
        </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="매체그룹명">
        {{ record.name }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      record: 'mediaGroup/record',
    }),
    createdAt() {
      return this.$moment(this.record.createdAt).format('YYYY년 MM월 DD일');
    },
  },
  data() {
    return {
      editDialog: false,
      loading: false,
      rules: {
        password: [{ min: 4, message: '4자 이상 입력해주세요.' }],
      },
      form: {
        type: '',
        password: '',
      },
      approve: false,
      mediaGroupTypeList: [
        {
          text: '직매체',
          value: 'D',
          color: 'blue',
        },
        {
          text: '대행사',
          value: 'A',
          color: 'purple',
        },
      ],
    };
  },
  watch: {
    record(v) {
      this.form.type = v.type.value;
    },
    editDialog() {
      this.approve = this.record.approve === 'Y';
    },
  },
  mounted() {
    this.form.type = this.record.type.value;
  },
  methods: {
    close() {
      this.form.password = '';

      if (this.$refs.updateForm) {
        this.$refs.updateForm.resetFields();
      }

      this.editDialog = false;
    },
    update() {
      this.$refs.updateForm.validate(valid => {
        if (valid) {
          this.loading = true;

          let approve = this.approve ? 'Y' : 'W';

          this.$axios
            .put('/admin/media-groups', {
              mediaGroupId: this.record.id,
              password: this.form.password,
              approve: approve,
              type: this.form.type,
            })
            .then(res => {
              if (res.data.success) {
                this.$notification['success']({
                  message: '변경 성공',
                  description: '정보가 변경 되었습니다.',
                });

                this.record.approve = approve;
                this.record.type.value = this.form.type;

                this.$emit('refresh');
                this.close();
              }

              this.loading = false;
            })
            .catch(err => {
              console.dir(err);
              this.loading = false;
              this.$axiosErrorAlert();
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
