import axios from 'axios';

export async function getPointOrdersApi(params) {
  try {
    const { data } = await axios.get(`/admin/point-orders`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function confirmBankTransferAPI(orderNo) {
  try {
    const { data } = await axios.patch(
      `/admin/point-order/${orderNo}/bank-transfer/confirm`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createPointOrderCommentApi(params, orderNo) {
  try {
    const { data } = await axios.post(
      `/admin/point-order/${orderNo}/comment`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPointOrderCommentsApi(orderNo) {
  try {
    const { data } = await axios.get(`/admin/point-order/${orderNo}/comments`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function deletePointOrderCommentApi(commentId) {
  try {
    const { data } = await axios.delete(
      `/admin/point-order-comment/${commentId}`
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function cancelPointOrderAPI(orderNo, params) {
  try {
    const { data } = await axios.put(
      `/admin/point-order/${orderNo}/cancel`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function deletePointOrderAPI(orderNo) {
  try {
    const { data } = await axios.delete(`/admin/point-order/${orderNo}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// 결제 취소 내역 조회
export async function getPaymentCancelsApi(orderNo) {
  try {
    const { data } = await axios.get(`/admin/point-order/${orderNo}/cancels`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
// 결제 부분취소 요청
export async function cancelPartialPointOrderAPI(orderNo, params) {
  try {
    const { data } = await axios.put(
      `/admin/point-order/${orderNo}/cancel-partial`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
