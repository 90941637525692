import axios from 'axios';

export async function getCampaignsApi(params) {
  try {
    const { data } = await axios.get(`/admin/campaigns-media`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function approveApi(campaignId) {
  try {
    const params = {
      status: 'Y'
    };

    const { data } = await axios.patch(
      `/admin/campaigns/${campaignId}/approve`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function stopApi(campaignId) {
  try {
    const params = {
      status: 'S'
    };

    const { data } = await axios.patch(
      `/admin/campaigns/${campaignId}/approve`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createCampaignCouponAPI(params) {
  try {
    const { data } = await axios.post(`/admin/campaign/coupon`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateCampaignCouponAPI(params, campaignId) {
  try {
    const { data } = await axios.put(
      `/admin/campaign/coupon/${campaignId}`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateCampaignActiveStatusAPI(params) {
  try {
    const { data } = await axios.put(`/admin/campaigns-active`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getCampaignDetailAPI(campaignId) {
  try {
    const { data } = await axios.get(`/admin/campaign/${campaignId}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getCampaignAdvertiserAPI(advertiserId) {
  try {
    const { data } = await axios.get(
      `/admin/campaigns-advertiser-groups/${advertiserId}`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: 쿠폰 카테고리 목록 조회
 */
export async function getCouponCategoriesAPI(params = undefined) {
  try {
    const { data } = await axios.get(
      `/admin/campaign-coupon-categories`,
      params
    );
    // const data = { coupon_categories: [], total_count: 0 };
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: 쿠폰 카테고리 등록
 */
export async function createCouponCategoryAPI(params) {
  try {
    const { data } = await axios.post(
      `/admin/campaign-coupon-category`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: 쿠폰 카테고리 수정
 */
export async function updateCouponCategoryAPI(categoryId, params) {
  try {
    const { data } = await axios.post(
      `/admin/campaign-coupon-category/${categoryId}`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: 쿠폰 카테고리 상태 활성화
 */
export async function approveCouponCategoryApi(categoryId) {
  try {
    const { data } = await axios.patch(
      `/admin/campaign-coupon-category/${categoryId}/approve`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: 쿠폰 카테고리 상태 비활성화
 */
export async function denyCouponCategoryApi(categoryId) {
  try {
    const { data } = await axios.patch(
      `/admin/campaign-coupon-category/${categoryId}/approve-deny`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
