<template>
  <v-container fluid>
    <v-row>
      <v-col cols="11" class="mx-auto">
        <v-row>
          <v-col>
            <CampaignContentHead />
          </v-col>
        </v-row>

        <v-row class="mt-5 px-3">
          <v-col>
            <v-row v-if="apiLoading" class="white">
              <v-col :cols="12" md="6">
                <v-skeleton-loader class="mb-6" type="article" />
                <v-skeleton-loader class="mb-6" type="list-item-three-line" />
                <v-skeleton-loader class="mb-6" type="list-item-three-line" />
                <v-skeleton-loader type="actions" />
              </v-col>
            </v-row>
            <form v-else @submit.prevent="submit">
              <transition name="fade">
                <!-- <campaign-admin :user.sync="campaign.user" /> -->
              </transition>

              <v-row v-if="currentStep === 1">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <CampaignStep1 />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 2">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <CampaignStep2 />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 3">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <CampaignStep3 />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 4">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <CampaignStep4 />
                  </transition>
                </v-col>
              </v-row>
              <v-row v-if="currentStep === 5">
                <v-col>
                  <transition name="component-fade" mode="out-in">
                    <CampaignStep5 />
                  </transition>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CampaignStep1 from './CampaignStep1.vue';
import CampaignStep2 from './CampaignStep2.vue';
import CampaignStep3 from './CampaignStep3.vue';
import CampaignStep4 from './CampaignStep4.vue';
import CampaignStep5 from './CampaignStep5.vue';
import CampaignContentHead from './CampaignContentHead.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CampaignStep1,
    CampaignStep2,
    CampaignStep3,
    CampaignStep4,
    CampaignStep5,
    CampaignContentHead
  },

  computed: {
    ...mapGetters({
      currentStep: 'campaign/currentStep',
      campaign: 'campaign/campaign',
      apiLoading: 'campaign/apiLoading'
    })
  },

  methods: {
    ...mapActions({
      setCampaign: 'campaign/setCampaign'
    }),
    submit() {
      this.$validator.validateAll();
    }
  }
};
</script>
<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.7s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
