<template>
  <v-dialog v-model="dialog" max-width="600" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>CRM 목록</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-0">
        <v-list
          class="mt-3 pa-0"
          style="
            height: 40vh;
            border: 1px solid #999;
            border-radius: 5px;
            max-height: 500px;
            overflow: auto;
          "
        >
          <v-list-item-group v-model="selected" active-class="pink--text">
            <template v-for="(notification, index) in notifications">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold d-flex align-center"
                  >
                    {{ notification.title }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="pt-2">
                    {{ notification.body }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="index < notifications.length - 1"
                :key="`divider${index}`"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center ma-0">
        <v-btn
          color="secondary"
          @click="handleSelectItem"
          :loading="loading"
          :disabled="selected === null"
        >
          적용
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import { getNotificationsApi } from '@/api/admin/NotificationsAPI';

export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({})
  },
  watch: {
    dialog() {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      notifications: [],
      selected: null
    };
  },
  mounted() {
    this.fetchNotifications();
  },
  methods: {
    // notification 목록 조회
    async fetchNotifications() {
      this.loading = true;

      const params = {
        params: {
          pagination: 'N'
        }
      };

      const [error, data] = await getNotificationsApi(params);

      if (error) {
        console.error(error);
      } else {
        const { notifications } = data;
        this.notifications = notifications;
      }
      this.loading = false;
    },
    async handleSelectItem() {
      if (this.selected !== null) {
        this.$emit('select', this.notifications[this.selected]);
      }
      this.$emit('close');
    },
    async handleCloseClick() {
      this.$emit('close');
    }
  }
};
</script>

<style></style>
