import axios from 'axios';

export async function getInquiryApi(params) {
  try {
    const { data } = await axios.get('/admin/inquiry', params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function setReplyInquiryApi(params) {
  try {
    const { data } = await axios.put('/admin/inquiry-reply', params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPromotionInquiriesApi(params) {
  try {
    const { data } = await axios.get('/admin/promotion-inquiries', params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPromotionInquiryApi(id) {
  try {
    const { data } = await axios.get(`/admin/promotion-inquiry/${id}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// 동기화
export async function setPromotionAdvertiserSyncApi(params) {
  try {
    const { data } = await axios.put(`/admin/promotion-inquiry/sync`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// 참여
export async function setPromotionStatusParticipateApi(id) {
  try {
    const { data } = await axios.patch(
      `/admin/promotion-inquiry/${id}/status/participate`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
// 미참여
export async function setPromotionStatusNonParticipateApi(id) {
  try {
    const { data } = await axios.patch(
      `admin/promotion-inquiry/${id}/status/non-participate`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// 대기
export async function setPromotionStatusHoldApi(id) {
  try {
    const { data } = await axios.patch(
      `/admin/promotion-inquiry/${id}/status/hold`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
// 코멘트 등록
export async function setPromotionReplyApi(id, params) {
  try {
    const { data } = await axios.put(
      `/admin/promotion-inquiry/${id}/reply`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}
