import axios from 'axios';

/**
 * @description: DID 셋톱박스 목록 전체 조회
 * */
export async function getDidSettopBoxesApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/settop-boxes`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 셋톱박스 목록 전체 조회
 * */
export async function getDidSettopBoxApi(settopBoxId) {
  try {
    const { data } = await axios.get(`/admin/did/settop-box/${settopBoxId}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 셋톱박스 등록
 * */
export async function createDidSettopBoxApi(params) {
  try {
    const { data } = await axios.post(`/admin/did/settop-box`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 셋톱박스 수정
 * */
export async function updateDidSettopBoxApi(params, settopBoxId) {
  try {
    const { data } = await axios.put(
      `/admin/did/settop-box/${settopBoxId}`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 셋톱박스 설정 상태 변경 - active
 * @param settop-boxId: string
 * */
export async function updateDidSettopBoxActiveApi(settopBoxId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/settop-box/${settopBoxId}/active`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 셋톱박스 설정 상태 변경 - inactive
 * @param settop-boxId: string
 * */
export async function updateDidSettopBoxInactiveApi(settopBoxId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/settop-box/${settopBoxId}/inactive`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 셋톱박스 옵션 목록 전체 조회
 * */
export async function getDidSettopBoxOptionsApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/settop-boxes-combo`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 셋톱박스 리부팅
 * */
export async function Api(params) {
  try {
    const { data } = await axios.get(`/admin/did/settop-boxes-combo`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
