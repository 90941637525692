import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import { SettopBoxStatus } from '@/config/admin/campaign-did';

export class DidSettopBoxConfig {
  origin = null;
  id = null; // config id
  settopBoxId = '';
  status = 'N'; // 설정 상태 (Y, N)
  configInterval = 0; // 설정 통신 주기
  configIntervalUnit = 's'; // 설정 통신 주기 단위
  campaignInterval = 0; // 캠페인 통신 주기
  campaignIntervalUnit = 's'; // 캠페인 통신 주기 단위
  logInterval = 0; // 로그 통신 주기
  logIntervalUnit = 's'; // 로그 통신 주기 단위
  wifircnInterval = 0; // 와이파이 재접속 주기 단위
  wifircnIntervalUnit = 's'; // 와이파이 재접속 주기 단위
  rebootTime = '02:00'; // 재부팅 설정 시간

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidSettopBoxConfig(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (['rebootTime'].includes(key)) {
        const rebootTimeSplit = val ? val.split(':') : ['00', '00', '00'];
        val = `${rebootTimeSplit[0]}:${rebootTimeSplit[1]}`;
      }
      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidSettopBoxConfig(this);
    }
  }

  //  상태 정보
  get statusInfo() {
    return (
      SettopBoxStatus.find(status => status.value === this.status) ??
      SettopBoxStatus[SettopBoxStatus.length - 1]
    );
  }

  //  재부팅 시간 포맷
  get getRebootTimeFormat() {
    const rebootTimeSplit = this.rebootTime
      ? this.rebootTime.split(':')
      : ['00', '00', '00'];
    return `${rebootTimeSplit[0]}:${rebootTimeSplit[1]}:00`;
  }

  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.getUpdatePayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.id
      ? this.origin.getUpdatePayload(true)
      : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  /** *************************
   * 등록 payload
   ************************* **/
  getCreatePayload() {
    return {
      name: this.name,
      configInterval: Number(this.configInterval),
      configIntervalUnit: this.configIntervalUnit,
      campaignInterval: Number(this.campaignInterval),
      campaignIntervalUnit: this.campaignIntervalUnit,
      logInterval: Number(this.logInterval),
      logIntervalUnit: this.logIntervalUnit,
      wifircnInterval: Number(this.wifircnInterval),
      wifircnIntervalUnit: this.wifircnIntervalUnit,
      rebootTime: this.getRebootTimeFormat
    };
  }

  /** *************************
   * 수정 payload
   ************************* **/
  getUpdatePayload() {
    // let changedValueKeys = [];
    return {
      id: this.id,
      name: this.name,
      settopBoxId: this.settopBoxId,
      configInterval: Number(this.configInterval),
      configIntervalUnit: this.configIntervalUnit,
      campaignInterval: Number(this.campaignInterval),
      campaignIntervalUnit: this.campaignIntervalUnit,
      logInterval: Number(this.logInterval),
      logIntervalUnit: this.logIntervalUnit,
      wifircnInterval: Number(this.wifircnInterval),
      wifircnIntervalUnit: this.wifircnIntervalUnit,
      rebootTime: this.getRebootTimeFormat
    };
    // // 값 체크 용도
    // if (isChecked) return res;

    // changedValueKeys = Object.keys(res);
    // const changedValues = this.changedValues(changedValueKeys, res);

    // return {
    //   ...res,
    //   changedValues: changedValues
    // };
  }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = `${changedObj[key]}` === `${val}`;

      if (typeof changedObj[key] === 'object' || typeof val === 'object') {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
