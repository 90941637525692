import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import * as am4core from '@amcharts/amcharts4/core';
// import * as am4charts from '@amcharts/amcharts4/charts';
import * as echarts from 'echarts';
// import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// am4core.useTheme(am4themes_animated);
import './utils/filter';
import './utils/vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import 'sweetalert2/dist/sweetalert2.min.css';
// SweetAlert2 테마
import '@sweetalert2/theme-bulma/bulma.css';
import firebaseMessaging from './utils/firebase'; // firebase
import { getAttributes } from './utils/tools';

require('./store/subscriber');

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Service-Client'] = 'console';

const { VUE_APP_AXIOS_XDEBUG } = process.env;
if (VUE_APP_AXIOS_XDEBUG ? JSON.parse(VUE_APP_AXIOS_XDEBUG) : false) {
  axios.interceptors.request.use(config => {
    config.params = {
      ...config.params,
      XDEBUG_SESSION_START: 'PHPSTORM'
    };
    return config;
  });
}

axios.interceptors.response.use(
  response => {
    initRefreshTime();
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          store.commit('auth/SET_TOKEN', null);
          store.commit('auth/SET_USER', null);
          router.replace('/login');
          break;
        case 403:
          break;
        case 404:
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

// A better way to use JavaScript Library in Vue
// momentjs 프로토타입 정의
Object.defineProperty(Vue.prototype, '$moment', {
  value: moment
});
// axios 프로토타입 정의
Object.defineProperty(Vue.prototype, '$axios', {
  value: axios
});

// sweetalert2 프로토타입 정의
Object.defineProperty(Vue.prototype, '$Swal', {
  value: Swal
});

// am charts 프로토타입 정의
// Object.defineProperty(Vue.prototype, '$am4core', {
//   value: am4core
// })
// Object.defineProperty(Vue.prototype, '$am4charts', {
//   value: am4charts
// })

// echarts 프로토타입 정의
Object.defineProperty(Vue.prototype, '$echarts', {
  value: echarts
});

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;
Object.defineProperty(Vue.prototype, '$isMobile', {
  value: isMobile
});

Vue.prototype.$axiosErrorAlert = (message = null) => {
  Swal.fire({
    icon: 'error',
    html: message ? message : '<h3>잘못된 요청입니다.</h3>',
    confirmButtonText: '확인'
  });
};

Object.defineProperty(Vue.prototype, '$mediaHour', {
  value: 11
});

// kakao map api
Vue.prototype.$kakao = window.kakao;

// kakao address api
Vue.prototype.$daum = window.daum;

// Main logo path
Vue.prototype.$mainLogoPath = require('@/assets/main-logo.png');

// momentjs locale 설정
moment.locale('ko');

Vue.config.productionTip = false;

Vue.use(Antd);

Vue.prototype.$fcmMessage = firebaseMessaging;
Vue.prototype.$getAttributes = getAttributes;

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});

// 화면 새로고침 알림 설정
const threeHourMinutes = 180;
let refreshTime = threeHourMinutes;
const initRefreshTime = () => {
  refreshTime = threeHourMinutes;
};

setInterval(async () => {
  refreshTime = refreshTime - 5;
  if (refreshTime < 0) {
    await Swal.fire({
      icon: 'info',
      html: '현재 화면에서 오랫동안 머무르고 있었습니다.<br>화면을 새로고침 해주세요.',
      confirmButtonText: '확인'
    });
    window.location.reload(true);
  }
}, 300000);
