export default {
    namespaced: true,

    state: {
        userIFrameDialog: false,

    },
    getters: {
        userIFrameDialog(state) {
            return state.userIFrameDialog
        },
    },
    mutations: {
        SET_userIFrameDialog(state, value) {
            state.userIFrameDialog = value
        },
    },
    actions: {
        closeUserIFrameDialog({
            commit
        }) {
            commit('SET_userIFrameDialog', false);
        },
        openUserIFrameDialog({
            commit
        }) {
            commit('SET_userIFrameDialog', true)
        },
    }
}