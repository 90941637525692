import {
    Tag
} from '@/model/admin/Tag'

export default {
    namespaced: true,

    state: {
        tag: new Tag(),
        tags: [],
        placeholder: {
            name: null,
        },
        types: [{
                text: '대분류',
                value: 1
            },
            {
                text: '중분류',
                value: 2
            },
            {
                text: '캠페인 형태',
                value: 3
            },
            {
                text: '캠페인 명',
                value: 4
            },
            {
                text: '광고 방식',
                value: 5
            },
        ],
        label: {
            name: '태그명',
        },
        dialog: {
            register: false,
            edit: false,
        },
    },
    getters: {
        tag(state) {
            return state.tag
        },
        tags(state) {
            return state.tags
        },
        placeholder(state) {
            return state.placeholder
        },
        label(state) {
            return state.label
        },
        dialog(state) {
            return state.dialog
        },
        types(state) {
            return state.types
        }
    },
    mutations: {
        SET_Tag(state, tag) {
            state.tag = tag
        },
        SET_Tags(state, tags) {
            state.tags = tags
        },
        SET_Dialog(state, dialog) {
            state.dialog = dialog
        },
        ADD_Tag(state, tag) {
            state.tags.push(tag);
        },
        DELETE_Tag(state, tagIndex) {
            state.tags.splice(tagIndex, 1)
        }
    },
    actions: {
        setTag({
            commit
        }, tag) {
            commit('SET_Tag', tag)
        },
        setTags({
            commit
        }, tags) {
            commit('SET_Tags', tags)
        },
        initTag({
            commit,
            dispatch
        }) {
            const tag = new Tag();
            commit('SET_Tag', tag);
            dispatch('initDialog');

        },
        initTags({
            commit,
            dispatch
        }) {
            const tags = [new Tag()];
            commit('SET_Tags', tags);
            dispatch('initDialog');

        },
        initDialog({
            commit
        }) {
            const dialog = {
                register: false,
                edit: false
            }

            commit('SET_Dialog', dialog);
        },
        hashingTag({
            dispatch
        }, tag) {

            const hashedTags = [new Tag(
                tag.id,
                tag.name,
                tag.tag_type_id
            )];
            dispatch('setTags', hashedTags);
        },
        addTag({
            commit
        }) {
            const tag = new Tag();
            commit('ADD_Tag', tag)
        },
        deleteTag({
            commit
        }, tagIndex) {
            commit('DELETE_Tag', tagIndex)
        }
    }
};