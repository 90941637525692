<template>
  <v-container fluid>
    <!-- <v-breadcrumbs :items="breadcrumbs" /> -->
    <!-- <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="searchString"
          label="매체사 검색"
          placeholder=""
          outlined
        ></v-text-field>
      </v-col>

      <v-col class="text-center text-md-right">
        <date-picker @setDateRanges="v => (dateRanges = v)"></date-picker>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn dark @click="handleRegitserClick()">
              네트워크 등록
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet class="pa-3">
          <Table
            :columns="columns"
            :data-source="networks"
            bordered
            size="small"
            :pagination="{
              defaultPageSize: 10,
              position: 'bottom',
              size: 'large',
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100']
            }"
            :customRow="
              record => {
                return {
                  on: {
                    mouseenter: event => {
                      hoverItem = record.id;
                    },
                    mouseleave: event => {
                      hoverItem = null;
                    }
                  }
                };
              }
            "
          >
            <template slot="isActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isActive"
                  color="info"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click="handleActiveClick(record)"
                ></v-switch>
              </div>
            </template>
            <template slot="name" slot-scope="value, record">
              <div
                class="d-inline-flex align-center"
                style="min-height:30px !important"
              >
                <span>{{ value }}</span>
                <div style="min-width:40px;">
                  <v-tooltip
                    v-if="hoverItem === record.id"
                    bottom
                    color="rgba(0,0,0,1)"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        class="ml-2"
                        v-on="{ ...onTooltip }"
                        icon
                        small
                        @click="handleEditClick(record.origin)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>수정</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template slot="money" slot-scope="text">
              {{ text | KRW }}
            </template>
            <template slot="mediaGroups" slot-scope="mediaGroups">
              <v-menu
                open-on-hover
                open-delay="100"
                close-delay="100"
                bottom
                offset-y
                nudge-left=""
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-0 font-weight-bold"
                    label
                    color="secondary"
                    small
                  >
                    <span
                      >{{ mediaGroups[0].name }}
                      {{
                        mediaGroups.length > 1
                          ? `외 ${mediaGroups.length - 1}개`
                          : ''
                      }}</span
                    >
                  </v-chip>
                </template>
                <v-card
                  width="300"
                  min-height="150"
                  max-height="300"
                  class="overflow-y-auto"
                >
                  <v-card-title>
                    매체그룹
                  </v-card-title>
                  <v-card-text>
                    <v-chip
                      v-for="(mediaGroup, index) in mediaGroups"
                      :key="index"
                      class="ma-1"
                      label
                      outlined
                      color="secondary"
                    >
                      <span>
                        {{ mediaGroup.name }}
                      </span>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </Table>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
        ></v-pagination>
      </v-col>
    </v-row> -->
    <NetworkDialog v-if="networkDialog" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import NetworkDialog from './components/NetworkDialog.vue';
import {
  getNetworksApi,
  updateNetworkActiveApi
} from '@/api/admin/partners/NetworkAPI';

import { NetworkTypeList } from '@/config/admin/network';

import { Table } from 'ant-design-vue';

export default {
  components: {
    // DatePicker
    MenuTitle,
    NetworkDialog,
    Table
  },
  data() {
    return {
      networks: [],
      columns: [
        {
          title: '번호',
          dataIndex: 'id',
          width: 80,
          key: 'id'
        },

        {
          title: '상태',
          dataIndex: 'isActive',
          key: 'isActive',
          width: 80,
          scopedSlots: {
            customRender: 'isActive'
          }
        },
        {
          title: '네트워크 유형',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: '네트워크 별명',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '네트워크 UID',
          dataIndex: 'uid',
          key: 'uid'
        },

        {
          title: '입찰 단가',
          dataIndex: 'impBidFloor',
          key: 'impBidFloor',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          }
        },
        {
          title: '매체',
          dataIndex: 'mediaName',
          customOptions: { group: 'setting' }
        }
      ],
      mediaDialog: false,
      dateRanges: null,
      searchString: null,
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      headers: [],
      loading: true,
      hoverItem: null
    };
  },
  computed: {
    ...mapGetters({
      networkDialog: 'network/dialog'
    })
  },
  created() {
    this.loadNetwork();
  },
  methods: {
    ...mapActions({
      openDialog: 'network/openDialog',
      setNetwork: 'network/setNetwork',
      initNetwork: 'network/initNetwork',
      setDialogTypeRegister: 'network/setDialogTypeRegister',
      setDialogTypeEdit: 'network/setDialogTypeEdit'
    }),
    async loadNetwork() {
      this.loading = true;
      const [error, data] = await getNetworksApi();
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const { networks } = data;
        this.networks = networks.map(item => {
          return {
            origin: item,
            key: item.id,
            id: item.id,
            type:
              NetworkTypeList.find(v => v.value === item.type)?.text || 'N/A',
            uid: item.uid,
            name: item.name,
            isActive: item.is_active,
            impBidFloor: item.imp_bid_floor,
            mediaId: item.media_id,
            mediaName: item.media_name
          };
        });
      }
      this.loading = false;
    },
    handleRegitserClick() {
      this.openDialog();
      this.setDialogTypeRegister();
      this.initNetwork();
    },
    handleEditClick(network) {
      this.openDialog();
      this.setDialogTypeEdit();
      this.setNetwork(JSON.parse(JSON.stringify(network)));
    },
    handleActiveClick(item) {
      const html = `<h3>${item.id}번 ${item.name} <br> 네트워크를 ${
        !item.isActive ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작

            const [error, data] = await updateNetworkActiveApi({
              networkIds: [item.id],
              isActive: !item.isActive
            });
            if (error) {
              console.error(error);
              this.$axiosErrorAlert();
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isActive = !item.isActive;
          }
        });
    }
  }
};
</script>
