<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-card class="my-3">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-row class="ma-3 pa-0 filter-box" align="center" justify="center">
              <v-col cols="auto">
                <h3 class="subtitle-2 px-15 py-1 ma-0">검색</h3>
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  v-model="searchText"
                  class="px-3"
                  dense
                  append-outer-icon="mdi-magnify"
                  placeholder="조회하려는 회원 번호, 회원 이름을 입력해주세요."
                  hide-details
                  clearable
                  outlined
                  @keydown.enter="fetchPartners(1)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 text-right px-6">
            <v-btn
              color="secondary"
              :disabled="loading"
              @click="fetchPartners(1)"
            >
              검색
            </v-btn>
          </v-col>
          <v-col cols="12">
            <a-table
              class="px-3 table-striped-rows"
              :columns="columns"
              :data-source="partners"
              :loading="loading"
              size="small"
              :locale="{
                emptyText: '데이터가 없습니다.'
              }"
              bordered
              rowKey="id"
              :pagination="false"
              :scroll="{ x: true }"
            >
              <template slot="localeString" slot-scope="text">
                {{ text.toLocaleString() }}
                <v-icon> mdi-alpha-p-circle </v-icon>
              </template>
              <template slot="createdAt" slot-scope="text">
                {{ $moment(text).format('YYYY-MM-DD') }}
              </template>
              <template slot="actions" slot-scope="value, record">
                <v-tooltip bottom color="rgba(0,0,0,1)">
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn
                      v-on="{ ...tooltipOn }"
                      type="primary"
                      small
                      icon
                      @click.stop="handleUserIFrameClick(record)"
                    >
                      <v-icon>mdi-home</v-icon>
                    </v-btn>
                  </template>
                  <span class="title">사용자 화면</span>
                </v-tooltip>
              </template>
            </a-table>

            <div class="d-flex justify-end pa-3" v-if="partners.length > 0">
              <a-pagination
                v-model="pagination.page"
                :defaultPageSize="pagination.defaultPageSize"
                :page-size.sync="pagination.perPage"
                size="large"
                :show-size-changer="true"
                :pageSizeOptions="['10', '20', '50', '100']"
                :total="pagination.totalCount"
                @change="fetchPartners()"
                @showSizeChange="fetchPartners(1)"
              >
              </a-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import { Pagination } from '@/model/Pagination';
import { getPartnersApi } from '@/api/admin/partners/PartnersAPI';
import { getTokenAPI } from '@/api/admin/UserAPI';
export default {
  components: {
    MenuTitle
  },
  data() {
    return {
      searchText: '',
      partners: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: 'center'
        },
        {
          title: '이름',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '유상 포인트',
          dataIndex: 'creditPoint',
          key: 'creditPoint',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '무상 포인트',
          dataIndex: 'freePoint',
          key: 'freePoint',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '등록일',
          dataIndex: 'createdAt',
          key: 'createdAt',
          align: 'center',
          scopedSlots: {
            customRender: 'createdAt'
          }
        },
        {
          title: '기능',
          dataIndex: 'actions',
          align: 'center',
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ],
      pagination: new Pagination(1, 20),
      loading: true
    };
  },
  computed: {
    ...mapGetters({})
  },
  created() {
    this.fetchPartners();
  },
  methods: {
    ...mapActions({
      openUserIFrameDialog: 'dialog/openUserIFrameDialog',
      setIframeUrl: 'user/setIframeUrl'
    }),
    async fetchPartners(page = null) {
      if (page) {
        this.pagination.page = page;
      }

      this.loading = true;

      const params = {
        params: {
          searchText: this.searchText ?? undefined,
          page: this.pagination.page,
          perPage: this.pagination.perPage
        }
      };

      const [error, data] = await getPartnersApi(params);

      if (error) {
        console.error(error);
      } else {
        const { data: items, total } = data.partners;

        this.partners = items.map(item => {
          return {
            id: item.id,
            name: item.name,
            createdAt: item.created_at,
            creditPoint: item.credit_point || 0,
            freePoint: item.free_point || 0
          };
        });

        const { perPage } = this.pagination;

        const totalPage = parseInt(total / perPage);
        this.pagination.totalCount = total;
        this.pagination.totalPage = total % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    },
    async handleUserIFrameClick(record) {
      const [error, data] = await getTokenAPI(record.id);
      if (error) {
        return false;
      }
      const { token } = data;
      const iframeUrl = `${process.env.VUE_APP_URL}/bridge/${token}`;

      this.setIframeUrl(iframeUrl);
      this.openUserIFrameDialog(record.id);
    }
  }
};
</script>
<style scoped lang="scss">
.filter-box {
  border: 1px solid #e8e8e8;

  .col:first-child {
    background: #f2f2f2;
  }
}
</style>
