export class SelectList {
  key = null;
  _options = []; // 목록에 뿌릴 items
  _selected = []; // 선택한 items
  _selectedIds = []; // 선택한 items ids
  selectedKey = null;
  isOpen = true; // expend 열림 여부

  constructor(init) {
    if (!init) return;

    for (const key in init) {
      let val = init[key];
      this[key] = val;
    }
  }

  get options() {
    return this._options;
  }

  set options(val) {
    this._options = val;
    this._selected = [];
  }
  // 선택 갯수
  get selectedCount() {
    return this._selected.length;
  }
  get selected() {
    return [...this._selected];
  }
  get selectedIds() {
    return [...this._selectedIds];
  }
  // 선택값 변경시
  set selectedIds(val) {
    this._selectedIds = [...val];

    if (this.selectedKey) {
      this._selected = this._options.filter(option =>
        val.includes(option[this.selectedKey])
      );
    }
  }
  // 전체 선택여부 변경
  get isSelectAll() {
    return this.options.length === this.selectedIds.length;
  }
  // 전체 선택여부 변경
  set isSelectAll(val) {
    if (val) {
      // this.selected = [...this._options];
      this.selectedIds = this._options.map(item => item[this.selectedKey]);
    } else {
      // this.selected = [];
      this.selectedIds = [];
    }
  }
}
