<template>
  <div>
    <div class="row ma-0 justify-end">
      <a-button
        size="small"
        class="mb-3"
        :loading="loading"
        @click="fetchPoints"
      >
        새로고침
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      bordered
      :scroll="{ x: true }"
      scrollToFirstRowOnChange
      size="small"
      :pagination="false"
    >
      <span slot="no" slot-scope="text, record, index">
        {{
          pagination.total -
          (pagination.perPage * pagination.page - pagination.perPage) -
          index
        }}
      </span>
      <template slot="localeString" slot-scope="text, record">
        {{ record.isRefund ? '+' : '' }}
        {{ text ? Number(text).toLocaleString() : '-' }}
      </template>

      <template slot="afterBalance" slot-scope="text">
        {{ text ? Number(text).toLocaleString() : text }}
      </template>

      <template slot="type" slot-scope="text, record">
        <a-tag v-if="text" :color="record.typeFlag === 'C' ? 'blue' : 'pink'">
          {{ text }} 포인트
        </a-tag>
      </template>

      <template slot="type" slot-scope="text, record">
        <a-tag v-if="text" :color="record.typeFlag === 'C' ? 'blue' : 'pink'">
          {{ text }} 포인트
        </a-tag>
      </template>

      <template slot="campaignId" slot-scope="text, record">
        <template v-if="text">
          <a-tooltip placement="rightTop">
            <template #title>
              <span>
                {{
                  !record.campaignName
                    ? '삭제된 캠페인 입니다.'
                    : `캠페인명 : ${record.campaignName}`
                }}
              </span>
            </template>
            <span>
              {{ text }}
              <a-icon
                v-if="!record.campaignName"
                type="exclamation-circle"
                class="ml-1"
                style="color: #ff5f5f"
              />
            </span>
          </a-tooltip>
        </template>

        <template v-else>
          <a-tooltip placement="rightTop">
            <template #title>
              <span>
                {{
                  !record.didCampaignServiceName
                    ? '삭제된 캠페인 입니다.'
                    : `캠페인명 : ${record.didCampaignServiceName}`
                }}
              </span>
            </template>
            <span>
              {{ record.didCampaignServiceId }}
              <a-icon
                v-if="!record.didCampaignServiceName"
                type="exclamation-circle"
                class="ml-1"
                style="color: #ff5f5f"
              />
            </span>
          </a-tooltip>
        </template>
      </template>
      <template slot="remark" slot-scope="text, record">
        <a-tag v-if="text" :color="record.isRefund ? 'red' : 'green'">
          {{ text }}
        </a-tag>
      </template>
    </a-table>

    <v-pagination
      v-model="pagination.page"
      :length="pagination.totalPage"
      :total-visible="pagination.perPage"
      class="mt-5"
      color="secondary"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCampaignDepositPointsApi } from '@/api/admin/UserAPI';

export default {
  props: {
    type: String,
    tab: String
  },
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    })
  },
  data() {
    return {
      loading: false,
      data: [],
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        total: 0
      },
      columns: [
        {
          title: '번호',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '사용일',
          dataIndex: 'transactionAt',
          key: 'transactionAt',
          align: 'center',
          ellipsis: true
        },
        {
          title: '유/무상 포인트',
          dataIndex: 'type',
          key: 'type',
          align: 'center',
          width: 120,
          ellipsis: true,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '사용 금액',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          ellipsis: true,
          scopedSlots: { customRender: 'localeString' }
        },
        {
          title: '잔여 금액',
          dataIndex: 'afterBalance',
          key: 'afterBalance',
          align: 'right',
          ellipsis: true,
          scopedSlots: { customRender: 'afterBalance' }
        },
        {
          title: '캠페인 유형',
          dataIndex: 'campaignTypeText',
          key: 'campaignTypeText',
          align: 'center',
          ellipsis: true
        },
        {
          title: '캠페인ID',
          dataIndex: 'campaignId',
          key: 'campaignId',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'campaignId' }
        },
        {
          title: '비고',
          dataIndex: 'remark',
          key: 'remark',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'remark' }
        }
      ]
    };
  },
  watch: {
    'pagination.page'() {
      this.fetchPoints();
    },
    advertiser() {
      this.fetchPoints();
    }
  },
  mounted() {
    this.fetchPoints();
  },
  methods: {
    async fetchPoints() {
      this.loading = true;
      this.data = [];

      const params = {
        params: {
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          // statusFlag: '-',
          pagination: 'Y'
        }
      };

      const [error, data] = await getCampaignDepositPointsApi(
        params,
        this.advertiser.origin.user.id
      );

      if (error) {
        console.log(error);
      } else {
        const { total, last_page, data: items } = data.points;

        this.pagination.total = total;
        this.pagination.totalPage = last_page;

        this.data = items.map((item, index) => {
          return {
            key: index,
            id: item.id,
            type: item.type_flag === 'C' ? '유상' : '무상',
            typeFlag: item.type_flag,
            orderNo: item.order_no,
            amount: item.amount * 1,
            afterBalance: item.after_balance * 1,
            campaignId: item.campaign_id || null,
            campaignName: item?.campaign?.name || null,
            didCampaignService: item?.did_campaign_service || null,
            didCampaignServiceId: item?.did_campaign_service_id || null,
            campaignTypeText: item?.did_campaign_service ? 'DID' : '기본',
            didCampaignServiceName: item?.did_campaign_service?.name || null,
            remark: item?.remark || null,
            code: item.code,
            isRefund: ['CDR00', 'CDCR0'].includes(item.code),
            transactionAt: this.$moment(item.transaction_at).format(
              'YYYY-MM-DD'
            )
          };
        });
      }

      this.loading = false;
    }
  }
};
</script>
