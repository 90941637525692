<template>
  <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card style="background-color:#dedede;">
      <v-toolbar dark>
        <v-btn icon dark @click="handleCloseClick()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >주의! 닫기버튼을 클릭하여 창을 닫으세요. 현재 화면에서 새로고침시
          해당 계정으로 완전히 이동됩니다.</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid>
        <iframe
          :src="iframeUrl"
          frameborder="0"
          width="100%"
          style="height:100vh"
        ></iframe>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      adminToken: null
    };
  },
  created() {
    this.adminToken = localStorage.getItem('token');
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/userIFrameDialog',
      iframeUrl: 'user/iframeUrl'
    })
  },
  beforeDestroy() {
    localStorage.setItem('token', this.adminToken);
  },
  methods: {
    ...mapActions({
      closeDialog: 'dialog/closeUserIFrameDialog'
    }),
    handleCloseClick() {
      this.closeDialog();
    }
  }
};
</script>

<style></style>
