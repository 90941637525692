import axios from 'axios';

export async function getMediasLightAPI() {
    try {
        const {
            data
        } = await axios.get(`/admin/medias/light`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function getActiveMediasLightAPI() {
    try {
        const params = {
            params: {
                'isActive': true
            }
        };
        const {
            data
        } = await axios.get(`/admin/medias/light`, params);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function updateMediaActiveApi(params) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media-active`, params);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function updateMediaAggReachActiveApi(params) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media-agg-reach-active`, params);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function activeCampaignMessageApi(mediaId) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media/${mediaId}/campaign-message/active`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function inactiveCampaignMessageApi(mediaId) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media/${mediaId}/campaign-message/inactive`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function activeCampaignImageApi(mediaId) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media/${mediaId}/campaign-image/active`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function inactiveCampaignImageApi(mediaId) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media/${mediaId}/campaign-image/inactive`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function activeCampaignTextApi(mediaId) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media/${mediaId}/campaign-text/active`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function inactiveCampaignTextApi(mediaId) {
    try {
        const {
            data
        } = await axios.patch(`/admin/media/${mediaId}/campaign-text/inactive`);
        return [null, data]
    } catch (error) {
        return [error]
    }
}