import axios from 'axios';

/**
 * @description: DID 매체 목록 전체 조회
 * */
export async function getDidAppsApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/apps`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 목록 전체 조회
 * */
export async function getDidAppApi(appId) {
  try {
    const { data } = await axios.get(`/admin/did/app/${appId}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 등록
 * */
export async function createDidAppApi(params) {
  try {
    const { data } = await axios.post(`/admin/did/app`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 수정
 * */
export async function updateDidAppApi(appId, params) {
  try {
    const { data } = await axios.put(`/admin/did/app/${appId}`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 매체 목록 전체 조회
 * */
export async function getDidAppOptionsApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/apps-combo`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
