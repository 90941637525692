<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <TagTable @openEditDialog="openDialog" />
      </v-col>
    </v-row>
    <TagDialog :tagDialog="tagDialog" @closeDialog="tagDialog = false" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import TagDialog from './components/TagDialog.vue';
import TagTable from './components/TagTable.vue';

export default {
  components: {
    MenuTitle,
    TagTable,
    TagDialog
  },
  data() {
    return {
      tags: [],
      tagDialog: false,
      dateRanges: null,
      columns: [],
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'tag/dialog'
    })
  },

  methods: {
    ...mapActions({
      initTags: 'tag/initTags',
      hashingTag: 'tag/hashingTag'
    }),

    openDialog(tag = null) {
      this.tagDialog = true;
      if (tag) {
        this.hashingTag(tag);
        this.dialog.edit = true;
      } else {
        this.initTags();
        this.dialog.register = true;
      }
    }
  }
};
</script>
