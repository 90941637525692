var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"depressed":"","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v(" 셋톱박스 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('DidSettopBoxFilters',{attrs:{"filters":_vm.filters,"loading":_vm.indexLoading},on:{"search":_vm.fetchDidSettopBoxes}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.indexLoading,"size":"small","rowKey":"id","columns":_vm.columns,"bordered":"","data-source":_vm.didSettopBoxes,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleRowClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(value, record, index){return [_vm._v(" "+_vm._s(_vm.pagination.total - (_vm.pagination.perPage * _vm.pagination.page - _vm.pagination.perPage) - index)+" ")]}},{key:"status",fn:function(value){return [_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","small":"","color":value ? value.color : '',"outlined":""}},[_vm._v(" "+_vm._s(value ? value.text : '-')+" ")])]}},{key:"switch",fn:function(value, record){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{staticClass:"ma-0 ml-3",attrs:{"color":"success","inset":"","hide-details":"","readonly":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateConfigStatus(record)}},model:{value:(record.configStatus),callback:function ($$v) {_vm.$set(record, "configStatus", $$v)},expression:"record.configStatus"}})],1)]}},{key:"rebootAction",fn:function(value, record){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRebootClick(record)}}},Object.assign({}, tooltipOn)),[_vm._v(" 리부팅 ")])]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("셋톱박스 리부팅")])])],1)]}},{key:"updatedAt",fn:function(value, record){return [_vm._v(" "+_vm._s(record.updatedDate)+" "),_c('br'),_vm._v(" "+_vm._s(record.updatedTime)+" ")]}},{key:"localeString",fn:function(text){return [_vm._v(" "+_vm._s(text.toLocaleString())+" ")]}},{key:"actions",fn:function(value, record){return [_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleShowScheduleClick(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("매체 캠페인 스케줄")])])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalCount)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":_vm.pagination.perPage,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1),(_vm.dialog)?_c('DidSettopBoxDialog',{on:{"refresh":_vm.fetchDidSettopBoxes}}):_vm._e(),(_vm.scheduleDialog)?_c('DidScheduleDialog',{attrs:{"columns":_vm.scheduleColumns,"scheduleDialog":_vm.scheduleDialog,"loading":_vm.loading.dialog,"title":'셋톱박스',"name":_vm.selectSettopBoxName,"tableData":_vm.scheduleTableData},on:{"fetchData":function (date) { return _vm.fetchDidSchedules(_vm.selectSettopBoxId, date); },"close":_vm.handleCloseClick}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }