import axios from 'axios';

export async function getTargetAppsCalculateAPI(params) {
    try {
        const {
            data
        } = await axios.get(`/es/target/apps/calculate`, params);
        return [null, data];
    } catch (error) {
        return [error];
    }
}