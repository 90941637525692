import axios from 'axios';

export async function getNetworksApi() {
    try {
        const {
            data
        } = await axios.get(`/admin/partners/networks`);
        return [null, data];
    } catch (error) {
        return [error];
    }
}

export async function createNetworkApi(network) {
    try {
        const {
            data
        } = await axios.post(`/admin/partners/network`, network)
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function updateNetworkApi(network) {
    try {
        const {
            data
        } = await axios.put(`/admin/partners/network`, network)
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function updateNetworkActiveApi(network) {
    try {
        const {
            data
        } = await axios.patch(`/admin/partners/network/active`, network)
        return [null, data]
    } catch (error) {
        return [error]
    }
}

export async function getReportStatsAPI(params) {
    try {
        const {
            data
        } = await axios.get(`/admin/partners/networks/report`, params);
        return [null, data];
    } catch (error) {
        return [error];
    }
}