export default {
    namespaced: true,

    state: {
        iframeUrl: null
    },
    getters: {
        iframeUrl(state) {
            return state.iframeUrl
        },
    },
    mutations: {
        SET_iframeUrl(state, iframeUrl) {
            state.iframeUrl = iframeUrl
        },
    },
    actions: {
        setIframeUrl({
            commit
        }, iframeUrl) {
            commit('SET_iframeUrl', iframeUrl)
        },
    }
};