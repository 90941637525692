import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';

export class DidApp {
  origin = null;
  id = null; // app id
  name = null; // app name
  package = null; // 패키지
  version = null;
  link = null; // 앱 다운로드 링크
  app = null;
  versionCode = null; // 앱에서 사용하는 버전 코드(변경되면 app 파일이 변경됐다고 생각)
  createdAt = null;

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidApp(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidApp(this);
    }
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }
  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.getUpdatePayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.id
      ? this.origin.getUpdatePayload(true)
      : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  /** *************************
   * 등록 payload
   ************************* **/
  getCreatePayload(isChecked = false) {
    let changedValueKeys = [];

    let res = {
      name: this.name,
      package: this.package,
      version: this.version,
      versionCode: this.versionCode ?? null,
      apk: this.apk
      // link: this.link
    };

    if (isChecked) return res;

    // 값 체크 용도
    changedValueKeys = Object.keys(res);
    const changedValues = this.changedValues(changedValueKeys, res);

    return {
      ...res,
      changedValues: changedValues
    };
  }

  /** *************************
   * 수정 payload
   ************************* **/
  getUpdatePayload(isChecked = false) {
    let changedValueKeys = [];

    let res = {
      id: this.id,
      name: this.name,
      package: this.package,
      version: this.version,
      versionCode: this.versionCode ?? null
      // link: this.link
    };

    if (isChecked) return res;

    // 값 체크 용도
    changedValueKeys = Object.keys(res);
    const changedValues = this.changedValues(changedValueKeys, res);

    return {
      ...res,
      changedValues: changedValues
    };
  }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = `${changedObj[key]}` === `${val}`;

      if (typeof changedObj[key] === 'object' || typeof val === 'object') {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
