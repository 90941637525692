<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <a-card>
          <div class="row ma-0 justify-end mb-5">
            <v-btn
              color="secondary"
              :small="$isMobile"
              @click="handleOpenDialogClick()"
            >
              목록 추가
            </v-btn>
          </div>

          <a-table
            :loading="loading"
            rowKey="id"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            :columns="columns"
            bordered
            :data-source="filterList"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '캠페인 목록을 추가해 주세요.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleOpenDialogClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="name" slot-scope="value">
              <div style="min-width: 200px">{{ value }}</div>
            </template>
            <template slot="filterOptions" slot-scope="value, record">
              <v-row style="max-width: 600px; overflow-y: auto" class="ma-0">
                <v-chip
                  v-for="(filter, idx) in record.filtersText"
                  :key="idx"
                  class="mr-2 mt-1"
                  small
                >
                  {{ filter }}
                </v-chip>
              </v-row>
            </template>
            <template slot="actions" slot-scope="value, record">
              <v-tooltip bottom color="rgba(0,0,0,1)">
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn
                    v-on="{ ...tooltipOn }"
                    type="primary"
                    small
                    icon
                    @click.stop="handleChangeNameDialog(record)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span class="title">캠페인 이름 변경</span>
              </v-tooltip>

              <v-tooltip bottom color="rgba(0,0,0,1)">
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn
                    v-on="{ ...tooltipOn }"
                    type="primary"
                    small
                    icon
                    class="mr-2"
                    @click.stop="handleRemoveClick(record)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span class="title">캠페인 목록 삭제</span>
              </v-tooltip>
            </template>
          </a-table>

          <div class="d-flex justify-end pa-3" v-if="filterList.length > 0">
            <a-pagination
              v-model="pagination.page"
              :defaultPageSize="10"
              :page-size.sync="pagination.perPage"
              size="large"
              :show-size-changer="true"
              :pageSizeOptions="['10', '20', '50', '100']"
              :total="pagination.totalCount"
              @change="fetchFiltersApi()"
              @showSizeChange="fetchFiltersApi(1)"
            >
            </a-pagination>
          </div>
        </a-card>
      </v-col>
    </v-row>
    <FilterNameChangeDialog
      v-if="isUpdateDialog"
      :openDialog="filterNameChangeDialog"
      :filter="selectFilter"
      @closeDialog="handleCloseFilterNameDialog"
      @refresh="fetchFiltersApi"
    />

    <AdminCampaignsDialog
      v-if="isCampaignsDialog"
      :dialog="campaignsDialog"
      :selectFilter="selectFilter"
      :campaignFilterList="filterList"
      @closeDialog="handleCloseCampaignDialog"
      @update="handleFilterUpdate"
      @refresh="fetchFiltersApi"
      @changeFilter="handleSelectFilterChange"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import FilterNameChangeDialog from './components/filter/FilterNameChangeDialog.vue';
import AdminCampaignsDialog from './AdminCampaignsDialog.vue';
import { CampaignFilterType } from '@/config/admin/campaign';
import { getFiltersApi, deleteFilterApi } from '@/api/common/MenusAPI';
import { Pagination } from '@/model/Pagination';

export default {
  components: {
    MenuTitle,
    FilterNameChangeDialog,
    AdminCampaignsDialog
  },
  data() {
    return {
      filterList: [],
      pagination: new Pagination(1, 10),
      campaignsDialog: false, // 캠페인 목록 Dialog
      filterNameChangeDialog: false, // 필터 이름 변경 Dialog
      selectFilter: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    // 이름변경 Dialog
    isUpdateDialog() {
      return this.filterNameChangeDialog && this.selectFilter;
    },
    // 캠페인 목록 Dialog
    isCampaignsDialog() {
      return this.campaignsDialog;
    },
    columns() {
      return [
        {
          title: '이름',
          key: 'name',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '선택 필터',
          key: 'filterOptions',
          dataIndex: 'filterOptions',
          scopedSlots: {
            customRender: 'filterOptions'
          }
        },
        {
          title: '최근 수정일',
          key: 'updatedAt',
          align: 'center',
          width: 200,
          dataIndex: 'updatedAt',
          sorter: (a, b) => a.age - b.age
        },
        {
          title: '만든 날짜',
          key: 'createdAt',
          align: 'center',
          width: 200,
          dataIndex: 'createdAt',
          sorter: (a, b) => a.age - b.age
        },

        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ];
    }
  },
  async created() {
    await this.fetchFiltersApi();
  },
  methods: {
    // 필터 목록 조회 API
    async fetchFiltersApi(page = this.pagination.page) {
      this.loading = true;

      const [error, data] = await getFiltersApi({
        params: {
          menuName: 'campaign',
          page: page,
          perPage: this.pagination.perPage,
          pagination: 'Y'
        }
      });

      if (error) {
        console.log(error);
      } else {
        const { data: filterList, last_page: totalPage, total } = data.filters;

        this.filterList = filterList.map(item => {
          const filtersText = item.filters.map(
            item =>
              CampaignFilterType.find(type => type.value === item.key)?.text ??
              ''
          );
          return {
            ...item,
            filtersText: filtersText,
            createdAt: this.$moment(item.created_at).format('YYYY.MM.DD'),
            updatedAt: this.$moment(item.updated_at).format('YYYY.MM.DD')
          };
        });

        this.pagination.totalCount = total;
        this.pagination.totalPage = totalPage;
      }

      this.loading = false;
    },
    // 필터 이름 변경
    handleChangeNameDialog(filter) {
      if (!filter) return;
      this.selectFilter = filter;
      this.filterNameChangeDialog = true;
    },
    // 필터 이름 변경 모달 닫힐때
    handleCloseFilterNameDialog() {
      this.filterNameChangeDialog = false;
      this.selectFilter = null;
    },
    // 필터 적용 캠페인 Dialog Open
    handleOpenDialogClick(filter = undefined) {
      this.selectFilter = filter;
      this.campaignsDialog = true;
    },
    // 필터 정보 업데이트
    handleFilterUpdate(filter) {
      if (!filter) return;

      const orgFilterIndex = this.filterList.findIndex(
        item => item.id === this.selectFilter.id
      );

      this.filterList[orgFilterIndex] = JSON.parse(JSON.stringify(filter));
      this.selectFilter = JSON.parse(JSON.stringify(filter));
    },
    handleCloseCampaignDialog(filter) {
      if (this.selectFilter) {
        const orgFilterIndex = this.filterList.findIndex(
          item => item.id === this.selectFilter.id
        );

        if (filter) {
          this.filterList[orgFilterIndex] = JSON.parse(JSON.stringify(filter));
        }
      }

      this.campaignsDialog = false;
      this.selectFilter = null;
    },
    // 선택 필터 변경
    handleSelectFilterChange(filterId) {
      const changeFilter = this.filterList.find(item => item.id === filterId);
      if (filterId && changeFilter) {
        this.selectFilter = null;
        this.selectFilter = JSON.parse(JSON.stringify(changeFilter));
      }
    },
    // 필터 삭제
    async handleRemoveClick(filter) {
      const filterName = filter.name || `캠페인 필터 ${filter.id}`;

      this.$Swal.fire({
        html: `<h3>[${filterName}] 필터를 삭제 하시겠습니까?</h3>`,
        confirmButtonText: '확인',
        showCancelButton: true,
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        icon: 'error',
        iconHtml: '!',
        preConfirm: async () => {
          this.loading = true;

          const [error, data] = await deleteFilterApi(filter.id);

          if (error) {
            const message = error?.data?.message || null;
            this.$Swal.showValidationMessage(message ?? `요청 실패`);
          } else {
            await this.fetchFiltersApi();

            this.$Swal.fire({
              icon: 'success',
              title: '삭제 되었습니다.',
              showConfirmButton: false
            });
            return data;
          }
        }
      });

      this.loading = false;
    }
  }
};
</script>
<style>
.table-striped-rows tr:nth-child(2n) td {
  background-color: #f5f5f5;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
</style>
