<template>
  <v-speed-dial
    v-model="speedDial"
    transition="scale-transition"
    direction="top"
    bottom
    right
    fixed
  >
    <template v-slot:activator>
      <v-btn v-model="speedDial" color="secondary" fab small :loading="loading">
        <v-icon v-if="speedDial"> mdi-close </v-icon>
        <v-icon v-else>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-tooltip
      :color="event.color"
      class="custom-tooltip"
      left
      v-for="(event, i) in eventsList"
      :key="i"
    >
      <template #activator="{ on: onTooltip }">
        <v-btn
          fab
          small
          v-on="onTooltip"
          :color="event.color"
          @click="event.function(event.params)"
        >
          <v-icon>{{ event.icon }}</v-icon>
        </v-btn>
      </template>
      <span class="headline">{{ event.text }}</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
import {
  getTypeReportStatsRawApi,
  getReportStatsTypeAPI,
  getReportStatsCouponAPI,
  getReportStatsCouponRawApi,
  getReportStatsMegaboxCouponChartAPI
} from '@/api/admin/report';
import { ExcelExport } from '@/utils/tools';
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      required: true
    },
    tableOptions: {
      type: Object,
      required: true
    },
    chartList: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      default: () => {}
    },
    searchString: {
      type: String,
      default: ''
    },
    issetLive: {
      type: Boolean,
      required: true
    },
    brchNos: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    eventsList() {
      let ev = this.events;
      if (this.type === 'megabox-coupon') {
        ev = ev.filter(item => item.text !== 'Raw 데이터 다운로드');
      }
      return ev;
    },
    dates() {
      return this.getDates();
    }
  },
  data() {
    return {
      speedDial: false,
      selectedItem: null,
      loading: false,
      events: [
        {
          text: 'Raw 데이터 다운로드',
          icon: 'mdi-file-document',
          color: 'secondary',
          function: this.handleRawDataExportClick
        },
        {
          text: '엑셀 다운로드',
          icon: 'mdi-file-excel',
          color: 'secondary',
          function: this.handleDataExportClick
        }
      ]
    };
  },
  methods: {
    async handleRawDataExportClick() {
      this.loading = true;

      const params = {
        ...this.filters,
        dateRanges: this.filters.dateRanges
      };

      const [error, data] =
        this.type !== 'coupon'
          ? await getTypeReportStatsRawApi({ params: params })
          : await getReportStatsCouponRawApi({ params: params });

      if (error) {
        console.error(error);
      } else {
        const { stats, columns } = data;
        ExcelExport(
          [columns],
          stats,
          `캠페인보고서_Raw_${this.$moment().format('YYYY-MM-DD')}`
        );
      }

      this.loading = false;
    },
    async handleDataExportClick() {
      this.loading = true;

      const params = {
        page: null,
        perPage: null,
        tableOptions: this.tableOptions,
        filters: this.filters,
        issetLive: this.issetLive
      };

      const [error, data] =
        this.type !== 'coupon'
          ? this.type !== 'megabox-coupon'
            ? await getReportStatsTypeAPI({
                params: {
                  ...params,
                  issetLive: this.issetLive,
                  campaignTypes: !this.type
                    ? ['rolling', 'push', 'message']
                    : this.type
                }
              })
            : await getReportStatsMegaboxCouponChartAPI({
                params: {
                  dateRanges: this.filters.dateRanges,
                  brchNos: this.brchNos
                }
              })
          : await getReportStatsCouponAPI({
              params: {
                ...params,
                brchNos: this.brchNos
              }
            });

      if (error) {
        console.error(error);
      } else {
        const { stats } = data;
        const result =
          this.type !== 'megabox-coupon'
            ? this.destructing(stats)
            : this.megaboxStatsforamt(stats);

        const heading = [this.headers.map(v => v.text ?? v.title)];
        const headerKeys = this.headers.map(v => v.value ?? v.dataIndex);
        const Data = result.map(campaigns => {
          const item = {};
          headerKeys.forEach(key => {
            item[key] = campaigns[key];
          });
          return item;
        });

        ExcelExport(
          heading,
          Data,
          `캠페인보고서_${this.$moment().format('YYYY-MM-DD')}`
        );
      }

      this.loading = false;
    },
    megaboxStatsforamt(stats) {
      if (!stats || !stats.length) return [];

      const chartStats = stats;

      let ctrStats = []; // 기공할 테이블 데이터
      let impressionSum = 0; // api 호출 횟수 합
      let reachCountSum = 0; // 예매한 회원 수 합
      let brchCountSum = 0; // 지점 수 합
      this.dates.forEach(date => {
        const data = chartStats.find(v => v.date === date.value);

        let obj = {
          key: date,
          brch_cnt: 0,
          date: date.value,
          impression: 0,
          reach_count: 0
        };

        if (data) {
          obj = { ...data, key: date };
          impressionSum += data.impression;
          brchCountSum += data.brch_cnt;
          reachCountSum += data.reach_count;
        }

        ctrStats = [...ctrStats, obj];
      });

      const summaryStat = {
        brch_cnt: brchCountSum,
        date: '전체',
        impression: impressionSum,
        reach_count: reachCountSum,
        summary: true
      };

      return [summaryStat, ...ctrStats];
    },
    getDates() {
      const dates = [];

      const startDate = this.$moment(this.filters.dateRanges[0]);
      const endDate = this.$moment(this.filters.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
      return dates;
    },
    destructing(items) {
      const chartList = this.chartList;
      return items.map(item => {
        return {
          date: item.date,
          campaign_type:
            chartList.find(v => v.value === item.campaign_type)?.text || '',
          media_id: item?.media_name || '',
          impressionCount: item.impression,
          reachCount: item.reach,
          clickCount: item.click,
          uniqueClickCount: item.unique_click,
          ctr: item.ctr,
          ctrReach: item.ctr_reach,
          expenditure: Number(item.expenditure),
          live: item?.live || 0,
          ctrLive: item?.ctr_live || 0,
          cpmiExpenditure: Number(item.cpmi_expenditure),
          cpmrExpenditure: Number(item.cpmr_expenditure),
          branchPopupCount: item?.branch_popup_count || 0,
          branchApiCallCount: item?.branch_api_call_count || 0
        };
      });
    }
  }
};
</script>

<style></style>
