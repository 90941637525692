import axios from 'axios';

export async function getAdvertisersAPI(params) {
  try {
    const { data } = await axios.get(`/admin/advertisers`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateAdvertiserAPI(advertiserId, params) {
  const option = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const { data } = await axios.post(
      `/admin/advertiser/${advertiserId}`,
      params,
      option
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createAdvertiserAPI(params) {
  try {
    const { data } = await axios.post(`/admin/advertiser`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getAdvertiserAPI(advertiserId) {
  try {
    const { data } = await axios.get(`/admin/advertiser/` + advertiserId);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
