import axios from 'axios';

export async function registerUserApi(params, option = null) {
    try {
        const {
            data
        } = await axios.post(`/register`, params, option);
        return [null, data];
    } catch (error) {
        return [error];
    }
}

export async function validateUidApi(params) {
    try {
        const {
            data
        } = await axios.post(`/validate-uid`, params);
        return [null, data];
    } catch (error) {
        return [error];
    }
}

export async function validateEmailApi(params) {
    try {
        const {
            data
        } = await axios.post(`/validate-email`, params);
        return [null, data];
    } catch (error) {
        return [error];
    }
}