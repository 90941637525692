var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){return _vm.handleRegitserClick()}}},[_vm._v(" 네트워크 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"pa-3"},[_c('Table',{attrs:{"columns":_vm.columns,"data-source":_vm.networks,"bordered":"","size":"small","pagination":{
            defaultPageSize: 10,
            position: 'bottom',
            size: 'large',
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100']
          },"customRow":function (record) {
              return {
                on: {
                  mouseenter: function (event) {
                    _vm.hoverItem = record.id;
                  },
                  mouseleave: function (event) {
                    _vm.hoverItem = null;
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"isActive",fn:function(value, record){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"info","inset":"","readonly":"","hide-details":""},on:{"click":function($event){return _vm.handleActiveClick(record)}},model:{value:(record.isActive),callback:function ($$v) {_vm.$set(record, "isActive", $$v)},expression:"record.isActive"}})],1)]}},{key:"name",fn:function(value, record){return [_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"min-height":"30px !important"}},[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticStyle:{"min-width":"40px"}},[(_vm.hoverItem === record.id)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleEditClick(record.origin)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("수정")])]):_vm._e()],1)])]}},{key:"money",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("KRW")(text))+" ")]}},{key:"mediaGroups",fn:function(mediaGroups){return [_c('v-menu',{attrs:{"open-on-hover":"","open-delay":"100","close-delay":"100","bottom":"","offset-y":"","nudge-left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-0 font-weight-bold",attrs:{"label":"","color":"secondary","small":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v(_vm._s(mediaGroups[0].name)+" "+_vm._s(mediaGroups.length > 1 ? ("외 " + (mediaGroups.length - 1) + "개") : ''))])])]}}],null,true)},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"width":"300","min-height":"150","max-height":"300"}},[_c('v-card-title',[_vm._v(" 매체그룹 ")]),_c('v-card-text',_vm._l((mediaGroups),function(mediaGroup,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"label":"","outlined":"","color":"secondary"}},[_c('span',[_vm._v(" "+_vm._s(mediaGroup.name)+" ")])])}),1)],1)],1)]}}])})],1)],1)],1),(_vm.networkDialog)?_c('NetworkDialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }