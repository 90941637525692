export default {
  namespaced: true,
  state: {
    couponCategory: null,
    dialog: false
  },
  getters: {
    couponCategory(state) {
      return state.couponCategory;
    },
    dialog(state) {
      return state.dialog;
    }
  },
  mutations: {
    SET_CouponCategory(state, couponCategory) {
      state.couponCategory = couponCategory;
    },
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    }
  },
  actions: {
    setCouponCategory({ commit }, couponCategory) {
      commit(
        'SET_CouponCategory',
        couponCategory ? { ...couponCategory } : null
      );
    },
    openDialog({ commit }, couponCategory) {
      if (couponCategory) {
        commit(
          'SET_CouponCategory',
          couponCategory ? { ...couponCategory } : null
        );
      }
      commit('SET_Dialog', true);
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
      commit('SET_CouponCategory', null);
    }
  }
};
