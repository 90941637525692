<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row id="admin-promotion-inquiry">
      <v-col cols="12">
        <a-card>
          <v-row>
            <v-col cols="12">
              <AdminPromotionInquiryFilters
                :loading="loading"
                :statusList="promotionInquiryStatus"
                @search="filters => fetchPromotionInqury(1, filters)"
              />
            </v-col>
            <v-col cols="12" class="text-right pb-6">
              <v-btn
                :disabled="!selectedIds.length"
                @click="handleAdvertiserSync"
                outlined
              >
                <v-icon size="large" class="mr-1"> mdi-sync </v-icon>

                동기화
              </v-btn>

              <v-btn
                :loading="loading"
                :disabled="loading"
                @click="excelExport"
                color="secondary"
                class="ml-3"
              >
                <v-icon size="large" class="mr-1"> mdi-microsoft-excel </v-icon>
                Excel
              </v-btn>
            </v-col>
          </v-row>
          <a-table
            :columns="filteredColumns"
            :data-source="promotionInquiries"
            :loading="loading"
            bordered
            :scroll="{ x: true }"
            scrollToFirstRowOnChange
            size="small"
            :pagination="false"
            class="mb-5 inquiry-table"
            rowKey="id"
            :row-selection="{
              selectedRowKeys: selectedIds,
              onChange: onSelectChange
            }"
            :locale="{
              emptyText: '등록된 내역이 없습니다'
            }"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleDetailClick(record);
                    }
                  }
                };
              }
            "
          >
            <span slot="no" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>

            <template slot="name" slot-scope="value, record">
              <v-btn
                v-if="record.advertiserId"
                text
                class="text-body font-weight-regular row align-center ma-0"
                @click.stop="
                  openAdvertiserDialog({
                    record: {
                      advertiserId: record.advertiserId
                    },
                    type: 'detail'
                  })
                "
              >
                {{ value }}
                <v-icon class="ml-1 mb-1" color="grey darken-3">
                  mdi-application-edit-outline
                </v-icon>
              </v-btn>
              <v-col v-else> {{ value }}</v-col>
            </template>

            <span slot="createdAt" slot-scope="value">
              {{ $moment(value).format('YYYY-MM-DD HH:mm') }}
            </span>

            <span slot="statusInfo" slot-scope="text, record">
              <v-chip
                :color="record.statusInfo.color"
                outlined
                label
                small
                @click.stop="() => handleStatusClick(record)"
              >
                {{ record.statusInfo.text }}

                <v-avatar right class="ml-0">
                  <span>
                    <v-tooltip bottom color="rgba(0,0,0,0.8)">
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          v-on="{ ...onTooltip }"
                          icon
                          x-small
                          :color="record.statusInfo.color"
                        >
                          <v-icon>mdi-sync</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-subtitle-2">상태 변경</span>
                    </v-tooltip>
                  </span>
                </v-avatar>
              </v-chip>
            </span>
          </a-table>

          <!-- <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="article, actions, article, actions, article, actions"
    ></v-skeleton-loader> -->
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="fetchPromotionInqury()"
        ></v-pagination>
      </v-col>
    </v-row>
    <PromotionDialog
      v-if="isPromotionDialog && selectRecord"
      :record="selectRecord"
      :dialog="isPromotionDialog"
      @index="fetchPromotionInqury(pagination.page)"
      @close="handleCloseDialog"
    />
    <PromotionStatusDialog
      v-if="isStatusDialog && selectRecord"
      :record="selectRecord"
      :dialog="isStatusDialog"
      :statusList="promotionInquiryStatus"
      @index="fetchPromotionInqury(pagination.page)"
      @close="handleCloseDialog"
    />
    <AdvertiserDetailDialog v-if="advertiserDialog" />
  </v-container>
</template>

<script>
import { utils, writeFile } from 'xlsx';
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import {
  getPromotionInquiriesApi,
  setPromotionAdvertiserSyncApi
} from '@/api/admin/InquiryApi';

import PromotionStatusDialog from './components/PromotionStatusDialog.vue';
import PromotionDialog from './components/PromotionDialog.vue';
import AdvertiserDetailDialog from '@/views/admin/pages/advertiser/components/dialog/AdvertiserDetailDialog.vue';
import AdminPromotionInquiryFilters from './components/AdminPromotionInquiryFilters.vue';

export default {
  components: {
    MenuTitle,
    PromotionDialog,
    AdminPromotionInquiryFilters,
    AdvertiserDetailDialog,
    PromotionStatusDialog
  },
  computed: {
    ...mapGetters({
      advertiserDialog: 'advertiser/dialog'
    }),
    filteredColumns() {
      return this.columns.filter(item => !item.hidden);
    }
  },
  data() {
    return {
      filters: {},
      selectedIds: [],
      columns: [
        {
          title: 'NO',
          align: 'center',
          dataIndex: 'id',
          key: 'id',
          width: 80
        },
        {
          title: '상태',
          dataIndex: 'status',
          align: 'center',
          key: 'status',
          scopedSlots: {
            customRender: 'statusInfo'
          },
          width: 100
        },
        {
          title: '프로모션 명',
          dataIndex: 'title',
          align: 'left',
          key: 'title',
          width: 250
        },
        {
          title: '상호명',
          dataIndex: 'businessName',
          key: 'businessName',
          width: 200
        },
        {
          title: '이름',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '이메일',
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: '연락처',
          align: 'center',
          dataIndex: 'phone',
          key: 'phone'
        },
        {
          title: '코멘트',
          dataIndex: 'reply',
          width: 300
        },
        {
          title: '등록일시',
          dataIndex: 'createdAt',
          align: 'center',
          key: 'createdAt',
          scopedSlots: {
            customRender: 'createdAt'
          },
          width: 150
        }
      ],
      promotionInquiries: [],
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      loading: true,
      isMobile: false,
      isStatusDialog: false,
      isPromotionDialog: false,
      selectRecord: null,
      promotionInquiryStatus: [
        {
          value: 'W',
          text: '미확인',
          color: '#424242'
        },
        {
          value: 'Y',
          text: '참여',
          color: '#4caf50'
        },
        {
          value: 'N',
          text: '미 참여',
          color: '#ff5252'
        },
        {
          value: 'H',
          text: '대기',
          color: '#fb8c00'
        }
      ]
    };
  },
  async created() {
    this.filters = {};

    window.addEventListener(
      'resize',
      () => (this.isMobile = window.innerWidth < 1500)
    );

    // await this.fetchPromotionInqury();
  },
  beforeDestroy() {
    window.removeEventListener(
      'resize',
      () => (this.isMobile = window.innerWidth < 1500)
    );
  },
  methods: {
    ...mapActions({
      // openDialog: 'inquiry/openDialog',
      openAdvertiserDialog: 'advertiser/openDialog' // 광고주 정보
    }),
    async fetchPromotionInqury(page = 1, filter = null) {
      this.loading = true;
      this.promotionInquiries = [];
      // 엑셀 다운로드 떄문에
      if (filter) {
        this.filters = { ...filter };
      }

      const params = {
        filters: this.filters ?? {},
        pagination: 'Y',
        page: page,
        perPage: this.pagination.perPage
      };

      const [error, data] = await getPromotionInquiriesApi({
        params: params
      });

      this.loading = false;

      if (error) {
        console.error(error);
      } else {
        this.promotionInquiries = data.promotion_inquiry.map(item => ({
          id: item.id,
          adminId: item.admin_id,
          adminName: item.admin_name,
          advertiserId: item.advertiser_id,
          businessName: item.business_name,
          repliedAt: item.replied_at,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
          email: item.email,
          name: item.name,
          phone: item.phone,
          reply: item.reply,
          title: item.title,
          statusInfo: this.promotionInquiryStatus.find(
            info => info.value === item.status
          )
        }));
        const { total } = data;
        const { perPage } = this.pagination;
        const totalPage = parseInt(total / perPage);
        this.pagination.totalPage = total % perPage ? totalPage + 1 : totalPage;
      }
    },
    // 프로모션 상태 변경 Dialog
    handleStatusClick(record) {
      this.selectRecord = {
        ...record,
        status: record.statusInfo
      };
      this.isStatusDialog = true;
    },
    // 프로모션 상세 Dialog
    handleDetailClick(record) {
      if (!record) return;

      this.selectRecord = {
        ...record
      };
      this.isPromotionDialog = true;
    },
    handleCloseDialog(target = undefined) {
      if (!target) {
        this.isStatusDialog = this.isPromotionDialog = false;
      } else {
        if (this[`${target}`]) {
          this[`${target}`] = false;
        }
      }
      this.selectRecord = null;
    },
    // 프로모션 광고주 동기화
    async handleAdvertiserSync() {
      this.loading = true;

      if (!this.selectedIds.length) {
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: '1개 이상의 프로모션을 선택해 주세요.',
          confirmButtonText: '확인'
        });
        return;
      }
      const params = {
        promotionIds: this.selectedIds
      };

      const [error] = await setPromotionAdvertiserSyncApi(params);

      this.loading = false;

      if (error) {
        console.error(error);
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: error?.data?.message,
          confirmButtonText: '확인'
        });
      } else {
        this.fetchPromotionInqury(this.pagination.page);
      }
    },
    // 엑셀 다운로드
    async excelExport() {
      if (this.promotionInquiries.length > 0 === false) {
        this.errorAlert();
        return false;
      }
      const hashingData = await this.getPomotionHashing();

      const Heading = [this.columns.map(item => item.title)];
      const headerKeys = this.columns.map(item => item.dataIndex);
      const Data = hashingData.map(promotion => {
        const item = {};
        headerKeys.forEach(key => {
          item[key] = promotion[key];
        });
        return item;
      });

      var wb = utils.book_new();

      var ws = utils.json_to_sheet(Data, {
        origin: 'A2',
        skipHeader: true
      });

      utils.sheet_add_aoa(ws, Heading); //heading: array of arrays
      utils.book_append_sheet(wb, ws);

      writeFile(wb, `프로모션_${this.$moment().format('YYYY-MM-DD')}.xlsx`);
    },
    // 엑셀 다운로드 프로모션 정보 hashing
    async getPomotionHashing() {
      this.loading = true;
      let hashingData = [];
      const params = {
        filters: this.filters ?? {},
        pagination: 'N'
      };

      const [error, data] = await getPromotionInquiriesApi({
        params: params
      });

      this.loading = false;

      if (error) {
        console.error(error);
      } else {
        const { promotion_inquiry } = data;
        hashingData = promotion_inquiry.map(item => ({
          id: item.id,
          adminId: item.admin_id,
          adminName: item.admin_name,
          advertiserId: item.advertiser_id,
          advertiserName: item.advertiser_name,
          businessName: item.business_name,
          createdAt: item.created_at,
          repliedAt: item.replied_at,
          email: item.email,
          name: item.name,
          phone: item.phone,
          reply: item.reply,
          title: item.title,
          status: this.promotionInquiryStatus.find(
            info => info.value === item.status
          ).text
        }));
      }

      return hashingData;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedIds = selectedRowKeys;
    }
  }
};
</script>

<style lang="scss">
#admin-promotion-inquiry {
  .inquiry-table td {
    max-width: 650px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
