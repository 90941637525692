import moment from 'moment';
import { getPointOrdersApi } from '@/api/advertiser/UserAPI';
import { PGOrderStatusFlags, PaymentMethods } from '@/config/charge';

export default {
  namespaced: true,

  state: {
    loading: false,
    buckets: [],
    columns: [
      {
        title: '주문 번호',
        dataIndex: 'orderNo',
        align: 'center'
      },
      {
        title: '결제 일시',
        align: 'center',
        dataIndex: 'orderedAt'
      },
      {
        title: '결제 금액',
        dataIndex: 'amount',
        align: 'right',
        scopedSlots: {
          customRender: 'money'
        }
      },
      {
        title: '부가세',
        dataIndex: 'taxAmount',
        align: 'right',
        scopedSlots: {
          customRender: 'money'
        }
      },
      {
        title: '실광고 금액',
        dataIndex: 'advertisingAmount',
        align: 'right',
        scopedSlots: {
          customRender: 'money'
        }
      },
      {
        title: '결제 상태',
        dataIndex: 'status'
      },
      {
        title: '결제 방식',
        dataIndex: 'paymentMethod',
        align: 'center'
      },
      {
        title: '청구서',
        dataIndex: 'hasBill',
        align: 'center',
        scopedSlots: {
          customRender: 'hasBill'
        }
      }
    ],
    payload: {
      page: 1,
      perPage: 20,
      dates: [],
      statusFlag: ['W', 'S', 'F', 'C'],
      orderNo: ''
    },
    pagination: {
      totalPage: 1,
      total: 0
    }
  },
  getters: {
    columns(state) {
      return state.columns;
    },
    payload(state) {
      return state.payload;
    },
    pagination(state) {
      return state.pagination;
    },
    buckets(state) {
      return state.buckets;
    },
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    SET_BUCKETS(state, value) {
      state.buckets = value;
    },
    SET_PAGINATION(state, value) {
      state.pagination = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async index({ commit, getters }) {
      commit('SET_LOADING', true);

      const payload = {
        params: getters.payload
      };

      const [error, data] = await getPointOrdersApi(payload);
      if (error) {
        console.error(error);
      } else {
        const { point_orders, last_page, total } = data;

        const items = point_orders.map((item, index) => {
          return {
            key: index,
            orderNo: item.order_no,
            orderedAt: moment(item.order_at).format('YYYY-MM-DD'),
            amount: Number(item.amount),
            taxAmount: Number(item.tax_amount),
            advertisingAmount: Number(item.advertising_amount),
            status:
              PGOrderStatusFlags.find(v => v.value === item.status_flag)
                ?.text || 'N/A',
            paymentMethod:
              PaymentMethods.find(v => v.value === item.payment_method)?.text ||
              'N/A',
            hasBill: item.has_bill
          };
        });

        commit('SET_PAGINATION', {
          totalPage: last_page,
          total: total
        });

        commit('SET_BUCKETS', items);
        commit('SET_LOADING', false);
      }
    }
  }
};
