<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="{ ...on }"
        fab
        small
        :loading="loading"
        @click="excelExport"
        color="secondary"
      >
        <v-icon>mdi-table-arrow-down</v-icon>
      </v-btn>
    </template>
    <span class="headline"> 엑셀 다운로드 </span>
  </v-tooltip>
</template>

<script>
import { utils, writeFile } from 'xlsx';

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    tableOptions: {
      type: Object,
      required: true
    },
    chartList: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      default: () => {}
    },
    searchString: {
      type: String,
      default: ''
    },
    issetLive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async excelExport() {
      const stats = await this.getStats();
      // Using same variables as the above answer
      const Heading = [this.headers.map(v => v.text)];
      const headerKeys = this.headers.map(v => v.value);
      const Data = stats.map(campaigns => {
        const item = {};
        headerKeys.forEach(key => {
          item[key] = campaigns[key];
        });
        return item;
      });

      var wb = utils.book_new();

      var ws = utils.json_to_sheet(Data, {
        origin: 'A2',
        skipHeader: true
      });
      utils.sheet_add_aoa(ws, Heading); //heading: array of arrays

      utils.book_append_sheet(wb, ws);

      writeFile(wb, `보고서_${this.$moment().format('YYYY-MM-DD')}.xlsx`);
    },
    async getStats() {
      this.loading = true;
      const params = {
        ss: this.searchString,
        page: null,
        perPage: null,
        tableOptions: this.tableOptions,
        filters: this.filters
      };

      try {
        const {
          data: { stats: stats }
        } = await this.$axios.get('/adt/report-stats', {
          params: params
        });
        return this.destructing(stats);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    destructing(items) {
      const chartList = this.chartList;
      const destructingData = items.map(item => {
        return {
          date: item.date,
          campaign_type:
            chartList.find(v => v.value === item.campaign_type)?.text || '',
          media_id: item?.media_name || '',
          reachCount: item.reach,
          clickCount: item.click,
          ctrReach: item.ctr_reach,
          expenditure: Number(item.expenditure)
        };
      });
      return destructingData;
    }
  }
};
</script>

<style></style>
