<template>
  <div class="d-inline-block float-right">
    <v-dialog v-model="reviewDialog" persistent max-width="1600px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="invalid"
          color="secondary"
          large
        >
          {{ dialogTitle }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">캠페인 {{ dialogTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-container class="px-5">
                <v-row>
                  <v-col cols="6">
                    <h3>{{ titles.rs1.advertiser }}</h3>
                    <v-text-field
                      :value="campaign.rs1.user.advertiser.name"
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h3>{{ titles.rs1.advertiserGroup }}</h3>
                    <v-text-field
                      :value="campaign.rs1.user.advertiserGroup.name"
                      outlined
                      filled
                      readonly
                    >
                      <template v-slot:prepend-inner>
                        <v-avatar tile left size="25">
                          <v-img
                            v-if="campaign.rs1.user.advertiserGroup.icon"
                            :src="campaign.rs1.user.advertiserGroup.icon.url"
                            class="rounded-lg "
                          ></v-img>
                        </v-avatar>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="6">
                    <h3>{{ titles.rs1.mediaGroup }}</h3>
                    <v-text-field
                      :value="
                        campaign.rs1.user.mediaGroups
                          .map(mediaGroup => mediaGroup.name)
                          .join(', ')
                      "
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="campaign.rs1.user.tracker">
                    <h3>{{ titles.rs1.tracker }}</h3>
                    <v-text-field
                      :value="campaign.rs1.user.tracker.name"
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <h3>{{ titles.rs1.campaignName }}</h3>
                <v-text-field
                  :value="campaign.rs1.campaignName"
                  outlined
                  filled
                  readonly
                ></v-text-field>
                <h3>{{ titles.rs1.landingUrl }}</h3>
                <v-text-field
                  :value="campaign.rs1.landingUrl"
                  outlined
                  filled
                  readonly
                ></v-text-field>
                <h3>{{ titles.rs1.campaignType }}</h3>

                <v-text-field
                  :value="
                    CampaignTypeList.find(
                      v => v.value === campaign.rs1.campaignType
                    ).text +
                      `${
                        campaign.rs1.campaignType === 'push'
                          ? campaign.rs2.push.clickType === 'direct'
                            ? ' - 다이렉트'
                            : ' - 인앱'
                          : ''
                      }`
                  "
                  outlined
                  filled
                  readonly
                ></v-text-field>

                <!-- <h3>{{ titles.displayPeriod }}</h3> -->
                <v-row v-if="isCampaignTypeMessage">
                  <v-col>
                    <h3>{{ titles.rs5.schedule.messageDateTime }}</h3>
                    <v-text-field
                      :value="
                        `${campaign.rs5.schedule.messageDate}  ${campaign.rs5.schedule.messageTime}`
                      "
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col cols="6">
                    <h3>광고 시작일</h3>
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      :value="campaign.rs5.schedule.displayPeriodStart"
                      readonly
                      outlined
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h3>광고 종료일</h3>
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      :value="displayPeriodEndText"
                      readonly
                      outlined
                      filled
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row v-if="!isCampaignTypeMessage" class="mt-0">
                  <v-col>
                    <h3>
                      {{ titles.rs4.impressionTime }}
                    </h3>
                    <v-text-field
                      :value="campaign.rs4.impressionTime"
                      :suffix="
                        campaign.rs4.impressionTimeType === 's' ? '초' : '분'
                      "
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                    <h3>{{ titles.rs4.impressionFrequency }}</h3>
                    <v-text-field
                      :value="campaign.rs4.impressionFrequency"
                      outlined
                      suffix="순위"
                      filled
                      readonly
                    ></v-text-field>
                    <h3>{{ titles.rs4.adsLimitInterval }}</h3>
                    <v-text-field
                      :value="campaign.rs4.adsLimitInterval"
                      outlined
                      suffix="일"
                      filled
                      readonly
                    ></v-text-field>
                    <h3>{{ titles.rs4.adsGoalCount }}</h3>
                    <v-text-field
                      :value="campaign.rs4.adsGoalCount"
                      outlined
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div v-if="isCampaignTypeMessage">
                  <h3>{{ titles.rs5.budget.cpm }}</h3>
                  <v-text-field
                    :value="campaign.rs5.budget.cpm | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </div>
                <div v-else>
                  <h3>{{ titles.rs5.budget.cpc }}</h3>
                  <v-text-field
                    :value="campaign.rs5.budget.cpc | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                  <h3>{{ titles.rs5.budget.dailyAdvertisingBudget }}</h3>
                  <v-text-field
                    :value="campaign.rs5.budget.dailyAdvertisingBudget | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                  <h3>{{ titles.rs5.budget.totalAdvertisingBudget }}</h3>
                  <v-text-field
                    :value="campaign.rs5.budget.totalAdvertisingBudget | KRW"
                    outlined
                    filled
                    readonly
                  ></v-text-field>
                </div>

                <!-- <p>{{ campaign.rs3.apps }}</p>
            <p>{{ campaign.rs3.locations }}</p> -->
              </v-container>
            </v-col>
            <v-col cols="12" lg="6">
              <v-container class="px-3">
                <v-row v-if="campaign.rs3.targetTags.length > 0">
                  <v-col cols="12" v-if="campaign.rs3.isTagFilter">
                    <h3>
                      # 태그 타겟
                    </h3>
                    <h3>
                      태그 타겟 모드:
                      {{
                        TargetTagsModeList.find(
                          v => v.value === campaign.rs3.targetTagsMode
                        ).text
                      }}
                    </h3>
                    <h3>
                      태그 타겟 필터 유형:
                      {{
                        TargetTagsTypeList.find(
                          v => v.value === campaign.rs3.targetTagsType
                        ).text
                      }}
                      <v-chip label outlined color="error ma-2">
                        <span>
                          예상 도달 수:
                        </span>
                        <span class="title">
                          {{
                            campaign.rs3.targetTagsCount.toLocaleString()
                          }}</span
                        >
                      </v-chip>
                    </h3>
                    <v-chip
                      v-for="(tag, index) in campaign.rs3.targetTags"
                      :key="index"
                      label
                      outlined
                      color="error ma-2"
                    >
                      <v-icon left>
                        mdi-pound
                      </v-icon>
                      <span class="title">{{ tag.name }}</span>
                    </v-chip>
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <h3># 수집 태그</h3>
                    <v-chip
                      v-for="(tag, index) in campaign.rs3.tags"
                      :key="index"
                      label
                      outlined
                      color="primary darken-3 ma-2"
                    >
                      <v-icon left>
                        mdi-pound
                      </v-icon>
                      <span class="title">{{ tag.name }}</span>
                    </v-chip>
                    <v-divider />
                  </v-col>
                </v-row>
                <campaign-preview :rs1="campaign.rs1" :rs2="campaign.rs2" />
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="reviewDialog = loading = false"
            :disabled="loading"
            large
          >
            이전
          </v-btn>
          <v-btn
            color="secondary"
            @click="createCampaign()"
            :loading="loading"
            large
          >
            완료
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CampaignPreview from './CampaignPreview.vue';
import {
  CampaignTypeList,
  Titles,
  TargetTagsTypeList,
  TargetTagsModeList
} from '@/config/admin/campaign';
export default {
  components: {
    CampaignPreview
  },
  props: ['invalid'],
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      isDialogTypeRegister: 'campaign/isDialogTypeRegister'
    }),
    dialogTitle() {
      return this.isDialogTypeRegister ? '등록' : '수정';
    },
    CampaignTypeList() {
      return CampaignTypeList;
    },
    titles() {
      return Titles;
    },
    TargetTagsTypeList() {
      return TargetTagsTypeList;
    },
    TargetTagsModeList() {
      return TargetTagsModeList;
    },
    isCampaignTypeMessage() {
      return this.campaign.rs1.campaignType === 'message';
    },
    displayPeriodEndText() {
      const campaign = this.campaign;
      const issetDisplayPeriodEnd =
        this.campaign.rs5.schedule?.issetDisplayPeriodEnd || false;
      const isCampaignTypeMessage = this.isCampaignTypeMessage;

      if (isCampaignTypeMessage) {
        return campaign.rs5.schedule.displayPeriodEnd;
      } else {
        return issetDisplayPeriodEnd
          ? campaign.rs5.schedule.displayPeriodEnd
          : '설정안함';
      }
    }
  },
  data() {
    return {
      reviewDialog: false,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      closeDialog: 'campaign/closeDialog'
    }),
    async createCampaign() {
      this.loading = true;

      const { id, rs1, rs2, rs3, rs4, rs5 } = this.campaign;
      const { user } = rs1;
      const { push, rolling } = rs2;
      const { schedule, budget } = rs5;

      push.messageDate = schedule?.messageDate || null;
      push.messageTime = schedule?.messageTime || null;

      // 메시지 타입인 경우 무조건 true 처리
      const issetDisplayPeriodEnd = this.isCampaignTypeMessage
        ? true
        : schedule?.issetDisplayPeriodEnd || false;

      // 광고 종료일 설정 유무에 따라 지정날짜로 변경 ( 메시지 타입 제외 )

      const displayPeriodEnd = this.isCampaignTypeMessage
        ? schedule.displayPeriodEnd
        : issetDisplayPeriodEnd
        ? schedule.displayPeriodEnd
        : '9999-12-31';

      const campaign = {
        id: id,
        mediaGroupIds: user.mediaGroups.map(v => v.id),
        advertiserId: user.advertiser.id,
        advertiserGroupId: user.advertiserGroup.id,
        trackerId: user.tracker?.id || null,
        campaignName: rs1.campaignName,
        campaignType: rs1.campaignType,
        landingUrl: rs1.landingUrl,
        push: push,
        rolling: rolling,
        impressionTime: rs4.impressionTime,
        impressionTimeType: rs4.impressionTimeType,
        impressionFrequency: rs4.impressionFrequency,
        adsGoalCount: rs4.adsGoalCount,
        adsLimitInterval: rs4.adsLimitInterval,
        displayPeriodStart: schedule.displayPeriodStart,
        displayPeriodEnd: displayPeriodEnd,
        messageDate: push.messageDate,
        messageTime: push.messageTime,
        issetDisplayPeriodEnd: issetDisplayPeriodEnd,
        isTagFilter: rs3.isTagFilter,
        tagIds: rs3.tags.map(v => v.id),
        targetTagIds: rs3.isTagFilter ? rs3.targetTags.map(v => v.id) : [],
        targetTagsMode: rs3.isTagFilter ? rs3.targetTagsMode : null,
        targetTagsType: rs3.isTagFilter ? rs3.targetTagsType : null,
        targetTagsRange: rs3.isTagFilter ? rs3.targetTagsRange : null,
        targetTagsCount: rs3.isTagFilter ? rs3.targetTagsCount : null,
        targetTagsClick: rs3.isTagFilter ? rs3.targetTagsClick : null,
        targetApps: rs3.apps,
        targetAppsType: rs3.appsType,
        targetLocations: rs3.locations,
        dailyAdvertisingBudget:
          this.isCampaignTypeMessage === true
            ? 0
            : Number(budget.dailyAdvertisingBudget),
        totalAdvertisingBudget:
          this.isCampaignTypeMessage === true
            ? 0
            : Number(budget.totalAdvertisingBudget),
        cpc: this.isCampaignTypeMessage === true ? 0 : Number(budget.cpc),
        cpm: this.isCampaignTypeMessage === true ? Number(budget.cpm) : 0,
        forecastReachCount: budget.forecastReachCount
      };

      if (this.isDialogTypeRegister) {
        // 캠페인 등록
        await this.$axios
          .post('/admin/campaigns', campaign)
          .then(() => {
            // console.log(res);
            this.reviewDialog = false;
            this.$root.$emit('getCampaignsApiEvent');
            this.closeDialog();
          })
          .catch(err => {
            this.loading = false;
            this.$axiosErrorAlert();
            console.error(err);
          });
      } else {
        // 캠페인 수정
        await this.$axios
          .put('/admin/campaigns', campaign)
          .then(() => {
            // console.log(res);
            this.reviewDialog = false;
            this.$root.$emit('getCampaignsApiEvent');
            this.closeDialog();
          })
          .catch(err => {
            this.loading = false;
            this.$axiosErrorAlert();
            console.error(err);
          });
      }
    }
  }
};
</script>
