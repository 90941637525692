import menus from '@/store/advertiser/menus';
import campaign from './campaign';
import user from './user';
import dialog from './dialog';
import config from './config';
import point from './point';
import memberPaymentPointCharge from '@/store/advertiser/member/payment/point-charge';
import memberPaymentPointUsage from '@/store/advertiser/member/payment/point-usage';
import memberPaymentPointOrder from '@/store/advertiser/member/payment/point-order';
import memberPaymentPointDetail from '@/store/advertiser/member/payment/point-detail';

export default {
  menus,
  campaign,
  user,
  dialog,
  config,
  point,
  memberPaymentPointCharge,
  memberPaymentPointUsage,
  memberPaymentPointOrder,
  memberPaymentPointDetail,
};
