<template>
  <v-container fluid>
    <v-row>
      <v-col class="mx-auto" cols="12" md="6" sm="12">
        <validation-observer ref="tagDialogContent" v-slot="{ invalid }">
          <form @submit.prevent="submit" class="p-5">
            <v-card style="padding:30px">
              <h2 class="font-weight-bold py-2">기본 설정</h2>
              <v-row v-for="(tag, index) in tags" :key="index">
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="label.name"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="tag.name"
                      :counter="20"
                      :error-messages="errors"
                      :label="label.name"
                      required
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" lg="3">
                  <div class="d-inline-flex align-center">
                    <validation-provider v-slot="{ errors }" rules="required">
                      <v-select
                        v-model="tag.tagTypeId"
                        :items="tagTypes"
                        item-text="text"
                        item-value="value"
                        label="태그 유형"
                        outlined
                        :error-messages="errors"
                        style="max-width:200px"
                      >
                      </v-select>
                    </validation-provider>
                    <v-tooltip bottom v-if="index > 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          class="pb-6"
                          @click="handleDeleteTagClick(index)"
                        >
                          <v-icon>mdi-minus-circle</v-icon></v-btn
                        >
                      </template>
                      <span> 삭제</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
              <v-btn text @click="handleAddTagClick()" v-if="dialog.register">
                <v-icon>mdi-playlist-plus</v-icon>입력 폼 추가
              </v-btn>
            </v-card>
            <v-btn
              v-if="dialog.register"
              :disabled="invalid"
              color="secondary"
              class="mt-4 mr-4"
              large
              :loading="loading"
              @click="handleRegisterClick()"
            >
              등록하기
            </v-btn>
            <v-btn
              v-else
              :disabled="invalid"
              color="secondary"
              class="mt-4 mr-4"
              large
              :loading="loading"
              @click="handleUpdateClick()"
            >
              수정하기
            </v-btn>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { createTagsApi, updateTagApi } from '@/api/admin/tag';

// import ImageFile from '@/components/ImageFile';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
    // ImageFile
  },
  data() {
    return {
      loading: false,
      inputLength: 2
    };
  },
  computed: {
    ...mapGetters({
      tags: 'tag/tags',
      placeholder: 'tag/placeholder',
      label: 'tag/label',
      dialog: 'tag/dialog',
      tagTypes: 'tag/types'
    }),
    isRegisterDialog() {
      return this.dialog.register === true;
    }
  },
  watch: {
    dialog: {
      deep: true,
      handler() {
        this.$refs.tagDialogContent.reset();
      }
    }
  },
  methods: {
    ...mapActions({
      addTag: 'tag/addTag',
      deleteTag: 'tag/deleteTag'
    }),
    async validateForm() {
      return await this.$refs.tagDialogContent.validate();
    },
    async handleRegisterClick() {
      this.loading = true;
      if (await this.validateForm()) {
        const params = {
          tags: this.tags
        };
        const [error, data] = await createTagsApi(params);
        if (error) {
          this.$axiosErrorAlert();
        } else {
          const { success, message } = data;

          if (success) {
            this.$router.go();
          } else {
            this.$axiosErrorAlert(message);
          }
        }
      }
      this.loading = false;
    },
    async handleUpdateClick() {
      this.loading = true;
      if (await this.validateForm()) {
        const params = this.tags[0];
        const [error] = await updateTagApi(params);
        if (error) {
          this.$axiosErrorAlert();
        } else {
          this.$router.go();
        }
      }
      this.loading = false;
    },
    handleAddTagClick() {
      this.addTag();
    },
    handleDeleteTagClick(index) {
      this.deleteTag(index);
    }
  }
};
</script>

<style></style>
