<template>
  <div v-if="authenticated">
    <v-navigation-drawer
      v-if="authenticated"
      v-model="drawer"
      app
      clipped
      :mini-variant="!$isMobile && !mini"
      mobile-breakpoint="600"
    >
      <v-list nav dense>
        <div v-for="(menu, mIndex) in menus" :key="mIndex">
          <v-list-group
            v-if="menu.items"
            v-model="menu.active"
            sub-group
            color="dark"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="menu.text"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(item, i) in menu.items"
              :key="i"
              link
              :to="item.link"
              exact
            >
              <v-list-item-icon>
                <v-tooltip right color="rgba(0,0,0,1)" :disabled="mini">
                  <template #activator="{ on: onTooltip }">
                    <v-icon v-on="{ ...onTooltip }">{{ item.icon }}</v-icon>
                  </template>
                  <span class="title">{{ item.text }}</span>
                </v-tooltip>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else link :to="menu.link" exact>
            <v-list-item-icon>
              <v-tooltip right color="rgba(0,0,0,1)" :disabled="mini">
                <template #activator="{ on: onTooltip }">
                  <v-icon v-on="{ ...onTooltip }">{{ menu.icon }}</v-icon>
                </template>
                <span class="title">{{ menu.text }}</span>
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="authenticated" app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="openNav()" />
      <v-toolbar-title>
        <v-img max-height="50" max-width="100" :src="$mainLogoPath"></v-img>
      </v-toolbar-title>

      <v-spacer />

      <v-menu
        v-model="admin_task_menu"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-badge
            :value="isTask"
            color="error"
            overlap
            dot
            offset-x="17"
            offset-y="17"
          >
            <v-btn icon v-on="on">
              <v-icon>mdi-clipboard-check-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <div style="height: 500px">
          <AdminTaskDialog
            v-if="admin_task_menu"
            @close="admin_task_menu = false"
            @refresh="fetchUnsignedTasks"
          />
        </div>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-avatar color="primary" size="48">
              <span class="white--text headline">
                {{ user.name.slice(0, 2) }}
              </span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title"
                >{{ user.name }} 님</v-list-item-title
              >
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item @click="logout" :disabled="loading">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>로그아웃</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view name="auth" />
    </v-main>

    <!-- <v-footer v-if="authenticated" app>
      <span>&copy;TDI 2021</span>
    </v-footer> -->

    <v-snackbar :value="errors" :timeout="2000">
      {{ errors }}
      <v-btn color="blue" text @click="errors = false"> 닫기 </v-btn>
    </v-snackbar>
    <UserIFrameDialog v-if="userIFrameDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserIFrameDialog from '@/components/UserIFrameDialog.vue';
import { AdminTaskDialog } from '@/components/common';
import { getUnsignedTaskApi, setFcmTokenApi } from '@/api/admin/TaskAPI';
// import Echo from 'laravel-echo';

export default {
  components: {
    UserIFrameDialog,
    AdminTaskDialog
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      errors: 'errors',
      menus: 'menus/menus',
      userIFrameDialog: 'dialog/userIFrameDialog'
    })
  },
  data: () => ({
    drawer: null,
    mini: true,
    items: [{ icon: 'mdi-view-dashboard', text: '대시보드', link: '/admin' }],
    breadcrumbs: [],
    loading: false,
    admin_task_menu: false,
    isTask: false,
    refreshTime: 5 * 60 * 1000
  }),
  async created() {
    const token = await this.$fcmMessage.getFcmToken();

    if (token) await setFcmTokenApi({ token: token, type: 'WEB' });

    const { fcmMessage } = this.$fcmMessage;

    fcmMessage.onMessage(payload => {
      // console.log('메세지 수신', payload);
      const { title, body } = payload.notification;
      const { type } = payload.data;
      if (type && type === 'adminTask') {
        if (!body) return;

        const notificationOptions = {
          body: body,
          icon: '/favicon.ico'
        };

        new Notification(title, notificationOptions);

        this.$notification['info']({
          key: 'notification-push',
          message: title,
          description: `${body ?? ''}`,
          duration: 0,
          style: {
            wordBreak: 'break-all'
          },
          onClick: () => {
            this.$notification.close('notification-push');
            this.admin_task_menu = true;
          }
        });
      }
    });

    // window.Pusher = require('pusher-js');
    // const {
    //   VUE_APP_PUSH_KEY,
    //   VUE_APP_PUSH_HOST,
    //   VUE_APP_PUSH_PORT,
    //   VUE_APP_PUSH_CLUSTER,
    //   VUE_APP_PUSH_TLS
    // } = process.env;

    // window.Echo = new Echo({
    //   broadcaster: 'pusher',
    //   key: VUE_APP_PUSH_KEY,
    //   wsHost: VUE_APP_PUSH_HOST,
    //   host: `${VUE_APP_PUSH_HOST}::${VUE_APP_PUSH_PORT}`,
    //   wsPort: VUE_APP_PUSH_PORT,
    //   cluster: VUE_APP_PUSH_CLUSTER,
    //   forceTLS: VUE_APP_PUSH_TLS ? JSON.parse(VUE_APP_PUSH_TLS) : false,
    //   encrypted: true
    // });

    // window.Echo.channel(`push-receiver-${this.user.token}`)
    //   // 이벤트 이름
    //   .listen('PusherReceiver', async res => {
    //     // console.log(res);
    //     const { message } = res;
    //     // 메시지가 없는 경우: 해당 테스크 할당시
    //     if (message) {
    //       this.$notification['info']({
    //         key: 'notification-push',
    //         message: '신규 태스크 등록',
    //         description: message ?? '',
    //         duration: 10,
    //         onClick: () => {
    //           this.$notification.close('notification-push');
    //           this.admin_task_menu = true;
    //         }
    //       });
    //     }

    //   //  소켓 이벤트 들어오면 조회
    //   await this.fetchUnsignedTasks();
    // });

    await this.fetchUnsignedTasks();

    // 5분마다 조회
    setInterval(async () => {
      this.fetchUnsignedTasks();
    }, this.refreshTime);
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),
    /**
     * @description: 태스크 목록 조회
     * */
    async fetchUnsignedTasks() {
      const [error, data] = await getUnsignedTaskApi();

      if (error) {
        console.error(error);
      } else {
        const taskCount = data.tasks?.length || 0;
        this.isTask = taskCount > 0;
      }
    },
    logout() {
      this.loading = true;
      // window.Echo.leave(`push-receiver-${this.user.token}`);
      this.logoutAction().then(() => {
        this.loading = false;
        this.$router.replace({
          name: 'login'
        });
      });
    },
    openNav() {
      this.drawer = !this.drawer;

      // // pc, 모바일 화면에 따른 nav 기능 을 지정합니다.
      // if (!this.$isMobile) {
      //   // pc 화면인경우 아이콘을 단축시킵니다.
      //   this.mini = !this.mini;
      // } else {
      //   // 모바일 화면인 경우 숨김 처리를 설정합니다.
      //   this.drawer = !this.drawer;
      // }
    }
  }
};
</script>
