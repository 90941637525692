<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      :rules="{
        required: true
      }"
    >
      <v-input
        type="hidden"
        v-model="rolling.imageId"
        :error-messages="errors"
      ></v-input>
      <image-upload
        :sImageId="rolling.imageId"
        @setImage="
          v => {
            rolling.imageId = v.id;
            rolling.imageUrl = v.url;
            rolling.imageType = v.type;
          }
        "
        campaignType="rolling"
        imageType="image"
        :title="titles.image"
      />
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import ImageUpload from '@/components/ImageUpload.vue';
export default {
  components: {
    ValidationProvider,
    ImageUpload
  },
  props: ['rolling'],
  data() {
    return {
      titles: {
        image: '광고 이미지',
        noImage: '선택 할 수 있는 이미지가 없습니다. 이미지를 업로드해주세요.'
      }
    };
  }
};
</script>
