export default {
    namespaced: true,

    state: {
        network: null,
        dialog: false,
        dialogType: null,

    },
    getters: {
        network(state) {
            return state.network
        },
        dialog(state) {
            return state.dialog
        },
        dialogType(state) {
            return state.dialogType
        },
        isDialogTypeRegister(state) {
            return state.dialogType === 'R'
        },
        isDialogTypeEdit(state) {
            return state.dialogType === 'E'
        },
    },
    mutations: {
        SET_Network(state, network) {
            state.network = network
        },
        SET_Dialog(state, dialog) {
            state.dialog = dialog
        },
        SET_DialogType(state, dialogType) {
            state.dialogType = dialogType
        }
    },
    actions: {
        initNetwork({
            commit
        }) {
            const data = {
                type: null,
                name: null,
                uid: null,
                impBidFloor: null,
                blockCategory: null,
                blockAdvertiser: null,
                appId: null,
                appName: null,
                appBundle: null,
                ctr: null,
                mediaId: null,
            }

            commit('SET_Network', data);
        },
        openDialog({
            commit
        }) {
            commit('SET_Dialog', true)
        },
        closeDialog({
            commit,
            dispatch
        }) {
            commit('SET_Dialog', false);
            dispatch('initNetwork');
        },
        setDialogTypeRegister({
            commit
        }) {
            commit('SET_DialogType', 'R')
        },
        setDialogTypeEdit({
            commit
        }) {
            commit('SET_DialogType', 'E')
        },
        setNetwork({
            commit
        }, network) {
            // console.log(network);

            const newItem = {
                id: network.id,
                type: network.type,
                name: network.name,
                uid: network.uid,
                impBidFloor: network.imp_bid_floor,
                blockCategory: network.block_category,
                blockAdvertiser: network.block_advertiser,
                appId: network.app_id,
                appName: network.app_name,
                appBundle: network.app_bundle,
                ctr: network.ctr,
                mediaId: network.media_id,
            };
            commit('SET_Network', newItem);
        },
    }
};