var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ReportFilter',{attrs:{"loading":_vm.loading},on:{"applyFilters":_vm.applyFilters}})],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.loading)?_c('MediaLiveChart',{attrs:{"title":"차트","showTitle":false,"dateRanges":_vm.dateRanges,"stats":_vm.chartStats,"loading":_vm.loading}}):_vm._e(),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"card"}})],1)],1),_c('v-row',{staticClass:"mb-5"},[_c('v-col',[_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Table',{staticClass:"px-3",staticStyle:{"white-space":"nowrap"},attrs:{"data-source":_vm.stats,"columns":_vm.columns,"rowClassName":function (record, index) {
                  if (index === 0) {
                    return 'table-summary-rows';
                  }
                },"rowKey":function (record, index) {
                  return ("" + (record.date));
                },"bordered":"","pagination":false,"loading":_vm.loading,"size":"small","locale":{
                emptyText: '데이터가 없습니다.'
              }},scopedSlots:_vm._u([_vm._l((_vm.dates),function(date,dateIndex){return {key:date.value,fn:function(data, record){return [_c('span',{key:("" + dateIndex),class:((_vm.getGapIcon(record, dateIndex).color) + "--text darken-3")},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(data.toLocaleString()))]),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-icon',{attrs:{"color":_vm.getGapIcon(record, dateIndex).color}},[_vm._v(_vm._s(_vm.getGapIcon(record, dateIndex).icon))]),_vm._v(" "+_vm._s(_vm.getGapIcon(record, dateIndex).percent)+" ")],1)])]}}}),{key:"localeString",fn:function(value){return [_vm._v(" "+_vm._s(value > 0 ? value.toLocaleString() : '-')+" ")]}},{key:"percent",fn:function(value){return [_vm._v(" "+_vm._s(value)+" % ")]}},{key:"money",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("KRW")(text))+" ")]}}],null,true)},_vm._l((_vm.dates),function(date,dateIndex){return _c('DayOfWeek',{key:dateIndex,attrs:{"slot":date.value,"date":date.value},slot:date.value})}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }