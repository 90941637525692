import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import { MediaGroupTypeList } from '@/config/media';
import { ActiveOptions } from '@/config/admin/campaign-did';

export class DidMediaGroup {
  origin = null;

  id = null;
  name = null;
  isActive = 0; // 광고 송출 상태
  userId = null;
  type = 'D'; // D: 직매체, A: 대행사

  activeUpdatedAt = null; // 승인일
  createdAt = null;

  user = {
    approveStatusFlag: 'W',
    uid: null, // 등록시 (ID)
    phone: null, // 등록시
    eamil: null, // 등록시
    password: null // 등록시
  };

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidMediaGroup(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (key === 'createdAt') {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }
      if (key === 'isActive') {
        val = val ? 1 : 0;
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidMediaGroup(this);
    }
  }
  get userEmail() {
    return this.user?.email;
  }
  get userUid() {
    return this.user?.uid;
  }
  /** @description: 회원 설정 정보  */
  get userApproveStatus() {
    return this.user?.approveStatusFlag === 'Y' ? 1 : 0;
  }
  /** @description: 회원 설정 정보  */
  set userApproveStatus(val) {
    this.user.approveStatusFlag = val ? 'Y' : 'W';
  }
  /** @description: 회원 설정 정보 Object */
  get userApproveStatusInfo() {
    let userApproveStatus = [
      {
        text: '활성',
        value: 'Y',
        color: 'green'
      },
      {
        text: '비활성',
        value: 'W',
        color: 'red'
      }
    ];
    return userApproveStatus.find(
      item => item.value === this.user?.approveStatusFlag
    );
  }
  /** @description: 타입 정보  */
  get typeInfo() {
    return MediaGroupTypeList.find(item => item.value === this.type);
  }
  /** @description: 광고 송출 상태  */
  get isActiveInfo() {
    return ActiveOptions.find(item => item.value === this.isActive);
  }
  get didBranchCount() {
    return this.branches?.length ?? 0;
  }
  get didSettopBoxCount() {
    let cnt = 0;
    if (this.branches) {
      this.branches.map(item => (cnt += item.settop_boxes?.length ?? 0));
    }
    return cnt;
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }

  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.getUpdatePayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.id
      ? this.origin.getUpdatePayload(true)
      : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  getCreatePayload() {
    // let changedValueKeys = [];
    // let res = {
    //   name: this.name,
    //   userId: this.userId,
    //   type: this.type
    // };
    // // 값 체크 용도
    // if (isChecked) return res;
    // changedValueKeys = Object.keys(res);
    // const changedValues = this.changedValues(changedValueKeys, res);

    return {
      name: this.name,
      userId: this.userId,
      type: this.type,
      isActive: this.isActive,
      approve: this.user.approveStatusFlag,
      uid: this.user.uid,
      phone: this.user.phone,
      email: this.user.email,
      password: this.user.password
    };
  }

  getUpdatePayload() {
    // let changedValueKeys = [];
    // let res = {
    //   id: this.id,
    //   name: this.name,
    //   userId: this.userId,
    //   type: this.type
    // };
    // // 값 체크 용도
    // if (isChecked) return res;
    // changedValueKeys = Object.keys(res);
    // const changedValues = this.changedValues(changedValueKeys, res);

    return {
      id: this.id,
      name: this.name,
      userId: this.userId,
      type: this.type
    };
  }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = changedObj[key] === val;

      if (typeof changedObj[key] === 'object' || typeof val === 'object') {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
