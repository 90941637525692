import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import {
  BranchCategories,
  CompanyCodes,
  Week
} from '@/config/admin/campaign-did';

export class DidBranch {
  origin = null;
  id = null;
  name = null;
  regionDepth1Id = null; // 지역 코드1
  regionDepth2Id = null; // 지역 코드2
  category = null; // ex) 영화관(cinema),
  companyCode = null; // 상호 코드 ex) 메가박스(megabox)
  userId = null; // 타입이 매체사인 회원 Id(정산 받을)
  didMediaGroupId = null; // 매체그룹 ID
  scheduleDayIds = []; // 운영 요일  0: 일, 1: 월, 2: 화. 3: 수, 4: 목, 5: 금, 6: 토
  scheduleOpenAt = null; // 운영 시작 시간
  scheduleCloseAt = null; // 운영 종료 시간
  isActive = 0; // 활성 상태
  didMediaGroup = null; // 매체사그룹 정보

  campaignResourceIds = []; // 매체사 기본 영상 목록
  campaignResources = []; // 매체사 기본 영상 목록

  profitRatio = null; // 대행사 단일 매출 비율
  agencyProfitRatio = null; // 대행사 매출 비율
  directProfitRatio = null; // 직매체 매출 비율

  //  front에 필요한 정보
  categoryText = null;
  companyCodeText = null;

  //TODO: 매체타입이 대행사인경우 로그인 정보 입력받아서 회원 계정 생성
  type = null; // 대행사: A, 직매체: D
  user = {
    uid: null,
    email: null,
    password: null,
    phone: null
  };

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidBranch(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];
      if (key === 'category') {
        this.categoryText = BranchCategories.find(
          category => category.value === val
        )?.text;
      }
      if (key === 'companyCode') {
        this.companyCodeText = CompanyCodes.find(
          companyCode => companyCode.value === val
        )?.text;
      }
      if (key === 'createdAt') {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }
      if (key === 'isActive') {
        val = val ? 1 : 0;
      }
      if (key === 'campaignResources') {
        this.campaignResourceIds = val.map(item => item.id);
      }
      if (['scheduleOpenAt', 'scheduleCloseAt'].includes(key)) {
        val = val ? moment(val, 'HH:mm:ss').format('HH:mm') : null;
      }
      if (key === 'user') {
        // 보기용
        this.userEmail = val?.email;
        this.userPhone = val?.phone;
        this.userUid = val?.uid;
      } else {
        this[key] = val;
      }
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidBranch(this);
    }
  }

  /** @description: 운영 요일  */
  get scheduleDayIdsText() {
    let daysText = this.scheduleDayIds.map(
      item => Week.find(day => day.value === item)?.text
    );
    return daysText?.join(', ');
  }

  /** @description: 운영 시간  */
  get businessTimeText() {
    return this.scheduleOpenAt && this.scheduleCloseAt
      ? `${moment(this.scheduleOpenAt, 'HH:mm:ss').format('HH:mm')} 
        ~
         ${moment(this.scheduleCloseAt, 'HH:mm:ss').format('HH:mm')}`
      : '';
  }
  get didMediaGroupName() {
    return this.didMediaGroup ? this.didMediaGroup.name : '';
  }
  get defaultResourceCount() {
    return this.campaignResourceIds ? this.campaignResourceIds.length : 0;
  }
  // 직매체인지 여부
  get isTypeDirect() {
    return this.type === 'D';
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }

  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.getUpdatePayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);
    const origin = this.id
      ? this.origin?.getUpdatePayload(true)
      : this.origin?.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  getCreatePayload() {
    // let changedValueKeys = [];
    let res = {
      name: this.name,
      didMediaGroupId: this.didMediaGroupId,
      regionDepth1Id: this.regionDepth1Id,
      regionDepth2Id: this.regionDepth2Id,
      category: this.category,
      companyCode: this.companyCode,
      scheduleDayIds: this.scheduleDayIds,
      scheduleOpenAt: this.scheduleOpenAt,
      scheduleCloseAt: this.scheduleCloseAt,
      campaignResourceIds: this.campaignResourceIds,
      type: this.type,
      directProfitRatio: this.directProfitRatio
    };

    if (!this.isTypeDirect) {
      // 대행사일경우
      res = {
        ...res,
        uid: this.user?.uid,
        email: this.user?.email,
        password: this.user?.password,
        phone: this.user?.phone,
        profitRatio: this.profitRatio,
        agencyProfitRatio: this.agencyProfitRatio
      };
    }

    // // 값 체크 용도
    // if (isChecked) return res;

    // changedValueKeys = Object.keys(res);
    // const changedValues = this.changedValues(changedValueKeys, res);

    // return {
    //   ...res,
    //   changedValues: changedValues
    // };
    return res;
  }

  getUpdatePayload() {
    // let changedValueKeys = [];
    let res = {
      id: this.id,
      name: this.name,
      didMediaGroupId: this.didMediaGroupId,
      userId: this.userId,
      regionDepth1Id: this.regionDepth1Id,
      regionDepth2Id: this.regionDepth2Id,
      category: this.category,
      companyCode: this.companyCode,
      scheduleDayIds: this.scheduleDayIds,
      scheduleOpenAt: this.scheduleOpenAt,
      scheduleCloseAt: this.scheduleCloseAt,
      campaignResourceIds: this.campaignResourceIds,
      type: this.type,
      profitRatio: this.profitRatio,
      agencyProfitRatio: this.agencyProfitRatio,
      directProfitRatio: this.directProfitRatio
    };

    // 값 체크 용도
    // if (isChecked) return res;

    // changedValueKeys = Object.keys(res);
    // const changedValues = this.changedValues(changedValueKeys, res);

    return {
      ...res
      // changedValues: changedValues
    };
  }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.origin.getUpdatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = changedObj[key] === val;

      if (typeof changedObj[key] === 'object' || typeof val === 'object') {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
