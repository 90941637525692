var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"depressed":"","dark":""},on:{"click":function($event){return _vm.openDialog({ record: {}, type: 'store' })}}},[_vm._v(" 매체그룹 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('media-group-filters',{attrs:{"filters":_vm.filters,"loading":_vm.loading},on:{"search":_vm.getMediaGroups}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.loading,"size":"small","columns":_vm.columns,"bordered":"","data-source":_vm.mediaGroups,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.openDialog({ record: record, type: 'detail' });
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(value, record, index){return [_vm._v(" "+_vm._s(_vm.pagination.total - (_vm.pagination.perPage * _vm.pagination.page - _vm.pagination.perPage) - index)+" ")]}},{key:"active",fn:function(value, record){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"info","inset":"","readonly":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateActive(record)}},model:{value:(record.active.value),callback:function ($$v) {_vm.$set(record.active, "value", $$v)},expression:"record.active.value"}})],1)]}},{key:"type",fn:function(value, record){return [_c('a-tag',{attrs:{"small":"","color":record.type.color}},[_vm._v(" "+_vm._s(record.type.text)+" ")])]}},{key:"actions",fn:function(value, record){return [_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleUserIFrameClick(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("사용자 화면")])])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":_vm.pagination.perPage,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),_c('media-group-store-dialog',{on:{"refresh":_vm.getMediaGroups}}),_c('media-group-detail-dialog',{on:{"refresh":_vm.getMediaGroups}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }