<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn depressed dark @click="openDialog()"> 매체 등록 </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <DidBranchFilters
            :filters="filters"
            :loading="indexLoading"
            @search="fetchDidBranches"
          />

          <a-table
            :loading="indexLoading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            rowKey="id"
            :columns="columns"
            bordered
            :data-source="didBranches"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleRowClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="regionDepth1Id" slot-scope="value">
              {{ getRegionText(regionDepth1Id, value) }}
            </template>

            <template slot="regionDepth2Id" slot-scope="value">
              {{ getRegionText(regionDepth2Id, value) }}
            </template>

            <template slot="isActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isActive"
                  color="success"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleActiveSwitch(record)"
                />
              </div>
            </template>

            <template slot="selectCount" slot-scope="value">
              <v-tooltip right v-if="value.length" maxWidth="250">
                <template v-slot:activator="{ on: tooltipOn }">
                  <div v-on="{ ...tooltipOn }" class="text-right">
                    {{ value.length.toLocaleString() }} 대
                  </div>
                </template>
                <span class="text-subtitle-2">
                  <v-chip
                    v-for="(item, index) in value"
                    :key="index"
                    label
                    small
                    class="ma-1"
                    color="white"
                  >
                    {{ item.name }}
                  </v-chip>
                </span>
              </v-tooltip>
              <div v-else class="text-right">0 대</div>
            </template>

            <template slot="syncAction" slot-scope="value, record">
              <div class="d-flex align-center justify-center">
                <v-tooltip bottom color="rgba(0,0,0,1)">
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn
                      v-on="{ ...tooltipOn }"
                      small
                      color="secondary"
                      @click.stop="handleSyncClick(record)"
                    >
                      동기화
                    </v-btn>
                  </template>
                  <span class="title">매체 재생시간 동기화</span>
                </v-tooltip>
              </div>
            </template>

            <template slot="actions" slot-scope="value, record">
              <v-tooltip bottom color="rgba(0,0,0,1)">
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn
                    v-on="{ ...tooltipOn }"
                    type="primary"
                    small
                    icon
                    @click.stop="handleShowScheduleClick(record)"
                  >
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-btn>
                </template>
                <span class="title">매체 캠페인 스케줄</span>
              </v-tooltip>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalCount"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>
    <DidScheduleDialog
      v-if="scheduleDialog"
      :scheduleDialog="scheduleDialog"
      :loading="loading.dialog"
      :columns="scheduleColumns"
      :title="'매체'"
      :name="selectBranchName"
      :tableData="scheduleTableData"
      @fetchData="date => fetchDidSchedules(selectBranchId, date)"
      @close="handleCloseClick"
    />
    <DidBranchDialog v-if="dialog" @refresh="fetchDidBranches" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import DidBranchDialog from './components/dialog/DidBranchDialog';
import DidScheduleDialog from '@/components/did/DidScheduleDialog';
import DidBranchFilters from './components/AdminDidBranchFilters';
import { DidBranch, DidSettopBox } from '@/model/admin/did';
import { getDidBranchScheduleApi } from '@/api/admin/DidCampaignAPI';
import {
  getDidBranchesApi,
  getDidBranchApi,
  updateDidBranchActiveApi,
  updateDidBranchInactiveApi,
  playSyncByBranchApi
} from '@/api/admin/DidBranchAPI';
import { Pagination } from '@/model/Pagination';
export default {
  components: {
    MenuTitle,
    DidBranchDialog,
    DidScheduleDialog,
    DidBranchFilters
  },
  data() {
    return {
      didBranches: [],
      pagination: new Pagination(1, 20),
      selectBranchId: '',
      selectBranchName: '',
      scheduleColumns: [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '셋톱박스',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '캠페인 수',
          align: 'center',
          dataIndex: 'didCampaigns',
          scopedSlots: {
            customRender: 'didCampaigns'
          }
        }
      ],
      scheduleTableData: []
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didBranch/dialog',
      scheduleDialog: 'didBranch/scheduleDialog',
      filters: 'didBranch/filters',
      loading: 'didBranch/loading',
      regionDepth1Id: 'didBranch/regionDepth1Id',
      regionDepth2Id: 'didBranch/regionDepth2Id'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '매체사그룹',
          align: 'center',
          dataIndex: 'didMediaGroupName'
        },
        {
          title: '매체명',
          align: 'center',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '활성여부',
          align: 'center',
          dataIndex: 'isActive',
          scopedSlots: {
            customRender: 'isActive'
          }
        },
        {
          title: '재생동기화',
          align: 'center',
          dataIndex: 'syncAction',
          scopedSlots: {
            customRender: 'syncAction'
          }
        },
        {
          title: '카테고리',
          align: 'center',
          dataIndex: 'categoryText'
        },
        {
          title: '셋톱박스',
          align: 'center',
          dataIndex: 'settopBoxes',
          scopedSlots: {
            customRender: 'selectCount'
          }
        },
        {
          title: '상호',
          align: 'center',
          dataIndex: 'companyCodeText'
        },
        {
          title: '지역1',
          align: 'center',
          dataIndex: 'regionDepth1Id',
          scopedSlots: {
            customRender: 'regionDepth1Id'
          }
        },
        {
          title: '지역2',
          align: 'center',
          dataIndex: 'regionDepth2Id',
          scopedSlots: {
            customRender: 'regionDepth2Id'
          }
        },
        {
          title: '운영 요일',
          align: 'center',
          dataIndex: 'scheduleDayIdsText',
          scopedSlots: {
            customRender: 'scheduleDayIdsText'
          }
        },
        { title: '운영시간', align: 'center', dataIndex: 'businessTimeText' },
        { title: '등록일', align: 'center', dataIndex: 'createdDate' },
        {
          title: '기능',
          align: 'center',
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ];
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchDidBranches();
    }
  },
  mounted() {
    this.loading.index = false;

    if (!this.regionDepth1Id.length) {
      this.fetchRegionOptions();
    }

    this.fetchDidBranches();
  },
  methods: {
    ...mapActions({
      openDialog: 'didBranch/openDialog',
      closeDialog: 'didBranch/closeDialog',
      openScheduleDialog: 'didBranch/openScheduleDialog',
      fetchRegionOptions: 'didBranch/fetchRegionOptions'
    }),
    async fetchDidBranches() {
      if (this.indexLoading) return;

      this.loading.index = true;
      const { perPage } = this.pagination;

      const [error, data] = await getDidBranchesApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          filters: this.filters,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { branches, total_count } = data;

        this.didBranches = branches.map(branch => {
          return new DidBranch(branch);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading.index = false;
    },
    getRegionText(list, value) {
      if (!list.length) return '-';
      return list.find(item => item.id === value)?.name;
    },
    async handleRowClick(record) {
      if (!record) return;
      this.loading.index = true;

      const [error, data] = await getDidBranchApi(record.id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { branch } = data;
        this.openDialog(branch);
      }

      this.loading.index = false;
    },
    /** 매체 활성 상태 변경 */
    handleActiveSwitch(item) {
      const html =
        `<h3><b>"${item.name}"</b> <br> 매체 상태를 ${
          !item.isActive ? '활성' : '비활성'
        } (으)로 변경 하시겠습니까? </h3>` +
        `${
          item.isActive
            ? '<br><small>❗️해당 매체이 비활성화로 변경시,매체의 광고들도 비활성화 됩니다.</small>'
            : ''
        }`;

      this.$Swal
        .fire({
          icon: 'info',
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const [error, data] = await (item.isActive
              ? updateDidBranchInactiveApi(item.id)
              : updateDidBranchActiveApi(item.id));

            if (error) {
              console.error(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isActive = !item.isActive;
          }
        });
    },
    /** 매체 스케줄 정보 초기화 */
    handleCloseClick() {
      this.closeDialog('Schedule');

      this.selectBranchId = '';
      this.selectBranchName = '';
      this.scheduleTableData = [];
    },
    /** 매체 스케줄 다이얼로그  */
    handleShowScheduleClick(record) {
      if (!record) return;
      this.selectBranchId = record.id;
      this.selectBranchName = record.name;
      this.openScheduleDialog();
      this.fetchDidSchedules(record.id);
    },
    /** 매체  스케줄 목록 조회 */
    async fetchDidSchedules(branchId, dates) {
      const searchDate =
        !dates || !dates.length ? [this.$moment().format('YYYY-MM-DD')] : dates;

      this.loading.dialog = true;
      const [error, data] = await getDidBranchScheduleApi(branchId, {
        params: {
          dates: [searchDate]
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        this.scheduleTableData = data.schedules.map(
          item => new DidSettopBox(item)
        );
      }
      this.loading.dialog = false;
    },

    /**@description: 매체기준 캠페인 동기화 */
    async handleSyncClick(item) {
      if (!item) return;

      const html = `<h3>${item.name} 매체의 재생시간을<br/>를 동기화 하시겠습니까?</h3>`;
      this.$Swal.fire({
        html: html,
        confirmButtonText: '확인',
        showCancelButton: true,
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          this.loading.dialog = true;
          try {
            const [error, data] = await playSyncByBranchApi(item.id);
            const { success, message } = data;

            if (!error && success) {
              this.$Swal.fire({
                icon: 'success',
                title: '재생시간 동기화',
                html: `<h3>${message}</h3>`,
                confirmButtonText: '확인'
              });
            } else {
              console.error('셋톱재생시간 동기화 오류::', error);
              this.$axiosErrorAlert(
                '잠시 후 다시 시도해 주세요.(재생시간 동기화 실패)'
              );
            }
          } catch (error) {
            console.error('재생시간 동기화 오류::', error);
            this.$axiosErrorAlert(
              `${
                error.data?.message ?? '잠시 후 다시 시도해 주세요.'
              }(재생시간 동기화 실패)`
            );
          } finally {
            this.loading.dialog = false;
          }
        }
      });
    }
  }
};
</script>
