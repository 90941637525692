<template>
  <div class="filters">
    <a-descriptions bordered>
      <a-descriptions-item label="상태">
        <a-select
          :style="{ minWidth: '150px' }"
          v-model="statusFlag"
          mode="multiple"
        >
          <a-select-option
            v-for="(item, index) in statusFlagOptions"
            :key="index"
            :value="item.value"
          >
            <span :style="`color: ${item.color}`">{{ item.text }} </span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="사용일">
        <a-range-picker
          v-model="dates"
          :ranges="{
            오늘: [$moment(), $moment()]
          }"
          format="YYYY-MM-DD"
        />
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="search"
      >
        검색
      </v-btn>
    </div>

    <a-descriptions :style="{ marginTop: '3rem' }">
      <div slot="title">
        <span>결제내역 ( 충전/환불 )</span>
        <a-button
          size="small"
          class="ml-3"
          :loading="loading"
          @click="fetchPointOrders"
        >
          새로고침
        </a-button>
      </div>
    </a-descriptions>

    <div style="overflow: auto">
      <a-table
        class="advertiser-payment-table"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        bordered
        scrollToFirstRowOnChange
        size="small"
        :pagination="false"
        style="min-width: 850px; overflow: auto"
      >
        <span slot="no" slot-scope="text, record, index">
          {{
            pagination.total -
            (pagination.perPage * pagination.page - pagination.perPage) -
            index
          }}
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag :color="record.color">{{ text }}</a-tag>
        </span>

        <template slot="expandedRowRender" slot-scope="record">
          <div class="row justify-center pa-5" v-if="!record.comments.length">
            등록된 메모가 없습니다.
          </div>
          <div v-else style="margin: 0; max-height: 300px">
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="(comment, idx) in record.comments"
                :key="idx"
                :color="'blue'"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="auto">
                    <strong v-html="comment.createdAt" />
                  </v-col>
                  <v-col>
                    <v-chip label outlined small>
                      {{ comment.adminName }}
                    </v-chip>
                    <div
                      class="text-subTitle-2 mt-2"
                      style="max-width: 90%"
                      v-html="comment.text"
                    />
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </div>
        </template>
      </a-table>
    </div>

    <v-pagination
      v-model="pagination.page"
      :length="pagination.totalPage"
      :total-visible="pagination.perPage"
      class="mt-5"
      color="secondary"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getPointOrdersApi } from '@/api/admin/UserAPI';

export default {
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    }),
    totalPoint() {
      return Number(this.point.credit) + Number(this.point.free);
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      data: [],
      point: {
        credit: 0,
        free: 0
      },
      dates: [this.$moment().subtract(3, 'months'), this.$moment()],
      statusFlag: ['S', 'C', 'PC'],
      statusFlagOptions: [
        { text: '결제완료', value: 'S', color: 'green' },
        { text: '결제취소', value: 'C', color: 'red' },
        { text: '부분취소', value: 'PC', color: 'purple' }
      ],
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        total: 0
      },
      columns: [
        {
          title: '번호',
          dataIndex: 'no',
          key: 'no',
          scopedSlots: { customRender: 'no' },
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '결제번호',
          dataIndex: 'orderNo',
          key: 'orderNo',
          align: 'center',
          ellipsis: true
        },
        {
          title: '결제금액',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          ellipsis: true
        },

        {
          title: '결제방식',
          dataIndex: 'paymentMethod',
          key: 'paymentMethod',
          align: 'center',
          ellipsis: true
        },
        {
          title: '상태',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '결제일',
          dataIndex: 'orderAt',
          key: 'orderAt',
          align: 'center',
          ellipsis: true
        }
      ]
    };
  },
  watch: {
    'pagination.page'() {
      this.fetchPointOrders();
    },
    advertiser() {
      this.fetchPointOrders();
    }
  },
  mounted() {
    this.fetchPointOrders();
  },
  methods: {
    search() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      }
      this.fetchPointOrders();
    },
    async fetchPointOrders() {
      this.loading = true;
      this.data = [];

      const params = {
        params: {
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          statusFlag: this.statusFlag,
          dates: this.dates.map(date => {
            return date.format('YYYY-MM-DD');
          }),
          pagination: 'Y'
        }
      };

      const [error, data] = await getPointOrdersApi(
        params,
        this.advertiser.origin.user.id
      );
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const {
          point_orders: { total, last_page, data: items }
        } = data;

        this.pagination.total = total;
        this.pagination.totalPage = last_page;

        this.data = items.map((item, index) => {
          let paymentMethod = '';

          if (item.payment_method === 'creditcard') {
            paymentMethod = '카드결제';
          } else if (item.payment_method === 'banktransfer') {
            paymentMethod = '직접결제';
          }

          let status = '';
          let isPaymentSuccess = false;
          let color = '';

          if (item.status_flag === 'S') {
            status = '결제완료';
            isPaymentSuccess = true;
            color = 'green';
            // } else if (item.status_flag === 'F') {
            //   status = '결제실패';
            //   color = 'red';
          } else if (item.status_flag === 'C') {
            status = '결제취소';
            color = 'red';
          } else if (item.status_flag === 'PC') {
            status = '부분취소';
            color = 'purple';
          }
          const comments = item.comments?.map((comment, idx) => {
            return {
              key: idx,
              id: comment.id,
              createdAt: this.$moment(comment.created_at).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              text: comment.text.replace(/\n/gi, '<br/>'),
              adminName: comment.admin_name
            };
          });

          return {
            key: index,
            id: item.id,
            status: status,
            amount: (item.amount * 1).toLocaleString(),
            orderAt: this.$moment(item.order_at).format('YYYY-MM-DD HH:mm:ss'),
            remark: item.remark,
            orderNo: item.order_no,
            paymentMethod: paymentMethod,
            isPaymentSuccess: isPaymentSuccess,
            color: color,
            comments: comments || []
          };
        });
      }

      this.loading = false;
    }
  }
};
</script>
<style lang="scss">
.advertiser-payment-table {
  .ant-table-expanded-row > td {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
