import { DidSettopBox } from '@/model/admin/did';
import { getDidBranchOptionsApi } from '@/api/admin/DidBranchAPI';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      dialog: false
    },
    settopBox: new DidSettopBox(),
    dialog: false,
    scheduleDialog: false, // 스케줄 상세
    filters: {
      deviceConnectionStatus: ['Y', 'N', 'UNKNOWN'],
      devicePlayStatus: ['Y', 'N', 'UNKNOWN'],
      status: ['Y', 'N'],
      didBranchId: '',
      searchText: '',
      searchKey: 'name'
    },
    didBranches: []
  },
  getters: {
    settopBox(state) {
      return state.settopBox;
    },
    dialog(state) {
      return state.dialog;
    },
    scheduleDialog(state) {
      return state.scheduleDialog;
    },
    loading(state) {
      return state.loading;
    },
    filters(state) {
      return state.filters;
    },
    filterParams(state) {
      let params = {
        deviceConnectionStatus: state.filters.deviceConnectionStatus,
        devicePlayStatus: state.filters.devicePlayStatus,
        status: state.filters.status,
        didBranchId: state.filters.didBranchId
      };

      params[`${state.filters.searchKey}`] = state.filters.searchText;

      return params;
    },
    didBranches(state) {
      return state.didBranches;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_ScheduleDialog(state, dialog) {
      state.scheduleDialog = dialog;
    },
    SET_SettopBox(state, settopBox) {
      state.settopBox = new DidSettopBox(settopBox, true);
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_DidBranches(state, branches = []) {
      state.didBranches = branches;
    },
    SET_Filters(state) {
      state.filters = {
        deviceConnectionStatus: ['Y', 'N', 'UNKNOWN'],
        devicePlayStatus: ['Y', 'N', 'UNKNOWN'],
        status: ['Y', 'N'],
        didBranchId: '',
        searchText: '',
        searchKey: 'name'
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    async openDialog({ commit }, settopBox = undefined) {
      commit('SET_SettopBox', new DidSettopBox(undefined, true));

      if (settopBox) {
        commit('SET_SettopBox', settopBox);
      }

      commit('SET_Dialog', true);
    },
    async openScheduleDialog({ commit }) {
      commit('SET_ScheduleDialog', true);
    },
    closeDialog({ commit }, dialogType = '') {
      commit(`SET_${dialogType}Dialog`, false);
    },
    /** 매체 콤보 박스 목록 조회 */
    async fetchDidBranchOptions({ commit }) {
      let options = [];
      const [error, data] = await getDidBranchOptionsApi();
      if (error) {
        console.log(error);
      } else {
        const { branches } = data;
        options = branches;
      }

      commit('SET_DidBranches', options);
    }
  }
};
