var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',{attrs:{"id":"admin-promotion-inquiry"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('a-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('AdminPromotionInquiryFilters',{attrs:{"loading":_vm.loading,"statusList":_vm.promotionInquiryStatus},on:{"search":function (filters) { return _vm.fetchPromotionInqury(1, filters); }}})],1),_c('v-col',{staticClass:"text-right pb-6",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.selectedIds.length,"outlined":""},on:{"click":_vm.handleAdvertiserSync}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"large"}},[_vm._v(" mdi-sync ")]),_vm._v(" 동기화 ")],1),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"secondary"},on:{"click":_vm.excelExport}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"large"}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Excel ")],1)],1)],1),_c('a-table',{staticClass:"mb-5 inquiry-table",attrs:{"columns":_vm.filteredColumns,"data-source":_vm.promotionInquiries,"loading":_vm.loading,"bordered":"","scroll":{ x: true },"scrollToFirstRowOnChange":"","size":"small","pagination":false,"rowKey":"id","row-selection":{
            selectedRowKeys: _vm.selectedIds,
            onChange: _vm.onSelectChange
          },"locale":{
            emptyText: '등록된 내역이 없습니다'
          },"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleDetailClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(text, record, index){return _c('span',{},[_vm._v(" "+_vm._s(index + 1)+" ")])}},{key:"name",fn:function(value, record){return [(record.advertiserId)?_c('v-btn',{staticClass:"text-body font-weight-regular row align-center ma-0",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openAdvertiserDialog({
                  record: {
                    advertiserId: record.advertiserId
                  },
                  type: 'detail'
                })}}},[_vm._v(" "+_vm._s(value)+" "),_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"color":"grey darken-3"}},[_vm._v(" mdi-application-edit-outline ")])],1):_c('v-col',[_vm._v(" "+_vm._s(value))])]}},{key:"createdAt",fn:function(value){return _c('span',{},[_vm._v(" "+_vm._s(_vm.$moment(value).format('YYYY-MM-DD HH:mm'))+" ")])}},{key:"statusInfo",fn:function(text, record){return _c('span',{},[_c('v-chip',{attrs:{"color":record.statusInfo.color,"outlined":"","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.handleStatusClick(record); })($event)}}},[_vm._v(" "+_vm._s(record.statusInfo.text)+" "),_c('v-avatar',{staticClass:"ml-0",attrs:{"right":""}},[_c('span',[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,0.8)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":"","color":record.statusInfo.color}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("상태 변경")])])],1)])],1)],1)}}])})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalPage > 1)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":10,"color":"secondary"},on:{"input":function($event){return _vm.fetchPromotionInqury()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1),(_vm.isPromotionDialog && _vm.selectRecord)?_c('PromotionDialog',{attrs:{"record":_vm.selectRecord,"dialog":_vm.isPromotionDialog},on:{"index":function($event){return _vm.fetchPromotionInqury(_vm.pagination.page)},"close":_vm.handleCloseDialog}}):_vm._e(),(_vm.isStatusDialog && _vm.selectRecord)?_c('PromotionStatusDialog',{attrs:{"record":_vm.selectRecord,"dialog":_vm.isStatusDialog,"statusList":_vm.promotionInquiryStatus},on:{"index":function($event){return _vm.fetchPromotionInqury(_vm.pagination.page)},"close":_vm.handleCloseDialog}}):_vm._e(),(_vm.advertiserDialog)?_c('AdvertiserDetailDialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }