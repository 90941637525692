import menus from '@/store/partner/menus';
import user from './user';
import dialog from './dialog';
import config from './config';
import point from './point';
import memberPaymentPointUsage from '@/store/partner/member/payment/point-usage';
import memberPaymentPointOrder from '@/store/partner/member/payment/point-order';
import memberPaymentPointDetail from '@/store/partner/member/payment/point-detail';

export default {
  menus,
  user,
  dialog,
  config,
  point,
  memberPaymentPointUsage,
  memberPaymentPointOrder,
  memberPaymentPointDetail
};
