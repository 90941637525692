var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"filters mt-5 mb-8"},[_c('a-descriptions',{attrs:{"bordered":""}},[_c('a-descriptions-item',{attrs:{"label":"상태"}},[_c('a-select',{style:({ minWidth: '200px' }),model:{value:(_vm.filters.statusFlag),callback:function ($$v) {_vm.$set(_vm.filters, "statusFlag", $$v)},expression:"filters.statusFlag"}},[_c('a-select-option',{attrs:{"value":""}},[_c('a-tag',[_vm._v(" 전체 ")])],1),_vm._l((_vm.paymentStatusList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_c('a-tag',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)})],2)],1),_c('a-descriptions-item',{attrs:{"label":"결제 방식","span":2}},[_c('a-select',{style:({ minWidth: '200px' }),model:{value:(_vm.filters.paymentMethod),callback:function ($$v) {_vm.$set(_vm.filters, "paymentMethod", $$v)},expression:"filters.paymentMethod"}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v(" 전체 ")]),_c('a-select-option',{attrs:{"value":"creditcard"}},[_vm._v(" 카드결제 ")]),_c('a-select-option',{attrs:{"value":"banktransfer"}},[_vm._v(" 직접입금 ")])],1)],1),_c('a-descriptions-item',{attrs:{"label":"등록일"}},[_c('a-range-picker',{attrs:{"ranges":{
                  오늘: [_vm.$moment(), _vm.$moment()]
                },"format":"YYYY-MM-DD"},model:{value:(_vm.filters.dates),callback:function ($$v) {_vm.$set(_vm.filters, "dates", $$v)},expression:"filters.dates"}})],1),_c('a-descriptions-item',{attrs:{"span":2}},[_c('template',{slot:"label"},[_vm._v("주문자 정보")]),_c('a-input-group',{attrs:{"compact":""}},[_c('a-select',{staticStyle:{"width":"200px"},model:{value:(_vm.filters.searchType),callback:function ($$v) {_vm.$set(_vm.filters, "searchType", $$v)},expression:"filters.searchType"}},[_c('a-select-option',{attrs:{"value":"order_no"}},[_vm._v(" 주문번호 ")]),_c('a-select-option',{attrs:{"value":"uid"}},[_vm._v(" 주문자 ID ")]),_c('a-select-option',{attrs:{"value":"name"}},[_vm._v(" 주문자 이름 ")]),_c('a-select-option',{attrs:{"value":"phone"}},[_vm._v(" 주문자 연락처 ")])],1),_c('a-input-search',{staticStyle:{"width":"calc(100% - 200px)","max-width":"1000px"},on:{"search":function($event){return _vm.fetchPointOrders(1)}},model:{value:(_vm.filters.searchString),callback:function ($$v) {_vm.$set(_vm.filters, "searchString", $$v)},expression:"filters.searchString"}})],1)],2)],1),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#00d1b2","depressed":"","loading":_vm.loading},on:{"click":function($event){return _vm.fetchPointOrders(1)}}},[_vm._v(" 검색 ")])],1)],1),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-descriptions',{attrs:{"layout":"vertical","bordered":""}},[_c('a-descriptions-item',{attrs:{"label":"조회 건수"}},[_vm._v(" "+_vm._s((_vm.summary.count * 1).toLocaleString())+" 건 ")]),_c('a-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" 승인 금액 합계 "),_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onTooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":""}},Object.assign({}, onTooltip)),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',{staticClass:"text-subTitle-1"},[_vm._v(" 승인 금액의 합계입니다. (VAT 포함) ")])])],1),_c('b',{staticClass:"mr-1 ext-subTitle-1"},[_vm._v(" "+_vm._s((_vm.summary.amount * 1).toLocaleString())+" 원 ")])],2),_vm._l((_vm.summary.status_amount),function(status,index){return _c('a-descriptions-item',{key:index,attrs:{"label":((_vm.flagFormat(status.status_flag, true)) + " 합계")}},[_vm._v(" "+_vm._s((status.sum_amount * 1).toLocaleString())+" 원 ")])}),_c('a-descriptions-item',{attrs:{"label":"직접입금 미처리건"}},[_vm._v(" "+_vm._s((_vm.summary.depositWaitCount * 1).toLocaleString())+" 건 ")])],2)],1)],1)],1),_c('Table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"size":"small","columns":_vm.columns,"bordered":"","data-source":_vm.pointOrders,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.openDialog(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"statusFlag",fn:function(value, record){return [_c('v-chip',{attrs:{"color":record.statusColor,"outlined":"","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.isValidStatus(record)
                    ? _vm.handlePaymentCancelClick(record)
                    : ''; })($event)}}},[_vm._v(" "+_vm._s(record.statusText)+" "),(_vm.isValidStatus(record))?_c('v-avatar',{staticClass:"ml-0",attrs:{"right":""}},[_c('span',[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,0.8)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":"","color":record.statusColor}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("결제 취소 요청")])])],1)]):_vm._e()],1)]}},{key:"userName",fn:function(value, record){return [_c('v-col',{staticClass:"py-0"},[_vm._v(" "+_vm._s(value)+" "),(record.advertiserId)?_c('v-btn',{staticClass:"text-body font-weight-regular align-center ma-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openAdvertiserDialog({
                    record: {
                      advertiserId: record.advertiserId
                    },
                    type: 'detail'
                  })}}},[_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"color":"grey darken-3"}},[_vm._v(" mdi-application-edit-outline ")])],1):_vm._e()],1)]}},{key:"advertiserType",fn:function(value){return [_c('div',{staticClass:"text-center px-2"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"amount",fn:function(value, record){return [_c('span',{style:(("" + (record.status === 'C' ? ("color: " + (record.statusColor)) : ''))),attrs:{"small":""}},[_vm._v(" "+_vm._s((value * 1).toLocaleString())+"원 ")])]}},{key:"cancelAmount",fn:function(value){return [_vm._v(" "+_vm._s((value * 1).toLocaleString())+"원 ")]}},{key:"validAmount",fn:function(value){return [_vm._v(" "+_vm._s((value * 1).toLocaleString())+"원 ")]}}])})],1)],1)],1),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mx-auto mb-3",attrs:{"type":"table"}}),_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":10,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1),(_vm.isStatusDialog)?_c('StatusChangeDialog',{attrs:{"record":_vm.selectRecord,"dialog":!!_vm.selectRecord,"status":_vm.requestStatus},on:{"index":function($event){return _vm.fetchPointOrders()},"close":_vm.handleCloseDialog}}):_vm._e(),(!_vm.isStatusDialog)?_c('DetailDialog',{on:{"index":function($event){return _vm.fetchPointOrders()}}}):_vm._e(),(_vm.dialog)?_c('AdvertiserDetailDialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }