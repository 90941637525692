import axios from 'axios';

export async function getMegaboxPlacesApi() {
  try {
    const { data } = await axios.get(`/partner/megabox-places`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}
