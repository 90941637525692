<template>
  <v-col cols="auto">
    <v-btn color="secondary" @click="() => (changeResourceDialog = true)">
      기본영상 등록
    </v-btn>

    <v-dialog
      v-model="changeResourceDialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-toolbar flat dark>
          <v-toolbar-title>캠페인 리소스 선택</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="handleCancleChangeResourceClick">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="py-3 px-0">
          <PreviewResource
            v-if="changeResourceDialog"
            :selectedId="tempCampaignResourceIds"
            :userId="didBranch.didMediaGroupUserId"
            :originId="didBranch.id"
            @setResource="setResource"
            :campaignType="'did'"
            multiple
          />

          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>

            <v-btn
              color="secondary"
              @click="handleCancleChangeResourceClick"
              :loading="loading"
              outlined
            >
              취소
            </v-btn>
            <v-btn
              color="secondary"
              @click="handleChangeResourceClick"
              :loading="loading"
              :disabled="loading"
            >
              적용
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>

      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CampaignResource } from '@/model/common';
import PreviewResource from '@/components/did/PreviewResource.vue';
// import { updateDidCampaignResourceApi } from '@/api/admin/DidCampaignAPI';
export default {
  components: { PreviewResource },
  props: {
    // created시 초기화 여부
    // 매체 상세 로드시에는 안함
    createdInit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      changeResourceDialog: false,
      tempCampaignResourceIds: [],
      tempCampaignResources: []
    };
  },
  computed: {
    ...mapGetters({
      didBranch: 'didBranch/branch'
    })
  },
  watch: {
    changeResourceDialog(newVal) {
      if (newVal) {
        this.init();
      }
      this.changeResourceDialog = newVal;
    }
  },
  mounted() {
    this.changeResourceDialog = false;

    if (!this.createdInit) return;
    this.init();
  },
  methods: {
    ...mapActions({}),
    init() {
      this.loading = false;
      console.log(
        this.didBranch.campaignResources,
        this.didBranch.campaignResourceIds
      );
      this.tempCampaignResources = [...this.didBranch.campaignResources];
      this.tempCampaignResourceIds = [...this.didBranch.campaignResourceIds];
    },
    setResource(resource) {
      this.tempCampaignResourceIds = this.tempCampaignResources = [];
      if (resource) {
        this.tempCampaignResources = resource.map(item => {
          this.tempCampaignResourceIds.push(item.id);
          return new CampaignResource(resource);
        });
      }
    },
    /**@description: 소재 취소 */
    handleCancleChangeResourceClick() {
      // 원래 값으로 돌려놓고
      this.didBranch.campaignResourceIds = [
        ...this.didBranch.origin.campaignResourceIds
      ];
      this.didBranch.campaignResources = [
        ...this.didBranch.origin.campaignResources
      ];
      this.changeResourceDialog = false;
    },
    /**@description: 소재 변경시 */
    async handleChangeResourceClick() {
      if (this.loading) return;

      const isSameOrigin = this.didBranch.origin.campaignResourceIds.some(
        item => this.tempCampaignResourceIds.includes(item)
      );
      if (
        this.didBranch.id &&
        this.didBranch.origin.campaignResourceIds.length ===
          this.tempCampaignResourceIds.length &&
        isSameOrigin
      ) {
        this.$Swal.fire({
          icon: 'info',
          html: '<h3>변경 사항이 없습니다.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      this.loading = true;

      this.didBranch.campaignResourceIds = [...this.tempCampaignResourceIds];
      this.didBranch.campaignResources = [...this.tempCampaignResources];

      this.loading = this.changeResourceDialog = false;
    }
  }
};
</script>
