var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.titles.category))]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.AdvertisingCategoryList,"item-text":"text","item-value":"value","data-vv-name":"pushCategory","placeholder":_vm.placeholder.category,"prepend-inner-icon":"mdi-magnify","hide-no-data":"","required":"","outlined":"","auto-select-first":""},model:{value:(_vm.campaign.pushCategory),callback:function ($$v) {_vm.$set(_vm.campaign, "pushCategory", $$v)},expression:"campaign.pushCategory"}})]}}])}),_c('h3',[_vm._v(_vm._s(_vm.titles.title))]),_c('validation-provider',{attrs:{"rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":25,"error-messages":errors,"placeholder":_vm.placeholder.title,"required":"","outlined":"","clearable":""},model:{value:(_vm.campaign.pushTitle),callback:function ($$v) {_vm.$set(_vm.campaign, "pushTitle", $$v)},expression:"campaign.pushTitle"}})]}}])}),_c('h3',[_vm._v(" "+_vm._s(_vm.titles.subTitle)+" "),_c('span',{staticStyle:{"font-size":"0.7em"}},[_vm._v("(광고 표시는 기본 삽입됩니다)")])]),_c('validation-provider',{attrs:{"rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":25,"error-messages":errors,"placeholder":_vm.placeholder.subTitle,"required":"","outlined":"","clearable":""},model:{value:(_vm.campaign.pushSubTitle),callback:function ($$v) {_vm.$set(_vm.campaign, "pushSubTitle", $$v)},expression:"campaign.pushSubTitle"}})]}}])}),_c('h3',[_vm._v(" "+_vm._s(_vm.titles.text)+" ")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"placeholder":_vm.placeholder.text,"required":"","outlined":"","clearable":""},model:{value:(_vm.campaign.pushText),callback:function ($$v) {_vm.$set(_vm.campaign, "pushText", $$v)},expression:"campaign.pushText"}})]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
          required: false
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('keep-alive',[(_vm.campaign.isTypeMessage)?_c('image-upload',{attrs:{"sImageId":_vm.campaign.messageImageId,"campaignType":"message","imageType":"image","title":_vm.titles.messageImage},on:{"setImage":function (v) {
                _vm.campaign.messageImageId = v.id;
                _vm.campaign.messageImageUrl = v.url;
                _vm.campaign.messageImageType = v.type;
              }}}):_vm._e()],1),_c('v-input',{attrs:{"type":"hidden","error-messages":errors},model:{value:(_vm.campaign.messageImageId),callback:function ($$v) {_vm.$set(_vm.campaign, "messageImageId", $$v)},expression:"campaign.messageImageId"}})]}}])}),(_vm.campaign.isTypeMessage)?_c('div',{staticClass:"d-inline-flex align-center"},[_c('h3',{staticClass:"mr-2"},[_vm._v(" 같은 소재 사용하기 "),_c('information-hover',{attrs:{"title":"같은 소재 사용하기","subTitle":"인앱광고 이미지에 메시지 이미지와 같은 이미지를 사용합니다."}})],1),_c('v-switch',{attrs:{"color":"primary","inset":""},model:{value:(_vm.useSameImageOption),callback:function ($$v) {_vm.useSameImageOption=$$v},expression:"useSameImageOption"}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('keep-alive',[(!_vm.useSameImageOption)?_c('image-upload',{attrs:{"sImageId":_vm.campaign.pushImageId,"campaignType":"push","imageType":"image","title":_vm.titles.image},on:{"setImage":function (v) {
              _vm.campaign.pushImageId = v.id;
              _vm.campaign.pushImageUrl = v.url;
              _vm.campaign.pushImageType = v.type;
            }}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }