import axios from 'axios';

export async function getCostTypesApi() {
  try {
    const { data } = await axios.get(`/config/cost-types`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getRegionOptionsApi() {
  try {
    const { data } = await axios.get(`/config/region-options`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
