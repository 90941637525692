<template>
  <div>
    <h3>{{ titles.category }}</h3>
    <validation-provider v-slot="{ errors }" rules="required">
      <v-autocomplete
        v-model="push.category"
        :error-messages="errors"
        :items="AdvertisingCategoryList"
        item-text="text"
        item-value="value"
        data-vv-name="pushCategory"
        :placeholder="placeholder.category"
        prepend-inner-icon="mdi-magnify"
        hide-no-data
        required
        outlined
        auto-select-first
      ></v-autocomplete>
    </validation-provider>

    <h3>{{ titles.title }}</h3>
    <validation-provider v-slot="{ errors }" rules="required|max:25">
      <v-text-field
        v-model="push.title"
        :counter="25"
        :error-messages="errors"
        :placeholder="placeholder.title"
        required
        outlined
        clearable
      ></v-text-field>
    </validation-provider>

    <h3>
      {{ titles.subTitle }}
      <span style="font-size:0.7em">(광고 표시는 기본 삽입됩니다)</span>
    </h3>
    <validation-provider v-slot="{ errors }" rules="required|max:40">
      <v-text-field
        v-model="push.subTitle"
        :counter="25"
        :error-messages="errors"
        :placeholder="placeholder.subTitle"
        required
        outlined
        clearable
      ></v-text-field>
    </validation-provider>

    <h3>
      {{ titles.text }}
    </h3>
    <validation-provider v-slot="{ errors }" rules="required">
      <v-textarea
        v-model="push.text"
        :error-messages="errors"
        :placeholder="placeholder.text"
        required
        outlined
        clearable
      ></v-textarea>
    </validation-provider>
    <v-row>
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          :rules="{
            required: false
          }"
        >
          <keep-alive>
            <image-upload
              v-if="isCampaignTypeMessage"
              :sImageId="push.messageImageId"
              @setImage="
                v => {
                  push.messageImageId = v.id;
                  push.messageImageUrl = v.url;
                  push.messageImageType = v.type;
                }
              "
              campaignType="message"
              imageType="image"
              :title="titles.messageImage"
            />
          </keep-alive>
          <v-input
            type="hidden"
            v-model="push.messageImageId"
            :error-messages="errors"
          ></v-input>
        </validation-provider>
        <div v-if="isCampaignTypeMessage" class="d-inline-flex align-center">
          <h3 class="mr-2">
            같은 소재 사용하기
            <information-hover
              title="같은 소재 사용하기"
              subTitle="인앱광고 이미지에 메시지 이미지와 같은 이미지를 사용합니다."
            />
          </h3>
          <v-switch
            v-model="useSameImageOption"
            color="primary"
            inset
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="12">
        <keep-alive>
          <image-upload
            v-if="!useSameImageOption"
            :sImageId="push.imageId"
            @setImage="
              v => {
                push.imageId = v.id;
                push.imageUrl = v.url;
                push.imageType = v.type;
              }
            "
            campaignType="push"
            imageType="image"
            :title="titles.image"
          />
        </keep-alive>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import ImageUpload from '@/components/ImageUpload.vue';
import { mapGetters } from 'vuex';
import InformationHover from '../../../../../../components/InformationHover.vue';
import {
  AdvertisingCategoryList,
  Instructions,
  Titles
} from '@/config/admin/campaign';

export default {
  components: {
    ValidationProvider,
    ImageUpload,
    InformationHover
  },
  props: ['push'],
  data() {
    return {
      useSameImageOption: false,
      urlRegex:
        'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
      time: null
    };
  },
  watch: {
    useSameImageOption(newValue) {
      if (newValue) {
        const image = this.getPushMessageImage();
        this.setInappImage(image);
      } else {
        this.setInappImage();
      }
    },
    'push.messageImageId': function() {
      if (this.useSameImageOption) {
        const image = this.getPushMessageImage();
        this.setInappImage(image);
      }
    }
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign'
    }),
    titles() {
      return Titles.rs2.push;
    },
    placeholder() {
      return Instructions.rs2.push;
    },
    isCampaignTypeMessage() {
      return this.campaign.rs1.campaignType === 'message';
    },
    AdvertisingCategoryList() {
      return AdvertisingCategoryList;
    }
  },
  mounted() {
    const { imageId, messageImageId } = this.push;
    let useSameImageOption = false;
    if (messageImageId && imageId === messageImageId) {
      useSameImageOption = imageId === messageImageId;
    }
    this.useSameImageOption = useSameImageOption;
  },
  methods: {
    setInappImage(image) {
      this.push.imageId = image?.imageId || null;
      this.push.imageUrl = image?.imageUrl || null;
      this.push.imageType = image?.imageType || null;
    },
    getPushMessageImage() {
      const push = this.push;
      return {
        imageId: push.messageImageId,
        imageUrl: push.messageImageUrl,
        imageType: push.messageImageType
      };
    }
  }
};
</script>
