var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('ValidationObserver',{ref:"networkContentRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"p-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticStyle:{"padding":"30px"}},[_c('h2',{staticClass:"font-weight-bold py-2"},[_vm._v("기본 입력 사항")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.NetworkTypeList,"label":"네트워크 유형","error-messages":errors,"required":"","outlined":""},model:{value:(_vm.network.type),callback:function ($$v) {_vm.$set(_vm.network, "type", $$v)},expression:"network.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"네트워크 별명","counter":20,"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.network.name),callback:function ($$v) {_vm.$set(_vm.network, "name", $$v)},expression:"network.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"네트워크 아이디","counter":255,"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.network.uid),callback:function ($$v) {_vm.$set(_vm.network, "uid", $$v)},expression:"network.uid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"입찰단가(CPM)","error-messages":errors,"required":"","outlined":"","suffix":"원"},model:{value:(_vm.network.impBidFloor),callback:function ($$v) {_vm.$set(_vm.network, "impBidFloor", $$v)},expression:"network.impBidFloor"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"CTR","required":"","outlined":"","suffix":"%"},model:{value:(_vm.network.ctr),callback:function ($$v) {_vm.$set(_vm.network, "ctr", $$v)},expression:"network.ctr"}})]}}],null,true)})],1),(_vm.medias.length > 0)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.medias,"error-messages":errors,"item-text":"name","item-value":"id","hide-no-data":"","label":"매체","clearable":"","outlined":""},model:{value:(_vm.network.mediaId),callback:function ($$v) {_vm.$set(_vm.network, "mediaId", $$v)},expression:"network.mediaId"}})]}}],null,true)})],1):_vm._e()],1),_c('h2',{staticClass:"font-weight-bold py-2"},[_vm._v("앱 정보 입력")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"앱 아이디","error-messages":errors,"required":"","outlined":""},model:{value:(_vm.network.appId),callback:function ($$v) {_vm.$set(_vm.network, "appId", $$v)},expression:"network.appId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"앱 이름","error-messages":errors,"required":"","outlined":""},model:{value:(_vm.network.appName),callback:function ($$v) {_vm.$set(_vm.network, "appName", $$v)},expression:"network.appName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"앱 번들","error-messages":errors,"required":"","outlined":""},model:{value:(_vm.network.appBundle),callback:function ($$v) {_vm.$set(_vm.network, "appBundle", $$v)},expression:"network.appBundle"}})]}}],null,true)})],1)],1)],1),_c('v-btn',{staticClass:"mt-4 mr-4",attrs:{"disabled":invalid,"color":"secondary","type":"submit","large":"","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.isDialogTypeRegister ? '등록' : '수정')+"하기 ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }