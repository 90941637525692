<template>
  <v-autocomplete
    v-model="selectedFilters"
    chips
    multiple
    hide-selected
    solo
    append-icon=""
    prefix="필터"
    :items="filterOptions"
    hide-no-data
    hide-details
    :menu-props="{
      closeOnContentClick: true,
      maxWidth: '300',
      nudgeRight: menuPropsOffsetLeft
    }"
    @focus="filterOptionsFocus"
    @click="filterOptionsFocus"
    :disabled="loading"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        pill
        :close="data.item.isDeletable"
        @click="data.select;"
        @click:close="
          () => {
            remove(data.item);
            data.item.items.selected = [];
            applyFilter();
          }
        "
      >
        <component
          v-if="data.item.value === 'period'"
          v-bind:is="data.item.component"
          @setDateRanges="
            v => {
              dateRanges = v;
            }
          "
          @applyFilter="applyFilter(false)"
          :title="data.item.text"
          :prefix="7"
        >
        </component>
        <component
          v-else
          v-bind:is="data.item.component"
          :title="data.item.text"
          :items="data.item.items"
          :detail="data.item.detail"
          :default="data.item.default"
          :min="data.item.min"
          :max="data.item.max"
          :keyString="data.item.keyString"
          :textString="data.item.textString"
          @remove="remove(data.item)"
          :api="data.item.api"
          @callApi="callApi(data.item.callApi)"
          @applyFilter="applyFilter()"
          :emptyMessage="data.item.emptyMessage"
          :isSavedFilter="isSavedFilter"
        ></component>
      </v-chip>
      <span
        v-if="
          filterOptions.length !== selectedFilters.length &&
            data.index + 1 === selectedFilters.length
        "
        class="subtitle-2 py-2"
      >
        필터 추가
      </span>
    </template>
    <template v-slot:item="data">
      <v-list-item-content style="width:200px">
        <v-list-item-title v-html="data.item.text"></v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
            v-on="{ ...onTooltip }"
            icon
            @click="applyFilter(false)"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span class="title">새로고침</span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    FilterDatePicker: () => import('@/components/FilterDatePicker.vue'),
    FilterMenu: () => import('@/components/FilterMenu.vue')
  },
  props: {
    loading: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data() {
    return {
      menuPropsOffsetLeft: null,
      dateRanges: null,
      selectedFilters: ['period', 'approveStatus'],
      isSavedFilter: false,
      filterOptions: [
        {
          text: '캠페인 생성기간',
          value: 'period',
          isDeletable: false,
          component: 'FilterDatePicker'
        },
        {
          text: '광고유형',
          value: 'campaignType',
          isDeletable: true,
          component: 'FilterMenu',
          detail: true,
          default: false,

          textString: 'text',
          keyString: 'value',
          min: 1,
          items: {
            list: [
              {
                text: '이미지',
                value: 'rolling'
              },
              {
                text: '텍스트',
                value: 'push'
              },
              {
                text: '메시지',
                value: 'message'
              }
            ],
            selected: []
          }
        },
        {
          text: '게재',
          value: 'approveStatus',
          default: true,
          textString: 'text',
          keyString: 'value',
          component: 'FilterMenu',
          min: 1,
          detail: true,
          items: {
            list: [
              {
                text: '승인',
                value: 'Y'
              },
              {
                text: '거절',
                value: 'N'
              },
              {
                text: '대기',
                value: 'W'
              },
              {
                text: '중단',
                value: 'S'
              }
            ],
            selected: [
              {
                text: '대기',
                value: 'W'
              }
            ]
          }
        },
        {
          text: '상태',
          value: 'activeStatus',
          default: false,
          textString: 'text',
          keyString: 'value',
          component: 'FilterMenu',
          min: 1,
          detail: true,
          items: {
            list: [
              {
                text: '활성',
                value: true
              },
              {
                text: '비활성',
                value: false
              }
            ],
            selected: [
              {
                text: '활성',
                value: true
              },
              {
                text: '비활성',
                value: false
              }
            ]
          }
        },
        {
          text: '태그 타겟',
          value: 'tagFilter',
          default: false,
          detail: true,
          isDeletable: true,
          textString: 'text',
          keyString: 'value',
          component: 'FilterMenu',
          min: 1,
          items: {
            list: [
              {
                text: '활성',
                value: true
              },
              {
                text: '비활성',
                value: false
              }
            ],
            selected: []
          }
        },
        {
          text: '계정',
          value: 'advertiser',
          isDeletable: true,
          default: false,
          component: 'FilterMenu',
          textString: 'name',
          keyString: 'id',
          items: {
            list: [],
            selected: []
          },
          callApi: `getAdvertiserGroupsApi`,
          api: true
        },
        {
          text: '광고그룹',
          value: 'advertiserGroup',
          isDeletable: true,
          default: false,
          component: 'FilterMenu',
          textString: 'name',
          keyString: 'id',
          items: {
            list: [],
            selected: []
          },
          callApi: `getCampaignsApi`,
          api: true,
          emptyMessage: '계정을 선택해주세요.'
        },
        {
          text: '캠페인',
          value: 'campaign',
          isDeletable: true,
          default: false,
          textString: 'name',
          keyString: 'id',
          component: 'FilterMenu',
          items: {
            list: [],
            selected: []
          },
          emptyMessage: '계정과 광고그룹을 선택해주세요.'
        },
        {
          text: '매체그룹',
          value: 'mediaGroup',
          isDeletable: true,
          default: false,
          component: 'FilterMenu',
          textString: 'name',
          keyString: 'id',
          items: {
            list: [],
            selected: []
          }
        }
      ]
    };
  },
  async mounted() {
    const { contents } = await this.getFiltersApi();
    if (contents?.filterOptions && contents?.selectedFilters) {
      this.filterOptions = contents.filterOptions;
      this.selectedFilters = contents.selectedFilters;
      this.applyFilter(false);
      this.isSavedFilter = true;
    } else {
      this.applyFilter(false);
    }
    // 필터옵션 오브젝트에 계정 리스트가 없을 경우 api 호출한다.
    const { items: advertiserItems } = this.filterOptions.find(
      v => v.value === 'advertiser'
    );
    if (advertiserItems.list.length === 0) {
      this.getAdvertisersApi();
    }

    const { items: mediaGroupItems } = this.filterOptions.find(
      v => v.value === 'mediaGroup'
    );
    if (mediaGroupItems.list.length === 0) {
      this.getMediaGroupsApi();
    }
  },
  methods: {
    filterOptionsFocus(e) {
      this.menuPropsOffsetLeft = e.target.offsetLeft;
    },
    //광고주 계정 목록
    async getAdvertisersApi() {
      try {
        const { data } = await this.$axios.get(`/admin/report-advertisers`);

        this.initFilterOptions('advertiser', data.advertisers);
        this.initFilterOptions('advertiserGroup');
        this.initFilterOptions('campaign');
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    // 광고그룹 목록
    async getAdvertiserGroupsApi() {
      try {
        const advertiserIds = this.filterOptions
          .find(v => v.value === 'advertiser')
          .items.selected.map(v => v.id);

        const { data } = await this.$axios.get(
          `/admin/report-advertiser-groups`,
          {
            params: {
              advertiserIds: advertiserIds
            }
          }
        );

        this.initFilterOptions('advertiserGroup', data.advertiserGroups);
        this.initFilterOptions('campaign');
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async getCampaignsApi() {
      try {
        const advertiserIds = this.filterOptions
          .find(v => v.value === 'advertiser')
          .items.selected.map(v => v.id);
        const advertiserGroupIds = this.filterOptions
          .find(v => v.value === 'advertiserGroup')
          .items.selected.map(v => v.id);

        const { data } = await this.$axios.get(`/admin/report-campaigns`, {
          params: {
            advertiserIds: advertiserIds,
            advertiserGroupIds: advertiserGroupIds
          }
        });

        this.initFilterOptions('campaign', data.campaigns);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    //매체 그룹 목록
    async getMediaGroupsApi() {
      try {
        const { data } = await this.$axios.get(`/admin/report-media-groups`);
        this.initFilterOptions('mediaGroup', data.mediaGroups);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    //선택 필터 항목 삭제
    remove(item) {
      const index = this.selectedFilters.indexOf(item.value);
      if (index >= 0) this.selectedFilters.splice(index, 1);
    },
    applyFilter(syncFilter = true) {
      const filter = {
        dateRanges: this.dateRanges,
        campaignTypes: this.filterOptions
          .find(v => v.value === 'campaignType')
          .items.selected.map(v => v.value),
        approveStatus: this.filterOptions
          .find(v => v.value === 'approveStatus')
          .items.selected.map(v => v.value),
        activeStatus: this.filterOptions
          .find(v => v.value === 'activeStatus')
          .items.selected.map(v => v.value),
        tagFilter: this.filterOptions
          .find(v => v.value === 'tagFilter')
          .items.selected.map(v => v.value),
        advertiserIds: this.filterOptions
          .find(v => v.value === 'advertiser')
          .items.selected.map(v => v.id),
        advertiserGroupIds: this.filterOptions
          .find(v => v.value === 'advertiserGroup')
          .items.selected.map(v => v.id),
        campaignIds: this.filterOptions
          .find(v => v.value === 'campaign')
          .items.selected.map(v => v.id),
        mediaGroupIds: this.filterOptions
          .find(v => v.value === 'mediaGroup')
          .items.selected.map(v => v.id)
      };
      // console.log(filter);
      this.$emit('applyFilters', filter);
      // 싱크 옵션에 따라 서버와 동기화 합니다.
      if (syncFilter) {
        this.setFiltersApi();
      }
    },
    async setFiltersApi() {
      try {
        const data = {
          visitor_id: this.user.visitorId,
          view: 'campaign-ad',
          type: 'filter',
          contents: {
            selectedFilters: this.selectedFilters,
            filterOptions: this.filterOptions
          }
        };
        await this.$axios.post(`menus/user`, data);
      } catch (err) {
        console.error(err);
      }
    },
    initFilterOptions(key, list = []) {
      const { items } = this.filterOptions.find(v => v.value === key);

      //광고그룹 초기화
      items.list = list;
      items.selected = [];

      //현재 선택되어있는 필터에서 해당 옵션 삭제
      const index = this.selectedFilters.indexOf(key);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
      }
    },
    async getFiltersApi() {
      try {
        const { data } = await this.$axios.get(`menus/user`, {
          params: {
            visitor_id: this.user.visitorId,
            view: 'campaigns-ad',
            type: 'filter'
          }
        });
        // console.log(`getFilterApi`, data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    // 동적으로 api 를 호출하기 위한 함수입니다.
    async callApi(apiName) {
      // api 가 존재하고 호출이 성공한 경우 applyFilter 호출하고 applyFilter 내부에서 filterOptions 를 setFilterApi를 통해 서버와 동기화 한다.
      if (apiName && (await this[apiName]())) {
        this.applyFilter();
      }
    }
  }
};
</script>

<style></style>
