<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <a-tabs v-model="tab" tab-position="top" class="" filtered>
          <a-tab-pane key="" tab="SDK" class="row ma-0">
            <v-col :cols="12" class="px-2 py-0">
              <v-alert
                class="mx-3 mb-5 py-3"
                border="left"
                colored-border
                icon="mdi-information-outline"
                color="blue"
                elevation="2"
              >
                <span class="text-subtitle-2">
                  '라이브, CTR_라이브' 항목은 최대 31일 기간이내 검색시
                  표시됩니다.
                </span>
              </v-alert>
            </v-col>
            <v-col :cols="12" class="px-2 py-0">
              <AdminReportTable />
            </v-col>
          </a-tab-pane>
          <!-- <a-tab-pane key="rolling" tab="이미지" class="row ma-0">
            <div :style="{ padding: '0 20px' }" class="col">
              <AdminReportTable type="rolling" />
            </div>
          </a-tab-pane>

          <a-tab-pane key="push" tab="텍스트" class="row ma-0">
            <div :style="{ padding: '0 20px' }" class="col">
              <AdminReportTable type="push" />
            </div>
          </a-tab-pane>

          <a-tab-pane key="message" tab="메시지" class="row ma-0">
            <div :style="{ padding: '0 20px' }" class="col">
              <AdminReportTable type="message" />
            </div>
          </a-tab-pane> -->

          <a-tab-pane key="coupon" tab="쿠폰" class="row ma-0">
            <div :style="{ padding: '0 20px' }" class="col">
              <AdminReportTable type="coupon" />
            </div>
          </a-tab-pane>

          <a-tab-pane key="megabox-coupon" tab="메가박스" class="row ma-0">
            <div :style="{ padding: '0 20px' }" class="col">
              <AdminMegaboxReportTable />
            </div>
          </a-tab-pane>
        </a-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import AdminReportTable from './components/AdminReportTable.vue';
import AdminMegaboxReportTable from './components/AdminMegaboxReportTable.vue';

export default {
  components: {
    MenuTitle,
    AdminReportTable,
    AdminMegaboxReportTable
  },
  data() {
    return {
      tab: '',
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      headers: [],
      loading: true,
      filters: null
    };
  },
  computed: {
    ...mapGetters({
      // isSuperAdmin: 'auth/isSuperAdmin'
    }),
    totalRowColSpan() {
      return 1;
    },
    issetGapIcon() {
      return this.filters.measureStandard === 'date';
    },
    issetLive() {
      return this.days.length > 31 ? false : true;
    }
  },
  methods: {}
};
</script>
