export const InquiryCategoryList = [
  {
    text: '광고설정',
    value: '광고설정'
  },
  {
    text: '광고취소',
    value: '광고취소'
  },
  {
    text: '충전',
    value: '충전'
  },
  {
    text: '기타',
    value: '기타'
  }
];
