import campaign from '@/store/admin/campaign';
import media from '@/store/admin/media';
import advertiser from '@/store/admin/advertiser';
import mediaGroup from '@/store/admin/media-group';
import advertiserGroup from '@/store/admin/advertiser-group';
import tag from '@/store/admin/tag';
import notice from '@/store/admin/notice';
import menus from '@/store/admin/menus';
import payment from '@/store/admin/payment';
import inquiry from '@/store/admin/inquiry';
import dialog from '@/store/admin/dialog';
import user from '@/store/admin/user';
import notificationPushes from '@/store/admin/notification-pushes';
import notification from '@/store/admin/notification';
import didBranch from '@/store/admin/did-branch';
import didSettopBox from '@/store/admin/did-settop-box';
import didApp from '@/store/admin/did-app';
import didCampaignService from '@/store/admin/did-campaign-service';
import campaignResource from '@/store/admin/campaign-resource';
import didMediaGroup from '@/store/admin/did-media-group';
import couponCategory from '@/store/admin/coupon-category';

export default {
  campaign,
  media,
  advertiser,
  mediaGroup,
  advertiserGroup,
  tag,
  menus,
  notice,
  payment,
  inquiry,
  dialog,
  user,
  notificationPushes,
  couponCategory,
  notification,
  didBranch,
  didSettopBox,
  didApp,
  didCampaignService,
  campaignResource,
  didMediaGroup
};
