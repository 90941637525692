<template>
  <div class="filters mt-8">
    <ValidationObserver :ref="refKey">
      <v-row dense style="height: 100%; eoverflow: auto">
        <v-col cols="12">
          <h3>지점명</h3>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="couponShopInfo.name"
              placeholder="지점명을 입력해 주세요."
              outlined
              :error-messages="errors"
              required
              dense
            />
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <h3>브랜드 로고</h3>
          <div v-if="couponShopInfo.logoImageUrl" class="row ma-0 align-end">
            <v-img
              :src="
                couponShopInfo.logoImageUrl ? couponShopInfo.logoImageUrl : ''
              "
              width="100"
              height="100"
              class="col-auto mr-2"
            />
            <v-btn icon @click="handleRemoveClick()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <ImageFileInput
            v-else
            fileType="image"
            fileLabel="브랜드 로고"
            :fileSizeMax="3"
            @setImage="
              file => {
                couponShopInfo.logoImageFile = file;
              }
            "
          ></ImageFileInput>
        </v-col>

        <v-col cols="12">
          <KakaoAddressForm
            v-if="kakaoAddress"
            popupKey="user"
            business
            dense
            popupTitle="지점 주소 검색"
            headerLabel="지점 주소"
            hidePostCode
            hideEextraAddress
            :address="kakaoAddress"
            @submit="
              value => {
                couponShopInfo.address = value.address;
                couponShopInfo.detailAddress = value.detailAddress;
              }
            "
          />
        </v-col>
        <h3 class="col-12">지점 영업 시간</h3>

        <v-col cols="6">
          <v-menu
            ref="bizHourSttMenu"
            v-model="bizHourSttMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :nudge-bottom="40"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="couponShopInfo.bizHourStt"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  label="영업 시작 시간"
                  dense
                  clearable
                  :error-messages="errors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </ValidationProvider>
            </template>
            <v-time-picker
              v-if="bizHourSttMenu"
              v-model="couponShopInfo.bizHourStt"
              :allowed-minutes="allowedMinutes"
              format="24hr"
              full-width
              color="secondary"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="setBizHour()"> 적용 </v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
            ref="bizHourEndMenu"
            v-model="bizHourEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :nudge-bottom="40"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="couponShopInfo.bizHourEnd"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  label="영업 종료 시간"
                  dense
                  clearable
                  :error-messages="errors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </ValidationProvider>
            </template>
            <v-time-picker
              v-if="bizHourEndMenu"
              v-model="couponShopInfo.bizHourEnd"
              :allowed-minutes="allowedMinutes"
              format="24hr"
              full-width
              color="secondary"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="setBizHour()"> 적용 </v-btn>
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="pt-0">
          <h3>연락처</h3>
          <ValidationProvider
            vid="tel"
            v-slot="{ errors }"
            rules="required|numeric|min:10|max:11"
          >
            <v-text-field
              v-model="couponShopInfo.tel"
              ref="shop-tel"
              type="tel"
              placeholder="연락처를 입력해주세요."
              outlined
              class="input_add notii-text-filed"
              :error-messages="errors"
              required
              persistent-hint
              hint="- 없이 번호만 입력하세요."
              dense
              counter
              maxlength="11"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" class="pt-3">
          <h3>지점 코멘트 <span class="text-caption">(선택)</span></h3>
          <v-textarea
            v-model="couponShopInfo.description"
            height="100px"
            outlined
            no-resize
            hide-details
            class="input_add custom-margin notii-text-filed"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            color="#00d1b2"
            class="white--text"
            depressed
            :loading="loading"
            :disabled="loading"
            @click="submit"
          >
            {{ modeText }}
          </v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ImageFileInput from '@/components/ImageFileInput.vue';
import KakaoAddressForm from '@/components/KakaoAddressForm.vue';
import { KakaoAddress } from '@/model/register/KakaoAddress';
import { CouponShop } from '@/model/admin/CouponShop';
import { convertFormData } from '@/utils/functions';
import {
  updateCouponShopApi,
  createCouponShopApi,
} from '@/api/admin/CouponShopAPI';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    KakaoAddressForm,
    ImageFileInput,
  },
  props: {
    data: {
      type: CouponShop,
    },
  },
  data() {
    return {
      refKey: 'couponShopRef',
      kakaoAddress: null,
      loading: false,
      couponShopInfo: new CouponShop(),
      bizHourSttMenu: false,
      bizHourEndMenu: false,
    };
  },
  computed: {
    modeText() {
      return this.data.id ? '변경' : '등록';
    },
    allowedMinutes() {
      return Array.from(Array(60).keys()).filter(v => v % 5 === 0);
    },
  },
  mounted() {
    this.$refs[this.refKey].reset();

    const newKakaoAddress = new KakaoAddress();

    this.couponShopInfo = new CouponShop(this.data);

    if (this.data.id) {
      newKakaoAddress.address = this.couponShopInfo.address;
      newKakaoAddress.detailAddress = this.couponShopInfo.detailAddress;
    }

    this.kakaoAddress = newKakaoAddress;
  },
  methods: {
    handleRemoveClick() {
      this.couponShopInfo.logoImage = null;
    },
    setBizHour() {
      this.bizHourSttMenu = this.bizHourEndMenu = false;
    },
    async submit() {
      const valid = await this.$refs[this.refKey].validate();
      if (valid) {
        this.data.id ? this.updateCouponShop() : this.createCouponShop();
      } else {
        this.$Swal.fire({
          icon: 'error',
          html: '입력 항목을 다시한번 확인해주세요.',
          timer: 1000,
          showConfirmButton: false,
        });
      }
    },
    async createCouponShop() {
      this.loading = true;

      const formData = convertFormData(this.couponShopInfo);

      const [error, data] = await createCouponShopApi(formData);

      let type = 'error';
      let message = '등록 실패';
      let description = '';

      if (error) {
        description = error?.data?.message || '잠시 후 다시 시도해주세요.';
      } else {
        const { success } = data;

        if (success) {
          type = 'success';
          message = '등록 성공';
          description = `정보가 등록 되었습니다`;
          this.close(true);
        }
      }

      this.$notification[type]({
        message: message,
        description: description,
      });

      this.loading = false;
    },
    async updateCouponShop() {
      this.loading = true;

      const formData = convertFormData(this.couponShopInfo);
      formData.append('_method', 'put');

      const [error, data] = await updateCouponShopApi(formData);

      let type = 'error';
      let message = '수정 실패';
      let description = '';

      if (error) {
        description = error?.data?.message || '요청 실패';
      } else {
        const { success } = data;
        if (success) {
          type = 'success';
          message = '수정 성공';
          description = `정보가 변경 되었습니다`;

          this.close(true);
        }
      }

      this.$notification[type]({
        message: message,
        description: description,
      });

      this.loading = false;
    },
    close(refresh) {
      this.$emit('close', refresh || false);
    },
  },
};
</script>

<style>
.ant-form-item {
  margin-bottom: 0 !important;
}
.notii-text-filed textarea {
  margin-top: 0 !important;
}
textarea::-webkit-scrollbar {
  width: 0.6em;
}
textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
}
</style>
