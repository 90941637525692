<template>
  <div>
    <v-row dense>
      <v-col class="col-auto" v-if="headerLabel">
        <h3 class="mt-2">{{ headerLabel }}</h3>
      </v-col>
      <v-col cols="6" lg="3" v-if="!hidePostCode">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <v-text-field
            v-model="kakaoAddress.postcode"
            :error-messages="errors"
            type="text"
            readonly
            label="우편번호"
            outlined
            filled
            :dense="dense"
            :disabled="disabled"
            :hide-details="!errors.length"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-spacer />
      <v-col class="col-auto">
        <v-btn
          v-if="!disabled"
          color="primary"
          outlined
          style="height: 40px"
          @click="execDaumPostcode()"
        >
          우편번호 찾기
        </v-btn>
      </v-col>
      <v-col cols="12">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <v-text-field
            v-model="kakaoAddress.address"
            :error-messages="errors"
            type="text"
            readonly
            label="주소"
            outlined
            filled
            :dense="dense"
            :disabled="disabled"
            :hide-details="!errors.length"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="8" lg="8">
        <ValidationProvider v-slot="{ errors }" :rules="{ required: false }">
          <v-text-field
            v-model="kakaoAddress.detailAddress"
            :error-messages="errors"
            ref="detailAddress"
            type="text"
            label="상세주소"
            outlined
            @change="submit()"
            :dense="dense"
            :disabled="disabled"
            :filled="disabled"
            :hide-details="!errors.length"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="4" lg="4" v-if="!hideEextraAddress">
        <v-text-field
          v-model="kakaoAddress.extraAddress"
          type="text"
          readonly
          label="참고"
          outlined
          filled
          :dense="dense"
          :disabled="disabled"
          :hide-details="dense"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { KakaoAddress } from '@/model/register/KakaoAddress';
export default {
  components: {
    ValidationProvider,
  },
  props: {
    popupTitle: {
      type: String,
      required: true,
    },
    popupKey: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    address: {
      type: KakaoAddress,
      default: () => {
        return new KakaoAddress();
      },
    },
    hidePostCode: {
      type: Boolean,
      default: false,
    },
    hideEextraAddress: {
      type: Boolean,
      default: false,
    },
    headerLabel: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      post: null,
      kakaoAddress: new KakaoAddress(),
    };
  },
  mounted() {
    // console.log(this.address);
    if (this.address) {
      this.kakaoAddress.address = this.address?.address || null;
      this.kakaoAddress.postcode = this.address?.postcode || null;
      this.kakaoAddress.detailAddress = this.address?.detailAddress || null;
      this.kakaoAddress.extraAddress = this.address?.extraAddress || null;
    }
  },
  methods: {
    execDaumPostcode() {
      if (this.post) {
        this.openPopup(this.post);
      } else {
        const post = this.createPopup();
        this.post = post;
        this.openPopup(post);
      }
    },
    createPopup() {
      const me = this;

      const post = new this.$daum.Postcode({
        oncomplete: function (data) {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ''; // 주소 변수
          var extraAddr = ''; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              extraAddr +=
                extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== '') {
              extraAddr = ' (' + extraAddr + ')';
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
            me.kakaoAddress.extraAddress = extraAddr;
          } else {
            me.kakaoAddress.extraAddress = '';
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          me.kakaoAddress.postcode = data.zonecode;
          me.kakaoAddress.address = addr;
          // 커서를 상세주소 필드로 이동한다.
          me.kakaoAddress.detailAddress = null;
          me.$refs.detailAddress.focus();
          me.submit();
        },
      });

      return post;
    },
    openPopup(post) {
      post.open({
        popupTitle: this.popupTitle,
        popupKey: this.popupKey,
      });
    },
    submit() {
      this.$emit('submit', this.kakaoAddress);
    },
  },
};
</script>

<style></style>
