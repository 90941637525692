import axios from 'axios';

export async function getCouponShopsApi(userId) {
  try {
    const { data } = await axios.get(`/admin/coupon-shops/${userId}`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createCouponShopApi(params) {
  try {
    const { data } = await axios.post(`/admin/coupon-shop`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateCouponShopApi(param) {
  try {
    const { data } = await axios.post(`/admin/coupon-shop`, param);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function deleteCouponShopApi(couponShopId) {
  try {
    const { data } = await axios.delete(`/admin/coupon-shop/${couponShopId}`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}
