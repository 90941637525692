<template>
  <div class="filters">
    <a-descriptions :style="{ marginTop: '1rem' }">
      <div slot="title">
        <span>매체리스트</span>
        <a-button
          size="small"
          class="ml-3"
          :loading="loading"
          @click="getMedias"
        >
          새로고침
        </a-button>
      </div>
    </a-descriptions>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      bordered
      :scroll="{ x: true }"
      scrollToFirstRowOnChange
      size="small"
      :pagination="false"
    >
      <span slot="no" slot-scope="text, record, index">
        {{
          pagination.total -
          (pagination.perPage * pagination.page - pagination.perPage) -
          index
        }}
      </span>

      <span slot="active" slot-scope="text, record">
        <a-tag :color="record.active ? 'green' : 'red'">
          {{ record.active ? '활성' : '비활성' }}
        </a-tag>
      </span>
    </a-table>

    <v-pagination
      v-model="pagination.page"
      :length="pagination.totalPage"
      :total-visible="pagination.perPage"
      class="mt-5"
      color="secondary"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: String,
    tab: String
  },
  computed: {
    ...mapGetters({
      record: 'mediaGroup/record'
    }),
    totalPoint() {
      return Number(this.point.credit) + Number(this.point.free);
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      data: [],
      point: {
        credit: 0,
        free: 0
      },
      dates: [this.$moment().subtract(3, 'months'), this.$moment()],
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
        total: 0
      },
      columns: [
        {
          title: '번호',
          dataIndex: 'no',
          key: 'no',
          scopedSlots: { customRender: 'no' },
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '광고 송출 상태',
          dataIndex: 'active',
          key: 'active',
          scopedSlots: { customRender: 'active' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '매체명',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          ellipsis: true
        },
        {
          title: '패키지',
          dataIndex: 'package',
          key: 'package',
          align: 'center',
          ellipsis: true
        }
      ]
    };
  },
  watch: {
    'pagination.page'() {
      this.getMedias();
    },
    record() {
      this.getMedias();
    }
  },
  mounted() {
    this.getMedias();
  },
  methods: {
    search() {
      if (this.pagination.page !== 1) {
        this.pagination.page = 1;
      }
      this.getMedias();
    },
    getMedias() {
      this.loading = true;
      this.data = [];

      let params = {
        page: this.pagination.page,
        perPage: this.pagination.perPage,
        mediaGroupId: this.record.id,
        pagination: 'Y'
      };

      try {
        this.$axios
          .get('admin/media-groups/medias', { params: params })
          .then(res => {
            const { data, total, last_page } = res.data.medias;

            this.pagination.total = total;
            this.pagination.totalPage = last_page;

            this.data = data.map((item, index) => {
              return {
                key: index,
                id: item.id,
                active: item.is_active,
                name: item.name,
                package: item.package
              };
            });

            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
