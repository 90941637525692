import { CampaignFilterType } from '@/config/admin/campaign';
import { ApproveStatusList, ActiveStatusList } from '@/config/admin/campaign';

export class CampaignFilter {
  text = null; // 필터 이름
  value = null; // 필터 키값
  isDeletable = true; // 필터 삭제 가능여부
  component = null; // 필터 컴포넌트

  detail = false; // text 를 어떻게 보여줄지
  default = false; // 화면이 처음 로드시 메뉴를 보여줄지 말지
  textString = 'text'; // treeview 에 사용될 text key
  keyString = 'value'; // treeview 에 사용될 vlaue key
  min = 0; // 선택 아이템의 최소 개수
  max = undefined; // 선택 아이템의 최대 개수

  defaultValue = []; // 선택된 아이템 값 배열
  items = []; // 선택된 전체아이템  배열

  callApi = null;
  api = false;
  emptyMessage = null;

  _orignValue = [];

  constructor(init) {
    if (!init) return;

    for (const key in init) {
      let val = init[key];

      if (val && typeof val === 'object') {
        val = JSON.parse(JSON.stringify(val));
      }

      this[key] = val;
    }

    const campaignFilterType = CampaignFilterType.find(
      type => type.value === this.value
    );
    if (campaignFilterType) {
      this.text = this.text || campaignFilterType.text;
    }

    if (this.defaultValue) {
      this._orignValue = JSON.parse(JSON.stringify(this.defaultValue));
    }

    if (this.value) {
      let res = this.items;
      switch (this.value) {
        case 'approveStatus':
          res = [...ApproveStatusList];
          break;
        case 'activeStatus':
        case 'tagFilter':
          res = [...ActiveStatusList];
          break;
      }
      this.items = res;
    }
  }

  get orignValue() {
    return this._orignValue;
  }

  get isSameValue() {
    return (
      JSON.stringify(this._orignValue) === JSON.stringify(this.defaultValue)
    );
  }
}
