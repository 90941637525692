var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs1",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"pa-5",staticStyle:{"background-color":"white"}},[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('alert-message',{attrs:{"border":"left","icon":"mdi-information","color":"blue","message":"광고 방식은 캠페인 등록 후 수정이 불가합니다.","localStorageKey":"campaignTypeAlert","dismissible":""}}),_c('h3',[_vm._v(_vm._s(_vm.titles.rs1.campaignType))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"d-inline-block",attrs:{"error-messages":errors,"disabled":_vm.isDialogTypeEdit},on:{"change":function($event){return _vm.campaign.initBudget()}},model:{value:(_vm.campaign.type),callback:function ($$v) {_vm.$set(_vm.campaign, "type", $$v)},expression:"campaign.type"}},_vm._l((_vm.CampaignTypeList),function(m,index){return _c('div',{key:index,staticClass:"mb-5"},[_c('v-radio',{staticClass:"ma-0",attrs:{"value":m.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('h4',[_vm._v(" "+_vm._s(m.text)+" "),_c('InformationHover',{attrs:{"title":m.text,"subTitle":m.comment,"image":m.previewImage}})],1),_c('h5',[_vm._v(_vm._s(m.comment))])])]},proxy:true}],null,true)})],1)}),0)]}}],null,true)}),(_vm.allowCampaignTypes.includes(_vm.campaign.type))?[_c('h3',[_vm._v(_vm._s(_vm.titles.rs1.clickType))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"d-inline-block",attrs:{"error-messages":errors},model:{value:(_vm.campaign.pushClickType),callback:function ($$v) {_vm.$set(_vm.campaign, "pushClickType", $$v)},expression:"campaign.pushClickType"}},_vm._l((_vm.clickTypeList),function(m,index){return _c('div',{key:index,staticClass:"mb-5"},[_c('v-radio',{staticClass:"ma-0",attrs:{"value":m.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('h4',[_vm._v(" "+_vm._s(m.text)+" "),(m.info)?_c('InformationHover',{attrs:{"title":m.text,"subTitle":m.comment,"image":m.previewImage}}):_vm._e()],1),_c('h5',[_vm._v(_vm._s(m.comment))])])]},proxy:true}],null,true)})],1)}),0)]}}],null,true)})]:_vm._e(),(_vm.campaign.type != null)?[_c('CampaignUser'),_c('h3',[_vm._v(_vm._s(_vm.titles.rs1.campaignName))]),_c('ValidationProvider',{attrs:{"rules":{ required: true, max: _vm.campaignNameStringMax }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":_vm.campaignNameStringMax,"error-messages":errors,"placeholder":_vm.placeholder.campaignName,"required":"","outlined":""},model:{value:(_vm.campaign.name),callback:function ($$v) {_vm.$set(_vm.campaign, "name", $$v)},expression:"campaign.name"}})]}}],null,true)}),(!_vm.campaign.isTypeCoupon)?[_c('h3',[_vm._v(_vm._s(_vm.titles.rs1.landingUrl))]),_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              regex: _vm.urlRegex
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","placeholder":_vm.placeholder.landingUrl,"outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.campaign.landingUrl && errors.length === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openInNewTab(_vm.campaign.landingUrl)}}},Object.assign({}, onTooltip)),[_c('v-icon',_vm._g({},onTooltip),[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("새탭에서 링크 열기")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.campaign.landingUrl),callback:function ($$v) {_vm.$set(_vm.campaign, "landingUrl", $$v)},expression:"campaign.landingUrl"}})]}}],null,true)})]:_vm._e()]:_vm._e()],2)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('CampaignContentFooter',{attrs:{"invalid":invalid}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }