var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('DidCampaignServiceFilters',{attrs:{"loading":_vm.indexLoading},on:{"search":function($event){return _vm.fetchDidCampaignServices()}}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.indexLoading,"size":"small","rowKey":"id","columns":_vm.columns,"bordered":"","data-source":_vm.didCampaignServices,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleRowClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(value, record, index){return [_vm._v(" "+_vm._s(_vm.pagination.total - (_vm.pagination.perPage * _vm.pagination.page - _vm.pagination.perPage) - index)+" ")]}},{key:"name",fn:function(value, record){return [_c('div',{staticClass:"sub-title-1 text-truncate",staticStyle:{"max-width":"250px","min-width":"200px !important"}},[_vm._v(" "+_vm._s(value)+" "),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showReportDialogClick(record)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-chart-areaspline-variant")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("일간 차트")])])],1)])]}},{key:"totalPoint",fn:function(value){return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(value ? value.toLocaleString() : 0)+" 원 ")])]}},{key:"didCampaigns",fn:function(value){return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(value.length.toLocaleString())+" 건 ")])]}},{key:"isExpired",fn:function(value, record){return [_c('v-chip',{staticClass:"row ma-0 align-center justify-center",staticStyle:{"max-width":"80px"},attrs:{"small":"","color":!value ? 'success' : 'error',"outlined":"","label":""}},[_vm._v(" "+_vm._s(!value ? '진행중' : '종료')+" "),(record.statusInfo.value === 'C')?[_vm._v(" ("+_vm._s(record.statusInfo.text)+") ")]:_vm._e()],2)]}},{key:"selectCount",fn:function(value){return [(value.length)?_c('v-tooltip',{attrs:{"right":"","maxWidth":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('div',_vm._g({staticClass:"text-right"},Object.assign({}, tooltipOn)),[_vm._v(" "+_vm._s(value.length.toLocaleString())+" 건 ")])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},_vm._l((value),function(item,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":"","color":"white"}},[_vm._v(" "+_vm._s(item)+" ")])}),1)]):_c('div',{staticClass:"text-right"},[_vm._v("0 개")])]}},{key:"statusInfo",fn:function(value){return [_c('v-chip',{attrs:{"small":"","color":value.color,"outlined":"","label":""}},[_vm._v(" "+_vm._s(value.text)+" ")])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalCount)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":_vm.pagination.perPage,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1),_c('DidCampaignServiceDialog',{on:{"refresh":function($event){return _vm.fetchDidCampaignServicess(_vm.pagination.page)}}}),(_vm.selectedCampaignService)?_c('DidCampaignReportDialog',{attrs:{"campaignService":_vm.selectedCampaignService},on:{"close":function($event){_vm.selectedCampaignService = null},"refresh":function($event){return _vm.fetchDidCampaignServices(_vm.pagination.page)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }