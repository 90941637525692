import axios from 'axios';

export async function getFiltersApi(params) {
  try {
    const { data } = await axios.get(`/menu-filter`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getFilterColumnsApi(filterId, params) {
  try {
    const { data } = await axios.get(`/menu-filter/${filterId}`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getMenuApi(params) {
  try {
    const { data } = await axios.get(`/menus/user`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function storeFilterApi(params) {
  try {
    const { data } = await axios.post(`/menu-filter`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function changeNameAPI(filterId, params) {
  try {
    const { data } = await axios.patch(
      `/menu-filter/${filterId}/filter-name`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function deleteFilterApi(filterId) {
  try {
    const { data } = await axios.delete(`/menu-filter/${filterId}`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateFilterContentsAPI(filterId, params) {
  try {
    const { data } = await axios.patch(
      `/menu-filter/${filterId}/filter`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateColumnsAPI(filterId, params) {
  try {
    const { data } = await axios.patch(
      `/menu-filter/${filterId}/columns`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}
