// import AdminHome from '@/views/admin/pages/home/AdminHome';
import AdminMediaGroup from '@/views/admin/pages/media-group/AdminMediaGroup';
import AdminAdvertiser from '@/views/admin/pages/advertiser/AdminAdvertiser';
import AdminAdvertiserGroup from '@/views/admin/pages/advertiser-group/AdminAdvertiserGroup';
// import AdminCampaignTabs from "@/views/admin/pages/campaign/AdminCampaignTabs";
// import AdminCampaign from '@/views/admin/pages/campaign/AdminCampaign';
import AdminCampaign from '@/views/admin/pages/campaign/AdminCampaignFilters';
import AdminCampaignsAd from '@/views/admin/pages/campaigns-ad/AdminCampaignsAd';
import AdminPartner from '@/views/admin/pages/partner/AdminPartner';
import CampaignsCouponCategory from '@/views/admin/pages/coupon-category/CampaignsCouponCategory';

// import AdminSubReport from "@/views/admin/pages/sub-report/AdminSubReport";
import AdminReport from '@/views/admin/pages/report/AdminReportMain';
import AdminReportMedia from '@/views/admin/pages/report-media/AdminReportMedia';
import AdminReportMediaLocation from '@/views/admin/pages/report-media-location/AdminReportMediaLocation';
import AdminReportPushLive from '@/views/admin/pages/report-push-live/AdminReportMedia';

import AdminMessageDates from '@/views/admin/pages/message-dates/AdminMessageDates';
import AdminTag from '@/views/admin/pages/tag/AdminTag';
import AdminMediaTag from '@/views/admin/pages/media-tag/AdminTag';
import AdminInquiry from '@/views/admin/pages/inquiry/AdminInquiry';
import AdminPromotionInquiry from '@/views/admin/pages/promotion-inquiry/AdminPromotionInquiry';
import AdminNotice from '@/views/admin/pages/notice/AdminNotice';
import AdminPayment from '@/views/admin/pages/payment/AdminPayment';
import Network from '@/views/admin/pages/partners/network/Network';
import NetworkReport from '@/views/admin/pages/partners/network-report/NetworkReport';

import TargetCalculator from '@/views/admin/pages/target-calculator/TargetCalculator';
import NotificationPushes from '@/views/admin/pages/notification-pushes/NotificationPushes';

import AdminDashboard from '@/views/admin/pages/dashboard/AdminDashboard';
import AdminTasks from '@/views/admin/pages/admin-tasks/AdminTasks';

import DidBranch from '@/views/admin/pages/did-branch/AdminDidBranch';
import DidSettopBox from '@/views/admin/pages/did-settop-box/AdminDidSettopBox';
import DidApp from '@/views/admin/pages/did-app/AdminDidApp';
import DidCampaignService from '@/views/admin/pages/did-campaign/AdminDidCampaignService';
import CampaignResource from '@/views/admin/pages/campaign-resource/AdminCampaignResource';
import DidMediaGroup from '@/views/admin/pages/did-media-group/AdminDidMediaGroup';
import DidSchedule from '@/views/admin/pages/did-schedule/AdminDidSchedule';

// 매체 그룹 메뉴
const adminMediaGroup = [
  {
    path: '/admin/media-group',
    name: 'AdminMediaGroup',
    components: {
      auth: AdminMediaGroup
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  }
];

// 캠페인 메뉴
const adminCampaign = [
  {
    path: '/admin/campaign',
    name: 'AdminCampaign',
    components: {
      auth: AdminCampaign
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4, 6]
    }
  },
  {
    path: '/admin/campaigns/ad',
    name: 'AdminCampaignsAd',
    components: {
      auth: AdminCampaignsAd
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/coupon-category',
    name: 'CampaignsCouponCategory',
    components: {
      auth: CampaignsCouponCategory
    },
    meta: {
      requiresAuth: true,
      // requiresAuthLevel: [3,4]
      requiresAuthLevel: [4]
    }
  }
];

// 광고주 메뉴
const adminAdvertiser = [
  {
    path: '/admin/advertiser',
    name: 'AdminAdvertiser',
    components: {
      auth: AdminAdvertiser
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/advertiser-group',
    name: 'AdminAdvertiserGroup',
    components: {
      auth: AdminAdvertiserGroup
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4, 6]
    }
  }
];

// 파트너스 메뉴
const adminPartner = [
  {
    path: '/admin/partner',
    name: 'AdminPartner',
    components: {
      auth: AdminPartner
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  }
];

// 리포트 메뉴
const adminReport = [
  {
    path: '/admin/report',
    name: 'AdminReport',
    components: {
      auth: AdminReport
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  // {
  //     path: '/admin/sub-report',
  //     name: 'AdminSubReport',
  //     components: {
  // auth: AdminSubReport
  // },
  //     meta: {
  //         requiresAuth: true,
  //         requiresAuthLevel: [3, 4],
  //     }
  // },
  {
    path: '/admin/report-media',
    name: 'AdminReportMedia',
    components: {
      auth: AdminReportMedia
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/report/media/location',
    name: 'AdminReportMediaLocation',
    components: {
      auth: AdminReportMediaLocation
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/report/push-live',
    name: 'AdminReportPushLive',
    components: {
      auth: AdminReportPushLive
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/message-dates',
    name: 'AdminMessageDates',
    components: {
      auth: AdminMessageDates
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  }
];

// 태그 메뉴
const adminTag = [
  {
    path: '/admin/tag',
    name: 'AdminTag',
    components: {
      auth: AdminTag
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4, 6]
    }
  },
  {
    path: '/admin/media-tag',
    name: 'AdminMediaTag',
    components: {
      auth: AdminMediaTag
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4, 6]
    }
  }
];

// 1:1 문의관리 메뉴
const adminInquiry = [
  {
    path: '/admin/inquiry',
    name: 'AdminInquiry',
    components: {
      auth: AdminInquiry
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/promotion-inquiry',
    name: 'AdminPromotionInquiry',
    components: {
      auth: AdminPromotionInquiry
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  }
];

// 공지사항 관리 메뉴
const adminNotice = [
  {
    path: '/admin/notice',
    name: 'AdminNotice',
    components: {
      auth: AdminNotice
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  }
];

const adminPartners = [
  {
    path: '/admin/partners/network',
    name: 'Network',
    components: {
      auth: Network
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [4]
    }
  },
  {
    path: '/admin/partners/network/report',
    name: 'NetworkReport',
    components: {
      auth: NetworkReport
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [4]
    }
  }
];

const adminPayment = [
  {
    path: '/admin/payment',
    name: 'AdminPayment',
    components: {
      auth: AdminPayment
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  }
];

const adminUtilities = [
  {
    path: '/admin/target-calculator',
    name: 'TargetCalculator',
    components: {
      auth: TargetCalculator
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/notification-pushes',
    name: 'NotificationPushes',
    components: {
      auth: NotificationPushes
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/tasks',
    name: 'AdminTasks',
    components: {
      auth: AdminTasks
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [4]
    }
  }
];

const adminDid = [
  {
    path: '/admin/did/media-group',
    name: 'AdminDidMediaGroup',
    components: {
      auth: DidMediaGroup
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/did/branch',
    name: 'DidBranch',
    components: {
      auth: DidBranch
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/did/app',
    name: 'DidApp',
    components: {
      auth: DidApp
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/did/settop-box',
    name: 'DidSettopBox',
    components: {
      auth: DidSettopBox
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/did/campaign-resource',
    name: 'CampaignResource',
    components: {
      auth: CampaignResource
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/did/campaign',
    name: 'DidCampaignService',
    components: {
      auth: DidCampaignService
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  {
    path: '/admin/did/schedule',
    name: 'DidSchedule',
    components: {
      auth: DidSchedule
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [4]
    }
  }
];

const adminRoutes = [
  {
    path: '/admin',
    name: 'AdminDashboard',
    components: {
      auth: AdminDashboard
    },
    meta: {
      requiresAuth: true,
      requiresAuthLevel: [3, 4]
    }
  },
  ...adminMediaGroup,
  ...adminCampaign,
  ...adminAdvertiser,
  ...adminReport,
  ...adminTag,
  ...adminInquiry,
  ...adminNotice,
  ...adminPartners,
  ...adminPayment,
  ...adminPartner,
  ...adminUtilities,
  ...adminDid
];

export default adminRoutes;
