<template>
  <v-autocomplete
    v-model="selectedFilters"
    chips
    multiple
    hide-selected
    solo
    append-icon=""
    prefix="필터"
    :items="filterOptions"
    hide-no-data
    hide-details
    :menu-props="{
      closeOnContentClick: true,
      maxWidth: '300',
      nudgeRight: menuPropsOffsetLeft
    }"
    @focus="filterOptionsFocus"
    @click="filterOptionsFocus"
    :disabled="loading"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        pill
        :close="data.item.isDeletable"
        @click="data.select;"
        @click:close="
          () => {
            remove(data.item);
            data.item.items.selected = [];
            applyFilter();
          }
        "
      >
        <component
          v-if="data.item.value === 'period'"
          v-bind:is="data.item.component"
          @setDateRanges="
            v => {
              dateRanges = v;
            }
          "
          @applyFilter="applyFilter(false)"
          :title="data.item.text"
          :prefix="3"
        >
        </component>
        <component
          v-else
          v-bind:is="data.item.component"
          :title="data.item.text"
          :items="data.item.items"
          :detail="data.item.detail"
          :default="data.item.default"
          :min="data.item.min"
          :max="data.item.max"
          :keyString="data.item.keyString"
          :textString="data.item.textString"
          @remove="remove(data.item)"
          :api="data.item.api"
          @callApi="callApi(data.item.callApi)"
          @applyFilter="applyFilter()"
          :emptyMessage="data.item.emptyMessage"
          :isSavedFilter="isSavedFilter"
        ></component>
      </v-chip>
      <v-icon
        v-if="
          filterOptions.length !== selectedFilters.length &&
          data.index + 1 === selectedFilters.length
        "
        class="py-2"
      >
        mdi-plus-circle
      </v-icon>
    </template>
    <template v-slot:item="data">
      <v-list-item-content style="width: 200px">
        <v-list-item-title v-html="data.item.text"></v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
            v-on="{ ...onTooltip }"
            icon
            @click="applyFilter(false)"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span class="title">새로고침</span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    FilterDatePicker: () => import('@/components/FilterDatePicker.vue'),
    FilterMenu: () => import('@/components/FilterMenu.vue')
  },
  props: {
    loading: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data() {
    return {
      menuPropsOffsetLeft: null,
      dateRanges: null,
      selectedFilters: ['period', 'measureStandard'],
      isSavedFilter: false,
      filterOptions: [
        {
          text: '기간',
          value: 'period',
          isDeletable: false,
          component: 'FilterDatePicker'
        },
        {
          text: '광고유형',
          value: 'campaignType',
          isDeletable: true,
          component: 'FilterMenu',
          detail: true,
          default: false,
          textString: 'text',
          keyString: 'value',
          min: 1,
          items: {
            list: [
              {
                text: '이미지',
                value: 'rolling'
              },
              {
                text: '텍스트',
                value: 'push'
              },
              {
                text: '메시지',
                value: 'message'
              }
            ],
            selected: []
          }
        },
        {
          text: '측정기준',
          value: 'measureStandard',
          isDeletable: false,
          component: 'FilterMenu',
          detail: true,
          default: true,
          textString: 'text',
          keyString: 'value',
          min: 1,
          max: 1,
          items: {
            list: [
              {
                text: '일간',
                value: 'date'
              },
              {
                text: '광고유형',
                value: 'campaign_type'
              }
              // {
              //   text: '매체',
              //   value: 'media_id'
              // }
            ],
            selected: [
              {
                text: '일간',
                value: 'date'
              }
            ]
          }
        },
        {
          text: '상태',
          value: 'activeStatus',
          default: false,
          isDeletable: true,
          detail: true,
          textString: 'text',
          keyString: 'value',
          component: 'FilterMenu',
          min: 1,
          items: {
            list: [
              {
                text: '활성',
                value: true
              },
              {
                text: '비활성',
                value: false
              }
            ],
            selected: []
          }
        },
        {
          text: '광고그룹',
          value: 'advertiserGroup',
          isDeletable: true,
          component: 'FilterMenu',
          textString: 'name',
          keyString: 'id',
          items: {
            list: [],
            selected: []
          },
          callApi: `getCampaignsApi`,
          api: true
        },
        {
          text: '캠페인',
          value: 'campaign',
          isDeletable: true,
          textString: 'name',
          keyString: 'id',
          component: 'FilterMenu',
          items: {
            list: [],
            selected: []
          },
          emptyMessage: '광고그룹을 선택해주세요.'
        }
        // {
        //   text: '매체그룹',
        //   value: 'mediaGroup',
        //   isDeletable: true,
        //   default: false,
        //   component: 'FilterMenu',
        //   textString: 'name',
        //   keyString: 'id',
        //   items: {
        //     list: [],
        //     selected: []
        //   },
        //   emptyMessage: '선택 가능한 매체그룹이 없습니다.'
        // }
      ]
    };
  },
  async mounted() {
    const { contents } = await this.getFiltersApi();
    if (contents?.filterOptions && contents?.selectedFilters) {
      this.filterOptions = contents.filterOptions;
      this.selectedFilters = contents.selectedFilters;
      this.applyFilter(false);
      this.isSavedFilter = true;
    } else {
      this.applyFilter(false);
    }
    // 필터옵션 오브젝트에 계정 리스트가 없을 경우 api 호출한다.
    const { items } = this.filterOptions.find(
      v => v.value === 'advertiserGroup'
    );
    if (items.list.length === 0) {
      await this.getAdvertiserGroupsApi();
    }

    // 필터옵션 - 매체그룹 아이템을 확인합니다.
    // const { items: mediaGroupItems } = this.filterOptions.find(
    //   v => v.value === 'mediaGroup'
    // );
    // if (mediaGroupItems.list.length === 0) {
    //   this.getMediaGroupsApi();
    // }
  },
  methods: {
    filterOptionsFocus(e) {
      this.menuPropsOffsetLeft = e.target.offsetLeft;
    },
    // 광고그룹 목록
    async getAdvertiserGroupsApi() {
      try {
        const { data } = await this.$axios.get(`/adt/report-advertiser-groups`);

        this.initFilterOptions('advertiserGroup', data.advertiserGroups);
        this.initFilterOptions('campaign');
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    //매체 그룹 목록
    // async getMediaGroupsApi() {
    //   try {
    //     const { data } = await this.$axios.get(`/adt/report-media-groups`);
    //     this.initFilterOptions('mediaGroup', data.mediaGroups);
    //     return true;
    //   } catch (err) {
    //     console.error(err);
    //     return false;
    //   }
    // },
    async getCampaignsApi() {
      try {
        const advertiserGroupIds = this.filterOptions
          .find(v => v.value === 'advertiserGroup')
          .items.selected.map(v => v.id);

        const { data } = await this.$axios.get(`/adt/report-campaigns`, {
          params: {
            advertiserGroupIds: advertiserGroupIds
          }
        });

        this.initFilterOptions('campaign', data.campaigns);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    //선택 필터 항목 삭제
    remove(item) {
      const index = this.selectedFilters.indexOf(item.value);
      if (index >= 0) this.selectedFilters.splice(index, 1);
    },
    applyFilter(syncFilter = true) {
      const filter = {
        dateRanges: this.dateRanges,
        campaignTypes: this.filterOptions
          .find(v => v.value === 'campaignType')
          .items.selected.map(v => v.value),
        measureStandard: this.filterOptions.find(
          v => v.value === 'measureStandard'
        ).items.selected[0].value,
        activeStatus: this.filterOptions
          .find(v => v.value === 'activeStatus')
          .items.selected.map(v => v.value),
        advertiserGroupIds: this.filterOptions
          .find(v => v.value === 'advertiserGroup')
          .items.selected.map(v => v.id),
        campaignIds: this.filterOptions
          .find(v => v.value === 'campaign')
          .items.selected.map(v => v.id)
        // mediaGroupIds: this.filterOptions
        //   .find(v => v.value === 'mediaGroup')
        //   .items.selected.map(v => v.id)
      };
      this.$emit('applyFilters', filter);
      // 싱크 옵션에 따라 서버와 동기화 합니다.
      if (syncFilter) {
        this.setFiltersApi();
      }
    },
    async setFiltersApi() {
      try {
        const data = {
          visitor_id: this.user.visitorId,
          view: 'report',
          type: 'filter',
          contents: {
            selectedFilters: this.selectedFilters,
            filterOptions: this.filterOptions
          }
        };
        await this.$axios.post(`menus/user`, data);
      } catch (err) {
        console.error(err);
      }
    },
    initFilterOptions(key, list = []) {
      const { items } = this.filterOptions.find(v => v.value === key);

      //광고그룹 초기화
      items.list = list;
      items.selected = [];

      //현재 선택되어있는 필터에서 해당 옵션 삭제
      const index = this.selectedFilters.indexOf(key);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
      }
    },
    async getFiltersApi() {
      try {
        const { data } = await this.$axios.get(`menus/user`, {
          params: {
            visitor_id: this.user.visitorId,
            view: 'report',
            type: 'filter'
          }
        });
        // console.log(`getFilterApi`, data);
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    // 동적으로 api 를 호출하기 위한 함수입니다.
    async callApi(apiName) {
      // api 가 존재하고 호출이 성공한 경우 applyFilter 호출하고 applyFilter 내부에서 filterOptions 를 setFilterApi를 통해 서버와 동기화 한다.
      if (apiName && (await this[apiName]())) {
        this.applyFilter();
      }
    }
  }
};
</script>

<style></style>
