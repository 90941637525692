<template>
  <v-dialog
    v-model="openDialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card style="background-color:#dedede;">
      <v-toolbar dark>
        <v-btn icon dark @click="exit()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >광고 그룹 {{ isRegisterDialog ? '등록' : '수정' }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <advertiser-group-dialog-content />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdvertiserGroupDialogContent from './AdvertiserGroupDialogContent.vue';
export default {
  components: {
    AdvertiserGroupDialogContent
  },
  props: ['openDialog'],
  data() {
    return {
      editExitSwalTitle: '광고 그룹 수정화면 닫기',
      registerExitSwalTitle:
        '지금 광고 그룹 등록을 닫으면<br> 입력한 내용이 모두 사라져요!'
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'advertiserGroup/dialog'
    }),
    isRegisterDialog() {
      return this.dialog.register;
    }
  },
  created() {
    this.initAdvertiserGroup();
  },
  mounted() {
    const backButtonRouteGuardEdit = this.$router.beforeEach(
      (to, from, next) => {
        if (this.openDialog === true) {
          /* Blocking back button in menu route */
          next(false);
          this.exit();
        } else {
          /* allowing all other routes*/
          next(true);
        }
      }
    );

    this.$once('hook:destroyed', () => {
      backButtonRouteGuardEdit();
    });
  },
  methods: {
    ...mapActions({
      initAdvertiserGroup: 'advertiserGroup/initAdvertiserGroup'
    }),
    exit() {
      this.$Swal
        .fire({
          html: `<h3>${
            this.isRegisterDialog
              ? this.registerExitSwalTitle
              : this.editExitSwalTitle
          }</h3>`,
          showCancelButton: true,
          confirmButtonText: '닫기',
          cancelButtonText: '취소'
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$emit('closeDialog');
            this.initAdvertiserGroup();
          }
        });
    }
  }
};
</script>
