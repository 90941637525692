import Vue from 'vue';
import { extend } from 'vee-validate';

import {
  required,
  digits,
  email,
  max,
  min,
  regex,
  numeric,
  max_value,
  min_value
} from 'vee-validate/dist/rules';

extend('digits', {
  ...digits,
  message: ' {length} 자리의 숫자로만 입력해주세요. 현재:({_value_})'
});

extend('required', {
  ...required,
  message: '필수 항목입니다.'
});

extend('max', {
  ...max,
  message: '최대 {length}자 까지 입력가능합니다.'
});

extend('min', {
  ...min,
  message: '최소 {length}자 이상 입력해주세요.'
});

extend('regex_mac_address', {
  ...regex,
  message: '올바른 형식의 값을 입력해주세요.'
});

extend('regex', {
  ...regex,
  message: '올바른 값을 입력해주세요. 예) https://example.com'
});

extend('email', {
  ...email,
  message: '잘못된 형식의 이메일 주소입니다.'
});

extend('numeric', {
  ...numeric,
  message: '숫자만 입력해주세요.'
});

extend('max_value', {
  ...max_value,
  message: '입력값 범위가 올바르지 않습니다. 최대 {max} 이하로 입력해주세요.'
});

extend('min_value', {
  ...min_value,
  message: `입력값 범위가 올바르지 않습니다. 최소 {min} 이상으로 입력해주세요.`
});

extend('min_value_krw', {
  validate(value, { min_value_krw }) {
    if (Number(String(value).replaceAll(',', '')) >= Number(min_value_krw)) {
      return true;
    }
    return `입력값 범위가 올바르지 않습니다. 최소 ${Vue.options.filters.KRW(
      min_value_krw
    )} 이상으로 입력해주세요.`;
  },
  params: ['min_value_krw']
});

extend('max_value_krw', {
  validate(value, { max_value_krw }) {
    let val =
      typeof value === 'string' ? Number(value.replaceAll(',', '')) : value;

    if (val <= Number(max_value_krw)) {
      return true;
    }
    return `입력값 범위가 올바르지 않습니다. 최대 ${Vue.options.filters.KRW(
      max_value_krw
    )} 이하로 입력해주세요.`;
  },
  params: ['max_value_krw']
});

extend('max_krw', {
  validate(value, { max_krw }) {
    console.log(value);
    if (String(value).replaceAll(',', '').toString().length <= max_krw) {
      return true;
    }
    return `허용 범위를 초과했습니다.`;
  },
  params: ['max_krw']
});

extend('confirmed', (value, [target]) => {
  if (value === target) {
    return true;
  }
  return '비밀번호가 일치하지 않습니다.';
});

extend('confirmed_status', (value, [target]) => {
  if (value !== target && !!value) {
    return true;
  }
  return '변경할 값을 선택해 주세요.';
});
