var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-auto",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('a-card',[_c('a-descriptions',{staticClass:"py-0",attrs:{"bordered":""}},[_c('a-descriptions-item',{attrs:{"label":"기간","span":2}},[_c('FilterDatePicker',{style:({ display: 'inline-block' }),attrs:{"prefix":7,"isOpend":true,"defaultValue":_vm.dateRanges},on:{"setDateRanges":function (v) {
                _vm.dateRanges = v;
              },"remove":function($event){_vm.dateRanges = _vm.day},"applyFilter":function($event){return _vm.handleDateRanges()}}})],1),_c('a-descriptions-item',{attrs:{"label":"지점","span":1}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.placeList,"item-text":"brch_nm","item-value":"brch_no","hide-details":"","clearable":"","dense":"","outlined":"","multiple":"","placeholder":'지점 선택'},on:{"change":_vm.getTypeReportStatsApi},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(" "+_vm._s(item.brch_nm)+" "+_vm._s(_vm.filters.brchNos.length > 1 ? (" 외 " + (_vm.filters.brchNos.length - 1) + " 개") : '')+" ")])]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleBrchNos}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.filters.brchNos.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.branchIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 전체선택 ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.filters.brchNos),callback:function ($$v) {_vm.$set(_vm.filters, "brchNos", $$v)},expression:"filters.brchNos"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":12}},[_c('AdminMegaboxReportCharts',{attrs:{"changeData":!_vm.loading,"campaignType":'megabox-coupon',"title":"메가박스 쿠폰","dateRanges":_vm.dateRanges,"chartData":_vm.chartStats}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"py-3"},[(_vm.columns && !_vm.loading)?_c('Table',{staticClass:"pa-3",staticStyle:{"white-space":"nowrap"},attrs:{"data-source":_vm.stats,"columns":_vm.columns,"rowKey":function (record, index) {
              return ("" + (record.date));
            },"rowClassName":function (record, index) {
              if (index === 0) {
                return 'table-summary-rows';
              }
            },"bordered":"","pagination":false,"loading":_vm.loading,"size":"small","scroll":{
            x: true
          },"locale":{
            emptyText: '데이터가 없습니다.'
          }},scopedSlots:_vm._u([_vm._l((_vm.dates),function(date,dateIndex){return {key:date.value,fn:function(data, record){return [_c('span',{key:("" + dateIndex),class:((_vm.getGapIcon(record, dateIndex).color) + "--text darken-3")},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(data.toLocaleString()))]),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-icon',{attrs:{"color":_vm.getGapIcon(record, dateIndex).color}},[_vm._v(_vm._s(_vm.getGapIcon(record, dateIndex).icon))]),_vm._v(" "+_vm._s(_vm.getGapIcon(record, dateIndex).percent)+" ")],1)])]}}}),{key:"localeString",fn:function(value){return [_vm._v(" "+_vm._s(value > 0 ? value.toLocaleString() : '-')+" ")]}}],null,true)},_vm._l((_vm.dates),function(date,dateIndex){return _c('DayOfWeek',{key:dateIndex,attrs:{"slot":date.value,"date":date.value},slot:date.value})}),1):_vm._e()],1)],1)],1),(_vm.stats.length > 0)?_c('SpeedDialMenu',{attrs:{"type":_vm.type,"headers":_vm.columns,"tableOptions":_vm.tableOptions,"filters":{
      dateRanges: _vm.filters.dateRanges
    },"chartList":_vm.chartList,"issetLive":_vm.issetLive,"brchNos":_vm.filters.brchNos}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }