<template>
  <div>
    <v-menu
      v-model="datepicker"
      :close-on-content-click="false"
      transition="scale-transition"
      max-width="450px"
      min-width="450px"
    >
      <template v-slot:activator="{ on }">
        <v-chip
          slot="activator"
          v-on="on"
          @click="
            () => {
              prefixDateIndex = selectedPrefixDateIndex;
              dateRanges = prefixDates[selectedPrefixDateIndex].dateRanges;
            }
          "
        >
          {{ title }}:
          {{
            selectedPrefixDateIndex === 0
              ? `${selectedDateRanges[0]} ~ ${selectedDateRanges[1]}`
              : prefixDates[selectedPrefixDateIndex].text
          }}
        </v-chip>
      </template>

      <v-card>
        <v-toolbar flat dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="datepicker = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="d-flex">
          <div
            class="d-inline-block"
            style="border-right: 1px solid #dedede; width: 150px"
          >
            <v-list dense>
              <v-list-item-group v-model="prefixDateIndex" mandatory>
                <v-list-item
                  v-for="(prefixDate, index) in prefixDates"
                  :key="index"
                  @click="
                    () => {
                      if (!index) {
                        return false;
                      }
                      dateRanges = prefixDate.dateRanges;
                    }
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ prefixDate.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="d-block">
            <v-row align="center" class="ma-0 px-2">
              <v-col class="ma-0 pa-0">
                <v-text-field
                  class="my-3"
                  v-bind="textFieldAttr"
                  :value="dateRanges[0]"
                  @blur="changeDateRanges($event, 0)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="auto" class="ma-0 py-0"> ~ </v-col>
              <v-col class="ma-0 pa-0">
                <v-text-field
                  class="my-3"
                  v-bind="textFieldAttr"
                  :value="dateRanges[1]"
                  @blur="changeDateRanges($event, 1)"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-date-picker
              v-model="dateRanges"
              v-bind="datepickerAttr"
              :min="minDay"
              :max="maxDay"
              no-title
              range
              color="secondary"
              width="300"
              reactive
              scrollable
              multiple
              @click:date="initPrefix()"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="setDateRanges()"
                :disabled="!dateRanges.length"
                >적용</v-btn
              >
            </v-date-picker>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    title: {
      type: String,
      default: '기간'
    },
    prefix: {
      type: Number,
      default: 3
    },
    minDay: {
      type: String,
      default: '2021-04-01'
    },
    isOpend: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultValue: {
      type: Array,
      default: () => []
    },
    maxDay: {
      type: String,
      required: false,
      default: moment().format('YYYY-MM-DD')
    }
  },
  data() {
    return {
      id: '5',
      day: this.$moment().format('YYYY-MM-DD'),
      dates: [],
      selectedDateRanges: [],
      dateRanges: [],
      datepicker: false,
      selectedPrefixDateIndex: 3,
      prefixDateIndex: 3,
      prefixDates: [],
      picker: '2021-03-24'
    };
  },
  computed: {
    /**@description:datepicker 속성  */
    datepickerAttr() {
      return {
        locale: 'ko-kr',
        noTitle: true,
        range: true,
        color: 'secondary',
        width: '100%',
        reactive: true,
        scrollable: true,
        multiple: true
      };
    },
    /**@description:textField 속성  */
    textFieldAttr() {
      return {
        outlined: true,
        hideDetails: true,
        dense: true
      };
    }
  },
  watch: {
    datepicker(value) {
      // datepicker 기본값이 open인 경우에만
      if (this.isOpend) {
        const dateRanges = JSON.parse(JSON.stringify(this.dateRanges));
        // 닫힐때
        if (!value && dateRanges.length === 0) {
          //현재 선택 아이템 갯수에 따라 autocomplete 선택 항목에서 삭제합니다.
          this.$emit('remove');
        } else {
          this.dateRanges = dateRanges;
        }
      }
    }
  },
  created() {
    // true 인경우 autocomplete에서 항목 추가시 바로 오픈
    if (this.isOpend && !this.defaultValue.length) {
      this.datepicker = true;
    }
    this.setPrefixDates();

    if (this.isOpend && this.defaultValue.length > 0) {
      this.dateRanges = JSON.parse(JSON.stringify(this.defaultValue));
      this.initPrefix();
      this.setDateRanges();
    } else {
      this.initDate(this.prefix);
    }
  },
  methods: {
    initDate(value = 3) {
      // props 기본값 설정된 경우
      if (value) {
        this.selectedPrefixDateIndex = value;
        this.prefixDateIndex = value;
        this.selectedDateRanges =
          this.prefixDates[this.selectedPrefixDateIndex].dateRanges;
        // slice 로 value 값만 copy
        this.$emit('setDateRanges', this.selectedDateRanges.slice());
      }
    },
    async setDateRanges() {
      if (!this.dateRanges[0]) {
        // 날짜 첫번째 값이 없으면 적용 안됨
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: `조회 시작일을 선택해 주세요.`,
          confirmButtonText: '확인'
        });
        this.setPrefixDates();
        return;
      }

      if (!this.dateRanges[1]) {
        this.dateRanges[1] = this.dateRanges[0];
      }
      this.selectedPrefixDateIndex = this.prefixDateIndex;
      this.selectedDateRanges = this.dateRanges.sort(
        (a, b) => new Date(a) - new Date(b)
      );
      this.prefixDates[this.prefixDateIndex].dateRanges = this.dateRanges;
      // slice 로 value 값만 copy
      await this.$emit('setDateRanges', this.dateRanges.slice());
      this.$emit('applyFilter');
      this.datepicker = false;
    },
    setPrefixDates() {
      const prefixDates = [
        {
          text: '맞춤',
          value: 'c',
          dateRanges: []
        },
        {
          text: '오늘 현재까지',
          value: '0d',
          dateRanges: [
            this.startDay(this.$moment().subtract(0, 'days')),
            this.endDay()
          ]
        },
        {
          text: '어제',
          value: '1d',
          dateRanges: [
            this.startDay(this.$moment().subtract(1, 'days')),
            this.endDay(this.$moment().subtract(1, 'days'))
          ]
        },
        {
          text: '지난 7일',
          value: '7d',
          dateRanges: [
            this.startDay(this.$moment().subtract(6, 'days')),
            this.endDay()
          ]
        },
        {
          text: '지난 30일',
          value: '30d',
          type: 'd',
          dateRanges: [
            this.startDay(this.$moment().subtract(29, 'days')),
            this.endDay()
          ]
        },
        {
          text: '이번달 현재까지',
          value: '0m',
          dateRanges: [
            this.startDay(this.$moment().startOf('month')),
            this.endDay()
          ]
        },
        {
          text: '지난달',
          value: '1m',
          dateRanges: [
            this.startDay(
              this.$moment().subtract(1, 'months').startOf('month')
            ),

            this.endDay(this.$moment().subtract(1, 'months').endOf('month'))
          ]
        },
        {
          text: '전체',
          value: 'a',
          dateRanges: [this.minDay, this.maxDay]
        }
      ];

      this.prefixDates = prefixDates;
    },
    initPrefix() {
      if (this.prefixDateIndex !== 0) {
        this.prefixDateIndex = 0;
      }
    },
    changeDateRanges(e, index) {
      this.initPrefix();
      this.dateRanges[index] = e.target.value;
    },
    startDay(day) {
      const minDay = this.$moment(this.minDay, 'YYYY-MM-DD');
      if (minDay.diff(day, 'days') >= 0) {
        return minDay.format('YYYY-MM-DD');
      } else {
        return day.format('YYYY-MM-DD');
      }
    },
    endDay(day = null) {
      const minDay = this.$moment(this.minDay, 'YYYY-MM-DD');

      if (day === null) {
        day = this.$moment();
      }
      if (minDay.diff(day, 'days') >= 0) {
        return minDay.format('YYYY-MM-DD');
      } else {
        return day.format('YYYY-MM-DD');
      }
    }
  }
};
</script>
