import { DidBranch } from '@/model/admin/did';
import { getRegionOptionsApi } from '@/api/common/ConfigAPI';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      dialog: false
    },
    branch: new DidBranch(),
    dialog: false,
    scheduleDialog: false, // 스케줄 상세
    filters: {
      isActive: '',
      categories: ['cinema'],
      companyCodes: ['megabox'],
      scheduleDayIds: [1, 2, 3, 4, 5, 6, 7],
      name: '',
      didMediaGroupIds: []
    },
    regionDepth1Id: [],
    regionDepth2Id: []
  },
  getters: {
    branch(state) {
      return state.branch;
    },
    dialog(state) {
      return state.dialog;
    },
    scheduleDialog(state) {
      return state.scheduleDialog;
    },
    loading(state) {
      return state.loading;
    },
    filters(state) {
      return state.filters;
    },
    regionDepth1Id(state) {
      return state.regionDepth1Id;
    },
    regionDepth2Id(state) {
      return state.regionDepth2Id;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_ScheduleDialog(state, dialog) {
      state.scheduleDialog = dialog;
    },
    SET_Branch(state, branch) {
      state.branch = new DidBranch(branch, true);
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_AreaOption(state, { regionDepth1Id = [], regionDepth2Id = [] }) {
      state.regionDepth1Id = regionDepth1Id;
      state.regionDepth2Id = regionDepth2Id;
    },
    SET_Filters(state) {
      state.filters = {
        isActive: '',
        categories: ['cinema'],
        companyCodes: ['megabox'],
        scheduleDayIds: [1, 2, 3, 4, 5, 6, 7],
        name: '',
        didMediaGroupIds: []
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    async openDialog({ commit }, branch = undefined) {
      commit('SET_Branch', new DidBranch());

      if (branch) {
        commit('SET_Branch', branch);
      }

      commit('SET_Dialog', true);
    },
    async openScheduleDialog({ commit }) {
      commit('SET_ScheduleDialog', true);
    },
    closeDialog({ commit }, dialogType = '') {
      commit(`SET_${dialogType}Dialog`, false);
    },
    async fetchRegionOptions({ commit }) {
      let regionList1 = [];
      let regionList2 = [];
      const [error, data] = await getRegionOptionsApi();
      if (error) {
        console.log(error);
      } else {
        const { regions1, regions2 } = data;
        regionList1 = regions1;
        regionList2 = regions2;
      }

      commit('SET_AreaOption', {
        regionDepth1Id: regionList1,
        regionDepth2Id: regionList2
      });
    }
  }
};
