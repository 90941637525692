<template>
  <v-container fluid>
    <v-row>
      <v-col class="mx-auto" cols="12" md="6" sm="12">
        <ValidationObserver ref="networkContentRef" v-slot="{ invalid }">
          <form @submit.prevent="submit" class="p-5">
            <v-card style="padding:30px">
              <h2 class="font-weight-bold py-2">기본 입력 사항</h2>
              <v-row>
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-select
                      v-model="network.type"
                      :items="NetworkTypeList"
                      label="네트워크 유형"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="network.name"
                      label="네트워크 별명"
                      :counter="20"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model="network.uid"
                      label="네트워크 아이디"
                      :counter="255"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="network.impBidFloor"
                      label="입찰단가(CPM)"
                      :error-messages="errors"
                      required
                      outlined
                      suffix="원"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|max_value:10"
                  >
                    <v-text-field
                      v-model="network.ctr"
                      :error-messages="errors"
                      label="CTR"
                      required
                      outlined
                      suffix="%"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" v-if="medias.length > 0">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-autocomplete
                      v-model="network.mediaId"
                      :items="medias"
                      :error-messages="errors"
                      item-text="name"
                      item-value="id"
                      hide-no-data
                      label="매체"
                      clearable
                      outlined
                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <h2 class="font-weight-bold py-2">앱 정보 입력</h2>
              <v-row>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="network.appId"
                      label="앱 아이디"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="network.appName"
                      label="앱 이름"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="network.appBundle"
                      label="앱 번들"
                      :error-messages="errors"
                      required
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card>
            <v-btn
              :disabled="invalid"
              color="secondary"
              class="mt-4 mr-4"
              type="submit"
              large
              :loading="loading"
            >
              {{ isDialogTypeRegister ? '등록' : '수정' }}하기
            </v-btn>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  createNetworkApi,
  updateNetworkApi
} from '@/api/admin/partners/NetworkAPI';

import { getMediasLightAPI } from '@/api/admin/MediaAPI';
import { NetworkTypeList } from '@/config/admin/network';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      loading: false,
      medias: [],
      NetworkTypeList: NetworkTypeList
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'network/dialog',
      network: 'network/network',
      isDialogTypeRegister: 'network/isDialogTypeRegister'
    })
  },
  watch: {
    dialog: {
      deep: true,
      handler() {
        this.$refs.networkContentRef.reset();
      }
    }
  },
  created() {
    this.fetchMedias();
  },
  methods: {
    async fetchMedias() {
      const [error, data] = await getMediasLightAPI();
      // console.log(data);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        this.medias = data.medias;
      }
    },
    async submit() {
      this.loading = true;
      const valid = await this.$refs.networkContentRef.validate();
      if (valid) {
        let error;
        if (this.isDialogTypeRegister) {
          [error] = await createNetworkApi(this.network);
        } else {
          [error] = await updateNetworkApi(this.network);
        }
        if (error) {
          this.$axiosErrorAlert();
          console.error(error);
        } else {
          this.$router.go();
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style></style>
