import PaymentComplete from "../components/payment/PaymentComplete.vue";

const payment = [{
    path: '/payment/complete',
    name: 'PaymentComplete',
    components: {
        public: PaymentComplete,
    }
}];

const routes = [
    ...payment
];

export default routes;