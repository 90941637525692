export default {
    namespaced: true,

    state: {
        orderNo: null,

    },
    getters: {
        orderNo(state) {
            return state.orderNo
        },
    },
    mutations: {
        SET_OrderNo(state, value) {
            state.orderNo = value
        },
    },
    actions: {
        setOrderNo({
            commit
        }, orderNo) {
            commit('SET_OrderNo', orderNo);
        },
        initOrderNo({
            commit
        }) {
            commit('SET_OrderNo', null)
        }
    }
}