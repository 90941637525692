<template>
  <div
    :ref="chartRef"
    style="width: 100%; height: 350px; max-height: 400px"
  ></div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    dateRanges: {
      type: Array,
      required: true
    },
    stats: {
      type: Array,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: [],
      chartReg: [],
      chart: null,
      chartTypes: [],
      loading: true,
      dayOfWeekString: ['일', '월', '화', '수', '목', '금', '토']
    };
  },
  computed: {
    chartRef() {
      return 'bottomSheetChartRef';
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    stats(value) {
      if (value.length > 0) {
        // console.log(`value`, value);
        this.setChart();
      }
    }
  },
  created() {
    this.initData();
    window.addEventListener('resize', this.resizeChart);
  },
  mounted() {
    this.setChart();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
    this.initChart();
  },
  methods: {
    initChart() {
      if (this.chart != null && this.chart != '' && this.chart != undefined) {
        this.chart.dispose();
      }
    },
    async setChart() {
      const chartData = await this.getChartData();
      this.drawChart(chartData);
    },
    resizeChart() {
      if (this.chart != null && this.chart != undefined) {
        this.chart.resize();
      }
    },
    initData() {
      const colors = [
        '#2196F3',
        '#90CAF9',
        '#1E88E5',
        '#0D47A1',
        '#9FA8DA',
        '#5C6BC0',
        '#303F9F',
        '#B39DDB',
        '#5E35B1',
        '#651FFF',
        '#7B1FA2',
        '#BA68C8',
        '#E1BEE7'
      ];
      const chartTypes = [];

      if (this.isDetail) {
        // 상세
        this.stats.map(item => {
          const d = chartTypes.find(type => type.value === `imps${item.id}`);
          if (!d) {
            chartTypes.push({
              text: item.name,
              value: 'imps' + item.id,
              color: colors[chartTypes.length]
            });
          }
        });
      } else {
        chartTypes.push({
          text: '노출',
          value: 'imps',
          color: '#F2597F'
        });
      }

      this.chartTypes = chartTypes;
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },

    async getChartData() {
      this.setDates();
      try {
        this.loading = true;
        const { dates, stats } = this;

        // 일별 데이터 체크 후 undefined 처리
        const chartData = [];
        dates.map(date => {
          const data = stats.filter(v => v.date === date.value);

          if (data.length === 0) {
            chartData.push({
              date: date.value,
              imps: 0
            });
          } else {
            const keys = Array.from(
              new Set(stats.map(item => `imps${this.isDetail ? item.id : ''}`))
            );

            keys.map(item => {
              const imps = data.reduce((acc, cur) => {
                return acc + (cur[`${item}`] ?? 0);
              }, 0);

              const temp = {
                date: `${date.value}`
              };

              temp[`${item}`] = imps;

              chartData.push(temp);
            });
          }
        });

        return chartData;
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    drawChart(chartData) {
      this.initChart();
      // 설정값 셋업
      const { chartTypes, title, $echarts, dates } = this;

      // 차트 생성
      const chartDom = this.$refs[this.chartRef];
      const myChart = $echarts.init(chartDom);
      const colors = chartTypes.map(chart => chart.color);

      const xAxis = [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: dates.map(
            date =>
              `${date.value} ${
                this.dayOfWeekString[this.$moment(date.value).day()]
              }`
          ) // 날짜
        }
      ];

      const legend = {
        data: chartTypes.map(chart => chart.text),
        bottom: 0,
        selected: {}
      };

      const series = chartTypes.map(type => {
        const filteredData = dates.map(date => {
          let idx = chartData.findIndex(item => item.date === date.value);
          let tempData = {
            date: date.value
          };

          tempData[`${type.value}`] =
            idx > 0 ? chartData[idx][`${type.value}`] : 0;

          return tempData;
        });

        return {
          name: type.text,
          type: 'bar',
          data: filteredData.map(item => item[`${type.value}`]),
          smooth: true,
          itemStyle: {
            opacity: 0.8,
            color: chartTypes.find(v => v.text === type.text).color
          }
        };
      });

      const option = {
        title: {
          text: this.showTitle ? this.title : null
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          containLabel: true
        },
        toolbox: {
          right: '20px',
          bottom: '20px',
          feature: {
            dataView: { readOnly: true, title: '로우 데이터' },
            saveAsImage: {
              show: true,
              title: '이미지 저장',
              name: `${title} 차트`
            }
          }
        },
        legend: legend,
        xAxis: xAxis,
        yAxis: this.chartTypes.map(type => ({
          type: 'value',
          name: !this.isDetail ? type.text : '',
          position: 'left',
          axisLine: {
            show: true,
            lineStyle: {
              color: this.chartTypes.find(v => v.text === type.text).color
            }
          },
          axisLabel: {
            formatter: '{value}'
          }
        })),
        series: series
      };
      this.chart = myChart;
      option && myChart.setOption(option);
    },

    // 해당 값의 자릿수 단위별 최댓값
    getMax(chartData, type) {
      const max = Math.max(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );

      let digits = 0;
      if (max > 1) {
        digits = Math.pow(10, Math.ceil(max).toString().length);
        return Math.ceil(max / digits) * digits;
      } else if (max > 0 && max < 1) {
        // 소수점 자릿수 구하기
        const subMax = Number(max.toFixed(2).toString().split('.')[1]);

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      } else {
        return 0;
      }
    },
    // 해당 값의 자릿수 단위별 최솟값
    getMin(chartData, type) {
      const min = Math.min(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );
      let digits = 0;
      if (min >= 1) {
        digits = Math.pow(10, Math.ceil(min).toString().length);
        // console.log(Math.ceil(min / digits) * digits);
        return Math.ceil(min / digits) * digits;
      } else {
        // 소수점 자릿수 구하기
        const subMax = Number(min.toFixed(2).toString().split('.')[1]);

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      }
    }
  }
};
</script>

<style></style>
