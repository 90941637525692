import axios from 'axios';

/**
 * @description: DID 매체 목록 전체 조회(콤보목록)
 * */
export async function getDidMediaGroupOptionsApi(params) {
  try {
    const { data } = await axios.get(
      `/admin/did/media-groups/light`,
      params ?? undefined
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 그룹 목록 전체 조회
 * */
export async function getDidMediaGroupsApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/media-groups`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 그룹 상세 조회
 * */
export async function getDidMediaGroupApi(mediaGroupId) {
  try {
    const { data } = await axios.get(`/admin/did/media-group/${mediaGroupId}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 그룹 상세 수정
 * */
export async function updateDidMediaGroupApi(mediaGroupId, params) {
  try {
    const { data } = await axios.put(
      `/admin/did/media-group/${mediaGroupId}`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 그룹 생성
 * */
export async function createDidMediaGroupApi(params) {
  try {
    const { data } = await axios.post(`/admin/did/media-group`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 그룹 광고 송출여부 활성화
 * */
export async function activeDidMediaGroupApi(mediaGroupId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/media-group/${mediaGroupId}/active`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 그룹 광고 송출여부 비활성화
 * */
export async function inactiveDidMediaGroupApi(mediaGroupId) {
  try {
    const { data } = await axios.patch(
      `/admin/did/media-group/${mediaGroupId}/inactive`
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
