<template>
  <v-container fluid class="py-0">
    <v-row class="ma-0">
      <v-col cols="12" lg="5">
        <v-container class="px-5">
          <v-row>
            <v-col cols="12">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                캠페인 이름
              </h3>
              <v-text-field
                :value="didCampaignService.name"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                광고주 계정
              </h3>
              <v-text-field
                :value="didCampaignService.advertiser.name"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                캠페인 등록일
              </h3>
              <v-text-field
                :value="didCampaignService.createdDate"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
          </v-row>

          <v-row v-if="didCampaignService.campaignResource" align="center">
            <v-col class="pb-0">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                소재
              </h3>
            </v-col>

            <v-col cols="12" class="px-0">
              <video
                preload="metadata"
                :src="`${didCampaignService.campaignResource.url}#t=0.5`"
                controls
                class="mx-auto col-12"
              />
              <v-col cols="12" class="subtitle-2 font-weight-bold px-3 pt-0">
                - 이름: {{ didCampaignService.campaignResource.name }}<br />
                - 해상도: {{ didCampaignService.campaignResource.width }} X
                {{ didCampaignService.campaignResource.height }}
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-divider vertical />

      <v-col cols="12" lg="7">
        <v-container class="px-5">
          <v-row align="center">
            <v-col cols="auto" class="px-0">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                송출 내역 ( {{ didCampaignService.didCampaigns.length }} 건 )
              </h3>
            </v-col>
            <v-col
              cols="12"
              class="row ma-0 px-0 pt-0"
              v-if="selectedDateOptions.length > 0"
            >
              <v-col class="pa-0 pr-3 pt-1">
                <v-menu
                  v-model="showDateFilter"
                  :close-on-content-click="false"
                  :return-value.sync="filterDates"
                  transition="scale-transition"
                  ref="tableFiltersDateMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-autocomplete
                      v-on="on"
                      v-model="tableFilters.dates"
                      :items="selectedDateOptions"
                      multiple
                      outlined
                      placeholder="날짜를 선택하세요"
                      no-data-text="조회 가능한 날짜가 없습니다."
                      hide-details
                      class="custom-filter-selectbox"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      readonly
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="data.index === 0"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          label
                          style="border: none; padding: 0"
                          @click="() => (showDateFilter = true)"
                        >
                          <v-chip small>
                            {{ data.item }}
                            {{
                              tableFilters.dates.length > 1
                                ? ` 외 ${tableFilters.dates.length - 1} 개`
                                : ''
                            }}
                          </v-chip>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </template>

                  <v-card>
                    <v-toolbar flat dark>
                      <v-toolbar-title>기간</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn icon dark @click="showDateFilter = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <div class="d-flex">
                      <v-date-picker
                        v-model="filterDates"
                        :allowed-dates="
                          val => selectedDateOptions.includes(val)
                        "
                        :min="selectedDateOptions[0]"
                        :max="
                          selectedDateOptions[selectedDateOptions.length - 1]
                        "
                        locale="ko-kr"
                        no-title
                        color="secondary"
                        reactive
                        scrollable
                        multiple
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="secondary"
                          @click="handleFilterDateSelectAll()"
                        >
                          전체 선택
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="handleFilterDateChange()"
                        >
                          적용
                        </v-btn>
                      </v-date-picker>
                    </div>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col class="pa-0 pt-1">
                <v-autocomplete
                  v-model="tableFilters.didBranches"
                  :items="selectedBranchOptions"
                  multiple
                  outlined
                  clearable
                  placeholder="매체를 선택하세요"
                  no-data-text="조회 가능한 매체가 없습니다."
                  hide-details
                  class="custom-filter-selectbox"
                  prepend-inner-icon="mdi-store"
                  dense
                >
                  <template
                    v-slot:prepend-item
                    v-if="selectedBranchOptions.length > 0"
                  >
                    <v-list-item ripple @click="toggleSelectedDidBranches">
                      전체선택
                    </v-list-item>
                    <v-divider />
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="data.index === 0"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      label
                      style="border: none; padding: 0"
                      @click="data.select"
                    >
                      <v-chip small>
                        {{ data.item }}
                        {{
                          tableFilters.didBranches.length > 1
                            ? ` 외 ${tableFilters.didBranches.length - 1} 개`
                            : ''
                        }}
                      </v-chip>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col class="pa-0 pl-3 pt-1">
                <v-autocomplete
                  v-model="tableFilters.settopBoxes"
                  :items="selectedSettopBoxOptions"
                  multiple
                  outlined
                  clearable
                  placeholder="셋톱박스를 선택하세요"
                  no-data-text="조회 가능한 셋톱박스가 없습니다."
                  hide-details
                  class="custom-filter-selectbox"
                  prepend-inner-icon="mdi-set-top-box"
                  dense
                >
                  <template
                    v-slot:prepend-item
                    v-if="selectedSettopBoxOptions.length > 0"
                  >
                    <v-list-item ripple @click="toggleSelectedSettopBoxes">
                      전체선택
                    </v-list-item>
                    <v-divider />
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="data.index === 0"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      label
                      style="border: none; padding: 0"
                      @click="data.select"
                    >
                      <v-chip small>
                        {{ data.item }}
                        {{
                          tableFilters.settopBoxes.length > 1
                            ? ` 외 ${tableFilters.settopBoxes.length - 1} 개`
                            : ''
                        }}
                      </v-chip>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-col>
            <v-col
              cols="12"
              style="min-height: 500px; overflow: auto; border: 1px solid #ddd"
            >
              <a-table
                class="table-striped-rows"
                style="white-space: nowrap"
                size="small"
                rowKey="id"
                :columns="columns"
                bordered
                :data-source="filteredDidCampaigns"
                :scroll="{ x: true }"
                :row-selection="{
                  selectedRowKeys: selectedSchedules,
                  onChange: onSelectChange,
                  getCheckboxProps: getCheckboxProps
                }"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
              >
                <template slot="statusInfo" slot-scope="value">
                  <v-chip small outlined label :color="value.color">
                    {{ value.text }}
                  </v-chip>
                </template>

                <template slot="action" slot-scope="value, record">
                  <v-icon
                    v-if="!record.isStatusCancel"
                    left
                    @click.stop="handleDeleteClick(record.id)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </a-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-actions class="pb-0">
      <v-spacer></v-spacer>

      <v-btn
        color="secondary"
        @click="closeDialog"
        :loading="loading.dialog"
        outlined
      >
        닫기
      </v-btn>
    </v-card-actions>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['invalid'],
  computed: {
    ...mapGetters({
      didCampaignService: 'didCampaignService/didCampaignService',
      loading: 'didCampaignService/loading'
    }),
    columns() {
      return [
        {
          title: 'ID',
          align: 'center',
          dataIndex: 'id',
          sorter: (a, b) => new Date(a.id) - new Date(b.id)
        },
        {
          title: '날짜',
          align: 'center',
          dataIndex: 'scheduleDate',
          sorter: (a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate)
        },
        {
          title: '매체',
          align: 'center',
          dataIndex: 'didBranchName',
          scopedSlots: {
            customRender: 'didBranchName'
          },
          sorter: (a, b) => a.didBranchName.localeCompare(b.didBranchName)
        },
        {
          title: '셋톱박스',
          align: 'center',
          dataIndex: 'settopBoxName',
          sorter: (a, b) => a.settopBoxName.localeCompare(b.settopBoxName)
        },
        {
          title: '상태',
          align: 'center',
          dataIndex: 'statusInfo',
          scopedSlots: {
            customRender: 'statusInfo'
          },
          sorter: (a, b) => a.status.localeCompare(b.status)
        }
      ];
    },
    filteredDidCampaigns() {
      return this.didCampaignService.didCampaigns.filter(
        item =>
          this.tableFilters.dates.includes(item.scheduleDate) &&
          this.tableFilters.didBranches.includes(item.didBranch.name) &&
          this.tableFilters.settopBoxes.includes(item.settopBox.name)
      );
    },
    // 상세기간 날짜 목록
    selectedDateOptions() {
      const dates = this.didCampaignService.didCampaigns.map(
        item => item.scheduleDate
      );
      return Array.from(new Set(dates));
    },
    // 상세기간 매체 목록
    selectedBranchOptions() {
      const didBranches = this.didCampaignService.didCampaigns.map(
        item => item.didBranch.name
      );
      return Array.from(new Set(didBranches));
    },
    // 상세기간 매체 그룹 목록
    selectedSettopBoxOptions() {
      const didBranches = this.didCampaignService.didCampaigns.map(
        item => item.settopBoxName
      );
      return Array.from(new Set(didBranches));
    }
  },
  data() {
    return {
      today: this.$moment().format('YYYY-MM-DD'),
      tableFilters: {
        dates: [],
        didBranches: [],
        settopBoxes: []
      },
      reviewDialog: false,
      selectedSchedules: [],
      showDateFilter: false,
      filterDates: [] // 필터 날짜 temp value
    };
  },
  created() {
    this.tableFilters.dates = this.filterDates = this.selectedDateOptions;
    this.tableFilters.didBranches = this.selectedBranchOptions;
    this.tableFilters.settopBoxes = this.selectedSettopBoxOptions;
  },
  methods: {
    ...mapActions({
      closeDialog: 'didCampaignService/closeDialog'
    }),
    //선택 초기화
    initSelect() {
      this.selectedSchedules = [];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedSchedules = selectedRowKeys;
    },
    // 날짜 전체 선택
    toggleSelectedDates() {
      this.$nextTick(() => {
        if (
          this.tableFilters.dates.length === this.selectedDateOptions.length
        ) {
          this.tableFilters.dates = [];
        } else {
          this.tableFilters.dates = [...this.selectedDateOptions];
        }
      });
    },
    // 매체 전체 선택
    toggleSelectedDidBranches() {
      this.$nextTick(() => {
        if (
          this.tableFilters.didBranches.length ===
          this.selectedBranchOptions.length
        ) {
          this.tableFilters.didBranches = [];
        } else {
          this.tableFilters.didBranches = [...this.selectedBranchOptions];
        }
      });
    },
    toggleSelectedSettopBoxes() {
      this.$nextTick(() => {
        if (
          this.tableFilters.settopBoxes.length ===
          this.selectedSettopBoxOptions.length
        ) {
          this.tableFilters.settopBoxes = [];
        } else {
          this.tableFilters.settopBoxes = [...this.selectedSettopBoxOptions];
        }
      });
    },
    //선택가능한 체크박스인지 확인
    getCheckboxProps(record) {
      // TODO :: 취소 정책 필요함
      // 취소상태인 체크박스 || 이미 지난 날 ()
      const diabled = record.status === 'C' || record.scheduleDate < this.today;
      return {
        props: {
          disabled: diabled
        }
      };
    },

    /** @description: 필터 일정 전체 선택 & 취소 */
    handleFilterDateSelectAll() {
      this.filterDates =
        this.filterDates.length === this.selectedDateOptions.length
          ? []
          : [...this.selectedDateOptions];
    },
    /** @description: 필터 일정 적용 */
    handleFilterDateChange() {
      this.$refs.tableFiltersDateMenu.save(this.filterDates);
      this.tableFilters.dates = [...this.filterDates];
    }
  }
};
</script>

<style lang="scss"></style>
