<template>
  <div class="filters mt-8">
    <a-spin :spinning="loading">
      <a-form-model ref="storeForm" :model="form" :rules="rules">
        <a-descriptions title="계정 정보" bordered>
          <a-descriptions-item label="계정 활성 여부" :span="3">
            <a-select :style="{ width: '100%' }" v-model="form.approve">
              <a-select-option
                v-for="(item, index) in userApproveList"
                :key="index"
                :value="item.value"
              >
                <a-tag :color="item.color"> {{ item.text }} </a-tag>
              </a-select-option>
            </a-select>
          </a-descriptions-item>

          <a-descriptions-item label="ID" :span="3">
            <a-form-model-item ref="uid" prop="uid">
              <a-input v-model="form.uid" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="비밀번호" :span="3">
            <a-form-model-item ref="password" prop="password">
              <a-input v-model="form.password" type="password" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="이메일" :span="3">
            <a-form-model-item ref="email" prop="email">
              <a-input v-model="form.email" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="연락처" :span="3">
            <a-form-model-item ref="phone" prop="phone">
              <a-input v-model="form.phone" />
            </a-form-model-item>
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="매체그룹 정보" bordered class="mt-7">
          <a-descriptions-item label="광고 송출 여부" :span="3">
            <a-select :style="{ width: '100%' }" v-model="form.active">
              <a-select-option
                v-for="(item, index) in mediaActiveList"
                :key="index"
                :value="item.value"
              >
                <a-tag :color="item.color"> {{ item.text }} </a-tag>
              </a-select-option>
            </a-select>
          </a-descriptions-item>

          <a-descriptions-item label="매체 유형" :span="3">
            <a-select :style="{ width: '100%' }" v-model="form.type">
              <a-select-option
                v-for="(item, index) in mediaGroupTypeList"
                :key="index"
                :value="item.value"
              >
                <a-tag :color="item.color"> {{ item.text }} </a-tag>
              </a-select-option>
            </a-select>
          </a-descriptions-item>

          <a-descriptions-item label="매체그룹명" :span="3">
            <a-form-model-item ref="name" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-descriptions-item>
        </a-descriptions>

        <v-row>
          <v-col cols="12" class="text-center mt-5">
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :loading="loading"
              @click="submit"
            >
              등록
            </v-btn>
          </v-col>
        </v-row>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      userApproveList: [
        {
          text: '활성',
          value: 'Y',
          color: 'green',
        },
        {
          text: '비활성',
          value: 'W',
          color: 'red',
        },
      ],
      mediaActiveList: [
        {
          text: '활성',
          value: 'Y',
          color: 'green',
        },
        {
          text: '비활성',
          value: 'N',
          color: 'red',
        },
      ],
      mediaGroupTypeList: [
        {
          text: '직매체',
          value: 'D',
          color: 'blue',
        },
        {
          text: '대행사',
          value: 'A',
          color: 'purple',
        },
      ],
      loading: false,
      rules: {
        uid: [
          {
            required: true,
            message: '필수값 입니다.',
          },
        ],
        email: [
          {
            required: true,
            message: '필수값 입니다.',
          },
        ],
        name: [
          {
            required: true,
            message: '필수값 입니다.',
          },
        ],
        password: [
          {
            required: true,
            message: '필수값 입니다.',
          },
          { min: 4, message: '4자 이상 입력해주세요.' },
        ],
        phone: [
          {
            required: true,
            message: '필수값 입니다.',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      form: 'mediaGroup/form',
    }),
  },
  watch: {
    dialog() {
      this.$refs.storeForm.resetFields();
    },
  },
  methods: {
    ...mapActions({
      closeDialog: 'mediaGroup/closeDialog',
    }),
    submit() {
      this.$refs.storeForm.validate(valid => {
        if (valid) {
          this.store();
        } else {
          return false;
        }
      });
    },
    store() {
      this.loading = true;

      return this.$axios
        .post('/admin/media-groups', this.form)
        .then(res => {
          let type = 'error';
          let message = '등록 실패';
          let description = res.data.message;

          if (res.data.success) {
            type = 'success';
            message = '등록 성공';

            this.closeDialog('store');
            this.$emit('refresh');
          }

          this.$notification[type]({
            message: message,
            description: description,
          });

          this.loading = false;
        })
        .catch(err => {
          console.dir(err);
          this.$axiosErrorAlert();
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.ant-form-item {
  margin-bottom: 0 !important;
}
</style>
