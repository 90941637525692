<template>
  <v-card>
    <!-- <v-card-subtitle>{{ title }}</v-card-subtitle> -->
    <!-- <v-divider class="mx-4"></v-divider> -->
    <v-card-text>
      <v-row>
        <v-col>
          <div
            v-if="!loading"
            :ref="chartRef"
            style="width: 100%; min-height: 350px; max-height: 1000px"
          ></div>
          <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  getReportStatsTypeChartAPI,
  getReportStatsCouponChartAPI
} from '@/api/admin/report';
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    dateRanges: {
      type: Array,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartTypeList: [
        {
          text: '이미지',
          value: 'rolling',
          options: ['click', 'impression'],
          chart: 'campaignReportChart0'
        },
        {
          text: '텍스트',
          value: 'push',
          options: ['click', 'impression'],
          chart: 'campaignReportChart1'
        },
        {
          text: '메시지',
          value: 'message',
          options: ['click', 'impression'],
          chart: 'campaignReportChart2'
        },
        {
          text: '쿠폰',
          value: 'coupon',
          options: ['click', 'impression'],
          chart: 'campaignReportChart3'
        }
      ],
      dates: [],
      chartReg: [],
      chart: null,
      chartTypes: [],
      selectedChartTypes: [],
      loading: false
    };
  },
  computed: {
    chartRef() {
      return this.campaignType + 'ReportRef';
    },
    chartSeries() {
      let series = [
        {
          text: '노출',
          value: 'impression',
          type: 'line',
          color: '#5470C6',
          position: 'left',
          isMax: true
        },
        {
          text: '클릭',
          value: 'click',
          type: 'line',
          color: '#EE6666',
          isMax: true,
          yAxisIndex: 1
        },
        {
          text: 'CTR',
          value: 'ctr',
          type: 'bar',
          color: '#91CC75',
          formatter: '%',
          offset: 50,
          isMin: true,
          yAxisIndex: 2
        }
      ];

      if (this.campaignType === 'message') {
        series[0].text = '도달';
      }

      return series;
    }
  },
  mounted() {},
  watch: {
    dateRanges() {
      this.setReport();
    }
  },
  created() {
    this.initData();
    window.addEventListener('resize', this.resizeChart);
    this.setReport();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
    this.initChart();
  },
  methods: {
    initChart() {
      if (this.chart != null && this.chart != '' && this.chart != undefined) {
        this.chart.dispose();
      }
    },
    resizeChart() {
      if (this.chart != null && this.chart != undefined) {
        this.chart.resize();
      }
    },
    initData() {
      const selectedChartTypes = ['click', 'impression'];

      this.chartTypes = this.chartSeries;
      this.selectedChartTypes = selectedChartTypes;
    },
    async setReport() {
      const chartData = await this.getStats();
      this.drawChart3(chartData);
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },

    async getStats() {
      this.loading = true;
      this.setDates();
      let chartData = [];

      const params = {
        params: {
          campaignType: this.campaignType,
          dateRanges: this.dateRanges
        }
      };

      const [error, data] =
        this.campaignType !== 'coupon'
          ? await getReportStatsTypeChartAPI(params)
          : await getReportStatsCouponChartAPI(params);

      if (error) {
        console.log(error);
      } else {
        const { stats: stats } = data;
        const { dates } = this;

        chartData = dates.map(date => {
          const item = stats.findIndex(stat => date.value === stat.date);
          if (~item) {
            return stats[item];
          } else {
            return {
              date: date.value,
              impression: 0,
              click: 0,
              ctr: 0
            };
          }
        });
      }
      this.loading = false;

      return chartData;
    },
    drawChart3(chartData) {
      this.initChart();
      // 설정값 셋업
      const { chartTypes, title } = this;

      // 차트 생성
      const chartDom = this.$refs[this.chartRef];
      const myChart = this.$echarts.init(chartDom);
      const colors = [];
      const series = [];
      const yAxis = [];

      this.chartTypes.map(chart => {
        colors.push(chart.color);

        const tempSeries = {
          name: chart.text,
          type: chart.type,
          data: chartData.map(data => data[`${chart.value}`]),
          smooth: true,
          showSymbol: false
        };

        if (chart.yAxisIndex != undefined) {
          tempSeries['yAxisIndex'] = chart.yAxisIndex;
        }
        series.push(tempSeries);

        const yAxisTemp = {
          type: 'value',
          name: chart.text,
          position: chart.position ?? 'right',
          axisLine: {
            show: true,
            lineStyle: {
              color: chart.color
            }
          },
          axisLabel: {
            formatter: `{value} ${chart.formatter ?? ''}`
          },
          offset: chart.offset ?? 0
        };
        if (chart.isMin && this.campaignType != 'coupon') {
          yAxisTemp['max'] = this.getMin(chartData, `${chart.value}`);
        }

        if (chart.isMax && this.campaignType != 'coupon') {
          yAxisTemp['max'] = this.getMax(chartData, `${chart.value}`);
        }

        yAxis.push(yAxisTemp);
      });

      const xAxis = [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: chartData.map(data => data.date.slice(5)) // 날짜
        }
      ];

      const legend = {
        data: chartTypes.map(chart => chart.text),
        bottom: 0
      };

      const option = {
        title: {
          text: this.title
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '80px',
          right: '110px'
        },
        toolbox: {
          right: '20px',
          bottom: '20px',
          feature: {
            dataView: { readOnly: true, title: '로우 데이터' },
            saveAsImage: {
              show: true,
              title: '이미지 저장',
              name: `${title} 차트`
            }
          }
        },
        legend: legend,
        xAxis: xAxis,
        yAxis: yAxis,
        series: series
      };
      this.chart = myChart;
      option && myChart.setOption(option);
    },

    // 해당 값의 자릿수 단위별 최댓값
    getMax(chartData, type) {
      const max = Math.max(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );

      let digits = 0;
      if (max > 1) {
        digits = Math.pow(10, Math.ceil(max).toString().length);
        return Math.ceil(max / digits) * digits;
      } else if (max > 0 && max < 1) {
        // 소수점 자릿수 구하기
        const subMax = Number(
          max
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      } else {
        return 0;
      }
    },
    // 해당 값의 자릿수 단위별 최솟값
    getMin(chartData, type) {
      const min = Math.min(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );
      let digits = 0;
      if (min >= 1) {
        digits = Math.pow(10, Math.ceil(min).toString().length);
        return Math.ceil(min / digits) * digits;
      } else {
        // 소수점 자릿수 구하기
        const subMax = Number(
          min
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return this.campaignType === 'message' ? 10 : 0.01 * digits;
      }
    }
  }
};
</script>

<style></style>
