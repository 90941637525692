<template>
  <v-menu
    open-on-hover
    open-delay="500"
    close-delay="300"
    bottom
    offset-x
    :nudge-top="nudgeTop"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small icon v-bind="attrs" v-on="on" :color="color">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card :width="dense ? 'auto' : 450">
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-subtitle v-if="subTitle">
        <strong v-html="subTitle"></strong>
      </v-card-subtitle>
      <v-card-text v-if="image">
        <div class="text-center">
          <v-img :src="image"></v-img>
          <span> [예시이미지] </span>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    nudgeTop: {
      type: String,
      default: '50%'
    },
    color: {
      type: String,
      default: 'blue'
    },
    dense: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>

<style></style>
