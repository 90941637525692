var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"rules":{
      required: true
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-input',{attrs:{"type":"hidden","error-messages":errors},model:{value:(_vm.campaign.rollingImageId),callback:function ($$v) {_vm.$set(_vm.campaign, "rollingImageId", $$v)},expression:"campaign.rollingImageId"}}),_c('image-upload',{attrs:{"sImageId":_vm.campaign.rollingImageId,"campaignType":"rolling","imageType":"image","title":_vm.titles.image},on:{"setImage":function (v) {
          _vm.campaign.rollingImageId = v.id;
          _vm.campaign.rollingImageUrl = v.url;
          _vm.campaign.rollingImageType = v.type;
        }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }