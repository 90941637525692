<template>
  <v-row
    :class="`info-message-warp ${
      color ? color + '--text' : ''
    } darken-4 px-3 py-6`"
    align="center"
    justify="center"
  >
    <v-col cols="auto">
      <v-icon :color="color" :size="iconSize"> {{ localIcon }} </v-icon>
    </v-col>
    <v-col
      v-if="title"
      :cols="12"
      class="subtitle-1 text-center font-weight-bold pt-1"
    >
      {{ title }}
    </v-col>
    <v-col
      v-if="description"
      :cols="12"
      class="subtitle-2 text-center font-weight-medium pt-1"
    >
      {{ description }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'grey'
    },
    type: {
      type: String,
      required: false,
      default: 'no-data'
    },
    icon: {
      type: String,
      required: false,
      default: undefined
    },
    iconSize: {
      type: [String, Number],
      required: false,
      default: () => 30
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    localIcon() {
      return this.icon ? this.icon : this.iconList[this.type];
    }
  },
  data() {
    return {
      iconList: {
        'no-data': 'mdi-alert-outline',
        info: 'mdi-alert-circle-outline',
        warning: 'mdi-alert-outline',
        success: 'mdi-check-circle-outline',
        error: 'mdi-close-circle-outline'
      }
    };
  }
};
</script>

<style scoped>
.info-message-warp {
  margin: 0px;
}
</style>
