import { DidApp } from '@/model/admin/did';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      dialog: false
    },
    app: new DidApp(),
    dialog: false,
    filters: {
      searchText: '',
      searchKey: 'name'
    }
  },
  getters: {
    app(state) {
      return state.app;
    },
    dialog(state) {
      return state.dialog;
    },
    loading(state) {
      return state.loading;
    },
    filters(state) {
      return state.filters;
    },
    filterParams(state) {
      let params = {};
      params[`${state.filters.searchKey}`] = state.filters.searchText;

      return params;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_App(state, app) {
      state.app = new DidApp(app, true);
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_Filters(state) {
      state.filters = {
        searchText: '',
        searchKey: 'name'
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    async openDialog({ commit }, app = undefined) {
      commit('SET_App', new DidApp());

      if (app) {
        commit('SET_App', app);
      }

      commit('SET_Dialog', true);
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
    }
  }
};
