export const CampaignTypeList = [
  {
    text: '텍스트',
    value: 'push',
  },
  {
    text: 'Push 메세지',
    value: 'message',
  },
  {
    text: '이미지',
    value: 'rolling',
  },
];
