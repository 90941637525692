<template>
  <ValidationObserver
    tag="form"
    ref="obs2"
    v-slot="{ invalid }"
    @submit.prevent="submit()"
  >
    <v-row class="pa-5" style="background-color: white">
      <v-col sm="12" lg="6">
        <template v-if="campaign.type" class="mx-auto">
          <CampaignTypeRolling v-if="campaign.type === 'rolling'" />

          <CampaignTypeCoupon v-if="campaign.type === 'coupon'" />

          <CampaignTypePush v-if="allowCampaignTypes.includes(campaign.type)" />
        </template>
      </v-col>
      <v-col sm="12" lg="6">
        <CampaignPreview />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="4">
        <CampaignContentFooter :invalid="invalid" />
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import CampaignTypePush from './CampaignStep2TypePush.vue';
import CampaignTypeRolling from './CampaignStep2TypeRolling.vue';
import CampaignTypeCoupon from './CampaignStep2TypeCoupon.vue';
import CampaignContentFooter from './CampaignContentFooter.vue';
import { mapActions, mapGetters } from 'vuex';
import CampaignPreview from './CampaignPreview.vue';

export default {
  components: {
    ValidationObserver,
    CampaignTypePush,
    CampaignTypeRolling,
    CampaignTypeCoupon,
    CampaignContentFooter,
    CampaignPreview
  },
  data() {
    return {
      allowCampaignTypes: ['push', 'message']
    };
  },
  computed: {
    ...mapGetters({
      currentStep: 'campaign/currentStep',
      dialog: 'campaign/dialog',
      campaign: 'campaign/campaign'
    })
  },
  watch: {
    dialog: function(newVal) {
      if (newVal) {
        this.$refs.obs2.reset();
      }
    }
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'campaign/setCurrentStep'
    }),
    submit() {
      const valid = this.$refs.obs2.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    }
  }
};
</script>
