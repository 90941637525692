<template>
  <validation-observer ref="reportFilters">
    <v-card class="pa-5">
      <v-row align="center">
        <v-col cols="12" lg="4">
          <div class="d-flex align-center">
            <h4 style="min-width:80px; white-space:nowrap;">광고 유형</h4>
            <validation-provider style="width:100%;" v-slot="{ errors }">
              <v-autocomplete
                v-model="selectedCampaignTypes"
                ref="campaignTypeAC"
                outlined
                hide-no-data
                hide-details
                full-width
                multiple
                clearable
                chips
                deletable-chips
                placeholder="광고 유형을 선택하세요."
                :items="campaignTypes"
                item-text="text"
                item-value="value"
                :error-messages="errors"
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleCampaignType">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          selectedCampaignTypes.length > 0 ? 'primary' : ''
                        "
                      >
                        {{ campaignTypeIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        전체선택
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </v-col>
        <v-divider vertical inset class="mx-3"></v-divider>
        <v-col cols="12" lg="4">
          <div class="d-flex align-center">
            <h4 style="min-width:80px; white-space:nowrap;">매체</h4>
            <validation-provider style="width:100%;" v-slot="{ errors }">
              <v-autocomplete
                v-model="mediaIds"
                ref="mediaAC"
                outlined
                hide-no-data
                hide-details
                full-width
                multiple
                clearable
                placeholder="매체를 선택하세요."
                :items="medias"
                item-text="name"
                item-value="id"
                :error-messages="errors"
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span v-if="mediaIds.length === medias.length"
                      >전체선택</span
                    >
                    <span v-else
                      >{{ item.name }}
                      {{
                        mediaIds.length > 1
                          ? ` 외 ${mediaIds.length - 1} 개`
                          : ''
                      }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleMedia">
                    <v-list-item-action>
                      <v-icon :color="mediaIds.length > 0 ? 'primary' : ''">
                        {{ mediaIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        전체선택
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="ml-auto text-lg-right">
          <div class="d-inline-flex align-center ">
            <DatePicker
              ref="datePicker"
              class="ml-auto"
              title="기간"
              :prefix="3"
              @setDateRanges="
                v => {
                  dateRanges = v;
                  emitFilters();
                }
              "
              :subDays="1"
            ></DatePicker>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DatePicker from '@/components/DatePicker.vue';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    DatePicker
  },
  props: {
    campaignTypes: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      measureStandard: 'date',
      selectedCampaignTypes: ['rolling', 'push', 'message'],
      mediaIds: [],
      medias: [],
      dateRanges: []
    };
  },
  computed: {
    allMedia() {
      return this.mediaIds.length === this.filteredMedias.length;
    },
    someMedia() {
      return this.mediaIds.length > 0 && !this.allMedia;
    },
    filteredMedias() {
      return this.$refs.mediaAC?.filteredItems || this.medias;
    },
    mediaIcon() {
      if (this.allMedia) return 'mdi-close-box';
      if (this.someMedia) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    filteredCampaignTypes() {
      return this.$refs.campaignTypeAC?.filteredItems || this.campaignTypes;
    },
    allCampaignType() {
      return (
        this.selectedCampaignTypes.length === this.filteredCampaignTypes.length
      );
    },
    someCampaignType() {
      return this.selectedCampaignTypes.length > 0 && !this.allCampaignType;
    },
    campaignTypeIcon() {
      if (this.allCampaignType) return 'mdi-close-box';
      if (this.someCampaignType) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    mediaIds(newVal) {
      if (newVal) {
        this.emitFilters();
      }
    },
    selectedCampaignTypes(newVal) {
      if (newVal) {
        this.emitFilters();
      }
    }
  },
  async mounted() {
    const { medias } = await this.getMediasApi();
    this.medias = medias;
    this.mediaIds = medias.map(media => media.id);
  },
  methods: {
    async getMediasApi() {
      try {
        const { data } = await this.$axios.get('/media/report-medias');
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    toggleCampaignType() {
      this.$nextTick(() => {
        if (this.allCampaignType) {
          this.selectedCampaignTypes = [];
        } else {
          this.selectedCampaignTypes = this.filteredCampaignTypes
            .slice()
            .map(v => v.value);
        }
      });
    },
    toggleMedia() {
      this.$nextTick(() => {
        if (this.allMedia) {
          this.mediaIds = [];
        } else {
          this.mediaIds = this.filteredMedias.slice().map(v => v.id);
        }
      });
    },
    emitFilters() {
      const filters = {
        campaignTypes: this.selectedCampaignTypes,
        mediaIds: this.mediaIds,
        dateRanges: this.dateRanges
      };
      this.$emit('applyFilters', filters);
    }
  }
};
</script>

<style></style>
