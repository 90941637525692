var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-observer',{ref:"advertiserGroupDialogContent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"p-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticStyle:{"padding":"30px"}},[_c('h2',{staticClass:"font-weight-bold py-2"},[_vm._v("기본 설정")]),_c('v-row',[_c('v-col',{attrs:{"cols":6}},[_c('h3',[_vm._v(_vm._s(_vm.titles.name))]),_c('validation-provider',{attrs:{"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.advertiserGroup.name),callback:function ($$v) {_vm.$set(_vm.advertiserGroup, "name", $$v)},expression:"advertiserGroup.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":6}},[_c('h3',[_vm._v(_vm._s(_vm.titles.advertiser))]),_c('validation-provider',{attrs:{"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.advertisers,"item-text":"name","item-value":"id","hide-no-data":"","required":"","outlined":"","clearable":"","auto-select-first":""},model:{value:(_vm.advertiserGroup.advertiser),callback:function ($$v) {_vm.$set(_vm.advertiserGroup, "advertiser", $$v)},expression:"advertiserGroup.advertiser"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                    required: false
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('image-upload',{attrs:{"sImageId":_vm.advertiserGroup.iconId,"campaignType":"push","imageType":"icon","title":_vm.titles.icon},on:{"setImage":function (v) {
                        _vm.advertiserGroup.iconId = v.id;
                        _vm.advertiserGroup.iconUrl = v.url;
                        _vm.advertiserGroup.iconType = v.type;
                      }}}),_c('v-input',{attrs:{"type":"hidden","error-messages":errors},model:{value:(_vm.advertiserGroup.iconId),callback:function ($$v) {_vm.$set(_vm.advertiserGroup, "iconId", $$v)},expression:"advertiserGroup.iconId"}})]}}],null,true)})],1)],1)],1),_c('v-btn',{staticClass:"mt-4 mr-4",attrs:{"color":"secondary","type":"submit","disabled":invalid,"large":""}},[_vm._v(" "+_vm._s(_vm.isRegisterDialog ? '등록' : '수정')+"하기 ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }