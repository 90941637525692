export default {
  namespaced: true,
  state: {
    dialog: false,
    notification: null
  },
  getters: {
    dialog(state) {
      return state.dialog;
    },
    notification(state) {
      return state.notification;
    },
  },
  mutations: {
    SET_Notification(state, notification) {
      state.notification = JSON.parse(JSON.stringify(notification)) ?? null;
    },
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    }
  },
  actions: {
    openDialog({ commit }) {
      commit('SET_Dialog', true);
      commit('SET_Notification',null);
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
      commit('SET_Notification', null);
    },
    selectNotification({ commit }, notification) {
      commit('SET_Notification', notification ?? null);
    },

  }
};
