<template>
  <div>
    결제요청
  </div>
</template>

<script>
export default {
  created() {
    const { status, message } = this.$route.query;
    // console.log(status);
    if (status === 'S') {
      // 결제 성공
      alert('결제가 완료되었습니다.');
      if (window.opener) {
        window.opener.parent.location.reload();
      }
      window.close();
    } else if (status === 'F') {
      // 결제 실패
      if (message) {
        alert(message);
      } else {
        alert('결제 오류: 잠시 후 다시 시도해주세요.');
      }
      window.close();
    }
  }
};
</script>

<style></style>
