<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn
            color="secondary"
            v-on="{ ...tooltipOn, ...dialogOn }"
            fab
            :small="!$isMobile"
            :x-small="$isMobile"
          >
            <v-icon>mdi-table-cog</v-icon>
          </v-btn>
        </template>
        <span class="headline">열 설정</span>
      </v-tooltip>
    </template>

    <v-card color="#ddd">
      <v-toolbar flat dark>
        <v-toolbar-title>열 설정</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="12" md="6">
            <v-sheet
              height="100%"
              class="pa-3"
              style="overflow-y: auto; max-height: 800px"
            >
              <v-treeview
                v-model="selectedItems"
                :items="columns"
                item-text="title"
                item-key="dataIndex"
                :selection-type="selectionType"
                selectable
                return-object
                rounded
                hoverable
                activatable
                open-all
                @update:active="v => (selectedItems = v)"
                multiple-active
                :open="open"
                :active="selectedItems"
              />
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6">
            <v-draggable
              id="columnDraggable"
              class="pa-3"
              :disabled="loading"
              :list="selectedItems"
              ghost-class="ghost"
              @start="dragging = true"
              @end="dragging = false"
              style="
                overflow-y: auto;
                max-height: 800px;
                border: 2px dashed #92b0b3;
              "
            >
              <transition-group type="transition" :name="'flip-list'">
                <v-sheet
                  :key="item.dataIndex"
                  v-for="(item, index) in selectedItems"
                  class="mb-3"
                  rounded="lg"
                  :style="{
                    cursor: 'pointer',
                    borderLeft: `5px solid ${item.color}`
                  }"
                >
                  <v-list-item dense>
                    <v-list-item-icon>
                      <v-icon> mdi-menu </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <div class="d-inline-flex">
                        <v-text-field
                          class="ma-0 pa-0"
                          :value="index + 1"
                          @blur="changeIndex($event, index)"
                          @keydown.enter="changeIndex($event, index)"
                          hide-details
                          style="width: 20px"
                          :min="0"
                          :max="selectedItems.length"
                        >
                        </v-text-field>
                        <v-list-item-title v-text="`. ${item.title}`" />
                      </div>
                    </v-list-item-content>
                    <v-list-item-action class="ma-0">
                      <v-btn icon>
                        <v-icon color="grey lighten-1" @click="remove(index)"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-sheet>
              </transition-group>
            </v-draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn class="mr-3" color="secondary" @click="initDefault()">
          초기화
        </v-btn>
        <v-btn @click="dialog = false"> 닫기 </v-btn>
        <v-btn color="primary" @click="store()" :loading="loading">
          적용
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import VDraggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
  props: {
    originHeaders: {
      type: Array,
      required: true
    },
    currentHeaders: {
      type: Array,
      required: true
    },
    fixedColumns: {
      type: Array,
      required: true
    }
  },
  components: {
    VDraggable
  },
  data() {
    return {
      dialog: false,
      open: ['setting', 'report'],
      columns: [],
      loading: false,
      selectionType: 'leaf',
      selectedItems: [],
      dragging: false,
      color: [
        {
          match: 'default',
          code: 'gray'
        },
        {
          match: 'setting',
          code: '#F15F5F'
        },
        {
          match: 'report',
          code: '#47C83E'
        }
      ]
    };
  },
  mounted() {
    // 컬럼 항목 설정
    this.setColumns();
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  watch: {
    dialog(value) {
      if (value) {
        // 선택된 컬럼 항목 설정
        this.selectedItems = this.setColorAttribute(
          this.currentHeaders.filter(v => v.customOptions.group !== 'default')
        );
      }
    }
  },
  methods: {
    initDefault() {
      const { originHeaders } = this;
      const filteredItems = originHeaders.filter(
        header => header.customOptions.isDefault
      );
      this.selectedItems = this.setColorAttribute(filteredItems);
    },
    setColumns() {
      const columns = [
        // {
        //   title: '기본 컬럼',
        //   dataIndex: 'default',
        //   children: [],
        //   locked: true
        // },
        {
          title: '보고서',
          dataIndex: 'report',
          children: [],
          color: '#47C83E'
        },
        {
          title: '캠페인 설정',
          dataIndex: 'setting',
          children: [],
          color: '#F15F5F'
        }
      ];
      const { originHeaders } = this;

      columns.forEach(column => {
        const filteredItems = originHeaders.filter(
          header => column.dataIndex === header.customOptions.group
        );
        column.children = this.setColorAttribute(filteredItems);
      });
      this.columns = columns;
    },
    setColorAttribute(items) {
      const { color } = this;
      return items.map(item => {
        return {
          ...item,
          color: color.find(v => v.match === item.customOptions.group).code
        };
      });
    },
    getColumns() {
      return this.$axios
        .get('/menu/list', {
          params: {
            code: 'network'
          }
        })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {});
    },
    remove(index) {
      this.selectedItems.splice(index, 1);
    },
    changeIndex(event, index) {
      let newIndex = parseInt(event.target.value) - 1;
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex > this.selectedItems.length) {
        newIndex = this.selectedItems.length;
      }
      const item = this.selectedItems[index];
      this.selectedItems.splice(index, 1);
      this.selectedItems.splice(newIndex, 0, item);
    },
    async store() {
      this.loading = true;
      await this.$emit('store', [...this.fixedColumns, ...this.selectedItems]);

      this.dialog = false;
      this.loading = false;
      // const params = {
      //   filter_menu: this.selectedItems,
      //   code: 'campaign'
      // };

      // this.$axios
      //   .post('/menu/columns', params)
      //   .then(res => {
      //     console.log(res);
      //   })
      //   .catch(err => {
      //     console.error(err);
      //   })
      //   .finally(() => {
      //     this.$emit('store', {
      //       columns: this.selectedItems
      //     });

      //     this.$emit('closeDialog');
      //     this.loading = false;
      //   });
    }
  }
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

/* #columnDraggable {
  overflow-y: scroll;
} */
</style>
