<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="상태" :span="2">
        <a-select
          style="width: 300px"
          v-model="filters.status"
          mode="multiple"
          placeholder="상태 선택"
        >
          <a-select-option
            v-for="(item, index) in statusList"
            :key="index"
            :value="item.value"
          >
            <span :style="`color: ${item.color};`">{{ item.text }} </span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="등록일">
        <div style="min-width: 150px" class="px-2">
          <FilterDatePicker
            :style="{ display: 'inline-block' }"
            :prefix="7"
            class="chip--small"
            @setDateRanges="
              v => {
                filters.dateRanges = v;
              }
            "
          />
        </div>
      </a-descriptions-item>

      <a-descriptions-item label="검색" :span="3">
        <a-input-group compact>
          <a-select style="width: 150px" v-model="filters.searchKey">
            <a-select-option value="title"> 프로모션 명 </a-select-option>
            <a-select-option value="name"> 이름 </a-select-option>
            <a-select-option value="businessName"> 상호명 </a-select-option>
            <a-select-option value="email"> 이메일 </a-select-option>
            <a-select-option value="phone"> 연락처 </a-select-option>
          </a-select>

          <a-input-search
            v-model="filters.searchText"
            placeholder="검색할 회원 이름을 입력해 주세요."
            @search="$emit('search', filterParams)"
            style="width: calc(100% - 150px); max-width: 1000px"
          />
        </a-input-group>
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search', filterParams)"
      >
        검색
      </v-btn>

      <v-btn
        class="ml-3"
        color="#00d1b2"
        outlined
        @click="handleInitClick()"
        :disabled="loading"
      >
        초기화
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterDatePicker from '@/components/FilterDatePicker.vue';

import {
  CampaignResourceStatusList,
  CampaignResourceTypeList,
  CampaignResourceDataTypeList,
  BusinessClassList
} from '@/config/common';

export default {
  props: {
    loading: {
      type: Boolean
    },
    statusList: {
      type: Array
    }
  },
  components: { FilterDatePicker },
  data() {
    return {
      filters: {},
      CampaignResourceStatusList: CampaignResourceStatusList,
      CampaignResourceTypeList: CampaignResourceTypeList,
      CampaignResourceDataTypeList: CampaignResourceDataTypeList,
      BusinessClassList: BusinessClassList
    };
  },
  computed: {
    ...mapGetters({}),
    filterParams() {
      const params = {
        dateRanges: this.filters.dateRanges,
        status: this.filters.status
      };

      params[`${this.filters.searchKey}`] = this.filters.searchText;

      return params;
    }
  },
  mounted() {
    this.initFilters();
    this.$emit('search', this.filterParams);
  },
  methods: {
    ...mapActions({}),
    initFilters() {
      this.filters = {
        searchKey: 'title',
        searchText: '',
        dateRanges: [],
        status: ['W', 'H']
      };
    },
    handleInitClick() {
      this.initFilters();
      this.$emit('search', this.filterParams);
    }
  }
};
</script>
