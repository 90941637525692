var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"depressed":"","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v(" 앱 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('DidAppFilters',{attrs:{"filters":_vm.filters,"loading":_vm.indexLoading},on:{"search":_vm.fetchDidApps}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.indexLoading,"size":"small","rowKey":"id","columns":_vm.columns,"bordered":"","data-source":_vm.didApps,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleRowClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(value, record, index){return [_vm._v(" "+_vm._s(_vm.pagination.total - (_vm.pagination.perPage * _vm.pagination.page - _vm.pagination.perPage) - index)+" ")]}},{key:"link",fn:function(value){return [_c('div',{staticStyle:{"max-width":"550px","white-space":"normal"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"regionDepth2Id",fn:function(value){return [_vm._v(" "+_vm._s(_vm.getRegionText(_vm.regionDepth2Id, value))+" ")]}},{key:"isActive",fn:function(value, record){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","inset":"","readonly":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleActiveSwitch(record)}},model:{value:(record.isActive),callback:function ($$v) {_vm.$set(record, "isActive", $$v)},expression:"record.isActive"}})],1)]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalCount)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":_vm.pagination.perPage,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1),_c('DidAppDialog',{on:{"refresh":_vm.fetchDidApps}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }