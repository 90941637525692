import { Campaign } from '@/model/admin/campaign';
import {
  getCampaignDetailAPI,
  getCampaignAdvertiserAPI
} from '@/api/admin/CampaignAPI';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

export default {
  namespaced: true,

  state: {
    testMode: false,
    advertiserId: null,
    advertiserGroups: null,
    dialog: false,
    dialogType: null,
    campaign: null,
    campaignId: null,
    tab: 0,
    originCampaign: null,
    currentStep: 1,
    approveStatusDialog: false,
    apiLoading: false
  },

  getters: {
    currentStep(state) {
      return state.currentStep;
    },
    campaign(state) {
      return state.campaign;
    },
    originCampaign(state) {
      return state.originCampaign;
    },
    dialog(state) {
      return state.dialog;
    },
    dialogType(state) {
      return state.dialogType;
    },
    tab(state) {
      return state.tab;
    },
    campaignId(state) {
      return state.campaignId;
    },
    advertiserGroups(state) {
      return state.advertiserGroups;
    },
    advertiserId(state) {
      return state.advertiserId;
    },
    isDialogTypeRegister(state) {
      return state.dialogType === 'R';
    },
    isDialogTypeEdit(state) {
      return state.dialogType === 'E';
    },
    approveStatusDialog(state) {
      return state.approveStatusDialog;
    },
    apiLoading(state) {
      return state.apiLoading;
    }
  },

  mutations: {
    SET_CurrentStep(state, currentStep) {
      state.currentStep = currentStep;
    },
    SET_Campaign(state, campaign) {
      state.campaign = campaign;
    },
    SET_CampaignId(state, campaignId) {
      state.campaignId = campaignId;
    },
    SET_OriginCampaign(state, originCampaign) {
      state.originCampaign = originCampaign;
    },
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_DialogType(state, dialogType) {
      state.dialogType = dialogType;
    },
    SET_Tab(state, tab) {
      state.tab = tab;
    },
    SET_AdvertiserGroups(state, advertiserGroups) {
      state.advertiserGroups = advertiserGroups;
    },
    SET_AdvertiserId(state, advertiserId) {
      state.advertiserId = advertiserId;
    },
    SET_ApproveStatusDialog(state, value) {
      state.approveStatusDialog = value;
    },
    SET_ApiLoading(state, value) {
      state.apiLoading = value;
    }
  },

  actions: {
    setCurrentStep({ commit }, currentStep) {
      commit('SET_CurrentStep', currentStep);
    },
    setTab({ commit }, tab) {
      commit('SET_Tab', tab);
    },
    initTab({ commit }) {
      const tab = 0;
      commit('SET_Tab', tab);
    },
    setCampaign({ commit }, campaign) {
      commit('SET_Campaign', campaign);
    },
    setCampaignId({ commit }, campaignId) {
      commit('SET_CampaignId', campaignId);
    },
    setOriginCampaign({ commit }, originCampaign) {
      commit('SET_OriginCampaign', originCampaign);
    },
    setDialogTypeRegister({ commit }) {
      commit('SET_DialogType', 'R');
    },
    setDialogTypeEdit({ commit }) {
      commit('SET_DialogType', 'E');
    },
    openDialog({ commit }) {
      commit('SET_Dialog', true);
    },
    closeDialog({ commit, dispatch }) {
      commit('SET_Dialog', false);
      dispatch('initCampaign');
    },
    initCampaign({ commit }) {
      // const testMode = state.testMode;
      // const campaign = {
      //     id: null,
      //     rs1: {
      //         user: {
      //             advertiser: testMode ? {
      //                 id: 1,
      //                 name: '티디아이'
      //             } : null,
      //             mediaGroups: testMode ? [{
      //                 id: 1,
      //                 name: '티디아이'
      //             }] : [],
      //             advertiserGroup: null,
      //             tracker: null,
      //         },
      //         landingUrl: testMode ? 'https://tdi9.com' : null,
      //         campaignName: testMode ? '테스트' : null,
      //         campaignType: testMode ? 'push' : null,
      //     },
      //     rs2: {
      //         push: {
      //             clickType: testMode ? 'normal' : null,
      //             subTitle: testMode ? '테스트 subTitle' : null,
      //             category: testMode ? '건강/운동' : null,
      //             title: testMode ? '테스트 title' : null,
      //             text: testMode ? '테스트 text' : null,
      //             messageImageId: null,
      //             messageImageUrl: null,
      //             messageImageType: null,
      //             imageId: null,
      //             imageUrl: null,
      //             imageType: null,
      //             messageDate: null,
      //             messageTime: null
      //         },
      //         rolling: {
      //             imageId: null,
      //             imageUrl: null,
      //             imageType: null,
      //         },
      //         message: {
      //             clickType: testMode ? 'normal' : null,
      //             subTitle: testMode ? '테스트 subTitle' : null,
      //             category: testMode ? '건강/운동' : null,
      //             title: testMode ? '테스트 title' : null,
      //             text: testMode ? '테스트 text' : null,
      //             messageImageId: null,
      //             messageImageUrl: null,
      //             messageImageType: null,
      //             imageId: null,
      //             imageUrl: null,
      //             imageType: null,
      //             iconId: null,
      //             iconUrl: null,
      //             iconType: null,
      //         },
      //     },
      //     rs3: {
      //         apps: [],
      //         appsType: 'nontarget',
      //         locations: [],
      //         tags: [],
      //         targetTags: [],
      //         targetTagsType: null,
      //         targetTagsMode: null,
      //         targetTagsCount: null,
      //         targetTagsRange: 30,
      //         targetTagsClick: 1,
      //         isTagFilter: false
      //     },
      //     rs4: {
      //         impressionTime: 10, //광고노출시간
      //         impressionTimeType: 's',
      //         impressionFrequency: 1, //광고노출우선순위
      //         adsGoalCount: 2, //광고 미노출 최대 클릭 수
      //         adsLimitInterval: 2 //광고 클릭 후 재노출 기간
      //     },
      //     rs5: {
      //         schedule: {
      //             displayPeriodStart: testMode ? '2021-04-01' : null,
      //             displayPeriodEnd: testMode ? '2021-05-30' : null,
      //             issetDisplayPeriodEnd: true,
      //             messageDate: null,
      //             messageTime: null,
      //         },
      //         budget: {
      //             costTypes: null,
      //             dailyAdvertisingBudget: null,
      //             totalAdvertisingBudget: null,
      //             cpc: null,
      //             cpm: null,
      //             forecastReachCount: null,
      //             advertisingReachCount: null,
      //         }
      //     },
      // };

      commit('SET_Campaign', new Campaign(undefined, true));
      commit('SET_CurrentStep', 1);
    },
    async getCampaign({ dispatch, commit, state }, campaignId = null) {
      if (state.apiLoading) return;

      let newCampaign = new Campaign(undefined, true);
      let res = true;
      if (campaignId) {
        commit('SET_ApiLoading', true);

        const [error, data] = await getCampaignDetailAPI(campaignId);

        if (error) {
          const { message } = error.data;
          Swal.fire({
            icon: 'error',
            html: message ?? '해당 캠페인이 존재하지 않습니다.',
            confirmButtonText: '확인'
          }).then(() => dispatch('closeDialog'));
        } else {
          newCampaign = new Campaign(data.campaign, true);
          dispatch('getAdvertiserGroups', newCampaign.advertiserId);
        }
        res = !error;
        commit('SET_ApiLoading', false);
      }

      dispatch('setOriginCampaign', newCampaign);
      dispatch('setCampaign', newCampaign);
      return res;
    },
    async getAdvertiserGroups({ commit, state }, advertiserId = null) {
      if (!advertiserId) {
        return false;
      }

      if (
        state.advertiserId === advertiserId &&
        state.advertiserGroups !== null
      ) {
        return false;
      }

      const [error, data] = await getCampaignAdvertiserAPI(advertiserId);

      if (error) {
        console.log(error);
      } else {
        const { advertiserGroups } = data;
        commit('SET_AdvertiserGroups', advertiserGroups ?? null);
        commit('SET_AdvertiserId', advertiserId);
      }

      return data;
    },
    openApproveStatusDialog({ commit }) {
      commit('SET_ApproveStatusDialog', true);
    },
    closeApproveStatusDialog({ commit }) {
      commit('SET_ApproveStatusDialog', false);
    }
  }
};
