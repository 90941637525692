<template>
  <v-dialog v-model="dialog" max-width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>매체 {{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 py-3">
        <ValidationObserver :ref="refKey">
          <v-row dense>
            <CustomGridItem title="매체 이름" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="branch.name"
                    v-bind="
                      $getAttributes({
                        errors: errors,
                        placeholder: '매체 이름'
                      })
                    "
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="매체사 그룹" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="branch.didMediaGroupId"
                    v-bind="
                      $getAttributes(
                        {
                          errors: errors,
                          items: didMediaGroups,
                          itemText: 'name',
                          itemValue: 'id',
                          placeholder: '매체사 그룹 선택',
                          readonly: !!branch.id
                        },
                        'select'
                      )
                    "
                    @change="handleDidMediaGroupId"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="활성여부" outlined :md="12" v-if="branch.id">
              <template v-slot:content>
                <v-col>
                  <v-chip
                    label
                    class="font-weight-bold px-5 white--text"
                    :color="branch.isActive ? '#00d1b2' : 'error'"
                  >
                    {{ branch.isActive ? '활성' : '비활성' }}
                  </v-chip>
                </v-col>
              </template>
            </CustomGridItem>

            <CustomGridItem title="카테고리" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="branch.category"
                    v-bind="
                      $getAttributes(
                        {
                          errors: errors,
                          items: BranchCategories,
                          placeholder: '카테고리 선택'
                        },
                        'select'
                      )
                    "
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="상호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="branch.companyCode"
                    v-bind="
                      $getAttributes(
                        {
                          errors: errors,
                          items: CompanyCodes,
                          placeholder: '상호 선택'
                        },
                        'select'
                      )
                    "
                    placeholder="상호 선텍"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="지역 1" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="branch.regionDepth1Id"
                    v-bind="
                      $getAttributes(
                        {
                          errors: errors,
                          items: regionDepth1Id,
                          placeholder: '특별·광역시, 도 선택',
                          itemText: 'name',
                          itemValue: 'id'
                        },
                        'select'
                      )
                    "
                    @change="branch.area2 = null"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="지역 2" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="branch.regionDepth2Id"
                    v-bind="
                      $getAttributes(
                        {
                          errors: errors,
                          items: filteredAreaDepth2,
                          placeholder: '시, 구 선택',
                          itemText: 'name',
                          itemValue: 'id',
                          disabled: !branch.regionDepth1Id
                        },
                        'select'
                      )
                    "
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="운영 요일"
              outlined
              :md="12"
              titleClass="col-12  py-3"
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <template>
                    <v-col class="py-1">
                      <v-btn-toggle
                        v-model="branch.scheduleDayIds"
                        class="ma-0 row"
                        style="border-width: 2px"
                        multiple
                      >
                        <v-btn
                          v-for="(day, index) in Week"
                          :key="day.value"
                          :value="day.value"
                          :disabled="dialogLoading"
                          class="col font-weight-bold"
                          color="#00d1b2"
                          outlined
                          dense
                          :style="`border-color: #00d1b2!important;
                          border-width: 2px;
                          ${!index ? '' : 'border-left-width: 0px'}`"
                        >
                          {{ day.text }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>

                    <v-col
                      v-if="errors.length"
                      cols="12"
                      class="red--text py-0 px-5"
                    >
                      {{ errors[0] }}
                    </v-col>
                  </template>
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="운영 시간"
              outlined
              :md="12"
              titleClass="col-12 py-3"
            >
              <template v-slot:content>
                <v-row class="ma-0">
                  <v-col cols="6">
                    <v-menu
                      ref="timePicker"
                      v-model="openAtDialog"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            v-model="branch.scheduleOpenAt"
                            v-bind="
                              $getAttributes({
                                errors: errors,
                                placeholder: '매체 이름',
                                prependInnerIcon: 'mdi-clock-outline',
                                label: '시작 시간',
                                disabled: dialogLoading,
                                persistentHint: true,
                                ...attrs
                              })
                            "
                            v-on="on"
                          />
                        </ValidationProvider>
                      </template>

                      <TimePicker
                        v-model="branch.scheduleOpenAt"
                        :show="openAtDialog"
                        @close="openAtDialog = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-menu
                      ref="timePicker"
                      v-model="closeAtDialog"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            v-model="branch.scheduleCloseAt"
                            v-bind="
                              $getAttributes({
                                errors: errors,
                                prependInnerIcon: 'mdi-clock-outline',
                                label: '종료 시간',
                                disabled: dialogLoading,
                                persistentHint: true,
                                ...attrs
                              })
                            "
                            v-on="on"
                          />
                        </ValidationProvider>
                      </template>
                      <!--  스케줄링 로직상, 24:00 가 들어가야 해서 추가 -->
                      <TimePicker
                        v-model="branch.scheduleCloseAt"
                        :show="closeAtDialog"
                        :max="25"
                        @close="closeAtDialog = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
            </CustomGridItem>

            <template v-if="isSuperAdmin">
              <h3 class="font-weight-bold mt-4">매체비율 설정</h3>
              <template v-if="branch.type === 'A'">
                <CustomGridItem title="단일매체 매출 비율" outlined :md="12">
                  <template v-slot:content>
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="{ required: true }"
                    >
                      <v-text-field
                        v-model="branch.profitRatio"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '직매체 매출 비율을 입력해 주세요.',
                            disabled: dialogLoading
                          })
                        "
                      />
                    </ValidationProvider>
                  </template>
                </CustomGridItem>

                <CustomGridItem
                  title="대행사(매체그룹) 매출 비율"
                  outlined
                  :md="12"
                >
                  <template v-slot:content>
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="{ required: true }"
                    >
                      <v-text-field
                        v-model="branch.agencyProfitRatio"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '직매체 매출 비율을 입력해 주세요.',
                            disabled: dialogLoading
                          })
                        "
                      />
                    </ValidationProvider>
                  </template>
                </CustomGridItem>
              </template>
              <template v-if="branch.type === 'D'">
                <CustomGridItem title="직매체 매출 비율" outlined :md="12">
                  <template v-slot:content>
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="{ required: true }"
                    >
                      <v-text-field
                        v-model="branch.directProfitRatio"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '직매체 매출 비율을 입력해 주세요.',
                            disabled: dialogLoading
                          })
                        "
                      />
                    </ValidationProvider>
                  </template>
                </CustomGridItem>
              </template>
            </template>

            <template v-if="branch.type === 'A'">
              <h3 class="font-weight-bold mt-4">계정 정보</h3>

              <CustomGridItem title="로그인 아이디" outlined :md="12">
                <template v-slot:content>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true }"
                  >
                    <template v-if="!branch.id">
                      <v-text-field
                        v-model="branch.user.uid"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '로그인 아이디를 입력해 주세요.',
                            disabled: dialogLoading
                          })
                        "
                      />
                    </template>
                    <v-col v-else class="py-2"> {{ branch.userUid }}</v-col>
                  </ValidationProvider>
                </template>
              </CustomGridItem>

              <CustomGridItem
                v-if="!branch.id"
                title="비밀번호"
                outlined
                :md="12"
              >
                <template v-slot:content>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      min: 8
                    }"
                  >
                    <v-text-field
                      v-model="branch.user.password"
                      v-bind="
                        $getAttributes({
                          errors: errors,
                          placeholder: '비밀번호를 입력해 주세요.',
                          type: 'password',
                          disabled: dialogLoading
                        })
                      "
                    />
                  </ValidationProvider>
                </template>
              </CustomGridItem>

              <CustomGridItem title="이메일" outlined :md="12">
                <template v-slot:content>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|email"
                  >
                    <template v-if="!branch.id">
                      <v-text-field
                        v-model="branch.user.email"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '이메일을 입력해 주세요.',
                            type: 'email',
                            disabled: dialogLoading
                          })
                        "
                      />
                    </template>
                    <v-col v-else class="py-2"> {{ branch.userEmail }}</v-col>
                  </ValidationProvider>
                </template>
              </CustomGridItem>

              <CustomGridItem title="연락처" outlined :md="12">
                <template v-slot:content>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true }"
                  >
                    <template v-if="!branch.id">
                      <v-text-field
                        v-model="branch.user.phone"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '연락처를 입력해 주세요.',
                            type: 'phone',
                            disabled: dialogLoading
                          })
                        "
                      />
                    </template>
                    <v-col v-else class="py-2">
                      {{ branch.userPhone }}
                    </v-col>
                  </ValidationProvider>
                </template>
              </CustomGridItem>
            </template>

            <h3 class="font-weight-bold mt-3">기본 영상</h3>
            <CustomGridItem
              title="기본영상"
              outlined
              :md="12"
              v-if="branch.didMediaGroupId"
            >
              <template v-slot:content>
                <v-row justify="center" align="center">
                  <v-col class="subtitle-1 font-weight-bold pl-5">
                    {{
                      branch.defaultResourceCount > 0
                        ? `${branch.defaultResourceCount} 개`
                        : '기본영상을 선택해 주세요.'
                    }}
                  </v-col>
                  <v-col cols="auto">
                    <PreviewResourceDialog :createdInit="false" />
                  </v-col>
                </v-row>
              </template>
            </CustomGridItem>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn secondary outlined @click="handleCloseClick"> 닫기 </v-btn>
        <v-btn
          color="secondary"
          @click="handleSaveClick"
          :loading="dialogLoading"
          :disabled="
            dialogLoading || (branch.id ? !this.branch.isChanged() : false)
          "
        >
          {{ titleText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import {
  BranchCategories,
  CompanyCodes,
  Week,
  ActiveOptions
} from '@/config/admin/campaign-did';
import TimePicker from '@/components/common/TimePicker.vue';
import { getDidMediaGroupOptionsApi } from '@/api/admin/DidMediaGroupAPI';
import {
  updateDidBranchApi,
  createDidBranchApi
} from '@/api/admin/DidBranchAPI';
import PreviewResourceDialog from './PreviewResourceDialog.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TimePicker,
    CustomGridItem,
    PreviewResourceDialog
  },
  data() {
    return {
      ActiveOptions: ActiveOptions,
      BranchCategories: BranchCategories,
      CompanyCodes: CompanyCodes,
      Week: Week,
      didMediaGroups: [],
      times: [],
      openAtDialog: false,
      closeAtDialog: false,
      refKey: 'didBranchDialog'
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didBranch/dialog',
      loading: 'didBranch/loading',
      branch: 'didBranch/branch',
      regionDepth1Id: 'didBranch/regionDepth1Id',
      regionDepth2Id: 'didBranch/regionDepth2Id',
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return !this.branch.id ? '등록' : '수정';
    },
    filteredAreaDepth2() {
      if (!this.branch.regionDepth1Id) return [];

      return this.regionDepth2Id.filter(
        item => item.region_depth1_id === this.branch.regionDepth1Id
      );
    }
  },
  watch: {
    dialog() {
      this.loading.dialog = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  mounted() {
    // 타입이 media인 회원 목록 옵션 조회
    this.fetchMediaGroupOptions().then(() => {
      if (this.branch.id) {
        this.handleDidMediaGroupId(this.branch.didMediaGroupId);
      }
    });

    if (!this.regionDepth1Id.length) {
      this.fetchRegionOptions();
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'didBranch/closeDialog',
      fetchRegionOptions: 'didBranch/fetchRegionOptions'
    }),
    // 미디어 그룹 유저 목록
    async fetchMediaGroupOptions() {
      if (this.dialogLoading) return;

      this.loading.dialog = true;

      const [error, data] = await getDidMediaGroupOptionsApi();

      if (error) {
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_media_groups } = data;
        this.didMediaGroups = did_media_groups.map(group => ({
          ...group,
          name: `${group.name} (${group.type === 'A' ? '대행사' : '직매체'})`
        }));
      }
      this.loading.dialog = false;
    },
    async handleSaveClick() {
      if (this.dialogLoading) return;

      const valid = await this.$refs[this.refKey].validate();

      if (!valid) return;

      // if (!this.branch.defaultResourceCount) {
      //   this.$Swal.fire({
      //     icon: 'info',
      //     html: '<h3>기본영상을 선택해 주세요</h3>',
      //     showConfirmButton: true,
      //     confirmButtonText: '확인'
      //   });
      //   return;
      // }

      if (!this.branch.isChanged()) {
        this.$Swal.fire({
          icon: 'info',
          html: '<h3>변경 사항이 없습니다.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      this.loading.dialog = true;

      const param = this.branch.id
        ? this.branch.getUpdatePayload()
        : this.branch.getCreatePayload();

      const [error] = this.branch.id
        ? await updateDidBranchApi(param, this.branch.id)
        : await createDidBranchApi(param, this.branch.id);

      if (error) {
        console.log(error);
        const message = error?.data?.message || '잠시 후 다시 시도해주세요';
        this.$axiosErrorAlert(message);
      } else {
        this.$emit('refresh');

        this.$Swal
          .fire({
            icon: 'success',
            title: `매체 ${this.titleText} `,
            html: `<h3>매체이 ${this.titleText}되었습니다.</h3>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          })
          .then(() => this.closeDialog());
      }

      this.loading.dialog = false;
    },
    handleDidMediaGroupId(didMediaGroupId) {
      const didMediaGroup = this.didMediaGroups.find(
        item => item.id === didMediaGroupId
      );

      this.branch.didMediaGroupUserId = didMediaGroup.user_id;

      this.branch.type = didMediaGroup.type;
      this.branch.user.uid = null;
      this.branch.user.email = null;
      this.branch.user.password = null;
      this.branch.user.email = null;
    },
    /** @description: 닫기 */
    handleCloseClick() {
      // 변경사항 있는경우 확인
      if (this.branch.isChanged()) {
        this.$Swal
          .fire({
            icon: 'info',
            title: `매체 ${this.titleText} `,
            html: '<h3>변경 사항이 있습니다.<br/> 저장하지 않고 닫으시겠습니까?</h3>',
            showConfirmButton: true,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.closeDialog();
            }
          });
      } else {
        this.closeDialog();
      }
    }
  }
};
</script>
