export default {
    namespaced: true,

    state: {
        menus: [{
                icon: 'mdi-view-dashboard',
                text: '대시보드',
                link: '/media'
            },
            {
                icon: 'mdi-chart-areaspline',
                text: '매체 운영 보고서',
                link: '/media/report'
            },
            {
                icon: 'mdi-antenna',
                text: '매체 라이브',
                link: '/media/live'
            },
            {
                icon: 'mdi-bullhorn',
                text: '공지사항',
                link: '/media/notice'
            },
            {
                icon: 'mdi-account-question',
                text: '1:1 문의',
                link: '/media/inquiry'
            }
        ]
    },
    getters: {
        menus(state) {
            return state.menus
        },
    },
};