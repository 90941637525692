<template>
  <validation-observer tag="form" ref="obs4" v-slot="{ invalid }">
    <v-row class="pa-5" style="background-color:white;">
      <v-col sm="12" lg="6">
        <!-- <v-row>
            <v-col cols="4">
              <validation-provider
                v-slot="{ errors }"
                :name="names.impressionInterval"
                rules="required|numeric"
              >
                <v-text-field
                  v-model="rs4.impressionInterval"
                  :error-messages="errors"
                  :placeholder="placeholder.impressionInterval"
                  :label="names.impressionInterval"
                  required
                  clearable
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="rs4.impressionIntervalType"
                :items="unitOfTimeList"
                item-text="text"
                item-value="value"
                item-disabled="disable"
                label="단위"
              ></v-select>
            </v-col>
          </v-row> -->

        <text-field-title :title="titles.rs4.impressionTime" />
        <v-row>
          <v-col cols="10">
            <validation-provider v-slot="{ errors }" rules="required|numeric">
              <v-text-field
                v-model="rs4.impressionTime"
                :error-messages="errors"
                required
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="rs4.impressionTimeType"
              :items="UnitOfTimeList"
              item-text="text"
              item-value="value"
              item-disabled="disable"
              outlined
              label="단위"
            ></v-select>
          </v-col>
        </v-row>
        <text-field-title
          :title="titles.rs4.impressionFrequency"
          :subTitle="SubTitles.rs4.impressionFrequency"
        />
        <v-row>
          <v-col cols="10">
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: true,
                numeric: true,
                min_value: Math.min(...impressionFrequency),
                max_value: Math.max(...impressionFrequency)
              }"
            >
              <v-autocomplete
                v-model="rs4.impressionFrequency"
                :error-messages="errors"
                suffix="순위"
                required
                outlined
                :items="impressionFrequency"
                hide-no-data
              >
              </v-autocomplete>
            </validation-provider>
          </v-col>
          <!-- <v-col cols="2">
              <v-text-field value="우선순위" readonly disabled></v-text-field>
            </v-col> -->
        </v-row>

        <text-field-title
          :title="titles.rs4.adsLimitInterval"
          :subTitle="SubTitles.rs4.adsLimitInterval"
        />
        <v-row>
          <v-col cols="10">
            <validation-provider v-slot="{ errors }" rules="required|numeric">
              <v-text-field
                v-model="rs4.adsLimitInterval"
                :error-messages="errors"
                required
                suffix="일"
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <text-field-title
          :title="titles.rs4.adsGoalCount"
          :subTitle="SubTitles.rs4.adsGoalCount"
        />
        <v-row>
          <v-col cols="10">
            <validation-provider v-slot="{ errors }" rules="required|numeric">
              <v-text-field
                v-model="rs4.adsGoalCount"
                :error-messages="errors"
                required
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" lg="4">
        <campaign-content-footer :invalid="invalid" />
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CampaignContentFooter from './CampaignContentFooter.vue';
import { mapGetters } from 'vuex';
import TextFieldTitle from '@/components/TextFieldTitle.vue';
import { SubTitles, Titles } from '@/config/admin/campaign';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CampaignContentFooter,
    TextFieldTitle
  },
  props: ['rs4'],
  computed: {
    ...mapGetters({
      dialog: 'campaign/dialog'
    }),
    impressionFrequency() {
      return Array.from(Array(60).keys(), n => n + 1);
    },
    titles() {
      return Titles;
    },
    SubTitles() {
      return SubTitles;
    }
  },
  watch: {
    dialog: function(newVal) {
      if (newVal) {
        this.$refs.obs4.reset();
      }
    }
  },
  data() {
    return {
      // impressionIntervalType: 's',
      impressionTimeType: 's',
      UnitOfTimeList: [
        {
          text: '초',
          value: 's',
          disable: false
        },
        {
          text: '분',
          value: 'm',
          disable: false
        }
        // {
        //   text: '시간',
        //   value: 'h',
        //   disable: true
        // },
        // {
        //   text: '일',
        //   value: 'd',
        //   disable: true
        // }
      ]
    };
  }
};
</script>

<style></style>
