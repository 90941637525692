<template>
  <v-dialog :value="dialog" width="900" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>
          충전하기 (
          <span class="font-weight-bold">직접입금</span>
          )
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <h2>노티아이 전용 입금계좌</h2>
        <v-card flat outlined class="mb-5 pa-3">
          <ul>
            <li class="title">
              <div style="display: inline-block; width: 150px">예금주</div>
              {{ bankInfo.account }}
            </li>
            <li class="title">
              <div style="display: inline-block; width: 150px">은행</div>
              {{ bankInfo.name }}
            </li>

            <li class="title">
              <div style="display: inline-block; width: 150px">계좌번호</div>
              {{ bankInfo.accountNumber }}
              <v-tooltip bottom>
                <template #activator="{ on: onTooltip }">
                  <v-btn v-on="{ ...onTooltip }" icon @click="handleCopyClick">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>복사하기</span>
              </v-tooltip>
            </li>
          </ul>
        </v-card>
        <h2>입금 금액 및 세금계산서 발행 정보</h2>
        <h4 class="red--text mt-2 font size12">
          ※ 입금시 입금자명과 입력한 입금자명이 동일해야 입금처리가 됩니다.
        </h4>
        <ValidationObserver ref="bankTransferRef">
          <v-card flat outlined class="mb-5 pa-3">
            <v-row>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    min_value_krw: 30000,
                    max_value_krw: maxPrice,
                    max_krw: 10
                  }"
                >
                  <v-text-field
                    v-model="formattedAmount"
                    label="충전 금액"
                    outlined
                    :hide-details="!errors.length"
                    :error-messages="errors"
                    suffix="원"
                    clearable
                    placeholder="충전 금액을 입력하세요."
                    persistent-placeholder
                    @change="handleAmountChange"
                    @click:clear="clearAmount()"
                  >
                  </v-text-field>
                  <h4 class="red--text">
                    {{
                      errors.length > 0
                        ? errors[0]
                        : '※충전 최소 충전액은 30,000원입니다.'
                    }}
                  </h4>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="부가세 금액"
                  :value="taxAmountStr"
                  outlined
                  readonly
                  persistent-placeholder
                  filled
                  placeholder="충전액 입력시, 자동입력됩니다."
                  suffix="원"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" lg="12">
                <v-text-field
                  label="입금 금액"
                  :value="advertisingAmountStr"
                  persistent-placeholder
                  placeholder="충전액 입력시, 자동입력됩니다."
                  outlined
                  readonly
                  filled
                  suffix="원"
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :rules="{
                    required: true
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="입금자명"
                    v-model="accountName"
                    outlined
                    placeholder="입금자명을 입력해 주세요"
                    persistent-placeholder
                    :error-messages="errors"
                    hide-details
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="6">
                <v-menu
                  ref="taxInvoiceDateMenu"
                  v-model="taxInvoiceDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="taxInvoiceDate"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="taxInvoiceDate"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :error-messages="errors"
                        :hide-details="!errors.length"
                        label="세금계산서 발행요청일"
                        persistent-placeholder
                        placeholder="세금계산서 발행요청 날짜를 선택해주세요."
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-if="taxInvoiceDateMenu"
                    v-model="taxInvoiceDate"
                    :min="today"
                    :max="allowMaxDate"
                    color="secondary"
                    locale="ko-KR"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="taxInvoiceDateMenu = false"
                    >
                      취소
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.taxInvoiceDateMenu.save(taxInvoiceDate)"
                    >
                      적용
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn large outlined @click="handleCloseClick()"> 취소 </v-btn>
        <v-btn
          color="#00d1b2"
          class="white--text px-3"
          large
          @click="submit()"
          :loading="loading"
        >
          충전 요청
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { createBankTransferApi } from '@/api/partner/UserAPI';
import { copyTextToClipboard } from '@/utils/tools';
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/bankTransferDialog',
      bankInfo: 'config/bankInfo',
      kst: 'auth/kst'
    }),
    taxAmount() {
      if (this.amount) {
        return this.amount / 10;
      } else {
        return null;
      }
    },
    taxAmountStr() {
      if (this.taxAmount) {
        return Math.round(this.taxAmount).toLocaleString();
      } else {
        return null;
      }
    },
    advertisingAmount() {
      if (this.amount && this.taxAmount) {
        return Math.round(this.amount + this.taxAmount);
      } else {
        return null;
      }
    },
    advertisingAmountStr() {
      if (this.amount && this.taxAmount) {
        return Math.round(this.amount + this.taxAmount).toLocaleString();
      } else {
        return null;
      }
    },
    today() {
      return this.$moment(this.kst).format('YYYY-MM-DD');
    },
    allowMaxDate() {
      return this.$moment(this.kst).add(30, 'd').format('YYYY-MM-DD');
    }
  },
  data() {
    return {
      amount: null,
      formattedAmount: null,
      loading: false,
      taxInvoiceDateMenu: false,
      taxInvoiceDate: null,
      accountName: '',
      maxPrice: 9999999000
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      closeBankTransferDialog: 'dialog/closeBankTransferDialog',
      openBankInfoDialog: 'dialog/openBankInfoDialog'
    }),
    init() {
      if (this.$refs.bankTransferRef) {
        this.$refs.bankTransferRef.reset();
      }
      this.clearAmount();
    },
    handleAmountChange(price) {
      if (price == null) {
        return false;
      }

      let amount = Number(price.replaceAll(',', ''));
      if (isNaN(amount)) {
        this.amount = null;
        this.formattedAmount = null;
      } else {
        // 천원이하 반올림처리
        if (amount > 1000) {
          amount = Math.round(amount / 10000) * 10000;
        }

        if (amount > this.maxPrice) {
          amount = this.maxPrice;
        }
        this.amount = amount;
        this.formattedAmount = amount.toLocaleString();
      }
    },
    clearAmount() {
      this.amount = null;
      this.formattedAmount = null;
    },
    async submit() {
      const valid = await this.$refs.bankTransferRef.validate();
      if (valid) {
        const [error] = await createBankTransferApi({
          amount: this.advertisingAmount,
          taxInvoiceDate: this.taxInvoiceDate,
          accountName: this.accountName
        });

        // console.log(data);
        if (error) {
          this.$Swal.fire({
            icon: 'error',
            title: '충전 요청 실패',
            html: '<h3>잠시 후 다시 시도해주세요.</h3>',
            confirmButtonText: '확인'
          });
        } else {
          this.$Swal
            .fire({
              icon: 'success',
              width: 800,
              title: '충전 요청 성공',
              html: '<h3><strong>"노티아이 전용 입금계좌"</strong>로 입금 하시면, 노티아이 운영팀에서 빠르게 확인하겠습니다.<br>입금 확인 후, 충전이 완료 될 예정입니다.<br/><br/>감사합니다!</h3>',
              confirmButtonText: '확인완료',
              showDenyButton: true,
              denyButtonText: '전용계좌 확인'
            })
            .then(result => {
              this.handleCloseClick();
              if (result.isDenied) {
                this.openBankInfoDialog();
              } else {
                this.$router.go();
              }
            });
        }
      } else {
        this.$Swal.fire({
          icon: 'error',
          html: '입력 항목을 다시한번 확인해주세요.',
          timer: 1000,
          showConfirmButton: false
        });
      }
    },
    handleCloseClick() {
      this.closeBankTransferDialog();
    },
    async handleCopyClick() {
      const { accountNumber } = this.bankInfo;
      console.log(accountNumber);
      if (accountNumber) {
        const result = await copyTextToClipboard(accountNumber);
        console.log(`result`, result);
        if (result) {
          this.$Swal.fire({
            icon: 'success',
            title: '복사 완료',
            html: `<h3>${accountNumber}</h3>`,
            showConfirmButton: false,
            timer: 1000
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
