<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn
              depressed
              dark
              @click="openDialog({ record: {}, type: 'store' })"
            >
              광고주 등록
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a-card>
          <AdvertiserFilters
            :filters="filters"
            :loading="loading"
            @search="fetchAdvertisers(1)"
          />

          <a-table
            :loading="loading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            :columns="columns"
            bordered
            :data-source="advertisers"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      openDialog({
                        record: {
                          ...record,
                          advertiserId: record.id
                        },
                        type: 'detail'
                      });
                    }
                  }
                };
              }
            "
          >
            <template slot="status" slot-scope="value, record">
              <v-row align="center" class="row ma-0">
                <v-col class="col-auto pa-0">
                  <!-- <a-tag class="mr-0" small :color="record.status.color">
                    {{ record.status.text }}
                  </a-tag> -->
                  <v-badge left dot inline :color="record.status.color">
                    {{ record.status.text }}
                  </v-badge>
                </v-col>

                <v-col
                  class="col-auto pa-0"
                  style="min-width: 30px; text-align: right"
                >
                  <v-tooltip bottom color="rgba(0,0,0,1)">
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        class=""
                        v-on="{ ...onTooltip }"
                        icon
                        small
                        @click.stop="handleApproveStatusClick(record)"
                      >
                        <v-icon size="17px">mdi-sync</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-subtitle-1">계정 활성여부 변경</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>

            <template slot="role" slot-scope="value, record">
              {{ record.role === 'cashnote' ? '캐시노트' : '일반 관리자' }}
            </template>

            <template slot="actions" slot-scope="value, record">
              <v-tooltip bottom color="rgba(0,0,0,1)">
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn
                    v-on="{ ...tooltipOn }"
                    type="primary"
                    small
                    icon
                    @click.stop="handleUserIFrameClick(record)"
                  >
                    <v-icon>mdi-home</v-icon>
                  </v-btn>
                </template>
                <span class="title">사용자 화면</span>
              </v-tooltip>
            </template>

            <template slot="point" slot-scope="value, record">
              <v-menu
                open-on-hover
                open-delay="100"
                close-delay="100"
                bottom
                offset-y
                nudge-left=""
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-0 font-weight-bold"
                    label
                    color="secondary"
                    small
                  >
                    <span>
                      {{ Number(value) | KRW }}
                    </span>
                  </v-chip>
                </template>
                <v-card
                  min-width="200"
                  min-height="150"
                  max-height="300"
                  class="overflow-y-auto"
                >
                  <v-card-title> 포인트 상세 내역 </v-card-title>
                  <v-card-text class="text-right">
                    <div class="mb-3">
                      <v-chip label>
                        <span>
                          유상포인트:
                          {{ Number(record.creditPoint) | KRW }}
                        </span>
                      </v-chip>
                    </div>
                    <div>
                      <v-chip label>
                        <span> 무상포인트: {{ record.freePoint | KRW }} </span>
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </a-table>

          <div class="d-flex justify-end pa-3" v-if="hasAdvertisers">
            <a-pagination
              v-model="pagination.page"
              :defaultPageSize="10"
              :page-size.sync="pagination.perPage"
              size="large"
              :show-size-changer="true"
              :pageSizeOptions="['10', '20', '50', '100']"
              :total="pagination.totalCount"
              @change="fetchAdvertisers()"
              @showSizeChange="fetchAdvertisers(1)"
            >
            </a-pagination>
          </div>
        </a-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-pagination
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row> -->
    <AdvertiserStoreDialog @refresh="fetchAdvertisers" />
    <AdvertiserDetailDialog @refresh="fetchAdvertisers" />
    <AdvertiserUpdateStatusDialog
      v-if="selectedAdvertiser"
      :advertiser="selectedAdvertiser"
      @refresh="fetchAdvertisers"
      @close="handleClosedApproveStatusDialog"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import AdvertiserStoreDialog from './components/dialog/AdvertiserStoreDialog';
import AdvertiserDetailDialog from './components/dialog/AdvertiserDetailDialog';
import AdvertiserUpdateStatusDialog from './components/dialog/AdvertiserUpdateStatusDialog';
import AdvertiserFilters from './AdminAdvertiserFilters';
import { getTokenAPI } from '@/api/admin/UserAPI';
import { getAdvertisersAPI } from '@/api/admin/AdvertiserAPI';
import { Pagination } from '@/model/Pagination';
import { Advertiser } from '@/model/admin/Advertiser';
export default {
  components: {
    MenuTitle,
    AdvertiserDetailDialog,
    AdvertiserStoreDialog,
    AdvertiserUpdateStatusDialog,
    AdvertiserFilters
  },
  data() {
    return {
      advertisers: [],
      pagination: new Pagination(1, 10),
      loading: false,
      selectedAdvertiser: null
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'advertiser/dialog',
      filters: 'advertiser/filters'
    }),
    hasAdvertisers() {
      return this.advertisers.length > 0;
    },
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '상태',
          align: 'center',
          dataIndex: 'status',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '권한',
          align: 'center',
          dataIndex: 'role',
          scopedSlots: {
            customRender: 'role'
          }
        },
        { title: '광고주명', dataIndex: 'name' },
        { title: 'ID', dataIndex: 'uid' },
        { title: '연락처', dataIndex: 'phone' },
        { title: 'E-mail', dataIndex: 'email' },
        {
          title: '포인트',
          dataIndex: 'totalPoint',
          key: 'totalPoint',
          align: 'right',
          scopedSlots: {
            customRender: 'point'
          },
          customOptions: { group: 'setting' }
        },
        { title: '가입일', align: 'center', dataIndex: 'createdAt' },

        {
          title: '기능',
          dataIndex: 'actions',
          align: 'center',
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ];
    }
  },
  mounted() {
    this.fetchAdvertisers();
  },
  methods: {
    ...mapActions({
      openDialog: 'advertiser/openDialog',
      openUserIFrameDialog: 'dialog/openUserIFrameDialog',
      setIframeUrl: 'user/setIframeUrl'
    }),
    async fetchAdvertisers(page = this.pagination.page) {
      this.loading = true;

      const params = {
        params: {
          page: page,
          perPage: this.pagination.perPage,
          email: this.filters.email,
          name: this.filters.name,
          uid: this.filters.uid,
          phone: this.filters.phone,
          status: this.filters.status,
          // dates: this.filters.dates.map(date => {
          //   return date.format('YYYY-MM-DD');
          // }),
          pagination: 'Y'
        }
      };

      const [error, data] = await getAdvertisersAPI(params);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const {
          data: advertisers,
          last_page: totalPage,
          total
        } = data.advertisers;
        this.advertisers = advertisers.map((advertiser, index) => {
          const newAdvertiser = new Advertiser({
            ...advertiser,
            key: index
          });

          return newAdvertiser;
        });

        this.pagination.totalCount = total;
        this.pagination.totalPage = totalPage;
      }

      this.loading = false;
    },

    handleApproveStatusClick(item) {
      this.selectedAdvertiser = item;
      this.openDialog({ record: {}, type: 'approveStatus' });
    },
    handleClosedApproveStatusDialog() {
      this.selectedAdvertiser = null;
    },
    async handleUserIFrameClick(record) {
      const [error, data] = await getTokenAPI(record.origin.user_id);
      if (error) {
        return false;
      }
      const { token } = data;
      let iframeUrl = `${process.env.VUE_APP_URL}/bridge/${token}`;

      if (record.role === 'cashnote') {
        iframeUrl = `${process.env.VUE_APP_CASHNOTE_URL}/m/login?token=${token}`;
      }

      this.setIframeUrl(iframeUrl);
      this.openUserIFrameDialog(record.origin.user_id);
    }
  }
};
</script>
