<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="광고 송출 상태">
        <a-select
          :style="{ minWidth: '200px' }"
          v-model="filters.isActive"
          mode="multiple"
        >
          <a-select-option
            v-for="item in ActiveOptions"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="계정 활성 여부">
        <a-select
          :style="{ minWidth: '200px' }"
          v-model="filters.approveStatusFlag"
          mode="multiple"
        >
          <a-select-option
            v-for="item in userApproveList"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="그룹 유형">
        <a-select :style="{ minWidth: '200px' }" v-model="filters.type">
          <a-select-option
            v-for="item in MediaGroupTypeList"
            :key="item.value"
            :value="item.value"
          >
            <span :style="`color: ${item.color} `">{{ item.text }}</span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="검색" :span="2">
        <a-input-group compact>
          <a-select style="width: 150px" v-model="filters.searchKey">
            <a-select-option value="name"> 매체그룹명 </a-select-option>
            <a-select-option value="email"> 이메일 </a-select-option>
            <a-select-option value="uid"> ID </a-select-option>
          </a-select>

          <a-input-search
            v-model="filters.searchText"
            @search="$emit('search')"
            style="width: calc(100% - 150px); max-width: 1000px"
          />
        </a-input-group>
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>

      <v-btn
        class="ml-3"
        color="#00d1b2"
        outlined
        @click="handleInitClick()"
        :disabled="loading"
      >
        초기화
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ActiveOptions } from '@/config/admin/campaign-did';
import { MediaGroupTypeList } from '@/config/media';
export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      ActiveOptions: ActiveOptions,
      MediaGroupTypeList: MediaGroupTypeList,
      userApproveList: [
        {
          text: '활성',
          value: 'Y',
          color: 'green'
        },
        {
          text: '비활성',
          value: 'W',
          color: 'red'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      filters: 'didMediaGroup/filters'
    })
  },
  methods: {
    ...mapActions({
      initFilters: 'didMediaGroup/initFilters'
    }),
    handleInitClick() {
      this.initFilters();
      this.$emit('search');
    }
  }
};
</script>
