<template>
  <v-alert
    :value="value && issetAlertKey"
    :border="border"
    :icon="icon"
    colored-border
    :color="color"
    elevation="2"
    @input="dismissAlert(localStorageKey)"
    :dismissible="dismissible"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: {
    border: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      default: undefined
    },
    localStorageKey: {
      type: String,
      default: undefined
    },
    value: {
      type: Boolean,
      default: true
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    issetAlertKey() {
      const alert = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (alert && alert.expire > this.$moment().unix()) {
        return alert.value ?? true;
      }
      return true;
    }
  },
  methods: {
    dismissAlert(key = null) {
      if (!key) {
        return false;
      }
      const data = {
        value: false,
        expire: this.$moment()
          .add(1, 'days')
          .unix()
      };
      localStorage.setItem(key, JSON.stringify(data));
    }
  }
};
</script>

<style></style>
