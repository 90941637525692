<template>
  <div>
    <p class="p1">
      제<span class="s1">1</span>조<span class="s1">&nbsp;[</span>포인트<span
        class="s1"
        >&nbsp;</span
      >충전<span class="s1">]</span>
    </p>
    <ol class="ol1">
      <li class="li1">
        회사는<span class="s1">&nbsp;</span>서비스<span class="s1">&nbsp;</span
        >이용계약의<span class="s1">&nbsp;</span>포인트<span class="s1"
          >&nbsp;</span
        >충전<span class="s1">&nbsp;</span>요금<span class="s1">&nbsp;</span
        >청구를<span class="s1">&nbsp;</span>위해<span class="s1">&nbsp;</span
        >서비스<span class="s1">&nbsp;</span>이용기간<span class="s1"
          >&nbsp;</span
        >및<span class="s1">&nbsp;</span>서비스<span class="s1">&nbsp;</span
        >이용<span class="s1">&nbsp;</span>기간<span class="s1">&nbsp;</span
        >종료<span class="s1">&nbsp;</span>후<span class="s1">&nbsp;</span
        >일정기간<span class="s1">&nbsp;</span>동안<span class="s1">&nbsp;</span
        >회원의<span class="s1">&nbsp;</span>결제관련정보를<span class="s1"
          >&nbsp;</span
        >보유할<span class="s1">&nbsp;</span>수<span class="s1">&nbsp;</span
        >있습니다<span class="s1">.</span>
      </li>
      <li class="li1">
        타인의<span class="s1">&nbsp;</span>결제<span class="s1">&nbsp;</span
        >정보를<span class="s1">&nbsp;</span>동의<span class="s1">&nbsp;</span
        >없이<span class="s1">&nbsp;</span>사용할<span class="s1">&nbsp;</span
        >경우<span class="s1">&nbsp;</span>민<span class="s1">/</span
        >형사상<span class="s1">&nbsp;</span>책임을<span class="s1">&nbsp;</span
        >지게<span class="s1">&nbsp;</span>되며<span class="s1">&nbsp;</span
        >관계법령에<span class="s1">&nbsp;</span>의해<span class="s1"
          >&nbsp;</span
        >처벌될<span class="s1">&nbsp;</span>수<span class="s1">&nbsp;</span
        >있습니다<span class="s1">.</span>
      </li>
      <li class="li1">
        서비스<span class="s1">&nbsp;</span>이용을<span class="s1">&nbsp;</span
        >위해<span class="s1">&nbsp;</span>포인트<span class="s1">&nbsp;</span
        >충전<span class="s1">&nbsp;</span>진행<span class="s1">&nbsp;</span
        >중<span class="s1">&nbsp;</span>회원의<span class="s1">&nbsp;</span
        >귀책사유로<span class="s1">&nbsp;</span>인해<span class="s1"
          >&nbsp;</span
        >발생한<span class="s1">&nbsp;</span>손해에<span class="s1">&nbsp;</span
        >대해<span class="s1">&nbsp;</span>회사는<span class="s1">&nbsp;</span
        >책임을<span class="s1">&nbsp;</span>지지<span class="s1">&nbsp;</span
        >않습니다<span class="s1">.</span>
      </li>
      <li class="li1">
        회사는<span class="s1">&nbsp;</span>유료서비스<span class="s1"
          >&nbsp;</span
        >하자<span class="s1">&nbsp;</span>등에<span class="s1">&nbsp;</span
        >의한<span class="s1">&nbsp;</span>이용자피해보상의<span class="s1"
          >&nbsp;</span
        >기준<span class="s1">&middot;</span>범위<span class="s1">&middot;</span
        >방법<span class="s1">&nbsp;</span>및<span class="s1">&nbsp;</span
        >절차에<span class="s1">&nbsp;</span>관한<span class="s1">&nbsp;</span
        >사항을<span class="s1">&nbsp;</span>디지털콘텐츠<span class="s1"
          >&nbsp;</span
        >이용자<span class="s1">&nbsp;</span>보호지침에<span class="s1"
          >&nbsp;</span
        >따라<span class="s1">&nbsp;</span>처리합니다<span class="s1">.</span>
      </li>
    </ol>
    <p class="p2"><br /></p>
    <p class="p1">
      제<span class="s1">2</span>조<span class="s1">&nbsp;[</span>환불<span
        class="s1"
        >&nbsp;</span
      >정책<span class="s1">]</span>
    </p>
    <p class="p1">
      서비스<span class="s1">&nbsp;</span>이용을<span class="s1">&nbsp;</span
      >위해<span class="s1">&nbsp;</span>충전된<span class="s1">&nbsp;</span
      >포인트는<span class="s1">&nbsp;</span>환불될<span class="s1">&nbsp;</span
      >수<span class="s1">&nbsp;</span>있습니다<span class="s1">.&nbsp;</span
      >단<span class="s1">,&nbsp;</span>환불을<span class="s1">&nbsp;</span
      >요청한<span class="s1">&nbsp;</span>기간을<span class="s1">&nbsp;</span
      >기점으로<span class="s1">&nbsp;</span>사용되지<span class="s1"
        >&nbsp;</span
      >않은<span class="s1">&nbsp;</span>포인트에<span class="s1">&nbsp;</span
      >할당하는<span class="s1">&nbsp;</span>금액을<span class="s1">&nbsp;</span
      >환불할<span class="s1">&nbsp;</span>수<span class="s1">&nbsp;</span
      >있습니다<span class="s1">.&nbsp;</span>그리고<span class="s1"
        >&nbsp;</span
      >회사가<span class="s1">&nbsp;</span>서비스를<span class="s1">&nbsp;</span
      >더<span class="s1">&nbsp;</span>이상<span class="s1">&nbsp;</span
      >제공하지<span class="s1">&nbsp;</span>않게<span class="s1">&nbsp;</span
      >되어<span class="s1">&nbsp;</span>본<span class="s1">&nbsp;</span
      >계약이<span class="s1">&nbsp;</span>해지되는<span class="s1">&nbsp;</span
      >경우<span class="s1">,&nbsp;</span>회사는<span class="s1">&nbsp;</span
      >사용자가<span class="s1">&nbsp;</span>납부한<span class="s1">&nbsp;</span
      >포인트<span class="s1">&nbsp;</span>충전<span class="s1">&nbsp;</span
      >금액에<span class="s1">&nbsp;</span>할당하여<span class="s1">&nbsp;</span
      >해당하는<span class="s1">&nbsp;</span>금액을<span class="s1">&nbsp;</span
      >사용자에게<span class="s1">&nbsp;</span>반환합니다<span class="s1"
        >.</span
      >
    </p>
    <p class="p1">
      회사는<span class="s1">&nbsp;</span>유료서비스<span class="s1"
        >&nbsp;</span
      >하자<span class="s1">&nbsp;</span>등에<span class="s1">&nbsp;</span
      >의한<span class="s1">&nbsp;</span>이용자피해보상의<span class="s1"
        >&nbsp;</span
      >기준<span class="s1">&middot;</span>범위<span class="s1">&middot;</span
      >방법<span class="s1">&nbsp;</span>및<span class="s1">&nbsp;</span
      >절차에<span class="s1">&nbsp;</span>관한<span class="s1">&nbsp;</span
      >사항을<span class="s1">&nbsp;</span>디지털콘텐츠<span class="s1"
        >&nbsp;</span
      >이용자<span class="s1">&nbsp;</span>보호지침에<span class="s1"
        >&nbsp;</span
      >따라<span class="s1">&nbsp;</span>처리합니다<span class="s1">.</span>
    </p>
    <p class="p2"><br /></p>
    <p class="p1">
      제<span class="s1">3</span>조<span class="s1">&nbsp;[</span>분쟁의<span
        class="s1"
        >&nbsp;</span
      >해결<span class="s1">]</span>
    </p>
    <p class="p1">
      회사는<span class="s1">&nbsp;</span>분쟁이<span class="s1">&nbsp;</span
      >발생하였을<span class="s1">&nbsp;</span>경우에<span class="s1"
        >&nbsp;</span
      >회원이<span class="s1">&nbsp;</span>제기하는<span class="s1">&nbsp;</span
      >정당한<span class="s1">&nbsp;</span>의견이나<span class="s1">&nbsp;</span
      >불만을<span class="s1">&nbsp;</span>반영하여<span class="s1">&nbsp;</span
      >적절하고<span class="s1">&nbsp;</span>신속한<span class="s1">&nbsp;</span
      >조치를<span class="s1">&nbsp;</span>취합니다<span class="s1"
        >.&nbsp;</span
      >다만<span class="s1">,&nbsp;</span>신속한<span class="s1">&nbsp;</span
      >처리가<span class="s1">&nbsp;</span>곤란한<span class="s1">&nbsp;</span
      >경우에<span class="s1">&nbsp;</span>회사는<span class="s1">&nbsp;</span
      >회원에게<span class="s1">&nbsp;</span>그<span class="s1">&nbsp;</span
      >사유와<span class="s1">&nbsp;</span>처리일정을<span class="s1"
        >&nbsp;</span
      >통보합니다<span class="s1">.</span>
    </p>
    <p class="p1"><br /></p>
    <p class="p1">
      제<span class="s1">4</span>조<span class="s1">&nbsp;[</span>준거법<span
        class="s1"
        >&nbsp;</span
      >및<span class="s1">&nbsp;</span>관할법원<span class="s1">]</span>
    </p>
    <p class="p1">
      <span class="s1">1.&nbsp;</span>회사와<span class="s1">&nbsp;</span
      >회원간에<span class="s1">&nbsp;</span>제기된<span class="s1">&nbsp;</span
      >소송에<span class="s1">&nbsp;</span>대해서는<span class="s1">&nbsp;</span
      >대한민국<span class="s1">&nbsp;</span>법률을<span class="s1">&nbsp;</span
      >준거법으로<span class="s1">&nbsp;</span>합니다<span class="s1">.</span>
    </p>
    <p class="p1">
      <span class="s1">2.&nbsp;</span>회사와<span class="s1">&nbsp;</span
      >회원간<span class="s1">&nbsp;</span>발생한<span class="s1">&nbsp;</span
      >분쟁에<span class="s1">&nbsp;</span>관한<span class="s1">&nbsp;</span
      >소송은<span class="s1">&nbsp;</span>제소<span class="s1">&nbsp;</span
      >당시의<span class="s1">&nbsp;</span>회원의<span class="s1">&nbsp;</span
      >주소에<span class="s1">&nbsp;</span>의하고<span class="s1">,&nbsp;</span
      >주소가<span class="s1">&nbsp;</span>없는<span class="s1">&nbsp;</span
      >경우<span class="s1">&nbsp;</span>거소를<span class="s1">&nbsp;</span
      >관할하는<span class="s1">&nbsp;</span>지방법원의<span class="s1"
        >&nbsp;</span
      >전속관할로<span class="s1">&nbsp;</span>합니다<span class="s1"
        >.&nbsp;</span
      >단<span class="s1">,&nbsp;</span>제소<span class="s1">&nbsp;</span
      >당시<span class="s1">&nbsp;</span>회원의<span class="s1">&nbsp;</span
      >주소<span class="s1">&nbsp;</span>또는<span class="s1">&nbsp;</span
      >거소가<span class="s1">&nbsp;</span>명확하지<span class="s1">&nbsp;</span
      >아니한<span class="s1">&nbsp;</span>경우의<span class="s1">&nbsp;</span
      >관할법원은<span class="s1">&nbsp;</span>민사소송법에<span class="s1"
        >&nbsp;</span
      >따라<span class="s1">&nbsp;</span>정합니다<span class="s1">.</span>
    </p>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
p.p1 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 13px 'Roboto', sans-serif;
  color: #000000;
}

p.p2 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 13px Helvetica;
  color: #000000;
  min-height: 12px;
}

li.li1 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 13px 'Roboto', sans-serif;
  color: #000000;
}

span.s1 {
  font: 13px Helvetica;
}

ol.ol1 {
  list-style-type: decimal;
}
</style>
