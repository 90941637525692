<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-card class="my-3">
      <v-card-text>
        <a-descriptions bordered>
          <a-descriptions-item label="타입">
            <a-select
              v-model="filters.type"
              :style="{ minWidth: '200px' }"
              mode="multiple"
            >
              <a-select-option
                v-for="(type, index) in adminTaskType"
                :key="index"
                :value="type.value"
              >
                {{ type.text }}
              </a-select-option>
            </a-select>
          </a-descriptions-item>
          <a-descriptions-item label="할당 여부">
            <a-select
              v-model="filters.assign"
              :style="{ minWidth: '200px' }"
              mode="multiple"
            >
              <a-select-option :value="1">할당완료</a-select-option>
              <a-select-option :value="0">할당대기</a-select-option>
            </a-select>
          </a-descriptions-item>
        </a-descriptions>

        <v-row class="ma-0 py-3" justify="center">
          <v-btn color="secondary" :disabled="loading" @click="fetchTasks(1)">
            검색
          </v-btn>
        </v-row>

        <v-row class="ma-0 pa-0" justify="end">
          <v-col cols="auto" class="pb-0">
            <v-btn
              color="secondary"
              outlined
              :disabled="loading || !selectedTaskKeys.length"
              @click="handleDeleteClick"
            >
              선택 삭제
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <a-table
              class="px-3 table-striped-rows"
              :columns="columns"
              :data-source="taskList"
              :loading="loading"
              size="small"
              :locale="{
                emptyText: '데이터가 없습니다.'
              }"
              bordered
              rowKey="id"
              :row-selection="{
                selectedRowKeys: selectedTaskKeys,
                onChange: onSelectChange
              }"
              :pagination="false"
              :scroll="{ x: true }"
            >
              <template slot="typeText" slot-scope="text, record">
                <v-chip
                  small
                  outlined
                  dense
                  label
                  :color="getTypeColor(record.type)"
                >
                  {{ text }}
                </v-chip>
              </template>
              <template slot="user" slot-scope="text, record">
                {{ record.user ? record.user.name : '' }}
              </template>
              <template slot="admin" slot-scope="text, record">
                {{ record.admin ? record.admin.name : '' }}
              </template>
              <template slot="actions" slot-scope="text, record">
                <v-tooltip bottom color="rgba(0,0,0,1)">
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn
                      v-on="{ ...tooltipOn }"
                      type="primary"
                      small
                      icon
                      @click.stop="handleMoveLinkClick(record)"
                    >
                      <v-icon>mdi-export-variant</v-icon>
                    </v-btn>
                  </template>
                  <span class="title">해당 페이지 이동</span>
                </v-tooltip>
              </template>
            </a-table>

            <div class="d-flex justify-end pa-3" v-if="taskList.length > 0">
              <a-pagination
                v-model="pagination.page"
                :defaultPageSize="pagination.defaultPageSize"
                :page-size.sync="pagination.perPage"
                size="large"
                :show-size-changer="true"
                :pageSizeOptions="['10', '20', '50', '100']"
                :total="pagination.totalCount"
                @change="fetchTasks()"
                @showSizeChange="fetchTasks(1)"
              >
              </a-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import { Pagination } from '@/model/Pagination';
import { AdminTask } from '@/model/admin/AdminTask';
import { getTaskApi, deleteTaskApi } from '@/api/admin/TaskAPI';
import { AdminTaskType } from '@/config/admin/admin-task';

export default {
  components: {
    MenuTitle
  },
  data() {
    return {
      adminTaskType: AdminTaskType,
      searchText: '',
      taskList: [],
      filters: {
        assign: [0, 1],
        type: ['inquiry', 'campaign', 'banktransfer', 'banktransfer-confirm']
      },
      selectedTaskKeys: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: 'center'
        },
        {
          title: '타입',
          dataIndex: 'typeText',
          key: 'typeText',
          align: 'center',
          scopedSlots: {
            customRender: 'typeText'
          }
        },
        {
          title: '번호',
          dataIndex: 'typeId',
          key: 'typeId',
          align: 'center',
          scopedSlots: {
            customRender: 'typeId'
          }
        },
        {
          title: '회원명',
          dataIndex: 'user',
          key: 'user',
          align: 'center',
          scopedSlots: {
            customRender: 'user'
          }
        },
        {
          title: 'IP',
          dataIndex: 'ipAddress',
          key: 'ipAddress',
          align: 'center'
        },
        {
          title: '담당자',
          dataIndex: 'admin',
          key: 'admin',
          align: 'center',
          scopedSlots: {
            customRender: 'admin'
          }
        },
        {
          title: '등록일',
          dataIndex: 'createdAt',
          key: 'createdAt',
          align: 'center',
          scopedSlots: {
            customRender: 'createdAt'
          }
        },
        {
          title: '기능',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ],
      pagination: new Pagination(1, 10),
      loading: true
    };
  },
  computed: {
    ...mapGetters({})
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({}),
    /**
     * @description: 초기화
     * */
    async init() {
      this.initSelect();
      this.taskList = [];
      await this.fetchTasks();
    },
    //선택 초기화
    initSelect() {
      this.selectedTaskKeys = [];
    },
    /**
     * @description: 태스크 목록 조회
     * */
    async fetchTasks(page = null) {
      this.initSelect();
      this.loading = true;
      if (page) {
        this.pagination.page = page;
      }

      const [error, data] = await getTaskApi({
        params: {
          filters: this.filters,
          page: this.pagination.page,
          perPage: this.pagination.perPage
        }
      });

      if (error) {
        console.error(error);
      } else {
        const { tasks, total_count } = data;

        this.taskList = tasks.map((item, index) => {
          return new AdminTask({
            ...item,
            key: index
          });
        });

        const { perPage } = this.pagination;

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedTaskKeys = selectedRowKeys;
    },
    getTypeColor(type) {
      if (!type) return '';
      return this.adminTaskType.find(task => task.value === type)?.color;
    },
    async handleDeleteClick() {
      if (!this.selectedTaskKeys.length) {
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: '삭제할 태스크를 선택해 주세요.',
          confirmButtonText: '확인'
        });
        return;
      }

      const [error] = await deleteTaskApi({
        params: {
          taskIds: [...this.selectedTaskKeys]
        }
      });

      if (error) {
        return false;
      } else {
        this.init();
      }
    },
    handleMoveLinkClick(task) {
      this.$router.push(task.link);
    }
  }
};
</script>
<style scoped lang="scss">
.filter-box {
  border: 1px solid #e8e8e8;

  .col:first-child {
    background: #f2f2f2;
  }
}
</style>
