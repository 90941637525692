  export const NetworkTypeList = [{
          text: '디지털캠프',
          value: 'digitalcamp'
      },
      {
          text: '나스미디어',
          value: 'adpacker'
      },
      {
          text: '엑셀비드',
          value: 'exelbid'
      },
      {
          text: '쿠팡',
          value: 'coupang'
      }
  ]