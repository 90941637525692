<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card style="background-color:#dedede;">
      <v-toolbar dark>
        <v-btn icon dark @click="exit()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >네트워크
          {{ isDialogTypeRegister ? '등록' : '수정' }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <NetworkDialogContent />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NetworkDialogContent from './NetworkDialogContent.vue';
export default {
  components: {
    NetworkDialogContent
  },
  data() {
    return {
      editExitSwalTitle: '수정화면 닫기',
      registerExitSwalTitle:
        '지금 화면을 닫으면<br> 입력한 내용이 모두 사라져요!'
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'network/dialog',
      isDialogTypeRegister: 'network/isDialogTypeRegister'
    })
  },
  mounted() {
    const backButtonRouteGuardEdit = this.$router.beforeEach(
      (to, from, next) => {
        if (this.dialog === true) {
          /* Blocking back button in menu route */
          next(false);
          this.exit();
        } else {
          /* allowing all other routes*/
          next(true);
        }
      }
    );

    this.$once('hook:destroyed', () => {
      backButtonRouteGuardEdit();
    });
  },
  methods: {
    ...mapActions({
      closeDialog: 'network/closeDialog'
    }),
    exit() {
      this.$Swal
        .fire({
          html: `<h3>${
            this.isDialogTypeRegister
              ? this.registerExitSwalTitle
              : this.editExitSwalTitle
          }</h3>`,
          showCancelButton: true,
          confirmButtonText: '닫기',
          cancelButtonText: '취소'
        })
        .then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.closeDialog();
          }
        });
    }
  }
};
</script>
