<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn depressed dark @click="openDialog()"> 소재 등록 </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <CampaignResourceFilters
            :filters="filters"
            :loading="indexLoading"
            @search="fetchCampaignResources"
          />

          <a-table
            :loading="indexLoading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            rowKey="id"
            :columns="columns"
            bordered
            :data-source="campaignResources"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleRowClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="approveStatusInfo" slot-scope="value, record">
              <div class="text-left">
                <div
                  class="d-inline-flex align-center"
                  style="min-height: 30px !important"
                >
                  <v-badge left dot inline :color="value.color">
                    {{ value.text }}
                  </v-badge>

                  <div
                    style="min-width: 40px"
                    v-if="!record.isExpired && value.value === 'W'"
                  >
                    <v-tooltip bottom color="rgba(0,0,0,1)">
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          class="ml-2"
                          v-on="{ ...onTooltip }"
                          icon
                          small
                          @click.stop="handleApproveStatusClick(record)"
                        >
                          <v-icon>mdi-sync</v-icon>
                        </v-btn>
                      </template>
                      <span class="title">게재 상태 변경</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </template>

            <template slot="validDate" slot-scope="value, record">
              <div
                :style="`color:${
                  record.isExpired
                    ? 'red'
                    : record.isExtendable
                    ? '#FF6D00'
                    : ''
                }`"
              >
                {{ value }}
                <div v-if="record.isExpired || record.isExtendable">
                  <v-icon
                    small
                    :color="
                      record.isExpired
                        ? 'red'
                        : record.isExtendable
                        ? '#FF6D00'
                        : ''
                    "
                  >
                    mdi-information-outline
                  </v-icon>
                  {{
                    record.isExpired
                      ? '만료됨'
                      : record.isExtendable
                      ? '연장필요'
                      : ''
                  }}
                </div>
              </div>
            </template>
            <template slot="typeInfo" slot-scope="value">
              <v-chip v-if="value" small label outlined :color="value.color">
                {{ value.text }}
              </v-chip>
              <template v-else> - </template>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalCount"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <CampaignResourceDialog v-if="dialog" @refresh="fetchCampaignResources" />
    <ApproveStatusDialog
      v-if="approveStatusDialog"
      @refresh="fetchCampaignResources"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import CampaignResourceFilters from './components/AdminCampaignResourceFilters';
import CampaignResourceDialog from './components/dialog/CampaignResourceDialog';
import ApproveStatusDialog from './components/dialog/ApproveStatusDialog.vue';
import { CampaignResource } from '@/model/common';
import {
  getCampaignResourcesApi,
  getCampaignResourceApi
} from '@/api/admin/CampaignResourceAPI';
import { Pagination } from '@/model/Pagination';

export default {
  components: {
    MenuTitle,
    ApproveStatusDialog,
    CampaignResourceDialog,
    CampaignResourceFilters
  },
  data() {
    return {
      campaignResources: [],
      pagination: new Pagination(1, 20)
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'campaignResource/dialog',
      filters: 'campaignResource/filters',
      filterParams: 'campaignResource/filterParams',
      loading: 'campaignResource/loading',
      approveStatusDialog: 'campaignResource/approveStatusDialog'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '게재',
          align: 'center',
          dataIndex: 'approveStatusInfo',
          scopedSlots: {
            customRender: 'approveStatusInfo'
          }
        },
        {
          title: '이름',
          dataIndex: 'name'
        },
        {
          title: '캠페인 타입',
          align: 'center',
          dataIndex: 'campaignTypeInfo',
          scopedSlots: {
            customRender: 'typeInfo'
          }
        },
        {
          title: '소재 타입',
          align: 'center',
          dataIndex: 'dataTypeInfo',
          scopedSlots: {
            customRender: 'typeInfo'
          }
        },
        {
          title: '회원 이름',
          align: 'center',
          dataIndex: 'userName'
        },
        {
          title: '회원 타입',
          align: 'center',
          dataIndex: 'userBusinessClassInfo',
          scopedSlots: {
            customRender: 'typeInfo'
          }
        },
        {
          title: '승인일',
          align: 'center',
          dataIndex: 'approvedDate'
        },
        {
          title: '거절사유',
          align: 'center',
          dataIndex: 'denyReason'
        },
        {
          title: '만료일',
          align: 'center',
          dataIndex: 'validDate',
          scopedSlots: {
            customRender: 'validDate'
          }
        },
        {
          title: '생성일',
          align: 'center',
          dataIndex: 'createdDate'
        }
      ];
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchCampaignResources();
    }
  },
  mounted() {
    this.loading.index = false;

    this.fetchCampaignResources();
  },
  methods: {
    ...mapActions({
      openDialog: 'campaignResource/openDialog', // 등록, 상세 dialog 열기
      openApproveStatusDialog: 'campaignResource/openApproveStatusDialog' // 상태 변경 dialog 열기
    }),
    /**@description:  캠페인 소재 목록 */
    async fetchCampaignResources() {
      if (this.indexLoading) return;

      this.loading.index = true;
      const { perPage } = this.pagination;

      const [error, data] = await getCampaignResourcesApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          filters: this.filterParams,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { resources, total_count } = data;

        this.campaignResources = resources?.map(resource => {
          return new CampaignResource(resource);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading.index = false;
    },
    /**@description:  캠페인 상세조회 */
    async handleRowClick(record) {
      if (!record) return;
      this.loading.index = true;

      const [error, data] = await getCampaignResourceApi(record.id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { resource } = data;
        this.openDialog(resource);
      }

      this.loading.index = false;
    },
    /** 게재 상태 변경 */
    handleApproveStatusClick(item) {
      //기본 설정 값 유효성 체크
      this.openApproveStatusDialog(item);
    }
  }
};
</script>
