<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="{ ...on }"
        fab
        small
        :loading="loading"
        @click="excelExport"
        color="secondary"
      >
        <v-icon>mdi-table-arrow-down</v-icon>
      </v-btn>
    </template>
    <span class="headline"> 엑셀 다운로드 </span>
  </v-tooltip>
</template>

<script>
import { utils, writeFile } from 'xlsx';

export default {
  props: {
    stats: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async excelExport() {
      const stats = this.stats;
      // Using same variables as the above answer
      const Heading = [this.columns.map(v => v.title)];
      const headerKeys = this.columns.map(v => v.dataIndex);
      const Data = stats.map(campaigns => {
        const item = {};
        headerKeys.forEach(key => {
          item[key] = campaigns[key];
        });
        return item;
      });

      var wb = utils.book_new();

      var ws = utils.json_to_sheet(Data, {
        origin: 'A2',
        skipHeader: true
      });
      utils.sheet_add_aoa(ws, Heading); //heading: array of arrays

      utils.book_append_sheet(wb, ws);

      writeFile(
        wb,
        `매체 운영 보고서_${this.$moment().format('YYYY-MM-DD')}.xlsx`
      );
    }
  }
};
</script>

<style></style>
