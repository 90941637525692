var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-card',{staticClass:"my-3"},[_c('v-card-text',[_c('a-descriptions',{attrs:{"bordered":""}},[_c('a-descriptions-item',{attrs:{"label":"타입"}},[_c('a-select',{style:({ minWidth: '200px' }),attrs:{"mode":"multiple"},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}},_vm._l((_vm.adminTaskType),function(type,index){return _c('a-select-option',{key:index,attrs:{"value":type.value}},[_vm._v(" "+_vm._s(type.text)+" ")])}),1)],1),_c('a-descriptions-item',{attrs:{"label":"할당 여부"}},[_c('a-select',{style:({ minWidth: '200px' }),attrs:{"mode":"multiple"},model:{value:(_vm.filters.assign),callback:function ($$v) {_vm.$set(_vm.filters, "assign", $$v)},expression:"filters.assign"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("할당완료")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v("할당대기")])],1)],1)],1),_c('v-row',{staticClass:"ma-0 py-3",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.loading},on:{"click":function($event){return _vm.fetchTasks(1)}}},[_vm._v(" 검색 ")])],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","disabled":_vm.loading || !_vm.selectedTaskKeys.length},on:{"click":_vm.handleDeleteClick}},[_vm._v(" 선택 삭제 ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a-table',{staticClass:"px-3 table-striped-rows",attrs:{"columns":_vm.columns,"data-source":_vm.taskList,"loading":_vm.loading,"size":"small","locale":{
              emptyText: '데이터가 없습니다.'
            },"bordered":"","rowKey":"id","row-selection":{
              selectedRowKeys: _vm.selectedTaskKeys,
              onChange: _vm.onSelectChange
            },"pagination":false,"scroll":{ x: true }},scopedSlots:_vm._u([{key:"typeText",fn:function(text, record){return [_c('v-chip',{attrs:{"small":"","outlined":"","dense":"","label":"","color":_vm.getTypeColor(record.type)}},[_vm._v(" "+_vm._s(text)+" ")])]}},{key:"user",fn:function(text, record){return [_vm._v(" "+_vm._s(record.user ? record.user.name : '')+" ")]}},{key:"admin",fn:function(text, record){return [_vm._v(" "+_vm._s(record.admin ? record.admin.name : '')+" ")]}},{key:"actions",fn:function(text, record){return [_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleMoveLinkClick(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-export-variant")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("해당 페이지 이동")])])]}}])}),(_vm.taskList.length > 0)?_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('a-pagination',{attrs:{"defaultPageSize":_vm.pagination.defaultPageSize,"page-size":_vm.pagination.perPage,"size":"large","show-size-changer":true,"pageSizeOptions":['10', '20', '50', '100'],"total":_vm.pagination.totalCount},on:{"update:pageSize":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"change":function($event){return _vm.fetchTasks()},"showSizeChange":function($event){return _vm.fetchTasks(1)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }