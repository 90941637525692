<template>
  <v-btn
    class=" white--text"
    color="#000"
    style="outline:2px solid #fff;"
    @click="handleUserPointButton"
    width="95%"
  >
    <div v-if="apiLoading">
      포인트
      <v-progress-circular
        indeterminate
        color="white"
        :size="20"
      ></v-progress-circular>
    </div>
    <div v-else>
      <span v-if="totalPoint == null">
        포인트 조회 오류
      </span>
      <span v-else-if="totalPoint > 0">
        {{ totalPoint.toLocaleString() }}원

        <v-menu
          open-on-hover
          open-delay="500"
          close-delay="300"
          bottom
          offset-x
          offset-y
          nudge-top=""
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on" color="white">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <v-card width="450">
            <v-card-title class="justify-center font-weight-bold"
              >충전 잔액에 대해 설명 드릴게요😊
            </v-card-title>
            <v-card-text>
              <ul>
                <li>
                  명시된 "충전 잔액"은 유상 잔액과 무상 잔액이 합쳐진
                  금액입니다.
                </li>
                <li>"충전 잔액"은 유상 잔액부터 소진됩니다.</li>
                <li>
                  현재의 표시된 금액은 충전한 금액에서 캠페인 진행 금액을 정산한
                  충전금에 대한 잔액입니다.
                </li>
                <!-- <li>
                  충전 금액은 전일 진행된 캠페인 금액을 계산하여, 남은 금액을
                  익일 오전 11시에 업데이트 하고 있습니다.<br />
                  (예시: 2021년 10월 31일 진행된 캠페인 결과 및 정산금액 →
                  2021년 11월 1일 11시에 캠페인 진행 금액을 계산, 남은 금액을
                  오전 11시에 업데이트)
                </li> -->
              </ul>
            </v-card-text>
          </v-card>
        </v-menu>
      </span>
      <span v-else style="font-size:1.1em"
        >포인트를 충전해주세요<v-icon color="#FFE400">{{
          chargeIcon
        }}</v-icon></span
      >
    </div>
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      chargeIcon: 'mdi-flash-outline',
      flashOutlineIcon: 'mdi-flash-outline',
      flashIcon: 'mdi-flash'
    };
  },
  watch: {
    totalPoint(value) {
      if (value != null && value <= 0) {
        this.twinkleChargeIcon();
      }
    }
  },
  computed: {
    ...mapGetters({
      totalPoint: 'user/totalPoint',
      apiLoading: 'user/apiLoading'
    })
  },

  methods: {
    ...mapActions({
      openRechargeDialog: 'dialog/openRechargeDialog'
    }),
    handleUserPointButton() {
      if (this.totalPoint > 0) {
        if (this.$route.params.menu !== 'payment') {
          this.$router.push({
            name: 'AdvertiserMember',
            params: { menu: 'payment' }
          });
        }
      } else if (this.totalPoint <= 0) {
        this.openRechargeDialog();
      }
    },
    twinkleChargeIcon() {
      setInterval(() => {
        if (this.chargeIcon === this.flashOutlineIcon) {
          this.chargeIcon = this.flashIcon;
        } else {
          this.chargeIcon = this.flashOutlineIcon;
        }
      }, 700);
    }
  }
};
</script>

<style></style>
