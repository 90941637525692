import moment from 'moment';
import { getUserPointDayApi } from '@/api/advertiser/UserAPI';
import { PointCodeFlags, PointStatusFlags } from '@/config/charge';

export default {
  namespaced: true,

  state: {
    loading: false,
    buckets: [],
    columns: [
      {
        title: '날짜',
        align: 'center',
        dataIndex: 'transactionAt'
      },
      {
        title: '사용 유형',
        dataIndex: 'code',
        align: 'center'
      },
      {
        title: '구분',
        dataIndex: 'statusFlag',
        align: 'center',
        scopedSlots: {
          customRender: 'statusFlag'
        }
      },
      {
        title: '금액',
        dataIndex: 'sumAmount',
        align: 'right'
      },
      {
        title: '잔액',
        dataIndex: 'afterBalance',
        align: 'right'
      }
    ],
    payload: {
      page: 1,
      perPage: 20,
      codes: [
        'PCC00', // 쿠폰 광고 진행 비용 차감,
        'RDB00', // 직접입금 환불
        'RPCC0', // 카드결제 환불
        'R0000' // 환불
      ],
      statusFlag: ['+', '-'],
      amount: [0, 100000000],
      dates: []
    },
    pagination: {
      totalPage: 1,
      total: 0
    }
  },
  getters: {
    columns(state) {
      return state.columns;
    },
    payload(state) {
      return state.payload;
    },
    pagination(state) {
      return state.pagination;
    },
    buckets(state) {
      return state.buckets;
    },
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    SET_BUCKETS(state, value) {
      state.buckets = value;
    },
    SET_PAGINATION(state, value) {
      state.pagination = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async index({ commit, getters }) {
      commit('SET_LOADING', true);

      const payload = {
        params: getters.payload
      };

      const [error, data] = await getUserPointDayApi(payload);
      if (error) {
        console.error(error);
      } else {
        const { points, last_page, total } = data;

        const items = points.map((item, index) => {
          return {
            key: index,
            transactionAt: moment(item.transaction_at).format('YYYY-MM-DD'),
            sumAmount: (item.sum_amount * 1).toLocaleString(),
            statusFlag:
              PointStatusFlags.find(v => v.value === item.status_flag) || '-',
            code:
              PointCodeFlags.find(v => v.value === item.code)?.text ||
              item.remark ||
              '-',
            afterBalance: (item.after_balance * 1).toLocaleString()
          };
        });

        commit('SET_PAGINATION', {
          totalPage: last_page,
          total: total
        });

        commit('SET_BUCKETS', items);
        commit('SET_LOADING', false);
      }
    }
  }
};
