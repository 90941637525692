import { CampaignResource } from '@/model/common';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      dialog: false
    },
    campaignResource: new CampaignResource(),
    dialog: false,
    approveStatusDialog: false,
    filters: {
      // 회의 결과: 우선 보류 (2022.09.05)
      // campaignType: ['did', 'push', 'rolling'],
      // dataType: ['video', 'image'],
      campaignType: ['did'],
      dataType: ['video'],
      approveStatus: ['W', 'A', 'D'],
      userBusinessClass: ['advertiser', 'media'],
      searchText: '',
      searchKey: 'name'
    }
  },
  getters: {
    campaignResource(state) {
      return state.campaignResource;
    },
    dialog(state) {
      return state.dialog;
    },
    approveStatusDialog(state) {
      return state.approveStatusDialog;
    },
    loading(state) {
      return state.loading;
    },
    filters(state) {
      return state.filters;
    },
    filterParams(state) {
      const params = {
        campaignType: state.filters.campaignType,
        dataType: state.filters.dataType,
        approveStatus: state.filters.approveStatus,
        userBusinessClass: state.filters.userBusinessClass
      };

      params[`${state.filters.searchKey}`] = state.filters.searchText;

      return params;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_ApproveStatusDialog(state, dialog) {
      state.approveStatusDialog = dialog;
    },
    SET_CampaignResource(state, campaignResource) {
      state.campaignResource = new CampaignResource(campaignResource, true);
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_Filters(state) {
      state.filters = {
        // 회의 결과: 우선 보류 (2022.09.05)
        // campaignType: ['did', 'push', 'rolling'],
        // dataType: ['video', 'image'],
        campaignType: ['did'],
        dataType: ['video'],
        approveStatus: ['W', 'A', 'D'],
        userBusinessClass: ['advertiser', 'media'],
        searchText: '',
        searchKey: 'name'
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    async openDialog({ commit }, campaignResource = undefined) {
      commit('SET_CampaignResource', new CampaignResource());

      if (campaignResource) {
        commit('SET_CampaignResource', campaignResource);
      }

      commit('SET_Dialog', true);
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
      commit('SET_CampaignResource', new CampaignResource());
    },
    async openApproveStatusDialog({ commit }, campaignResource = undefined) {
      commit('SET_CampaignResource', new CampaignResource());

      if (campaignResource) {
        commit('SET_CampaignResource', campaignResource);
      }
      commit('SET_ApproveStatusDialog', true);
    },
    closeApproveStatusDialog({ commit }) {
      commit('SET_ApproveStatusDialog', false);
      commit('SET_CampaignResource', new CampaignResource());
    }
  }
};
