<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- <v-alert
          class="mb-2"
          border="left"
          colored-border
          icon="mdi-information-outline"
          color="blue"
          elevation="2"
          ><span
            >'라이브, CTR_라이브' 항목은 최대 30일 기간이내 검색시
            표시됩니다.</span
          >
        </v-alert> -->
        <!-- <ReportFilter
          v-if="$isMobile"
          :chartList="chartList"
          @applyFilters="applyFilters"
          :loading="loading"
        /> -->
        <SearchFilter @applyFilters="applyFilters" :loading="loading" />
      </v-col>
      <!-- <v-col cols="12">
        <ReportFilter
          :chartList="chartList"
          @applyFilters="applyFilters"
          :loading="loading"
        />
      </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="12" lg="4" v-for="chart in chartList" :key="chart.value">
        <ReportCharts
          :campaignType="chart.value"
          :title="chart.text"
          :dateRanges="dateRanges"
          :countLabel="chart.countLabel"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pa-5" v-if="false">
          <v-row>
            <v-col>
              <div class="float-right">
                <ReportExcelExport
                  :headers="headers"
                  :tableOptions="tableOptions"
                  :filters="filters"
                  :chartList="chartList"
                  :issetLive="issetLive"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          v-if="headers && !loading"
          :headers="headers"
          :items="stats"
          item-key="id"
          :loading="loading"
          hide-default-footer
          class="px-3 elevation-2"
          style="white-space: nowrap"
          loading-text="데이터 로딩..."
          no-data-text="데이터가 없습니다."
          mobile-breakpoint="0"
          :server-items-length="pagination.totalCount"
          disable-sort
        >
          <template slot="body.prepend" v-if="!loading && totalStats">
            <tr style="background-color: rgba(244, 244, 244, 1)">
              <td :colspan="totalRowColSpan" class="text-center">전체</td>
              <td class="text-right">
                {{ totalStats.impression.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ totalStats.reach.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ totalStats.click.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ totalStats.ctr }}
                %
              </td>
              <td class="text-right">
                {{ totalStats.ctrReach }}
                %
              </td>

              <td class="text-right">
                {{ totalStats.cpmiExpenditure | KRW }}
              </td>
              <td class="text-right">
                {{ totalStats.cpmrExpenditure | KRW }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <DayOfWeek :date="item.date" />
          </template>
          <template v-slot:[`item.impressionCount`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('impressionCount', index).color"
              >{{ getGapIcon('impressionCount', index).icon }}</v-icon
            >
            {{ item.impressionCount.toLocaleString() }}
          </template>
          <template v-slot:[`item.reachCount`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('reachCount', index).color"
              >{{ getGapIcon('reachCount', index).icon }}</v-icon
            >
            {{ item.reachCount.toLocaleString() }}
          </template>

          <template v-slot:[`item.clickCount`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('clickCount', index).color"
              >{{ getGapIcon('clickCount', index).icon }}</v-icon
            >
            {{ item.clickCount.toLocaleString() }}
          </template>
          <template v-slot:[`item.ctr`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('ctr', index).color"
              >{{ getGapIcon('ctr', index).icon }}</v-icon
            >
            {{ item.ctr }}
            %
          </template>
          <template v-slot:[`item.ctrReach`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('ctrReach', index).color"
              >{{ getGapIcon('ctrReach', index).icon }}</v-icon
            >
            {{ item.ctrReach }}
            %
          </template>
          <template v-slot:[`item.cpmiExpenditure`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('cpmiExpenditure', index).color"
              >{{ getGapIcon('cpmiExpenditure', index).icon }}</v-icon
            >
            {{ item.cpmiExpenditure | KRW }}
          </template>
          <template v-slot:[`item.cpmrExpenditure`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('cpmrExpenditure', index).color"
              >{{ getGapIcon('cpmrExpenditure', index).icon }}</v-icon
            >
            {{ item.cpmrExpenditure | KRW }}
          </template>
        </v-data-table>
        <v-skeleton-loader v-else type="table"> </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getTypeReportStatsApi()"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import ReportCharts from './components/ReportCharts.vue';
import DayOfWeek from '../../../../components/DayOfWeek.vue';
// import ReportFilter from './components/ReportFilter.vue';
import ReportExcelExport from './components/ReportExcelExport.vue';
import SearchFilter from './components/SearchFilter.vue';

export default {
  components: {
    MenuTitle,
    ReportCharts,
    DayOfWeek,
    // ReportFilter,
    SearchFilter,
    ReportExcelExport
  },
  data() {
    return {
      chartList: [
        {
          text: '이미지',
          value: 'rolling',
          options: ['click', 'impression'],
          countLabel: '노출'
        },
        {
          text: '텍스트',
          value: 'push',
          options: ['click', 'impression'],
          countLabel: '노출'
        },
        {
          text: '메시지',
          value: 'message',
          options: ['click', 'impression'],
          countLabel: '도달'
        }
      ],
      pagination: {
        page: 1,
        perPage: 30,
        totalPage: 1,
        totalCount: 0
      },
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      headers: [],
      stats: [],
      totalStats: null,
      loading: true,
      tableOptions: {},
      filters: null
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    totalRowColSpan() {
      return 1;
    },
    issetGapIcon() {
      return this.filters.measureStandard === 'date';
    },
    days() {
      const days = [];
      const dateStart = this.$moment(this.dateRanges[0], 'YYYY-MM-DD');
      const dateEnd = this.$moment(this.dateRanges[1], 'YYYY-MM-DD');
      while (dateEnd.diff(dateStart, 'days') >= 0) {
        days.push(dateStart.format('D'));
        dateStart.add(1, 'days');
      }
      return days;
    },
    issetLive() {
      return this.days.length > 30 ? false : true;
    }
  },
  methods: {
    applyFilters(filters) {
      this.filters = filters;
      this.dateRanges = filters.dateRanges;

      this.setHeaders();
      this.getTypeReportStatsApi(1);
    },
    initStats() {
      this.stats = [];
      this.totalStats = null;
    },
    getTypeReportStatsApi(page = this.pagination.page) {
      this.loading = true;
      this.stats = [];
      this.pagination.page = page;
      const params = {
        ss: this.searchString,
        page: page,
        perPage: this.pagination.perPage,
        tableOptions: this.tableOptions,
        filters: this.filters
      };

      return this.$axios
        .get('/adt/report-stats', {
          params: params
        })
        .then(res => {
          // console.log(res);
          this.setHeaders();
          const { stats, totalCount, totalStats } = res.data;
          this.stats = this.destructing(stats);
          this.totalStats = this.destructingTotal(totalStats);

          const { perPage } = this.pagination;
          const totalPage = parseInt(totalCount / perPage);
          this.pagination.totalCount = totalCount;
          this.pagination.totalPage =
            totalCount % perPage ? totalPage + 1 : totalPage;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setHeaders() {
      let headers = [
        { text: '날짜', value: 'date', width: 150 },
        { text: '광고 유형', value: 'campaign_type', align: 'center' },
        { text: '매체', value: 'media_id', width: 200, align: 'center' },
        { text: '노출', value: 'impressionCount', align: 'right' },
        { text: '도달', value: 'reachCount', align: 'right' },
        { text: '클릭', value: 'clickCount', align: 'right' },
        { text: 'CTR(노출)', value: 'ctr', align: 'right' },
        { text: 'CTR(도달)', value: 'ctrReach', align: 'right' },
        {
          text: '지출금액(CPM 노출)',
          value: 'cpmiExpenditure',
          align: 'right'
        },
        {
          text: '지출금액(CPM 도달)',
          value: 'cpmrExpenditure',
          align: 'right'
        }
      ];
      const hideColums = ['date', 'campaign_type', 'media_id'].filter(
        v => v !== this.filters.measureStandard
      );

      headers = headers.filter(el => !hideColums.includes(el.value));

      this.headers = headers;
    },
    destructing(items) {
      const chartList = this.chartList;
      const destructingData = items.map(item => {
        return {
          date: item.date,
          campaign_type:
            chartList.find(v => v.value === item.campaign_type)?.text || '',
          media_id: item?.media_name || '',
          impressionCount: item.impression,
          reachCount: item.reach,
          clickCount: item.click,
          ctr: item.ctr,
          ctrReach: item.ctr_reach,
          cpmiExpenditure: Number(item.cpmi_expenditure),
          cpmrExpenditure: Number(item.cpmr_expenditure)
        };
      });
      return destructingData;
    },
    destructingTotal(total) {
      const destructingData = {
        impression: total.impression,
        reach: total.reach,
        click: total.click,
        ctr: total.ctr,
        ctrReach: total.ctr_reach,
        cpmiExpenditure: Number(total.cpmi_expenditure),
        cpmrExpenditure: Number(total.cpmr_expenditure)
      };

      return destructingData;
    },
    getGapIcon(key, index) {
      if (this.stats.length === index + 1) {
        return '';
      }
      if (this.stats[index][key] > this.stats[index + 1][key]) {
        return {
          color: 'red',
          icon: `mdi-menu-up`
        };
      } else if (this.stats[index][key] < this.stats[index + 1][key]) {
        return {
          color: 'primary',
          icon: `mdi-menu-down`
        };
      } else {
        return {
          color: 'black',
          icon: `mdi-equal`
        };
      }
    }
  }
};
</script>
