<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-0 mx-auto px-5">
        <DidCampaignServiceRegisterHead />
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="mx-auto pa-0">
        <v-row v-if="dialogLoading" class="white">
          <v-col :cols="12" md="6">
            <v-skeleton-loader class="mb-6" type="article" />
            <v-skeleton-loader class="mb-6" type="list-item-three-line" />
            <v-skeleton-loader class="mb-6" type="list-item-three-line" />
            <v-skeleton-loader type="actions" />
          </v-col>
        </v-row>
        <form v-else @submit.prevent="submit">
          <transition name="fade">
            <!-- <campaign-admin :user.sync="didCampaignService.user" /> -->
          </transition>

          <v-row
            v-for="(step, index) in campaignSteps"
            :key="index"
            class="ma-0"
          >
            <v-col v-show="currentStep === step.value" class="pa-0">
              <transition name="component-fade" mode="out-in">
                <component v-bind:is="step.component" title="기본 설정" />
              </transition>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DidCampaignServiceRegisterHead from './DidCampaignServiceRegisterHead.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    DidCampaignServiceRegisterStep1: () =>
      import('./DidCampaignServiceRegisterStep1.vue'),
    DidCampaignServiceRegisterStep2: () =>
      import('./DidCampaignServiceRegisterStep2.vue'),
    DidCampaignServiceRegisterStep3: () =>
      import('./DidCampaignServiceRegisterStep3.vue'),
    DidCampaignServiceRegisterHead
  },
  computed: {
    ...mapGetters({
      currentStep: 'didCampaignService/currentStep',
      didCampaignService: 'didCampaignService/didCampaignService',
      dialog: 'didCampaignService/dialog',
      dialogLoading: 'didCampaignService/dialogLoading',
      campaignSteps: 'didCampaignService/didCampaignServiceSteps'
    })
  },

  methods: {
    ...mapActions({
      setCampaign: 'didCampaignService/setCampaign'
    }),
    submit() {
      this.$validator.validateAll();
    }
  }
};
</script>
<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.7s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
