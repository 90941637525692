<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ReportFilter @applyFilters="applyFilters" :loading="loading" />
      </v-col>
      <v-col cols="12">
        <MediaLiveChart
          v-if="filters && !loading"
          title="매체 라이브"
          :showTitle="false"
          :dateRanges="dateRanges"
          :stats="chartStats"
          :loading="loading"
        />
        <v-skeleton-loader v-show="loading" type="card"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col>
        <!-- <v-card class="pa-5">
          <div class="d-flex align-center">
            <div
              style="width: 50px"
              class="d-flex ml-auto align-center justify-space-around"
            >
              <ReportExcelExport :stats="stats" />
            </div>
          </div>
        </v-card> -->
        <v-card>
          <v-row>
            <v-col>
              <Table
                class="px-3 table-striped-rows"
                style="white-space:nowrap"
                :data-source="stats"
                :columns="columns"
                bordered
                :pagination="{
                  pageSize: 20,
                  position: 'bottom',
                  size: 'large'
                }"
                :loading="loading"
                size="small"
                :scroll="{
                  x: true
                }"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
                :expanded-row-keys.sync="expandedRowKeys"
              >
                <DayOfWeek
                  v-for="(date, dateIndex) in dates"
                  :key="dateIndex"
                  :slot="date.value"
                  :date="date.value"
                />
                <template
                  v-for="(date, dateIndex) in dates"
                  :slot="date.value"
                  slot-scope="data, record"
                >
                  <span
                    :key="`${dateIndex}`"
                    :class="
                      `${getGapIcon(record, dateIndex).color}--text darken-3`
                    "
                  >
                    <span class="title">{{ data.toLocaleString() }}</span>
                    <div class="d-flex align-center justify-end">
                      <v-icon :color="getGapIcon(record, dateIndex).color">{{
                        getGapIcon(record, dateIndex).icon
                      }}</v-icon>
                      {{ getGapIcon(record, dateIndex).percent }}
                    </div>
                  </span>
                </template>
              </Table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MenuTitle from '@/components/MenuTitle.vue';
import MediaLiveChart from './components/MediaLiveChart.vue';
import ReportFilter from './components/ReportFilter.vue';
import { Table } from 'ant-design-vue';
import DayOfWeek from '../../../../components/DayOfWeek.vue';

export default {
  components: {
    MenuTitle,
    MediaLiveChart,
    ReportFilter,
    Table,
    DayOfWeek
    // ReportExcelExport
  },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        totalCount: 0
      },
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      dates: [],
      columns: [],
      innerColumns: [],
      stats: [],
      totalStats: null,
      loading: true,
      tableOptions: {},
      filters: null,
      chartStats: [],
      detailStats: [],
      expandedRowKeys: []
    };
  },
  mounted() {
    this.setHeaders();
  },
  methods: {
    async applyFilters(filters) {
      // console.log(filters);
      this.filters = filters;
      this.dateRanges = filters.dateRanges;
      this.setHeaders();
      if (filters.mediaIds.length === 0) {
        this.initStats();
      } else {
        const { stats, chartStats, detailStats } = await this.getStatsApi(1);
        this.stats = stats;
        this.chartStats = chartStats;
        this.detailStats = detailStats;
      }
    },
    initStats() {
      this.stats = [];
      this.chartStats = [];
      this.totalStats = null;
    },
    async getStatsApi(page = this.pagination.page) {
      this.loading = true;
      this.stats = [];
      this.pagination.page = page;
      const params = {
        ss: this.searchString,
        page: page,
        perPage: this.pagination.perPage,
        filters: this.filters
      };

      try {
        const { data } = await this.$axios.get('/media/report-live', {
          params: params
        });
        // console.log(data);
        return data;
      } catch (err) {
        console.error(err);
        return null;
      } finally {
        this.loading = false;
      }
    },
    setHeaders() {
      this.setDates();
      const columns = [
        {
          title: '매체',
          dataIndex: 'media_name',
          align: 'left',
          slots: { title: 'customTitle' },
          fixed: true,
          width: 200
        }
      ];

      this.columns = [
        ...columns,
        ...this.dates.map(date => {
          return {
            key: date.value,
            dataIndex: date.value,
            align: 'right',
            slots: { title: date.value },
            scopedSlots: {
              customRender: date.value
            },
            sorter: (a, b) => a[date.value] - b[date.value],
            render: text => text.toLocaleString()
          };
        })
      ];
      this.innerColumns = [
        {
          title: '광고 유형',
          dataIndex: 'campaign_type',
          align: 'center'
        },
        ...this.dates.map(date => {
          return {
            title: date.value,
            key: date.value,
            dataIndex: date.value,
            align: 'center',
            slots: { title: 'customTitle' },
            scopedSlots: {
              customRender: date.value
            },
            sorter: (a, b) => a[date.value] - b[date.value],
            render: text => text.toLocaleString()
          };
        })
      ];
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },
    getGapIcon(record, dateIndex) {
      const { dates } = this;
      // console.log(record);
      // console.log(`date`, dates[dateIndex].value);
      if (dateIndex === 0) {
        return '';
      }
      const percent =
        (record[dates[dateIndex].value] / record[dates[dateIndex - 1].value]) *
          100 -
        100;

      if (record[dates[dateIndex].value] > record[dates[dateIndex - 1].value]) {
        return {
          color: 'red',
          icon: `mdi-menu-up`,
          percent: percent === Infinity ? '' : `${percent.toFixed(2)}%`
        };
      } else if (
        record[dates[dateIndex].value] < record[dates[dateIndex - 1].value]
      ) {
        return {
          color: 'blue',
          icon: `mdi-menu-down`,
          percent: percent === Infinity ? '' : `${percent.toFixed(2)}%`
        };
      } else {
        return {
          color: 'teal',
          icon: `mdi-equal`,
          percent: ''
        };
      }
    }
  }
};
</script>
<style>
.table-children-rows {
  background-color: #f6f6f6;
}
</style>
