<template>
  <v-card>
    <!-- <v-card-subtitle>{{ title }}</v-card-subtitle> -->
    <!-- <v-divider class="mx-4"></v-divider> -->
    <v-card-text>
      <v-row>
        <v-col>
          <div
            v-if="!loading"
            :ref="chartRef"
            style="width: 100%; min-height: 350px;  max-height:1000px;"
          ></div>
          <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    dateRanges: {
      type: Array,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    },
    countLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartTypeList: [
        {
          text: '이미지',
          value: 'rolling',
          options: ['click', 'impression'],
          chart: 'campaignReportChart0'
        },
        {
          text: '텍스트',
          value: 'push',
          options: ['click', 'impression'],
          chart: 'campaignReportChart1'
        },
        {
          text: '메시지',
          value: 'message',
          options: ['click', 'impression'],
          chart: 'campaignReportChart2'
        }
      ],
      dates: [],
      chartReg: [],
      chart: null,
      chartTypes: [],
      selectedChartTypes: [],
      loading: false
    };
  },
  computed: {
    chartRef() {
      return this.campaignType + 'ReportRef';
    }
  },
  mounted() {},
  watch: {
    dateRanges() {
      this.setReport();
    }
  },
  created() {
    this.initData();
    window.addEventListener('resize', this.resizeChart);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
    this.initChart();
  },
  methods: {
    initChart() {
      if (this.chart != null && this.chart != '' && this.chart != undefined) {
        this.chart.dispose();
      }
    },
    resizeChart() {
      if (this.chart != null && this.chart != undefined) {
        this.chart.resize();
      }
    },
    initData() {
      const selectedChartTypes = ['click', 'impression'];
      const chartTypes = [
        { text: this.countLabel, value: 'impression', color: '#5470C6' },
        { text: '클릭', value: 'click', color: '#EE6666' },
        { text: 'CTR', value: 'ctr', color: '#91CC75' }
      ];

      this.chartTypes = chartTypes;
      this.selectedChartTypes = selectedChartTypes;
    },
    async setReport() {
      const chartData = await this.getStatsApi();
      this.drawChart3(chartData);
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },

    async getStatsApi() {
      this.loading = true;
      this.setDates();
      this.tableDatas = [];
      try {
        const {
          data: { stats: stats }
        } = await this.$axios.get('/adt/report-stats-chart', {
          params: {
            campaignType: this.campaignType,
            dateRanges: this.dateRanges
          }
        });

        const { dates } = this;
        const chartData = dates.map(date => {
          const item = stats.findIndex(stat => date.value === stat.date);
          if (~item) {
            return stats[item];
          } else {
            return {
              date: date.value,
              impression: 0,
              click: 0,
              ctr: 0
            };
          }
        });
        return chartData;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    drawChart3(chartData) {
      this.initChart();
      // 설정값 셋업
      const { chartTypes, title } = this;

      // 차트 생성
      const chartDom = this.$refs[this.chartRef];
      const myChart = this.$echarts.init(chartDom);

      const colors = this.chartTypes.map(chart => chart.color);

      const xAxis = [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: chartData.map(data => data.date.slice(5)) // 날짜
        }
      ];

      const legend = {
        data: chartTypes.map(chart => chart.text),
        bottom: 0
      };

      const series = [
        {
          name: this.countLabel,
          type: 'line',
          data: chartData.map(data => data.impression),
          smooth: true,
          showSymbol: false
        },
        {
          name: '클릭',
          type: 'line',
          yAxisIndex: 1,
          data: chartData.map(data => data.click),
          smooth: true,
          showSymbol: false
        },
        {
          name: 'CTR',
          type: 'bar',
          yAxisIndex: 2,
          data: chartData.map(data => data.ctr),
          smooth: true,
          showSymbol: false
        }
      ];

      const option = {
        title: {
          text: this.title
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '80px',
          right: '110px'
        },
        toolbox: {
          right: '20px',
          bottom: '20px',
          feature: {
            // dataView: { readOnly: true, title: '로우 데이터' },
            saveAsImage: {
              show: true,
              title: '이미지 저장',
              name: `${title} 차트`
            }
          }
        },
        legend: legend,
        xAxis: xAxis,
        yAxis: [
          {
            type: 'value',
            name: this.countLabel,
            position: 'left',
            max: this.getMax(chartData, 'impression'),
            axisLine: {
              show: true,
              lineStyle: {
                color: chartTypes.find(v => v.text === this.countLabel).color
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '클릭',
            position: 'right',
            max: this.getMax(chartData, 'click'),
            axisLine: {
              show: true,
              lineStyle: {
                color: chartTypes.find(v => v.text === '클릭').color
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: 'CTR',
            offset: 50,
            max: this.getMin(chartData, 'ctr'),
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: chartTypes.find(v => v.text === 'CTR').color
              }
            },
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: series
      };
      this.chart = myChart;
      option && myChart.setOption(option);
    },

    // 해당 값의 자릿수 단위별 최댓값
    getMax(chartData, type) {
      const max = Math.max(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );

      let digits = 0;
      if (max > 1) {
        digits = Math.pow(10, Math.ceil(max).toString().length);
        return Math.ceil(max / digits) * digits;
      } else if (max > 0 && max < 1) {
        // 소수점 자릿수 구하기
        const subMax = Number(
          max
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      } else {
        return 0;
      }
    },
    // 해당 값의 자릿수 단위별 최솟값
    getMin(chartData, type) {
      const min = Math.min(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );
      let digits = 0;
      if (min >= 1) {
        digits = Math.pow(10, Math.ceil(min).toString().length);
        return Math.ceil(min / digits) * digits;
      } else {
        // 소수점 자릿수 구하기
        const subMax = Number(
          min
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return this.campaignType === 'message' ? 10 : 0.01 * digits;
      }
    }
  }
};
</script>

<style></style>
