<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row v-show="!loading">
      <v-col cols="12">
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn text color="primary" @click="openNoticeDialog()">
              등록하기
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card class="pa-3">
          <Table
            class="table-striped-rows"
            style="white-space:nowrap"
            size="small"
            :columns="columns"
            bordered
            :data-source="notices"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="{
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ['15', '30', '50', '100']
            }"
            :customRow="
              record => {
                return {
                  on: {
                    mouseenter: event => {
                      hoverItem = record.id;
                    },
                    mouseleave: event => {
                      hoverItem = null;
                    }
                  }
                };
              }
            "
          >
            <template slot="noticeTitle" slot-scope="value, record">
              <div
                class="d-inline-flex align-center"
                style="min-height:30px !important"
              >
                <span>{{ value }}</span>
                <div style="min-width:40px;">
                  <v-tooltip
                    v-if="hoverItem === record.id"
                    bottom
                    color="rgba(0,0,0,1)"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        class="ml-2"
                        v-on="{ ...onTooltip }"
                        icon
                        small
                        @click="openNoticeDialog(record.origin)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>수정</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template slot="isActive" slot-scope="value, record">
              <div class="d-flex align-center">
                <v-switch
                  v-model="record.isActive"
                  class="ma-0"
                  color="success"
                  inset
                  hide-details
                  readonly
                  @click="updateIsActive(record)"
                ></v-switch>
              </div>
            </template>
            <template slot="noticeGroup" slot-scope="value">
              {{ UserRoleList.find(v => v.value === value).text }}
            </template>
            <template slot="isUrgent" slot-scope="value">
              <v-chip v-if="value" color="error" label outlined>긴급</v-chip>
            </template>
            <template slot="endAt" slot-scope="value, record">
              <div v-if="record.issetEnd === false">
                설정안함
              </div>
              <div v-else>
                {{ value }}
              </div>
            </template>
          </Table>
        </v-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
    <!-- <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getNoticeApi()"
        ></v-pagination>
      </v-col>
    </v-row> -->
    <NoticeDialog v-if="noticeDialog" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import { Table } from 'ant-design-vue';
import NoticeDialog from './components/NoticeDialog.vue';
import { UserRoleList } from '@/config/user';

export default {
  components: {
    MenuTitle,
    Table,
    NoticeDialog
  },
  computed: {
    ...mapGetters({
      noticeDialog: 'notice/dialog'
    })
  },
  data() {
    return {
      UserRoleList: UserRoleList,
      columns: [
        {
          title: '번호',
          dataIndex: 'id',
          width: 80,
          key: 'id',
          scopedSlots: {
            customRender: 'showNumber'
          }
        },
        {
          title: '상태',
          dataIndex: 'isActive',
          key: 'isActive',
          width: 80,
          scopedSlots: {
            customRender: 'isActive'
          }
        },
        {
          title: '분류',
          dataIndex: 'category',
          key: 'category',
          width: 80
        },
        {
          title: '공지 일자',
          dataIndex: 'startAt',
          key: 'startAt',
          width: 200
        },
        {
          title: '공지 그룹',
          dataIndex: 'group',
          key: 'group',
          width: 100,
          scopedSlots: {
            customRender: 'noticeGroup'
          }
        },
        {
          title: '공지 제목',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {
            customRender: 'noticeTitle'
          }
        },
        {
          title: '긴급',
          dataIndex: 'isUrgent',
          key: 'isUrgent',
          scopedSlots: {
            customRender: 'isUrgent'
          },
          width: 50
        },
        {
          title: '등록일',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 200
        },
        {
          title: '종료일',
          dataIndex: 'endAt',
          key: 'endAt',
          scopedSlots: {
            customRender: 'endAt'
          },
          width: 200
        },
        {
          title: '마지막 수정일',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          width: 200
        }
      ],
      hoverItem: null,
      data: [],
      panel: [],
      notices: [],
      pagination: {
        page: 1,
        perPage: 15,
        totalPage: 1
      },
      loading: true
    };
  },

  async mounted() {
    //하위 컴포넌트에서 해당 이벤트 호출을 위해 on 이벤트를 등록합니다.
    const thisInstance = this;
    this.$root.$on('getNoticeApiEvent', function() {
      thisInstance.getNoticeApi();
    });
    this.getNoticeApi();
  },
  methods: {
    ...mapActions({
      openDialog: 'notice/openDialog',
      setDialogTypeEdit: 'notice/setDialogTypeEdit',
      setDialogTypeRegister: 'notice/setDialogTypeRegister',
      hashingNotice: 'notice/hashing',
      initNotice: 'notice/init',
      getNoticeApi: 'notice/getNoticeApi'
    }),
    async getNoticeApi(page = this.pagination.page) {
      try {
        const { data } = await this.$axios.get(`/admin/notice`, {
          params: {
            page: page,
            perPage: this.pagination.perPage
          }
        });
        const { totalCount } = data;
        const { perPage } = this.pagination;
        const totalPage = parseInt(totalCount / perPage);
        this.pagination.totalPage =
          totalCount % perPage ? totalPage + 1 : totalPage;

        this.notices = data.notices.map(notice => {
          return {
            origin: notice,
            id: notice.id,
            key: notice.id,
            showNumber: notice.show_number,
            isActive: notice.is_active,
            category: notice.category,
            startAt: this.$moment(notice.start_at).format('YYYY-MM-DD HH:mm'),
            content: notice.content,
            group: notice.group,
            title: notice.title,
            isUrgent: notice.is_urgent,
            createdAt: this.$moment(notice.created_at).format(
              'YYYY-MM-DD HH:mm'
            ),
            updatedAt: this.$moment(notice.updated_at).format(
              'YYYY-MM-DD HH:mm'
            ),
            endAt:
              notice.end_at == null
                ? '설정안함'
                : this.$moment(notice.end_at).format('YYYY-MM-DD HH:mm'),
            issetEnd: notice.isset_end
          };
        });
        console.log(this.notices);
      } catch (err) {
        console.error(err);
        return [];
      } finally {
        this.loading = false;
      }
    },
    openNoticeDialog(notice = null) {
      this.openDialog();
      if (notice !== null) {
        this.setDialogTypeEdit();
        this.hashingNotice(JSON.parse(JSON.stringify(notice)));
      } else {
        this.setDialogTypeRegister();
        this.initNotice();
      }
    },
    updateIsActive(item) {
      //기본 설정 값 유효성 체크

      this.$Swal
        .fire({
          html: `<h3>${item.id}번 공지사항을 ${
            !item.isActive ? '활성' : '비활성'
          } 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .put('/admin/notice-active', {
                noticeIds: [item.id],
                isActive: !item.isActive
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isActive = !item.isActive;
          }
        });
    }
  }
};
</script>

<style></style>
