<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-col cols="12">
      <a-card>
        <v-row align="center">
          <v-col>
            <a-descriptions bordered class="py-0">
              <a-descriptions-item label="기간">
                <FilterDatePicker
                  :style="{ display: 'inline-block' }"
                  :prefix="7"
                  @setDateRanges="
                    v => {
                      dateRanges = v;
                    }
                  "
                  :isOpend="true"
                  :defaultValue="dateRanges"
                  @remove="dateRanges = day"
                  @applyFilter="handleDateRanges()"
                />
              </a-descriptions-item>
            </a-descriptions>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  icon
                  @click="handleRefreshClick()"
                  :disabled="loading"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span class="title">새로고침</span>
            </v-tooltip></v-col
          >
        </v-row>
      </a-card>
    </v-col>

    <v-col
      cols="12"
      :lg="12 / chartList.length"
      v-for="chart in chartList"
      :key="chart.value"
    >
      <ReportCharts
        :title="chart.text"
        :dateRanges="dateRanges"
        campaignType="coupon"
      />
    </v-col>
    <v-row>
      <v-col>
        <v-card class="pa-5" v-if="false">
          <v-row>
            <v-col>
              <div class="float-right">
                <ReportExcelExport
                  v-if="selectFilter"
                  :headers="headers"
                  :tableOptions="tableOptions"
                  :filters="filters"
                  :chartList="chartList"
                  :issetLive="issetLive"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          v-if="headers && !loading"
          :headers="headers"
          :items="stats"
          item-key="id"
          :loading="loading"
          hide-default-footer
          class="px-3 elevation-2"
          style="white-space: nowrap"
          loading-text="데이터 로딩..."
          no-data-text="데이터가 없습니다."
          mobile-breakpoint="0"
          :server-items-length="pagination.totalCount"
          disable-sort
        >
          <template slot="body.prepend" v-if="!loading && totalStats">
            <tr style="background-color: rgba(244, 244, 244, 1)">
              <td :colspan="totalRowColSpan" class="text-center">전체</td>
              <td class="text-right">
                {{ totalStats.impression.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ totalStats.reach.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ totalStats.click.toLocaleString() }}
              </td>
              <td class="text-right">
                {{ totalStats.ctr }}
                %
              </td>
              <td class="text-right">
                {{ totalStats.ctrReach }}
                %
              </td>
              <td class="text-right">
                {{ Number(totalStats.expenditure) | KRW }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <DayOfWeek :date="item.date" />
          </template>
          <template v-slot:[`item.impressionCount`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('impressionCount', index).color"
            >
              {{ getGapIcon('impressionCount', index).icon }}
            </v-icon>
            {{ item.impressionCount.toLocaleString() }}
          </template>
          <template v-slot:[`item.reachCount`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('reachCount', index).color"
            >
              {{ getGapIcon('reachCount', index).icon }}
            </v-icon>
            {{ item.reachCount.toLocaleString() }}
          </template>

          <template v-slot:[`item.clickCount`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('clickCount', index).color"
            >
              {{ getGapIcon('clickCount', index).icon }}
            </v-icon>
            {{ item.clickCount.toLocaleString() }}
          </template>
          <template v-slot:[`item.ctr`]="{ item, index }">
            <v-icon v-if="issetGapIcon" :color="getGapIcon('ctr', index).color">
              {{ getGapIcon('ctr', index).icon }}
            </v-icon>
            {{ item.ctr }}
            %
          </template>
          <template v-slot:[`item.ctrReach`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('ctrReach', index).color"
            >
              {{ getGapIcon('ctrReach', index).icon }}
            </v-icon>
            {{ item.ctrReach }}
            %
          </template>
          <template v-slot:[`item.cpmiExpenditure`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('cpmiExpenditure', index).color"
            >
              {{ getGapIcon('cpmiExpenditure', index).icon }}
            </v-icon>
            {{ item.cpmiExpenditure | KRW }}
          </template>
          <template v-slot:[`item.cpmrExpenditure`]="{ item, index }">
            <v-icon
              v-if="issetGapIcon"
              :color="getGapIcon('cpmrExpenditure', index).color"
            >
              {{ getGapIcon('cpmrExpenditure', index).icon }}
            </v-icon>
            {{ Number(item.cpmrExpenditure) | KRW }}
          </template>
          <template v-slot:[`item.expenditure`]="{ item }">
            {{ Number(item.expenditure) | KRW }}
          </template>
        </v-data-table>
        <v-skeleton-loader v-else type="table"> </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getTypeReportStatsApi()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import ReportCharts from './components/ReportCharts.vue';
import DayOfWeek from '../../../../components/DayOfWeek.vue';
import ReportExcelExport from './components/ReportExcelExport.vue';
// import SearchFilter from './components/SearchFilter.vue';
import {
  updateFilterContentsAPI,
  storeFilterApi,
  getFiltersApi
} from '@/api/common/MenusAPI';
import { getReportStatsCouponAPI } from '@/api/partner/ReportAPI';
import { CampaignFilterType } from '@/config/partner/campaign';
import FilterDatePicker from '@/components/FilterDatePicker.vue';

export default {
  components: {
    MenuTitle,
    ReportCharts,
    DayOfWeek,
    // SearchFilter,
    ReportExcelExport,
    FilterDatePicker
  },
  data() {
    return {
      type: 'coupon',
      selectFilter: null,
      pagination: {
        page: 1,
        perPage: 30,
        totalPage: 1,
        totalCount: 0
      },
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      headers: [],
      stats: [],
      totalStats: null,
      loading: true,
      tableOptions: {},
      filters: null
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    totalRowColSpan() {
      return 1;
    },
    issetGapIcon() {
      // return this.filterMeasureStandard === 'date';
      return true;
    },
    chartList() {
      let resList = [
        // {
        //   text: '이미지',
        //   value: 'rolling',
        //   options: ['click', 'impression']
        // },
        // {
        //   text: '텍스트',
        //   value: 'push',
        //   options: ['click', 'impression']
        // },
        // {
        //   text: '메시지',
        //   value: 'message',
        //   options: ['click', 'impression']
        // },
        {
          text: '쿠폰',
          value: 'coupon',
          options: ['click', 'impression']
        }
      ];

      if (this.type) {
        return resList.filter(v => v.value === this.type);
      } else {
        resList = resList.filter(v => v.value !== 'coupon');
      }

      return resList;
    },
    hiddenOptions() {
      return ['campaignTypes', 'mediaGroupIds', 'measureStandard'];
    },
    days() {
      const days = [];
      const dateStart = this.$moment(this.dateRanges[0], 'YYYY-MM-DD');
      const dateEnd = this.$moment(this.dateRanges[1], 'YYYY-MM-DD');
      while (dateEnd.diff(dateStart, 'days') >= 0) {
        days.push(dateStart.format('D'));
        dateStart.add(1, 'days');
      }
      return days;
    },
    // 필터 측정기준
    filterMeasureStandard() {
      return (
        this.filters.find(v => v.key === 'measureStandard')?.value[0] || 'date'
      );
    },
    issetLive() {
      return this.days.length > 30 ? false : true;
    },
    filterObjFormat() {
      if (!this.filters) return null;

      const obj = {};
      this.filters.map(item => (obj[`${item.key}`] = item.value));

      return obj;
    },
    tableHeader() {
      return [
        { text: '날짜', value: 'date', width: 150 },
        { text: '노출', value: 'impressionCount', align: 'right' },
        { text: '도달', value: 'reachCount', align: 'right' },
        { text: '클릭', value: 'clickCount', align: 'right' },
        { text: 'CTR_노출', value: 'ctr', align: 'right' },
        { text: 'CTR_도달', value: 'ctrReach', align: 'right' },
        {
          text: '지출금액(CPC)',
          value: 'expenditure',
          align: 'right'
        }
      ];
    }
  },
  async created() {
    this.dateRanges = [
      this.$moment(this.day, 'YYYY-MM-DD')
        .subtract(6, 'days')
        .format('YYYY-MM-DD'),
      this.$moment(this.day, 'YYYY-MM-DD').format('YYYY-MM-DD')
    ];
    // await this.fetchFiltersApi();
    await this.handleDateRanges();
  },
  methods: {
    // 필터 목록 조회 API
    async fetchFiltersApi() {
      this.loading = true;

      const [error, data] = await getFiltersApi({
        params: {
          menuName: 'report',
          name: 'coupon',
          pagination: 'N'
        }
      });

      if (error) {
        console.log(error);
      } else {
        const { filters } = data;

        if (filters.length > 0) {
          const filtersText = filters[0].filters.map(
            item =>
              CampaignFilterType.find(type => type.value === item.key).text
          );

          this.filters = [
            ...filters[0].filters,
            { key: 'dateRanges', value: this.dateRanges }
          ];
          this.selectFilter = {
            ...filters[0],
            filters: this.filters,
            filtersText: filtersText,
            createdAt: this.$moment(filters[0].created_at).format('YYYY.MM.DD'),
            updatedAt: this.$moment(filters[0].updated_at).format('YYYY.MM.DD')
          };
        } else {
          this.filters = [
            // { key: 'isAdmin', value: [true, false] },
            { key: 'dateRanges', value: this.dateRanges }
            // { key: 'measureStandard', value: ['date'] }
          ];
          this.selectFilter = {
            filters: this.filters
          };
        }
      }
      this.loading = false;
    },
    async applyFilters(filters) {
      let convFilter = this.getFilterContentsFormat(filters);

      this.filters = JSON.parse(JSON.stringify(convFilter.filters));
      if (filters.dateRanges && filters.dateRanges.length > 0) {
        this.dateRanges = filters.dateRanges;
      }
      if (!this.headers || this.headers.length <= 0) {
        await this.setHeaders();
      }

      if (!this.filters) {
        // 처음에반 세팅
        // this.originFilter = JSON.parse(JSON.stringify(this.filters));
      } else {
        convFilter = this.getFilterContentsFormat(filters, ['dateRanges']);

        await Promise.all([
          this.selectFilter.id
            ? this.handelUpdateClick(convFilter)
            : this.handleStoreFilterClick(convFilter),
          this.getTypeReportStatsApi(1)
        ]);
      }
    },
    initStats() {
      this.stats = [];
      this.totalStats = null;
    },
    async getTypeReportStatsApi(page = this.pagination.page) {
      this.loading = true;
      this.stats = [];
      this.pagination.page = page;
      // const filters = {
      //   ...this.filterObjFormat,
      //   dateRanges: this.dateRanges
      // };

      const params = {
        page: page,
        perPage: this.pagination.perPage,
        tableOptions: this.tableOptions,
        // filters: filters,
        campaignType: this.campaignType,
        dateRanges: this.dateRanges
      };

      const [error, data] = await getReportStatsCouponAPI({ params: params });

      if (error) {
        console.log(error);
      } else {
        const { stats, totalCount, totalStats } = data;
        this.stats = this.destructing(stats);
        this.totalStats = this.destructingTotal(totalStats);

        const { perPage } = this.pagination;
        const totalPage = parseInt(totalCount / perPage);
        this.pagination.totalCount = totalCount;
        this.pagination.totalPage =
          totalCount % perPage ? totalPage + 1 : totalPage;
      }
      this.loading = false;
    },

    destructing(items) {
      const chartList = this.chartList;
      const destructingData = items.map(item => {
        return {
          date: item.date,
          campaign_type:
            chartList.find(v => v.value === item.campaign_type)?.text || '',

          impressionCount: item.impression,
          reachCount: item.reach,
          live: item?.live || 0,
          clickCount: item.click,
          uniqueClickCount: item.unique_click,
          ctr: item.ctr,
          ctrReach: item.ctr_reach,
          ctrLive: item?.ctr_live || 0,
          expenditure: Number(item.expenditure),
          cpmiExpenditure: Number(item.cpmi_expenditure),
          cpmrExpenditure: Number(item.cpmr_expenditure)
        };
      });
      return destructingData;
    },
    destructingTotal(total) {
      return {
        impression: total.impression,
        reach: total.reach,
        click: total.click,
        uniqueClick: total.unique_click,
        ctr: total.ctr,
        live: total?.live || 0,
        ctrReach: total.ctr_reach,
        ctrLive: total?.ctr_live || 0,
        expenditure: Number(total.expenditure),
        cpmiExpenditure: Number(total.cpmi_expenditure),
        cpmrExpenditure: Number(total.cpmr_expenditure)
      };
    },
    async setHeaders() {
      // const hideColums = ['date', 'campaign_type', 'media_id'].filter(
      //   v => v !== this.filterMeasureStandard
      // );
      let header = this.tableHeader;
      // header = header.filter(el => !hideColums.includes(el.value));
      if (!this.issetLive) {
        header = header.filter(el => !['live', 'ctrLive'].includes(el.value));
      }

      this.headers = header;
    },
    getGapIcon(key, index) {
      if (this.stats.length === index + 1) {
        return '';
      }
      if (this.stats[index][key] > this.stats[index + 1][key]) {
        return {
          color: 'red',
          icon: `mdi-menu-up`
        };
      } else if (this.stats[index][key] < this.stats[index + 1][key]) {
        return {
          color: 'primary',
          icon: `mdi-menu-down`
        };
      } else {
        return {
          color: 'black',
          icon: `mdi-equal`
        };
      }
    },
    // 필터 등록
    async handleStoreFilterClick(newFilter) {
      const [error, data] = await storeFilterApi({
        menuName: 'report',
        filters: newFilter.filters,
        columns: this.headers,
        name: 'coupon'
      });

      if (error) {
        console.log(error);
        this.$Swal.showValidationMessage(`요청 실패`);
      } else {
        this.selectFilter.id = data.id;
      }
    },
    async handelUpdateClick(newFilter) {
      const [error] = await updateFilterContentsAPI(newFilter.id, {
        filters: newFilter.filters
      });

      if (error) {
        console.log(error);
      } else {
        this.selectFilter = JSON.parse(JSON.stringify(newFilter));
        this.filters = JSON.parse(JSON.stringify(newFilter.filters));
      }
    },
    getFilterContentsFormat(filterObj, hiddenKeys = []) {
      const contents = [];

      for (const key in filterObj) {
        const value = filterObj[key];
        if (!hiddenKeys.length || !hiddenKeys.includes(key)) {
          contents.push({ key: `${key}`, value: value });
        }
      }

      return { ...this.selectFilter, filters: contents };
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }

      this.dates.reverse();
    },
    handleDateRanges() {
      this.setHeaders();
      this.getTypeReportStatsApi();
    },
    handleRefreshClick() {
      this.dateRanges = JSON.parse(JSON.stringify(this.dateRanges));
      this.getTypeReportStatsApi();
    }
  }
};
</script>
