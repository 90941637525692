export default {
  namespaced: true,
  state: {
    filters: {
      status: ['W', 'S', 'C'],
      searchString: ''
    },
    dialog: false,
    notification: null,
    target_options: [
      {
        id: 1,
        value: '앱설치 ⭕️+결제 ❌',
      },{
        id: 2,
        value: '앱설치 ⭕️+결제 ⭕️+캠페인 생성 ❌',
      }
    ],
    status_options: [
      {
        value: 'W',
        text: '발송 대기',
        color: 'success',
      },{
        value: 'S',
        text: '발송 완료',
        color: 'warning',
      },{
        value: 'C',
        text: '발송 취소',
        color: 'secondary',
      },{
        value: 'F',
        text: '발송 실패',
        color: 'error',
      },
    ]
  },
  getters: {
    filters(state) {
      return state.filters;
    },
    dialog(state) {
      return state.dialog;
    },
    notification(state) {
      return state.notification;
    },
    targetOptions(state) {
      return state.target_options;
    },
    statusOptions(state) {
      return state.status_options;
    }
  },
  mutations: {
    SET_Notification(state, notification) {
      state.notification = notification ?? null;
    },
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    }
  },
  actions: {
    openDialog({ commit }, notification) {
      commit('SET_Dialog', true);
      commit('SET_Notification', notification ?? {
        id: null,
        title: '',
        body: '',
        users: [],
        desciption:'', 
        status: false,
        send_at: null,
        reserved_at: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        target: '', // 저장은 안함
        immediately: false, // 즉시발송
      });
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
      commit('SET_Notification', null);
    }
  }
};
