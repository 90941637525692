<template>
  <v-dialog
    v-model="localDialog"
    scrollable
    persistent
    eager
    :maxWidth="type === 'date' ? 700 : 500"
  >
    <v-row class="did-campaign-update-dialog ma-0" style="min-height: 100%">
      <v-card style="width: 100%">
        <v-toolbar flat dark>
          <v-toolbar-title>캠페인 추가</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="handleCloseClick">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="py-3 px-0">
          <ValidationObserver>
            <v-row
              v-if="type === 'settopBox'"
              class="ma-0 fit"
              style="min-height: 500px; flex-direction: column"
            >
              <v-col cols="auto" class="pb-0 pl-5">
                <h3 class="subtitle-2 font-weight-bold">
                  <v-icon color="error" x-small>mdi-asterisk</v-icon>
                  노출 매체 그룹
                </h3>
                <v-col cols="auto" class="pa-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true }"
                  >
                    <v-autocomplete
                      v-model="didCampaignService.didMediaGroupId"
                      outlined
                      hide-no-data
                      multiple
                      clearable
                      placeholder="광고를 노출할 매체그룹을 선택해 주세요"
                      :items="didMediaGroups"
                      item-text="name"
                      item-value="id"
                      dense
                      :disabled="dialogLoading"
                      :error-messages="errors"
                      :hide-details="!errors.length"
                      @change="handleMediaGroupId"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span
                            v-if="
                              didCampaignService.didMediaGroupId.length ===
                              didMediaGroups.length
                            "
                          >
                            전체선택
                          </span>
                          <span v-else>
                            {{ item.name }}
                            {{
                              didCampaignService.didMediaGroupId.length > 1
                                ? ` 외 ${
                                    didCampaignService.didMediaGroupId.length -
                                    1
                                  } 개`
                                : ''
                            }}
                          </span>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-chip
                          v-if="item.did_media_group_name"
                          label
                          small
                          class="mr-2"
                          color="secondary"
                          outlined
                        >
                          <!-- 매체사 그룹 이름 -->
                          {{ item.did_media_group_name }}
                        </v-chip>
                        {{ item.name }}
                        <span class="ml-2 caption">
                          ({{ item.type === 'D' ? '직매채' : '대행사' }})
                        </span>
                      </template>

                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleDidMediaGroupIds">
                          <v-list-item-content>
                            <v-list-item-title> 전체선택 </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-col>

              <v-col cols="auto" class="pb-0 pl-5">
                <h3 class="subtitle-2 font-weight-bold">
                  <v-icon color="error" x-small>mdi-asterisk</v-icon>
                  노출 매체
                </h3>
                <v-col :cols="12" class="pa-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{
                      required:
                        !!didCampaignService.didMediaGroupId &&
                        didCampaignService.didMediaGroupId.length > 0
                    }"
                  >
                    <v-autocomplete
                      v-model="didCampaignService.didBranchIds"
                      outlined
                      hide-no-data
                      multiple
                      clearable
                      placeholder="광고를 노출할 매체을 선택해 주세요"
                      :items="filteredDidBranches"
                      item-text="name"
                      item-value="id"
                      dense
                      :disabled="
                        dialogLoading || !didCampaignService.didMediaGroupId
                      "
                      :error-messages="errors"
                      :hide-details="!errors.length"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span
                            v-if="
                              didCampaignService.didBranchIds.length ===
                              didBranches.length
                            "
                          >
                            전체선택
                          </span>
                          <span v-else>
                            {{ item.name }}
                            {{
                              didCampaignService.didBranchIds.length > 1
                                ? ` 외 ${
                                    didCampaignService.didBranchIds.length - 1
                                  } 개`
                                : ''
                            }}
                          </span>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-chip
                          v-if="item.did_media_group_name"
                          label
                          small
                          class="mr-2"
                          color="secondary"
                          outlined
                        >
                          <!-- 매체사 그룹 이름 -->
                          {{ item.did_media_group_name }}
                        </v-chip>
                        {{ item.name }}
                      </template>

                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleDidBranchIds">
                          <v-list-item-content>
                            <v-list-item-title> 전체선택 </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-col>

              <v-col
                :cols="12"
                class="row ma-0 flex-column"
                style="flex-grow: 1"
              >
                <!-- 선택한 셋톱박스 목록 -->
                <ValidationProvider :rules="{ required: true, min_value: 1 }">
                  <input hidden v-model="selectedSettopBoxCount" />
                </ValidationProvider>

                <v-col cols="auto" style="height: auto">
                  <h3 class="row ma-0 subtitle-2 font-weight-bold">
                    <v-icon color="error" x-small>mdi-asterisk</v-icon>
                    사용가능 셋톱 박스 ( 선택: {{ selectedSettopBoxCount }} )

                    <v-spacer />
                    <!-- <v-chip
            outlined
            small
            :color="
              filteredSettopBoxes.length === didCampaignService.settopBoxes
                ? '#00d1b2'
                : 'secondary'
            "
          > -->
                    <v-chip
                      outlined
                      small
                      :color="
                        filteredSettopBoxes.length === selectedSettopBoxCount
                          ? '#00d1b2'
                          : 'secondary'
                      "
                      @click.stop="() => selectedAllSettopBox()"
                    >
                      전체
                      {{
                        filteredSettopBoxes.length === selectedSettopBoxCount
                          ? '해제'
                          : '선택'
                      }}
                    </v-chip>
                  </h3>
                </v-col>
                <v-col
                  v-if="filteredSettopBoxes.length > 0"
                  style="max-height: 100%; min-height: 100%; overflow: auto"
                  class="invalid_settop_box row"
                >
                  <a-table
                    class="pl-3 table-striped-rows"
                    :columns="columns"
                    :data-source="filteredSettopBoxes"
                    size="small"
                    :locale="{
                      emptyText: '데이터가 없습니다.'
                    }"
                    bordered
                    rowKey="id"
                    :row-selection="{
                      selectedRowKeys: didCampaignService.didSettopBoxIds,
                      onChange: selected => onSelectChange(selected)
                    }"
                    :pagination="{ pageSize: 10 }"
                    :scroll="{ x: true }"
                    style="width: 100%"
                  >
                    <template slot="settopBoxCostPerDay" slot-scope="value">
                      <div
                        class="text-right subtitle-body font-weight-600 red--text"
                      >
                        {{ (value * 1).toLocaleString() }}
                      </div>
                    </template>
                  </a-table>
                </v-col>
                <v-col
                  v-else
                  class="row align-center justify-center ma-0"
                  style="border: 1px solid #ddd"
                >
                  <h4 class="font-weight-bold text-center">
                    노출 매체를 선택해 주세요.
                  </h4>
                </v-col>
              </v-col>
            </v-row>

            <v-row
              v-if="type === 'date'"
              class="ma-0 fit"
              style="min-height: 500px; flex-direction: column"
            >
              <v-col cols="auto" class="pb-0 pl-5">
                <h3 class="subtitle-2 font-weight-bold">
                  <v-icon color="error" x-small>mdi-asterisk</v-icon>
                  캠페인 연장일 선택
                </h3>
                <v-col cols="auto" class="pa-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="{ required: true }"
                  >
                    <template v-if="datepickerLoading">
                      <v-skeleton-loader
                        class="mb-0"
                        type="list-item, card-heading, date-picker-days"
                      />
                      <v-skeleton-loader
                        class="mb-6"
                        type="date-picker-days, actions"
                      />
                    </template>
                    <v-date-picker
                      v-show="!datepickerLoading"
                      v-model="selectedDates"
                      :allowed-dates="checkAllowedDates"
                      :min="minDate"
                      :max="maxDate"
                      full-width
                      no-title
                      color="#00d1b2"
                      multiple
                      locale="ko-KR"
                      class="custom-date-picker"
                      @update:picker-date="fetchDidCampaignServiceDailySchedule"
                    />
                    <input
                      type="hidden"
                      v-model="errors[0]"
                      v-if="errors.length"
                    />
                  </ValidationProvider>
                </v-col>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined @click="handleCloseClick()"> 취소 </v-btn>

          <v-btn
            v-if="type === 'settopBox'"
            :disabled="!selectedSettopBoxCount"
            :loading="dialogLoading"
            color="secondary"
            @click="handleAddSettopBox(didCampaignService.didSettopBoxIds)"
          >
            추가
          </v-btn>
          <v-btn
            v-if="type === 'date'"
            :disabled="!selectedDateCount"
            :loading="dialogLoading"
            color="secondary"
            @click="handleAddDate(selectedDates)"
          >
            연장
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay> -->
    </v-row>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { getDidBranchOptionsApi } from '@/api/admin/DidBranchAPI';
import { getDidMediaGroupOptionsApi } from '@/api/admin/DidMediaGroupAPI';
import { getDidSettopBoxOptionsApi } from '@/api/admin/DidSettopBoxAPI';
import { getDidCampaignDailyScheduleApi } from '@/api/admin/DidCampaignAPI';

import { DidCampaignService } from '@/model/admin/did';

export default {
  props: [
    'value',
    'type',
    'loading',
    'defaultSettopBoxIds',
    'defaultBranchIds',
    'defaultDates'
  ],
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      refKey: 'didCampaignUpdateDialog',
      localDialog: false,
      dialogLoading: false,
      didMediaGroups: [],
      didBranches: [],
      didSettopBoxes: [],
      didCampaignService: new DidCampaignService(),

      minDate: this.$moment().format('YYYY-MM-DD'), // 최소 선택 가능일
      maxDate: null, // 최대 선택 가능일
      searchMoths: [], // 조회한 달
      dailySchedule: [], // 선택 가능한 날짜
      selectedDates: [],
      datepickerLoading: false
    };
  },
  computed: {
    allDidBranches() {
      return (
        this.didCampaignService.didBranchIds.length ===
        this.filteredDidBranches.length
      );
    },
    allDidMediaGroups() {
      return (
        this.didCampaignService.didMediaGroupId?.length ===
        this.didMediaGroups.length
      );
    },
    filteredDidBranches() {
      return (
        this.$refs.networkAC?.filteredItems ||
        this.filteredByMediaGroupDidBranches
      );
    },
    filteredByMediaGroupDidBranches() {
      return this.didBranches.filter(item =>
        this.didCampaignService.didMediaGroupId
          ? this.didCampaignService.didMediaGroupId?.includes(
              item.did_media_group_id
            )
          : item
      );
    },
    filteredSettopBoxes() {
      let defaultSettopBoxIds = this.defaultSettopBoxIds ?? [];
      if (!this.didCampaignService.didBranchIds.length) return [];

      return this.didSettopBoxes.filter(
        item =>
          !defaultSettopBoxIds.includes(item.id) &&
          this.didCampaignService.didBranchIds?.includes(item.did_branch_id)
      );
    },
    // 사용가능 셋톱박스 테이블 columns
    columns() {
      return [
        {
          title: '지점 이름',
          dataIndex: 'did_branch_name',
          key: 'did_branch_name',
          align: 'center',
          sorter: (a, b) => a.did_branch_name.localeCompare(b.did_branch_name)
        },
        {
          title: '이름',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          sorter: (a, b) => a.name.localeCompare(b.name)
        }
      ];
    },
    selectedSettopBoxCount() {
      return this.didCampaignService.didSettopBoxIds.length;
    },
    selectedDateCount() {
      return this.selectedDates.length;
    },
    // 상세기간 날짜 목록
    dateOptions() {
      if (!this.dailySchedule.length) return [];

      const dates = this.defaultDates;

      return Array.from(new Set(dates)).filter(item =>
        this.dailySchedule.includes(item)
      );
    }
  },
  watch: {
    value(newVal) {
      this.localDialog = newVal ? true : false;
      this.dialogLoading = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    },
    loading(newVal) {
      this.dialogLoading = newVal;
    }
    // type(newVal, prevVal) {
    //   this.dialogLoading = false;

    //   if (prevVal || !newVal) {
    //     this.localDialog = false;
    //     return;
    //   }

    //   if (newVal === 'settopBox') {
    //     // 셋톱박스 목록 조회
    //   }

    //   if (newVal === 'date') {
    //     // 날짜 해당 캠페인 오늘 날짜 이후로  조회
    //   }
    // }
  },
  mounted() {
    this.dialogLoading = false;
    this.localDialog = this.value ?? false;
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([
        //settopBox
        this.fetchDidMediaGroupOptions(),
        this.fetchDidBranchOptions(),
        this.fetchDidSettopBoxOptions(),
        // date
        this.fetchDidCampaignServiceDailySchedule()
      ]);

      if (this.$refs.didCampaignServiceRef) {
        this.$refs.didCampaignServiceRef.reset();
      }
    },
    /** 매체 그룹 콤보 박스 목록 조회 */
    async fetchDidMediaGroupOptions() {
      this.didMediaGroups = [];
      const [error, data] = await getDidMediaGroupOptionsApi({
        params: { isActive: false }
      });
      if (error) {
        console.log(error);
      } else {
        const { did_media_groups: didMediaGroups } = data;
        this.didMediaGroups = didMediaGroups;
      }
    },
    /** 매체 콤보 박스 목록 조회 */
    async fetchDidBranchOptions() {
      this.didBranches = [];
      const [error, data] = await getDidBranchOptionsApi();
      if (error) {
        console.log(error);
      } else {
        const { branches } = data;
        this.didBranches = branches;
      }
    },
    /** 셋톱박스 콤보 박스 목록 조회 */
    async fetchDidSettopBoxOptions() {
      this.didSettopBoxes = [];
      const [error, data] = await getDidSettopBoxOptionsApi();
      if (error) {
        console.log(error);
      } else {
        const { settop_boxes } = data;
        this.didSettopBoxes = settop_boxes;
      }
    },
    /** 사용 가능일 & 셋톱박스 목록 조회 */
    async fetchDidCampaignServiceDailySchedule(month = null) {
      // 조회 시작일, 조회 종료일
      const params = {
        startDate: month
          ? this.$moment(month).startOf('month').format('YYYY-MM-DD')
          : this.minDate,
        endDate: this.$moment(month ?? this.minDate)
          .endOf('month')
          .format('YYYY-MM-DD'),
        branches: this.defaultBranchIds ?? [],
        settopBoxes: this.defaultSettopBoxIds ?? []
      };

      const searchMonth = this.$moment(params.startDate)
        .endOf('month')
        .format('YYYY-MM');

      // 이미 조회한 달이면 패스
      if (this.searchMoths.includes(searchMonth)) return;

      this.datepickerLoading = true;

      const [error, data] = await getDidCampaignDailyScheduleApi({
        params: params
      });

      if (error) {
        console.error(error);
        this.datepickerLoading = false;
      } else {
        const { schedule } = data;
        const tempSchedule = Object.keys(schedule).filter(
          item => schedule[item].length > 0
        );
        // 예약 가능한 스케줄 목록
        this.dailySchedule = [...this.dailySchedule, ...tempSchedule];
        this.datepickerLoading = false;
        this.searchMoths.push(searchMonth);
      }
    },
    /** 날짜 선택 가능여부 체크 */
    checkAllowedDates(val) {
      return !this.dateOptions.includes(val);
    },
    /** @description: validate  */
    async submit() {
      const valid = await this.$refs.didCampaignServiceRef.validate();
      if (valid) {
        const step = this.currentStep;
        this.setCurrentStep(step + 1);
      }
    },
    /** @description: 매체 선택 변경시 */
    toggleDidBranchIds() {
      this.$nextTick(() => {
        if (this.allDidBranches) {
          this.didCampaignService.didBranchIds = [];
        } else {
          this.didCampaignService.didBranchIds = this.filteredDidBranches
            .slice()
            .map(v => v.id);
        }
        this.didCampaignService.didBranchs =
          this.filteredByMediaGroupDidBranches.filter(item =>
            this.didCampaignService.didBranchIds.includes(item.id)
          );
      });
    },
    /** @description: 매체 그룹 선택 변경시 */
    toggleDidMediaGroupIds() {
      this.$nextTick(() => {
        let tempDidMediaGroupId = [];
        if (!this.allDidMediaGroups) {
          tempDidMediaGroupId = this.didMediaGroups.map(item => item.id);
        }

        this.$set(
          this.didCampaignService,
          'didMediaGroupId',
          tempDidMediaGroupId
        );

        if (!tempDidMediaGroupId.length) {
          this.didCampaignService.didSettopBoxIds = [];
          this.didCampaignService.didBranchIds = [];
        }
      });
    },
    handleMediaGroupId() {
      this.didCampaignService.didBranchIds = [];
      // this.fetchDidMediaGroupOptions();
    },
    /**@description: 셋톱박스 선택했을 경우 */
    onSelectChange(selectedRowKeys) {
      // 사용가능 셋톱박스 목록에 있는 index 로 선택한 ID값 세팅

      this.didCampaignService.didSettopBoxIds = [...selectedRowKeys];
      // this.filteredSettopBoxes[index].list.selectedIds = [
      //   ...selectedRowKeys
      // ];
    },
    selectedAllSettopBox() {
      if (this.selectedSettopBoxCount === this.filteredSettopBoxes.length) {
        this.didCampaignService.didSettopBoxIds = [];
      } else {
        this.didCampaignService.didSettopBoxIds = [
          ...this.filteredSettopBoxes.map(item => item.id)
        ];
      }
    },
    /** @description: 셋톱박스 추가 */
    handleAddSettopBox(settopBoxIds = []) {
      if (this.dialogLoading) return;

      if (!settopBoxIds.length) {
        this.$Swal.fire({
          icon: 'error',
          html: '추가 가능한 셋톱박스가 없습니다.',
          confirmButtonText: '확인'
        });
        return;
      }

      this.$emit('addSettopBox', settopBoxIds);
    },
    /** @description: 셋톱박스 추가 */
    handleAddDate(dates = []) {
      if (this.dialogLoading) return;

      if (!dates.length) {
        this.$Swal.fire({
          icon: 'error',
          html: '추가 가능한 일자가 없습니다.',
          confirmButtonText: '확인'
        });
        return;
      }
      this.$emit('addDate', this.selectedDates);
    },
    /** @description: 닫기 */
    handleCloseClick() {
      this.$emit('closeDialog');
    },
    handleRefreshList() {
      this.$emit('refresh');
    }
  }
};
</script>
<style lang="scss">
.did-campaign-update-dialog {
  .calendar-dim {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .custom-date-picker {
    .v-date-picker-table {
      height: auto !important;
    }
    td {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    td > button {
      padding: 25px !important;
    }
  }

  .invalid_settop_box {
    .v-expansion-panel-content__wrap {
      padding: 15px 0 0;
    }
  }
}
</style>
