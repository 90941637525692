export default {
    namespaced: true,

    state: {
        bankInfo: {
            account: '주식회사 티디아이',
            accountNumber: '094-085337-04-062',
            name: '기업은행'
        },
        businessInfo: {
            businessNumber: '617-81-95610',
            address: '서울특별시 서초구 반포대로 20길 29 (서초동, TDI 타워)',
            name: '티디아이',
            presidentName: '이승주'
        },
        paymentMethods: [{
                text: '카드결제',
                value: 'creditcard'
            },
            {
                text: '직접입금',
                value: 'banktransfer'
            }
        ],
        directOrderStatusFlags: [{
                text: '결제대기',
                value: 'W'
            },
            {
                text: '충전완료',
                value: 'S',
            },
            {
                text: '결제실패',
                value: 'F'
            },
            {
                text: '결제취소',
                value: 'C'
            }
        ],
        pgOrderStatusFlags: [{
                text: '결제승인대기',
                value: 'W'
            },
            {
                text: '충전완료',
                value: 'S',
            },
            {
                text: '결제실패',
                value: 'F'
            },
            {
                text: '결제취소',
                value: 'C'
            }
        ],
        pointTypeFlags: [{
                text: '유상 충전',
                value: 'C'
            },
            {
                text: '무상 충전',
                value: 'F'
            },
            {
                text: '환불',
                value: 'R'
            }
        ],
    },
    getters: {
        bankInfo(state) {
            return state.bankInfo
        },
        businessInfo(state) {
            return state.businessInfo
        },
        paymentMethods(state) {
            return state.paymentMethods
        },
        directOrderStatusFlags(state) {
            return state.directOrderStatusFlags
        },
        pgOrderStatusFlags(state) {
            return state.pgOrderStatusFlags
        },
        pointTypeFlags(state) {
            return state.pointTypeFlags
        }
    },
}