<template>
  <div class="filters mt-8">
    <a-spin :spinning="loading">
      <a-form-model ref="storeForm" :model="form" :rules="rules">
        <a-descriptions title="기본 정보" bordered>
          <a-descriptions-item label="광고 송출 여부" :span="3">
            <v-switch
              color="info"
              class="mt-0 ml-3"
              inset
              hide-details
              v-model="form.active"
            />
          </a-descriptions-item>

          <a-descriptions-item label="라이브 집계 여부" :span="3">
            <v-switch
              color="info"
              class="mt-0 ml-3"
              inset
              hide-details
              v-model="form.aggReachActive"
            />
          </a-descriptions-item>

          <a-descriptions-item label="매체명" :span="3">
            <a-form-model-item ref="name" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="매체그룹" :span="3">
            <a-form-model-item ref="mediaGroupId" prop="mediaGroupId">
              <a-select
                v-model="form.mediaGroupId"
                :style="{ width: '300px' }"
                :disabled="type === 'detail' && !superAdmin"
              >
                <a-select-option
                  v-for="(item, index) in mediaGroups"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="패키지" :span="3">
            <a-form-model-item ref="package" prop="package">
              <a-input v-model="form.package" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="스토어 URL" :span="3">
            <a-form-model-item ref="storeUrl" prop="storeUrl">
              <a-input v-model="form.storeUrl" />
            </a-form-model-item>
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="매체비율 설정"
          bordered
          class="mt-7"
          v-if="superAdmin && mediaGroupType"
        >
          <a-descriptions-item
            label="직매체 매출 비율"
            :span="3"
            v-if="mediaGroupType === 'D'"
          >
            <a-form-model-item ref="directProfitRatio" prop="directProfitRatio">
              <a-input
                v-model="form.directProfitRatio"
                suffix="%"
                class="text-right"
              />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item
            label="단일매체 매출 비율"
            :span="3"
            v-if="mediaGroupType === 'A'"
          >
            <a-form-model-item ref="profitRatio" prop="profitRatio">
              <a-input
                v-model="form.profitRatio"
                suffix="%"
                class="text-right"
              />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item
            label="대행사 매출 비율"
            :span="3"
            v-if="mediaGroupType === 'A'"
          >
            <a-form-model-item ref="agencyProfitRatio" prop="agencyProfitRatio">
              <a-input
                v-model="form.agencyProfitRatio"
                suffix="%"
                class="text-right"
              />
            </a-form-model-item>
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="로그인 정보 설정"
          bordered
          class="mt-7"
          v-if="superAdmin && mediaGroupType === 'A'"
        >
          <a-descriptions-item label="로그인 아이디" :span="3">
            <a-form-model-item ref="uid" prop="uid">
              <a-input v-model="form.uid" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="비밀번호" :span="3">
            <a-form-model-item ref="password" prop="password">
              <a-input
                v-model="form.password"
                type="password"
                :placeholder="
                  type === 'detail' ? '미 입력시 변경되지 않습니다.' : ''
                "
              />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="이메일" :span="3">
            <a-form-model-item ref="email" prop="email">
              <a-input v-model="form.email" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="연락처" :span="3">
            <a-form-model-item ref="phone" prop="phone">
              <a-input v-model="form.phone" />
            </a-form-model-item>
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="SDK 설정" bordered class="mt-7">
          <a-descriptions-item label="매체번호" :span="3">
            <a-form-model-item ref="sdkMediaIdx" prop="sdkMediaIdx">
              <a-input v-model="form.sdkMediaIdx" />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item label="광고 노출 타입" :span="3">
            <a-form-model-item
              ref="campaignImpressionType"
              prop="campaignImpressionType"
            >
              <a-select
                :style="{ width: '100%', width: '100px' }"
                v-model="form.campaignImpressionType"
              >
                <a-select-option :value="0"> 0 </a-select-option>
                <a-select-option :value="1"> 1 </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item :span="3">
            <template v-slot:label>
              광고 노출 차단 시간
              <v-tooltip right>
                <template #activator="{ on: onTooltip }">
                  <v-btn v-on="{ ...onTooltip }" icon>
                    <v-icon small dense>mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <div class="text-subtitle-2">
                  * 지정된 시간에는 광고 노출이 차단 됩니다.
                </div>
              </v-tooltip>
            </template>

            <v-row align="center" class="ma-0">
              <v-menu
                ref="timePicker"
                v-model="campaignImpressionBlockDialog.start"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on">
                    <v-icon class="mr-2">mdi-clock</v-icon>
                    시작 시간 :
                    <span class="px-3">
                      {{ form.campaignImpressionBlockStart || '00:00' }}
                    </span>
                  </v-chip>
                </template>

                <TimePicker
                  v-model="form.campaignImpressionBlockStart"
                  :show="campaignImpressionBlockDialog.start"
                  @close="campaignImpressionBlockDialog.start = false"
                />
              </v-menu>
              <v-col cols="auto"> ~ </v-col>
              <v-menu
                ref="timePicker"
                v-model="campaignImpressionBlockDialog.end"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on">
                    <v-icon class="mr-2">mdi-clock</v-icon>
                    종료 시간 :
                    <span class="px-3">
                      {{ form.campaignImpressionBlockEnd || '00:00' }}
                    </span>
                  </v-chip>
                </template>

                <TimePicker
                  v-model="form.campaignImpressionBlockEnd"
                  :show="campaignImpressionBlockDialog.end"
                  @close="campaignImpressionBlockDialog.end = false"
                />
              </v-menu>
            </v-row>

            <v-row class="ma-0">
              <v-alert
                dense
                text
                outlined
                small
                prominent
                border="left"
                color="info"
                class="ma-1 py-1 pr-2"
              >
                <v-row
                  v-for="(item, index) in campaignImpressionBlockInfo"
                  :key="index"
                  class="caption ma-0"
                >
                  <v-icon small dense color="info" class="mr-1">
                    mdi-information-outline
                  </v-icon>
                  <v-col class="pa-1" style="flex-grow: 1; flex-basis: 0">
                    {{ item.message }}
                  </v-col>
                </v-row>
              </v-alert>
            </v-row>
          </a-descriptions-item>

          <a-descriptions-item label="ADX 광고 비율 (%)" :span="3">
            <a-form-model-item ref="campaignAdxRate" prop="campaignAdxRate">
              <a-input
                v-model="form.campaignAdxRate"
                suffix="%"
                class="text-right"
              />
            </a-form-model-item>
          </a-descriptions-item>

          <a-descriptions-item
            :span="3"
            :label="input.name"
            v-for="(input, index) in inputs"
            :key="index"
          >
            <v-row align="center">
              <v-col cols="8">
                <a-form-model-item :ref="input.value" :prop="input.value">
                  <a-input v-model="form[input.value]" class="text-right" />
                </a-form-model-item>
              </v-col>

              <v-col cols="4">
                <template v-if="input.unit">
                  <a-select
                    :style="{ width: '100%', marginTop: '4px' }"
                    v-model="form[input.unit]"
                  >
                    <a-select-option
                      v-for="(item, index) in UnitOfTimeList"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </a-select-option>
                  </a-select>
                </template>
                <div v-else class="text-right">개</div>
              </v-col>
            </v-row>
          </a-descriptions-item>

          <a-descriptions-item label="광고 정보 버튼 활성여부" :span="3">
            <v-switch
              color="info"
              class="mt-0 ml-3"
              inset
              hide-details
              v-model="form.campaignInfoActivation"
            />
          </a-descriptions-item>

          <a-descriptions-item label="광고 확장 버튼 활성여부" :span="3">
            <v-switch
              color="info"
              class="mt-0 ml-3"
              inset
              hide-details
              v-model="form.campaignArrowActivation"
            />
          </a-descriptions-item>
        </a-descriptions>

        <v-row>
          <v-col cols="12" class="text-center mt-5">
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :loading="loading"
              @click="submit"
            >
              {{ btnText }}
            </v-btn>
          </v-col>
        </v-row>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UnitOfTimeList } from '@/config/common';
import TimePicker from '@/components/common/TimePicker.vue';

export default {
  props: {
    dialog: {
      type: Boolean
    },
    type: {
      type: String
    }
  },
  components: {
    TimePicker
  },
  data() {
    return {
      UnitOfTimeList: UnitOfTimeList,
      inputs: [
        {
          name: '서비스 재시작 간격',
          value: 'serviceRestartInterval',
          unit: 'serviceRestartIntervalType'
        },
        {
          name: '세팅 요청 간격',
          value: 'settingRequestInterval',
          unit: 'settingRequestIntervalType'
        },
        {
          name: '날씨 데이터 요청 간격',
          value: 'weatherRequestInterval',
          unit: 'weatherRequestIntervalType'
        },
        {
          name: '광고 요청 간격',
          value: 'campaignRequestInterval',
          unit: 'campaignRequestIntervalType'
        },
        {
          name: '광고 요청 백그라운드 간격',
          value: 'campaignRequestBackgroundInterval',
          unit: 'campaignRequestBackgroundIntervalType'
        },
        {
          name: '광고 노출 간격',
          value: 'campaignImpressionInterval',
          unit: 'campaignImpressionIntervalType'
        },
        {
          name: '스크린락 해제 개수',
          value: 'campaignImpressionUnlockCount',
          unit: ''
        },
        {
          name: '위치데이터 저장 기간',
          value: 'locationSavePeriod',
          unit: 'locationSavePeriodType'
        },
        {
          name: '위치데이터 저장 백그라운드 기간',
          value: 'locationSaveBackgroundPeriod',
          unit: 'locationSaveBackgroundPeriodType'
        }
      ],
      // input 별 custom Rule
      inputCustomRules: [
        {
          key: 'campaignAdxRate',
          min: 0
        },
        {
          key: 'directProfitRatio',
          min: 0
        }
      ],
      loading: false,
      rules: {},
      urlRegex:
        'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
      mediaGroups: [],
      form: {},
      campaignImpressionBlockInfo: [
        {
          key: '>', // 시작시간 > 종료시간
          message:
            '시작 시간이 종료 시간보다 큰 경우, 종료시간은 익일로 처리 됩니다.'
          // '시작 시간 > 종료 시간 : 종료시간은 익일로 처리 됩니다.'
        },
        {
          key: '=', // 시작시간 = 종료시간
          message:
            '시작 시간과 종료 시간이 같은 경우, 차단 설정이 되지 않습니다.'
          // '시작 시간 = 종료 시간 : 차단 설정이 되지 않습니다.'
        }
      ],
      campaignImpressionBlockDialog: {
        start: false,
        end: false
      }
    };
  },
  computed: {
    ...mapGetters({
      media: 'media/form',
      superAdmin: 'auth/isSuperAdmin'
    }),
    btnText() {
      return this.type === 'store' ? '등록' : '변경';
    },
    mediaGroupType() {
      let type = '';

      this.mediaGroups.forEach(group => {
        if (group.id === this.form.mediaGroupId) {
          type = group.type;
        }
      });

      return type;
    }
  },
  watch: {
    dialog() {
      this.init();
    },
    mediaGroupType(v) {
      let fields = ['uid', 'email', 'phone'];

      if (this.type === 'store') {
        fields.push('password');
      }

      fields.forEach(field => {
        this.rules[field] = [
          {
            required: v === 'A',
            message: '필수값 입니다.'
          }
        ];
      });
    }
  },
  mounted() {
    this.ruleInit();
    this.getMediaGroupsApi();
    this.init();
  },
  methods: {
    ...mapActions({
      closeDialog: 'media/closeDialog'
    }),
    init() {
      this.form = JSON.parse(JSON.stringify(this.media)); // clone deep
      this.$refs.storeForm.resetFields();
    },
    ruleInit() {
      let media = Object.keys(this.media);

      media.forEach(value => {
        let rule = [
          {
            required: true,
            message: '필수값 입니다.'
          }
        ];

        if (value === 'storeUrl') {
          rule.push({
            pattern: this.urlRegex,
            message: '올바른 값을 입력해주세요. 예) https://example.com'
          });
        }

        let numberType = false;

        this.inputs.forEach(input => {
          if (input.value === value) {
            numberType = true;
          }
        });

        if (['sdkMediaIdx', 'campaignAdxRate'].includes(value)) {
          numberType = true;
        }

        if (numberType) {
          // 0 입력 가능한 key
          const opionalNumberKeys = this.inputCustomRules.map(item => item.key);
          let min_value = 1;

          if (opionalNumberKeys.includes(value)) {
            min_value =
              this.inputCustomRules.find(item => item.key === value)?.min ?? 0;
          }

          rule.push({
            validator: (rule, value, callback) => {
              if (!Number.isInteger(value * 1)) {
                callback(new Error('숫자만 입력해주세요.'));
              } else if (!(value * 1 >= min_value)) {
                callback(new Error('1보다 큰 숫자만 입력해주세요.'));
              }
              callback();
            }
          });
        }
        this.rules[value] = rule;
      });
    },
    getMediaGroupsApi() {
      return this.$axios
        .get('/admin/report-media-groups')
        .then(res => {
          // console.log(res);
          const { mediaGroups } = res.data;
          this.mediaGroups = mediaGroups.map(group => {
            group.name = `${group.name} - ${
              group.type === 'A' ? '대행사' : '직매체'
            }`;
            return group;
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    submit() {
      this.$refs.storeForm.validate(valid => {
        if (valid) {
          this.form.mediaGroupType = this.mediaGroupType;

          if (this.mediaGroupType === 'A') {
            let user = {
              uid: this.form.uid,
              phone: this.form.phone,
              email: this.form.email,
              password: this.form.password
            };

            this.form.user = user;
          }

          if (this.type === 'store') {
            this.createMediaApi();
          } else {
            this.updateMediaApi();
          }
        } else {
          return false;
        }
      });
    },
    createMediaApi() {
      this.loading = true;
      return this.$axios
        .post('/admin/media', this.form)
        .then(() => {
          this.closeDialog(this.type);
          this.$emit('refresh');
        })
        .catch(err => {
          this.$axiosErrorAlert();
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
    updateMediaApi() {
      this.loading = true;
      return this.$axios
        .put('/admin/media', this.form)
        .then(() => {
          this.closeDialog(this.type);
          this.$emit('refresh');
        })
        .catch(err => {
          this.$axiosErrorAlert();
          console.error(err);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style>
.ant-form-item {
  margin-bottom: 0 !important;
}
</style>
