<template>
  <v-row class="ma-0">
    <v-col cols="12">
      <v-row align="center" justify="end" class="ma-0 mb-3">
        <v-btn
          outlined
          @click="handleInitClick"
          :disabled="isLoading || !isSetData"
        >
          초기화
        </v-btn>
        <v-btn
          class="ml-2"
          color="secondary"
          :disabled="!validCalculator"
          @click="handleCalculateClick"
          :loading="isLoading"
        >
          계산하기
        </v-btn>
      </v-row>
    </v-col>
    <template v-if="calculationResult.length > 0">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <a-table
          class="mt-3 table-striped-rows"
          :columns="columns"
          :data-source="calculationResult"
          :loading="isLoading"
          size="small"
          :scroll="{ x: true }"
          :locale="{
            emptyText: '데이터가 없습니다.'
          }"
          bordered
          :rowKey="
            (record, index) => {
              return `${record.id}`;
            }
          "
          :rowClassName="
            (record, index) => {
              if (index === 0) {
                return 'table-summary-rows';
              }
            }
          "
          :pagination="false"
        >
          <template slot="localeString" slot-scope="text">
            {{ text.toLocaleString() }}
          </template>
        </a-table>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { getTargetAppsCalculateAPI } from '@/api/admin/TargetAPI';
export default {
  props: {
    mediaIds: {
      type: Array,
      default() {
        return [];
      }
    },
    appPackages: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    validCalculator() {
      return this.mediaIds.length > 0 && this.appPackages.length > 0;
    },
    isSetData() {
      return this.mediaIds.length > 0 || this.appPackages.length > 0;
    },
    isLoading() {
      return this.loading || this.calculationLoading;
    }
  },
  data() {
    return {
      calculationLoading: false,
      calculationResult: [],
      columns: [
        {
          title: '매체',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          align: 'center'
        },
        {
          title: '이미지 모수',
          dataIndex: 'image_reach_count',
          key: 'image_reach_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '텍스트 모수',
          dataIndex: 'text_reach_count',
          key: 'text_reach_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '푸시메시지 모수',
          dataIndex: 'message_reach_count',
          key: 'message_reach_count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        }
        // {
        //   title: '전체 모수',
        //   dataIndex: 'total_reach_count',
        //   key: 'total_reach_count',
        //   align: 'right',
        //   scopedSlots: {
        //     customRender: 'localeString'
        //   }
        // }
      ]
    };
  },
  methods: {
    // 계산하기 버튼 클릭
    async handleCalculateClick() {
      this.calculationResult = [];
      const params = {
        params: {
          packages: this.appPackages,
          mediaIds: this.mediaIds
        }
      };
      this.calculationLoading = true;

      const [error, data] = await getTargetAppsCalculateAPI(params);

      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const { stats } = data;
        const summary = {
          name: '전체',
          image_reach_count: stats.reduce((acc, cur) => {
            return acc + cur.image_reach_count;
          }, 0),
          text_reach_count: stats.reduce((acc, cur) => {
            return acc + cur.text_reach_count;
          }, 0),
          message_reach_count: stats.reduce((acc, cur) => {
            return acc + cur.message_reach_count;
          }, 0)
          // total_reach_count: stats.reduce((acc, cur) => {
          //   return acc + cur.total_reach_count;
          // }, 0)
        };
        this.calculationResult = [summary, ...stats];
      }
      this.calculationLoading = false;
    },
    // 선택 값 초기화 이벤트
    handleInitClick() {
      this.calculationResult = [];
      this.$emit('init');
    }
  }
};
</script>

<style></style>
