<template>
  <v-combobox
    v-model="keyword"
    :search-input.sync="searchString"
    class="mx-3"
    outlined
    hide-details
    rounded
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    :loading="loading"
    :items="keywords"
    clearable
    :menu-props="{}"
    @input="emitKeyword"
    placeholder="캠페인 번호, 캠페인 명, #수집태그로 검색하세요."
  >
    <template v-slot:progress>
      <v-progress-linear absolute indeterminate></v-progress-linear>
    </template>
  </v-combobox>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      searchString: null,
      keywords: [],
      keyword: null,
      awaitingSearch: false
    };
  },
  methods: {
    async emitKeyword() {
      await this.$emit('setSearchString', this.keyword);
      this.keywords = [];
    },
    search() {
      if (!this.awaitingSearch) {
        setTimeout(async () => {
          const { keywords } = await this.getKeywords();
          this.keywords = keywords;
          this.awaitingSearch = false;
        }, 100); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    async getKeywords() {
      //공백제거
      if (!this.searchString) {
        return false;
      }
      const searchString = this.searchString.replace(/\s{2,}/g, ' ').trim();
      if (searchString.length === 0) {
        return false;
      }
      this.loading = true;
      try {
        const params = {
          ss: searchString
        };
        const { data } = await this.$axios.get(`/admin/campaigns-keywords`, {
          params: params
        });
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
