<template>
  <v-card scrollable width="400px" height="100%">
    <v-card-title>
      테스크 목록
      <v-spacer />
      <v-btn icon @click="init" :disabled="loading">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      class="pa-0"
      style="overflow: auto; max-height: calc(100% - 68px)"
    >
      <v-card
        v-for="(task, index) in taskList"
        class="card_list"
        style="box-shadow: none"
        :key="index"
        v-intersect="
          (entries, observer) => {
            taskList.length === index + 1
              ? onIntersect(entries, observer)
              : false;
          }
        "
      >
        <v-divider />

        <div :class="`row ma-0 align-center`">
          <v-icon class="d-inline-block col-auto pa-3">
            {{ task.typeIcon }}
          </v-icon>
          <div class="d-inline-block col pl-0">
            <v-chip small label :color="`${task.color}`">
              <div style="font-weight: bold; color: #fff">
                {{ task.typeText }}
              </div>
            </v-chip>
            <v-chip small label outlined color="grey darken-1" class="ml-2">
              <div style="font-weight: bold">번호 : {{ task.typeId }}</div>
            </v-chip>
            <div
              class="col py-1 px-0"
              style="font-weight: 600; color: secondary"
            >
              {{ task.typeDescription }}
            </div>
            <div class="text-caption pl-0">{{ task.createdAt }}</div>
          </div>
          <div class="row ma-0 float-right col-auto">
            <v-btn
              color="secondary"
              darks
              small
              outlined
              @click="handleMoveLink(task)"
            >
              이동
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-row
        v-if="loading"
        class="fill-height ma-0 mt-3"
        align="center"
        justify="center"
        style="height: 100px"
      >
        <v-progress-circular indeterminate color="grey" />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Pagination } from '@/model/Pagination';
import { getUnsignedTaskApi, assignTaskApi } from '@/api/admin/TaskAPI';
import { AdminTask } from '@/model/admin/AdminTask';

export default {
  data() {
    return {
      pagination: new Pagination(),
      taskList: [],
      loading: false
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 초기화
     * */
    async init() {
      this.taskList = [];
      await this.fetchUnsignedTasks();
    },
    /**
     * @description: 태스크 목록 조회
     * */
    async fetchUnsignedTasks(page = 1) {
      this.loading = true;

      const [error, data] = await getUnsignedTaskApi({
        params: { page: page }
      });

      if (error) {
        console.error(error);
      } else {
        const { tasks, current_page, has_more_pages } = data;

        const taskList = tasks.map((item, index) => {
          return new AdminTask({
            ...item,
            key: index
          });
        });

        this.taskList.push(...taskList);

        this.pagination.page = current_page;
        this.pagination.hasMorePage = has_more_pages;
      }

      this.loading = false;
    },
    /**
     * @description: task 할당 처리
     * */
    async updateAssignTask(taskId) {
      const [error] = await assignTaskApi(taskId);
      if (error) {
        console.error(error);
      }
    },
    /**
     * @description: 태스크 할당및 페이지 이동 처리
     * */
    async handleMoveLink(task) {
      await this.updateAssignTask(task.id);
      // 태스크가 한개인경우 목록 재조회
      if (this.taskList.length === 1) {
        this.$emit('refresh');
      }
      this.$emit('close');
      // 현재페이지와 동일한 경우 미처리
      if (this.$router.currentRoute.path !== task.link) {
        this.$router.push(task.link);
      }
    },
    /**
     * @description: infinity scroll 처리
     * */
    onIntersect(entries) {
      const isIntersecting = entries[0].isIntersecting;
      if (isIntersecting && this.pagination.hasMorePage) {
        this.fetchUnsignedTasks(this.pagination.nextPage);
      }
    }
  }
};
</script>

<style></style>
