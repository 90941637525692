<template>
  <v-card sm="12" lg="6" flat>
    <v-card-title>광고 미리보기</v-card-title>
    <v-card-subtitle>
      광고는 모바일 기기에 따라 다르게 보여질 수 있습니다.
    </v-card-subtitle>
    <v-card-text>
      <div v-if="showLandingUrl">
        <h4>광고 URL</h4>
        <v-text-field
          id="landingUrl"
          :value="campaign.landingUrl"
          filled
          required
          outlined
          readonly
        >
          <template v-slot:append-outer>
            <v-tooltip v-if="campaign.landingUrl" bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  icon
                  @click="openInNewTab(campaign.landingUrl)"
                >
                  <v-icon v-on="onTooltip">mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>새탭에서 링크 열기</span>
            </v-tooltip>
            <v-tooltip v-if="campaign.landingUrl" bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn v-on="{ ...onTooltip }" icon @click="copyToClipboard">
                  <v-icon>mdi-content-cut</v-icon>
                </v-btn>
              </template>
              <span>링크 복사하기</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </div>

      <div class="layout-mobile mx-auto">
        <transition name="component-fade" mode="in-out">
          <div v-if="campaign.isTypeImage && campaign.rollingImageUrl">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <v-img :src="previewHead"></v-img>
                <div class="box-img">
                  <v-img class="img" :src="campaign.rollingImageUrl"></v-img>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3"
              >노티 미리보기
            </v-card-subtitle>
          </div>
        </transition>
        <transition name="component-fade" mode="in-out">
          <div v-if="allowCampaignTypes.includes(campaign.type)">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <v-img :src="previewHead"></v-img>
                <div class="box-img">
                  <div class="pa-3" style="font-size: 14px">
                    <h3>
                      {{
                        campaign.pushTitle ? campaign.pushTitle : '노티 제목'
                      }}
                    </h3>
                    <h4>
                      (광고)
                      {{
                        campaign.pushSubTitle
                          ? campaign.pushSubTitle
                          : '노티 내용'
                      }}
                    </h4>
                    <v-img
                      v-if="campaign.messageImageUrl"
                      class="mt-2"
                      :src="campaign.messageImageUrl"
                    ></v-img>
                  </div>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3"
              >노티 미리보기
            </v-card-subtitle>
          </div>
        </transition>
        <transition name="component-fade" mode="in-out">
          <div v-if="allowCampaignTypes.includes(campaign.type)">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <div class="title">
                  &lt; &nbsp;
                  {{
                    campaign.pushCategory
                      ? campaign.pushCategory
                      : '인앱 광고 노출 이름'
                  }}
                </div>
                <div class="content">
                  <div class="inner">
                    <div class="box-date">
                      <div class="line"></div>
                      <div class="date">
                        {{ $moment().format('LL') }} ({{
                          dayOfWeek[$moment().day()]
                        }})
                      </div>
                    </div>
                    <!-- 채팅 한 묶음 -->
                    <dl class="wrap-chat">
                      <dt class="box-user d-inline-flex align-center">
                        <v-img
                          :src="iconUrl"
                          class="rounded-lg"
                          width="36"
                          height="36"
                        ></v-img>
                        <p class="name mb-0 ml-2" style="font-size: 14px">
                          {{
                            campaign.pushTitle
                              ? campaign.pushTitle
                              : '노티 제목'
                          }}
                        </p>
                      </dt>
                      <dd class="box-txt">
                        <div class="txt-row pa-1">
                          <div class="txt">
                            <v-textarea
                              style="font-size: 14px"
                              :value="
                                campaign.pushText
                                  ? campaign.pushText
                                  : '인앱 광고 메시지'
                              "
                              readonly
                              auto-grow
                              rows="1"
                              row-height="10"
                              solo
                              flat
                              hide-details
                            >
                            </v-textarea>
                          </div>
                          <v-img
                            v-if="campaign.pushImageUrl"
                            :src="campaign.pushImageUrl"
                          ></v-img>
                          <div class="time">
                            {{ $moment().format('LT') }}
                          </div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3">
              인앱광고 미리보기
            </v-card-subtitle>
          </div>
        </transition>
        <transition name="component-fade" mode="in-out">
          <div v-if="campaign.isTypeCoupon">
            <div class="box-android">
              <span></span>
              <div class="area-content">
                <div class="title row ma-0 pa-0">
                  <v-icon class="col-auto" style="visibility: hidden">
                    mdi-close
                  </v-icon>
                  <div class="col text-center">쿠폰받기</div>
                  <v-icon class="col-auto">mdi-close</v-icon>
                </div>
                <div class="content">
                  <div class="inner pb-3">
                    <div class="box-date"></div>
                    <!-- 쿠폰 한 묶음 -->
                    <dl class="wrap-coupon row ma-0 elevation-3">
                      <dt
                        class="box-user d-inline-flex align-center row col ma-0"
                        style="background: white; max-width: calc(100% - 52px)"
                      >
                        <div class="col-auto pa-0">
                          <v-img
                            v-if="
                              !!campaign.coupounShopInfo &&
                              campaign.couponShopImageUrl
                            "
                            :src="
                              campaign.couponShopImageUrl
                                ? campaign.couponShopImageUrl
                                : ''
                            "
                            width="60"
                            height="60"
                          />
                          <v-skeleton-loader
                            v-else
                            type="image"
                            width="60"
                            height="60"
                          ></v-skeleton-loader>
                        </div>

                        <div class="col pa-0" style="width: calc(100% - 60px)">
                          <div class="name mb-0 ml-2" style="font-size: 12px">
                            {{
                              campaign.couponShopName
                                ? campaign.couponShopName
                                : '지점 이름'
                            }}
                            &gt;
                            <div
                              class="txt font-weight-bold"
                              style="font-size: 14px"
                            >
                              {{
                                campaign.couponDescriptionPreview ||
                                '쿠폰 할인 메시지'
                              }}
                            </div>
                            <div class="grey--text">
                              {{ stdDate }} ~ {{ endDate }}
                            </div>
                          </div>
                        </div>
                      </dt>

                      <dt
                        class="col-auto ma-0 pa-1 row align-center"
                        style="background-color: #14b9cb"
                      >
                        <div class="white--text text-caption">쿠폰받기</div>
                      </dt>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <v-card-subtitle class="text-center mt-3">
              인앱광고 미리보기
            </v-card-subtitle>
          </div>
        </transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    showLandingUrl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dayOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
      allowCampaignTypes: ['push', 'message'],
      previewHead: require('@/assets/campaign/preview-head.jpeg')
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign'
    }),
    iconUrl() {
      return (
        this.campaign?.advertiserGroup?.icon?.url ||
        'https://notii.azureedge.net/campaign/images/push_default_icon.png'
      );
    },
    stdDate() {
      return this.campaign.displayPeriodStart
        ? this.$moment(this.campaign.displayPeriodStart, 'YYYY-MM-DD').format(
            'YY.MM.DD'
          )
        : '00.00.00';
    },
    endDate() {
      return this.campaign.couponExpiresAt
        ? this.$moment(this.campaign.couponExpiresAt, 'YYYY-MM-DD').format(
            'YY.MM.DD'
          )
        : '00.00.00';
    }
  },
  methods: {
    copyToClipboard() {
      const copy = document.querySelector('#landingUrl');
      copy.setAttribute('type', 'text');
      copy.select();
      try {
        document.execCommand('copy');
        this.$Swal.fire({
          icon: 'success',
          title: '복사 완료.',
          text: '' + copy.value,
          showConfirmButton: false,
          timer: 1200
        });
      } catch (err) {
        this.$Swal.fire({
          icon: 'error',
          title: '복사 오류',
          showConfirmButton: false,
          timer: 1000
        });
      }
      /* unselect the range */
      window.getSelection().removeAllRanges();
    },
    openInNewTab(landingUrl) {
      window.open(landingUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.7s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.layout-mobile {
  width: 50%;
  min-width: 350px;
  max-width: 90%;
  margin: auto;
}
.layout-mobile .box-android {
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-radius: 50px 50px 0 0;
  box-shadow: 1px 1px 5px 2px #efefef;
  text-align: center;
  max-width: 500px;
}
.layout-mobile .box-android span {
  display: inline-block;
  width: 120px;
  height: 10px;
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
}
.layout-mobile .box-android .area-content {
  min-height: 60px;
  margin: 10px 15px 0;

  outline: 3px solid #666;
  text-align: left;
}
.layout-mobile .box-android .area-content .box-img {
  padding: 3px;
}
.layout-mobile .box-android .area-content .box-img .img {
  width: 100%;
  max-height: 75px;
}
.layout-mobile .box-android .area-content > img {
  width: 100%;
}
.layout-mobile .box-ios {
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-radius: 50px 50px 0 0;
  box-shadow: 1px 1px 5px 2px #efefef;
  text-align: center;
}

/* 채팅창 레이아웃 미리보기 */
.area-content .title {
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  color: #333;
  font-size: 16px !important;
}
.area-content .content {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}
.area-content .inner {
  width: 90%;
  margin: auto;
}
.area-content .box-date {
  position: relative;
  padding: 20px 0;
}
.area-content .box-date .line {
  width: 100%;
  height: 2px;
  background-color: #ccc;
}
.area-content .box-date .date {
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  padding: 0 10px;
  background-color: #efefef;
  white-space: nowrap;
}
q .area-content .wrap-chat {
  display: flex;
  padding-bottom: 10px;
}
.area-content .wrap-chat .box-user .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #666;
}
.area-content .wrap-chat .box-txt {
  margin-left: 5px;
  width: 100%;
}
.area-content .wrap-chat .box-txt .name {
  margin-bottom: 5px;
}
.area-content .wrap-chat .box-txt .txt {
  width: 100%;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.5;
}
.area-content .wrap-chat .box-txt .time {
  font-size: 10px;
  color: #999;
}
</style>
