import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import {
  CampaignResourceDataTypeList,
  CampaignResourceTypeList,
  CampaignResourceStatusList,
  BusinessClassList
} from '@/config/common';

export class CampaignResource {
  origin = null;
  id = null; // resource id
  name = null; // 별칭
  userId = null; // 광고주 Id
  user = null; // 광고주 정보
  dataType = 'video'; // image, video ...
  campaignType = 'did'; // rolling, push, did
  approverIpAddr = null; // 승인IP
  approvedAt = null; // 승인일시
  denyCode = null; // 거절 코드
  denyReson = null; // 거절 시유
  approveStatus = 'W'; // 상태 W: 대기, Y: 승인, N: 거절
  validDate = null; // 유효기간
  isExpired = false;
  isExtendable = false;
  isExtendable = false;
  thumbnailUrl = null; // 미리보기 이미지

  width = 0;
  height = 0;
  fileName = null;
  filePath = null;
  mime = null;
  fileSize = 0;
  filePlaytime = 0;
  url = null;

  createdAt = null;
  file = null;

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new CampaignResource(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }

      this[key] = val;
    }
    // 만료일이 없는경우에는 연장가능여부, 삭제여부는 false
    if (!this.validDate) {
      this.isExpired = false;
      this.isExtendable = false;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new CampaignResource(this);
    }
    // 이름 없는경우 파일이름으로 표시
    if (!this.name) {
      this.name = this.fileName;
    }
  }

  /** @description: 데이터 타입 이미지 여부*/
  get isDataTypeImage() {
    return this.dataType === 'image';
  }
  /** @description: 데이터 타입 아이콘 여부*/
  get isDataTypeIcon() {
    return this.dataType === 'icon';
  }
  /** @description: 데이터 타입 비디오 여부*/
  get isDataTypeVideo() {
    return this.dataType === 'video';
  }
  /** @description: 광고주 이름 */
  get userName() {
    return this.user ? this.user.name : '';
  }
  /** @description: 회원 유형 */
  get userBusinessClassInfo() {
    const businessClass = this.user ? this.user.businessClass : '';
    return BusinessClassList.find(type => type.value === businessClass);
  }
  get approveStatusInfo() {
    return CampaignResourceStatusList.find(
      type => type.value === this.approveStatus
    );
  }
  get dataTypeInfo() {
    return CampaignResourceDataTypeList.find(
      type => type.value === this.dataType
    );
  }
  get campaignTypeInfo() {
    return CampaignResourceTypeList.find(
      type => type.value === this.campaignType
    );
  }
  /** @description: 승인 날짜 */
  get approvedDate() {
    return this.approvedAt ? moment(this.approvedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 승인 시간 */
  get approvedTime() {
    return this.approvedAt ? moment(this.approvedAt).format('HH:mm:ss') : '-';
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }
  /** *************************
   * 값 변경 여부 확인
   ************************* **/
  isChanged() {
    const changed = this.id
      ? this.geStatusPayload(true)
      : this.getCreatePayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.id
      ? this.origin.geStatusPayload(true)
      : this.origin.getCreatePayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }

  isStatusChanged() {
    const changed = this.geStatusPayload(true);
    let checkTempKeys = Object.keys(changed);

    const origin = this.origin.geStatusPayload(true);

    return checkTempKeys.some(key => {
      if (key !== 'origin') {
        const val = origin[key];

        let isSameData = `${changed[key]}` !== `${val}`;

        if (typeof changed[key] === 'object' || typeof val === 'object') {
          isSameData = JSON.stringify(changed[key]) !== JSON.stringify(val);
        }

        if (isSameData) {
          return isSameData;
        }
      }
    });
  }
  /** *************************
   * 등록 payload
   ************************* **/
  getCreatePayload(isChecked = false) {
    let changedValueKeys = [];

    let res = {
      userId: this.userId, // 광고주 Id
      campaignType: this.campaignType, // rolling, push, did
      dataType: this.dataType,
      file: this.file,
      name: this.name ?? ''
    };

    if (isChecked) return res;

    // 값 체크 용도
    changedValueKeys = Object.keys(res);
    const changedValues = this.changedValues(changedValueKeys, res);

    return {
      ...res,
      changedValues: changedValues
    };
  }

  /** *************************
   * 상태 변경 payload
   ************************* **/
  geStatusPayload(isChecked = false) {
    let changedValueKeys = [];

    let res = {
      approveStatus: this.approveStatus,
      denyCode: this.denyCode,
      denyReson: this.denyReson
    };

    if (isChecked) return res;

    // 값 체크 용도
    changedValueKeys = Object.keys(res);
    const changedValues = this.changedValues(changedValueKeys, res);

    return {
      ...res,
      changedValues: changedValues
    };
  }

  changedValues(checkKeys = [], changedObj = this) {
    let checkTempKeys =
      checkKeys.length > 0 ? checkKeys : Object.keys(changedObj);
    let changes = [];
    const origin = this.id
      ? this.origin.geStatusPayload(true)
      : this.origin.getCreatePayload(true);

    for (const key of checkTempKeys) {
      if (key === 'origin') {
        continue;
      }

      const val = origin[key];
      let isSameData = `${changedObj[key]}` === `${val}`;

      if (typeof changedObj[key] === 'object' || typeof val === 'object') {
        isSameData = JSON.stringify(changedObj[key]) == JSON.stringify(val);
      }

      if (!isSameData) {
        changes.push({
          key: key,
          before: val,
          after: changedObj[key]
        });
      }
    }
    return changes;
  }
}
