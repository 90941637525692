var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"width":"900","scrollable":"","persistent":"","eager":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v(" 충전하기 ( "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("직접입금")]),_vm._v(" ) ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.handleCloseClick()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('h2',[_vm._v("노티아이 전용 입금계좌")]),_c('v-card',{staticClass:"mb-5 pa-3",attrs:{"flat":"","outlined":""}},[_c('ul',[_c('li',{staticClass:"title"},[_c('div',{staticStyle:{"display":"inline-block","width":"150px"}},[_vm._v("예금주")]),_vm._v(" "+_vm._s(_vm.bankInfo.account)+" ")]),_c('li',{staticClass:"title"},[_c('div',{staticStyle:{"display":"inline-block","width":"150px"}},[_vm._v("은행")]),_vm._v(" "+_vm._s(_vm.bankInfo.name)+" ")]),_c('li',{staticClass:"title"},[_c('div',{staticStyle:{"display":"inline-block","width":"150px"}},[_vm._v("계좌번호")]),_vm._v(" "+_vm._s(_vm.bankInfo.accountNumber)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.handleCopyClick}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("복사하기")])])],1)])]),_c('h2',[_vm._v("입금 금액 및 세금계산서 발행 정보")]),_c('h4',{staticClass:"red--text mt-2 font size12"},[_vm._v(" ※ 입금시 입금자명과 입력한 입금자명이 동일해야 입금처리가 됩니다. ")]),_c('ValidationObserver',{ref:"bankTransferRef"},[_c('v-card',{staticClass:"mb-5 pa-3",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  min_value_krw: 30000,
                  max_value_krw: _vm.maxPrice,
                  max_krw: 10
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"충전 금액","outlined":"","hide-details":!errors.length,"error-messages":errors,"suffix":"원","clearable":"","placeholder":"충전 금액을 입력하세요.","persistent-placeholder":""},on:{"change":_vm.handleAmountChange,"click:clear":function($event){return _vm.clearAmount()}},model:{value:(_vm.formattedAmount),callback:function ($$v) {_vm.formattedAmount=$$v},expression:"formattedAmount"}}),_c('h4',{staticClass:"red--text"},[_vm._v(" "+_vm._s(errors.length > 0 ? errors[0] : '※충전 최소 충전액은 30,000원입니다.')+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"부가세 금액","value":_vm.taxAmountStr,"outlined":"","readonly":"","persistent-placeholder":"","filled":"","placeholder":"충전액 입력시, 자동입력됩니다.","suffix":"원","hide-details":""}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"입금 금액","value":_vm.advertisingAmountStr,"persistent-placeholder":"","placeholder":"충전액 입력시, 자동입력됩니다.","outlined":"","readonly":"","filled":"","suffix":"원","hide-details":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"입금자명","outlined":"","placeholder":"입금자명을 입력해 주세요","persistent-placeholder":"","error-messages":errors,"hide-details":""},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"taxInvoiceDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.taxInvoiceDate,"transition":"scale-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.taxInvoiceDate=$event},"update:return-value":function($event){_vm.taxInvoiceDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":{
                      required: true
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","error-messages":errors,"hide-details":!errors.length,"label":"세금계산서 발행요청일","persistent-placeholder":"","placeholder":"세금계산서 발행요청 날짜를 선택해주세요."},model:{value:(_vm.taxInvoiceDate),callback:function ($$v) {_vm.taxInvoiceDate=$$v},expression:"taxInvoiceDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.taxInvoiceDateMenu),callback:function ($$v) {_vm.taxInvoiceDateMenu=$$v},expression:"taxInvoiceDateMenu"}},[(_vm.taxInvoiceDateMenu)?_c('v-date-picker',{attrs:{"min":_vm.today,"max":_vm.allowMaxDate,"color":"secondary","locale":"ko-KR"},model:{value:(_vm.taxInvoiceDate),callback:function ($$v) {_vm.taxInvoiceDate=$$v},expression:"taxInvoiceDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.taxInvoiceDateMenu = false}}},[_vm._v(" 취소 ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.taxInvoiceDateMenu.save(_vm.taxInvoiceDate)}}},[_vm._v(" 적용 ")])],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"large":"","outlined":""},on:{"click":function($event){return _vm.handleCloseClick()}}},[_vm._v(" 취소 ")]),_c('v-btn',{staticClass:"white--text px-3",attrs:{"color":"#00d1b2","large":"","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 충전 요청 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }