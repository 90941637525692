<template>
  <v-dialog
    v-model="dialog"
    :confirm-loading="loading"
    @cancel="close"
    :width="650"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>비밀번호 변경</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 filters">
        <a-alert
          message="비밀번호는 영문/숫자/기호 혼합 8자리 이상 입력해주세요."
          type="info"
          show-icon
          class="mb-5"
        />
        <ValidationObserver :ref="refKey">
          <v-row dense>
            <CustomGridItem title="비밀번호 변경" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    min: 8,
                    max: 16
                  }"
                >
                  <v-text-field
                    v-model="form.password"
                    placeholder="영문/숫자/기호 혼합 8자리 이상 입력"
                    outlined
                    color="green"
                    autocomplete="new-password"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    dense
                    type="password"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="비밀번호 변경 확인" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: isPasswordConfirmRequired,
                    confirmed: form.password
                  }"
                >
                  <v-text-field
                    v-model="form.password_confirmation"
                    placeholder="비밀번호를 다시 한번 입력해주세요."
                    outlined
                    color="green"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    dense
                    type="password"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="#00d1b2"
                class="white--text"
                depressed
                @click="handleSaveBtnClick"
              >
                변경
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CustomGridItem
  },
  props: {
    dialog: {
      type: Boolean
    },
    originPassword: {
      type: String,
      require: true,
      default: ''
    }
  },
  computed: {
    ...mapGetters({}),
    isPasswordConfirmRequired() {
      const password = this.form?.password || '';
      return password.length > 0;
    }
  },
  watch: {
    dialog() {
      this.loading = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  data() {
    return {
      loading: false,
      refKey: 'updatePasswordForm',
      form: {
        password: '',
        password_confirmation: ''
      }
    };
  },
  methods: {
    async handleSaveBtnClick() {
      const valid = await this.$refs[this.refKey].validate();

      if (!valid) return;

      if (this.form.password === this.originPassword) {
        this.$notification['error']({
          message: '입력 항목 확인',
          description: '이전 비밀번호와 동일합니다.'
        });
        return;
      }

      this.loading = true;

      const params = {
        password: this.form.password,
        password_confirmation: this.form.password_confirmation
      };
      console.log(params);
      this.$emit('changePassword', params);

      // this.close();
    },
    close() {
      this.loading = false;
      this.$emit('close');
    }
  }
};
</script>
