export class MediaTag {
    constructor(
        key,
        tagName,
        tagType,
        totalCount,
        latestCount
    ) {
        this.key = key;
        this.tagName = tagName;
        this.tagType = tagType;
        this.totalCount = totalCount;
        this.latestCount = latestCount;
    }
}