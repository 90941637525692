import moment from 'moment';

export default {
  namespaced: true,
  state: {
    dialog: {
      store: false,
      detail: false,
    },
    record: {},
    form: {},
    filters: {
      email: '',
      name: '',
      type: '',
      active: '',
      phone: '',
      dates: [moment().subtract(2, 'years'), moment()],
    },
  },
  getters: {
    dialog(state) {
      return state.dialog;
    },
    filters(state) {
      return state.filters;
    },
    record(state) {
      return state.record;
    },
    form(state) {
      return state.form;
    },
  },
  mutations: {
    SET_Dialog(state, {
      dialog: dialog,
      type: type
    }) {
      state.dialog[type] = dialog;
    },
    SET_Record(state, record) {
      state.record = record;
    },
    SET_Form(state, form) {
      state.form = form;
    },
  },
  actions: {
    initForm({
      commit
    }) {
      const form = {
        approve: 'Y',
        active: 'Y',
        type: 'D',
        name: null,
        uid: '',
        password: null,
        phone: null,
        email: null,
      };
      commit('SET_Form', form);
    },
    openDialog({
      commit,
      dispatch
    }, {
      record: record,
      type: type
    }) {
      if (type === 'detail') {
        commit('SET_Record', record);
      }

      if (type === 'store') {
        dispatch('initForm');
      }

      commit('SET_Dialog', {
        dialog: true,
        type: type
      });
    },
    closeDialog({
      commit
    }, type) {
      commit('SET_Dialog', {
        dialog: false,
        type: type
      });
    },
  },
};