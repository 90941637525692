import MediaHome from "../../views/media/pages/home/MediaHome.vue";
import MediaReport from "../../views/media/pages/report/MediaReport.vue"
import MediaNotice from "../../views/media/pages/notice/MediaNotice.vue"
import MediaInquiry from "../../views/media/pages/inquiry/MediaInquiry.vue"
import MediaLive from "../../views/media/pages/live/MediaLive.vue"


const mediaReport = [{
    path: '/media/report',
    name: 'MediaReport',
    components: {
        auth: MediaReport
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [1, 5],
    }
}];

const mediaNotice = [{
    path: '/media/notice',
    name: 'MediaNotice',
    components: {
        auth: MediaNotice
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [1, 5],
    }
}];

const mediaInquiry = [{
    path: '/media/inquiry',
    name: 'MediaInquiry',
    components: {
        auth: MediaInquiry
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [1, 5],
    }
}];

const mediaLive = [{
    path: '/media/live',
    name: 'MediaLive',
    components: {
        auth: MediaLive
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [1, 5],
    }
}];

const mediaRoutes = [{
        path: '/media',
        name: 'MediaHome',
        components: {
            auth: MediaHome
        },
        meta: {
            requiresAuth: true,
            requiresAuthLevel: [1, 5],
        },
    },
    ...mediaReport,
    ...mediaNotice,
    ...mediaInquiry,
    ...mediaLive
];

export default mediaRoutes;