import axios from 'axios';

export async function getTagsApi(params) {
    try {
        const {
            data
        } = await axios.get(`/admin/tags`, params);
        return [null, data]
    } catch (error) {
        return [error]
    }
}