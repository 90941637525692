var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"depressed":"","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v(" 매체 등록 ")])],1)])])],1),_c('v-row',[_c('v-col',[_c('a-card',[_c('DidBranchFilters',{attrs:{"filters":_vm.filters,"loading":_vm.indexLoading},on:{"search":_vm.fetchDidBranches}}),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.indexLoading,"size":"small","rowKey":"id","columns":_vm.columns,"bordered":"","data-source":_vm.didBranches,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleRowClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"no",fn:function(value, record, index){return [_vm._v(" "+_vm._s(_vm.pagination.total - (_vm.pagination.perPage * _vm.pagination.page - _vm.pagination.perPage) - index)+" ")]}},{key:"regionDepth1Id",fn:function(value){return [_vm._v(" "+_vm._s(_vm.getRegionText(_vm.regionDepth1Id, value))+" ")]}},{key:"regionDepth2Id",fn:function(value){return [_vm._v(" "+_vm._s(_vm.getRegionText(_vm.regionDepth2Id, value))+" ")]}},{key:"isActive",fn:function(value, record){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","inset":"","readonly":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleActiveSwitch(record)}},model:{value:(record.isActive),callback:function ($$v) {_vm.$set(record, "isActive", $$v)},expression:"record.isActive"}})],1)]}},{key:"selectCount",fn:function(value){return [(value.length)?_c('v-tooltip',{attrs:{"right":"","maxWidth":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('div',_vm._g({staticClass:"text-right"},Object.assign({}, tooltipOn)),[_vm._v(" "+_vm._s(value.length.toLocaleString())+" 대 ")])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},_vm._l((value),function(item,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"label":"","small":"","color":"white"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]):_c('div',{staticClass:"text-right"},[_vm._v("0 대")])]}},{key:"syncAction",fn:function(value, record){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleSyncClick(record)}}},Object.assign({}, tooltipOn)),[_vm._v(" 동기화 ")])]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("매체 재생시간 동기화")])])],1)]}},{key:"actions",fn:function(value, record){return [_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleShowScheduleClick(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("매체 캠페인 스케줄")])])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalCount)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":_vm.pagination.perPage,"color":"secondary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1),(_vm.scheduleDialog)?_c('DidScheduleDialog',{attrs:{"scheduleDialog":_vm.scheduleDialog,"loading":_vm.loading.dialog,"columns":_vm.scheduleColumns,"title":'매체',"name":_vm.selectBranchName,"tableData":_vm.scheduleTableData},on:{"fetchData":function (date) { return _vm.fetchDidSchedules(_vm.selectBranchId, date); },"close":_vm.handleCloseClick}}):_vm._e(),(_vm.dialog)?_c('DidBranchDialog',{on:{"refresh":_vm.fetchDidBranches}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }