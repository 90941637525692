export const PriceList = [
  {
    text: '55,000원',
    value: 55000
  },
  {
    text: '110,000원',
    value: 110000
  },
  {
    text: '330,000원',
    value: 330000
  },
  {
    text: '550,000원',
    value: 550000
  },
  {
    text: '1,100,000원',
    value: 1100000
  }
];

export const BankList = [
  'KB국민은행',
  '신한은행',
  '우리은행',
  '하나은행',
  '케이뱅크',
  '카카오뱅크',
  'KDB산업은행',
  'IBK기업은행',
  '토스뱅크',
  'NH농협은행',
  '수협은행',
  '대구은행',
  '부산은행',
  '경남은행',
  '광주은행',
  '전북은행',
  '제주은행'
];

export const BankInfo = {
  account: '주식회사 티디아이',
  accountNumber: '094-085337-04-062',
  name: '기업은행'
};

export const BusinessInfo = {
  businessNumber: '617-81-95610',
  address: '서울특별시 서초구 반포대로 20길 29 (서초동, TDI 타워)',
  name: '티디아이',
  presidentName: '이승주'
};

export const PaymentMethods = [
  {
    text: '카드결제',
    value: 'creditcard'
  },
  {
    text: '직접입금',
    value: 'banktransfer'
  }
];

export const DirectOrderStatusFlags = [
  {
    text: '결제대기',
    value: 'W',
    color: 'orange'
  },
  {
    text: '충전완료',
    value: 'S',
    color: 'green'
  },
  {
    text: '결제실패',
    value: 'F',
    color: 'red'
  },
  {
    text: '결제취소',
    value: 'C',
    color: 'purple'
  },
  {
    text: '부분취소',
    value: 'PC',
    color: 'pink'
  }
];

export const PGOrderStatusFlags = [
  {
    text: '결제승인대기',
    value: 'W'
  },
  {
    text: '결제완료',
    value: 'S'
  },
  {
    text: '결제실패',
    value: 'F'
  },
  {
    text: '결제취소',
    value: 'C'
  },
  {
    text: '부분취소',
    value: 'PC'
  }
];

export const PointTypeFlags = [
  {
    text: '유상',
    value: 'C'
  },
  {
    text: '무상',
    value: 'F'
  },
  {
    text: '환불',
    value: 'R'
  }
];

export const PointStatusFlags = [
  {
    text: '차감',
    value: '-',
    color: 'red'
  },
  {
    text: '지급',
    value: '+',
    color: 'green'
  }
];

export const PointCodeFlags = [
  {
    text: '보증금',
    value: 'CD000'
  },
  {
    text: '보증금 환불',
    value: 'CDR00'
  },
  {
    text: '광고 요금',
    value: 'CC000'
  },
  {
    text: '환불',
    value: 'R0000'
  },
  {
    text: '카드결제 환불',
    value: 'RPCC0'
  },
  {
    text: '직접입금 환불',
    value: 'RDB00'
  },
  {
    text: '쿠폰 광고 진행 비용 차감',
    value: 'PCC00'
  },
  {
    text: 'DID광고 요금',
    value: 'CDC00'
  },
  {
    text: 'DID광고 요금 환불',
    value: 'CDCR0'
  }
];
