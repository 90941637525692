export default {
    namespaced: true,

    state: {
        rechargeDialog: false,
        creditCardDialog: false,
        bankTransferDialog: false,
        bankInfoDialog: false,
        creditCardBillDialog: false,
        bankTransferBillDialog: false,
        inquiryDialog: false
    },
    getters: {
        rechargeDialog(state) {
            return state.rechargeDialog
        },
        creditCardDialog(state) {
            return state.creditCardDialog
        },
        bankTransferDialog(state) {
            return state.bankTransferDialog
        },
        bankInfoDialog(state) {
            return state.bankInfoDialog
        },
        creditCardBillDialog(state) {
            return state.creditCardBillDialog
        },
        bankTransferBillDialog(state) {
            return state.bankTransferBillDialog
        },
        inquiryDialog(state) {
            return state.inquiryDialog
        }
    },
    mutations: {
        SET_RechargeDialog(state, value) {
            state.rechargeDialog = value
        },
        SET_CreditCardDialog(state, value) {
            state.creditCardDialog = value
        },
        SET_BankTransferDialog(state, value) {
            state.bankTransferDialog = value
        },
        SET_BankInfoDialog(state, value) {
            state.bankInfoDialog = value
        },
        SET_CreditCardBillDialog(state, value) {
            state.creditCardBillDialog = value
        },
        SET_BankTransferBillDialog(state, value) {
            state.bankTransferBillDialog = value
        },
        SET_InquiryDialog(state, value) {
            state.inquiryDialog = value;
        }
    },
    actions: {
        closeRechargeDialog({
            commit
        }) {
            commit('SET_RechargeDialog', false);
        },
        openRechargeDialog({
            commit
        }) {
            commit('SET_RechargeDialog', true)
        },
        closeCreditCardDialog({
            commit
        }) {
            commit('SET_CreditCardDialog', false);
        },
        openCreditCardDialog({
            commit
        }) {
            commit('SET_CreditCardDialog', true)
        },
        closeBankTransferDialog({
            commit
        }) {
            commit('SET_BankTransferDialog', false);
        },
        openBankTransferDialog({
            commit
        }) {
            commit('SET_BankTransferDialog', true)
        },
        closeBankInfoDialog({
            commit
        }) {
            commit('SET_BankInfoDialog', false);
        },
        openBankInfoDialog({
            commit
        }) {
            commit('SET_BankInfoDialog', true)
        },
        openCreditCardBillDialog({
            commit
        }) {
            commit('SET_CreditCardBillDialog', true)
        },
        closeCreditCardBillDialog({
            commit
        }) {
            commit('SET_CreditCardBillDialog', false)
        },
        openBankTransferBillDialog({
            commit
        }) {
            commit('SET_BankTransferBillDialog', true)
        },
        closeBankTransferBillDialog({
            commit
        }) {
            commit('SET_BankTransferBillDialog', false)
        },
        openInquiryDialog({
            commit
        }) {
            commit('SET_InquiryDialog', true)
        },
        closeInquiryDialog({
            commit
        }) {
            commit('SET_InquiryDialog', false)
        }
    }
}