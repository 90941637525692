<template>
  <v-dialog :value="dialog" max-width="900" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>
          CRM {{ titleText }}
          <v-chip
            v-if="statusInfo"
            label
            :color="statusInfo.color"
            class="ml-3"
            style="height: auto"
          >
            {{ statusInfo.text }}
          </v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="" v-if="notification">
        <ValidationObserver ref="notificationsDialogRef">
          <v-row class="ma-0">
            <v-col :cols="12" md="6">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <div class="mx-0 my-3 d-flex justify-center align-center">
                  <div class="col pa-0 pr-3">
                    <v-text-field
                      v-model="notification.title"
                      label="제목"
                      class="mr-3 col"
                      :readonly="!isWait && !!notification.id"
                      hide-details
                      required
                      outlined
                      :clearable="isWait || !notification.id"
                      dense
                    />
                  </div>

                  <v-btn
                    v-if="isWait || !notification.id"
                    color="secondary"
                    class="white--text"
                    depressed
                    @click="notificationListDialog = true"
                  >
                    가져오기
                  </v-btn>
                </div>
                <div v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-textarea
                  v-model="notification.body"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                  :readonly="!isWait && !!notification.id"
                  outlined
                  label="내용"
                  flat
                  class="text-subtitle-2 pa-0"
                  rows="10"
                />
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }">
                <div class="mx-0 my-5 d-flex justify-center align-center">
                  <v-text-field
                    v-model="notification.description"
                    label="설명"
                    placeholder="(선택) 발송 대상 설명, 발송 목적을 입력해 주세요"
                    :readonly="!isWait && !!notification.id"
                    hide-details
                    required
                    outlined
                    :clearable="isWait || !notification.id"
                    dense
                  />
                </div>
                <div v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
              <template v-if="isSended">
                <v-text-field
                  :value="
                    $moment(notification.reserved_at).format('YYYY-MM-DD HH:mm')
                  "
                  prepend-inner-icon="mdi-calendar"
                  class="mt-5"
                  label="예약일시"
                  hide-details
                  readonly
                  outlined
                  dense
                />
                <v-text-field
                  :value="
                    $moment(notification.send_at).format('YYYY-MM-DD HH:mm')
                  "
                  prepend-inner-icon="mdi-calendar"
                  class="mt-5"
                  label="발송일시"
                  hide-details
                  readonly
                  outlined
                  dense
                />
              </template>
              <template v-else>
                <v-checkbox
                  v-model="notification.immediately"
                  @change="handleImmediately"
                  color="primary"
                  hide-details
                  class="my-0"
                  :disabled="(!isWait && !!notification.id) || loading"
                  :label="`즉시 발송`"
                />
                <div v-if="!notification.immediately" class="d-flex">
                  <v-menu
                    v-model="sendedDateDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <v-text-field
                          v-model="reserveDate"
                          prepend-inner-icon="mdi-calendar"
                          class="mt-5 mr-2"
                          label="발송 예약일"
                          :error-messages="errors"
                          :hide-details="!errors.length"
                          :disabled="!isWait && !!notification.id"
                          persistent-hint
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-if="sendedDateDialog && !isSended"
                      v-model="reserveDate"
                      @input="sendedDateDialog = false"
                      :min="today"
                      locale="ko-KR"
                    />
                  </v-menu>

                  <v-menu
                    ref="timePicker"
                    v-model="sendedTimeDialog"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <v-text-field
                          v-model="reserveTime"
                          prepend-inner-icon="mdi-calendar"
                          class="mt-5"
                          label="발송 예약 시간"
                          :error-messages="errors"
                          :hide-details="!errors.length"
                          :disabled="!isWait && !!notification.id"
                          persistent-hint
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        />
                      </ValidationProvider>
                    </template>

                    <TimePicker
                      v-model="reserveTime"
                      :show="sendedTimeDialog && !isSended"
                      :minuteUnit="5"
                      @close="sendedTimeDialog = false"
                    />
                  </v-menu>
                </div>
              </template>
            </v-col>
            <v-col :cols="12" md="6" class="row flex-column">
              <v-col cols="auto" class="pb-0">
                <v-select
                  v-model="notification.target"
                  :items="[
                    {
                      id: '',
                      value: '타겟 전체'
                    },
                    ...targetOptions
                  ]"
                  item-text="value"
                  item-value="id"
                  class="mt-3"
                  :disabled="!isWait && !!notification.id"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  hide-details
                  dense
                  @change="fetchTargetUsers"
                />
              </v-col>
              <v-col cols="auto" class="py-0">
                <v-text-field
                  v-model="searchText"
                  prepend-inner-icon="mdi-magnify"
                  class="mt-5"
                  label="검색"
                  hide-details
                  clearable
                  outlined
                  dense
                />
              </v-col>

              <v-col class="pt-0">
                <v-overlay :value="loading">
                  <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <div class="d-flex justify-center align-center">
                  <v-checkbox
                    v-model="isSelectAll"
                    @click="handleSelectedAll"
                    color="primary"
                    hide-details
                    class="my-0"
                    :disabled="(!isWait && !!notification.id) || loading"
                    :label="`전체 (${users.length})`"
                  />
                  <div class="col text-right">
                    (선택 : {{ notification.users.length }})
                  </div>
                </div>

                <v-list
                  class=""
                  style="
                    height: 40vh;
                    border: 1px solid #999;
                    border-radius: 5px;
                    max-height: 500px;
                    overflow: auto;
                  "
                >
                  <v-list-item
                    v-for="(user, index) in filteredUsers"
                    :key="index"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        :disabled="(!isWait && !!notification.id) || loading"
                        :input-value="checkedUser(user.id)"
                        :value="user.id"
                        @change="v => handleCheckedUser(v, user.id)"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-bold">
                        {{ user.name }}
                        <v-chip
                          v-if="user.typeText"
                          x-small
                          label
                          outlined
                          color="secondary"
                          class="ml-3"
                          style="height: auto"
                        >
                          {{ user.typeText }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center ma-0">
        <v-btn secondary outlined @click="closeDialog" :disabled="loading">
          취소
        </v-btn>
        <v-btn
          v-if="isWait && !isSended"
          color="error"
          @click="handleStatusCancleClick()"
          :loading="loading"
        >
          발송취소
        </v-btn>
        <v-btn
          color="secondary"
          @click="handleSaveClick()"
          :loading="loading"
          v-if="(isWait && !isSended) || !notification.id"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
      <NotificationListDialog
        v-if="notificationListDialog"
        :dialog="notificationListDialog"
        @close="notificationListDialog = false"
        @select="handleSelectedNotification"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { UserRoleList } from '@/config/user';
import NotificationListDialog from './NotificationListDialog.vue';
import TimePicker from '@/components/common/TimePicker.vue';
import {
  createNotificationPushApi,
  updateNotificationPushApi,
  updateNotificationCancleApi,
  getNotificationTargetApi
} from '@/api/admin/NotificationsAPI';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    NotificationListDialog,
    TimePicker
  },
  computed: {
    ...mapGetters({
      notification: 'notificationPushes/notification',
      dialog: 'notificationPushes/dialog',
      targetOptions: 'notificationPushes/targetOptions',
      statusOptions: 'notificationPushes/statusOptions'
    }),
    isEditDialog() {
      return !!this.notification?.id;
    },
    titleText() {
      return this.notification?.id ? '상세' : '등록';
    },
    buttonText() {
      return this.notification?.id ? '저장' : '등록';
    },
    isSended() {
      return !!this.notification.send_at;
    },
    isWait() {
      return this.notification.status === 'W';
    },
    statusInfo() {
      return this.statusOptions.find(
        option => option.value === this.notification.status
      );
    },
    filteredUsers() {
      if (!this.searchText) return this.users;

      return this.users.filter(user => user.name.includes(this.searchText));
    }
  },
  watch: {
    'notification.users'(val) {
      this.isSelectAll = val.length === this.users.length;
    }
  },
  data() {
    return {
      loading: false,
      today: this.$moment().format('YYYY-MM-DD'),
      sendedDateDialog: false,
      sendedTimeDialog: false,
      reserveDate: null,
      reserveTime: null,
      isSelectAll: false,
      users: [],
      notificationListDialog: false,
      searchText: ''
    };
  },
  mounted() {
    if (this.$refs.notificationsDialogRef) {
      this.$refs.notificationsDialogRef.reset();
    }

    this.reserveDate = this.notification.reserved_at
      ? this.$moment(this.notification.reserved_at).format('YYYY-MM-DD')
      : this.today;

    this.reserveTime = this.notification.reserved_at
      ? this.$moment(this.notification.reserved_at).format('HH:mm')
      : '00:00';
    this.notification.immediately =
      this.notification.send_at === this.notification.reserved_at;

    // 회원 목록 조회
    this.fetchTargetUsers();
  },
  methods: {
    ...mapActions({
      initTargetCrm: 'notificationPushes/init',
      closeDialog: 'notificationPushes/closeDialog'
    }),
    async fetchTargetUsers() {
      this.loading = true;
      this.users = [];
      const params = {
        params: {
          targetKey: this.notification.target
        }
      };

      const [error, data] = await getNotificationTargetApi(params);

      if (error) {
        this.loading = false;
        console.error(error);
      } else {
        const { users } = data;
        const tempUser = users.map(user => ({
          id: user.id,
          name: user.name,
          type: user.role,
          typeText: UserRoleList.find(role => role.value === user.role)?.text
        }));
        const sltUser = tempUser.filter(user =>
          this.notification.users.includes(user.id)
        );
        const noSltUser = tempUser.filter(
          user => !this.notification.users.includes(user.id)
        );
        this.users = [...sltUser, ...noSltUser];
      }
      this.loading = false;
      this.isSelectAll = this.users.length === this.notification.users.length;
    },
    async handleSaveClick() {
      const valid = await this.$refs.notificationsDialogRef.validate();
      if (!valid) return;

      this.loading = true;

      if (!this.notification.users.length) {
        this.$Swal.fire({
          html: '<h3>CRM 발송할 대상을 선택해 주세요.</h3>',
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });

        this.loading = false;
        return false;
      }

      const params = {
        title: this.notification.title,
        body: this.notification.body,
        description: this.notification.description,
        users: this.notification.users,
        immediately: this.notification.immediately,
        reservedAt: this.notification.immediately
          ? undefined
          : `${this.reserveDate} ${this.reserveTime}:00 `
      };

      const [error] = await (this.isEditDialog
        ? updateNotificationPushApi(this.notification.id, params)
        : createNotificationPushApi(params));

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        this.$Swal.fire({
          html: `<h3>CRM 발송정보가 ${this.buttonText}되었습니다.</h3>`,
          icon: 'success',
          confirmButtonText: '확인'
        });
        this.closeDialog();
        this.$emit('index');
      }

      this.loading = false;
    },
    // 발송취소
    async handleStatusCancleClick() {
      this.loading = true;

      const [error] = await updateNotificationCancleApi(this.notification.id);

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
        this.loading = false;
      } else {
        this.$Swal.fire({
          html: `<h3>CRM 발송정보가 취소되었습니다.</h3>`,
          icon: 'success',
          confirmButtonText: '확인'
        });
        this.closeDialog();
        this.$emit('index');
      }
      this.loading = false;
    },
    async handleSelectedAll() {
      this.loading = true;

      if (this.isSelectAll) {
        await Promise.all(
          (this.notification.users = this.users.map(user => user.id)),
          Array.from(new Set(this.notification.users))
        );
      } else {
        this.notification.users = [];
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    handleSelectedNotification({ title, body }) {
      this.notification.title = title || '';
      this.notification.body = body || '';
    },
    handleImmediately() {
      if (this.notification.immediately) {
        this.notification.reserved_at = null;
        this.reserveDate = null;
        this.reserveTime = null;
      } else {
        this.reserveDate = this.today;
        this.reserveTime = '00:00';
      }
    },
    // 회원 선택시 체크
    handleCheckedUser(checked, userId) {
      if (checked) {
        this.notification.users.push(userId);
        Array.from(new Set(this.notification.users));
      } else {
        this.notification.users.splice(userId, 1);
      }
    },
    // 체크여부 확인
    checkedUser(userId) {
      return this.notification.users.includes(userId) ? userId : false;
    }
  }
};
</script>

<style></style>
