var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" 일정 ")]),_c('v-divider'),_c('v-card-text',[(_vm.campaign.isTypeMessage)?_c('div',[_c('alert-message',{attrs:{"value":!_vm.isEditableMessageDatetime,"border":"left","icon":"mdi-alert","color":"red","message":"광고 전송 일시가 지났거나 남은 시간이 3시간 미만인 경우 수정이\n        불가합니다."}}),_c('h3',[_vm._v(_vm._s(_vm.titles.messageDateTime))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"messageDateMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"return-value":_vm.messageDate,"transition":"scale-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.messageDate=$event},"update:return-value":function($event){_vm.messageDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":{
                  required: true,
                }}},[_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","persistent-hint":"","readonly":"","clearable":"","outlined":_vm.isEditableMessageDatetime,"filled":!_vm.isEditableMessageDatetime,"disabled":!_vm.isEditableMessageDatetime},model:{value:(_vm.campaign.messageDate),callback:function ($$v) {_vm.$set(_vm.campaign, "messageDate", $$v)},expression:"campaign.messageDate"}},'v-text-field',attrs,false),on))],1)]}}],null,false,453360343),model:{value:(_vm.modal.messageDate),callback:function ($$v) {_vm.$set(_vm.modal, "messageDate", $$v)},expression:"modal.messageDate"}},[(_vm.modal.messageDate)?_c('v-date-picker',{attrs:{"min":_vm.today,"color":"secondary","locale":"ko-KR"},model:{value:(_vm.messageDate),callback:function ($$v) {_vm.messageDate=$$v},expression:"messageDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setMessageDate()}}},[_vm._v(" 적용 ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"messageTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"return-value":_vm.messageTime,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.messageTime=$event},"update:return-value":function($event){_vm.messageTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":{
                  required: true,
                }}},[_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","clearable":"","outlined":_vm.isEditableMessageDatetime,"filled":!_vm.isEditableMessageDatetime,"disabled":!_vm.isEditableMessageDatetime},model:{value:(_vm.campaign.messageTime),callback:function ($$v) {_vm.$set(_vm.campaign, "messageTime", $$v)},expression:"campaign.messageTime"}},'v-text-field',attrs,false),on))],1)]}}],null,false,1835225535),model:{value:(_vm.modal.messageTime),callback:function ($$v) {_vm.$set(_vm.modal, "messageTime", $$v)},expression:"modal.messageTime"}},[(_vm.modal.messageTime)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedMinutes,"format":"24hr","full-width":"","color":"secondary","scrollable":""},model:{value:(_vm.messageTime),callback:function ($$v) {_vm.messageTime=$$v},expression:"messageTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setMessageTime()}}},[_vm._v(" 적용 ")])],1):_vm._e()],1)],1)],1),_c('alert-message',{attrs:{"border":"left","icon":"mdi-information","color":"blue","message":"광고방식이 메시지 타입인 경우 광고 시작일 종료일이 자동 지정 됩니다.","localStorageKey":"displayPeriodAlert","dismissible":""}})],1):_vm._e(),_c('h3',[_vm._v(_vm._s(_vm.titles.displayPeriodStart))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition","bottom":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","persistent-hint":"","readonly":"","clearable":"","outlined":!(_vm.campaign.isTypeMessage || _vm.isDialogTypeEdit),"filled":_vm.campaign.isTypeMessage || _vm.isDialogTypeEdit,"disabled":_vm.campaign.isTypeMessage || _vm.isDialogTypeEdit},model:{value:(_vm.campaign.displayPeriodStart),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodStart", $$v)},expression:"campaign.displayPeriodStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal.displayPeriod[0]),callback:function ($$v) {_vm.$set(_vm.modal.displayPeriod, 0, $$v)},expression:"modal.displayPeriod[0]"}},[_c('v-date-picker',{attrs:{"max":_vm.campaign.displayPeriodEnd,"min":_vm.displayPeriod[0],"color":"green lighten-1","locale":"ko-KR"},on:{"input":function($event){_vm.modal.displayPeriod[0] = false}},model:{value:(_vm.campaign.displayPeriodStart),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodStart", $$v)},expression:"campaign.displayPeriodStart"}})],1)],1)],1),_c('h3',[_vm._v(_vm._s(_vm.titles.displayPeriodEnd))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.campaign.isTypeMessage)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('validation-provider',[_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","persistent-hint":"","readonly":"","clearable":"","outlined":!_vm.campaign.isTypeMessage,"filled":_vm.campaign.isTypeMessage,"disabled":_vm.campaign.isTypeMessage},model:{value:(_vm.campaign.displayPeriodEnd),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodEnd", $$v)},expression:"campaign.displayPeriodEnd"}},'v-text-field',attrs,false),on))],1)]}}],null,false,4236777848),model:{value:(_vm.modal.displayPeriod[1]),callback:function ($$v) {_vm.$set(_vm.modal.displayPeriod, 1, $$v)},expression:"modal.displayPeriod[1]"}},[_c('v-date-picker',{attrs:{"min":_vm.displayPeriod[0],"color":"red lighten-1","locale":"ko-KR"},on:{"input":function($event){_vm.modal.displayPeriod[1] = false}},model:{value:(_vm.campaign.displayPeriodEnd),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodEnd", $$v)},expression:"campaign.displayPeriodEnd"}})],1):_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"d-inline-block",attrs:{"error-messages":errors},model:{value:(_vm.campaign.issetDisplayPeriodEnd),callback:function ($$v) {_vm.$set(_vm.campaign, "issetDisplayPeriodEnd", $$v)},expression:"campaign.issetDisplayPeriodEnd"}},[(!_vm.isTagFilter)?_c('v-radio',{attrs:{"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('h4',[_vm._v(" 설정안함 "),_c('information-hover',{attrs:{"title":"설정안함","subTitle":"광고종료일을 설정안함으로 할 경우, 총 예산이 모두 소진 될때까지 광고가 진행됩니다."}})],1)])]},proxy:true}],null,true)}):_vm._e(),_c('v-radio',{attrs:{"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":60,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":{
                          required: _vm.campaign.issetDisplayPeriodEnd,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"prepend-inner-icon":"mdi-calendar","error-messages":errors,"persistent-hint":"","readonly":"","clearable":"","outlined":!_vm.campaign.isTypeMessage,"filled":_vm.campaign.isTypeMessage,"disabled":_vm.campaign.isTypeMessage},model:{value:(_vm.campaign.displayPeriodEnd),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodEnd", $$v)},expression:"campaign.displayPeriodEnd"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.modal.displayPeriod[1]),callback:function ($$v) {_vm.$set(_vm.modal.displayPeriod, 1, $$v)},expression:"modal.displayPeriod[1]"}},[_c('v-date-picker',{attrs:{"min":_vm.displayPeriod[0],"color":"red lighten-1","locale":"ko-KR"},on:{"input":function($event){_vm.modal.displayPeriod[1] = false}},model:{value:(_vm.campaign.displayPeriodEnd),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodEnd", $$v)},expression:"campaign.displayPeriodEnd"}})],1)]},proxy:true}],null,true)})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }