export default {
  namespaced: true,

  state: {
    menus: [
      {
        text: '광고 관리',
        active: true,
        items: [
          {
            icon: 'mdi-chart-areaspline',
            text: '보고서',
            link: '/partner/report'
          }
        ]
      }
    ],
    bottomMenus: [
      //   {
      //     icon: 'mdi-account-question',
      //     text: '1:1 문의',
      //     name: 'PartnerMember',
      //     params: {
      //       menu: 'inquiry'
      //     }
      //   },
      {
        icon: 'mdi-account',
        text: 'My Page',
        name: 'PartnerMember',
        params: {
          menu: 'mypage'
        }
      }
    ]
  },
  getters: {
    menus(state) {
      return state.menus;
    },
    bottomMenus(state) {
      return state.bottomMenus;
    }
  }
};
