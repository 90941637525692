<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="filters mt-5">
          <a-descriptions bordered>
            <a-descriptions-item label="카테고리">
              <a-select
                v-model="filters.category"
                :style="{ minWidth: '200px' }"
              >
                <a-select-option value=""> 전체 </a-select-option>
                <a-select-option
                  v-for="(item, index) in inquiryCategoryList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item label="상태" :span="isMobile ? 2 : 1">
              <a-select v-model="filters.status" :style="{ minWidth: '200px' }">
                <a-select-option value=""> 전체 </a-select-option>
                <a-select-option value="replied"> 답변 완료 </a-select-option>
                <a-select-option value="wait"> 답변대기 </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item :span="isMobile ? 3 : 1">
              <template slot="label">검색</template>

              <a-input-search
                v-model="filters.searchString"
                @search="getInquiryApi()"
                style="min-width: 500px"
              />
            </a-descriptions-item>
          </a-descriptions>

          <div class="text-center mt-3">
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :loading="loading"
              @click="getInquiryApi()"
            >
              검색
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-show="!loading">
      <v-col cols="12">
        <a-card>
          <a-table
            :columns="columns"
            :data-source="inquiries"
            :loading="loading"
            bordered
            :scroll="{ x: true }"
            scrollToFirstRowOnChange
            size="small"
            :pagination="false"
            class="mb-5 inquiry-table"
            rowKey="id"
            :locale="{
              emptyText: '접수된 문의 내역이 없습니다'
            }"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      openDialog(record);
                    }
                  }
                };
              }
            "
          >
            <span slot="no" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
            <span slot="category" slot-scope="text">
              <v-chip class="ml-2" label small outlined color="secondary">
                {{ text }}
              </v-chip>
            </span>
            <template slot="name" slot-scope="value, record">
              <v-btn
                v-if="record.advertiser_id"
                text
                class="text-body font-weight-regular row align-center ma-0"
                @click.stop="
                  openAdvertiserDialog({
                    record: {
                      advertiserId: record.advertiser_id
                    },
                    type: 'detail'
                  })
                "
              >
                {{ value }}
                <v-icon class="ml-1 mb-1" color="grey darken-3">
                  mdi-application-edit-outline
                </v-icon>
              </v-btn>
              <template v-else> {{ value }}</template>
            </template>
            <span slot="repliedAt" slot-scope="text">
              {{ text ? $moment(text).format('YYYY-MM-DD H:mm:s') : '' }}
            </span>
            <span slot="createdAt" slot-scope="text">
              {{ $moment(text).format('YYYY-MM-DD H:mm:s') }}
            </span>

            <span slot="status" slot-scope="text, record">
              <v-chip
                class="ml-2"
                label
                small
                :color="record.replied_at ? 'success' : 'error'"
                outlined
              >
                답변{{ !!record.replied_at ? '완료' : '대기' }}
              </v-chip>
            </span>
          </a-table>
        </a-card>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="article, actions, article, actions, article, actions"
    ></v-skeleton-loader>
    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getInquiryApi()"
        ></v-pagination>
      </v-col>
    </v-row>
    <InquiryDialog v-if="inquiryDialog" @index="getInquiryApi()" />
    <AdvertiserDetailDialog v-if="advertiserDialog" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import { InquiryCategoryList } from '@/config/support';
import { getInquiryApi } from '@/api/admin/InquiryApi';
import InquiryDialog from './components/InquiryDialog.vue';
import AdvertiserDetailDialog from '@/views/admin/pages/advertiser/components/dialog/AdvertiserDetailDialog.vue';

export default {
  components: {
    MenuTitle,
    InquiryDialog,
    AdvertiserDetailDialog
  },
  computed: {
    ...mapGetters({
      filters: 'inquiry/filters',
      inquiryDialog: 'inquiry/dialog',
      advertiserDialog: 'advertiser/dialog'
    })
  },
  data() {
    return {
      columns: [
        {
          title: 'NO',
          align: 'center',
          dataIndex: 'id',
          key: 'id',
          width: 80
        },
        {
          title: '카테고리',
          dataIndex: 'category',
          align: 'center',
          key: 'category',
          scopedSlots: {
            customRender: 'category'
          },
          width: 120
        },
        {
          title: '문의자',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {
            customRender: 'name'
          },
          width: 200
        },
        {
          title: '내용',
          dataIndex: 'content',
          key: 'content',
          scopedSlots: {
            customRender: 'content'
          }
        },
        {
          title: '상태',
          dataIndex: 'status',
          align: 'center',
          key: 'status',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '답변일시',
          dataIndex: 'replied_at',
          align: 'center',
          key: 'replied_at',
          scopedSlots: {
            customRender: 'repliedAt'
          },
          width: 150
        },
        {
          title: '등록일시',
          dataIndex: 'created_at',
          align: 'center',
          key: 'created_at',
          scopedSlots: {
            customRender: 'createdAt'
          },
          width: 150
        }
      ],
      inquiryCategoryList: InquiryCategoryList,
      data: [],
      panel: [],
      inquiries: [],
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      loading: true,
      isMobile: false
    };
  },
  async mounted() {
    window.addEventListener(
      'resize',
      () => (this.isMobile = window.innerWidth < 1500)
    );

    await this.getInquiryApi();
  },
  beforeDestroy() {
    window.removeEventListener(
      'resize',
      () => (this.isMobile = window.innerWidth < 1500)
    );
  },
  methods: {
    ...mapActions({
      openDialog: 'inquiry/openDialog',
      openAdvertiserDialog: 'advertiser/openDialog' // 광고주 정보
    }),
    async getInquiryApi() {
      this.loading = true;

      const params = {
        ...this.filters,
        page: this.pagination.page,
        perPage: this.pagination.perPage
      };

      const [error, data] = await getInquiryApi({
        params: params
      });

      if (error) {
        console.error(error);
      } else {
        this.inquiries = data.inquiries;
        const { totalCount } = data;
        const { perPage } = this.pagination;
        const totalPage = parseInt(totalCount / perPage);
        this.pagination.totalPage =
          totalCount % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    }
  }
};
</script>

<style>
.inquiry-table td {
  max-width: 650px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
