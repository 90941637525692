<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>
          캠페인 목록
          <v-menu
            v-if="selectFilter.id"
            open-on-hover
            open-delay="100"
            close-delay="100"
            bottom
            offset-y
            nudge-left=""
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" label class="ml-5">
                <div class="text-subtitle-2">
                  {{ selectFilter.name }}
                </div>
                <v-icon dark right> mdi-chevron-down </v-icon>
              </v-chip>
            </template>
            <v-sheet>
              <v-list-item
                v-for="(filter, index) in unSelectFilterList"
                :key="index"
                @click="handleSelectFilterChange(filter.id)"
              >
                <v-list-item-content>
                  {{ filter.name }}
                </v-list-item-content>
              </v-list-item>
            </v-sheet>

            <!-- <v-card
              width="300"
              min-height="150"
              max-height="300"
              class="overflow-y-auto"
            >
              <v-card-text>
                <v-chip
                  v-for="(filter, index) in unSelectFilterList"
                  :key="index"
                  class="ma-1"
                  label
                  outlined
                  color="secondary"
                  @click="handleSelectFilterChange(filter.id)"
                >
                  <span>
                    {{ filter.name }}
                  </span>
                </v-chip>
              </v-card-text>
            </v-card> -->
          </v-menu>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="align-center">
          <span v-if="!selectFilter.id" class="mr-2">
            <v-btn
              dark
              outlined
              rounded
              small
              :disabled="loading"
              @click="handleStoreFilterClick()"
            >
              저장
            </v-btn>
          </span>
          <span v-else-if="!isSameValue" class="mr-2">
            <v-btn
              dark
              outlined
              rounded
              small
              :disabled="loading"
              @click="handleUpdateClick()"
            >
              변경 저장
            </v-btn>
          </span>

          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="px-3 py-5" style="height: 100%">
        <v-row>
          <v-col cols="12">
            <SearchFilter
              v-if="selectFilter"
              :loading="loading"
              :filter="selectFilter.filters"
              @applyFilters="applyFilters"
            />
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              color="secondary"
              active-class="secondary white--text"
            >
              <v-tab
                v-for="(tab, idx) in summaries"
                :key="idx"
                style="border-radius: 5px 5px 0 0"
              >
                {{ idx }}
              </v-tab>
              <v-tabs-items
                v-model="tab"
                style="border: 1px solid #ccc; border-radius: 0 5px 5px 5px"
              >
                <v-tab-item v-for="(summary, idx) in summaries" :key="idx">
                  <v-col class="error--text text-caption pb-0">
                    * 리포트 조회기간 미설정시, 기본 7일 기준으로 조회됩니다.
                  </v-col>
                  <v-simple-table class="pa-3" style="white-space: nowrap">
                    <tr
                      style="
                        color: #555;
                        font-size: 0.75rem;
                        background-color: rgba(244, 244, 244, 1);
                        border-bottom: 2px solid #fff;
                      "
                    >
                      <th
                        class="px-3 py-4 text-center"
                        :rowspan="2"
                        style="
                          font-size: 0.85rem;
                          width: 120px;
                          border-right: 2px solid #fff;
                        "
                      >
                        <v-row class="row ma-0" justify="center" align="center">
                          {{ idx.toUpperCase() }}<br />
                          리포트 요약
                        </v-row>
                      </th>
                      <th
                        :class="`pa-3 ${item.class}`"
                        v-for="(item, index) in summariesColumn[idx]"
                        :key="index"
                      >
                        {{ item.name }}
                        <span
                          v-if="item.desc"
                          class="text-caption"
                          style="font-size: 0.65rem !important"
                        >
                          {{ item.desc }}
                        </span>
                      </th>
                    </tr>
                    <tr
                      style="
                        background-color: rgba(244, 244, 244, 1);
                        font-size: 0.875rem;
                      "
                    >
                      <td
                        :class="`pa-3 ${item.class}`"
                        v-for="(item, index) in summariesColumn[idx]"
                        :key="index"
                      >
                        <template v-if="!!item.localeString">
                          {{
                            parseInt(
                              summary[item.key] *
                                (!!item.multiple ? item.multiple : 1)
                            ).toLocaleString()
                          }}
                        </template>
                        <template v-else-if="item.KRW">
                          {{ summary[item.key] | KRW }}
                        </template>

                        <template v-else> {{ summary[item.key] }} </template>

                        <div v-if="!!item.rawKey && rawDataSwitch">
                          (
                          <template v-if="item.toLocaleString">
                            {{
                              parseInt(
                                summary[`${item.rawKey}`] *
                                  (!!item.multiple ? item.multiple : 1)
                              ).toLocaleString()
                            }}
                          </template>

                          <template v-else>
                            {{ summary[`${item.rawKey}`] }}
                          </template>
                          )
                        </div>
                      </td>
                    </tr>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>

          <!-- <v-col cols="12" v-for="(summary, idx) in summaries" :key="idx">
            <v-simple-table
              class="pa-3 elevation-2"
              style="white-space: nowrap"
            >
              <tr
                style="
                  color: #555;
                  font-size: 0.75rem;
                  background-color: rgba(244, 244, 244, 1);
                  border-bottom: 2px solid #fff;
                "
              >
                <th
                  class="px-3 py-4 text-center"
                  :rowspan="2"
                  style="
                    font-size: 0.85rem;
                    width: 120px;
                    border-right: 2px solid #fff;
                  "
                >
                  <v-row class="row ma-0" justify="center" align="center">
                    {{ idx.toUpperCase() }}<br />
                    요약
                  </v-row>
                </th>
                <th
                  :class="`pa-3 ${item.class}`"
                  v-for="(item, index) in summariesColumn[idx]"
                  :key="index"
                >
                  {{ item.name }}
                  <span
                    v-if="item.desc"
                    class="text-caption"
                    style="font-size: 0.65rem !important"
                  >
                    {{ item.desc }}
                  </span>
                </th>
              </tr>
              <tr
                style="
                  background-color: rgba(244, 244, 244, 1);
                  font-size: 0.875rem;
                "
              >
                <td
                  :class="`pa-3 ${item.class}`"
                  v-for="(item, index) in summariesColumn[idx]"
                  :key="index"
                >
                  <template v-if="!!item.localeString">
                    {{
                      parseInt(
                        summary[item.key] *
                          (!!item.multiple ? item.multiple : 1)
                      ).toLocaleString()
                    }}
                  </template>
                  <template v-else-if="item.KRW">
                    {{ summary[item.key] | KRW }}
                  </template>

                  <template v-else> {{ summary[item.key] }} </template>

                  <div v-if="!!item.rawKey && rawDataSwitch">
                    (
                    <template v-if="item.toLocaleString">
                      {{
                        parseInt(
                          summary[`${item.rawKey}`] *
                            (!!item.multiple ? item.multiple : 1)
                        ).toLocaleString()
                      }}
                    </template>

                    <template v-else>
                      {{ summary[`${item.rawKey}`] }}
                    </template>
                    )
                  </div>
                </td>
              </tr>
            </v-simple-table>
          </v-col> -->
        </v-row>
        <v-row v-if="isSuperAdmin">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6" lg="3">
                    <v-select
                      v-model="adjustReach"
                      label="도달 보정"
                      :items="AdjustFilterList"
                      dense
                      outlined
                      no-data-text
                      hide-details
                      :disabled="loading"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="6" lg="3">
                    <v-select
                      v-model="adjustImpression"
                      label="노출 보정"
                      :items="AdjustFilterList"
                      dense
                      outlined
                      no-data-text
                      hide-details
                      :disabled="loading"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="pa-5">
              <div class="d-flex align-center">
                <v-btn
                  color="secondary"
                  @click="openCampaignDialog()"
                  :small="$isMobile"
                >
                  캠페인 등록
                </v-btn>
                <div
                  class="d-flex mx-5"
                  style="flex-direction: column; align-items: center"
                >
                  <v-switch
                    v-model="rawDataSwitch"
                    inset
                    hide-details=""
                    color="#F15F5F"
                  ></v-switch>

                  <v-chip class="mt-1" color="#F15F5F" x-small outlined
                    >로우 데이터</v-chip
                  >
                </div>
                <div
                  style="width: 150px"
                  class="d-flex ml-auto align-center justify-space-around"
                >
                  <CampaignExcelExport
                    :headers="headers"
                    :campaigns="originCampaigns"
                    :filters="filters"
                  />
                  <ColumnFilterDialog
                    :originHeaders="
                      originHeaders.filter(
                        v => v.customOptions.group !== 'default'
                      )
                    "
                    :fixedColumns="fixedColumns"
                    :currentHeaders="
                      headers.filter(v => v.customOptions.group !== 'default')
                    "
                    @store="setHeaders"
                  />

                  <CampaignEditBulk
                    :selectedCampaigns="selectedCampaigns"
                    @initSelectedCampaigns="initSelect"
                    @getCampaigns="getCampaignsApi(1)"
                  />
                </div>
              </div>
              <div class="flex mt-3">
                <CampaignSearchForm
                  @setSearchString="
                    v => {
                      searchString = v;
                      getCampaignsApi(1);
                    }
                  "
                />
              </div>
            </v-card>

            <v-card>
              <v-row>
                <v-col>
                  <a-table
                    class="px-3 table-striped-rows"
                    style="white-space: nowrap"
                    :columns="headers"
                    :data-source="campaigns"
                    :row-selection="{
                      selectedRowKeys: selectedCampaignKeys,
                      onChange: onSelectChange
                    }"
                    bordered
                    :loading="loading"
                    :scroll="{ x: true }"
                    :pagination="false"
                    size="small"
                    :locale="{
                      emptyText: '데이터가 없습니다.'
                    }"
                    @change="
                      (pagination, sorter, filter) => {
                        handleTableSortChange(filter);
                      }
                    "
                    :customRow="
                      record => {
                        return {
                          on: {
                            click: event => {
                              this.selectedCampaignKeys = [record.id];
                              this.selectedCampaigns = [record];
                            }
                          }
                        };
                      }
                    "
                  >
                    <template slot="name" slot-scope="value, record">
                      <div
                        class="sub-title-1 text-truncate"
                        style="max-width: 250px; min-height: 20px !important"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ value }} </span>
                          </template>
                          <span> {{ value }}</span>
                        </v-tooltip>
                      </div>

                      <div
                        class="d-inline-flex"
                        style="min-height: 20px !important"
                      >
                        <v-btn
                          class="font-light text-decoration-underline px-0"
                          text
                          small
                          @click="handlePreviewCampaign(record)"
                        >
                          미리보기 >
                        </v-btn>
                        <v-tooltip bottom color="rgba(0,0,0,1)">
                          <template #activator="{ on: onTooltip }">
                            <v-btn
                              class="ml-2"
                              v-on="{ ...onTooltip }"
                              icon
                              small
                              @click="openCampaignDialog(record)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span class="title">수정</span>
                        </v-tooltip>
                        <v-tooltip bottom color="rgba(0,0,0,1)">
                          <template #activator="{ on: onTooltip }">
                            <v-btn
                              class="ml-2"
                              v-on="{ ...onTooltip }"
                              icon
                              small
                              @click="showDetail(record)"
                            >
                              <v-icon>mdi-chart-areaspline-variant</v-icon>
                            </v-btn>
                          </template>
                          <span class="title">일간 차트</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <template slot="activeStatus" slot-scope="value, record">
                      <div class="d-flex justify-center">
                        <v-switch
                          v-model="record.activeStatus"
                          color="success"
                          class="mt-0"
                          inset
                          readonly
                          hide-details
                          @click="handleActiveStatusSwitch(record)"
                        ></v-switch>
                      </div>
                    </template>
                    <template
                      slot="advertiserGroupName"
                      slot-scope="value, record"
                    >
                      <v-chip
                        outlined
                        label
                        pill
                        style="border: none !important"
                      >
                        <v-avatar tile left>
                          <v-img
                            v-if="record.advertiserGroupIconUrl"
                            :lazy-src="`https://notii.azureedge.net/campaign/images/push_default_icon.png`"
                            :src="record.advertiserGroupIconUrl"
                            class="rounded-lg"
                            style="border: 1px solid #ddd"
                          ></v-img>
                        </v-avatar>
                        <span>{{ value }}</span>
                      </v-chip>
                    </template>

                    <template slot="advertiserName" slot-scope="value, record">
                      <v-btn
                        v-if="record.advertiserId"
                        text
                        class="text-body font-weight-regular row align-center ma-0"
                        @click.stop="
                          openAdvertiserDialog({
                            record: {
                              advertiserId: record.advertiserId
                            },
                            type: 'detail'
                          })
                        "
                      >
                        {{ value }}
                        <v-icon class="ml-1 mb-1" color="grey darken-3">
                          mdi-application-edit-outline
                        </v-icon>
                      </v-btn>
                      <template v-else> {{ value }}</template>
                    </template>

                    <template slot="type" slot-scope="value">
                      {{ CampaignTypeList.find(v => v.value === value).text }}
                    </template>
                    <!-- 매체그룹 -->
                    <template slot="mediaGroups" slot-scope="mediaGroups">
                      <v-menu
                        v-if="mediaGroups && mediaGroups.length > 0"
                        open-on-hover
                        open-delay="100"
                        close-delay="100"
                        bottom
                        offset-y
                        nudge-left=""
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 font-weight-bold"
                            label
                            color="secondary"
                            small
                          >
                            <span
                              >{{ mediaGroups[0].name }}
                              {{
                                mediaGroups.length > 1
                                  ? `외 ${mediaGroups.length - 1}개`
                                  : ''
                              }}</span
                            >
                          </v-chip>
                        </template>
                        <v-card
                          width="300"
                          min-height="150"
                          max-height="300"
                          class="overflow-y-auto"
                        >
                          <v-card-title> 매체그룹 </v-card-title>
                          <v-card-text>
                            <v-chip
                              v-for="(mediaGroup, index) in mediaGroups"
                              :key="index"
                              class="ma-1"
                              label
                              outlined
                              color="secondary"
                            >
                              <span>
                                {{ mediaGroup.name }}
                              </span>
                            </v-chip>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                    <template slot="money" slot-scope="text">
                      {{ Number(text) | KRW }}
                    </template>

                    <template slot="localeString" slot-scope="text">
                      {{ text ? text.toLocaleString() : '-' }}
                    </template>
                    <template slot="adjustReach" slot-scope="text, record">
                      {{
                        text
                          ? parseInt(text * adjustReach).toLocaleString()
                          : '-'
                      }}
                      <div v-if="rawDataSwitch">
                        ({{
                          record.rawReachCount
                            ? parseInt(
                                record.rawReachCount * adjustReach
                              ).toLocaleString()
                            : '-'
                        }})
                      </div>
                    </template>
                    <template slot="adjustImpression" slot-scope="text, record">
                      {{
                        text
                          ? parseInt(text * adjustImpression).toLocaleString()
                          : '-'
                      }}
                      <div v-if="rawDataSwitch">
                        (
                        {{
                          text
                            ? parseInt(
                                record.rawImpressionCount * adjustImpression
                              ).toLocaleString()
                            : '-'
                        }})
                      </div>
                    </template>
                    <template slot="adjustCtr" slot-scope="text, record">
                      {{
                        adjustImpression === 1
                          ? text
                          : (
                              (record.clickCount /
                                parseInt(
                                  record.impressionCount * adjustImpression
                                )) *
                              100
                            ).toFixed(3)
                      }}
                      %
                      <div v-if="rawDataSwitch">
                        (
                        {{
                          adjustImpression === 1
                            ? record.rawImpressionCtr
                            : (
                                (record.clickCount /
                                  parseInt(
                                    record.rawImpressionCount * adjustImpression
                                  )) *
                                100
                              ).toFixed(3)
                        }}
                        %)
                      </div>
                    </template>
                    <template slot="adjustCtrReach" slot-scope="text, record">
                      {{
                        adjustReach === 1
                          ? text
                          : (
                              (record.clickCount /
                                parseInt(record.sReachCount * adjustReach)) *
                              100
                            ).toFixed(3)
                      }}
                      %
                      <div v-if="rawDataSwitch">
                        ({{
                          adjustReach === 1
                            ? record.rawReachCtr
                            : (
                                (record.clickCount /
                                  parseInt(
                                    record.rawReachCount * adjustReach
                                  )) *
                                100
                              ).toFixed(3)
                        }}
                        %)
                      </div>
                    </template>
                    <template slot="percent" slot-scope="text">
                      {{ text }} %
                    </template>

                    <template slot="impressionTime" slot-scope="text, item">
                      {{ text }}
                      {{
                        UnitOfTimeList.find(
                          v => v.value === item.impressionTimeType
                        ).text
                      }}
                    </template>
                    <template slot="messageDateTime" slot-scope="text, record">
                      <div style="position: relative">
                        <p class="ma-0">{{ text }}</p>
                        <small
                          v-if="record.activeStatus"
                          class="red--text"
                          style="position: absolute"
                        >
                          {{
                            !text ||
                            $moment().format('YYYY-MM-DD HH:mm:ss') > text
                              ? ''
                              : `전송 ${$moment(
                                  text,
                                  'YYYY-MM-DD HH:mm'
                                ).toNow()}`
                          }}
                        </small>
                      </div>
                    </template>

                    <template slot="displayPeriodEnd" slot-scope="text, item">
                      <div
                        style="position: relative"
                        v-if="item.issetDisplayPeriodEnd"
                      >
                        <p class="ma-0">{{ text }}</p>
                        <small
                          v-if="item.activeStatus"
                          class="red--text"
                          style="position: absolute"
                        >
                          {{
                            $moment().format('YYYY-MM-DD HH:mm:ss') >
                            `${text} 23:59:59`
                              ? ''
                              : `종료 ${$moment(
                                  `${text} 23:59:59`,
                                  'YYYY-MM-DD HH:mm:ss'
                                ).toNow()}`
                          }}
                        </small>
                      </div>

                      <div v-else>설정안함</div>
                    </template>
                    <template slot="targetTags" slot-scope="tags, record">
                      <v-menu
                        v-if="tags && tags.length > 0"
                        open-on-hover
                        open-delay="100"
                        close-delay="100"
                        bottom
                        offset-y
                        nudge-left=""
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 font-weight-bold"
                            label
                            :color="`${
                              record.isTagFilter ? 'success' : 'secondary'
                            }`"
                            small
                          >
                            <span
                              ># {{ tags[0].name }}
                              {{
                                tags.length > 1 ? `외 ${tags.length - 1}개` : ''
                              }}</span
                            >
                          </v-chip>
                        </template>
                        <v-card
                          width="300"
                          min-height="150"
                          max-height="300"
                          class="overflow-y-auto"
                        >
                          <!-- <v-card-title>
                        <span>
                          최대 예상 태그 타겟 모수:
                          <br />
                          <span v-if="record.targetTagsType === 'A'">
                            {{
                              Math.min(
                                ...tags.map(v => v.latest_count)
                              ).toLocaleString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              tags
                                .reduce((acc, cur) => {
                                  return acc + cur.latest_count;
                                }, 0)
                                .toLocaleString()
                            }}
                          </span>
                        </span>
                      </v-card-title> -->
                          <v-card-text>
                            <v-chip
                              v-for="(tag, index) in tags"
                              :key="index"
                              class="ma-1"
                              label
                              outlined
                              color="error"
                            >
                              <v-icon>mdi-pound</v-icon>
                              <span>
                                {{ tag.name }}
                              </span>
                            </v-chip>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                    <template slot="tags" slot-scope="tags">
                      <v-menu
                        v-if="tags && tags.length > 0"
                        open-on-hover
                        open-delay="100"
                        close-delay="100"
                        bottom
                        offset-y
                        nudge-left=""
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 font-weight-bold"
                            label
                            color="default"
                            small
                          >
                            <span
                              ># {{ tags[0].name }}
                              {{
                                tags.length > 1 ? `외 ${tags.length - 1}개` : ''
                              }}</span
                            >
                          </v-chip>
                        </template>
                        <v-card
                          width="300"
                          min-height="150"
                          max-height="300"
                          class="overflow-y-auto"
                        >
                          <v-card-text>
                            <v-chip
                              v-for="(tag, index) in tags"
                              :key="index"
                              class="ma-1"
                              label
                              outlined
                              color="primary darken-3"
                            >
                              <v-icon>mdi-pound</v-icon>
                              <span>
                                {{ tag.name }}
                              </span>
                            </v-chip>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                    <template slot="targetAppsType" slot-scope="type, record">
                      <v-menu
                        open-on-hover
                        open-delay="100"
                        close-delay="100"
                        bottom
                        auto
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-chip
                              v-if="type"
                              class="ma-0 font-weight-bold"
                              label
                              :color="`${
                                TargetAppsTypeList.find(v => v.value === type)
                                  .color
                              }`"
                              small
                            >
                              <span>
                                {{
                                  TargetAppsTypeList.find(v => v.value === type)
                                    .text
                                }}
                                {{
                                  record.targetApps &&
                                  record.targetApps.length > 0
                                    ? `${record.targetApps.length}개`
                                    : ''
                                }}</span
                              >
                            </v-chip>
                            <template v-else> - </template>
                          </span>
                        </template>
                        <v-card
                          v-if="
                            record.targetApps && record.targetApps.length > 0
                          "
                          min-width="300"
                          max-width="500"
                          min-height="150"
                        >
                          <v-card-title>
                            {{
                              TargetAppsTypeList.find(v => v.value === type)
                                .text
                            }}
                            {{
                              `${
                                record.targetApps ? record.targetApps.length : 0
                              }개`
                            }}
                          </v-card-title>
                          <v-card-text>
                            <v-chip
                              v-for="(app, index) in record.targetApps"
                              :key="index"
                              label
                              color="secondary"
                              class="px-3 py-5 ma-1"
                              style="height: 40px"
                            >
                              <v-img
                                :src="app.image"
                                class="rounded-lg"
                                width="30"
                              ></v-img>
                              <span class="ml-2 font-weight-bold">{{
                                app.name
                              }}</span>
                            </v-chip>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                    <template slot="targetTagsType" slot-scope="type, record">
                      <p class="ma-0">
                        {{
                          record.targetTagsMode !== null
                            ? TargetTagsModeList.find(
                                v => v.value === record.targetTagsMode
                              ).text
                            : ''
                        }}
                      </p>

                      {{
                        type !== null
                          ? TargetTagsTypeList.find(v => v.value === type).text
                          : ''
                      }}
                    </template>
                    <template slot="targetTagsCount" slot-scope="count">
                      {{ count > 0 ? count.toLocaleString() : '-' }}
                    </template>

                    <template slot="costTypes" slot-scope="costTypes">
                      <p
                        v-for="(costType, index) in costTypes"
                        :key="index"
                        class="mb-0"
                      >
                        {{ costType.text }}
                      </p>
                    </template>
                    <template slot="approveStatus" slot-scope="value, record">
                      <div
                        class="d-inline-flex align-center"
                        style="min-height: 30px !important"
                      >
                        <v-badge
                          left
                          dot
                          inline
                          :color="
                            ApproveStatusList.find(v => v.value === value)
                              .colorCode
                          "
                          >{{
                            ApproveStatusList.find(v => v.value === value).text
                          }}
                        </v-badge>
                        <div style="min-width: 40px" v-if="value !== 'S'">
                          <v-tooltip bottom color="rgba(0,0,0,1)">
                            <template #activator="{ on: onTooltip }">
                              <v-btn
                                class="ml-2"
                                v-on="{ ...onTooltip }"
                                icon
                                small
                                @click.stop="handleApproveStatusClick(record)"
                              >
                                <v-icon>mdi-sync</v-icon>
                              </v-btn>
                            </template>
                            <span class="title">게재 상태 변경</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </template>

                    <template
                      slot="targetLocationsText"
                      slot-scope="value, record"
                    >
                      <v-menu
                        open-on-hover
                        open-delay="100"
                        close-delay="100"
                        bottom
                        offset-y
                        max-height="300"
                        :disabled="!record.targetLocations.length"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <!-- 활동 상태 & 실시간 일경우 모수 체크 안함  -->
                            <v-badge
                              left
                              dot
                              inline
                              :color="
                                !record.targetLocationsCount
                                  ? record.targetLocationsType === 'R' &&
                                    record.targetLocations.length > 0
                                    ? 'primary'
                                    : 'error'
                                  : 'primary'
                              "
                            >
                              {{ value }}
                            </v-badge>
                            <!-- 활동 상태인데, targetLocationsCount가 0인경우  -->
                            <v-icon
                              v-if="
                                record.targetLocationsType !== 'R' &&
                                !record.targetLocationsCount &&
                                record.targetLocations.length > 0
                              "
                              small
                              class="ml-1"
                              color="error"
                            >
                              mdi-information-outline
                            </v-icon>
                          </div>
                        </template>
                        <v-card>
                          <v-card-title
                            class="text-subtitle-2 pt-2 pb-0"
                            v-if="record.targetLocationsType !== 'R'"
                          >
                            모수 :
                            {{ record.targetLocationsCount.toLocaleString() }}
                          </v-card-title>
                          <v-card-text
                            class="pa-0 pb-1"
                            v-if="record.targetLocations.length > 0"
                          >
                            <v-col class="pb-1">
                              <v-chip label small outlined color="secondary">
                                {{
                                  getTargetLocationsTypeText(
                                    record.targetLocationsType
                                  )
                                }}
                              </v-chip>
                            </v-col>
                            <div
                              v-for="(
                                location, index
                              ) in record.targetLocations"
                              :key="index"
                              :class="`ma-2 px-1 ${index > 0 ? 'mt-2' : ''}`"
                              style="border: 1px solid #eee"
                            >
                              <v-card-text>
                                위도 : {{ location.lat }}<br />
                                경도 : {{ location.lng }}<br />
                                주소 : {{ location.address }}<br />
                                반경 :
                                {{ location.radius.toLocaleString() }} (m)
                              </v-card-text>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </a-table>

                  <div class="d-flex justify-end pa-3" v-if="hasCampaigns">
                    <a-pagination
                      v-model="pagination.page"
                      :defaultPageSize="10"
                      :page-size.sync="pagination.perPage"
                      size="large"
                      :show-size-changer="true"
                      :pageSizeOptions="['10', '20', '50', '100']"
                      :total="pagination.totalCount"
                      @change="handlePaginationChange"
                      @showSizeChange="getCampaignsApi(1)"
                    >
                    </a-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <CampaignDialog />
        <CampaignDetail
          v-if="bottomSheet"
          :sheet="bottomSheet"
          :campaign="bottomSheetCampaign"
          @closeBottomSheet="bottomSheet = false"
        />
        <CampaignPreviewDialog
          :openDialog="campaignPreviewDialog"
          @closeDialog="campaignPreviewDialog = false"
        />
        <ApproveStatusDialog
          v-if="approveStatusDialog"
          :campaign="selectedCampaign"
        />
        <AdvertiserDetailDialog v-if="advertiserDialog" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import CampaignFilter from './components/index/CampaignFilter.vue';
// import CampaignReports from './components/media/CampaignReports.vue';
import CampaignDialog from './components/register/CampaignDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import CampaignPreviewDialog from './components/index/CampaignPreviewDialog.vue';
import ColumnFilterDialog from '@/components/ColumnFilterDialog.vue';
import CampaignEditBulk from './components/index/CampaignEditBulk.vue';
import CampaignExcelExport from './components/index/CampaignExcelExport.vue';
import CampaignSearchForm from './components/index/CampaignSearchForm.vue';
import SearchFilter from './components/index/SearchFilterCampaign.vue';
import CampaignDetail from './components/index/CampaignDetail.vue';
import ApproveStatusDialog from './components/index/ApproveStatusDialog.vue';
import { UnitOfTimeList } from '@/config/common';
import {
  getCampaignsApi,
  updateCampaignActiveStatusAPI
} from '@/api/admin/CampaignAPI';
import {
  getMenuApi,
  updateFilterContentsAPI,
  storeFilterApi,
  updateColumnsAPI
} from '@/api/common/MenusAPI';
import {
  AdjustFilterList,
  ApproveStatusList,
  CampaignTypeList,
  TargetTagsTypeList,
  TargetAppsTypeList,
  TargetTagsModeList,
  TargetLocationsTypeList
} from '@/config/admin/campaign';

import { Pagination } from '@/model/Pagination';
import { Campaign } from '@/model/admin/campaign';
import AdvertiserDetailDialog from '@/views/admin/pages/advertiser/components/dialog/AdvertiserDetailDialog.vue';

export default {
  props: {
    selectFilter: {
      type: Object,
      default: () => {
        // 필터 새로 생성시 기본값
        return {
          filters: [
            { key: 'isAdmin', value: [true] },
            { key: 'approveStatus', value: ['Y', 'W'] },
            { key: 'activeStatus', value: [true, false] }
          ]
        };
      }
    },
    dialog: {
      type: Boolean,
      default: false
    },
    campaignFilterList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    CampaignDialog,
    CampaignPreviewDialog,
    ColumnFilterDialog,
    CampaignEditBulk,
    CampaignExcelExport,
    CampaignSearchForm,
    SearchFilter,
    CampaignDetail,
    ApproveStatusDialog,
    AdvertiserDetailDialog
  },
  data() {
    return {
      TargetTagsTypeList: TargetTagsTypeList,
      TargetAppsTypeList: TargetAppsTypeList,
      TargetTagsModeList: TargetTagsModeList,
      CampaignTypeList: CampaignTypeList,
      activeStatusSwitchAuth: [3, 4],
      pagination: new Pagination(1, 10),
      campaignMediaGroupBuckets: [],
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      searchString: null,
      campaignPreviewDialog: false,
      headers: [],
      originHeaders: [],
      campaigns: [],
      originCampaigns: [],
      activeCampaignIds: null,
      selectedCampaigns: [],
      selectedCampaign: null,
      selectedCampaignKeys: [],
      loading: false,
      tableOptions: null,
      editFunctionList: [{ text: '' }],
      filters: null,
      originFilter: null,
      columns: [],
      tab: 'sdk',
      summaries: null,
      bottomSheet: false,
      bottomSheetCampaign: null,
      adjustReach: 1,
      adjustImpression: 1,
      AdjustFilterList: AdjustFilterList,
      ApproveStatusList: ApproveStatusList,
      rawDataSwitch: false,
      order: null
    };
  },
  watch: {
    async selectFilter(next, prev) {
      if (prev?.id === next?.id) return;
      this.originFilter = null;
      this.init();
    }
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
      kst: 'auth/kst',
      user: 'auth/user',
      approveStatusDialog: 'campaign/approveStatusDialog',
      advertiserDialog: 'advertiser/dialog'
    }),
    UnitOfTimeList() {
      return UnitOfTimeList;
    },
    fixedColumns() {
      return this.originHeaders.filter(
        v => v.customOptions.group === 'default'
      );
    },
    hasCampaigns() {
      return this.campaigns.length > 0;
    },
    isSameValue() {
      return JSON.stringify(this.filters) === JSON.stringify(this.originFilter);
    },
    summariesColumn() {
      return {
        sdk: [
          {
            name: '도달',
            key: 'reach_count',
            multiple: this.adjustReach,
            localeString: true,
            rawKey: 'raw_reach_count',
            class: 'text-right'
          },
          {
            name: '노출',
            key: 'impression_count',
            multiple: this.adjustImpression,
            localeString: true,
            rawKey: 'raw_impression_count',
            class: 'text-right'
          },
          {
            name: '클릭',
            key: 'click_count',
            localeString: true,
            class: 'text-right'
          },
          {
            name: 'CTR_도달',
            key: 'ctrr',
            rawKey: 'raw_ctrr',
            class: 'text-right'
          },
          {
            name: 'CTR_노출',
            key: 'ctri',
            rawKey: 'raw_ctri',
            class: 'text-right'
          },
          {
            name: '빈도',
            key: 'frequency',
            rawKey: 'raw_frequency',
            class: 'text-right'
          },
          {
            name: '지출금액',
            desc: '(CPC)',
            key: 'cpc',
            KRW: true,
            class: 'text-right'
          },
          {
            name: '지출금액',
            desc: '(CPM 노출)',
            key: 'cpmi',
            KRW: true,
            class: 'text-right'
          },
          {
            name: '지출금액',
            desc: '(CPM 도달)',
            key: 'cpmr',
            KRW: true,
            class: 'text-right'
          }
        ],
        coupon: [
          {
            name: 'API 호출',
            key: 'branch_api_call_count',
            localeString: true,
            class: 'text-right'
          },
          {
            name: '팝업 노출',
            key: 'branch_popup_count',
            localeString: true,
            class: 'text-right'
          },
          {
            name: '도달',
            key: 'reach_count',
            multiple: this.adjustReach,
            localeString: true,
            rawKey: 'raw_reach_count',
            class: 'text-right'
          },
          {
            name: '노출',
            key: 'impression_count',
            multiple: this.adjustImpression,
            localeString: true,
            rawKey: 'raw_impression_count',
            class: 'text-right'
          },
          {
            name: '클릭',
            key: 'click_count',
            localeString: true,
            class: 'text-right'
          },
          {
            name: 'CTR_도달',
            key: 'ctrr',
            rawKey: 'raw_ctrr',
            class: 'text-right'
          },
          {
            name: 'CTR_노출',
            key: 'ctri',
            rawKey: 'raw_ctri',
            class: 'text-right'
          },
          {
            name: '빈도',
            key: 'frequency',
            rawKey: 'raw_frequency',
            class: 'text-right'
          },
          {
            name: '지출금액',
            desc: '(CPC)',
            key: 'expenditure',
            KRW: true,
            class: 'text-right'
          }
        ]
      };
    },
    unSelectFilterList() {
      return this.campaignFilterList.filter(
        item => item.id !== this.selectFilter.id
      );
    }
  },
  created() {
    this.init();
  },
  mounted() {
    //하위 컴포넌트에서 해당 이벤트 호출을 위해 on 이벤트를 등록합니다.
    const thisInstance = this;
    this.$root.$on('getCampaignsApiEvent', function () {
      thisInstance.getCampaignsApi();
    });
  },
  methods: {
    ...mapActions({
      getCampaign: 'campaign/getCampaign',
      initCampaign: 'campaign/initCampaign',
      setTab: 'campaign/setTab',
      openDialog: 'campaign/openDialog',
      setCampaignId: 'campaign/setCampaignId',
      setCampaign: 'campaign/setCampaign',
      setDialogTypeEdit: 'campaign/setDialogTypeEdit',
      setDialogTypeRegister: 'campaign/setDialogTypeRegister',
      openApproveStatusDialog: 'campaign/openApproveStatusDialog',
      openAdvertiserDialog: 'advertiser/openDialog' // 광고주 정보
    }),
    handlePaginationChange() {
      this.getCampaignsApi();
    },
    // 창 띄웠을 경우, 필터 변경시
    async init() {
      this.initSelect();

      this.dateRanges = [
        this.$moment(this.day, 'YYYY-MM-DD')
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
        this.$moment(this.day, 'YYYY-MM-DD').format('YYYY-MM-DD')
      ];

      await this.initHeaders();

      if (this.selectFilter.columns) {
        const fixedColumns = ['id', 'activeStatus', 'approveStatus', 'name'];

        this.headers = this.selectFilter.columns.map(column => {
          const res = JSON.parse(JSON.stringify(column));
          if (fixedColumns.includes(column.dataIndex)) {
            res.fixed = !this.$isMobile;
          }
          return res;
        });
      }
    },
    //선택 초기화
    initSelect() {
      this.selectedCampaigns = [];
      this.selectedCampaignKeys = [];
    },
    //필터 적용 후 캠페인
    applyFilters(filters) {
      this.filters = filters;

      if (!this.originFilter) {
        // 처음에반 세팅
        this.originFilter = JSON.parse(JSON.stringify(this.filters));
      }

      this.getCampaignsApi(1);
    },
    // 캠페인 목록 조회
    async getCampaignsApi(page = null) {
      if (page) {
        this.pagination.page = page;
      }

      this.loading = true;
      this.initSelect();
      this.campaigns = [];
      const params = {
        ss: this.searchString,
        page: this.pagination.page,
        perPage: this.pagination.perPage,
        filters: this.filters,
        tableOptions: this.tableOptions,
        isAdmin: true,
        order: this.order ?? null
      };
      const [error, data] = await getCampaignsApi({ params: params });

      if (error) {
        console.error(error);
      } else {
        // console.log(`data`, data);
        const { campaigns, total_count, summaries } = data;
        const { hashedCampaigns, activeCampaignIds } = this.hashing(campaigns);
        this.originCampaigns = campaigns;
        this.campaigns = hashedCampaigns;
        this.activeCampaignIds = activeCampaignIds;

        this.summaries = summaries ? {} : null;
        if (summaries) {
          for (const key in summaries) {
            const summary = summaries[key];

            let ctrr =
              this.adjustReach === 1
                ? summary.ctr_reach
                : (
                    (summary.click_count /
                      parseInt(summary.reach_count * this.adjustReach)) *
                    100
                  ).toFixed(3);
            let raw_ctrr =
              this.adjustReach === 1
                ? summary.raw_reach_ctr
                : (
                    (summary.click_count /
                      parseInt(summary.raw_reach_count * this.adjustReach)) *
                    100
                  ).toFixed(3);

            let ctri =
              this.adjustImpression === 1
                ? summary.ctr
                : (
                    (summary.click_count /
                      parseInt(summary.impression_count * this.adjustReach)) *
                    100
                  ).toFixed(3);
            let raw_ctri =
              this.adjustImpression === 1
                ? summary.raw_impression_ctr
                : (
                    (summary.click_count /
                      parseInt(
                        summary.raw_impression_count * this.adjustReach
                      )) *
                    100
                  ).toFixed(3);

            this.summaries[key] = {
              ...summary,
              ctrr: ctrr + '%',
              raw_ctrr: raw_ctrr,
              ctri: ctri + '%',
              raw_ctri: raw_ctri
            };
          }
        }
        const { perPage } = this.pagination;

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }
      this.loading = false;
    },
    // 테이블 컬럼 Header 정보 저장
    async setHeaders(columns) {
      this.headers = columns;

      if (!this.selectFilter.id) return;

      const [error] = await updateColumnsAPI(this.selectFilter.id, {
        columns: columns
      });

      if (error) {
        console.error(error);
      } else {
        this.$emit('refresh');
      }
    },
    // 테이블 컬럼 Header 정보 조회
    async getHeaders() {
      const [error, data] = await getMenuApi({
        params: {
          visitor_id: this.user.visitorId,
          view: 'campaign',
          type: 'header'
        }
      });

      if (error) {
        console.log(error);
      } else {
        // console.log(data);
        return data.columns;
      }
    },
    // 테이블 컬럼 Header 초기화
    initHeaders(showSetting = false) {
      this.originHeaders = [
        {
          title: '번호',
          dataIndex: 'id',
          width: 50,
          fixed: this.$isMobile ? false : true,
          key: 'id',
          customOptions: { group: 'default' }
        },
        {
          title: '상태',
          dataIndex: 'activeStatus',
          key: 'activeStatus',
          fixed: this.$isMobile ? false : true,
          width: 80,
          scopedSlots: {
            customRender: 'activeStatus'
          },
          customOptions: { group: 'default' }
        },
        {
          title: '게재',
          dataIndex: 'approveStatus',
          key: 'approveStatus',
          fixed: this.$isMobile ? false : true,
          width: 80,
          scopedSlots: {
            customRender: 'approveStatus'
          },
          customOptions: { group: 'default' }
        },
        {
          title: '캠페인명',
          dataIndex: 'name',
          key: 'name',
          fixed: this.$isMobile ? false : true,
          width: 200,
          scopedSlots: {
            customRender: 'name'
          },
          customOptions: { group: 'default' }
        },
        {
          title: 'API 호출',
          dataIndex: 'couponBranchApiCallCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' }
        },
        {
          title: '팝업 노출',
          dataIndex: 'couponBranchPopupCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' }
        },
        {
          title: '도달 Live',
          dataIndex: 'liveReachCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' }
        },
        {
          title: '도달',
          dataIndex: 'sReachCount',
          align: 'right',
          scopedSlots: {
            customRender: 'adjustReach'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.sReachCount - b.sReachCount
        },
        {
          title: '노출',
          dataIndex: 'impressionCount',
          key: 'impressionCount',
          align: 'right',
          scopedSlots: {
            customRender: 'adjustImpression'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.impressionCount - b.impressionCount
        },
        {
          title: '클릭',
          dataIndex: 'clickCount',
          key: 'clickCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.clickCount - b.clickCount
        },
        {
          title: 'CTR_도달',
          dataIndex: 'sCtrReach',
          key: 'sCtrReach',
          align: 'right',
          scopedSlots: {
            customRender: 'adjustCtrReach'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.ctrReach - b.ctrReach
        },
        {
          title: 'CTR_노출',
          dataIndex: 'ctr',
          key: 'ctr',
          align: 'right',
          scopedSlots: {
            customRender: 'adjustCtr'
          },
          customOptions: { group: 'report' },
          sorter: (a, b) => a.ctr - b.ctr
        },
        {
          title: '빈도',
          dataIndex: 'frequency',
          key: 'frequency',
          align: 'right',
          customOptions: { group: 'report' },
          sorter: (a, b) => a.frequency - b.frequency
        },
        {
          title: '지출금액',
          dataIndex: 'expenditure',
          key: 'expenditure',
          align: 'right',
          customOptions: { group: 'report' },
          scopedSlots: {
            customRender: 'money'
          },
          sorter: (a, b) => a.expenditure - b.expenditure
        },
        {
          title: '#태그 타겟',
          dataIndex: 'targetTags',
          key: 'targetTags',
          scopedSlots: { customRender: 'targetTags' },
          customOptions: { group: 'setting' }
        },

        {
          title: '#최대 예상 타겟 모수',
          dataIndex: 'targetTagsCount',
          key: 'targetTagsCount',
          align: 'right',
          width: '100',
          scopedSlots: { customRender: 'localeString' },
          customOptions: { group: 'setting' }
        },
        {
          title: '#태그 타겟 필터',
          dataIndex: 'targetTagsType',
          key: 'targetTagsType',
          scopedSlots: { customRender: 'targetTagsType' },

          customOptions: { group: 'setting' }
        },
        {
          title: '#수집 태그',
          dataIndex: 'tags',
          key: 'tags',
          scopedSlots: { customRender: 'tags' },
          customOptions: { group: 'setting' }
        },
        {
          title: '앱 타겟 유형',
          dataIndex: 'targetAppsType',
          key: 'targetAppsType',
          scopedSlots: { customRender: 'targetAppsType' },
          customOptions: { group: 'setting' }
        },
        {
          title: '위치 타겟',
          dataIndex: 'targetLocationsText',
          scopedSlots: { customRender: 'targetLocationsText' },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고그룹',
          dataIndex: 'advertiserGroupName',
          key: 'advertiserGroupName',
          scopedSlots: { customRender: 'advertiserGroupName' },
          customOptions: { group: 'setting' }
        },

        {
          title: '광고주',
          dataIndex: 'advertiserName',
          key: 'advertiserName',
          scopedSlots: { customRender: 'advertiserName' },
          customOptions: { group: 'setting' }
        },
        {
          title: '매체그룹',
          dataIndex: 'mediaGroups',
          key: 'mediaGroups',
          scopedSlots: { customRender: 'mediaGroups' },
          customOptions: { group: 'setting' }
        },
        {
          title: '트래커',
          dataIndex: 'tracker',
          key: 'tracker',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 유형',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: {
            customRender: 'type'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 노출 시간',
          dataIndex: 'impressionTime',
          align: 'center',
          scopedSlots: {
            customRender: 'impressionTime'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 노출 우선 순위',
          dataIndex: 'impressionFrequency',
          key: 'impressionFrequency',
          align: 'center',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 미노출 최대 클릭 수',
          dataIndex: 'adsGoalCount',
          align: 'center',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 클릭 후 재노출 기간 (일간)',
          dataIndex: 'adsLimitInterval',
          key: 'adsLimitInterval',
          align: 'center',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 전송일시',
          dataIndex: 'messageDateTime',
          key: 'messageDateTime',
          scopedSlots: {
            customRender: 'messageDateTime'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 시작일',
          dataIndex: 'displayPeriodStart',
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 종료일',
          dataIndex: 'displayPeriodEnd',
          key: 'displayPeriodEnd',
          scopedSlots: {
            customRender: 'displayPeriodEnd'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 생성일시',
          dataIndex: 'createdAt',
          key: 'createdAt',
          customOptions: { group: 'setting' }
        },
        {
          title: '일 예산',
          dataIndex: 'dailyAdvertisingBudget',
          key: 'dailyAdvertisingBudget',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '총 예산',
          dataIndex: 'totalAdvertisingBudget',
          key: 'totalAdvertisingBudget',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: 'CPC',
          dataIndex: 'cpc',
          key: 'cpc',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.cpc - b.cpc
        },
        {
          title: 'CPM 노출',
          dataIndex: 'cpmi',
          key: 'cpmi',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.cpmi - b.cpmi
        },
        {
          title: 'CPM 도달',
          dataIndex: 'cpmr',
          key: 'cpmr',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.cpmr - b.cpmr
        },
        {
          title: '보증금',
          dataIndex: 'deposit',
          key: 'deposit',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.deposit - b.deposit
        },
        {
          title: '잔여보증금',
          dataIndex: 'remainDeposit',
          key: 'remainDeposit',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          },
          customOptions: { group: 'setting' },
          sorter: (a, b) => a.remainDeposit - b.remainDeposit
        },
        {
          title: '과금 유형',
          dataIndex: 'costTypes',
          key: 'costTypes',
          scopedSlots: {
            customRender: 'costTypes'
          },
          customOptions: { group: 'setting' }
        },
        {
          title: '광고 집행 도달 수',
          dataIndex: 'advertisingReachCount',
          key: 'advertisingReachCount',
          align: 'right',
          scopedSlots: { customRender: 'localeString' },
          customOptions: { group: 'setting' }
        }
      ];

      const settingColumns = [
        // 'advertiserName',
        // 'mediaGroups',
        // 'type',
        'impressionTime',
        'impressionFrequency',
        'adsGoalCount',
        'adsLimitInterval'
        // 'messageDateTime',
        // 'dailyAdvertisingBudget',
        // 'totalAdvertisingBudget',
        // 'displayPeriodStart',
        // 'displayPeriodEnd',
        // 'createdAt'
      ];

      const reportColumns = [
        // 'reachCount',
        // 'impressionCount',
        // 'clickCount',
        // 'ctr',
        // 'ctrReach',
        // 'frequency',
        // 'cpc',
        // 'expenditure'
      ];

      if (showSetting) {
        this.headers = this.originHeaders.filter(
          el => !reportColumns.includes(el.dataIndex)
        );
      } else {
        this.headers = this.originHeaders.filter(
          el => !settingColumns.includes(el.dataIndex)
        );
      }
    },
    hashing(campaigns) {
      const activeCampaignIds = [];
      const hashedCampaigns = campaigns.map(campaign => {
        activeCampaignIds.push(campaign.id);

        return new Campaign(campaign, true);
      });

      return {
        hashedCampaigns: hashedCampaigns,
        activeCampaignIds: activeCampaignIds
      };
    },
    async openCampaignDialog(campaign = null) {
      this.openDialog();

      if (campaign) {
        this.setDialogTypeEdit();
      } else {
        this.setDialogTypeRegister();
      }

      await this.getCampaign(campaign?.id);
    },
    showDetail(record) {
      this.bottomSheet = true;
      this.bottomSheetCampaign = record;
    },
    moveToReport(campaignId) {
      this.setCampaignId(campaignId);
      this.$router.push({
        name: 'AdminSubReport',
        params: {
          campaignIds: [campaignId]
        }
      });
    },
    handleActiveStatusSwitch(item) {
      // 승인 되지 않는 캠페인 활성 상태 변경 불가
      if (item.approveStatus !== 'Y') {
        this.$Swal.fire({
          html: '<h3>승인이 필요합니다.</h3>',
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return false;
      }

      if (this.activeStatusSwitchAuth.includes(this.user.level) === false) {
        return false;
      }
      //기본 설정 값 유효성 체크
      /**
       * 조건
       * 1. 현재 상태가 비활성 상태
       * 2. message 타입이면서 광고전송일시(messageDateTime) 가 설정되어있지않은 경우
       * 3. 또는 광고전송일시가 3시간이상 남은경우
       */
      if (item.type === 'message') {
        // 메시지 전송 시간 계산
        const diff = this.$moment(this.kst).diff(
          this.$moment(`${item.messageDateTime}`).format('YYYY-MM-DD HH:mm'),
          'hours'
        );
        // console.log(`diff`, diff);
        const status = item.messageDateTime === '' ? true : diff > -3;
        if (item.activeStatus === false && status) {
          this.$Swal.fire({
            html: '<h3>유효하지 않은 광고 전송일시 입니다.</h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }

        const diffEndDay = this.$moment(this.kst).diff(
          this.$moment(`${item.displayPeriodEnd}`)
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        );
        // 활성상태, 종료일, 24시간 전을 확인 합니다.
        if (item.activeStatus === true && diffEndDay < 0 && diff > -24) {
          this.$Swal.fire({
            html: '<h3>종료 대기중인 캠페인입니다. <br/><small>즉시 비활성화는 관리자에게 문의하세요.</small></h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }
      }

      this.$Swal
        .fire({
          html: `<h3>${item.id}번 ${item.name} <br> 광고를 ${
            !item.activeStatus ? '라이브' : '중단'
          } 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            const [error] = await updateCampaignActiveStatusAPI({
              campaignIds: [item.id],
              activeStatus: !item.activeStatus
            });

            if (error) {
              console.error(error);
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.activeStatus = !item.activeStatus;
          }
        });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedCampaignKeys = selectedRowKeys;
      this.selectedCampaigns = selectedRows;
    },
    handleApproveStatusClick(item) {
      if (item.approveStatus === 'S') {
        return false;
      }

      //기본 설정 값 유효성 체크
      /**
       * 조건
       * 1. 현재 상태가 비활성 상태
       * 2. message 타입이면서 광고전송일시(messageDateTime) 가 설정되어있지않은 경우
       * 3. 또는 광고전송일시가 3시간이상 남은경우
       */
      if (item.type === 'message') {
        // 메시지 전송 시간 계산
        const diff = this.$moment(this.kst).diff(
          this.$moment(`${item.messageDateTime}`).format('YYYY-MM-DD HH:mm'),
          'hours'
        );

        const status = item.messageDateTime === '' ? true : diff > -3;
        if (item.activeStatus === false && status) {
          this.$Swal.fire({
            html: '<h3>유효하지 않은 광고 전송일시 입니다.</h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }

        const diffEndDay = this.$moment(this.kst).diff(
          this.$moment(`${item.displayPeriodEnd}`)
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        );
        // 활성상태, 종료일, 24시간 전을 확인 합니다.
        if (item.activeStatus === true && diffEndDay < 0 && diff > -24) {
          this.$Swal.fire({
            html: '<h3>종료 대기중인 캠페인입니다.</h3>',
            icon: 'error',
            iconHtml: '!',
            confirmButtonText: '확인'
          });
          return false;
        }
      }

      this.selectedCampaign = item;
      this.openApproveStatusDialog();
    },
    // columnShow(column) {
    //   let result = false;

    //   this.headers.map((header) => {
    //     if(header.value === column){
    //       result = true;
    //     }
    //   });

    //   return result;
    // }
    // 모달 닫을때
    async handleCloseClick() {
      if (!this.isSameValue) {
        await this.$Swal
          .fire({
            target: '.v-dialog',
            html: `<h3>변경 내용을 저장하시겠습니까? 저장하지 않으면 변경 내용이 반영되지 않습니다.</h3>`,
            showDenyButton: true,
            denyButtonText: `저장 안함`,
            confirmButtonText: '저장',
            showCancelButton: true,
            cancelButtonText: '취소',
            showLoaderOnConfirm: true,
            icon: 'error',
            iconHtml: '!',
            preConfirm: async () => {
              this.loading = true;
              // 저장
              await this.handleUpdateClick();
            }
          })
          .then(result => {
            if (!result.isDismissed) {
              // 저장, 저장안함 PASS
              this.close();
            }
          });
      } else {
        this.close();
      }
    },
    // 닫기 진행
    close() {
      this.campaigns = [];
      this.summaries = null;
      this.initSelect();

      this.$emit('closeDialog');
    },
    async handleUpdateClick() {
      this.loading = true;

      const newFilter = this.getFilterContentsFormat();

      const [error] = await updateFilterContentsAPI(newFilter.id, {
        filters: newFilter.filters
      });

      if (error) {
        console.log(error);
      } else {
        this.$Swal.fire({
          icon: 'success',
          title: '저장 되었습니다.',
          showConfirmButton: false,
          timer: 1000
        });

        this.originFilter = JSON.parse(JSON.stringify(newFilter));
        this.filters = JSON.parse(JSON.stringify(newFilter));

        // this.$emit('update', newFilter);
        this.$emit('refresh');
      }
      this.loading = false;
    },
    // 필터 등록
    async handleStoreFilterClick() {
      this.$Swal
        .fire({
          target: '.v-dialog',
          html: `<h3>저장하려는 목록 이름을 입력해 주세요.<br/>미입력시 기본이름으로 저장됩니다.</h3>`,
          confirmButtonText: '확인',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          preConfirm: async name => {
            this.loading = true;

            const newFilter = this.getFilterContentsFormat();

            const [error] = await storeFilterApi({
              menuName: 'campaign',
              filters: newFilter.filters,
              columns: this.headers,
              name: name ?? undefined
            });

            if (error) {
              console.log(error);
              this.$Swal.showValidationMessage(`요청 실패`);
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$emit('refresh');
            this.close();
            this.$Swal.fire({
              icon: 'success',
              title: '저장 되었습니다.',
              showConfirmButton: false,
              timer: 1000
            });
          }
        });

      this.loading = false;
    },
    getFilterContentsFormat() {
      const contents = [];

      for (const key in this.filters) {
        const value = this.filters[key];
        if (value.length > 0 && key !== 'dateRanges') {
          contents.push({ key: `${key}`, value: value });
        }
      }

      return { ...this.selectFilter, filters: contents };
    },
    handleTableSortChange({ columnKey, order }) {
      const orderBy = order === 'ascend' ? 'asc' : 'desc';
      const regex = /\s+|_+/g;
      const replacement = '$1 $2';

      const key = columnKey
        .replace(/([a-z])([A-Z])/g, replacement)
        .replace(regex, '_')
        .toLowerCase();

      if (this.order && orderBy === this.order.sort && key === this.order.key) {
        // 필터 해제
        this.order = null;
      } else {
        this.order = {
          key: key,
          sort: orderBy
        };
      }
      this.getCampaignsApi();
    },
    async handlePreviewCampaign(record) {
      this.campaignPreviewDialog = true;
      this.setCampaign(new Campaign(record, true));
    },
    async handleSelectFilterChange(filterId) {
      this.loading = true;

      if (!this.isSameValue) {
        await this.$Swal
          .fire({
            target: '.v-dialog',
            html: `<h3>변경 내용을 저장하시겠습니까? 저장하지 않으면 변경 내용이 반영되지 않습니다.</h3>`,
            showDenyButton: true,
            denyButtonText: `저장 안함`,
            confirmButtonText: '저장',
            showCancelButton: true,
            cancelButtonText: '취소',
            showLoaderOnConfirm: true,
            icon: 'error',
            iconHtml: '!',
            preConfirm: async () => {
              this.loading = true;
              // 저장
              await this.handleUpdateClick();
              this.$emit('changeFilter', filterId);
            }
          })
          .then(result => {
            if (!result.isDismissed) {
              // 저장, 저장안함 PASS
              this.$emit('changeFilter', filterId);
            }
            this.loading = false;
          });
      } else {
        this.$emit('changeFilter', filterId);
        this.loading = false;
      }
    },
    getTargetLocationsTypeText(val) {
      return TargetLocationsTypeList.find(type => type.value === val)?.text;
    }
  }
};
</script>
<style>
.table-striped-rows tr:nth-child(2n) td {
  background-color: #f5f5f5;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
</style>
