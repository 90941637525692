<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-row class="ma-0 justify-end">
            <v-col cols="auto">
              <v-btn color="secondary" :small="$isMobile" @click="openDialog()">
                등록
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <Table
                class="px-3 table-striped-rows"
                style="white-space: nowrap"
                :columns="columns"
                :data-source="dataList"
                bordered
                :loading="loading"
                :scroll="{ x: true }"
                :pagination="{
                  defaultPageSize: 10,
                  position: 'bottom',
                  size: 'large',
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100']
                }"
                size="small"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
                :customRow="
                  record => {
                    return {
                      on: {
                        click: event => {
                          openDialog(record);
                        },
                        mouseenter: event => {
                          hoverItem = record.id;
                        },
                        mouseleave: event => {
                          hoverItem = null;
                        }
                      }
                    };
                  }
                "
              >
                <template slot="isActive" slot-scope="value, record">
                  <div class="d-flex justify-center">
                    <v-switch
                      v-model="record.isActive"
                      color="success"
                      class="mt-0"
                      inset
                      readonly
                      hide-details
                      @click.stop="updateActive(record)"
                    />
                  </div>
                </template>

                <template slot="url" slot-scope="value, record">
                  <v-col style="text-allign: center">
                    <v-skeleton-loader
                      v-if="value"
                      type="image, image"
                      :style="{
                        margin: 'auto',
                        maxHeight: '80px',
                        maxWidth: '80px',
                        backgroundSize: 'contain'
                      }"
                    >
                      <v-img
                        :src="value"
                        :alt="record.fileName || '이미지'"
                        :style="{
                          maxHeight: '80px',
                          maxWidth: '80px',
                          backgroundSize: 'contain'
                        }"
                        class="mx-auto"
                      />
                    </v-skeleton-loader>

                    <template v-else>등록된 이미지가 없습니다.</template>
                  </v-col>
                </template>
                <template slot="createdAt" slot-scope="value">
                  {{ $moment(value).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
              </Table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CouponCategoryDialog v-if="dialog" @refresh="fetchCouponCategories" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import { Pagination } from '@/model/Pagination';
import {
  approveCouponCategoryApi,
  denyCouponCategoryApi,
  getCouponCategoriesAPI
} from '@/api/admin/CampaignAPI';

import CouponCategoryDialog from './components/CouponCategoryDialog.vue';

import { Table } from 'ant-design-vue';

export default {
  components: {
    MenuTitle,
    CouponCategoryDialog,
    Table
  },
  data() {
    return {
      pagination: new Pagination(1, 20),
      hoverItem: null,
      loading: false,
      dataList: []
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'couponCategory/dialog'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'key'
        },
        {
          title: '상태',
          align: 'center',
          dataIndex: 'isActive',
          scopedSlots: {
            customRender: 'isActive'
          }
        },
        {
          title: '이름',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '카테고리 Key',
          align: 'center',
          dataIndex: 'categoryKey'
        },
        {
          title: '이미지',
          align: 'center',
          dataIndex: 'url',
          scopedSlots: {
            customRender: 'url'
          }
        },
        {
          title: '등록일',
          align: 'center',
          dataIndex: 'createdAt',
          scopedSlots: {
            customRender: 'createdAt'
          }
        }
      ];
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchCouponCategories();
    }
  },
  async created() {
    this.init();
  },
  methods: {
    ...mapActions({
      openDialog: 'couponCategory/openDialog'
    }),
    /**
     * @description: 초기화
     * */
    async init() {
      this.dataList = [];
      await this.fetchCouponCategories();
    },
    /**
     * @description: 쿠폰 카테고리 목록 조회
     * */
    async fetchCouponCategories(page = 0) {
      this.loading = true;
      this.pagination.page = page || this.pagination.page;

      const [error, data] = await getCouponCategoriesAPI({
        params: {
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
      } else {
        const { coupon_categories, total_count } = data;

        this.dataList = coupon_categories.map((item, index) => {
          return {
            id: item.id,
            categoryKey: item.category_key,
            isActive: item.is_active,
            name: item.name,
            fileName: item.file_name,
            url: item.url,
            createdAt: item.created_at,
            key:
              index + 1 + (this.pagination.page - 1) * this.pagination.perPage
          };
          // return new AdminTask({
          //   ...item,
          //   key: index
          // });
        });

        const { perPage } = this.pagination;

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    },
    /**
     * @description: 쿠폰 카테고리 관리 Dialog 닫기
     * */
    handleCloseDialog() {
      this.isStatusDialog = false;
      this.selectRecord = null;
      this.statusList = null;
    },
    /**
     * @description: 쿠폰 카테고리 관리 상태 변경
     * */
    updateActive(item) {
      // 전체 보기
      if (item.id === 1) {
        this.$Swal.fire({
          icon: 'error',
          html: `<h3>해당 카테고리는 상태 변경이 불가능 합니다.</h3>`,
          showConfirmButton: true
        });
        return;
      }
      const html =
        `<h3>해당 [${item.name}] 쿠폰 카테고리를 <br/> ${
          !item.isActive ? '활성화' : '비활성화'
        } 하시겠습니까?</h3>` +
        `${
          item.isActive
            ? `<v-col>
                  <small>❗️비활성화 시, <b>메가박스 주변 할인 쿠폰에</b><br/>
                  해당 카테고리에 속한 쿠폰이 더이상 노출 되지 않습니다.
                  </small>
                </v-col>`
            : ''
        }`;

      this.$Swal
        .fire({
          html: html,
          icon: 'info',
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            const [error] = !item.isActive
              ? await approveCouponCategoryApi(item.id)
              : await denyCouponCategoryApi(item.id);

            if (error) {
              console.error(error);
              this.$axiosErrorAlert(error?.data?.message || `요청 실패`);
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isActive = !item.isActive;
          }
        });
    }
  }
};
</script>
<style>
.table-striped-rows tr:nth-child(2n) td {
  background-color: #f5f5f5;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
</style>
