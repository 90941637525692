<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn
              depressed
              outlined
              class="mr-1"
              @click="handelSyncSchedule()"
            >
              <v-icon left> mdi-sync </v-icon>
              동기화
            </v-btn>
            <v-btn depressed dark @click="openDialog()"> 캠페인 등록 </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <DidCampaignServiceFilters
            :loading="indexLoading"
            @search="fetchDidCampaignServices"
          />
          <a-table
            :loading="indexLoading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            rowKey="id"
            :columns="columns"
            bordered
            :data-source="didCampaignServices"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleRowClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>
            <template slot="name" slot-scope="value, record">
              <div
                class="sub-title-1 text-truncate"
                style="max-width: 250px; min-width: 200px !important"
              >
                {{ value }}
                <div>
                  <v-tooltip bottom color="rgba(0,0,0,1)">
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        v-on="{ ...onTooltip }"
                        icon
                        small
                        @click.stop="showReportDialogClick(record)"
                      >
                        <v-icon>mdi-chart-areaspline-variant</v-icon>
                      </v-btn>
                    </template>
                    <span class="title">일간 차트</span>
                  </v-tooltip>
                </div>
              </div>
            </template>

            <template slot="totalPoint" slot-scope="value">
              <div class="text-right">
                {{ value ? value.toLocaleString() : 0 }} 원
              </div>
            </template>

            <template slot="didCampaigns" slot-scope="value">
              <div class="text-right">
                {{ value.length.toLocaleString() }} 건
              </div>
            </template>
            <template slot="isExpired" slot-scope="value, record">
              <v-chip
                small
                :color="!value ? 'success' : 'error'"
                outlined
                label
                class="row mx-auto my-0 align-center justify-center"
                style="max-width: 80px"
              >
                {{ !value ? '진행중' : '종료' }}
                <template v-if="record.statusInfo.value === 'C'">
                  ({{ record.statusInfo.text }})
                </template>
              </v-chip>
            </template>

            <template slot="selectCount" slot-scope="value">
              <v-tooltip right v-if="value.length" maxWidth="250">
                <template v-slot:activator="{ on: tooltipOn }">
                  <div v-on="{ ...tooltipOn }" class="text-right">
                    {{ value.length.toLocaleString() }} 건
                  </div>
                </template>
                <span class="text-subtitle-2">
                  <v-chip
                    v-for="(item, index) in value"
                    :key="index"
                    small
                    class="ma-1"
                    color="white"
                  >
                    {{ item }}
                  </v-chip>
                </span>
              </v-tooltip>
              <div v-else class="text-right">0 개</div>
            </template>

            <template slot="statusInfo" slot-scope="value">
              <v-chip small :color="value.color" outlined label>
                {{ value.text }}
              </v-chip>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalCount"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <DidCampaignServiceDialog @refresh="fetchDidCampaignServices" />
    <DidCampaignReportDialog
      v-if="selectedCampaignService"
      :campaignService="selectedCampaignService"
      @close="selectedCampaignService = null"
      @refresh="fetchDidCampaignServices"
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import DidCampaignServiceDialog from './components/dialog/DidCampaignServiceDialog';
import DidCampaignReportDialog from './components/dialog/DidCampaignReportDialog';
import DidCampaignServiceFilters from './components/AdminDidCampaignServiceFilters';
import { DidCampaignService } from '@/model/admin/did';
import {
  getDidCampaignServicesApi,
  getDidCampaignServiceApi
} from '@/api/admin/DidCampaignAPI';
import { Pagination } from '@/model/Pagination';
export default {
  components: {
    MenuTitle,
    DidCampaignServiceDialog,
    DidCampaignReportDialog,
    DidCampaignServiceFilters
  },
  data() {
    return {
      didCampaignServices: [],
      selectedCampaignService: null,
      pagination: new Pagination(1, 20)
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didCampaignService/dialog',
      filterParams: 'didCampaignService/filterParams',
      loading: 'didCampaignService/loading'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '캠페인 명',
          align: 'left',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '광고주 계정',
          align: 'center',
          dataIndex: 'advertiserName',
          scopedSlots: {
            customRender: 'advertiserName'
          }
        },
        {
          title: '송출 횟수',
          align: 'center',
          width: 120,
          dataIndex: 'didCampaigns',
          scopedSlots: {
            customRender: 'didCampaigns'
          }
        },
        {
          title: '매체 그룹',
          align: 'center',
          width: 100,
          dataIndex: 'didMediaGroups',
          scopedSlots: {
            customRender: 'selectCount'
          }
        },
        {
          title: '매체',
          align: 'center',
          width: 100,
          dataIndex: 'branches',
          scopedSlots: {
            customRender: 'selectCount'
          }
        },
        {
          title: '셋톱박스',
          align: 'center',
          width: 100,
          dataIndex: 'settopBoxes',
          scopedSlots: {
            customRender: 'selectCount'
          }
        },
        // {
        //   title: '결제 상태',
        //   align: 'center',
        //   dataIndex: 'statusInfo',
        //   scopedSlots: {
        //     customRender: 'statusInfo'
        //   }
        // },
        {
          title: '진행 상태',
          align: 'center',
          dataIndex: 'isExpired',
          scopedSlots: {
            customRender: 'isExpired'
          }
        },
        // {
        //   title: '결제 예상 금액',
        //   align: 'center',
        //   dataIndex: 'costPoint',
        //   scopedSlots: {
        //     customRender: 'totalPoint'
        //   }
        // },
        {
          title: '결제 예상 금액',
          align: 'center',
          dataIndex: 'totalPoint',
          scopedSlots: {
            customRender: 'totalPoint'
          }
        },
        // {
        //   title: '취소금액',
        //   align: 'center',
        //   dataIndex: 'canclePoint',
        //   scopedSlots: {
        //     customRender: 'totalPoint'
        //   }
        // },
        {
          title: '캠페인 시작일',
          align: 'center',
          dataIndex: 'startDate',
          sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate)
        },
        {
          title: '캠페인 종료일',
          align: 'center',
          dataIndex: 'endDate',
          sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate)
        },
        {
          title: '등록일',
          align: 'center',
          dataIndex: 'createdDate',
          sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
        }
      ];
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchDidCampaignServices();
    }
  },
  mounted() {
    const root = this;
    root.loading.index = false;

    root.fetchDidCampaignServices();
    this.$root.$off('getDidCampaignServicesApiEvent');
    this.$root.$on('getDidCampaignServicesApiEvent', function () {
      root.fetchDidCampaignServices();
    });
  },
  methods: {
    ...mapActions({
      openDialog: 'didCampaignService/openDialog'
    }),
    /**@description: DID 캠페인 목록 조회 */
    async fetchDidCampaignServices() {
      if (this.indexLoading) return;

      this.loading.index = true;
      const { perPage } = this.pagination;

      const [error, data] = await getDidCampaignServicesApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          ...this.filterParams,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_campaign_services, total_count } = data;

        this.didCampaignServices = did_campaign_services.map(resource => {
          return new DidCampaignService(resource);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading.index = false;
    },
    /**@description: DID 캠페인 상세 조회 */
    async handleRowClick(record) {
      if (!record || this.loading.dialog) return;
      this.loading.dialog = true;

      const [error, data] = await getDidCampaignServiceApi(record.id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_campaign_service } = data;
        this.openDialog(did_campaign_service);
      }

      this.loading.dialog = false;
    },
    /**@description: DID 캠페인 스케줄 동기화 */
    async handelSyncSchedule() {
      if (this.loading.dialog) return;

      await this.$Swal
        .fire({
          title: '캠페인 스케줄링 동기화',
          html: `<h3>캠페인 스케줄을 동기화 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          icon: 'question',
          iconHtml: '?',
          preConfirm: async () => {
            this.loading.dialog = true;
            try {
              const response = await this.$axios.get(
                `/admin/did/campaign-sync`
              );
              const { success } = response.data;
              if (success) {
                this.$Swal.fire({
                  icon: 'success',
                  title: '캠페인 스케줄링 동기화',
                  html: `<h3>캠페인 스케줄이 동기화 되었습니다.</h3>`,
                  confirmButtonText: '확인'
                });
              } else {
                console.error('캠페인 스케줄링 오류::', response);
                this.$axiosErrorAlert(
                  '잠시 후 다시 시도해 주세요.(동기화 실패)'
                );
              }
            } catch (error) {
              console.error('캠페인 스케줄링 오류::', error);
              this.$axiosErrorAlert(
                `${
                  error.data?.message ?? '잠시 후 다시 시도해 주세요.'
                }(동기화 실패)`
              );
            } finally {
              this.loading.dialog = false;
            }
          }
        })
        .then(result => {
          if (!result.isDismissed) {
            // 저장, 저장안함 PASS
            this.close();
          }
        });
    },
    /**@description: DID 캠페인 리포트 모달 열기*/
    async showReportDialogClick(record) {
      this.selectedCampaignService = {
        ...record
      };
    }
  }
};
</script>
