export default {
    namespaced: true,

    state: {
        notices: [],
        notice: null,
        label: {
            category: '공지 유형',
            user_group: '공지 유저 그룹',
            content: '공지 내용',
            startDate: '시작일',
            startTime: '시작시간',
            endDate: '종료일',
            endTime: '종료시간'
        },
        dialog: false,
        dialogType: null,
    },
    getters: {
        label(state) {
            return state.label
        },
        notices(state) {
            return state.notices
        },
        notice(state) {
            return state.notice
        },
        dialog(state) {
            return state.dialog
        },
        dialogType(state) {
            return state.dialogType
        },
        isDialogTypeRegister(state) {
            return state.dialogType === 'R'
        },
        isDialogTypeEdit(state) {
            return state.dialogType === 'E'
        }
    },
    mutations: {
        SET_Notice(state, notice) {
            state.notice = notice
        },
        SET_Dialog(state, dialog) {
            state.dialog = dialog
        },
        SET_DialogType(state, dialogType) {
            state.dialogType = dialogType
        },
    },
    actions: {
        setNotice({
            commit
        }, notice) {
            commit('SET_Notice', notice)
        },
        openDialog({
            commit
        }) {
            commit('SET_Dialog', true)
        },
        closeDialog({
            commit,
            dispatch
        }) {
            commit('SET_Dialog', false);
            dispatch('init');
        },
        init({
            commit
        }) {
            const init = {
                title: null,
                category: null,
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                isUrgent: false,
                activeNow: false,
                issetEnd: false
            };
            commit('SET_Notice', init);
        },
        setDialogTypeRegister({
            commit
        }) {
            commit('SET_DialogType', 'R')
        },
        setDialogTypeEdit({
            commit
        }) {
            commit('SET_DialogType', 'E')
        },

        hashing({
            dispatch
        }, notice) {

            const item = {
                id: notice.id,
                title: notice.title,
                category: notice.category,
                content: notice.content,
                group: notice.group,
                startDate: notice.start_at.substr(0, 10),
                startTime: notice.start_at.substr(11, 5),
                endDate: notice.isset_end === true ? notice.end_at.substr(0, 10) : null,
                endTime: notice.isset_end === true ? notice.end_at.substr(11, 5) : null,
                isUrgent: notice.is_urgent,
                issetEnd: notice.isset_end,
            }

            console.log(item);
            dispatch('setNotice', item);
        }
    }
};