<template>
  <v-dialog :value="true" width="900" scrollable persistent eager>
    <v-card flat max-height="90%" width="100%">
      <v-toolbar flat dark>
        <v-toolbar-title
          >{{ campaignService.id }}번
          {{ campaignService.name }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-row dense>
          <v-col cols="12" class="text-right">
            <DatePicker
              ref="datePicker"
              class="ml-auto"
              title="기간"
              :prefix="3"
              :isTextBtn="false"
              :loading="loading"
              :minDay="campaignService.startDate"
              :maxDay="campaignService.endDate"
              @setDateRanges="
                v => {
                  dateRanges = v;
                }
              "
            />
          </v-col>

          <v-col cols="12">
            <v-tabs v-model="tab" style="border-bottom: 2px solid grey">
              <v-tabs-slider color="#999"></v-tabs-slider>

              <v-tab class="grey white--text" active-class="secondary">
                전체
              </v-tab>
              <v-tab class="grey white--text" active-class="secondary">
                셋톱박스별
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" style="min-hieght: 200px">
              <v-tab-item>
                <v-card flat>
                  <v-col cols="12" class="px-4">
                    <v-simple-table
                      v-if="summary && !loading"
                      class="pa-1 elevation-1"
                      style="white-space: nowrap; border: 1px solid #ddd"
                    >
                      <thead>
                        <tr style="">
                          <th class="text-center" style="width: 50px">기간</th>
                          <th class="text-right">노출</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="">
                          <td class="text-center">
                            {{ dateRanges[0] }} ~ {{ dateRanges[1] }}
                          </td>
                          <td class="text-right">
                            {{ summary.imps.toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <DidCampaignReportChart
                    v-if="!loading"
                    title="전체"
                    :stats="stats"
                    :dateRanges="dateRanges"
                  />
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <DidCampaignReportChart
                    v-if="!loading"
                    title="셋톱박스별 차트"
                    :stats="statsDetail"
                    :isDetail="true"
                    :dateRanges="dateRanges"
                  />
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-skeleton-loader
              v-show="loading"
              type="image, image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import DidCampaignReportChart from './DidCampaignReportChart.vue';
export default {
  components: {
    DatePicker,
    DidCampaignReportChart
  },
  props: {
    campaignService: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: true,
      dateRanges: [],
      chartRef: 'bottomSheetChart',
      stats: [],
      statsDetail: [],
      summary: null,
      tab: 0
    };
  },
  watch: {
    async dateRanges(value) {
      if (value) {
        const { stats, stats_detail } = await this.getDidCampaignReportApi();
        this.stats = stats;
        let tempDetail = stats_detail.map(item => {
          const temp = { ...item, id: item.settop_box_id };
          temp[`imps${item.settop_box_id}`] = Number(item.imps);
          return {
            ...temp
          };
        });

        this.statsDetail = tempDetail;

        const imps = stats.reduce((acc, cur) => {
          return acc + Number(cur.imps);
        }, 0);

        this.summary = {
          imps: imps
        };
      }
    }
  },
  methods: {
    async getDidCampaignReportApi() {
      if (this.campaignService == null) {
        return false;
      }
      this.loading = true;
      try {
        const { data } = await this.$axios.get(
          `/did/campaign-service/${this.campaignService.id}/report`,
          {
            params: {
              dateRanges: this.dateRanges
            }
          }
        );
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }
  }
};
</script>

<style></style>
