export class Pagination {
    constructor(currentPage = 1, perPage, hasMorePage, totalCount, totalPage) {
        this.page = currentPage;
        this.perPage = perPage;
        this.hasMorePage = hasMorePage;
        this.totalCount = totalCount;
        this.totalPage = totalPage;
        this.defaultPageSize = perPage;
    }
    get nextPage() {
        return this.page + 1
    }
}