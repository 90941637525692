<template>
  <v-dialog
    v-model="dialog.detail"
    max-width="1200"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>광고주 상세정보</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="px-0">
        <a-tabs
          v-model="tab"
          :tab-position="$isMobile ? 'top' : 'left'"
          :class="$isMobile ? '' : 'my-8'"
          filtered
        >
          <a-tab-pane key="account" tab="기본정보">
            <div :style="{ padding: '0 20px' }">
              <AdvertiserAccount @refresh="$emit('refresh')" />
            </div>
          </a-tab-pane>

          <a-tab-pane key="point" tab="포인트내역">
            <div :style="{ padding: '0 20px' }">
              <AdvertiserPoint />
            </div>
          </a-tab-pane>

          <a-tab-pane key="payment" tab="결제내역">
            <div :style="{ padding: '0 20px' }">
              <AdvertiserPayment />
            </div>
          </a-tab-pane>

          <a-tab-pane key="couponShop" tab="지점관리">
            <div :style="{ padding: '0 20px' }">
              <AdvertiserCouponShop />
            </div>
          </a-tab-pane>
        </a-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AdvertiserAccount from '../detail/AdvertiserAccount';
import AdvertiserPoint from '../detail/AdvertiserPoint';
import AdvertiserPayment from '../detail/AdvertiserPayment';
import AdvertiserCouponShop from '../detail/AdvertiserCouponShop';

export default {
  components: {
    AdvertiserAccount,
    AdvertiserPoint,
    AdvertiserPayment,
    AdvertiserCouponShop
  },
  computed: {
    ...mapGetters({
      dialog: 'advertiser/dialog'
    })
  },
  data() {
    return {
      tab: 'account'
    };
  },
  methods: {
    ...mapActions({
      closeDialog: 'advertiser/closeDialog'
    }),
    close() {
      this.tab = 'account';
      this.closeDialog('detail');
    }
  }
};
</script>
