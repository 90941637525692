<template>
  <div
    :ref="chartRef"
    style="width:100%; height: 350px; max-height:400px;"
  ></div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    dateRanges: {
      type: Array,
      required: true
    },
    stats: {
      type: Array,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: [],
      chartReg: [],
      chart: null,
      chartTypes: [],
      loading: true,
      dayOfWeekString: ['일', '월', '화', '수', '목', '금', '토']
    };
  },
  computed: {
    chartRef() {
      return 'bottomSheetChartRef';
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    stats(value) {
      if (value.length > 0) {
        // console.log(`value`, value);
        this.setChart();
      }
    }
  },
  created() {
    this.initData();
    window.addEventListener('resize', this.resizeChart);
  },
  mounted() {
    this.setChart();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
    this.initChart();
  },
  methods: {
    initChart() {
      if (this.chart != null && this.chart != '' && this.chart != undefined) {
        this.chart.dispose();
      }
    },
    async setChart() {
      const chartData = await this.getChartData();
      this.drawChart(chartData);
    },
    resizeChart() {
      if (this.chart != null && this.chart != undefined) {
        this.chart.resize();
      }
    },
    initData() {
      const chartTypes = [
        {
          text: '노출',
          value: 'impression',
          color: '#F2597F'
        },
        {
          text: '도달',
          value: 'reach',
          color: '#8041D9'
        },
        {
          text: '클릭',
          value: 'click',
          color: '#4374D9'
        },
        {
          text: 'CTR',
          value: 'ctr',
          color: '#47C83E'
        }
      ];
      this.chartTypes = chartTypes;
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },

    async getChartData() {
      this.setDates();
      try {
        this.loading = true;
        const { dates, stats } = this;
        // console.log(stats);
        // 일별 데이터 체크 후 undefined 처리
        const chartData = dates.map(date => {
          const data = stats.filter(v => v.date === date.value);
          if (data.length === 0) {
            return {
              date: date.value,
              impression: 0,
              reach: 0,
              click: 0,
              ctr: 0
            };
          } else {
            const impression = data
              .filter(v => v.type === 'impression')
              .reduce((acc, cur) => {
                return acc + cur.count;
              }, 0);

            const reach = data
              .filter(v => v.type === 'reach')
              .reduce((acc, cur) => {
                return acc + cur.count;
              }, 0);

            const click = data
              .filter(v => v.type === 'click')
              .reduce((acc, cur) => {
                return acc + cur.count;
              }, 0);

            const ctr =
              impression == 0
                ? 0
                : parseFloat(((click / impression) * 100).toFixed(2));

            return {
              date: `${date.value}`,
              impression: impression,
              reach: reach,
              click: click,
              ctr: ctr
            };
          }
        });
        // console.log(`chartData`, chartData);
        return chartData;
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    drawChart(chartData) {
      this.initChart();
      // 설정값 셋업
      const { chartTypes, title, $echarts, dates } = this;

      // 차트 생성
      const chartDom = this.$refs[this.chartRef];
      const myChart = $echarts.init(chartDom);

      const colors = chartTypes.map(chart => chart.color);

      const xAxis = [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: dates.map(
            date =>
              `${date.value} ${
                this.dayOfWeekString[this.$moment(date.value).day()]
              }`
          ) // 날짜
        }
      ];

      const legend = {
        data: chartTypes.map(chart => chart.text),
        bottom: 0,
        selected: {}
      };

      const series = [
        {
          name: '노출',
          type: 'bar',
          data: chartData.map(data => data.impression),
          smooth: true,
          itemStyle: {
            color: chartTypes.find(v => v.text === '노출').color
          }

          // label: {
          //   show: !this.isMobile,
          //   position: 'top',
          //   formatter: d => {
          //     // return d.data.toLocaleString();
          //     return d.dataIndex % 2 === 0 ? d.data.toLocaleString() : '';
          //   }
          // }
        },
        {
          name: '도달',
          type: 'bar',
          smooth: true,
          itemStyle: {
            color: chartTypes.find(v => v.text === '도달').color
          },
          data: chartData.map(data => data.reach)
        },
        {
          name: '클릭',
          type: 'bar',
          itemStyle: {
            color: chartTypes.find(v => v.text === '클릭').color
          },
          yAxisIndex: 1,
          data: chartData.map(data => data.click)
        },
        {
          name: 'CTR',
          type: 'bar',
          itemStyle: {
            color: chartTypes.find(v => v.text === 'CTR').color
          },
          showSymbol: false,
          yAxisIndex: 2,
          smooth: true,
          data: chartData.map(data => data.ctr)
        }
      ];

      //최솟값
      // const min = Math.min(
      //   ...[
      //     ...chartData
      //       .filter(v => v.non_message_reach > 0)
      //       .map(v => v.non_message_reach),
      //     ...chartData
      //       .filter(v => v.message_reach > 0)
      //       .map(v => v.message_reach)
      //   ]
      // ).toString();

      const option = {
        title: {
          text: this.showTitle ? this.title : null
        },
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          containLabel: true
        },
        toolbox: {
          right: '20px',
          bottom: '20px',
          feature: {
            dataView: { readOnly: true, title: '로우 데이터' },
            saveAsImage: {
              show: true,
              title: '이미지 저장',
              name: `${title} 차트`
            }
          }
        },
        legend: legend,
        xAxis: xAxis,
        yAxis: [
          {
            type: 'value',
            name: '노출',
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: chartTypes.find(v => v.text === '노출').color
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '클릭',
            position: 'right',
            // max: this.getMax(chartData, 'click'),
            axisLine: {
              show: true,
              lineStyle: {
                color: chartTypes.find(v => v.text === '클릭').color
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: 'CTR',
            offset: 50,
            // max: this.getMin(chartData, 'ctr'),
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: chartTypes.find(v => v.text === 'CTR').color
              }
            },
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: series
      };
      this.chart = myChart;
      option && myChart.setOption(option);
    },

    // 해당 값의 자릿수 단위별 최댓값
    getMax(chartData, type) {
      const max = Math.max(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );

      let digits = 0;
      if (max > 1) {
        digits = Math.pow(10, Math.ceil(max).toString().length);
        return Math.ceil(max / digits) * digits;
      } else if (max > 0 && max < 1) {
        // 소수점 자릿수 구하기
        const subMax = Number(
          max
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      } else {
        return 0;
      }
    },
    // 해당 값의 자릿수 단위별 최솟값
    getMin(chartData, type) {
      const min = Math.min(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );
      let digits = 0;
      if (min >= 1) {
        digits = Math.pow(10, Math.ceil(min).toString().length);
        // console.log(Math.ceil(min / digits) * digits);
        return Math.ceil(min / digits) * digits;
      } else {
        // 소수점 자릿수 구하기
        const subMax = Number(
          min
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      }
    }
  }
};
</script>

<style></style>
