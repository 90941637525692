<template>
  <div class="d-inline-flex align-center">
    <v-icon v-if="menu.icon">{{ menu.icon }}</v-icon>
    <h1 class="ml-2 mb-0">{{ menu.text }}</h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      menus: 'menus/menus'
    }),
    menu() {
      const menusItems = this.menus.map(v => v.items).flat(); // 메뉴 그룹의 하위 아이템 목록 변수

      // 메뉴와, 메뉴 아이템을 합친 후 route path 와 비교하여 아이콘과 메뉴이름을 설정합니다.
      return [...this.menus, ...menusItems].find(
        v => v.link === this.$route.path
      );
    }
  }
};
</script>

<style></style>
