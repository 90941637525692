<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-card class="row pa-3 mx-3 my-5 flex-column">
      <v-row class="ma-0">
        <v-col cols="12" md="6" style="height: 600px">
          <h3>선택 매체 ( {{ selectedMedias.length }} )</h3>
          <div class="app-list-wrap" style="max-height: 38%">
            <v-row
              v-if="!selectedMedias.length"
              class="ma-0 col"
              style="height: 100%"
              align="center"
              justify="center"
            >
              <InfoMessage title="선택한 매체 목록이 없습니다." />
            </v-row>
            <v-hover v-for="(media, index) in selectedMedias" :key="index">
              <template v-slot:default="{ hover }">
                <v-chip
                  label
                  color="secondary"
                  outlined
                  class="px-1 ma-1 app-list-item"
                  style="height: 40px"
                >
                  <div class="px-3" style="position: relative">
                    <div class="subtitle-1 font-weight-medium app-title-wrap">
                      {{ media.name }}
                    </div>
                  </div>

                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute opacity="0.9">
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            v-on="{ ...onTooltip }"
                            icon
                            @click="handlePopList('selectedMedias', index)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                        <span>삭제</span>
                      </v-tooltip>
                    </v-overlay>
                  </v-fade-transition>
                </v-chip>
              </template>
            </v-hover>
          </div>

          <h3 class="mt-5">선택 앱 ( {{ selectedApps.length }} )</h3>
          <div class="app-list-wrap" style="max-height: 50%">
            <v-row
              v-if="!selectedApps.length"
              class="ma-0 col"
              style="height: 100%"
              align="center"
              justify="center"
            >
              <InfoMessage title="선택한 앱 목록이 없습니다." />
            </v-row>
            <v-hover v-for="(app, index) in selectedApps" :key="index">
              <template v-slot:default="{ hover }">
                <v-chip
                  label
                  color="secondary"
                  outlined
                  class="px-1 ma-1 app-list-item"
                >
                  <v-img :src="app.image" class="rounded-lg" width="40"></v-img>
                  <div class="px-3" style="position: relative">
                    <div class="subtitle-1 font-weight-medium app-title-wrap">
                      {{ app.name }}
                      <div
                        v-if="app.category && app.installed"
                        class="text-caption"
                      >
                        ({{ app.category }} -
                        {{ app.installed.toLocaleString() }})
                      </div>
                    </div>
                  </div>

                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute opacity="0.9">
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            v-on="{ ...onTooltip }"
                            icon
                            @click="handlePopList('selectedApps', index)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                        <span>삭제</span>
                      </v-tooltip>
                    </v-overlay>
                  </v-fade-transition>
                </v-chip>
              </template>
            </v-hover>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panels">
            <v-expansion-panel
              style="border: 1px solid gray"
              @click.prevent="
                e => (panels = panels === e.target.value ? 0 : e.target.value)
              "
            >
              <v-expansion-panel-header
                disable-icon-rotate
                style="background-color: #f2f2f2"
                :class="'py-1'"
              >
                <h3 class="mb-0 subtitle-1">매체 선택</h3>
                <template v-slot:actions>
                  <v-icon :color="!selectedMedias.length ? 'error' : 'primary'">
                    mdi-{{ !selectedMedias.length ? 'alert-circle' : 'check' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-top: 1px solid #ccc">
                <v-row class="mt-0">
                  <v-col cols="12">
                    <h4>매체 그룹</h4>
                    <v-select
                      v-model="selectedMediaGroups"
                      :items="mediaGroups"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :loading="loading"
                      :disabled="loading"
                      item-text="name"
                      item-value="id"
                      placeholder="매체 그룹을 선택하세요."
                      @change="fetchMedias"
                      chips
                      multiple
                      outlined
                      dense
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="data.index === 0"
                          :input-value="data.selected"
                          outlined
                          label
                          pill
                          style="border: none !important"
                          @click="data.select"
                        >
                          <v-chip>
                            {{ data.item.name }}
                            {{
                              selectedMediaGroups.length > 1
                                ? ` 외 ${selectedMediaGroups.length - 1} 개`
                                : ''
                            }}
                          </v-chip>
                        </v-chip>
                      </template></v-select
                    >

                    <h4>매체</h4>
                    <v-select
                      v-model="selectedMedias"
                      :items="medias"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :loading="loading"
                      :disabled="loading"
                      item-text="name"
                      item-value="id"
                      placeholder="매체를 선택하세요."
                      chips
                      multiple
                      outlined
                      return-object
                      dense
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="data.index === 0"
                          :input-value="data.selected"
                          outlined
                          label
                          pill
                          style="border: none !important"
                          @click="data.select"
                        >
                          <v-chip>
                            {{ data.item.name }}
                            {{
                              selectedMedias.length > 1
                                ? ` 외 ${selectedMedias.length - 1} 개`
                                : ''
                            }}
                          </v-chip>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              style="border: 1px solid gray"
              @click.prevent="
                e => (panels = panels === e.target.value ? 1 : e.target.value)
              "
            >
              <v-expansion-panel-header
                disable-icon-rotate
                style="background-color: #f2f2f2"
                :class="'py-1'"
              >
                <h3 class="mb-0 subtitle-1">앱 선택</h3>
                <template v-slot:actions>
                  <v-icon :color="!selectedApps.length ? 'error' : 'primary'">
                    mdi-{{ !selectedApps.length ? 'alert-circle' : 'check' }}
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-top: 1px solid #ccc">
                <v-row class="mt-0">
                  <v-col cols="12">
                    <h4>앱 카테고리</h4>
                    <v-select
                      :items="appCategories"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :loading="loading"
                      :disabled="loading"
                      item-text="name"
                      item-value="id"
                      placeholder="카테고리를 선택하세요."
                      @change="fetchAppsByCategory"
                      outlined
                      hide-selected
                      dense
                    />

                    <h4>앱 검색 - 개별</h4>
                    <v-autocomplete
                      ref="appsAC"
                      :value="selectedApps"
                      :items="apps"
                      :loading="loading"
                      :search-input.sync="searchText"
                      item-text="name"
                      hide-no-data
                      item-value="package"
                      placeholder="앱 이름 또는 정확한 패키지명을 입력하세요."
                      multiple
                      outlined
                      dense
                      prepend-inner-icon="mdi-magnify"
                      return-object
                      @change="updateApps"
                      @keyup.enter="fetchAppsByName()"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item.name)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          label
                          @click:close="data.parent.selectItem(data.item.name)"
                          color="secondary"
                          class="d-none px-1 py-5"
                          style="height: 60px"
                        >
                          <v-img
                            :src="data.item.image"
                            class="rounded-lg"
                            width="50"
                          ></v-img>
                          <h3 class="px-3">{{ data.item.name }}</h3>
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item === 'object'">
                          <v-list-item-avatar tile>
                            <img
                              v-if="data.item.image"
                              class="rounded-lg"
                              :src="data.item.image"
                              alt="아이콘"
                            />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>

                    <h3>다중 앱 변환</h3>
                    <v-textarea
                      v-model="packages"
                      outlined
                      hide-details
                      :placeholder="
                        '앱 패키지 명을 입력하세요.\npackage1\npackage2\npackage3\n...'
                      "
                      clearable
                    >
                    </v-textarea>
                    <v-btn
                      class="mt-3 mr-auto"
                      color="secondary"
                      outlined
                      :loading="loading"
                      :disabled="convertPackages.length < 1"
                      @click="handleTransformAppsClick"
                    >
                      <v-icon>mdi-atom-variant</v-icon>
                      변환하기
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <TargetCalculatorTable
        :loading="loading"
        :mediaIds="convertMediaIds"
        :appPackages="convertAppPackages"
        @init="initSelectedItems()"
      />
    </v-card>
  </v-container>
</template>
<script>
import { InfoMessage } from '@/components/common';
import MenuTitle from '@/components/MenuTitle.vue';
import TargetCalculatorTable from '@/components/target-calculator/TargetCalculatorTable.vue';
import {
  getAppCategoriesApi,
  getCategoryAppsApi,
  getNameAppsApi,
  transformAppsApi
} from '@/api/common/DataDragonAPI';
import { getMediaGroupsComboAPI, getMediasComboAPI } from '@/api/admin/report';

const PACKAGE_MAX_LENGTH = 100;
export default {
  components: {
    MenuTitle,
    TargetCalculatorTable,
    InfoMessage
  },
  data() {
    return {
      panels: 0,
      mediaGroups: [], // 미디어 그룹 목록
      medias: [], // 미디어 목록
      appCategories: [], // 앱 카테고리 목록
      apps: [], // 카테고리에 할당된 앱 목록
      selectedApps: [], // 선택된 앱 목록
      searchText: '', // 앱 이름으로 검색
      packages: '', // 다중 앱 변환 입력값
      selectedMediaGroups: [], // 선택된 미디어 그룹 목록
      selectedMedias: '', // 선택된 미디어 목록,
      loading: true
    };
  },
  computed: {
    convertPackages() {
      let res = [];
      if (this.packages) {
        res = this.packages.split('\n');
      }
      return res;
    },
    convertAppPackages() {
      let res = [];
      if (this.selectedApps) {
        res = this.selectedApps.map(app => app.package);
      }
      return res;
    },
    convertMediaIds() {
      let res = [];
      if (this.selectedMedias) {
        res = this.selectedMedias.map(media => media.id);
      }
      return res;
    }
  },
  async created() {
    await Promise.all([this.fetchAppCategories(), this.fetchMediaGroups()]);
  },
  methods: {
    // 매체 그룹 목록 조회
    async fetchMediaGroups() {
      this.loading = true;
      this.mediaGroups = [];
      const [error, data] = await getMediaGroupsComboAPI();

      if (error) {
        console.log(error);
      } else {
        const { mediaGroups } = data;
        this.mediaGroups = mediaGroups;
        this.selectedMediaGroups = mediaGroups.map(mediaGroup => mediaGroup.id);
      }

      this.loading = false;

      await this.fetchMedias();
    },
    // 매체 목록 조회
    async fetchMedias() {
      this.medias = [];
      this.selectedMedias = [];
      // 선택된 매체그룹 없으면 return
      if (!this.selectedMediaGroups.length) return;

      this.loading = true;

      const params = {
        params: {
          mediaGroupIds: this.selectedMediaGroups
        }
      };

      const [error, data] = await getMediasComboAPI(params);
      if (error) {
        console.log(error);
      } else {
        const { medias } = data;
        this.medias = medias;
      }
      this.loading = false;
    },

    // 앱 카테고리 목록조회
    async fetchAppCategories() {
      this.loading = true;

      const [error, data] = await getAppCategoriesApi();
      if (error) {
        console.log(error);
      } else {
        const { categories } = data;
        this.appCategories = categories;
      }
      this.loading = false;
    },
    // 카테고리에 해당하는 앱 목록조회
    async fetchAppsByCategory(categoryId) {
      this.loading = true;

      const params = {
        params: {
          categoryId: categoryId
        }
      };

      const [error, data] = await getCategoryAppsApi(params);

      if (error) {
        console.log(error);
      } else {
        const { apps } = data;
        this.selectedApps = [];
        this.updateApps(apps);
      }
      this.loading = false;
    },
    async fetchAppsByName() {
      if (this.loading || !this.searchText) return;

      this.loading = true;
      const params = {
        params: {
          ss: this.searchText
        }
      };

      const [error, data] = await getNameAppsApi(params);

      if (error) {
        console.log(error);
      } else {
        const { apps } = data;
        this.apps = apps;
      }
      this.loading = false;
    },
    // 패키지명 변환 버튼 클릭
    async handleTransformAppsClick() {
      const packages = this.convertPackages;

      if (packages.length > 0 === false) {
        await this.showSwaAlert({
          content: '<h3>최소 1개이상의 앱 패키지를 입력하세요</h3>'
        });
        return;
      }

      this.loading = true;

      const [error, data] = await transformAppsApi({
        packages: packages
      });

      if (error) {
        console.log(error);
      } else {
        const { apps } = data;

        if (!apps) return;

        let content = `<h3>총 ${apps.length}개의 앱이 변환되었습니다.</h3>`;
        // 변환된 앱 이름 목록
        if (apps.length > 0) {
          const ulStyle = `max-height: 100px;  overflow: auto; overflow-x: hidden; background: #eee;`;
          const liStyle = `text-overflow: ellipsis;  
            white-space: nowrap; 
            max-width: 80%; 
            padding:5px; 
            text-align:left; 
            overflow: hidden;`;
          let appList = '';

          apps.forEach((app, index) => {
            appList += `<li style='${liStyle}'> ${index + 1}. ${app.name}</li>`;
          });

          content += `<ul style='${ulStyle}'>${appList}</ul>`;
        }

        this.showSwaAlert({
          icon: 'info',
          content: content
        });

        this.updateApps(apps);
      }

      this.loading = false;
    },
    // 선택된 앱
    updateApps(value) {
      // 중복된 패키지 제거
      const tempApps = [...this.selectedApps, ...value].filter(
        (app, index, self) => {
          return index === self.findIndex(t => t.package === app.package);
        }
      );

      if (tempApps.length > PACKAGE_MAX_LENGTH) {
        this.showSwaAlert({
          content: `<h3>최대 허용 앱 갯수는 ${PACKAGE_MAX_LENGTH}개 입니다.</h3>`
        });
        return;
      }

      this.selectedApps = tempApps;
    },
    // 에러 알림창
    showSwaAlert({ title = '', content = '', icon = 'error', iconHtml = '!' }) {
      this.$Swal.fire({
        title: title,
        html: content,
        icon: icon,
        iconHtml: iconHtml
      });
    },
    // 리스트에서 해당 인덱스 아이템 삭제하기
    handlePopList(listKey, index) {
      this[`${listKey}`].splice(index, 1);
    },
    /**
     * TODO
     * 초기화
     */
    initSelectedItems() {
      this.selectedApps = [];
      this.selectedMedias = [];
    }
  }
};
</script>
<style scoped lang="scss">
.app-list-wrap {
  border: 2px dashed gray;
  border-radius: 10px;
  box-sizing: border-box;
  height: 85%;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;

  .app-list-item {
    height: 50px;

    .app-title-wrap {
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
