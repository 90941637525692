<template>
  <v-row
    class="did-schedule-calendar-wrap mt-0 mb-3"
    style="min-height: 100%; min-width: 620px"
  >
    <v-col cols="12">
      <v-col cols="12" class="row ma-0 mb-3">
        <div class="col col-auto">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
        </div>
        <div class="col row align-center justify-center">
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <div v-if="$refs.calendar" class="col-auto text-h6 font-weight-black">
            {{ currentMonth }}월
          </div>

          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </div>
        <div class="col-auto">
          <v-btn outlined class="mr-4" style="visibility: hidden"> </v-btn>
          <v-btn
            class="ml-2 px-0"
            style="min-width: 40px"
            outlined
            @click="fetchDidSchedules"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="row ma-0 mb-3 align-center">
        <v-calendar
          ref="calendar"
          v-model="focusDate"
          color="primary"
          class="col pa-0"
          style="height: 90%; max-height: 90%"
          :interval-count="0"
          :events="didSchedules"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @change="updateRange"
          :loading="loading"
          :interval-width="0"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        >
          <template v-slot:day-label-header="{ day, date }">
            {{ day }}
            <span
              v-if="eventCouont(date) > 0"
              class="ml-1 grey--text text-caption darken-4 font-weight-bold"
            >
              ( {{ eventCouont(date) }} )
            </span>
            <v-sheet
              v-if="!loading && eventCouont(date) <= 0"
              class="px-3 py-5"
            >
              <v-row class="ma-0">
                <v-col cols="12" class="pb-0">
                  <v-icon> mdi-alert-outline</v-icon>
                </v-col>
                <v-col cols="12" class="subtitle2">
                  등록 된 캠페인이 없습니다.
                </v-col>
              </v-row>
            </v-sheet>
          </template>

          <!-- <template v-slot:event="{ eventSummary }">
          {{ eventSummary }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                :class="`event-item px-1 ${eventParsed.input.color}`"
                v-bind="attrs"
                v-on="on"
              >
                <strong class="mr-1">
                  {{ eventParsed.start.time }}
                </strong>
                {{ eventParsed.input.name }}
              </div>
            </template>
            <span>
              <v-chip class="mr-1" color="white" outlined small dark>
                {{ eventParsed.input.campaign_id }}
              </v-chip>
              {{ eventParsed.input.name }}
            </span>
          </v-tooltip>
        </template> -->
          <template v-slot:event="{ eventParsed }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="`event-item py-1 px-2 ${eventParsed.input.color}`"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-row class="ma-0" align="center">
                    <v-col class="pa-0">
                      <v-chip class="px-1" color="white" outlined x-small dark>
                        {{ eventParsed.input.id }}
                      </v-chip>
                    </v-col>

                    <span class="caption">
                      (순서: {{ eventParsed.input.idx }})
                    </span>
                  </v-row>

                  <div class="pa-0 overflow-eclips">
                    {{ eventParsed.input.name }}
                  </div>
                </div>
              </template>
              <span>
                <v-chip class="mr-1" color="white" outlined dark>
                  {{ eventParsed.input.id }}
                </v-chip>
                {{ eventParsed.input.name }}
              </span>
            </v-tooltip>
          </template>
        </v-calendar>
      </v-col>

      <!-- @description: 클릭: 상세 보기 & 순서 변경 -->
      <template v-if="selectedOpenDialog">
        <v-menu
          v-model="selectedOpenDialog"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="250px"
            max-width="500px"
            flat
          >
            <v-toolbar :color="selectedEvent.color" dark dense>
              <v-chip outlined class="mr-3" small>
                {{ selectedEvent.idx }}
              </v-chip>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>

              <v-btn icon @click="selectedOpenDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-chip outlined class="mr-3" small label>
                캠페인 ID : {{ selectedEvent.id }}
              </v-chip>

              <div v-if="selectedEvent.url">
                <video
                  preload="metadata"
                  :src="`${selectedEvent.url}#t=0.5`"
                  controls
                  class="mx-auto col-12"
                />
              </div>

              <ValidationObserver
                tag="form"
                ref="obs1"
                v-slot="{ invalid }"
                class="row align-end ma-0"
                @submit.prevent="submitCampaignOrder"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|min:0"
                  class="col mr-1"
                >
                  <v-text-field
                    v-model="selectedEvent.crtvLv"
                    type="number"
                    label="재생 우선 순위"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    required
                    style="text-align: right"
                  />
                </ValidationProvider>
                <v-col cols="auto">
                  <v-btn
                    outlined
                    @click="submitCampaignOrder"
                    :disabled="loading || invalid"
                  >
                    저장
                  </v-btn>
                </v-col>
              </ValidationObserver>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-col>

    <template v-if="loading">
      <v-row
        class="calendar-dim fill-height ma-0"
        align="center"
        justify="center"
        background-color="primary"
      >
        <v-progress-circular indeterminate color="secondary lighten-1" />
      </v-row>
    </template>
  </v-row>
</template>
<script>
// import { mapGetters, mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { getDidSchedulesApi } from '@/api/admin/DidScheduleAPI';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      events1: [],
      didSchedules: [],

      loading: false,
      focusDate: '',
      dateRanges: [null, null],
      selectedOpenDialog: false,

      selectedElement: null,
      selectedEvent: {},
      type: 'week',
      currentMonth: '',
      colors: [
        'cyan',
        'cyan darken-3',
        'blue darken-3',
        'blue darken-4',
        'indigo lighten-1',
        'indigo darken-1',
        'purple light-2',
        'purple darken-2',
        'deep-purple darken-4',
        'teal',
        'green light-2',
        'green darken-',
        'indigo darken-4',
        'cyan light-1',
        'cyan darken-4',
        'yellow darken-3',
        'yellow darken-4',
        'amber darken-1',
        'amber darken-3',
        'deep-orange lighten-1',
        'deep-orange darken-1',
        'deep-orange darken-4',
        'deep-orange accent-4',
        'green darken-1',
        'green darken-3',
        'deep-purple lighten-1',
        'deep-purple darken-1',
        'blue darken-1',
        'orange lighten-1',
        'orange darken-2',
        'orange darken-4',
        'teal darken-1',
        'teal darken-4'
      ]
    };
  },
  computed: {
    ...mapGetters({
      selectedData: 'didSchedule/selectedData'
    })
  },
  watch: {
    'selectedData.didSettopBoxId': function (newVal, prev) {
      if (!prev) return;
      this.fetchDidSchedules();
    }
  },
  async mounted() {
    if (this.$refs.calendar) {
      this.currentMonth = this.$refs.calendar.days[0].month;
    }
  },
  methods: {
    // DID 캠페인 목록 조회
    async fetchDidSchedules() {
      if (this.loading) return;
      this.loading = true;

      this.didSchedules = [];
      const params = {
        filters: {
          settopBoxIds: [this.selectedData.didSettopBoxId],
          dates: this.dateRanges
        },
        pagination: 'N',
        isDistinct: true
      };
      // this.loading = false;
      // if (!this.loading) return;
      const [error, data] = await getDidSchedulesApi({ params: params });

      if (error) {
        console.error(error);
      } else {
        const { did_schedules } = data;

        let colorIndex = 0;
        let currentDate = '';
        const campaignIdList = [];
        const events = [];

        did_schedules.map(schedule => {
          colorIndex = campaignIdList.indexOf(schedule.did_campaign_service_id);
          if (colorIndex < 0) {
            campaignIdList.push(schedule.did_campaign_service_id);
            colorIndex = campaignIdList.length - 1;
          }

          currentDate = schedule.schedule_date;
          events.push({
            name: schedule.name,
            start: currentDate,
            end: currentDate,
            color: this.getEventColor(colorIndex),
            timed: true,
            url: schedule.url,
            id: schedule.did_campaign_service_id,
            idx: schedule.crtv_lv ?? 0,
            crtvLv: schedule.crtv_lv ?? 0
          });
        });

        this.didSchedules = events;
      }

      this.loading = false;
    },
    getEventColor(idx) {
      let colorIndex = idx || 0;

      const colorLeng = this.colors.length;
      if (idx / colorLeng >= 1) {
        colorIndex = idx % colorLeng;
      }

      return this.colors[colorIndex];
    },
    setToday() {
      this.focusDate = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    // moveMonth(month) {
    //   console.log(
    //     this.currentMonth,
    //     month,
    //     month - parseInt(this.currentMonth)
    //   );

    //   if (parseInt(this.currentMonth) === month) return;
    //   this.$refs.calendar.move('2022-01-01');
    // },
    showEvent({ nativeEvent, event }) {
      this.selectedOpenDialog = false;
      this.selectedEvent = {};
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // console.log(this.selectedEvent);
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpenDialog = true))
        );
      };

      if (this.selectedOpenDialog) {
        this.selectedOpenDialog = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    eventCouont(date) {
      const res = this.didSchedules.filter(
        item => item && item.start && item.start === date
      );
      return res.length;
    },
    updateRange(date) {
      const { start, end } = date;

      const newVal = this.$moment(`${start.date}`, 'YYYY-MM-DD')
        // .subtract(-1, 'days')
        .format('YYYY-MM-DD');

      if (this.dateRanges[0]) {
        const prevVal = this.$moment(
          `${this.dateRanges[0]}`,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD');

        if (prevVal === newVal) return;
      }

      this.currentMonth = start.month;

      this.dateRanges = [
        newVal,
        this.$moment(`${end.date}`, 'YYYY-MM-DD')
          // .add(7, 'days')
          .format('YYYY-MM-DD')
      ];

      this.fetchDidSchedules();
    },
    // 캠페인 순서 변경
    async submitCampaignOrder() {
      if (this.loading) return;
      this.loading = true;
      const prevIndex = this.didSchedules.findIndex(
        item =>
          item.id === this.selectedEvent.id &&
          item.start === this.selectedEvent.start
      );
      let msg = '';
      if (prevIndex < 0) msg = '존재하지 않는 캠페인입니다.';

      if (
        !msg &&
        this.didSchedules[prevIndex].crtvLv === this.selectedEvent.idx
      )
        msg = '값을 변경해 주세요.';

      if (msg) {
        this.$Swal.fire({
          icon: 'error',
          html: `<h3>${msg}</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소'
        });
        this.loading = false;

        return;
      }

      this.$Swal.fire({
        icon: 'info',
        html: `<h3>${this.selectedEvent.name} 캠페인 순서를 변경 하시겠습니까?</h3>`,
        confirmButtonText: '확인',
        showCancelButton: true,
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          //버튼 동작
          await this.$axios
            .patch(
              `/admin/did/campaign-service/${this.selectedEvent.id}/order`,
              {
                crtvLv: this.selectedEvent.crtvLv
              }
            )
            .then(res => {
              if (res.data.error) {
                throw new Error(res.statusText);
              }

              // 다 바꿔
              this.didSchedules = this.didSchedules
                .map(item => {
                  if (item.id === this.selectedEvent.id) {
                    item.idx = item.crtvLv = this.selectedEvent.crtvLv;
                  }

                  return item;
                })
                .sort((a, b) => a.idx - b.idx);

              this.$Swal.fire({
                icon: 'success',
                html: `<h3>변경 되었습니다.</h3>`,
                confirmButtonText: '확인',
                showCancelButton: true,
                cancelButtonText: '취소'
              });

              return res.data;
            })
            .catch(err => {
              console.dir(err);
              this.$Swal.showValidationMessage(`요청 실패`);
            });
        },
        allowOutsideClick: () => !this.$Swal.isLoading()
      });

      this.loading = false;
    }
  }
};
</script>
<style lang="scss">
.did-schedule-calendar-wrap {
  .v-calendar {
    .v-event {
      height: auto !important;
      margin: 0 auto;
    }
  }
  .overflow-eclips {
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: normal;
    word-break: break-all;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
  }
}
</style>
