<template>
  <v-dialog v-model="dialog" width="800" scrollable persistent eager>
    <template v-slot:activator="{ on: dialogOn }">
      <v-btn
        color="primary"
        text
        v-on="{ ...dialogOn }"
        @click="initDialog()"
        >{{ title }}</v-btn
      >
    </template>
    <ValidationObserver ref="inquiryDialogObs">
      <v-card>
        <v-toolbar flat dark>
          <v-toolbar-title>1:1 문의 등록</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-5 py-3">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-select
              v-model="inquiry.category"
              :error-messages="errors"
              :items="categoryList"
              label="문의 유형"
              required
              outlined
            ></v-select>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-textarea
              v-model="inquiry.content"
              :error-messages="errors"
              label="문의 내용"
              required
              outlined
              clearable
            ></v-textarea>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required|email">
            <v-text-field
              v-model="inquiry.email"
              :error-messages="errors"
              required
              label="연락받을 메일 주소"
              outlined
              clearable
            ></v-text-field>
          </ValidationProvider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="submit()" :loading="loading">
            등록
          </v-btn>
          <v-btn text @click="dialog = false" :loading="loading">
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    title: {
      type: String,
      default: '문의하기'
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      inquiry: {
        category: null,
        content: null,
        email: null
      },
      categoryList: [
        {
          text: '정산',
          value: '정산'
        },
        {
          text: '기타',
          value: '기타'
        }
      ]
    };
  },
  methods: {
    initDialog() {
      const inquiry = {
        category: null,
        content: null,
        email: null
      };
      this.inquiry = inquiry;
      this.$refs.inquiryDialogObs.reset();
    },
    async submit() {
      const valid = await this.$refs.inquiryDialogObs.validate();
      if (valid) {
        this.createInquiryApi();
      }
    },
    async createInquiryApi() {
      this.loading = true;
      try {
        const { status } = await this.$axios.post(`/c/u/inquiry`, this.inquiry);
        if (status === 201) {
          this.$emit('getInquiryApi');
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    }
  }
};
</script>

<style></style>
