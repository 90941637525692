<template>
  <v-dialog
    v-model="dialog.detail"
    max-width="1200"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>매체그룹 상세정보</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <a-tabs v-model="tab" tab-position="left" class="mt-10">
          <a-tab-pane key="account" tab="기본정보">
            <div :style="{ padding: '0 20px' }">
              <media-group-account @refresh="$emit('refresh')" />
            </div>
          </a-tab-pane>

          <a-tab-pane key="medias" tab="매체리스트">
            <div :style="{ padding: '0 20px' }">
              <media-group-media-list @refresh="$emit('refresh')" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MediaGroupAccount from '../detail/MediaGroupAccount';
import MediaGroupMediaList from '../detail/MediaGroupMediaList';

export default {
  components: {
    MediaGroupAccount,
    MediaGroupMediaList,
  },
  computed: {
    ...mapGetters({
      dialog: 'mediaGroup/dialog',
    }),
  },
  data() {
    return {
      tab: 'account',
    };
  },
  methods: {
    ...mapActions({
      closeDialog: 'mediaGroup/closeDialog',
    }),
    close() {
      this.tab = 'account';
      this.closeDialog('detail');
    },
  },
};
</script>
