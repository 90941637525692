<template>
  <v-dialog
    v-if="campaign"
    :value="approveStatusDialog"
    width="600"
    persistent
    scrollable
    eager
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title
          >{{ campaign.id }}번 {{ campaign.name }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3 py-3">
        <v-row>
          <v-col cols="12">
            <v-radio-group
              v-model="selectedApproveStatus"
              class="d-inline-block mt-0"
              label="게재 상태 선택"
            >
              <template v-for="(m, index) in ApproveStatusList">
                <div
                  v-if="!disabledStatusList.includes(m.value)"
                  :key="index"
                  class="ma-3"
                >
                  <v-radio :value="m.value" class="ma-0" :color="m.colorCode">
                    <template v-slot:label>
                      <div>
                        <h4>
                          <v-badge dot inline :color="m.colorCode">
                            {{ m.text }}
                          </v-badge>
                        </h4>
                        <h5>{{ m.comment }}</h5>
                      </div>
                    </template>
                  </v-radio>
                </div>
              </template>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="selectedApproveStatus === 'N'" dense>
          <template v-if="!getApproveDenyReason">
            <v-col cols="12">
              <v-select
                v-model="denyCode"
                label="승인 거절 사유"
                :items="ApproveDenyReasonList"
                item-text="text"
                item-value="value"
                outlined
                @input="setApproveDenyReason"
              ></v-select>
            </v-col>
            <v-col v-if="denyCode === 'D000'" cols="12">
              <v-textarea
                v-model="denyReason"
                outlined
                placeholder="거절 사유를 입력해주세요"
              >
              </v-textarea>
            </v-col>
          </template>
          <v-col v-else cols="12">
            승인 거절 사유
            <p class="col error--text font-weight-bold">
              {{ getApproveDenyReason }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn @click="handleCloseClick" text> 취소 </v-btn>
        <v-btn
          color="primary"
          @click.stop="updateApprovedStatusApi()"
          text
          :loading="loading"
          :disabled="selectedApproveStatus === campaign.approveStatus"
        >
          적용
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ApproveStatusList,
  ApproveDenyReasonList
} from '@/config/admin/campaign';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {},
  props: {
    campaign: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      campaignId: null,
      selectedApproveStatus: null,
      denyCode: null,
      denyReason: null
    };
  },
  computed: {
    ...mapGetters({
      approveStatusDialog: 'campaign/approveStatusDialog'
    }),
    ApproveStatusList() {
      return ApproveStatusList;
    },
    ApproveDenyReasonList() {
      return ApproveDenyReasonList;
    },
    disabledStatusList() {
      let res = [];
      // 승인 처리후에는 종료외에는 상태 변경 불가
      if (this.campaign.approveStatus === 'S') {
        res = ['Y', 'W', 'N'];
      }

      if (this.campaign.approveStatus === 'Y') {
        res = ['W', 'N'];
      }
      return res;
    },
    getApproveDenyReason() {
      return this.campaign.approveDenyReason;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      closeApproveStatusDialog: 'campaign/closeApproveStatusDialog'
    }),
    init() {
      this.selectedApproveStatus = this.campaign.approveStatus;
      this.campaignId = this.campaign.id;
    },
    async updateApprovedStatusApi() {
      this.loading = true;
      try {
        const { data } = await this.$axios.patch(
          `/admin/campaigns/${this.campaignId}/approve`,
          {
            status: this.selectedApproveStatus,
            denyCode: this.denyCode,
            denyReason: this.denyReason
          }
        );

        if (data.success) {
          this.campaign.approveStatus = this.selectedApproveStatus;

          if (this.selectedApproveStatus === 'S') {
            this.campaign.activeStatus = false;
          }

          if (this.selectedApproveStatus === 'Y') {
            this.campaign.activeStatus = true;
          }

          this.campaign.approveDenyReason =
            this.selectedApproveStatus === 'N' ? this.denyReason : '';

          this.$Swal.fire({
            icon: 'success',
            title: '변경사항이 적용되었습니다.',
            confirmButtonText: '확인'
          });
          this.closeApproveStatusDialog();
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    // 거절 사유를 선택합니다.
    setApproveDenyReason(value) {
      this.denyReason = null;
      if (value !== 'D000') {
        // 거절 사유가 직접입력이 아닌경우
        this.denyReason = this.ApproveDenyReasonList.find(
          v => v.value === value
        ).text;
      }
    },
    handleCloseClick() {
      this.closeApproveStatusDialog();
    }
  }
};
</script>

<style></style>
