import axios from 'axios';

// 타겟 회원 조회
export async function getNotificationTargetApi(params) {
  try {
    const { data } = await axios.get('/admin/notification-push/users', params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// crm 내역
export async function getNotificationPsushApi(params) {
  try {
    const { data } = await axios.get('/admin/notification-pushes', params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getNotificationsApi(params) {
  try {
    const { data } = await axios.get('/admin/notifications', params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createNotificationApi(params) {
  try {
    const { data } = await axios.post('/admin/notification', params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateNotificationApi(notificationId, params) {
  try {
    const { data } = await axios.post(
      `/admin/notification/${notificationId}`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function deleteNotificationApi(notificationId) {
  try {
    const { data } = await axios.delete(
      `/admin/notification/${notificationId}`
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}


// 발송내역
export async function getNotificationHistoryApi(params) {
  try {
    const { data } = await axios.get('/admin/notification-push/history', params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createNotificationPushApi(params) {
  try {
    const { data } = await axios.post('/admin/notification-push', params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateNotificationPushApi(notificationId, params) {
  try {
    const { data } = await axios.post(
      `/admin/notification-push/${notificationId}`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateNotificationCancleApi(notificationId, params) {
  try {
    const { data } = await axios.patch(
      `/admin/notification-push/${notificationId}`,
      params
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}
