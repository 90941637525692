<template>
  <v-dialog
    v-model="dialog"
    :confirm-loading="loading"
    @cancel="close"
    :width="800"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>광고주 정보 변경</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 filters">
        <ValidationObserver :ref="refKey">
          <h3 class="font-weight-bold">기본 정보</h3>
          <v-row class="pb-5" dense>
            <CustomGridItem outlined titleClass="col-4">
              <template v-slot:title>
                <div class="title-text">
                  이메일
                  <v-tooltip right>
                    <template #activator="{ on: onTooltip }">
                      <v-btn v-on="{ ...onTooltip }" icon>
                        <v-icon small dense>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>

                    <div class="text-subtitle-2">
                      · 홈텍스에 등록된 이메일 주소를 권장합니다.
                    </div>
                    <div class="text-subtitle-2 whtie--text"></div>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                  <v-text-field
                    v-model="form.email"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="이메일 주소를 입력하세요."
                    required
                    outlined
                    dense
                    @blur="handleEmailInput"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="광고주명" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 100 }"
                >
                  <v-text-field
                    v-model="form.name"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="광고주명"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="전화번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|numeric|min:10|max:11"
                >
                  <v-text-field
                    v-model="form.phone"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="담당자명" outlined :md="6">
              <template v-slot:content>
                <v-text-field
                  v-model="form.managerName"
                  hide-details
                  placeholder="담당자명"
                  outlined
                  dense
                />
              </template>
            </CustomGridItem>

            <CustomGridItem title="담당자 연락처" outlined :md="6">
              <template v-slot:content>
                <v-text-field
                  v-model="form.managerTel"
                  hide-details
                  outlined
                  dense
                />
              </template>
            </CustomGridItem>

            <CustomGridItem title="추천인 코드" outlined :md="6">
              <template v-slot:content>
                <v-text-field
                  v-model="form.referrerCode"
                  hide-details
                  placeholder="추천인 코드"
                  outlined
                  dense
                />
              </template>
            </CustomGridItem>
          </v-row>

          <h3 class="font-weight-bold mt-5">
            회사 정보
            <span class="caption red--text ml-2">
              ※ 사업자등록증에 표기된 내용으로 입력해주세요.
            </span>
          </h3>
          <v-row class="pb-5" dense>
            <CustomGridItem title="대표자 명" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 20 }"
                >
                  <v-text-field
                    v-model="form.businessPresident"
                    :counter="20"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="대표자 명"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <CustomGridItem title="사업자 상호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, max: 20 }"
                >
                  <v-text-field
                    v-model="form.businessName"
                    :counter="20"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="사업자 상호"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="사업자 등록번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|digits:10"
                >
                  <v-text-field
                    v-model="form.businessNum"
                    :counter="10"
                    type="tel"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="업태" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="form.businessType"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="업태"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="업종" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="form.businessCategory"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="업종"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="사업자등록증 사본"
              titleClass="col-4"
              outlined
            >
              <template v-slot:content>
                <v-btn
                  v-if="form.businessImage"
                  color="secondary"
                  small
                  outlined
                  class="ml-3"
                  @click="form.businessImage = null"
                >
                  변경하기
                </v-btn>
                <ImageFileInput
                  v-else
                  fileType="image"
                  fileLabel="사업자등록증 사본"
                  :fileSizeMax="5"
                  :disabled="loading"
                  @setImage="
                    file => {
                      form.businessImageFile = file;
                    }
                  "
                />
              </template>
            </CustomGridItem>

            <CustomGridItem title="사업장 주소" titleClass="col-4" outlined>
              <template v-slot:content>
                <div class="pa-3">
                  <KakaoAddressForm
                    v-if="kakaoAddress"
                    popupKey="user"
                    business
                    popupTitle="사업장 주소 검색"
                    dense
                    :address="kakaoAddress"
                    @submit="
                      value => {
                        form.businessPostcode = value.postcode;
                        form.businessAddress = value.address;
                        form.businessDetailAddress = value.detailAddress;
                        form.businessExtraAddress = value.extraAddress;
                      }
                    "
                  />
                </div>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="세금계산서 이메일"
              outlined
              titleClass="col-4"
            >
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                  <v-text-field
                    v-model="form.businessTaxEmail"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="세금계산서 이메일 주소를 입력하세요."
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>
          <h3 class="font-weight-bold mt-5">결제정보</h3>
          <v-row class="pb-3" dense>
            <CustomGridItem title="은행" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }">
                  <v-select
                    v-model="form.bankName"
                    placeholder="은행 선택"
                    outlined
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="BankList"
                    required
                    dense
                    style="max-width: 300px"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="통장 사본" outlined :md="6">
              <template v-slot:content>
                <v-btn
                  v-if="form.bankbookImage"
                  color="secondary"
                  small
                  outlined
                  class="ml-3"
                  :disabled="loading"
                  @click="form.bankbookImage = null"
                >
                  변경하기
                </v-btn>
                <ImageFileInput
                  v-else
                  fileType="image"
                  fileLabel="통장 사본"
                  :fileSizeMax="5"
                  @setImage="
                    file => {
                      form.bankbookImageFile = file;
                    }
                  "
                />
              </template>
            </CustomGridItem>

            <CustomGridItem title="계좌번호" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider v-slot="{ errors }" rules="numeric">
                  <v-text-field
                    v-model="form.bankAccountNum"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="- 없이 번호만 입력하세요."
                    type="tel"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="예금주" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: false }"
                >
                  <v-text-field
                    v-model="form.bankAccountHolder"
                    :counter="100"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="예금주"
                    required
                    outlined
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="#00d1b2"
                class="white--text"
                @click="submit"
                :loading="loading"
              >
                변경
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import CustomGridItem from '@/components/CustomGridItem.vue';
import KakaoAddressForm from '@/components/KakaoAddressForm.vue';
import ImageFileInput from '@/components/ImageFileInput.vue';
import { UserApproveStatusList } from '@/config/user';
import { BankList } from '@/config/charge';
import { KakaoAddress } from '@/model/register/KakaoAddress';
import { validateEmailApi } from '@/api/common/register';
import { convertFormData } from '@/utils/functions';
import { updateAdvertiserAPI } from '@/api/admin/AdvertiserAPI';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    KakaoAddressForm,
    ImageFileInput,
    CustomGridItem
  },
  props: {
    dialog: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    })
  },
  watch: {
    dialog() {
      this.init();
    }
  },
  data() {
    return {
      loading: false,
      refKey: 'updateForm',
      UserApproveStatusList: UserApproveStatusList.filter(item => !!item.value),
      BankList: BankList,
      kakaoAddress: null,
      form: {},
      orgForm: {}
    };
  },
  mounted() {
    console.log(`advertiser`, this.advertiser);
    this.init(this.advertiser.origin.user ?? null);
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt'
    }),
    init(data) {
      const {
        business_address,
        business_postcode,
        business_detail_address,
        business_extra_address
      } = data;

      this.form = {
        // 기본정보
        status: data?.approve_status_flag || null,
        name: data?.name || null,
        phone: data?.phone || null,
        email: data?.email || null,
        managerName: data?.manager_name || null,
        managerTel: data?.manager_tel || null,
        referrerCode: data?.referrer_code || null,

        // 회사정보
        businessPresident: data?.business_president || null,
        businessName: data?.business_name || null,
        businessNum: data?.business_num || null,
        businessType: data?.business_type || null,
        businessCategory: data?.business_category || null,
        businessPostcode: data?.business_postcode || null,
        businessAddress: data?.business_address || null,
        businessDetailAddress: data?.business_detail_address || null,
        businessExtraAddress: data?.business_extra_address || null,
        businessTaxEmail: data?.business_tax_email || null,
        businessImage: data?.business_image || null,
        businessImageFile: null,

        // 결제 정보
        bankName: data?.bank_name || null,
        bankAccountNum: data?.bank_account_num || null,
        bankAccountHolder: data?.bank_account_holder || null,
        bankbookImage: data?.bankbook_image || null,
        bankbookImageFile: null
      };
      this.orgForm = JSON.parse(JSON.stringify(this.form));

      this.kakaoAddress = new KakaoAddress(
        business_address,
        business_postcode,
        business_detail_address,
        business_extra_address
      );

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    },
    async handleEmailInput(e) {
      const email = e.target.value;

      if (email.length === 0 || email === this.user.email) {
        return false;
      }

      this.loading = true;

      const params = {
        email: this.form.email
      };

      const [error, data] = await validateEmailApi(params);
      if (error) {
        console.error(error);
        this.form.email = null;
      } else {
        const { success, message } = data;
        if (success === false) {
          this.$Swal.fire({
            icon: 'error',
            html: message,
            confirmButtonText: '확인'
          });

          this.form.email = null;
        }
      }
      this.loading = false;
    },
    async submit() {
      const valid = await this.$refs[this.refKey].validate();

      if (!valid) return;

      const reqData = {};

      for (const key in this.form) {
        // 변경된 데이터만 넘깁시다.
        let isSameData = this.form[key] === this.orgForm[key];

        if (key === 'businessImage' || key === 'bankbookImage') {
          isSameData =
            JSON.stringify(this.form[key]) ===
            JSON.stringify(this.orgForm[key]);
        }
        if (isSameData) continue;

        reqData[key] = this.form[key];
      }

      if (Object.entries(reqData).length === 0) {
        this.$Swal.fire({
          icon: 'info',
          html: '변경 내용이 없습니다.',
          confirmButtonText: '확인'
        });
        return;
      }
      await this.updateAdvertiser(this.form);
    },
    async updateAdvertiser(reqData) {
      this.loading = true;

      const [error] = await updateAdvertiserAPI(
        this.advertiser.id,
        convertFormData(reqData)
      );
      if (error) {
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        this.attempt();
        this.$emit('refresh');

        this.$notification['success']({
          message: '변경 성공',
          description: '광고주 정보가 변경 되었습니다.'
        }).then(() => this.close());
      }
      this.loading = false;
    },
    close() {
      this.loading = false;
      this.$emit('close');
    }
  }
};
</script>
