<template>
  <v-card outlined>
    <v-card-title> 예산 </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <alert-message
        class="mt-3"
        border="left"
        icon="mdi-information"
        color="info"
        message="예산 항목은 캠페인 등록 후 변경 할 수 없습니다."
        localStorageKey="budgetInfoAlert"
        dismissible
      />
      <v-row>
        <v-col cols="12" v-if="!campaign.isTypeCoupon">
          <h3>과금 유형</h3>
          <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
            <v-select
              v-model="campaign.costTypes"
              :items="CostTypeList"
              multiple
              item-text="text"
              item-value="id"
              outlined
              :error-messages="errors"
              @change="handleCostTypeChange"
              :disabled="isDialogTypeEdit"
            ></v-select>
          </ValidationProvider>
          <div v-if="campaign.isCostTypeCPMR">
            <h3>
              CPM 도달
              <information-hover
                title="CPM 도달"
                :subTitle="`CPM(Cost Per Mile) - 1,000 회 도달당 비용. <br>최소 ${cpmMin}원 이상 설정해주세요.`"
              />
            </h3>
            <FormattedTextField
              prefix="₩"
              :round="100"
              :required="campaign.isCostTypeCPMR"
              :minValueKrw="cpcMin"
              :value="campaign.cpmr"
              @setAmount="
                amount => {
                  this.campaign.cpmr = amount;
                  validateUserPoint();
                }
              "
              :disabled="isDialogTypeEdit"
            />
          </div>
          <div v-if="campaign.isCostTypeCPMI">
            <h3>
              CPM 노출
              <information-hover
                title="CPM 노출"
                :subTitle="`CPM(Cost Per Mile) - 1,000 회 노출당 비용. <br>최소 ${cpmMin}원 이상 설정해주세요.`"
              />
            </h3>
            <FormattedTextField
              prefix="₩"
              :round="100"
              :required="campaign.isCostTypeCPMI"
              :minValueKrw="cpcMin"
              :value="campaign.cpmi"
              @setAmount="
                amount => {
                  this.campaign.cpmi = amount;
                }
              "
              :disabled="isDialogTypeEdit"
            />
          </div>
          <div v-if="campaign.isCostTypeCPC">
            <h3>
              {{ titles.cpc }}
              <information-hover
                :title="titles.cpc"
                :subTitle="`CPC(Cost Per Click) - 링크 클릭당 평균 비용. <br>최소 ${cpcMin}원 이상 설정해주세요.`"
              />
            </h3>
            <FormattedTextField
              prefix="₩"
              :round="10"
              :required="campaign.isCostTypeCPC"
              :minValueKrw="cpcMin"
              :value="campaign.cpc"
              @setAmount="
                amount => {
                  this.campaign.cpc = amount;
                }
              "
              :disabled="isDialogTypeEdit"
            />
          </div>
          <div v-if="campaign.isTypeMessage">
            <h3>
              {{ titles.advertisingReachCount }}
            </h3>
            <ValidationProvider v-slot="{ errors }" :rules="{ required: true }">
              <v-text-field
                v-model="campaign.advertisingReachCount"
                :error-messages="errors"
                clearable
                required
                outlined
                @change="validateUserPoint"
                :disabled="isDialogTypeEdit"
              ></v-text-field>
            </ValidationProvider>
          </div>

          <div>
            <div v-if="campaign.isTypeDA">
              <h3>
                {{ titles.dailyAdvertisingBudget }}
                <information-hover
                  :title="titles.dailyAdvertisingBudget"
                  subTitle="일 예산은 CPC 비용보다 크거나 같게 설정해야 합니다.<br>일일 광고 지출 금액이 일 예산을 초과하면 광고가 중지되며 매일 자정에 초기화 됩니다."
                />
              </h3>
              <FormattedTextField
                prefix="₩"
                :round="100"
                :minValueKrw="campaign.costMax"
                :value="campaign.dailyAdvertisingBudget"
                @setAmount="
                  amount => {
                    this.campaign.dailyAdvertisingBudget = amount;
                    setBudgetTotal();
                  }
                "
                :disabled="isDialogTypeEdit"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-if="!campaign.isTypeMessage">
          <h3 class="pb-2">
            {{ titles.totalAdvertisingBudget }}
            <information-hover
              :title="titles.totalAdvertisingBudget"
              subTitle="총 예산은 일 예산 보다 크거나 같게 설정해야 합니다.<포인트를>전체 광고 지출 금액이 총 예산을 초과하면 광고가 중지 됩니다."
            />
            <v-tooltip
              right
              color="rgba(0,0,0,1)"
              v-if="issetDisplayPeriodEnd && !campaign.isTypeCoupon"
            >
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  x-small
                  class="pa-2"
                  text
                  @click="setBudgetTotal()"
                  ><v-icon>mdi-calculator </v-icon> 자동계산</v-btn
                >
              </template>
              <span>일정과 일예산에 맞춰 자동계산 됩니다.</span>
            </v-tooltip>
          </h3>
          <FormattedTextField
            prefix="₩"
            :round="100"
            :minValueKrw="campaign.dailyAdvertisingBudget"
            :value="campaign.totalAdvertisingBudget"
            @setAmount="
              amount => {
                this.campaign.totalAdvertisingBudget = amount;
                validateUserPoint();
              }
            "
            :disabled="isDialogTypeEdit"
          />
          <template v-if="campaign.isTypeCoupon">
            <h3 class="mb-0">{{ titles.couponPublish }}</h3>
            <v-card-subtitle class="px-0 py-0 mb-2">
              쿠폰 발행 횟수는 예산 설정시 자동으로 설정됩니다.
            </v-card-subtitle>
            <validation-provider v-slot="{ errors }" rules="required|max:25">
              <v-text-field
                :value="campaign.couponPublish"
                :error-messages="errors"
                disabled
                required
                filled
                suffix="회"
                clearable
              />
            </validation-provider>
          </template>
        </v-col>
        <v-col cols="12">
          <h3 v-if="campaign.willDeposit">
            캠페인 진행 보증금: {{ campaign.willDeposit | KRW }}
          </h3>
          <h3>광고주 계정 잔여 충전금: {{ advertiserBudget | KRW }}</h3>
          <ValidationProvider
            ref="advertiserBudgetInput"
            v-slot="{ errors }"
            :rules="{
              min_value_krw: isDialogTypeEdit ? false : campaign.willDeposit
            }"
          >
            <v-input
              :value="advertiserBudget"
              type="hidden"
              disabled
              readonly
              filled
              hide-details
            ></v-input>
            <alert-message
              v-if="errors.length > 0 || userPoint == 0"
              class="mt-3"
              :value="advertiserBudgetAlert"
              border="left"
              icon="mdi-alert"
              color="red"
              message="광고주 계정 잔여 충전금이 보증금 보다 적어 캠페인을 진행 할 수 없습니다. 예산을 낮추거나 포인트를 충전해주세요."
              localStorageKey="advertiserBudgetAlert"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" v-if="!campaign.isTypeCoupon">
          <div>
            <h3>
              {{ titles.forecastReachCount }}
              <InformationHover
                :title="titles.forecastReachCount"
                :subTitle="`이 수치는 캠페인 집행 전 참고를 위한 지표로, 실제 광고 집행시의 도달 수와 상이 할 수 있으며 추후 변동 될 수 있습니다.`"
              />
              :

              <span v-if="campaign.isTagFilter">
                {{ targetTagsCount.toLocaleString() }} 건
              </span>
              <span v-else>
                <div class="d-inline-block" v-if="!forecastReachCountLoading">
                  {{ forecastReachCount.toLocaleString() }}건
                </div>

                <v-progress-circular
                  v-if="forecastReachCountLoading"
                  indeterminate
                  :width="3"
                  :size="20"
                  color="primary"
                ></v-progress-circular>

                <v-tooltip right v-if="!forecastReachCountLoading">
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      v-on="{ ...onTooltip }"
                      icon
                      x-small
                      @click="updateForecastReachCount()"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </template>
                  <span>새로고침</span>
                </v-tooltip>
              </span>
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Titles } from '@/config/admin/campaign';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import AlertMessage from '@/components/AlertMessage.vue';
import InformationHover from '@/components/InformationHover.vue';
import { getCostTypesApi } from '@/api/common/ConfigAPI';
import { getUserPointApi } from '@/api/admin/UserAPI';
import FormattedTextField from '@/components/FormattedTextField.vue';
export default {
  components: {
    ValidationProvider,
    AlertMessage,
    InformationHover,
    FormattedTextField
  },
  data() {
    return {
      cpcMin: 50,
      cpmMin: 1000,
      placeholder: {
        displayPeriod: [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().add(1, 'month').format('YYYY-MM-DD')
        ]
      },
      formattedAdvertisingReachCount: null,
      forecastReachCount: 0,
      forecastReachCountLoading: false,
      CostTypeList: [],
      formattedCpc: null,
      formattedCpmi: null,
      formattedCpmr: null,
      formattedDailyAdvertisingBudget: null,
      formattedTotalAdvertisingBudget: null,
      userPoint: null
    };
  },

  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      originCampaign: 'campaign/originCampaign',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit'
    }),
    titles() {
      return Titles.rs5.budget;
    },
    advertiserBudget() {
      return this.userPoint || 0;
    },
    oldMediaGroupIds() {
      return this.originCampaign.mediaGroups.map(v => v.id);
    },
    advertiserBudgetAlert() {
      let condition = false;
      if (this.campaign.isTypeMessage) {
        condition = this.advertiserBudget < this.campaign.willDeposit;
      } else {
        condition =
          this.advertiserBudget < this.campaign.totalAdvertisingBudget;
      }
      return condition || this.advertiserBudget === 0;
    },
    issetDisplayPeriodEnd() {
      return this.campaign.issetDisplayPeriodEnd || false;
    },
    isTagFilter() {
      return this.campaign.isTagFilter === true;
    },
    targetTagsCount() {
      const targetTagsCount = this.campaign?.targetTagsCount || 0;
      const targetTagsMode = this.campaign.targetTagsMode;

      if (targetTagsMode === 'T') {
        // 태그 타겟인 경우, 원래 값 리턴
        return targetTagsCount;
      } else if (targetTagsMode === 'D') {
        // 태그 디타겟인 경우, 예상 도달 수 에서 태그타겟 모수 뺀값을 리턴

        const count = this.forecastReachCount - targetTagsCount;
        return count > 0 ? count : 0;
      } else {
        return 0;
      }
    }
  },
  created() {
    this.fetchCostTypes();
    this.fetchUserPoint();
  },
  async mounted() {
    if (
      this.campaign.forecastReachCount &&
      JSON.stringify(this.campaign.mediaGroupIds.sort((a, b) => a - b)) ===
        JSON.stringify(this.oldMediaGroupIds.sort((a, b) => a - b))
    ) {
      this.forecastReachCount = this.campaign?.forecastReachCount || 0;
    } else {
      if (!this.campaign.isTypeCoupon) {
        this.updateForecastReachCount();
      }
    }
  },
  methods: {
    async fetchCostTypes() {
      const [error, data] = await getCostTypesApi();
      if (error) {
        console.error(error);
      } else {
        const { cost_types } = data;

        this.CostTypeList = cost_types.filter(costType => {
          const allowedCampaignTypes = JSON.parse(
            costType.allowed_campaign_types
          );

          return allowedCampaignTypes.includes(this.campaign.type);
        });
      }
    },
    async fetchUserPoint() {
      const [error, data] = await getUserPointApi(this.campaign.advertiser.id);

      if (error) {
        console.error(error);
      } else {
        console.log(data);

        const { total_point } = data;
        this.userPoint = total_point;
      }
    },
    async updateForecastReachCount() {
      const forecastReachCount = await this.getForeacastReachCount();
      this.forecastReachCount = this.campaign.forecastReachCount =
        forecastReachCount;
    },
    setBudgetTotal() {
      // 광고종료일 설정 옵션과 값이 존재하는 경우에만 처리
      if (this.issetDisplayPeriodEnd && this.campaign.displayPeriodEnd) {
        const totalBudget = this.getBudgetTotal();
        this.campaign.totalAdvertisingBudget = totalBudget;
      }
    },
    getBudgetTotal() {
      const { displayPeriodStart, displayPeriodEnd } = this.campaign;
      const startDay = this.$moment(displayPeriodStart);
      const endDay = this.$moment(displayPeriodEnd);

      const diffDay = endDay.diff(startDay, 'days') + 1;
      const dailyBudget = this.campaign?.dailyAdvertisingBudget || 0;

      return dailyBudget * diffDay;
      // return new Intl.NumberFormat('ko-KR', {
      //   style: 'currency',
      //   currency: 'KRW'
      // }).format(dailyBudget * diffDay);
    },
    async getForeacastReachCount() {
      try {
        this.forecastReachCountLoading = true;
        const params = {
          mediaGroupIds: this.campaign.mediaGroupIds
        };
        const { data } = await this.$axios.get(
          `/admin/campaign-forecast-reach`,
          {
            params: params
          }
        );
        return data.forecastReachCount;
      } catch (err) {
        console.error(err);
        return 0;
      } finally {
        this.forecastReachCountLoading = false;
      }
    },
    handleCostTypeChange(val) {
      let len = val.length;
      if (len > 1) {
        this.campaign.costTypes = [val[len - 1]];
      }
      this.campaign.cpc = null;
      this.campaign.cpmr = null;
      this.campaign.cpmi = null;
      this.campaign.advertisingReachCount = null;
    },
    validateUserPoint() {
      this.$refs.advertiserBudgetInput.validate();
    }
  }
};
</script>

<style></style>
