var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-row',{staticClass:"ma-0 justify-end"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"secondary","small":_vm.$isMobile},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v(" 등록 ")])],1)],1),_c('v-row',[_c('v-col',[_c('Table',{staticClass:"px-3 table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"columns":_vm.columns,"data-source":_vm.dataList,"bordered":"","loading":_vm.loading,"scroll":{ x: true },"pagination":{
                defaultPageSize: 10,
                position: 'bottom',
                size: 'large',
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100']
              },"size":"small","locale":{
                emptyText: '데이터가 없습니다.'
              },"customRow":function (record) {
                  return {
                    on: {
                      click: function (event) {
                        _vm.openDialog(record);
                      },
                      mouseenter: function (event) {
                        _vm.hoverItem = record.id;
                      },
                      mouseleave: function (event) {
                        _vm.hoverItem = null;
                      }
                    }
                  };
                }},scopedSlots:_vm._u([{key:"isActive",fn:function(value, record){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"color":"success","inset":"","readonly":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateActive(record)}},model:{value:(record.isActive),callback:function ($$v) {_vm.$set(record, "isActive", $$v)},expression:"record.isActive"}})],1)]}},{key:"url",fn:function(value, record){return [_c('v-col',{staticStyle:{"text-allign":"center"}},[(value)?_c('v-skeleton-loader',{style:({
                      margin: 'auto',
                      maxHeight: '80px',
                      maxWidth: '80px',
                      backgroundSize: 'contain'
                    }),attrs:{"type":"image, image"}},[_c('v-img',{staticClass:"mx-auto",style:({
                        maxHeight: '80px',
                        maxWidth: '80px',
                        backgroundSize: 'contain'
                      }),attrs:{"src":value,"alt":record.fileName || '이미지'}})],1):[_vm._v("등록된 이미지가 없습니다.")]],2)]}},{key:"createdAt",fn:function(value){return [_vm._v(" "+_vm._s(_vm.$moment(value).format('YYYY-MM-DD HH:mm:ss'))+" ")]}}])})],1)],1)],1)],1)],1),(_vm.dialog)?_c('CouponCategoryDialog',{on:{"refresh":_vm.fetchCouponCategories}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }