import axios from 'axios';
import store from '../store';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
    kst: null,
    interval: null
  },

  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
    kst(state) {
      return state.kst
    },
    isSuperAdmin(state) {
      return state.user.role === 'superAdmin'
    }
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    setTimeInterval(
      state, date) {
      state.kst = new Date(date)
      state.interval = setInterval(() => {
        state.kst = new Date(state.kst.getTime() + 1000)
      }, 1000);
    },
    clearTimeInterval(state) {
      clearInterval(state.interval);
      state.interval = null;
    }
  },

  actions: {
    async login({
      dispatch
    }, credentials) {
      store.dispatch('setError', null);

      try {
        const {
          data: {
            success,
            message,
            access_token
          }
        } = await axios.post('/login', credentials);
        if (success) {
          return dispatch('attempt', access_token);
        } else {
          store.dispatch('setError', message);
        }
      } catch (error) {
        store.dispatch('setError', '로그인 정보를 확인해 주세요.');
      }
    },

    async attempt({
      commit,
      state
    }, token) {
      if (token) {
        commit('SET_TOKEN', token);
      }

      if (!state.token) {
        return;
      }

      try {
        const response = await axios.get('/me');
        // Initialize an agent at application startup.
        const fpPromise = FingerprintJS.load();

        // Get the visitor identifier when you need it.
        const fp = await fpPromise
        const result = await fp.get()

        // This is the visitor identifier:
        const visitorId = result.visitorId

        commit('SET_USER', {
          ...response.data,
          visitorId: visitorId
        });
      } catch (error) {
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      }
    },
    async session({
      commit
    }, token) {
      if (token) {
        try {
          const {
            status,
            data: {
              datetime
            }
          } = await axios.get('/session');

          const date = new Date(Date.parse(datetime));
          commit('clearTimeInterval');
          commit('setTimeInterval', date);

          if (status === 401) {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
          }
        } catch (err) {
          commit('SET_TOKEN', null);
          commit('SET_USER', null);
        }
      }
    },
    logout({
      commit
    }) {
      return axios.post('/logout').then(() => {
        // window.location.replace('/login');
        commit('SET_TOKEN', null);
        commit('SET_USER', null);
      });
    }
  }
};