<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn depressed dark @click="openDialog()"> 앱 등록 </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <DidAppFilters
            :filters="filters"
            :loading="indexLoading"
            @search="fetchDidApps"
          />

          <a-table
            :loading="indexLoading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            rowKey="id"
            :columns="columns"
            bordered
            :data-source="didApps"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleRowClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="link" slot-scope="value">
              <div style="max-width: 550px; white-space: normal">
                {{ value }}
              </div>
            </template>

            <template slot="regionDepth2Id" slot-scope="value">
              {{ getRegionText(regionDepth2Id, value) }}
            </template>
            <template slot="regionDepth2Id" slot-scope="value">
              {{ getRegionText(regionDepth2Id, value) }}
            </template>

            <template slot="isActive" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.isActive"
                  color="success"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="handleActiveSwitch(record)"
                ></v-switch>
              </div>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalCount"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <DidAppDialog @refresh="fetchDidApps" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import DidAppDialog from './components/dialog/DidAppDialog';
import DidAppFilters from './components/AdminDidAppFilters';
import { DidApp } from '@/model/admin/did';
import { getDidAppsApi, getDidAppApi } from '@/api/admin/DidAppAPI';
import { Pagination } from '@/model/Pagination';
export default {
  components: {
    MenuTitle,
    DidAppDialog,
    DidAppFilters
  },
  data() {
    return {
      didApps: [],
      pagination: new Pagination(1, 20)
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didApp/dialog',
      filters: 'didApp/filters',
      filterParams: 'didApp/filterParams',
      loading: 'didApp/loading'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '이름',
          align: 'center',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '패키지',
          align: 'center',
          dataIndex: 'package',
          width: 200,
          scopedSlots: {
            customRender: 'package'
          }
        },
        {
          title: '버전',
          align: 'center',
          width: 100,
          dataIndex: 'version'
        },
        {
          title: '링크',
          dataIndex: 'link',
          scopedSlots: {
            customRender: 'link'
          }
        },
        {
          title: '갱신일',
          align: 'center',
          width: 200,
          dataIndex: 'updatedDate'
        }
      ];
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchDidApps();
    }
  },
  mounted() {
    this.loading.index = false;

    this.fetchDidApps();
  },
  methods: {
    ...mapActions({
      openDialog: 'didApp/openDialog'
    }),
    async fetchDidApps() {
      if (this.indexLoading) return;

      this.loading.index = true;
      const { perPage } = this.pagination;

      const [error, data] = await getDidAppsApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          filters: this.filterParams,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { apps, total_count } = data;

        this.didApps = apps.map(app => {
          return new DidApp(app);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading.index = false;
    },
    async handleRowClick(record) {
      if (!record) return;
      this.loading.index = true;

      const [error, data] = await getDidAppApi(record.id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { app } = data;
        this.openDialog(app);
      }

      this.loading.index = false;
    }
  }
};
</script>
