import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';
import { AdminTaskType } from '@/config/admin/admin-task';

export class AdminTask {
  key = null;
  id = null;
  type = null; // 문의사항, 캠페인, 직접입금
  typeId = null; // (문의사항, 캠페인, 직접입금 ID)
  adminId = null; // task 담당 admin
  isRead = null; // 확인 여부
  createdAt = null;

  //  UI에서 필요한 정보
  typeText = null;
  typeDescription = null;
  typeIcon = null;
  link = null;

  ipAddress = null;
  userId = null;
  admin = null;
  user = null;

  constructor(init) {
    if (!init) return;

    const covObject = getCamelKeys(init);
    const initKeys = Object.keys(covObject);

    for (const key in this) {
      if (initKeys.includes(key)) {
        let val = covObject[key];

        if (key === 'createdAt') {
          val = moment(val).format('YYYY.MM.DD HH:mm');
        }

        this[key] = val;
      }
    }

    const taskType = AdminTaskType.find(type => type.value === this.type);
    if (taskType) {
      this.typeText = taskType.text;
      this.typeDescription = taskType.description;
      this.typeIcon = taskType.icon;
      this.link = taskType.link;
      this.color = taskType.color;
    }
  }
}
