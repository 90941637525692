<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <!-- 2023.02.14 요약 내용이 올바르지 않아 주석 -->
    <!-- <h1 class="mt-3">광고 성과 요약</h1>
    <v-row>
      <v-col cols="12" lg="4">
        <v-card>
          <v-card-title>
            <v-list-item-content>
              <v-list-item-title class="title" v-text="`이번달 매출`" />
            </v-list-item-content>
          </v-card-title>
          <v-card-text>
            <div class="text-right">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="green"
              ></v-progress-circular>
              <h1 v-else class=" font-weight-bold">{{ monthSales | KRW }}</h1>
            </div></v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card>
          <v-card-title>
            <v-list-item-content>
              <v-list-item-title class="title" v-text="`전일 매출`" />
            </v-list-item-content>
          </v-card-title>
          <v-card-text>
            <div class="text-right">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="green"
              ></v-progress-circular>
              <h1 v-else class="font-weight-bold">{{ standDaySales | KRW }}</h1>
            </div></v-card-text
          >
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card>
          <v-card-title>
            <v-list-item-content>
              <v-list-item-title class="title" v-text="`정산 가능 금액`" />
            </v-list-item-content>
            <v-list-item-action class="ma-0">
              <v-btn text color="primary" @click="preparing()">
                <span class="text-decoration-underline">정산</span>
              </v-btn>
            </v-list-item-action>
          </v-card-title>
          <v-card-text>
            <div class="text-right">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="green"
              ></v-progress-circular>
              <h1 v-else class=" font-weight-bold">
                {{ settlementAmount | KRW }}
              </h1>
            </div></v-card-text
          >
        </v-card>
      </v-col>
    </v-row> -->

    <v-spacer class="py-3" />
    <h1>광고 매출 통계</h1>
    <v-row>
      <v-col cols="12">
        <MediaHomeCharts
          title=""
          :dateRanges="dateRanges"
          :stats="chartStats"
          :loading="loading"
        />
      </v-col>
    </v-row>
    <v-spacer class="py-3" />

    <v-row>
      <v-col cols="12" lg="6">
        <h1>매체별 매출 요약</h1>
        <v-card
          class="overflow-y-auto"
          min-height="480"
          max-height="480"
          style="background-color: rgba(255, 255, 255, 0)"
          flat
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="6"
              v-for="(media, index) in mediaSummary"
              :key="index"
            >
              <v-card class="ma-3">
                <v-card-title>
                  {{ media.name }}
                </v-card-title>
                <v-card-text>
                  <p class="ma-0">이번달 매출: {{ media.sales | KRW }}</p>
                  <p>
                    노티 라이브:
                    {{ Number(media.reach_count).toLocaleString() }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12">
            <h1>공지사항</h1>
            <v-card
              v-if="!noticeLoading"
              class="pa-3"
              min-height="200"
              max-height="280"
            >
              <Table
                class="table-striped-rows"
                style="white-space: nowrap"
                :columns="columns"
                bordered
                :pagination="false"
                :data-source="notices"
                :locale="{
                  emptyText: '등록된 공지사항이 없습니다.'
                }"
                size="small"
                :customRow="
                  record => {
                    return {
                      on: {
                        click: event => {
                          $router.push({
                            name: 'MediaNotice',
                            query: { nid: record.key }
                          });
                        }
                      }
                    };
                  }
                "
              >
                <template slot="noticeTitle" slot-scope="value, record">
                  <v-chip
                    v-if="record.isUrgent"
                    color="error"
                    small
                    label
                    outlined
                    >긴급</v-chip
                  >
                  {{ value }}
                </template>
              </Table>
            </v-card>
            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12">
            <h1>1:1 문의</h1>
            <v-card
              v-if="!inquiryLoading"
              class="d-flex justify-center pa-5 mb-5 align-center"
              min-height="200"
              max-height="200"
            >
              <v-expansion-panels
                v-if="inquiries.length > 0"
                readonly
                focusable
              >
                <v-expansion-panel
                  v-for="(inquiry, index) in inquiries"
                  :key="index"
                  @click="
                    $router.push({
                      name: 'MediaInquiry',
                      query: { panel: index }
                    })
                  "
                >
                  <v-expansion-panel-header disable-icon-rotate>
                    <div>
                      <h3>
                        {{
                          inquiry.created_at.substr(0, 10).replaceAll('-', '.')
                        }}
                      </h3>
                      <div
                        class="d-inline-block text-truncate"
                        style="max-width: 300px"
                      >
                        {{ inquiry.content }}
                      </div>
                    </div>

                    <template v-slot:actions>
                      <div
                        v-if="inquiry.reply !== null"
                        class="d-inline-flex align-center"
                        style="white-space: nowrap"
                      >
                        <v-icon color="success">
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <span class="ml-2">답변완료</span>
                      </div>
                      <div
                        v-if="inquiry.reply === null"
                        class="d-inline-flex align-center"
                        style="white-space: nowrap"
                      >
                        <v-icon color="secondary"> mdi-circle-slice-6 </v-icon>
                        <span class="ml-2">답변대기</span>
                      </div>
                    </template>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>

              <div v-else>
                <InquiryDialog
                  @getInquiryApi="getInquiryApi()"
                  title="1:1 문의하기"
                />
              </div>
            </v-card>
            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MenuTitle from '@/components/MenuTitle.vue';
import MediaHomeCharts from './components/MediaHomeCharts.vue';
import InquiryDialog from '../inquiry/components/InquiryDialog.vue';
import { Table } from 'ant-design-vue';
import { getInquiriesApi, getNoticesApi } from '@/api/media/UserAPI';

export default {
  components: {
    MenuTitle,
    MediaHomeCharts,
    InquiryDialog,
    Table
  },
  data() {
    return {
      chartStats: [],
      loading: false,
      monthSales: 0,
      standDaySales: 0,
      settlementAmount: 0,
      mediaSummary: [],
      inquiries: [],
      notices: [],
      columns: [
        {
          title: '공지 분류',
          dataIndex: 'category',
          key: 'category',
          width: 80
        },
        {
          title: '공지 일자',
          dataIndex: 'startAt',
          key: 'startAt',
          width: 200
        },
        {
          title: '공지 내용',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {
            customRender: 'noticeTitle'
          }
        },
        {
          title: '',
          dataIndex: 'function',
          key: 'function',
          width: 10
        }
      ],
      noticeLoading: true,
      inquiryLoading: true
    };
  },
  mounted() {
    this.getSalesApi();
    this.fetchInquiries();
    this.fetchNotices();
  },
  computed: {
    dateRanges() {
      return [
        this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
        this.$moment()
          .subtract(1, 'days')
          .subtract(this.$mediaHour, 'hours')
          .format('YYYY-MM-DD')
      ];
    }
  },
  methods: {
    async getSalesApi() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(`/media/dashboard-sales`);
        // console.log(data);
        this.standDaySales = data.stand_day_sales;
        this.monthSales = data.month_sales;
        this.settlementAmount = data.settlement_amount;
        this.chartStats = data.stats;
        this.mediaSummary = data.media_summary;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchInquiries() {
      this.inquiryLoading = true;

      const params = {
        params: {
          page: 1,
          perPage: 2
        }
      };
      const [error, data] = await getInquiriesApi(params);
      if (error) {
        console.error(error);
      } else {
        const { inquiries } = data;
        this.inquiries = inquiries;
      }

      this.inquiryLoading = false;
    },
    async fetchNotices() {
      this.noticeLoading = true;

      const [error, data] = await getNoticesApi();

      if (error) {
        console.error(error);
      } else {
        const { notices } = data;

        this.notices = notices.data.map(notice => {
          return {
            key: notice.id,
            category: notice.category,
            startAt: notice.start_at.substr(0, 10),
            content: notice.content,
            title: notice.title,
            isUrgent: notice.is_urgent
          };
        });
      }

      this.noticeLoading = false;
    },
    preparing() {
      this.$Swal.fire({
        icon: 'info',
        html: '<h3>준비중입니다.</h3>',
        confirmButtonText: '확인'
      });
    }
  }
};
</script>
