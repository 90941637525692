<template>
  <v-row align="center" justify="center" class="mx-auto">
    <v-col cols="12">
      <SearchFilter
        v-if="selectFilter"
        :loading="loading"
        :filter="selectFilter.filters"
        :hiddenOptions="hiddenOptions"
        @applyFilters="applyFilters"
      />
    </v-col>
    <v-col
      cols="12"
      :lg="12 / chartList.length"
      v-for="chart in chartList"
      :key="chart.value"
    >
      <AdminReportCharts
        v-if="selectFilter"
        :campaignType="chart.value"
        :title="chart.text"
        :dateRanges="dateRanges"
      />
    </v-col>

    <v-col cols="12" v-if="!isTypeCoupon">
      <v-card class="pa-2">
        <v-row>
          <v-col>
            <div
              class="d-inline-flex mx-5"
              style="flex-direction: column; align-items: center"
            >
              <v-switch
                v-model="rawDataSwitch"
                inset
                hide-details=""
                color="#F15F5F"
              ></v-switch>

              <v-chip class="mt-1" color="#F15F5F" x-small outlined>
                로우 데이터
              </v-chip>
            </div>
            <div class="d-inline-flex" v-if="isSuperAdmin">
              <v-select
                v-model="adjustImpression"
                label="노출 보정"
                :items="adjustFilterList"
                dense
                outlined
                no-data-text
                hide-details
                :disabled="loading"
              >
              </v-select>
              <v-select
                v-model="adjustReach"
                label="도달 보정"
                :items="adjustFilterList"
                dense
                outlined
                no-data-text
                hide-details
                :disabled="loading"
              >
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-data-table
        v-if="headers && !loading"
        :headers="headers"
        :items="stats"
        item-key="id"
        :loading="loading"
        hide-default-footer
        class="px-3 elevation-2"
        style="white-space: nowrap"
        loading-text="데이터 로딩..."
        no-data-text="데이터가 없습니다."
        mobile-breakpoint="0"
        :server-items-length="pagination.totalCount"
        disable-sort
      >
        <template slot="body.prepend" v-if="!loading && totalStats">
          <tr style="background-color: rgba(244, 244, 244, 1)">
            <td :colspan="totalRowColSpan" class="text-center">전체</td>
            <td v-if="isTypeCoupon" class="text-right">
              {{ totalStats.branchApiCallCount.toLocaleString() }}
            </td>
            <td v-if="isTypeCoupon" class="text-right">
              {{ totalStats.branchPopupCount.toLocaleString() }}
            </td>
            <td class="text-right">
              {{ parseInt(totalStats.impression).toLocaleString() }}
              <div v-if="rawDataSwitch">
                (
                {{
                  parseInt(
                    totalStats.rawImpressionCount * adjustImpression
                  ).toLocaleString()
                }})
              </div>
            </td>
            <td class="text-right">
              {{ parseInt(totalStats.reach).toLocaleString() }}
              <div v-if="rawDataSwitch">
                (
                {{
                  parseInt(
                    totalStats.rawReachCount * adjustReach
                  ).toLocaleString()
                }})
              </div>
            </td>

            <!-- <td v-if="issetLive && !isTypeCoupon" class="text-right">
              {{ totalStats.live.toLocaleString() }}
            </td> -->
            <td class="text-right">
              {{ totalStats.click.toLocaleString() }}
            </td>
            <td class="text-right">
              {{ totalStats.ctr }}
              %
              <div v-if="rawDataSwitch">
                (
                {{
                  adjustImpression === 1
                    ? totalStats.rawImpressionCtr
                    : (
                        (totalStats.click /
                          parseInt(
                            totalStats.rawImpressionCount * adjustImpression
                          )) *
                        100
                      ).toFixed(3)
                }}
                %)
              </div>
            </td>
            <td class="text-right">
              {{ totalStats.ctrReach }} %

              <div v-if="rawDataSwitch">
                (
                {{
                  adjustReach === 1
                    ? totalStats.rawReachCtr
                    : (
                        (totalStats.click /
                          parseInt(totalStats.rawReachCount * adjustReach)) *
                        100
                      ).toFixed(3)
                }}
                %)
              </div>
            </td>
            <!-- <td v-if="issetLive && !isTypeCoupon" class="text-right">
              {{ totalStats.ctrLive }}
              %
            </td> -->
            <td class="text-right">
              {{ totalStats.expenditure | KRW }}
            </td>
            <td v-if="!isTypeCoupon" class="text-right">
              {{ totalStats.cpmiExpenditure | KRW }}
            </td>
            <td v-if="!isTypeCoupon" class="text-right">
              {{ totalStats.cpmrExpenditure | KRW }}
            </td>
          </tr>
        </template>

        <template v-slot:[`item.date`]="{ item }">
          <DayOfWeek :date="item.date" />
        </template>
        <template v-slot:[`item.media_id`]="{ item }">
          {{ item.mediaName }}
        </template>
        <template v-slot:[`item.impressionCount`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('impressionCount', index).color"
          >
            {{ getGapIcon('impressionCount', index).icon }}
          </v-icon>

          {{ parseInt(item.impressionCount).toLocaleString() }}
          <div v-if="rawDataSwitch">
            ({{
              parseInt(
                item.rawImpressionCount * adjustImpression
              ).toLocaleString()
            }})
          </div>
        </template>
        <template v-slot:[`item.reachCount`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('reachCount', index).color"
          >
            {{ getGapIcon('reachCount', index).icon }}
          </v-icon>
          {{ parseInt(item.reachCount).toLocaleString() }}
          <div v-if="rawDataSwitch">
            ({{ parseInt(item.rawReachCount * adjustReach).toLocaleString() }})
          </div>
        </template>
        <template v-slot:[`item.branchApiCallCount`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('branchApiCallCount', index).color"
          >
            {{ getGapIcon('branchApiCallCount', index).icon }}
          </v-icon>
          {{ item.branchApiCallCount.toLocaleString() }}
        </template>
        <template v-slot:[`item.branchPopupCount`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('branchPopupCount', index).color"
          >
            {{ getGapIcon('branchPopupCount', index).icon }}
          </v-icon>
          {{ item.branchPopupCount.toLocaleString() }}
        </template>
        <template v-slot:[`item.live`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('live', index).color"
            >{{ getGapIcon('live', index).icon }}</v-icon
          >
          {{ item.live.toLocaleString() }}
        </template>
        <template v-slot:[`item.clickCount`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('clickCount', index).color"
          >
            {{ getGapIcon('clickCount', index).icon }}
          </v-icon>
          {{ item.clickCount.toLocaleString() }}
          <!-- ({{
              item.uniqueClickCount.toLocaleString()
            }}) -->
        </template>
        <template v-slot:[`item.ctr`]="{ item, index }">
          <v-icon v-if="issetGapIcon" :color="getGapIcon('ctr', index).color">{{
            getGapIcon('ctr', index).icon
          }}</v-icon>
          {{ item.ctr }} %
          <div v-if="rawDataSwitch">
            (
            {{
              adjustImpression === 1
                ? item.rawImpressionCtr
                : (
                    (item.clickCount /
                      parseInt(item.rawImpressionCount * adjustImpression)) *
                    100
                  ).toFixed(3)
            }}
            %)
          </div>
        </template>
        <template v-slot:[`item.ctrReach`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('ctrReach', index).color"
            >{{ getGapIcon('ctrReach', index).icon }}</v-icon
          >
          {{ item.ctrReach }} %
          <div v-if="rawDataSwitch">
            (
            {{
              adjustReach === 1
                ? item.rawReachCtr
                : (
                    (item.clickCount /
                      parseInt(item.rawReachCount * adjustReach)) *
                    100
                  ).toFixed(3)
            }}
            %)
          </div>
        </template>
        <template v-slot:[`item.ctrLive`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('ctrLive', index).color"
            >{{ getGapIcon('ctrLive', index).icon }}</v-icon
          >
          {{ item.ctrLive }}
          %
        </template>
        <template v-slot:[`item.expenditure`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('expenditure', index).color"
            >{{ getGapIcon('expenditure', index).icon }}</v-icon
          >
          {{ Number(item.expenditure) | KRW }}
        </template>
        <template v-slot:[`item.cpmiExpenditure`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('cpmiExpenditure', index).color"
            >{{ getGapIcon('cpmiExpenditure', index).icon }}</v-icon
          >
          {{ Number(item.cpmiExpenditure) | KRW }}
        </template>
        <template v-slot:[`item.cpmrExpenditure`]="{ item, index }">
          <v-icon
            v-if="issetGapIcon"
            :color="getGapIcon('cpmrExpenditure', index).color"
            >{{ getGapIcon('cpmrExpenditure', index).icon }}</v-icon
          >
          {{ Number(item.cpmrExpenditure) | KRW }}
        </template>
      </v-data-table>
      <v-skeleton-loader v-else type="table"> </v-skeleton-loader>
    </v-col>

    <v-col cols="12">
      <v-pagination
        v-if="pagination.totalPage > 1"
        v-model="pagination.page"
        :length="pagination.totalPage"
        :total-visible="10"
        color="secondary"
        @input="getTypeReportStatsApi()"
      ></v-pagination>
    </v-col>

    <SpeedDialMenu
      v-if="stats.length"
      :headers="headers"
      :tableOptions="tableOptions"
      :filters="{
        ...filterObjFormat,
        measureStandard: filterMeasureStandard,
        dateRanges: dateRanges
      }"
      :chartList="chartList"
      :issetLive="issetLive"
      :type="type"
    />
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchFilter from './SearchFilter.vue';
import AdminReportCharts from './AdminReportCharts.vue';
import DayOfWeek from '@/components/DayOfWeek.vue';
import SpeedDialMenu from './SpeedDialMenu.vue';
import { getFiltersApi } from '@/api/common/MenusAPI';
import { updateFilterContentsAPI, storeFilterApi } from '@/api/common/MenusAPI';
import {
  getReportStatsTypeAPI,
  getReportStatsCouponAPI
} from '@/api/admin/report';
import { CampaignFilterType } from '@/config/admin/campaign';

export default {
  props: {
    // 데이터 타입 [rolling, push, message]
    type: {
      type: String,
      required: false
    }
  },
  components: {
    AdminReportCharts,
    DayOfWeek,
    SearchFilter,
    SpeedDialMenu
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin'
    }),
    totalRowColSpan() {
      return 1;
    },
    chartList() {
      let resList = [
        {
          text: '이미지',
          value: 'rolling',
          options: ['click', 'impression']
        },
        {
          text: '텍스트',
          value: 'push',
          options: ['click', 'impression']
        },
        {
          text: '메시지',
          value: 'message',
          options: ['click', 'impression']
        },
        {
          text: '쿠폰',
          value: 'coupon',
          options: ['click', 'impression']
        }
      ];

      if (this.type) {
        return resList.filter(v => v.value === this.type);
      } else {
        resList = resList.filter(v => v.value !== 'coupon');
      }

      return resList;
    },
    hiddenOptions() {
      return this.isTypeCoupon
        ? ['campaignTypes', 'mediaGroupIds', 'measureStandard']
        : undefined;
    },
    days() {
      const days = [];
      const dateStart = this.$moment(this.dateRanges[0], 'YYYY-MM-DD');
      const dateEnd = this.$moment(this.dateRanges[1], 'YYYY-MM-DD');
      while (dateEnd.diff(dateStart, 'days') >= 0) {
        days.push(dateStart.format('D'));
        dateStart.add(1, 'days');
      }
      return days;
    },
    // 필터 측정기준
    filterMeasureStandard() {
      return (
        this.filters.find(v => v.key === 'measureStandard')?.value[0] || 'date'
      );
    },
    issetGapIcon() {
      return this.filterMeasureStandard === 'date';
    },
    isTypeCoupon() {
      return this.type === 'coupon';
    },
    issetLive() {
      return this.days.length > 31 ? false : true;
    },
    filterObjFormat() {
      if (!this.filters) return null;

      const obj = {};
      this.filters.map(item => (obj[`${item.key}`] = item.value));

      return obj;
    },
    tableHeader() {
      const couponHeaders = [
        { text: '날짜', value: 'date', width: 150 },
        { text: 'API 호출', value: 'branchApiCallCount', align: 'right' },
        { text: '팝업 노출', value: 'branchPopupCount', align: 'right' },
        { text: '노출', value: 'impressionCount', align: 'right' },
        { text: '도달', value: 'reachCount', align: 'right' },
        { text: '클릭', value: 'clickCount', align: 'right' },
        { text: 'CTR_노출', value: 'ctr', align: 'right' },
        { text: 'CTR_도달', value: 'ctrReach', align: 'right' },
        {
          text: '지출금액(CPC)',
          value: 'expenditure',
          align: 'right'
        }
      ];

      const headers = [
        { text: '날짜', value: 'date', width: 150 },
        { text: '광고 유형', value: 'campaign_type', align: 'center' },
        { text: '매체', value: 'media_id', width: 200, align: 'center' },
        { text: '노출', value: 'impressionCount', align: 'right' },
        { text: '도달', value: 'reachCount', align: 'right' },
        // { text: '라이브', value: 'live', align: 'right' },
        { text: '클릭', value: 'clickCount', align: 'right' },
        { text: 'CTR_노출', value: 'ctr', align: 'right' },
        { text: 'CTR_도달', value: 'ctrReach', align: 'right' },
        // { text: 'CTR_라이브', value: 'ctrLive', align: 'right' },
        {
          text: '지출금액(CPC)',
          value: 'expenditure',
          align: 'right'
        },
        {
          text: '지출금액(CPM 노출)',
          value: 'cpmiExpenditure',
          align: 'right'
        },
        {
          text: '지출금액(CPM 도달)',
          value: 'cpmrExpenditure',
          align: 'right'
        }
      ];

      return this.isTypeCoupon ? couponHeaders : headers;
    }
  },
  data() {
    return {
      selectFilter: null,
      pagination: {
        page: 1,
        perPage: 50,
        totalPage: 1,
        totalCount: 0
      },
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      headers: [],
      filters: null,
      stats: [],
      totalStats: null,
      tableOptions: {},
      adjustReach: 1,
      adjustImpression: 1,
      adjustFilterList: [
        {
          text: '적용안함',
          value: 1
        },
        {
          text: '1/2',
          value: 1 / 2
        },
        {
          text: '1/3',
          value: 1 / 3
        },
        {
          text: '1/4',
          value: 1 / 4
        },
        {
          text: '1/5',
          value: 1 / 5
        },
        {
          text: '1/6',
          value: 1 / 6
        },
        {
          text: '1/7',
          value: 1 / 7
        },
        {
          text: '1/8',
          value: 1 / 8
        }
      ],
      rawDataSwitch: false,
      loading: false
    };
  },
  async created() {
    this.dateRanges = [
      this.$moment(this.day, 'YYYY-MM-DD')
        .subtract(6, 'days')
        .format('YYYY-MM-DD'),
      this.$moment(this.day, 'YYYY-MM-DD').format('YYYY-MM-DD')
    ];
    await this.fetchFiltersApi();
  },
  methods: {
    // 필터 목록 조회 API
    async fetchFiltersApi() {
      this.loading = true;

      const [error, data] = await getFiltersApi({
        params: {
          menuName: 'report',
          name: this.type || 'all',
          pagination: 'N'
        }
      });

      if (error) {
        console.log(error);
      } else {
        const { filters } = data;

        if (filters.length > 0) {
          const filtersText = filters[0].filters.map(
            item =>
              CampaignFilterType.find(type => type.value === item.key).text
          );

          this.filters = [
            ...filters[0].filters,
            { key: 'dateRanges', value: this.dateRanges }
          ];
          this.selectFilter = {
            ...filters[0],
            filters: this.filters,
            filtersText: filtersText,
            createdAt: this.$moment(filters[0].created_at).format('YYYY.MM.DD'),
            updatedAt: this.$moment(filters[0].updated_at).format('YYYY.MM.DD')
          };
        } else {
          this.filters = [
            { key: 'isAdmin', value: [true, false] },
            { key: 'dateRanges', value: this.dateRanges },
            { key: 'measureStandard', value: ['date'] }
          ];
          this.selectFilter = {
            filters: this.filters
          };
        }
      }

      this.loading = false;
    },
    async applyFilters(filters) {
      let convFilter = this.getFilterContentsFormat(filters);
      // 기존 필터내용이랑, 현재 필터랑 동일하면 업뎃 할 필요 없음
      if (JSON.stringify(this.filters) === JSON.stringify(convFilter.filters))
        return;

      this.filters = JSON.parse(JSON.stringify(convFilter.filters));
      if (filters.dateRanges && filters.dateRanges.length > 0) {
        this.dateRanges = filters.dateRanges;
      }
      // 측정기준 변경시 테이블 헤더 변경해야해서
      await this.setHeaders();

      if (!this.filters) {
        // 처음에반 세팅
        // this.originFilter = JSON.parse(JSON.stringify(this.filters));
      } else {
        convFilter = this.getFilterContentsFormat(filters, ['dateRanges']);

        await Promise.all([
          this.selectFilter.id
            ? this.handleUpdateClick(convFilter)
            : this.handleStoreFilterClick(convFilter),
          this.getTypeReportStatsApi(1)
        ]);
      }
    },
    initStats() {
      this.stats = [];
      this.totalStats = null;
    },
    async getTypeReportStatsApi(page = this.pagination.page) {
      this.loading = true;
      this.stats = [];
      this.pagination.page = page;
      const filters = {
        ...this.filterObjFormat,
        dateRanges: this.dateRanges,
        measureStandard: this.filterMeasureStandard || ''
      };

      const params = {
        page: page,
        perPage: this.pagination.perPage,
        tableOptions: this.tableOptions,
        filters: filters,
        campaignType: this.campaignType,
        dateRanges: this.dateRanges
      };

      const [error, data] =
        this.type !== 'coupon'
          ? await getReportStatsTypeAPI({
              params: {
                ...params,
                issetLive: this.issetLive,
                campaignTypes: !this.type
                  ? ['rolling', 'push', 'message']
                  : this.type
              }
            })
          : await getReportStatsCouponAPI({ params: params });

      if (error) {
        console.log(error);
      } else {
        const { stats, totalCount, totalStats } = data;
        this.stats = this.destructing(stats);
        this.totalStats = this.destructingTotal(totalStats);

        const { perPage } = this.pagination;
        const totalPage = parseInt(totalCount / perPage);
        this.pagination.totalCount = totalCount;
        this.pagination.totalPage =
          totalCount % perPage ? totalPage + 1 : totalPage;
      }
      this.loading = false;
    },
    async setHeaders() {
      const hideColums = ['date', 'campaign_type', 'media_id'].filter(
        v => v !== this.filterMeasureStandard
      );
      let header = this.tableHeader;
      header = header.filter(el => !hideColums.includes(el.value));
      if (!this.issetLive) {
        header = header.filter(el => !['live', 'ctrLive'].includes(el.value));
      }

      this.headers = header;
    },
    destructing(items) {
      const chartList = this.chartList;
      const destructingData = items.map(item => {
        return {
          date: item.date,
          campaign_type:
            chartList.find(v => v.value === item.campaign_type)?.text || '',
          media_id: item.media_id || '',
          mediaName: item.media_name || '',
          branchApiCallCount: item.branch_api_call_count ?? 0,
          branchPopupCount: item.branch_popup_count ?? 0,
          impressionCount: item.impression,
          reachCount: item.reach,
          rawImpressionCount: item.raw_impression_count ?? 0,
          rawReachCount: item.raw_reach_count ?? 0,
          rawImpressionCtr: item.raw_impression_ctr,
          rawReachCtr: item.raw_reach_ctr,
          live: item?.live || 0,
          clickCount: item.click,
          uniqueClickCount: item.unique_click,
          ctr: item.ctr,
          ctrReach: item.ctr_reach,
          ctrLive: item?.ctr_live || 0,
          expenditure: item.expenditure,
          cpmiExpenditure: item.cpmi_expenditure,
          cpmrExpenditure: item.cpmr_expenditure
        };
      });
      return destructingData;
    },
    destructingTotal(total) {
      return {
        impression: total.impression,
        reach: total.reach,
        click: total.click,
        uniqueClick: total.unique_click,
        ctr: total.ctr,
        live: total?.live || 0,
        ctrReach: total.ctr_reach,
        ctrLive: total?.ctr_live || 0,
        expenditure: total.expenditure,
        cpmiExpenditure: total.cpmi_expenditure,
        cpmrExpenditure: total.cpmr_expenditure,
        rawImpressionCount: total.raw_impression_count,
        rawReachCount: total.raw_reach_count,
        rawImpressionCtr: total.raw_impression_ctr,
        rawReachCtr: total.raw_reach_ctr,
        branchApiCallCount: total.branch_api_call_count ?? 0,
        branchPopupCount: total.branch_popup_count ?? 0
      };
    },
    getGapIcon(key, index) {
      if (this.stats.length === index + 1) {
        return '';
      }
      if (this.stats[index][key] > this.stats[index + 1][key]) {
        return {
          color: 'red',
          icon: `mdi-menu-up`
        };
      } else if (this.stats[index][key] < this.stats[index + 1][key]) {
        return {
          color: 'primary',
          icon: `mdi-menu-down`
        };
      } else {
        return {
          color: 'black',
          icon: `mdi-equal`
        };
      }
    },
    // 필터 등록
    async handleStoreFilterClick(newFilter) {
      const [error, data] = await storeFilterApi({
        menuName: 'report',
        filters: newFilter.filters,
        columns: this.headers,
        name: this.type ?? 'all'
      });

      if (error) {
        console.log(error);
        this.$Swal.showValidationMessage(`요청 실패`);
      } else {
        this.selectFilter.id = data.id;
      }
    },
    async handleUpdateClick(newFilter) {
      const [error] = await updateFilterContentsAPI(newFilter.id, {
        filters: newFilter.filters
      });

      if (error) {
        console.log(error);
      } else {
        this.selectFilter = JSON.parse(JSON.stringify(newFilter));
        this.filters = JSON.parse(JSON.stringify(newFilter.filters));
      }
    },
    getFilterContentsFormat(filterObj, hiddenKeys = []) {
      const contents = [];

      for (const key in filterObj) {
        const value = filterObj[key];
        if (!hiddenKeys.length || !hiddenKeys.includes(key)) {
          contents.push({ key: `${key}`, value: value });
        }
      }

      return { ...this.selectFilter, filters: contents };
    }
  }
};
</script>

<style></style>
