var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" 예산 ")]),_c('v-divider'),_c('v-card-text',[_c('alert-message',{staticClass:"mt-3",attrs:{"border":"left","icon":"mdi-information","color":"info","message":"예산 항목은 캠페인 등록 후 변경 할 수 없습니다.","localStorageKey":"budgetInfoAlert","dismissible":""}}),_c('v-row',[(!_vm.campaign.isTypeCoupon)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("과금 유형")]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.CostTypeList,"multiple":"","item-text":"text","item-value":"id","outlined":"","error-messages":errors,"disabled":_vm.isDialogTypeEdit},on:{"change":_vm.handleCostTypeChange},model:{value:(_vm.campaign.costTypes),callback:function ($$v) {_vm.$set(_vm.campaign, "costTypes", $$v)},expression:"campaign.costTypes"}})]}}],null,false,4216020770)}),(_vm.campaign.isCostTypeCPMR)?_c('div',[_c('h3',[_vm._v(" CPM 도달 "),_c('information-hover',{attrs:{"title":"CPM 도달","subTitle":("CPM(Cost Per Mile) - 1,000 회 도달당 비용. <br>최소 " + _vm.cpmMin + "원 이상 설정해주세요.")}})],1),_c('FormattedTextField',{attrs:{"prefix":"₩","round":100,"required":_vm.campaign.isCostTypeCPMR,"minValueKrw":_vm.cpcMin,"value":_vm.campaign.cpmr,"disabled":_vm.isDialogTypeEdit},on:{"setAmount":function (amount) {
                this$1.campaign.cpmr = amount;
                _vm.validateUserPoint();
              }}})],1):_vm._e(),(_vm.campaign.isCostTypeCPMI)?_c('div',[_c('h3',[_vm._v(" CPM 노출 "),_c('information-hover',{attrs:{"title":"CPM 노출","subTitle":("CPM(Cost Per Mile) - 1,000 회 노출당 비용. <br>최소 " + _vm.cpmMin + "원 이상 설정해주세요.")}})],1),_c('FormattedTextField',{attrs:{"prefix":"₩","round":100,"required":_vm.campaign.isCostTypeCPMI,"minValueKrw":_vm.cpcMin,"value":_vm.campaign.cpmi,"disabled":_vm.isDialogTypeEdit},on:{"setAmount":function (amount) {
                this$1.campaign.cpmi = amount;
              }}})],1):_vm._e(),(_vm.campaign.isCostTypeCPC)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.cpc)+" "),_c('information-hover',{attrs:{"title":_vm.titles.cpc,"subTitle":("CPC(Cost Per Click) - 링크 클릭당 평균 비용. <br>최소 " + _vm.cpcMin + "원 이상 설정해주세요.")}})],1),_c('FormattedTextField',{attrs:{"prefix":"₩","round":10,"required":_vm.campaign.isCostTypeCPC,"minValueKrw":_vm.cpcMin,"value":_vm.campaign.cpc,"disabled":_vm.isDialogTypeEdit},on:{"setAmount":function (amount) {
                this$1.campaign.cpc = amount;
              }}})],1):_vm._e(),(_vm.campaign.isTypeMessage)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.advertisingReachCount)+" ")]),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","required":"","outlined":"","disabled":_vm.isDialogTypeEdit},on:{"change":_vm.validateUserPoint},model:{value:(_vm.campaign.advertisingReachCount),callback:function ($$v) {_vm.$set(_vm.campaign, "advertisingReachCount", $$v)},expression:"campaign.advertisingReachCount"}})]}}],null,false,268452403)})],1):_vm._e(),_c('div',[(_vm.campaign.isTypeDA)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.dailyAdvertisingBudget)+" "),_c('information-hover',{attrs:{"title":_vm.titles.dailyAdvertisingBudget,"subTitle":"일 예산은 CPC 비용보다 크거나 같게 설정해야 합니다.<br>일일 광고 지출 금액이 일 예산을 초과하면 광고가 중지되며 매일 자정에 초기화 됩니다."}})],1),_c('FormattedTextField',{attrs:{"prefix":"₩","round":100,"minValueKrw":_vm.campaign.costMax,"value":_vm.campaign.dailyAdvertisingBudget,"disabled":_vm.isDialogTypeEdit},on:{"setAmount":function (amount) {
                  this$1.campaign.dailyAdvertisingBudget = amount;
                  _vm.setBudgetTotal();
                }}})],1):_vm._e()])],1):_vm._e(),(!_vm.campaign.isTypeMessage)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.titles.totalAdvertisingBudget)+" "),_c('information-hover',{attrs:{"title":_vm.titles.totalAdvertisingBudget,"subTitle":"총 예산은 일 예산 보다 크거나 같게 설정해야 합니다.<포인트를>전체 광고 지출 금액이 총 예산을 초과하면 광고가 중지 됩니다."}}),(_vm.issetDisplayPeriodEnd && !_vm.campaign.isTypeCoupon)?_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-2",attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.setBudgetTotal()}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-calculator ")]),_vm._v(" 자동계산")],1)]}}],null,false,1310970899)},[_c('span',[_vm._v("일정과 일예산에 맞춰 자동계산 됩니다.")])]):_vm._e()],1),_c('FormattedTextField',{attrs:{"prefix":"₩","round":100,"minValueKrw":_vm.campaign.dailyAdvertisingBudget,"value":_vm.campaign.totalAdvertisingBudget,"disabled":_vm.isDialogTypeEdit},on:{"setAmount":function (amount) {
              this$1.campaign.totalAdvertisingBudget = amount;
              _vm.validateUserPoint();
            }}}),(_vm.campaign.isTypeCoupon)?[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.titles.couponPublish))]),_c('v-card-subtitle',{staticClass:"px-0 py-0 mb-2"},[_vm._v(" 쿠폰 발행 횟수는 예산 설정시 자동으로 설정됩니다. ")]),_c('validation-provider',{attrs:{"rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.campaign.couponPublish,"error-messages":errors,"disabled":"","required":"","filled":"","suffix":"회","clearable":""}})]}}],null,false,3438058644)})]:_vm._e()],2):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.campaign.willDeposit)?_c('h3',[_vm._v(" 캠페인 진행 보증금: "+_vm._s(_vm._f("KRW")(_vm.campaign.willDeposit))+" ")]):_vm._e(),_c('h3',[_vm._v("광고주 계정 잔여 충전금: "+_vm._s(_vm._f("KRW")(_vm.advertiserBudget)))]),_c('ValidationProvider',{ref:"advertiserBudgetInput",attrs:{"rules":{
            min_value_krw: _vm.isDialogTypeEdit ? false : _vm.campaign.willDeposit
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-input',{attrs:{"value":_vm.advertiserBudget,"type":"hidden","disabled":"","readonly":"","filled":"","hide-details":""}}),(errors.length > 0 || _vm.userPoint == 0)?_c('alert-message',{staticClass:"mt-3",attrs:{"value":_vm.advertiserBudgetAlert,"border":"left","icon":"mdi-alert","color":"red","message":"광고주 계정 잔여 충전금이 보증금 보다 적어 캠페인을 진행 할 수 없습니다. 예산을 낮추거나 포인트를 충전해주세요.","localStorageKey":"advertiserBudgetAlert"}}):_vm._e()]}}])})],1),(!_vm.campaign.isTypeCoupon)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.titles.forecastReachCount)+" "),_c('InformationHover',{attrs:{"title":_vm.titles.forecastReachCount,"subTitle":"이 수치는 캠페인 집행 전 참고를 위한 지표로, 실제 광고 집행시의 도달 수와 상이 할 수 있으며 추후 변동 될 수 있습니다."}}),_vm._v(" : "),(_vm.campaign.isTagFilter)?_c('span',[_vm._v(" "+_vm._s(_vm.targetTagsCount.toLocaleString())+" 건 ")]):_c('span',[(!_vm.forecastReachCountLoading)?_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.forecastReachCount.toLocaleString())+"건 ")]):_vm._e(),(_vm.forecastReachCountLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":3,"size":20,"color":"primary"}}):_vm._e(),(!_vm.forecastReachCountLoading)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.updateForecastReachCount()}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1614542274)},[_c('span',[_vm._v("새로고침")])]):_vm._e()],1)],1)])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }