<template>
  <v-dialog
    v-model="dialog.detail"
    max-width="700"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>매체 편집</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <media-store-form
          :dialog="dialog.detail"
          type="detail"
          @refresh="$emit('refresh')"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MediaStoreForm from '../MediaStoreForm';

export default {
  components: {
    MediaStoreForm,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      dialog: 'media/dialog',
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'media/closeDialog',
    }),
    close() {
      this.closeDialog('detail');
    },
  },
};
</script>
