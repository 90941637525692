<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ReportFilter @applyFilters="handleFilterApply" :loading="loading" />
      </v-col>
    </v-row>
    <v-card class="my-3">
      <v-row>
        <v-col>
          <a-table
            class="px-3 table-striped-rows"
            :columns="columns"
            :data-source="tags"
            :loading="loading"
            size="small"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            bordered
            rowKey="tagName"
            :pagination="{
              defaultPageSize: 50,
              position: 'bottom',
              size: 'large',
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100']
            }"
            :scroll="{ x: 800, y: 800 }"
            :customRow="
              record => {
                return {
                  on: {
                    mouseenter: event => {
                      hoverItem = record.id;
                    },
                    mouseleave: event => {
                      hoverItem = null;
                    }
                  }
                };
              }
            "
          >
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`${column.title} 검색`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  e => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                검색
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
              >
                초기화
              </a-button>
            </div>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <template slot="localeString" slot-scope="text">
              {{ text.toLocaleString() }}
            </template>
          </a-table>
        </v-col>
      </v-row>
      <!-- <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getTagsApi()"
        ></v-pagination>
      </v-col>
    </v-row> -->
    </v-card>
    <SpeedDialMenu v-if="!loading" />
  </v-container>
</template>
<script>
import MenuTitle from '@/components/MenuTitle.vue';
import SpeedDialMenu from './components/SpeedDialMenu.vue';
import ReportFilter from './components/ReportFilter.vue';
import { getMediaTagsApi } from '@/api/admin/tag';
import { MediaTag } from '@/model/admin/MediaTag';
export default {
  components: {
    MenuTitle,
    SpeedDialMenu,
    ReportFilter
  },
  data() {
    return {
      tags: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      tagDialog: false,
      columns: [],
      loading: true
    };
  },
  created() {
    this.setHeaders();
  },
  mounted() {
    // this.fetchMediaTags();
  },
  methods: {
    init() {
      this.tags = [];
    },
    setHeaders() {
      const columns = [
        {
          title: '태그 명',
          dataIndex: 'tagName',
          key: 'tagName',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
            record.tagName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          }
        },
        {
          title: '태그 유형',
          dataIndex: 'tagType',
          key: 'tagType'
        },
        {
          title: '최근 30일 모수',
          dataIndex: 'latestCount',
          key: 'latestCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          sorter: (a, b) => a.latestCount - b.latestCount
        },
        {
          title: '전체 모수',
          dataIndex: 'totalCount',
          key: 'totalCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          sorter: (a, b) => a.totalCount - b.totalCount
        }
      ];
      this.columns = columns;
    },
    handleFilterApply(filters) {
      // console.log(filters);
      this.filters = filters;
      this.setHeaders();
      if (filters.mediaIds.length === 0) {
        this.init();
      } else {
        this.fetchMediaTags();
      }
    },
    async fetchMediaTags(page = 1) {
      this.loading = true;
      const params = {
        page: page,
        filters: this.filters
      };
      // console.log(params);
      const [error, data] = await getMediaTagsApi(params);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const { tags } = data;

        this.tags = tags.map((tag, index) => {
          const newTag = new MediaTag();
          const key = `${tag.tag_name}.${index}`;
          newTag.key = key;
          newTag.id = key;
          newTag.tagName = tag.tag_name;
          newTag.tagType = tag.tag_type_name;
          newTag.totalCount = tag.total_count;
          newTag.latestCount = tag.latest_count;
          return newTag;
        });
      }
      this.loading = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    }
  }
};
</script>
