export const Steps = [
  {
    text: '기본 설정',
    value: 1,
    isEditable: false,
    isRules: true
  },
  {
    text: '소재 등록',
    value: 2,
    isEditable: false,
    isRules: true
  },
  {
    text: '타겟 설정',
    value: 3,
    isEditable: false,
    isRules: true
  },
  {
    text: '세부 설정',
    value: 4,
    isEditable: false,
    isRules: true
  },
  {
    text: '일정 및 예산',
    value: 5,
    isEditable: false,
    isRules: true
  }
];

export const CostTypeList = [
  {
    text: 'CPM 노출',
    value: 'cpmi'
  },
  {
    text: 'CPM 도달',
    value: 'cpmr'
  },
  {
    text: 'CPC',
    value: 'cpc'
  }
];

export const AdvertisingCategoryList = [
  {
    text: '건강/운동',
    value: '건강/운동'
  },
  {
    text: '교육',
    value: '교육'
  },
  {
    text: '금융',
    value: '금융'
  },
  {
    text: '날씨',
    value: '날씨'
  },
  {
    text: '뉴스/잡지',
    value: '뉴스/잡지'
  },
  {
    text: '데이트',
    value: '데이트'
  },
  {
    text: '도구',
    value: '도구'
  },
  {
    text: '도서/참고자료',
    value: '도서/참고자료'
  },
  {
    text: '동영상플레이어/편집기',
    value: '동영상플레이어/편집기'
  },
  {
    text: '라이브러리/데모',
    value: '라이브러리/데모'
  },
  {
    text: '라이프스타일',
    value: '라이프스타일'
  },
  {
    text: '만화',
    value: '만화'
  },
  {
    text: '맞춤 설정',
    value: '맞춤 설정'
  },
  {
    text: '부동산/홈 인테리어',
    value: '부동산/홈 인테리어'
  },
  {
    text: '뷰티',
    value: '뷰티'
  },
  {
    text: '비지니스',
    value: '비지니스'
  },
  {
    text: '사진',
    value: '사진'
  },
  {
    text: '생산성',
    value: '생산성'
  },
  {
    text: '소셜',
    value: '소셜'
  },
  {
    text: '쇼핑',
    value: '쇼핑'
  },
  {
    text: '스포츠',
    value: '스포츠'
  },
  {
    text: '식음료',
    value: '식음료'
  },
  {
    text: '엔터테인먼트',
    value: '엔터테인먼트'
  },
  {
    text: '여행 및 지역정보',
    value: '여행 및 지역정보'
  },
  {
    text: '예술/디자인',
    value: '예술/디자인'
  },
  {
    text: '음악/오디오',
    value: '음악/오디오'
  },
  {
    text: '의료',
    value: '의료'
  },
  {
    text: '이벤트',
    value: '이벤트'
  },
  {
    text: '자동차',
    value: '자동차'
  },
  {
    text: '증강 현실',
    value: '증강 현실'
  },
  {
    text: '지도/내비게이션',
    value: '지도/내비게이션'
  },
  {
    text: '출산/육아',
    value: '출산/육아'
  },
  {
    text: '커뮤니케이션',
    value: '커뮤니케이션'
  },
  {
    text: '게임',
    value: '게임'
  },
  {
    text: 'Kids',
    value: 'Kids'
  },
  {
    text: '소상공인',
    value: '소상공인'
  }
];

export const CouponTypeList = [
  {
    text: '다운로드',
    value: 'download'
  },
  {
    text: '링크이동',
    value: 'url'
  }
];

export const Titles = {
  rs1: {
    advertiser: '계정',
    advertiserGroup: '광고 그룹',
    mediaGroup: '매체 그룹',
    campaignName: '캠페인 이름',
    clickType: '광고 URL 연결 유형',
    landingUrl: '광고 URL',
    tracker: '광고 트래커(선택사항)',
    campaignType: '광고 방식'
  },
  rs2: {
    push: {
      clickUrl: '클릭 URL을 입력하세요',
      title: '광고 제목 (노티,인앱광고에 노출)',
      subTitle: '노티 내용',
      category: '광고 카테고리',
      text: '인앱 광고 메시지',
      image: '인앱 광고 이미지 소재 업로드(선택사항)',
      messageImage: '메시지 이미지 소재 업로드(선택사항)',
      icon: '아이콘 이미지 소재 업로드',
      noImage: '선택 할 수 있는 이미지가 없습니다. 이미지를 업로드해주세요.'
    },
    rolling: {
      image: '노티 이미지 소재 업로드',
      noImage: '선택 할 수 있는 이미지가 없습니다. 이미지를 업로드해주세요.'
    },
    coupon: {
      couponShopId: '쿠폰 사용 지점',
      couponDescription: '쿠폰 할인 상세 내용',
      couponPrice: '쿠폰 할인 금액',
      couponExpiresAt: '쿠폰 유효 기간',
      couponBranches: '쿠폰 노출 제휴매장',
      couponType: '쿠폰 타입',
      couponLandingUrl: '광고 URL',
      maxUses: '클릭횟수'
    }
  },
  rs4: {
    impressionTime: '광고 노출 시간',
    impressionFrequency: '광고 노출 우선순위',
    adsLimitInterval: '동일 광고 제한 기간',
    adsGoalCount: '사용자별 클릭수 제한'
  },
  rs5: {
    schedule: {
      messageDateTime: '광고 전송 일시',
      displayPeriodStart: '광고 시작일',
      displayPeriodEnd: '광고 종료일'
    },
    budget: {
      dailyAdvertisingBudget: '일 예산',
      totalAdvertisingBudget: '총 예산',
      cpc: 'CPC',
      cpm: 'CPM',
      forecastReachCount: '예상 도달 수',
      advertisingReachCount: '광고 집행 도달 수',
      couponPublish: '쿠폰 발행 횟수'
    }
  },
  displayPeriod: '일정',
  budget: '일 예산'
};

export const Instructions = {
  rs1: {
    advertiser: '계정을 선택하세요',
    advertiserGroup: '광고 그룹을 선택하세요',
    mediaGroup: '매체 그룹을 선택하세요',
    campaignName: '캠페인 이름을 입력하세요',
    landingUrl: '광고에서 연결될 웹사이트 URL 을 입력하세요',
    campaignType: '광고 방식을 선택하세요',
    clickType: '광고 URL 연결 유형을 설정하세요',
    tracker: '광고 트래커를 선택하세요.'
  },
  rs2: {
    push: {
      clickUrl: 'https://example.com',
      category: '광고의 카테고리를 선택해주세요',
      title: '광고 타이틀을 입력하세요 (최대 20자)',
      subTitle: '광고 문구를 입력하세요 (최대 25자)',
      text: '광고 페이지에 노출될 메시지를 입력해주세요'
    },
    coupon: {
      couponShopId: '쿠폰 사용 지점을 선택해주세요.',
      couponDescription: '품목 및 할인 내용',
      couponPrice: '쿠폰 할인 금액을 입력하세요.',
      couponBranches: '쿠폰 광고가 노출될 매장을 선택하세요.',
      couponType: '쿠폰 광고 클릭시 실행될 이벤트를 선택해주세요.',
      couponLandingUrl: '쿠폰 광고 클릭시 이동할 광고 URL을 입력해주세요.',
      maxUses: '한 계정당 쿠폰 광고 클릭가능 횟수를 입력해주세요.'
    }
  }
};

export const SubTitles = {
  rs4: {
    impressionTime: null,
    impressionFrequency:
      '우선순위는 1~60 까지 설정할 수 있으며, 순위가 높아질 수록 우선 노출됩니다.',
    adsLimitInterval:
      '유저가 광고를 클릭한 경우 해당 디바이스에 며칠간 동일 광고를 내보내지 않을지 설정됩니다.',
    adsGoalCount:
      '설정한 클릭수만큼 클릭이 발생하면 해당 디바이스에는 더 이상 광고를 노출하지 않습니다.'
  }
};

export const CampaignTypeList = [
  {
    text: '텍스트 타입',
    value: 'push',
    comment: '별도의 이미지 없이 간편하게 광고를 만들 수 있습니다.',
    previewImage: require('@/assets/campaign/preview-push.png')
  },
  {
    text: '이미지 타입',
    value: 'rolling',
    comment:
      '이미지로 한눈에 들어오는 광고를 만들 수 있습니다. (이미지 소재 필요)',
    previewImage: require('@/assets/campaign/preview-rolling.png')
  },
  {
    text: '메시지 타입',
    value: 'message',
    comment: '원하는 일시에 타겟에게 광고 메시지를 전송 할 수 있습니다.',
    previewImage: require('@/assets/campaign/preview-message.png')
  },
  {
    text: '쿠폰 타입',
    value: 'coupon',
    comment: '제휴업체에 쿠폰광고를 진행 할 수 있습니다.',
    previewImage: require('@/assets/campaign/preview-coupon.png')
  }
];

export const ApproveStatusList = [
  {
    text: '승인',
    value: 'Y',
    comment: '캠페인이 즉시 활성화 됩니다.',
    colorCode: 'primary'
  },
  {
    text: '대기',
    value: 'W',
    colorCode: 'warning'
  },
  {
    text: '거절',
    value: 'N',
    colorCode: 'error'
  },
  {
    text: '종료',
    value: 'S',
    comment:
      '캠페인이 비활성화 되며 종료시점으로 부터 이틀 뒤 보증금이 반환됩니다.',
    colorCode: 'secondary'
  }
];
export const ApproveDenyReasonList = [
  {
    text: '부적절한 소재 사용',
    value: 'D001'
  },
  {
    text: '직접 입력',
    value: 'D000'
  }
];

export const TargetAppsTypeList = [
  {
    text: '타겟팅',
    value: 'target',
    comment: '선택 한 앱이 설치된 유저에게만 광고가 노출됩니다.',
    color: 'primary'
  },
  {
    text: '디타겟팅',
    value: 'detarget',
    comment: '선택 한 앱이 설치되지 않은 유저에게만 광고가 노출됩니다.',
    color: 'error'
  },
  {
    text: '논타겟팅',
    value: 'nontarget',
    comment: '모든 유저에게 광고가 노출 됩니다.',
    color: 'default'
  }
];
export const TargetTagsTypeList = [
  {
    text: 'AND 필터',
    value: 'A',
    comment:
      '설정한 태그에 모두 속한 유저에게만 광고가 노출됩니다.<br/>OR 필터 보다 적은 유저에게 노출되며  높은 CTR을 기대 할 수 있습니다.<br/><br/>※ 해당 수치는 참고용이며 실제 수치와 상이 할 수 있습니다.'
  },
  {
    text: 'OR 필터',
    value: 'O',
    comment:
      '설정한 태그에 하나이상 속한 유저에게 광고가 노출됩니다.<br/>최대한 많은 유저에게 노출 되며, AND 필터보다 낮은 CTR을 기대 할 수 있습니다.<br/><br/>※ 해당 수치는 참고용이며 실제 수치와 상이 할 수 있습니다.'
  }
];
export const TargetTagsModeList = [
  {
    text: '타겟팅',
    value: 'T',
    comment: '최대 예상 태그 모수에 속한 유저에게 광고를 노출 합니다.'
  },
  {
    text: '디타겟팅',
    value: 'D',
    comment: '최대 예상 태그 모수에 속하지 않은 유저에게 광고를 노출합니다.'
  }
];

export const TargetLocationsTypeList = [
  {
    text: '과거 방문 대상',
    value: 'P', // Past
    title: `광고 설정 시, “과거 방문 대상”이란?`,
    comment: `설정한 위치 범위에 최근 90일 동안 방문한 사람들을 의미합니다.`
  },
  {
    text: '실시간 방문 대상',
    value: 'R', // Realtime
    title: `광고 설정 시, “실시간 방문 대상”이란?`,
    comment: `설정한 위치 범위에 현재 실시간 현재 방문한 사람들을 의미합니다.`
  },
  {
    text: '과거 방문 + 실시간 방문 대상',
    value: 'D',
    title: `광고 설정 시, “과거 방문 + 실시간 방문 대상”이란?`,
    comment: `설정한 위치 범위에 현재 실시간 현재 방문한 사람들과 최근 90일 동안 방문한 사람들을 의미합니다.`
  }
];

export const AdjustFilterList = [
  {
    text: '적용안함',
    value: 1
  },
  {
    text: '1/2',
    value: 1 / 2
  },
  {
    text: '1/3',
    value: 1 / 3
  },
  {
    text: '1/4',
    value: 1 / 4
  },
  {
    text: '1/5',
    value: 1 / 5
  },
  {
    text: '1/6',
    value: 1 / 6
  },
  {
    text: '1/7',
    value: 1 / 7
  },
  {
    text: '1/8',
    value: 1 / 8
  }
];

export const ActiveStatusList = [
  { text: '활성', value: true },
  { text: '비활성', value: false }
];

export const CampaignFilterType = [
  {
    text: '캠페인 생성기간',
    value: 'dateRanges'
  },
  {
    text: '리포트 조회기간',
    value: 'reportDateRanges'
  },
  {
    text: '광고유형',
    value: 'campaignTypes'
  },
  {
    text: '게재',
    value: 'approveStatus'
  },
  {
    text: '상태',
    value: 'activeStatus'
  },
  {
    text: '태그 타겟',
    value: 'tagFilter'
  },
  {
    text: '광고주 계정',
    value: 'advertiserIds'
  },
  {
    text: '광고그룹',
    value: 'advertiserGroupIds'
  },
  {
    text: '캠페인',
    value: 'campaignIds'
  },
  {
    text: '매체그룹',
    value: 'mediaGroupIds'
  },
  {
    text: '캠페인 생성소',
    value: 'isAdmin'
  },
  {
    text: '광고주 유형',
    value: 'advertiserType'
  },
  // 리포트에서 사용
  {
    text: '측정기준',
    value: 'measureStandard'
  }
];
