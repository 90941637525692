export default {
  namespaced: true,

  state: {
    menus: [
      {
        text: '대시보드',
        active: true,
        items: [
          {
            icon: 'mdi-chart-bubble',
            text: '대시보드',
            link: '/admin'
          }
        ]
      },
      {
        text: '보고서',
        active: true,
        items: [
          {
            icon: 'mdi-chart-areaspline',
            text: '캠페인 보고서',
            link: '/admin/report'
          },
          {
            icon: 'mdi-antenna',
            text: '매체 라이브',
            link: '/admin/report-media'
          },
          {
            icon: 'mdi-cellphone-marker',
            text: '매체 위치 라이브',
            link: '/admin/report/media/location'
          },
          {
            icon: 'mdi-comment-flash-outline',
            text: 'Push 라이브',
            link: '/admin/report/push-live'
          },
          {
            icon: 'mdi-calendar-clock',
            text: 'Push 캘린더',
            link: '/admin/message-dates'
          }
        ]
      },
      {
        text: '계정 관리',
        active: true,
        items: [
          {
            icon: 'mdi-group',
            text: '매체 그룹',
            link: '/admin/media-group'
          },
          {
            icon: 'mdi-nature-people',
            text: '광고주',
            link: '/admin/advertiser'
          },
          {
            icon: 'mdi-handshake-outline',
            text: '파트너스',
            link: '/admin/partner'
          }
        ]
      },
      {
        text: '캠페인 관리',
        active: true,
        items: [
          {
            icon: 'mdi-bookmark-multiple',
            text: '캠페인 광고 그룹',
            link: '/admin/advertiser-group'
          },
          {
            icon: 'mdi-projector-screen',
            text: '캠페인 목록',
            link: '/admin/campaign'
          },
          // {
          //   icon: 'mdi-projector-screen-outline',
          //   text: '외부 캠페인 목록',
          //   link: '/admin/campaigns/ad',
          // },
          {
            icon: 'mdi-pound-box',
            text: '태그',
            link: '/admin/tag'
          },
          {
            icon: 'mdi-pound-box-outline',
            text: '매체 태그',
            link: '/admin/media-tag'
          }
          // {
          //   icon: 'mdi-ticket-percent-outline',
          //   text: '쿠폰 카테고리',
          //   link: '/admin/coupon-category'
          // }
        ]
      },
      {
        text: 'DID',
        active: true,
        items: [
          {
            icon: 'mdi-group',
            text: '매체 그룹',
            link: '/admin/did/media-group'
          },
          {
            icon: 'mdi-store',
            text: '매체 관리',
            link: '/admin/did/branch'
          },
          {
            icon: 'mdi-set-top-box',
            text: '셋톱박스 관리',
            link: '/admin/did/settop-box'
          },
          {
            icon: 'mdi-folder-multiple-image',
            text: '소재 관리',
            link: '/admin/did/campaign-resource'
          },
          {
            icon: 'mdi-projector-screen',
            text: '캠페인 목록',
            link: '/admin/did/campaign'
          },
          {
            icon: 'mdi-cog-box',
            text: '앱 관리',
            link: '/admin/did/app'
          }
        ]
      },
      {
        text: 'CS 관리',
        active: true,
        items: [
          {
            icon: 'mdi-bullhorn',
            text: '공지사항 관리',
            link: '/admin/notice'
          },
          {
            icon: 'mdi-account-question',
            text: '1:1 문의 관리',
            link: '/admin/inquiry'
          },
          {
            icon: 'mdi-gift',
            text: '프로모션',
            link: '/admin/promotion-inquiry'
          }
        ]
      },
      {
        text: '결제 관리',
        active: true,
        items: [
          {
            icon: 'mdi-bank',
            text: '결제 관리',
            link: '/admin/payment'
          }
        ]
      },
      {
        text: '유틸리티',
        active: true,
        items: [
          {
            icon: 'mdi-calculator',
            text: '광고 타겟 계산기',
            link: '/admin/target-calculator'
          },
          {
            icon: 'mdi-cellphone-message',
            text: 'CRM 발송',
            link: '/admin/notification-pushes'
          }
        ]
      }
    ]
  },
  getters: {
    menus(state) {
      return state.menus;
    }
  }
};
