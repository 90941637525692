<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn depressed dark @click="openDialog()"> 매체 그룹 등록 </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <DidMediaGroupFilters
            :loading="indexLoading"
            @search="fetchDidMediaGroups"
          />

          <a-table
            :loading="indexLoading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            rowKey="id"
            :columns="columns"
            bordered
            :data-source="didMediaGroups"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      handleRowClick(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="isActive" slot-scope="value, record">
              <div class="d-flex align-center justify-center">
                <v-switch
                  v-model="record.isActive"
                  class="ma-0"
                  color="success"
                  inset
                  hide-details
                  readonly
                  @click.stop="updateIsActive(record)"
                />
              </div>
            </template>

            <template slot="userApproveStatus" slot-scope="value, record">
              <div class="d-flex align-center justify-center">
                <v-switch
                  v-model="record.userApproveStatus"
                  class="ma-0"
                  color="success"
                  inset
                  hide-details
                  readonly
                  @click.stop="updateUserApproveStatus(record)"
                />
              </div>
            </template>

            <template slot="typeInfo" slot-scope="value">
              <v-chip v-if="value" small label outlined :color="value.color">
                {{ value.text }}
              </v-chip>
              <template v-else> - </template>
            </template>

            <template slot="count" slot-scope="value">
              <div class="text-right">
                {{ value }}
              </div>
            </template>

            <template slot="updatedAt" slot-scope="value, record">
              {{ record.updatedDate }} <br />
              {{ record.updatedTime }}
            </template>

            <template slot="localeString" slot-scope="text">
              {{ text.toLocaleString() }}
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalCount"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <DidMediaGroupDialog @refresh="fetchDidMediaGroups" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import DidMediaGroupDialog from './components/dialog/DidMediaGroupDialog';
import DidMediaGroupFilters from './components/AdminDidMediaGroupFilters';
import MenuTitle from '@/components/MenuTitle.vue';
import {
  getDidMediaGroupsApi,
  getDidMediaGroupApi,
  activeDidMediaGroupApi,
  inactiveDidMediaGroupApi
} from '@/api/admin/DidMediaGroupAPI';
import { approveUserStatusApi, waitUserStatusApi } from '@/api/admin/UserAPI';
import { DidMediaGroup } from '@/model/admin/did';
import { Pagination } from '@/model/Pagination';

export default {
  components: {
    MenuTitle,
    DidMediaGroupDialog,
    DidMediaGroupFilters
  },
  data() {
    return {
      didMediaGroups: [],
      pagination: new Pagination(1, 20)
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didMediaGroup/dialog',
      filterParams: 'didMediaGroup/filterParams',
      loading: 'didMediaGroup/loading'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '광고 송출 상태',
          align: 'center',
          dataIndex: 'isActive',
          scopedSlots: {
            customRender: 'isActive'
          },
          width: 120
        },
        {
          title: '계정 활성 여부',
          align: 'center',
          dataIndex: 'userApproveStatus',
          scopedSlots: {
            customRender: 'userApproveStatus'
          },
          width: 120
        },
        {
          title: '매체그룹명',
          align: 'center',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '그룹 유형',
          align: 'center',
          dataIndex: 'typeInfo',
          scopedSlots: {
            customRender: 'typeInfo'
          }
        },
        {
          title: 'ID',
          align: 'left',
          dataIndex: 'userUid'
        },
        {
          title: '이메일',
          align: 'left',
          dataIndex: 'userEmail'
        },
        {
          title: '매체',
          align: 'center',
          dataIndex: 'didBranchCount',
          scopedSlots: {
            customRender: 'count'
          }
        },
        {
          title: '셋톱박스',
          align: 'center',
          dataIndex: 'didSettopBoxCount',
          scopedSlots: {
            customRender: 'count'
          }
        },
        { title: '등록일', align: 'center', dataIndex: 'createdDate' }
      ];
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchDidMediaGroups();
    }
  },
  mounted() {
    this.loading.index = false;
    this.fetchDidMediaGroups();
  },
  methods: {
    ...mapActions({
      openDialog: 'didMediaGroup/openDialog',
      closeDialog: 'didMediaGroup/closeDialog'
    }),
    /** @description: 매체 그룹 목록 조회 */
    async fetchDidMediaGroups() {
      if (this.indexLoading) return;

      this.loading.index = true;
      const { perPage } = this.pagination;

      const [error, data] = await getDidMediaGroupsApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          filters: this.filterParams,
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { did_media_groups, total_count } = data;
        this.didMediaGroups = did_media_groups.map(didMediaGroup => {
          return new DidMediaGroup(didMediaGroup);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading.index = false;
    },
    /** @description: 매체 그룹 상세 조회 */
    async handleRowClick(record) {
      this.loading.index = true;

      if (!record) return;

      const [error, data] = await getDidMediaGroupApi(record.id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { media_group } = data;

        this.openDialog(media_group);
      }

      this.loading.index = false;
    },
    /**@description: 회원 활성여부 변경 */
    updateUserApproveStatus(item) {
      const html = `<h3>${item.id}번 ${item.name} <br> 해당 계정을 ${
        !item.userApproveStatus ? '활성' : '비활성'
      } 하시겠습니까?</h3>`;
      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            const [error, data] = !item.userApproveStatus
              ? await approveUserStatusApi(item.userId) // 활성
              : await waitUserStatusApi(item.userId); // 비활성
            if (error) {
              this.$Swal.showValidationMessage(
                error?.data?.message ?? '요청 실패'
              );
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.userApproveStatus = !item.userApproveStatus;
          }
        });
    },
    /**@description: 광고 송출여부 변경 */
    updateIsActive(item) {
      const html =
        `<h3>${item.id}번 ${item.name} <br> 매체 그룹을 ${
          !item.isActive ? '활성' : '비활성'
        } 하시겠습니까?</h3>` +
        `${
          !item.isActive === false
            ? '<br><small>❗️해당 매체 그룹에 속한 모든 매체가 비활성화 됩니다.</small>'
            : ''
        }`;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            const [error, data] = !item.isActive
              ? await activeDidMediaGroupApi(item.id) // 활성
              : await inactiveDidMediaGroupApi(item.id); // 비활성
            if (error) {
              this.$Swal.showValidationMessage(
                error?.data?.message ?? '요청 실패'
              );
            } else {
              return data;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.isActive = !item.isActive;
          }
        });
    }
  }
};
</script>
