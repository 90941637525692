<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="결제 상태">
        <a-select mode="multiple" style="width: 250px" v-model="filters.status">
          <a-select-option value="S"> 성공 </a-select-option>
          <a-select-option value="C"> 취소</a-select-option>
          <a-select-option value="PC"> 부분취소</a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="진행 상태">
        <a-select
          mode="multiple"
          style="width: 250px"
          v-model="filters.expired"
        >
          <a-select-option :value="0"> 진행중 </a-select-option>
          <a-select-option :value="1"> 종료</a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="광고주">
        <v-autocomplete
          v-model="filters.advertisers"
          :items="advertisers"
          ref="advertiserGroupAC"
          item-text="name"
          item-value="id"
          multiple
          outlined
          clearable
          placeholder="광고주를 선택하세요"
          no-data-text="선택가능한 광고주가 없습니다."
          hide-details
          class="custom-filter-selectbox"
          dense
        >
          <template v-slot:prepend-item v-if="advertisers.length > 0">
            <v-list-item ripple @click="toggleAdvertiser">
              <v-list-item-content>
                <v-list-item-title> 전체선택 </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="data">
            <v-chip
              v-if="data.index === 0"
              v-bind="data.attrs"
              :input-value="data.selected"
              outlined
              label
              pill
              style="border: none !important"
              @click="data.select"
            >
              <v-chip small>
                {{ data.item.name }}
                {{
                  filters.advertisers.length > 1
                    ? ` 외 ${filters.advertisers.length - 1} 개`
                    : ''
                }}
              </v-chip>
            </v-chip>
          </template>
        </v-autocomplete>
      </a-descriptions-item>

      <a-descriptions-item label="등록일">
        <div style="min-width: 150px" class="px-2">
          <FilterDatePicker
            :style="{ display: 'inline-block' }"
            :prefix="7"
            class="chip--small"
            @setDateRanges="
              v => {
                filters.dateRanges = v;
              }
            "
          />
        </div>
      </a-descriptions-item>

      <a-descriptions-item label="검색" :span="2">
        <a-input-group compact>
          

          <a-input-search
            v-model="filters.searchText"
            placeholder="검색할 캠페인 이름을 입력해 주세요."
            @search="$emit('search')"
            style="width: calc(100%ㅋ); max-width: 1000px"
          />
        </a-input-group>
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>

      <v-btn
        class="ml-3"
        color="#00d1b2"
        outlined
        @click="handleInitClick()"
        :disabled="loading"
      >
        초기화
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getUserCampaignUserApi } from '@/api/admin/UserAPI';
import FilterDatePicker from '@/components/FilterDatePicker.vue';

export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  components: { FilterDatePicker },
  data() {
    return {
      advertisers: []
    };
  },
  computed: {
    ...mapGetters({
      filters: 'didCampaignService/filters'
    })
  },
  created() {
    this.initFilters();
    this.fetchAdvertisers();
  },
  methods: {
    ...mapActions({
      initFilters: 'didCampaignService/initFilters'
    }),
    filterOption(text, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      );
    },
    /**@description: 광고주 목록 */
    async fetchAdvertisers() {
      this.advertisers = [];
      const [error, data] = await getUserCampaignUserApi();
      if (error) {
        console.error(error);
      } else {
        const { advertisers } = data;
        this.advertisers = advertisers.map(item => ({
          id: item.id,
          name: item.name
        }));
      }
    },
    toggleAdvertiser() {
      this.$nextTick(() => {
        if (this.filters.advertisers.length === this.advertisers.length) {
          this.filters.advertisers = [];
        } else {
          this.filters.advertisers = this.advertisers.map(item => item.id);
        }
      });
    },
    handleInitClick() {
      this.initFilters();
      this.$emit('search');
    }
  }
};
</script>
<style lang="scss">
.chip--small {
  .v-chip {
    border-radius: 12px !important;
    font-size: 12px !important;
    height: 24px !important;
  }
}
.custom-filter-selectbox {
  font-size: 14px !important;
  max-width: 300px !important;

  .v-input__control {
    .v-input__slot {
      padding: 2px 5px !important;
    }
    .v-chip,
    div,
    fieldset {
      height: auto !important;
      min-height: auto !important;
      padding: auto !important;
    }
    input {
      padding: 2px !important;
    }
    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-top: 4px !important;
    }
    .v-select__selections {
      height: auto;
      max-height: 32px !important;
    }
  }
}
</style>
