<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <v-btn
              depressed
              dark
              @click="openDialog({ record: {}, type: 'store' })"
            >
              매체그룹 등록
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a-card>
          <media-group-filters
            :filters="filters"
            :loading="loading"
            @search="getMediaGroups"
          />

          <a-table
            :loading="loading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            :columns="columns"
            bordered
            :data-source="mediaGroups"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      openDialog({ record: record, type: 'detail' });
                    }
                  }
                };
              }
            "
          >
            <template slot="no" slot-scope="value, record, index">
              {{
                pagination.total -
                (pagination.perPage * pagination.page - pagination.perPage) -
                index
              }}
            </template>

            <template slot="active" slot-scope="value, record">
              <div class="d-flex justify-center">
                <v-switch
                  v-model="record.active.value"
                  color="info"
                  class="mt-0"
                  inset
                  readonly
                  hide-details
                  @click.stop="updateActive(record)"
                />
              </div>
            </template>

            <template slot="type" slot-scope="value, record">
              <a-tag small :color="record.type.color">
                {{ record.type.text }}
              </a-tag>
            </template>

            <template slot="actions" slot-scope="value, record">
              <v-tooltip bottom color="rgba(0,0,0,1)">
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn
                    v-on="{ ...tooltipOn }"
                    type="primary"
                    small
                    icon
                    @click.stop="handleUserIFrameClick(record)"
                  >
                    <v-icon>mdi-home</v-icon>
                  </v-btn>
                </template>
                <span class="title">사용자 화면</span>
              </v-tooltip>
            </template>
          </a-table>
        </a-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="pagination.perPage"
          color="secondary"
        />
      </v-col>
    </v-row>

    <media-group-store-dialog @refresh="getMediaGroups" />
    <media-group-detail-dialog @refresh="getMediaGroups" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import MediaGroupStoreDialog from './components/dialog/MediaGroupStoreDialog';
import MediaGroupDetailDialog from './components/dialog/MediaGroupDetailDialog';
import MediaGroupFilters from './AdminMediaGroupFilters';
import { getTokenAPI } from '@/api/admin/UserAPI';

export default {
  components: {
    MenuTitle,
    MediaGroupDetailDialog,
    MediaGroupStoreDialog,
    MediaGroupFilters
  },
  data() {
    return {
      mediaGroups: [],
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        total: 0
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'mediaGroup/dialog',
      filters: 'mediaGroup/filters'
    }),
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id'
        },
        {
          title: '광고 송출 상태',
          align: 'center',
          dataIndex: 'active',
          scopedSlots: {
            customRender: 'active'
          }
        },
        { title: '매체그룹명', dataIndex: 'name' },
        {
          title: '그룹유형',
          dataIndex: 'type',
          align: 'center',
          scopedSlots: {
            customRender: 'type'
          }
        },
        { title: '연락처', dataIndex: 'phone', align: 'center' },
        { title: '이메일', dataIndex: 'email', align: 'center' },
        { title: '등록일', align: 'center', dataIndex: 'createdAt' },
        {
          title: '기능',
          dataIndex: 'actions',
          align: 'center',
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ];
    }
  },
  watch: {
    'pagination.page': function () {
      this.getMediaGroups();
    }
  },
  mounted() {
    this.getMediaGroups();
  },
  methods: {
    ...mapActions({
      openDialog: 'mediaGroup/openDialog',
      openUserIFrameDialog: 'dialog/openUserIFrameDialog',
      setIframeUrl: 'user/setIframeUrl'
    }),
    getMediaGroups() {
      this.loading = true;
      return this.$axios
        .get('/admin/media-groups', {
          params: {
            page: this.pagination.page,
            perPage: this.pagination.perPage,
            email: this.filters.email,
            name: this.filters.name,
            type: this.filters.type,
            phone: this.filters.phone,
            active: this.filters.active,
            dates: this.filters.dates.map(date => {
              return date.format('YYYY-MM-DD');
            }),
            pagination: 'Y'
          }
        })
        .then(res => {
          const { data, last_page, total } = res.data.mediaGroups;

          this.hashing(data);

          this.pagination.totalPage = last_page;
          this.pagination.total = total;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hashing(mediaGroups) {
      const hashedMediaGroups = mediaGroups.map(mediaGroup => {
        let active = {
          text: '비활성',
          value: false,
          color: 'red'
        };

        if (mediaGroup.is_active) {
          active = {
            text: '활성',
            value: true,
            color: 'green'
          };
        }

        let type = {
          text: '직매체',
          value: 'D',
          color: 'blue'
        };

        if (mediaGroup.type === 'A') {
          type = {
            text: '대행사',
            value: 'A',
            color: 'purple'
          };
        }

        return {
          origin: mediaGroup,
          key: mediaGroup.id,
          id: mediaGroup.id,
          active: active,
          name: mediaGroup.name,
          type: type,
          createdAt: this.$moment(mediaGroup.created_at).format('YYYY-MM-DD'),
          phone: mediaGroup?.user?.phone || null,
          email: mediaGroup?.user?.email || null,
          uid: mediaGroup?.user?.uid || null,
          approve: mediaGroup?.user?.approve_status_flag || null
        };
      });

      this.mediaGroups = hashedMediaGroups;
    },
    updateActive(item) {
      const html =
        `<h3>${item.id}번 ${item.name} <br> 매체 그룹을 ${
          !item.active.value ? '활성' : '비활성'
        } 하시겠습니까?</h3>` +
        `${
          !item.active.value === false
            ? '<br><small>❗️해당 매체 그룹에 속한 모든 매체가 비활성화 됩니다.</small>'
            : ''
        }`;

      this.$Swal
        .fire({
          html: html,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .put('/admin/media-group-active', {
                mediaGroupIds: [item.id],
                isActive: !item.active.value
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            item.active.value = !item.active.value;
          }
        });
    },
    async handleUserIFrameClick(record) {
      const [error, data] = await getTokenAPI(record.origin.user_id);
      if (error) {
        return false;
      }
      const { token } = data;

      let iframeUrl = `${process.env.VUE_APP_URL}/bridge/${token}`;

      this.setIframeUrl(iframeUrl);
      this.openUserIFrameDialog(record.origin.user_id);
    }
  }
};
</script>
