<template>
  <div>
    <div class="row ma-0 justify-end">
      <a-button
        size="small"
        class="mb-3"
        :loading="loading"
        @click="fetchFreePointOrders"
      >
        새로고침
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      bordered
      :scroll="{ x: true }"
      scrollToFirstRowOnChange
      size="small"
      :pagination="false"
    >
      <span slot="no" slot-scope="text, record, index">
        {{
          pagination.total -
          (pagination.perPage * pagination.page - pagination.perPage) -
          index
        }}
      </span>

      <span slot="status" slot-scope="text, record">
        <!-- <a-tag :color="record.status.color">
          {{ record.status.text }}
        </a-tag> -->

        <v-chip
          :color="record.status.color"
          outlined
          label
          small
          @click.stop="
            () =>
              record.statusFlag === 'S' ? handlePaymentStatusClick(record) : ''
          "
        >
          {{ record.status.text }}

          <v-avatar right v-if="record.statusFlag === 'S'" class="ml-0">
            <span>
              <v-tooltip bottom color="rgba(0,0,0,0.8)">
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    v-on="{ ...onTooltip }"
                    icon
                    x-small
                    :color="record.status.color"
                  >
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                </template>
                <span class="text-subtitle-2">포인트 환불</span>
              </v-tooltip>
            </span>
          </v-avatar>
        </v-chip>
      </span>
      <template slot="localeString" slot-scope="text">
        {{ text ? Number(text).toLocaleString() : '-' }}
      </template>
    </a-table>

    <v-pagination
      v-model="pagination.page"
      :length="pagination.totalPage"
      :total-visible="pagination.perPage"
      class="mt-5"
      color="secondary"
    />

    <StatusChangeDialog
      v-if="isStatusDialog"
      title="지급 취소 요청"
      :record="selectRecord"
      :dialog="!!selectRecord"
      @index="fetchFreePointOrders()"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFreePointOrdersApi } from '@/api/admin/UserAPI';
import StatusChangeDialog from '@/components/payment/StatusChangeDialog';

export default {
  props: {
    type: String,
    tab: String
  },
  components: {
    StatusChangeDialog
  },
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    })
  },
  data() {
    return {
      loading: false,
      data: [],
      isStatusDialog: false,
      selectRecord: null,
      pagination: {
        page: 1,
        perPage: 20,
        totalPage: 1,
        total: 0
      },
      columns: [
        {
          title: '번호',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 50,
          ellipsis: true
        },
        {
          title: '지급일',
          dataIndex: 'orderAt',
          key: 'orderAt',
          align: 'center',
          ellipsis: true
        },
        {
          title: '상태',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '지급 포인트',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          ellipsis: true
        },
        {
          title: '결제 번호',
          dataIndex: 'orderNo',
          key: 'orderNo',
          align: 'center',
          ellipsis: true
        }
      ]
    };
  },
  watch: {
    'pagination.page'() {
      this.fetchFreePointOrders();
    },
    advertiser() {
      this.fetchFreePointOrders();
    }
  },
  mounted() {
    this.fetchFreePointOrders();
  },
  methods: {
    async fetchFreePointOrders() {
      this.loading = true;
      this.data = [];

      const params = {
        params: {
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          pagination: 'Y'
        }
      };

      const [error, data] = await getFreePointOrdersApi(
        params,
        this.advertiser.origin.user.id
      );
      if (error) {
        console.error(error);
      } else {
        // console.log(`free point orders `, data);
        const {
          point_orders: { total, last_page, data: items }
        } = data;
        this.pagination.total = total;
        this.pagination.totalPage = last_page;

        this.data = items.map((item, index) => {
          let status = {};

          if (item.status_flag === 'W') {
            status = {
              text: '활성 대기',
              color: 'orange'
            };
          }

          if (item.status_flag === 'S') {
            status = {
              text: '활성 완료',
              color: 'success'
            };
          }

          if (item.status_flag === 'C') {
            status = {
              text: '지급 취소',
              color: 'error'
            };
          }
          return {
            key: index,
            id: item.id,
            type: item.type,
            status: status,
            statusFlag: item.status_flag,
            amount: item.amount * 1,
            amountString: (item.amount * 1).toLocaleString() + ' 원',
            advertisingAmount: (item.advertising_amount * 1).toLocaleString(),
            orderNo: item.order_no,
            orderAt: item.order_at,
            paymentMethod: item.payment_method,
            validAmount: item.valid_amount * 1,
            userId: item.user_id
          };
        });
      }

      this.loading = false;
    },
    // 충전완료 -> 결제 취소 상태 변경
    handlePaymentStatusClick(record) {
      // 충전완료인 상태만 변경 가능
      if (record.statusFlag !== 'S') {
        this.$Swal.fire({
          html: '<h3>변경이 불가능한 상태입니다.</h3>',
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return false;
      }
      this.isStatusDialog = true;
      this.selectRecord = {
        ...record,
        status: record.statusFlag
      };
    },
    handleCloseDialog() {
      this.isStatusDialog = false;
      this.selectRecord = null;
    }
  }
};
</script>
