<template>
  <v-row
    id="store-location-select-wrap"
    style="width: 80vw; max-width: 1200px"
    class="ma-0"
  >
    <v-col cols="12" md="6" class="pb-0">
      <v-row class="ma-0 pa-3">
        <h3 style="height: auto">매장 선택 ({{ storeLocationList.length }})</h3>
        <v-spacer />
        <div class="mx-2">
          <v-btn small color="primary" outlined @click="selectedAll">
            <span class="text-overline">전체 선택</span>
          </v-btn>
        </div>
      </v-row>
      <v-col col="12" class="px-2 border-box">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(areaList, areaName, index) in groupedStoreList"
              :key="index"
              class="mt-2 location-expansion"
              active-class="active-location-expansion"
            >
              <v-expansion-panel-header class="font-weight-medium">
                {{ areaName }} ({{ areaList.length }})
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="my-0">
                  <v-col
                    v-for="(item, idx) in areaList"
                    :key="idx"
                    cols="auto"
                    class="pa-1"
                  >
                    <v-btn-toggle
                      v-model="selectedTempItems"
                      dense
                      outlined
                      group
                      multiple
                      style="width: 100%"
                    >
                      <v-btn
                        :value="item.brch_no"
                        :color="
                          activeItem(selectedTempItems, item.brch_no)
                            ? 'primary'
                            : 'secondary'
                        "
                        class="d-flex align-center button_selected"
                        :class="
                          activeItem(selectedTempItems, item.brch_no)
                            ? 'on'
                            : 'off'
                        "
                        dense
                        outlined
                        rounded
                      >
                        <span>{{ item.brch_nm }}</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <h4 class="red--text mt-2 font size12">
            {{ errors.length > 0 ? errors[0] : '' }}
          </h4>
        </ValidationProvider>
      </v-col>
    </v-col>
    <v-col cols="12" md="6" class="pb-0">
      <v-row class="ma-0 pa-3">
        <h3 style="height: auto">
          선택한 매장 ({{ selectedStoreList.length }})
        </h3>
        <v-spacer />
        <div class="mx-2">
          <v-btn
            small
            color="secondary"
            outlined
            @click="() => (selectedTempItems = [])"
          >
            <span class="text-overline">초기화</span>
          </v-btn>
        </div>
      </v-row>
      <div class="border-box">
        <v-row
          class="ma-0"
          :style="selectedStoreList.length === 0 ? 'height:100%;' : ''"
        >
          <v-col
            cols="12"
            v-if="selectedStoreList.length === 0"
            class="text-center grey--text darken-4 ma-auto"
          >
            <v-icon class="mb-1 grey--text darken-4">
              mdi-alert-outline
            </v-icon>
            <h3 class="subtitle-1 font-weight-medium grey--text darken-4">
              광고가 노출될 매장을 선택해 주세요.
            </h3>
          </v-col>
          <v-col
            v-for="(selectedItem, index) in selectedStoreList"
            :key="index"
            class="pa-1"
            cols="auto"
            style="max-width: 250px"
          >
            <v-chip
              close
              color="primary"
              class="d-flex align-center on"
              dense
              @click:close="removeLocation(selectedItem.brch_no)"
            >
              <span>{{ selectedItem.brch_nm }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col
      col="12"
      class="row justify-center ma-0"
      style="border-top: 1px solid #999"
    >
      <v-btn class="mr-5" outlined @click="handleCloseClick"> 취소 </v-btn>
      <v-btn color="secondary" @click.stop="setPlaceData()"> 적용 </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  props: {
    storeLocationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    groupedStoreList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      selectedTempItems: [],
    };
  },
  created() {
    if (
      this.campaign.couponBranches &&
      this.campaign.couponBranches.length > 0
    ) {
      this.selectedTempItems = JSON.parse(
        JSON.stringify(this.campaign.couponBranches)
      );
    }
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit',
    }),
    selectedStoreList: {
      get: function () {
        const root = this;
        let resultList = [];
        root.selectedTempItems.forEach((item, area_cd) => {
          const itemIdx = root.storeLocationList.findIndex(
            store => store.brch_no === item
          );

          if (itemIdx >= 0) {
            resultList.push({
              ...root.storeLocationList[itemIdx],
              area_cd: area_cd,
            });
          }
        });
        return resultList;
      },
    },
  },
  methods: {
    // 화면이 넓으니까 노노노
    // getItemCols(text) {
    //   const getByteLengthOfString = (s, b, i, c) => {
    //     for (
    //       b = i = 0;
    //       (c = s.charCodeAt(i++));
    //       b += c >> 11 ? 3 : c >> 7 ? 2 : 1
    //     );
    //     return b;
    //   };

    //   const stringLength = getByteLengthOfString(text);
    //   let resCols = 3;

    //   if (stringLength > 13) resCols = 6;

    //   if (stringLength > 25) resCols = 9;

    //   return resCols;
    // },
    activeItem(list, value) {
      const checkList = list ?? [];
      return checkList.includes(value);
    },
    removeLocation(id) {
      const idx = this.selectedTempItems.indexOf(id);

      if (idx >= 0) {
        this.selectedTempItems.splice(idx, 1);
      }

      if (this.selectedStoreList.length === 0) {
        this.selectedTempItems = [];
      }
    },
    selectedAll() {
      if (this.selectedTempItems.length === this.storeLocationList.length)
        return;
      this.selectedTempItems = this.storeLocationList.map(item => item.brch_no);
    },
    setPlaceData() {
      this.campaign.couponBranches = JSON.parse(
        JSON.stringify(this.selectedTempItems)
      );
      this.$emit('close');
    },
    handleCloseClick() {
      this.selectedTempItems = [];
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
#store-location-select-wrap {
  .green--text {
    color: #00b1bc !important;
    caret-color: #00b1bc !important;
  }
  .green {
    background-color: #00b1bc !important;
  }
  .border-box {
    height: 450px;
    padding: 12px;
    overflow: auto;
    border: 2px solid #999;
    border-radius: 10px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      width: 0.6em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem;
    }

    .col-auto {
      height: auto;
    }
  }
  .location-expansion {
    background-color: transparent;
    border: 1px solid #999;
    border-radius: 8px;
  }
  .active-location-expansion {
    background-color: rgba(255, 255, 255, 0.8) !important;
    border: 1.5px solid #1976d2;

    > button {
      min-height: 16px;
      color: #1976d2;
    }
  }

  .v-btn-toggle--group > .v-btn.button_selected {
    border: 2px solid #1976d2;
    border-radius: 10px;

    &.off {
      background: rgba(153, 153, 153, 0.5) !important;
      border-color: rgb(153, 153, 153);
      color: #333 !important;
    }
  }
  .v-btn__content,
  .v-chip__content {
    max-width: 100% !important;

    span {
      max-width: 100% !important;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.75rem !important;
    }
  }

  .v-chip__content {
    span,
    button {
      color: #fff !important;
      font-weight: 500 !important;
    }
  }
}
</style>
