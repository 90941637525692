import { DidCampaignService } from '@/model/admin/did';

export default {
  namespaced: true,

  state: {
    loading: {
      index: false,
      dialog: false
    },
    didCampaignService: new DidCampaignService(),
    dialog: false,
    filters: {
      dateRanges: [],
      branches: [],
      status: ['S', 'C', 'PC'],
      expired: [0, 1],
      searchText: '',
      searchKey: 'name'
    },
    currentStep: 1
  },
  getters: {
    didCampaignService(state) {
      return state.didCampaignService;
    },
    didCampaignServiceSteps(state) {
      return state.didCampaignServiceSteps;
    },
    currentStep(state) {
      return state.currentStep;
    },
    dialog(state) {
      return state.dialog;
    },
    loading(state) {
      return state.loading;
    },
    dialogLoading(state) {
      return state.loading.dialog;
    },
    filters(state) {
      return state.filters;
    },
    filterParams(state) {
      let params = {
        dateRanges: state.filters.dateRanges,
        branches: state.filters.branches,
        status: state.filters.status,
        expired: state.filters.expired
      };
      params[`${state.filters.searchKey}`] = state.filters.searchText;

      return params;
    },
    isDialogTypeEdit(state) {
      return !!state.didCampaignService.id;
    },
    invalidDialog(state) {
      return state.invalidDialog;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_DidCampaignService(state, didCampaignService) {
      state.didCampaignService = new DidCampaignService(
        didCampaignService,
        true
      );
    },
    SET_CurrentStep(state, currentStep) {
      state.currentStep = currentStep;
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_Filters(state) {
      state.filters = {
        dateRanges: [],
        branches: [],
        status: ['S', 'C', 'PC'],
        expired: [0, 1],
        searchText: '',
        searchKey: 'name'
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    async openDialog({ commit }, didCampaignService = undefined) {
      commit('SET_DidCampaignService', new DidCampaignService());

      if (didCampaignService) {
        commit('SET_DidCampaignService', didCampaignService);
      }

      commit('SET_Dialog', true);
    },
    closeDialog({ commit }) {
      commit('SET_Dialog', false);
      commit('SET_DidCampaignService', new DidCampaignService());
      commit('SET_CurrentStep', 1);
    },
    setCurrentStep({ commit }, currentStep) {
      commit('SET_CurrentStep', currentStep);
    }
  }
};
