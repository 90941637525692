var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-auto"},[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){return _vm.openEditDialog()}}},[_vm._v(" 태그 등록 ")])],1),_c('SearchForm',{attrs:{"placeholder":"태그명, 태그유형으로 검색하세요."},on:{"setSearchString":function (v) {
              _vm.searchString = v;
              _vm.fetchTags(1);
            }}})],1)])],1),_c('v-card',{staticClass:"my-3"},[_c('v-row',[_c('v-col',[_c('a-table',{staticClass:"px-3 table-striped-rows",attrs:{"columns":_vm.columns,"data-source":_vm.tags,"loading":_vm.loading,"size":"small","locale":{
            emptyText: '데이터가 없습니다.'
          },"bordered":"","rowKey":"id","pagination":false,"scroll":{ x: 800, y: 1200 },"customRow":function (record) {
              return {
                on: {
                  mouseenter: function (event) {
                    _vm.hoverItem = record.id;
                  },
                  mouseleave: function (event) {
                    _vm.hoverItem = null;
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"name",fn:function(value, record){return [_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"min-height":"30px !important"}},[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticStyle:{"min-width":"40px"}},[(_vm.hoverItem === record.id)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openEditDialog(record.origin)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("수정")])]):_vm._e()],1)])]}},{key:"localeString",fn:function(text){return [_vm._v(" "+_vm._s(text.toLocaleString())+" ")]}},{key:"campaignTags",fn:function(tags){return [(tags.length > 0)?_c('v-menu',{attrs:{"open-on-hover":"","open-delay":"100","close-delay":"100","bottom":"","offset-y":"","nudge-left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-0 font-weight-bold",attrs:{"label":"","color":"default","small":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v("# "+_vm._s(tags[0].name)+" "+_vm._s(tags.length > 1 ? ("외 " + (tags.length - 1) + "개") : ''))])])]}}],null,true)},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"width":"300","min-height":"150","max-height":"300"}},[_c('v-card-text',_vm._l((tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"label":"","outlined":"","color":"primary darken-3"}},[_c('v-icon',[_vm._v("mdi-pound")]),_c('span',[_vm._v(" "+_vm._s(tag.name)+" ")])],1)}),1)],1)],1):_vm._e()]}}])}),(_vm.hasTags)?_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('a-pagination',{attrs:{"defaultPageSize":_vm.pagination.defaultPageSize,"page-size":_vm.pagination.perPage,"size":"large","show-size-changer":true,"pageSizeOptions":['10', '20', '50', '100'],"total":_vm.pagination.totalCount},on:{"update:pageSize":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"change":function($event){return _vm.fetchTags()},"showSizeChange":function($event){return _vm.fetchTags(1)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }