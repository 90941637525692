var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.titles.couponBranches))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition","max-height":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.selectedBranchLabel,"prepend-inner-icon":"mdi-storefront-outline","persistent-hint":"","readonly":"","clearable":"","placeholder":_vm.placeholder.couponBranches,"error-messages":errors,"outlined":""},on:{"click:clear":function($event){_vm.campaign.couponBranches = []}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.displayMegaboxPlaceModal),callback:function ($$v) {_vm.displayMegaboxPlaceModal=$$v},expression:"displayMegaboxPlaceModal"}},[(_vm.displayMegaboxPlaceModal)?_c('v-card',[_c('CampaignStep2TypeCouponBranchs',{attrs:{"storeLocationList":_vm.storeLocationList,"groupedStoreList":_vm.groupedStoreList},on:{"close":function($event){_vm.displayMegaboxPlaceModal = false}}})],1):_vm._e()],1)]}}])}),_c('h3',[_vm._v(_vm._s(_vm.titles.couponShopId))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.couponShopList,"error-messages":errors,"item-text":"name","item-value":"id","no-data-text":"등록된 지점이 존재 하지않습니다. 지점을 추가하세요.","placeholder":_vm.placeholder.couponShop,"required":"","outlined":"","return-object":"","auto-select-first":""},on:{"change":_vm.handleCouponShopChange},model:{value:(_vm.campaign.couponShop),callback:function ($$v) {_vm.$set(_vm.campaign, "couponShop", $$v)},expression:"campaign.couponShop"}})]}}])}),_c('h3',[_vm._v(_vm._s(_vm.titles.couponPrice))]),_c('FormattedTextField',{attrs:{"prefix":"₩","round":10,"minValueKrw":_vm.couponMinPrice,"value":_vm.campaign.couponPrice},on:{"setAmount":function (amount) {
        this$1.campaign.couponPrice = amount;
      }}}),_c('h3',[_vm._v(_vm._s(_vm.titles.couponExpiresAt))]),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition","bottom":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","persistent-hint":"","readonly":"","outlined":"","clearable":""},model:{value:(_vm.campaign.displayPeriodStart),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodStart", $$v)},expression:"campaign.displayPeriodStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.displayPeriodModal[0]),callback:function ($$v) {_vm.$set(_vm.displayPeriodModal, 0, $$v)},expression:"displayPeriodModal[0]"}},[_c('v-date-picker',{attrs:{"max":_vm.campaign.couponExpiresAt,"min":_vm.minDate,"color":"green lighten-1","locale":"ko-KR"},on:{"input":function($event){_vm.displayPeriodModal[0] = false}},model:{value:(_vm.campaign.displayPeriodStart),callback:function ($$v) {_vm.$set(_vm.campaign, "displayPeriodStart", $$v)},expression:"campaign.displayPeriodStart"}})],1)],1),_c('v-col',{staticClass:"col-auto",staticStyle:{"align-self":"center","margin-top":"-28px"}},[_vm._v(" ~ ")]),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"nudge-bottom":40,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('ValidationProvider',[_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","persistent-hint":"","readonly":"","clearable":"","outlined":""},model:{value:(_vm.campaign.couponExpiresAt),callback:function ($$v) {_vm.$set(_vm.campaign, "couponExpiresAt", $$v)},expression:"campaign.couponExpiresAt"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.displayPeriodModal[1]),callback:function ($$v) {_vm.$set(_vm.displayPeriodModal, 1, $$v)},expression:"displayPeriodModal[1]"}},[_c('v-date-picker',{attrs:{"min":_vm.campaign.displayPeriodStart,"color":"red lighten-1","locale":"ko-KR"},on:{"input":function($event){_vm.displayPeriodModal[1] = false}},model:{value:(_vm.campaign.couponExpiresAt),callback:function ($$v) {_vm.$set(_vm.campaign, "couponExpiresAt", $$v)},expression:"campaign.couponExpiresAt"}})],1)],1)],1),_c('h3',{staticClass:"row ma-0 align-center",on:{"click":function($event){_vm.expandCouponOptions = !_vm.expandCouponOptions}}},[_vm._v(" 고급 옵션 "),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","icon":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-"+_vm._s(_vm.expandCouponOptions ? 'chevron-up' : 'chevron-down')+" ")])],1),_c('v-divider',{staticClass:"ml-2"})],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandCouponOptions),expression:"expandCouponOptions"}],staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.titles.couponType))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.placeholder.couponType,"items":_vm.couponTypeList,"error-messages":errors,"item-text":"text","item-value":"value","required":"","outlined":""},on:{"change":_vm.handleCouponTypeChange},model:{value:(_vm.couponType),callback:function ($$v) {_vm.couponType=$$v},expression:"couponType"}})]}}])}),(_vm.couponType === 'url')?[_c('h3',[_vm._v(_vm._s(_vm.titles.couponLandingUrl))]),_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              regex: _vm.urlRegex
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","placeholder":_vm.placeholder.couponLandingUrl,"outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.campaign.couponLandingUrl && errors.length === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openInNewTab(_vm.campaign.couponLandingUrl)}}},Object.assign({}, onTooltip)),[_c('v-icon',_vm._g({},onTooltip),[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("새탭에서 링크 열기")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.campaign.couponLandingUrl),callback:function ($$v) {_vm.$set(_vm.campaign, "couponLandingUrl", $$v)},expression:"campaign.couponLandingUrl"}})]}}],null,false,4186049466)}),_c('h3',[_vm._v(_vm._s(_vm.titles.maxUses))]),_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              numeric: true,
              min_value: 1
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-hint":"","clearable":"","placeholder":_vm.placeholder.maxUses,"error-messages":errors,"type":"number","suffix":"회","outlined":"","required":""},on:{"click:clear":function($event){_vm.campaign.maxUses = 1}},model:{value:(_vm.campaign.maxUses),callback:function ($$v) {_vm.$set(_vm.campaign, "maxUses", $$v)},expression:"campaign.maxUses"}})]}}],null,false,3610975247)})]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }