<template>
  <v-dialog :value="inquiryDialog" max-width="1200" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>문의사항 상세</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-5">
        <a-descriptions bordered>
          <a-descriptions-item label="계정 이름">
            {{ inquiry.name }}
          </a-descriptions-item>

          <a-descriptions-item label="계정 유형" :span="2">
            <v-chip color="primary" label class="ma-1" outlined>
              {{
                inquiry.role
                  ? UserRoleList.find(v => v.value === inquiry.role).text
                  : '-'
              }}
            </v-chip>
          </a-descriptions-item>
          <a-descriptions-item label="답변 받을 이메일 주소">
            {{ inquiry.email }}
          </a-descriptions-item>

          <a-descriptions-item label="문의일시" :span="2">
            {{ $moment(inquiry.created_at).format('YYYY-MM-DD H:mm:s') }}
          </a-descriptions-item>
          <a-descriptions-item label="문의 내용" :span="3">
            <v-textarea
              class="ma-0 pa-0 text-subtitle-2"
              readonly
              auto-grow
              solo
              flat
              hide-details
              :value="inquiry.content"
            />
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions layout="vertical" bordered class="mt-0">
          <a-descriptions-item :span="3">
            <div slot="label" class="row ma-0 align-center">
              답변
              <div class="col text-right" v-if="inquiry.replied_at">
                답변일시 :
                {{ $moment(inquiry.replied_at).format('YYYY-MM-DD H:mm:s') }}
              </div>
            </div>
            <ValidationObserver ref="inquiryDialogRef">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-textarea
                  v-model="reply"
                  :filled="inquiry.replied_at !== null"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                  flat
                  class="text-subtitle-2 pa-0"
                  :style="`border:${
                    errors.length > 0 ? '1px' : '0px'
                  } solid red`"
                  rows="15"
                />
              </ValidationProvider>
            </ValidationObserver>
          </a-descriptions-item>
        </a-descriptions>
        <!-- <v-row dense>

            <v-col cols="12" lg="6"> </v-col>
          </v-row>-->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          text
          color="primary"
          @click="handleRegisterClick()"
          :loading="loading"
        >
          {{ repliedButton }}
        </v-btn>
        <v-btn text @click="closeDialog" :disabled="loading"> 취소 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { setReplyInquiryApi } from '@/api/admin/InquiryApi';
import { UserRoleList } from '@/config/user';

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      inquiry: 'inquiry/inquiry',
      inquiryDialog: 'inquiry/dialog'
    }),
    repliedButton() {
      return this.inquiry.replied_at ? '답변 수정' : '답변 등록';
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      reply: '',
      UserRoleList: UserRoleList
    };
  },
  mounted() {
    this.$refs.inquiryDialogRef.reset();
    if (this.inquiry.reply) {
      this.reply = this.inquiry.reply;
    }
  },
  methods: {
    ...mapActions({
      initInquiry: 'inquiry/init',
      closeDialog: 'inquiry/closeDialog'
    }),
    async handleRegisterClick() {
      const valid = await this.$refs.inquiryDialogRef.validate();
      if (!valid) return;

      if (this.reply == null || this.reply.length > 0 === false) {
        this.$Swal.fire({
          icon: 'error',
          html: '답변을 등록해주세요.',
          confirmButtonText: '확인'
        });
        return false;
      }

      this.$Swal
        .fire({
          html: `<h3>답변을 ${
            this.inquiry.replied_at ? '수정' : '등록'
          } 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          icon: 'error',
          iconHtml: '!',
          preConfirm: async () => {
            await setReplyInquiryApi({ ...this.inquiry, reply: this.reply });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Swal.fire({
              icon: 'success',
              title: '요청 완료',
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit('index');
          }
        });
    }
  }
};
</script>

<style></style>
