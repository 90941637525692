export const AdminTaskType = [
  {
    value: 'inquiry',
    text: '문의사항',
    color: 'secondary',
    description: '새로운 문의사항이 등록되었습니다.',
    icon: 'mdi-account-question',
    link: '/admin/inquiry'
  },
  {
    value: 'campaign',
    text: '캠페인',
    color: 'red lighten-1',
    description: '새로운 캠페인이 등록되었습니다.',
    icon: 'mdi-projector-screen',
    link: '/admin/campaign'
  },
  {
    value: 'banktransfer',
    text: '직접입금',
    color: 'orange accent-2',
    description: '새로운 직접입금 층전요청이 등록되었습니다.',
    icon: 'mdi-bank',
    link: '/admin/payment'
  },
  {
    value: 'banktransfer-confirm',
    text: '입금 확인',
    color: 'green lighten-1',
    description: '새로운 입금 확인 요청이 등록되었습니다.',
    icon: 'mdi-bank',
    link: '/admin/payment'
  }
];
