<template>
  <div>
    <h3>{{ titles.couponBranches }}</h3>
    <ValidationProvider v-slot="{ errors }" rules="required">
      <v-menu
        v-model="displayMegaboxPlaceModal"
        :close-on-content-click="false"
        :nudge-right="40"
        :nudge-bottom="40"
        transition="scale-transition"
        max-height="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="selectedBranchLabel"
            prepend-inner-icon="mdi-storefront-outline"
            persistent-hint
            readonly
            clearable
            :placeholder="placeholder.couponBranches"
            :error-messages="errors"
            v-bind="attrs"
            v-on="on"
            outlined
            @click:clear="campaign.couponBranches = []"
          ></v-text-field>
        </template>

        <v-card v-if="displayMegaboxPlaceModal">
          <CampaignStep2TypeCouponBranchs
            :storeLocationList="storeLocationList"
            :groupedStoreList="groupedStoreList"
            @close="displayMegaboxPlaceModal = false"
          />
        </v-card>
      </v-menu>
    </ValidationProvider>

    <h3>{{ titles.couponShopId }}</h3>
    <ValidationProvider v-slot="{ errors }" rules="required">
      <v-select
        v-model="campaign.couponShop"
        :items="couponShopList"
        :error-messages="errors"
        item-text="name"
        item-value="id"
        no-data-text="등록된 지점이 존재 하지않습니다. 지점을 추가하세요."
        :placeholder="placeholder.couponShop"
        required
        outlined
        return-object
        auto-select-first
        @change="handleCouponShopChange"
      />
    </ValidationProvider>

    <!-- [3.29] 메가박스에서 Description 디자인때문에 안쓴다고 함 -->
    <!-- <h3>{{ titles.couponDescription }}</h3>
    <ValidationProvider v-slot="{ errors }" rules="required|max:25">
      <v-text-field
        v-model="campaign.couponDescription"
        :counter="25"
        :error-messages="errors"
        :placeholder="placeholder.couponDescription"
        required
        outlined
        clearable
      />
    </ValidationProvider> -->

    <h3>{{ titles.couponPrice }}</h3>
    <FormattedTextField
      prefix="₩"
      :round="10"
      :minValueKrw="couponMinPrice"
      :value="campaign.couponPrice"
      @setAmount="
        amount => {
          this.campaign.couponPrice = amount;
        }
      "
    />

    <h3>{{ titles.couponExpiresAt }}</h3>
    <v-row>
      <v-col>
        <v-menu
          v-model="displayPeriodModal[0]"
          :close-on-content-click="false"
          :nudge-right="40"
          :nudge-bottom="40"
          transition="scale-transition"
          bottom
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="campaign.displayPeriodStart"
              prepend-inner-icon="mdi-calendar"
              persistent-hint
              readonly
              outlined
              clearable
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="campaign.displayPeriodStart"
            @input="displayPeriodModal[0] = false"
            :max="campaign.couponExpiresAt"
            :min="minDate"
            color="green lighten-1"
            locale="ko-KR"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="col-auto" style="align-self: center; margin-top: -28px">
        ~
      </v-col>
      <v-col>
        <v-menu
          v-model="displayPeriodModal[1]"
          :close-on-content-click="false"
          :nudge-right="40"
          :nudge-bottom="40"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider>
              <v-text-field
                v-model="campaign.couponExpiresAt"
                prepend-inner-icon="mdi-calendar"
                persistent-hint
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </ValidationProvider>
          </template>
          <v-date-picker
            v-model="campaign.couponExpiresAt"
            @input="displayPeriodModal[1] = false"
            :min="campaign.displayPeriodStart"
            color="red lighten-1"
            locale="ko-KR"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <h3
      class="row ma-0 align-center"
      @click="expandCouponOptions = !expandCouponOptions"
    >
      고급 옵션
      <v-btn class="ml-2" text icon>
        <v-icon dark>
          mdi-{{ expandCouponOptions ? 'chevron-up' : 'chevron-down' }}
        </v-icon>
      </v-btn>

      <v-divider class="ml-2" />
    </h3>
    <v-expand-transition>
      <v-row class="ma-0" v-show="expandCouponOptions">
        <v-col cols="12">
          <h3>{{ titles.couponType }}</h3>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-select
              v-model="couponType"
              :placeholder="placeholder.couponType"
              :items="couponTypeList"
              :error-messages="errors"
              item-text="text"
              item-value="value"
              required
              outlined
              @change="handleCouponTypeChange"
            />
          </ValidationProvider>

          <template v-if="couponType === 'url'">
            <h3>{{ titles.couponLandingUrl }}</h3>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{
                required: true,
                regex: urlRegex
              }"
            >
              <v-text-field
                v-model="campaign.couponLandingUrl"
                :error-messages="errors"
                required
                :placeholder="placeholder.couponLandingUrl"
                outlined
              >
                <template v-slot:append-outer>
                  <v-tooltip
                    v-if="campaign.couponLandingUrl && errors.length === 0"
                    bottom
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        v-on="{ ...onTooltip }"
                        icon
                        @click="openInNewTab(campaign.couponLandingUrl)"
                      >
                        <v-icon v-on="onTooltip">mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>새탭에서 링크 열기</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </ValidationProvider>

            <h3>{{ titles.maxUses }}</h3>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{
                required: true,
                numeric: true,
                min_value: 1
              }"
            >
              <v-text-field
                v-model="campaign.maxUses"
                persistent-hint
                clearable
                :placeholder="placeholder.maxUses"
                :error-messages="errors"
                type="number"
                suffix="회"
                outlined
                required
                @click:clear="campaign.maxUses = 1"
              />
            </ValidationProvider>
          </template>
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import { Instructions, Titles, CouponTypeList } from '@/config/admin/campaign';
import FormattedTextField from '@/components/FormattedTextField.vue';
import { CouponShop } from '@/model/admin/CouponShop';
import { getCouponShopsApi } from '@/api/admin/CouponShopAPI';
import CampaignStep2TypeCouponBranchs from './CampaignStep2TypeCouponBranchs.vue';
import { getMegaboxPlacesApi } from '@/api/common/PartnerAPI';

export default {
  components: {
    ValidationProvider,
    FormattedTextField,
    CampaignStep2TypeCouponBranchs
  },
  data() {
    return {
      couponMinPrice: 500,
      couponShopList: [],
      displayPeriodModal: [false, false],
      displayMegaboxPlaceModal: false,
      storeLocationList: [], // 1depth
      couponType: 'download',
      urlRegex:
        'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
      expandCouponOptions: false
    };
  },
  async created() {
    if (this.campaign.couponLandingUrl) {
      this.couponType = 'url';
    }
    await Promise.all([this.fetchCouponShop(), this.fetchMegaboxPlace()]);
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit'
    }),
    titles() {
      return Titles.rs2.coupon;
    },
    placeholder() {
      return Instructions.rs2.coupon;
    },
    couponTypeList() {
      return CouponTypeList;
    },
    minDate() {
      return this.$moment().format('YYYY-MM-DD');
    },
    groupedStoreList() {
      if (!this.storeLocationList) return [];

      return this.storeLocationList.reduce(
        (result, item) => ({
          ...result,
          [item['area_nm']]: [...(result[item['area_nm']] || []), item]
        }),
        {}
      );
    },
    selectedBranchLabel() {
      const branchLeng = this.campaign.couponBranches.length;

      if (!branchLeng) return '';

      const firstBranch = this.storeLocationList.find(
        item => item.brch_no === this.campaign.couponBranches[0]
      );
      const firstBranchName = firstBranch ? firstBranch.brch_nm : '';
      const branchCnt = branchLeng - 1 > 0 ? `외 (${branchLeng - 1})` : '';

      return `${firstBranchName}${branchCnt}`;
    }
  },
  methods: {
    // 지점 목록 조회
    async fetchCouponShop() {
      // TODO 지점 목록 조회 API 연결
      const [error, data] = await getCouponShopsApi(this.campaign.userId);
      if (error) {
        console.log(error);
        return;
      }
      const { coupon_shops } = data;
      this.couponShopList = coupon_shops.map(couponShop => {
        const newCouponShop = new CouponShop(couponShop);
        return newCouponShop;
      });
    },
    async fetchMegaboxPlace() {
      this.storeLocationList = [];

      const [error, data] = await getMegaboxPlacesApi();
      if (error) {
        console.error(error);
      } else {
        const { megabox_places } = data;
        this.storeLocationList = megabox_places || [];
      }
    },
    handleCouponShopChange(shop) {
      this.campaign.couponShop = shop || null;
      this.campaign.couponShopId = shop?.id || null;
      this.campaign.couponShopAddress = shop?.address || null;
      this.campaign.couponShopName = shop?.name || null;
      this.campaign.couponShopImageUrl = shop?.logo_image?.url || null;
    },
    handleCouponTypeChange() {
      this.campaign.couponLandingUrl = '';
      this.campaign.maxUses = 1;
    },
    openInNewTab(landingUrl) {
      window.open(landingUrl, '_blank');
    }
  }
};
</script>
