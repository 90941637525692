<template>
  <v-dialog :value="dialog" max-width="900" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>프로모션 상세</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <template v-if="!!promotion">
        <v-card-text class="py-5">
          <a-descriptions bordered>
            <a-descriptions-item label="프로모션 명" :span="2">
              {{ promotion.title }}
            </a-descriptions-item>

            <a-descriptions-item label="프로모션 상태">
              <v-chip
                :color="promotion.statusInfo.color"
                label
                class="ma-1"
                outlined
              >
                {{ promotion.statusInfo.text }}
              </v-chip>
            </a-descriptions-item>

            <a-descriptions-item label="신청자 이름" :span="2">
              {{ promotion.name }}
            </a-descriptions-item>

            <a-descriptions-item label="신청자 이메일">
              {{ promotion.email }}
            </a-descriptions-item>

            <a-descriptions-item label="신청자 연락처" :span="2">
              {{ promotion.businessName || '-' }}
            </a-descriptions-item>

            <a-descriptions-item label="신청자 상호명">
              {{ promotion.businessName || '-' }}
            </a-descriptions-item>

            <a-descriptions-item label="신청일시" :span="2">
              {{ $moment(promotion.createdAt).format('YYYY-MM-DD H:mm:s') }}
            </a-descriptions-item>

            <a-descriptions-item label="수정일시">
              {{ $moment(promotion.updatedAt).format('YYYY-MM-DD H:mm:s') }}
            </a-descriptions-item>
          </a-descriptions>

          <a-descriptions layout="vertical" bordered class="mt-0">
            <a-descriptions-item :span="3">
              <div slot="label" class="row ma-0 align-center">
                코멘트
                <div class="col text-right" v-if="promotion.repliedAt">
                  코멘트 작성 일시 :
                  {{ $moment(promotion.repliedAt).format('YYYY-MM-DD H:mm:s') }}
                </div>
              </div>
              <v-textarea
                v-model="reply"
                :filled="promotion.repliedAt !== null"
                :hide-details="true"
                flat
                class="text-subtitle-2 pa-0"
                rows="10"
              />
            </a-descriptions-item>
          </a-descriptions>
        </v-card-text>
      </template>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="secondary"
          @click="handleRegisterClick()"
          :loading="loading"
          :disabled="!isChangeReply"
        >
          저장
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="closeDialog"
          dark
          :disabled="loading"
        >
          취소
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { setPromotionReplyApi } from '@/api/admin/InquiryApi';

export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    },
    statusList: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters({}),
    isChangeReply() {
      const org = JSON.stringify(this.promotion.reply);
      const temp = JSON.stringify(this.reply);
      return org !== temp;
    }
  },
  data() {
    return {
      loading: false,
      promotion: null,
      reply: ''
    };
  },
  created() {
    this.promotion = this.record
      ? {
          ...this.record
        }
      : null;
    this.reply = this.promotion?.reply ?? '';
  },
  methods: {
    ...mapActions({}),
    closeDialog() {
      this.$emit('close');
    },
    async handleRegisterClick() {
      if (!this.isChangeReply) return;
      this.loading = true;

      const [error] = await setPromotionReplyApi(this.record.id, {
        reply: this.reply
      });
      this.loading = false;

      if (error) {
        this.$Swal.fire({
          icon: 'error',
          title: '요청 실패',
          html: '잠시 후 재시도 해주세요.',
          confirmButtonText: '확인'
        });
      } else {
        this.promotion.reply = this.reply;
        this.$emit('index');
        this.$Swal.fire({
          icon: 'success',
          title: '요청 완료',
          html: '코멘트가 저장되었습니다.',
          confirmButtonText: '확인'
        });
      }
    }
  }
};
</script>

<style></style>
