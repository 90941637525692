import axios from 'axios';

// 앱 카테고리 목록
export async function getAppCategoriesApi() {
  try {
    const { data } = await axios.get(`/dd/categories`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

//  카테고리에 해당된 앱 목록
export async function getCategoryAppsApi(params) {
  try {
    const { data } = await axios.get(`/dd/category-apps`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// 이름에 해당된 앱 목록
export async function getNameAppsApi(params) {
  try {
    const { data } = await axios.get(`/dd/apps`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

// 변환하기
export async function transformAppsApi(params) {
  try {
    const { data } = await axios.post(`/dd/apps-transform`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
