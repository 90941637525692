<template>
  <v-dialog v-model="dialog" max-width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>셋톱박스 {{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-5">
        <ValidationObserver :ref="refKey">
          <v-row dense class="mb-5">
            <CustomGridItem title="별명" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="settopBox.name"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="ex) 엘리베이터 앞.. , 1번"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <!-- UID, 연결 상태, 재생 상태 -->
            <template v-if="settopBox.id">
              <CustomGridItem
                title="연결 상태"
                outlined
                :md="6"
                dense
                :titleDescription="titleDescription.deviceConnectionStatusInfo"
              >
                <template v-slot:content>
                  <v-chip
                    v-if="settopBox.deviceConnectionStatusInfo"
                    class="ml-2"
                    label
                    :color="settopBox.deviceConnectionStatusInfo.color"
                    outlined
                  >
                    {{ settopBox.deviceConnectionStatusInfo.text }}
                  </v-chip>
                </template>
              </CustomGridItem>

              <CustomGridItem
                title="재생 상태"
                outlined
                :md="6"
                dense
                :titleDescription="titleDescription.devicePlayStatusInfo"
              >
                <template v-slot:content>
                  <v-chip
                    v-if="settopBox.devicePlayStatusInfo"
                    class="ml-2"
                    label
                    :color="settopBox.devicePlayStatusInfo.color"
                    outlined
                  >
                    {{ settopBox.devicePlayStatusInfo.text }}
                  </v-chip>
                </template>
              </CustomGridItem>
            </template>

            <CustomGridItem title="매체" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.didBranchId"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="didBranches"
                    placeholder="매체 선택"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="UID" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="settopBox.uid"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="UID"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <!-- <CustomGridItem title="MAC 주소" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, regex_mac_address: macAddressRegx }"
                >
                  <CustomInput
                    v-model="settopBox.macAddress"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="MAC 주소"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem> -->

            <CustomGridItem title="일간 구독 횟수" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.dailySubscribeCount"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="일간 최대 설정 가능한 구독구좌 횟수"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="일간 금액"
              outlined
              :md="6"
              dense
              :titleDescription="titleDescription.costPerDay"
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.costPerDay"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="원"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="기준 시간 (초)" outlined :md="6">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.costStandardSecond"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="초"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              :title="`금액 (${settopBox.costStandardSecond}초)`"
              outlined
              :md="6"
              dense
              :titleDescription="titleDescription.costPerSecond"
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.costPerSecond"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="원"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>

          <h4 class="subtitle-1 font-weight-bold">
            앱 설정
            <span class="info--text text-caption ml-2">
              셋톱박스에 내려보낼 앱 설정값입니다.
            </span>
          </h4>
          <v-row dense class="mb-5">
            <CustomGridItem title="앱" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.didAppId"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="didApps"
                    placeholder="앱 선택"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.name }}

                      <v-chip
                        x-small
                        outlined
                        label
                        secondary
                        class="ml-1 mr-0 font-weight-bold"
                      >
                        <i>V . </i>
                        {{ item.version }}
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.name }}

                      <v-chip
                        x-small
                        outlined
                        label
                        secondary
                        class="ml-1 mr-0 font-weight-bold"
                      >
                        <i>V . </i>
                        {{ item.version }}
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="앱 업데이트 유무" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.appUpdateStatus"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    :items="AppUpdateStatus"
                    placeholder="앱 업데이트 유무 선택"
                    required
                    outlined
                    dense
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              v-if="settopBox.id"
              title="앱 다운로드 링크"
              outlined
              :md="12"
            >
              <template v-slot:content>
                <v-col>
                  {{ settopBox.appLink }}
                </v-col>
              </template>
            </CustomGridItem>
          </v-row>

          <h4 class="subtitle-1 font-weight-bold">
            상세 설정
            <span class="info--text text-caption ml-2">
              셋톱박스에 내려보낼 설정값입니다.
            </span>
          </h4>
          <v-row dense class="mb-5">
            <!-- config정보 -->
            <!-- <CustomGridItem
              title="상태"
              outlined
              :md="12"
              dense
              :titleDescription="titleDescription.configStatus"
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.config.status"
                    :items="SettopBoxStatus"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="상태 선텍"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem> -->
            <CustomGridItem title="설정 통신 주기" outlined :md="7">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.config.configInterval"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
            <CustomGridItem title="단위" outlined :md="5">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.config.configIntervalUnit"
                    :items="TimeUnitOptions"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="단위 선텍"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="캠페인 통신 주기" outlined :md="7">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.config.campaignInterval"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="단위" outlined :md="5">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.config.campaignIntervalUnit"
                    :items="TimeUnitOptions"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="단위 선텍"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="로그 통신 주기" outlined :md="7">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.config.logInterval"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="단위" outlined :md="5">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.config.logIntervalUnit"
                    :items="TimeUnitOptions"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="단위 선텍"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="와이파이 재접속 주기" outlined :md="7">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, numeric: true, min_value: 1 }"
                >
                  <CustomInput
                    v-model="settopBox.config.wifircnInterval"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    type="number"
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="단위" outlined :md="5">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="settopBox.config.wifircnIntervalUnit"
                    :items="TimeUnitOptions"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="단위 선텍"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="자동 재부팅 시간"
              outlined
              dense
              :titleDescription="titleDescription.rebootTime"
            >
              <template v-slot:content>
                <v-menu
                  ref="timePicker"
                  v-model="rebootTimeDialog"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                      <v-text-field
                        v-model="settopBox.config.rebootTime"
                        v-bind="
                          $getAttributes({
                            errors: errors,
                            placeholder: '재부팅 시간',
                            prependInnerIcon: 'mdi-clock-outline',
                            label: '재부팅 시간',
                            disabled: dialogLoading,
                            persistentHint: true,
                            ...attrs
                          })
                        "
                        v-on="on"
                        readonly
                      />
                    </ValidationProvider>
                  </template>

                  <TimePicker
                    v-model="settopBox.config.rebootTime"
                    :show="rebootTimeDialog"
                    :minuteUnit="60"
                    @close="rebootTimeDialog = false"
                  />
                </v-menu>
              </template>
            </CustomGridItem>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-center">
        <v-btn secondary outlined @click="handleCloseClick"> 닫기 </v-btn>
        <v-btn
          color="secondary"
          @click="handleSaveClick()"
          :loading="dialogLoading"
          :disabled="
            dialogLoading ||
            (settopBox.id ? !this.settopBox.isChanged() : false)
          "
        >
          {{ titleText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import {
  SettopBoxStatus,
  TimeUnitOptions,
  AppUpdateStatus
} from '@/config/admin/campaign-did';
import {
  createDidSettopBoxApi,
  updateDidSettopBoxApi
} from '@/api/admin/DidSettopBoxAPI';
import { getDidAppsApi } from '@/api/admin/DidAppAPI';
import CustomInput from '@/components/common/CustomInput.vue';
import TimePicker from '@/components/common/TimePicker.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomGridItem,
    CustomInput,
    TimePicker
  },
  data() {
    return {
      SettopBoxStatus: SettopBoxStatus,
      TimeUnitOptions: TimeUnitOptions,
      AppUpdateStatus: AppUpdateStatus,
      didApps: [],
      times: [],
      rebootTimeDialog: false,
      refKey: 'didSettopBoxDialog',
      macAddressRegx: /([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}/
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didSettopBox/dialog',
      loading: 'didSettopBox/loading',
      settopBox: 'didSettopBox/settopBox',
      didBranches: 'didSettopBox/didBranches'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return !this.settopBox.id ? '등록' : '수정';
    },
    minEndTimeHour() {
      if (!this.settopBox.openAt) return 0;
      const minHour = this.settopBox.openAt?.split(':')[0];
      return Number(minHour) + 1;
    },
    titleDescription() {
      const msgInfo = {
        // 연결 상태
        deviceConnectionStatusInfo: `셋톱박스 디바이스의 연결 상태를 확인합니다.`,
        // 재생 상태
        devicePlayStatusInfo: `셋톱박스 디바이스의 재생상태를 확인합니다.`,
        // 설정 상태
        configStatus: `셋톱박스 디바이스의 설정상태를 확인합니다.<br>
          <i class='red--text'>셋톱박스에 내려보낼 상태값</i>입니다.<br>
          캠페인 등록 시 <i class='red--text'>재생으로 설정된 셋톱박스만</i> 선택가능합니다. `,
        costPerDay: `캠페인 일정 선택시 <i class='red--text'>일별로 구매시</i> 측정될 가격입니다`,
        costPerSecond: `캠페인 일정 선택시 <i class='red--text'>초 단위로 구매시</i> 측정될 가격입니다.`,
        rebootTime: `해당 시간에 셋톱박스를 자동 재부팅 합니다.`
      };

      for (const key in msgInfo) {
        msgInfo[key] = `<div class='subtitle-2'>${msgInfo[key]}</div>`;
      }
      return msgInfo;
    }
  },
  watch: {
    dialog() {
      this.loading.dialog = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  mounted() {
    if (!this.didBranches.length) {
      this.fetchDidBranchOptions();
    }
    this.fetchDidAppOptions();
  },
  methods: {
    ...mapActions({
      closeDialog: 'didSettopBox/closeDialog',
      fetchDidBranchOptions: 'didSettopBox/fetchDidBranchOptions'
    }),
    async fetchDidAppOptions() {
      let options = [];

      const [error, data] = await getDidAppsApi();

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { apps } = data;

        options = apps.map(app => {
          return {
            id: app.id,
            name: app.name,
            version: app.version
          };
        });
      }

      this.didApps = options;
    },
    /** @description: 셋톱박스 저장 */
    async handleSaveClick() {
      if (this.dialogLoading) return;

      const valid = await this.$refs[this.refKey].validate();

      if (!valid) return;

      if (!this.settopBox.isChanged()) {
        this.$Swal.fire({
          icon: 'info',
          title: `셋톱박스 ${this.titleText} `,
          html: '<h3>변경 사항이 없습니다.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      this.loading.dialog = true;

      const saveData = this.settopBox.id
        ? this.settopBox.getUpdatePayload()
        : this.settopBox.getCreatePayload();

      const [error] = this.settopBox.id
        ? await updateDidSettopBoxApi(saveData, this.settopBox.id)
        : await createDidSettopBoxApi(saveData);

      if (error) {
        console.log(error);
        const { data } = error;
        this.$Swal.fire({
          icon: 'error',
          title: `셋톱박스 ${this.titleText} 요청 실패`,
          html: `${data?.message ?? '<h3>잠시 후 다시 시도해주세요.</h3>'}`,
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
      } else {
        this.$emit('refresh');

        this.$Swal
          .fire({
            icon: 'success',
            title: `셋톱박스 ${this.titleText} `,
            html: `<h3>셋톱박스가 ${this.titleText}되었습니다.</h3>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          })
          .then(() => this.closeDialog());
      }

      this.loading.dialog = false;
    },
    /** @description: 닫기 */
    handleCloseClick() {
      // 변경사항 있는경우 확인
      if (this.settopBox.isChanged()) {
        this.$Swal
          .fire({
            icon: 'info',
            title: `셋톱박스 ${this.titleText} `,
            html: '<h3>변경 사항이 있습니다.<br/> 저장하지 않고 닫으시겠습니까?</h3>',
            showConfirmButton: true,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.closeDialog();
            }
          });
      } else {
        this.closeDialog();
      }
    }
  }
};
</script>
