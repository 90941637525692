<template>
  <v-row class="align-center">
    <v-col>
      <h2>
        {{ Steps[currentStep - 1].text
        }}<span v-if="currentStep === 2 && campaign.rs1.campaignType">
          |
          {{
            CampaignTypeList.find(v => v.value === campaign.rs1.campaignType)
              .text
          }}</span
        >
      </h2>
    </v-col>

    <v-col cols="12" lg="6" xl="4" class="ml-auto">
      <v-stepper
        :value="currentStep"
        style="background-color:transparent;box-shadow:none;"
      >
        <v-stepper-header>
          <template v-for="step in Steps">
            <v-stepper-step
              :key="`${step.value}-step`"
              :complete="currentStep > step.value"
              :step="step.value"
              color="secondary"
              :editable="isDialogTypeEdit && isEditableStep(step.value)"
              :rules="[() => step.isRules]"
              @click="validateStep(step.value)"
            >
              {{ step.text }}
              <small v-if="!step.isRules">미완료</small>
            </v-stepper-step>

            <v-divider
              v-if="step.value !== Steps.length"
              :key="step.value"
            ></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CampaignTypeList, Steps } from '@/config/admin/campaign';

export default {
  computed: {
    ...mapGetters({
      currentStep: 'campaign/currentStep',
      campaign: 'campaign/campaign',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit'
    }),
    Steps() {
      return Steps;
    },
    CampaignTypeList() {
      return CampaignTypeList;
    },
    isCampaignTypeMessage() {
      return this.campaign.rs1.campaignType === 'message';
    }
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'campaign/setCurrentStep'
    }),
    isEditableStep(step) {
      if (this.isCampaignTypeMessage && step === 4) {
        return false;
      }
      return true;
    },
    // 수정시 단계롤 바로 건너 뛸때, validation 체크를 진행합니다.
    async validateStep(step) {
      // 수정 가능 여부를 확인합니다.
      if (this.isEditableStep(step) === false) {
        return false;
      }
      // 현재 컴포넌트의 부모컴포넌트에서 자식컴포넌트를 조회하여 설정한 refs 를 가져옵니다.

      const refs =
        this.$parent.$children.find(
          v => v.$refs[`obs${this.currentStep}`] != undefined
        )?.$refs || null;

      if (refs === null) {
        return false;
      }

      // 설정한 vee-validate observer 의 refs 를 가져와 validate 를 진행합니다.
      const valid = await refs[`obs${this.currentStep}`].validate();
      if (valid) {
        this.setCurrentStep(step);
      }
    }
  }
};
</script>

<style></style>
