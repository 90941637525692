import moment from 'moment';
import { getCamelKeys } from '@/utils/functions';

export class DidSchedule {
  id = null; // schedule id

  settopBoxId = null;
  settopBox = null;
  didBranchId = null;
  didBranch = null;
  didCampaignServiceId = null;
  didCampaignService = null;
  didCampaignId = null;
  didCampaign = null;
  campaignResourceId = null;
  campaignResource = null;
  startAt = null;
  endAt = null;
  isBroadcast = false;

  constructor(init, setOrigin = false) {
    if (!init) {
      if (setOrigin) {
        // ofigin에 값 세팅여부
        this.origin = new DidSchedule(this);
      }
      return;
    }

    const covObject = getCamelKeys(init);

    for (const key in covObject) {
      let val = covObject[key];

      if (['isBroadcast'].includes(key)) {
        val = val ? true : false;
      }

      if (['createdAt', 'updatedAt'].includes(key)) {
        val = val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : null;
      }

      this[key] = val;
    }

    if (setOrigin) {
      // ofigin에 값 세팅여부
      this.origin = new DidSchedule(this);
    }
  }
  /** @description: 생성 날짜 */
  get createdDate() {
    return this.createdAt ? moment(this.createdAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 생성 시간 */
  get createdTime() {
    return this.createdAt ? moment(this.createdAt).format('HH:mm:ss') : '-';
  }
  /** @description: 수정 날짜 */
  get updatedDate() {
    return this.updatedAt ? moment(this.updatedAt).format('YYYY-MM-DD') : '-';
  }
  /** @description: 수정 시간 */
  get updatedTime() {
    return this.updatedAt ? moment(this.updatedAt).format('HH:mm:ss') : '-';
  }
  /** @description: 셋톱박스 이름 */
  get settopBoxName() {
    return this.settopBox ? this.settopBox.name : '';
  }
  /** @description: 매체그룹 이름 */
  get didMediaGroupName() {
    return this.didBranch ? this.didBranch.didMediaGroup?.name : '';
  }
  /** @description: 매체 이름 */
  get didBranchName() {
    return this.didBranch ? this.didBranch.name : '';
  }
  /** @description: 캠페인 서비스 이름 */
  get didCampaignServiceName() {
    return this.didCampaignService ? this.didCampaignService.name : '';
  }
}
