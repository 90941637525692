<template>
  <v-dialog
    :value="dialog"
    max-width="600"
    persistent
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver :ref="refKey">
          <v-row class="mt-3">
            <v-col class="error--text" v-if="isPaymentMethodFree">
              * 무상포인트 지급취소시 취소 금액 변경이 불가능합니다.
            </v-col>
            <!-- 결제 취소 요청일 경우 -->
            <!-- 취소요청 금액이 결제 금액과 동일하면 -> 취소요청 -->
            <!-- 취소요청 금액이 결제 금액과 동일하지 않으면 -> 부분취소요청 -->
            <template v-if="isCancelStatus">
              <v-col cols="12" class="pt-1">
                <a-descriptions bordered>
                  <a-descriptions-item label="회원 포인트" :span="3">
                    <v-row align="center">
                      <span class="col text-right">
                        {{ userPoint.toLocaleString() }}
                      </span>
                      <v-icon> mdi-alpha-p-circle-outline </v-icon>
                    </v-row>
                  </a-descriptions-item>
                  <a-descriptions-item label="승인 금액" :span="3">
                    <v-row align="center">
                      <span class="col text-right">
                        {{
                          record.validAmount
                            ? record.validAmount.toLocaleString()
                            : 0
                        }}
                        <span v-if="!isPaymentMethodFree" class="text-caption">
                          ( <v-icon>mdi-alpha-p-circle-outline</v-icon> :
                          {{ validPoint.toLocaleString() }}
                          )
                        </span>
                      </span>
                      <strong v-if="!isPaymentMethodFree"> 원 </strong>
                    </v-row>
                  </a-descriptions-item>
                </a-descriptions>
              </v-col>
              <v-col cols="12" class="pt-3">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    numeric: true,
                    min_value_krw: 1,
                    max_value: cancellablePoint
                  }"
                >
                  <v-text-field
                    ref="cancelPoint"
                    @focus="inputFocus($event.target)"
                    v-model="cancelPoint"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="취소요청할 포인트를 입력해주세요."
                    label="취소 요청 포인트"
                    required
                    outlined
                    dense
                    type="number"
                    append-icon="mdi-alpha-p-circle-outline"
                    :readonly="isPaymentMethodFree"
                    :filled="isPaymentMethodFree"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-0" v-if="!isPaymentMethodFree">
                <a-descriptions bordered>
                  <a-descriptions-item label="취소 요청 금액" :span="3">
                    <v-row align="center">
                      <span class="col text-right">
                        {{ cancelAmount.toLocaleString() }}
                      </span>
                      <strong> 원 </strong>
                    </v-row>
                  </a-descriptions-item>
                </a-descriptions>
              </v-col>
            </template>
            <div v-else class="col pb-0 pt-5 text-center">
              <v-icon large class="my-2">mdi-information-outline</v-icon>

              <h3>해당 결제 정보를 삭제 하시겠습니까?</h3>
              <p class="error--text">
                * 삭제시 결제내역에서 더이상 표시 되지않습니다.
              </p>
            </div>
            <!-- <v-col cols="12" class="pt-3">
              <h3>결제 상태 선택</h3>

              <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  max: 100
                }"
              >
                <v-radio-group
                  v-model="status"
                  class="d-inline-block mt-0"
                  hide-details
                >
                  <template v-for="(m, index) in approveStatusList">
                    <v-radio
                      :value="m.value"
                      class="ma-3 align-center row"
                      :color="m.colorCode"
                      :key="index"
                    >
                      <template v-slot:label>
                        <v-chip outlined label :color="m.color" class="px-5">
                          {{ m.text }}
                        </v-chip>
                      </template>
                    </v-radio>
                  </template>

                  <small v-if="errors.length" class="col pt-0 error--text">
                    {{ errors[0] }}
                  </small>
                </v-radio-group>
              </ValidationProvider>
            </v-col> -->

            <!-- <v-col cols="12" class="pt-0" v-if="isCancelStatus">
              <h3>취소 사유</h3>

              <ValidationProvider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  max: 100
                }"
              >
                <v-textarea
                  v-model="cancelText"
                  outlined
                  placeholder="결제 취소사유를 입력해주세요.(최대 100글자)"
                  rows="4"
                  row-height="30"
                  :error-messages="errors"
                  :hide-details="!errors.length"
                />
              </ValidationProvider>
            </v-col> -->
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-row class="ma-0">
          <v-col cols="12" class="text-center">
            <v-btn class="mr-2" depressed @click="closeDialog"> 닫기 </v-btn>
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              @click="handleConfirmClick()"
            >
              {{ saveBtnLabel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  cancelPointOrderAPI,
  deletePointOrderAPI,
  cancelPartialPointOrderAPI
} from '@/api/admin/PointAPI';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { getPointApi } from '@/api/admin/UserAPI';

export default {
  components: { ValidationProvider, ValidationObserver },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    },
    statusList: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'C'
    }
  },
  computed: {
    ...mapGetters({
      form: 'advertiser/form'
    }),
    // 무상포인트 환불 여부
    isPaymentMethodFree() {
      return this.record.paymentMethod === 'free';
    },
    // 취소 요청
    isCancelStatus() {
      return this.status === 'C';
    },
    // 삭제요청
    isDeleteStatus() {
      return this.status === 'D';
    },
    dialogTitle() {
      return this.title
        ? this.title
        : this.isDeleteStatus
        ? '결제 삭제 요청'
        : '결제 취소 요청';
    },
    saveBtnLabel() {
      return this.isDeleteStatus ? '삭제' : '저장';
    },
    // 승인금액 부가세 제외한 포인트
    validPoint() {
      // 무상 포인트일경우 승인금액 그대로
      let convValidAmount = this.record.validAmount;
      // 유상 결제 취소시 부가세 재외한 금액
      if (!this.isPaymentMethodFree) {
        convValidAmount = (this.record.validAmount / 11) * 10; // 승인가능 금액 - 부가세
      }
      return Math.round(convValidAmount);
    },
    // 승인금액 OR 회원 유상 포인트중 더 작은금액으로 환불 가능
    cancellablePoint() {
      return Math.min(Math.round(this.validPoint), this.userPoint);
    },
    cancelAmount() {
      // 유상 결제 취소시 부가세 재외한 금액
      // 무상 포인트일경우 승인금액 그대로
      const res = this.isPaymentMethodFree
        ? this.cancelPoint
        : this.cancelPoint * 1.1;
      return Math.round(res);
    }
  },
  data() {
    return {
      loading: false,
      refKey: 'paymentStatusRef',
      userPoint: 0,
      cancelText: '',
      // cancelAmount: 0, // 취소요청 금액
      cancelPoint: 0 // 취소요청 포인트
    };
  },
  async created() {
    this.loading = false;
    this.init();
    await this.getUserPoint();
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    init() {
      this.cancelText = '';
      // 무상 포인트 환불시 지급 전체 금액으로 취소
      this.cancelPoint = this.isPaymentMethodFree ? this.record.validAmount : 0;
      this.userPoint = 0;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    },
    // 회원 유상 포인트 조회
    async getUserPoint() {
      this.loading = true;

      let point = 0;
      const [error, data] = await getPointApi(this.record.userId);

      if (error) {
        console.error(error);
      } else {
        point = this.isPaymentMethodFree ? data.free_point : data.credit_point;
      }

      this.loading = false;

      this.userPoint = point;
    },
    // 상태값 변경
    async handleConfirmClick() {
      const { isValid } = await this.$refs[this.refKey].validateWithInfo();

      if (!isValid) return;
      // 결제 취소
      if (this.isCancelStatus) {
        let cancelMessage = `${
          this.isPaymentMethodFree ? '환불 포인트' : '취소 금액'
        } : ${this.cancelAmount} `;

        this.$Swal
          .fire({
            icon: 'info',
            title: this.title + '확인',
            html: `<h3>취소 내용을 확인해주세요.<br>${cancelMessage}</h3>`,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소',
            showLoaderOnConfirm: true,

            preConfirm: async () => {
              await this.cancelPointOrder();
            },
            allowOutsideClick: () => !this.$Swal.isLoading()
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$Swal.fire({
                icon: 'success',
                title: '결제 상태가 변경되었습니다.',
                showConfirmButton: false
              });
              this.$emit('index');
              this.closeDialog();
            }
          });
      }

      if (this.isDeleteStatus) {
        // 결제 삭제
        await this.deletePointOrder();
      }
    },
    // 결제 취소
    // 결제 상태 확인 -> 사용자 포인트 확인(환불가능 포인트 있는지 확인)
    // 결제 취소사유 -> 코멘트에 등록, 결제 상태 변경,
    async cancelPointOrder() {
      this.loading = true;

      const params = {
        cancelAmount: this.cancelAmount
        // cancelText: this.cancelText
      };

      const [error, data] =
        this.status === 'C' && this.cancelAmount === this.record.amount
          ? await cancelPointOrderAPI(this.record.orderNo, params) // 결제취소
          : await cancelPartialPointOrderAPI(this.record.orderNo, params); // 부분결제 취소

      if (error) {
        console.error(error);
        this.$Swal.showValidationMessage(error?.data?.message || `요청 실패`);
        return false;
      } else {
        return data;
      }
    },
    // 결제 삭제
    // 결제 상태 확인 -> 사용자 포인트 확인(환불가능 포인트 있는지 확인)
    // 결제 취소사유 -> 코멘트에 등록, 결제 상태 변경,
    async deletePointOrder() {
      this.loading = true;

      const [error, data] = await deletePointOrderAPI(this.record.orderNo);

      if (error) {
        console.error(error);
        this.$Swal.showValidationMessage(error?.data?.message || `요청 실패`);
        return false;
      } else {
        this.$Swal.fire({
          icon: 'success',
          title: '결제 상태가 삭제되었습니다.',
          showConfirmButton: false
        });
        this.$emit('index');
        this.closeDialog();
        return data;
      }
    },
    inputFocus(target) {
      target.select();
    }
  }
};
</script>
<style lang="scss"></style>
