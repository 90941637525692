import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import mediaRoutes from './media/media';
import advertiserRoutes from './advertiser/advertiser';
import partnerRoutes from './partner/partner';
import didRoutes from './did/did';
import adminRoutes from './admin/admin';
import superAdminRoutes from './admin/super-admin';
import Login from '../views/login/Login.vue';
import payment from './payment';

Vue.use(VueRouter);

const home = {
  1: 'MediaHome',
  2: 'AdvertiserReport',
  3: 'AdminDashboard',
  4: 'AdminDashboard',
  5: 'MediaHome',
  6: 'AdminCampaign',
  8: 'PartnerReport',
  9: 'DidCampaign', //didMediaGroup
  10: 'DidCampaign' //didBranch
};

const routes = [
  ...advertiserRoutes,
  ...mediaRoutes,
  ...adminRoutes,
  ...payment,
  ...partnerRoutes,
  ...didRoutes,
  ...superAdminRoutes,
  {
    path: '/login',
    alias: ['/'],
    name: 'login',
    components: {
      public: Login
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        const user = store.getters['auth/user'];
        return next({
          name: home[user.level]
        });
      }
      next();
    }
  },
  {
    path: '/bridge/:token',
    name: 'bridge',
    components: {
      public: Login
    },
    beforeEnter: async (to, from, next) => {
      if (to.params.token == null) {
        return false;
      }

      await store.dispatch('auth/attempt', to.params.token);
      const user = store.getters['auth/user'];
      return next({
        name: home[user.level]
      });
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  // 로그인을 제외한 router 요청시 session을 체크합니다.
  if (from.name !== 'login') {
    await store.dispatch('auth/session', localStorage.getItem('token'));
  }
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (!store.getters['auth/authenticated']) {
      return next({
        name: 'login'
      });
    } else {
      //인증완료 이후 프로세스
      const user = store.getters['auth/user'];
      /**
       * 라우터 레벨 권한 체크
       */
      if (
        to.matched.some(route =>
          route.meta.requiresAuthLevel.includes(user.level)
        )
      ) {
        next();
      } else {
        next({
          name: 'login'
        });
      }
    }
  } else {
    //인증이 불필요한 라우트 ex) login
    next();
  }
});

export default router;
