<template>
  <v-speed-dial
    v-model="speedDial"
    transition="scale-transition"
    direction="top"
    bottom
    right
    fixed
  >
    <template v-slot:activator>
      <v-btn v-model="speedDial" color="secondary" fab small :loading="loading">
        <v-icon v-if="speedDial">
          mdi-close
        </v-icon>
        <v-icon v-else>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-tooltip
      :color="event.color"
      class="custom-tooltip"
      left
      v-for="(event, i) in events"
      :key="i"
    >
      <template #activator="{ on: onTooltip }">
        <v-btn
          fab
          small
          v-on="onTooltip"
          :color="event.color"
          @click="event.function(event.params)"
        >
          <v-icon>{{ event.icon }}</v-icon>
        </v-btn>
      </template>
      <span class="headline">{{ event.text }}</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
import { getMediaTagRawApi } from '@/api/admin/tag';
import { ExcelExport } from '@/utils/tools';
export default {
  props: {},
  data() {
    return {
      speedDial: false,
      selectedItem: null,
      loading: false,
      events: [
        {
          text: 'Raw 데이터 다운로드',
          icon: 'mdi-file-document',
          color: 'secondary',
          function: this.handleRawDataExportClick
        }
      ]
    };
  },
  methods: {
    async handleRawDataExportClick() {
      this.loading = true;

      const [error, data] = await getMediaTagRawApi();
      if (error) {
        console.error(error);
      } else {
        const { stats, columns } = data;

        ExcelExport(
          [columns],
          stats,
          `매체태그_${this.$moment().format('YYYY-MM-DD')}`
        );
      }

      this.loading = false;
    }
  }
};
</script>

<style></style>
