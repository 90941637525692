<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="광고 송출 상태">
        <a-select :style="{ minWidth: '150px' }" v-model="filters.active">
          <a-select-option
            v-for="(item, index) in MediaActive"
            :key="index"
            :value="item.value"
          >
            <a-tag :color="item.color"> {{ item.text }} </a-tag>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="그룹유형">
        <a-select :style="{ minWidth: '150px' }" v-model="filters.type">
          <a-select-option
            v-for="(item, index) in MediaGroupTypeList"
            :key="index"
            :value="item.value"
          >
            <a-tag :color="item.color"> {{ item.text }} </a-tag>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="이메일">
        <a-input v-model="filters.email" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <a-descriptions-item label="매체그룹명">
        <a-input v-model="filters.name" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <a-descriptions-item label="연락처">
        <a-input v-model="filters.phone" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <a-descriptions-item label="등록일">
        <a-range-picker
          v-model="filters.dates"
          :ranges="{
            오늘: [$moment(), $moment()],
          }"
          format="YYYY-MM-DD"
        />
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>
    </div>
  </div>
</template>
<script>
import { MediaActive, MediaGroupTypeList } from '@/config/media';

export default {
  props: {
    filters: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      MediaActive: MediaActive,
      MediaGroupTypeList: MediaGroupTypeList,
    };
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
