<template>
  <validation-observer
    tag="form"
    ref="obs3"
    v-slot="{ invalid }"
    @submit.prevent="submit()"
  >
    <v-row class="pa-5" style="background-color:white;">
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab v-for="(tab, index) in tabs" :key="index">
            <v-icon left>
              {{ tab.icon }}
            </v-icon>
            {{ tab.text }}
          </v-tab>
          <v-tabs-items v-model="tab" class="mt-5">
            <v-tab-item v-for="(tab, index) in tabs" :key="index">
              <v-card flat>
                <keep-alive>
                  <component v-bind:is="tab.component"></component>
                </keep-alive>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="4">
        <CampaignContentFooter :invalid="invalid" />
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CampaignContentFooter from './CampaignContentFooter.vue';
import InformationHover from '@/components/InformationHover.vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CampaignContentFooter,
    CampaignTargetTag: () => import('./CampaignStep3TargetTag'),
    CampaignTargetApp: () => import('./CampaignStep3TargetApp'),
    CampaignTargetLocation: () => import('./CampaignStep3TargetLocation'),
    InformationHover
  },
  props: {
    rs3: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          text: '태그 타겟',
          value: 'tag',
          icon: 'mdi-pound-box',
          component: 'CampaignTargetTag'
        },
        {
          text: '앱 타겟',
          value: 'app',
          icon: 'mdi-alpha-a-circle',
          component: 'CampaignTargetApp'
        },
        {
          text: '위치 타겟',
          value: 'location',
          icon: 'mdi-map-marker',
          component: 'CampaignTargetLocation'
        }
      ]
    };
  },
  methods: {
    submit() {
      return false;
    }
  }
};
</script>

<style></style>
