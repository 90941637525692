<template>
  <v-card>
    <!-- <v-card-subtitle>{{ title }}</v-card-subtitle> -->
    <!-- <v-divider class="mx-4"></v-divider> -->
    <v-card-text>
      <v-row>
        <v-col>
          <div
            v-show="!loading && stats.length > 0"
            :ref="chartRef"
            style="width: 100%; min-height: 350px;  max-height:1000px;"
          ></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    dateRanges: {
      type: Array,
      required: true
    },
    stats: {
      type: Array,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dates: [],
      chartReg: [],
      chart: null,
      chartTypes: [],
      loading: false
    };
  },
  computed: {
    chartRef() {
      return 'mediaLiveChartRef';
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isShowSymbol() {
      return this.dates.length > 30 ? false : true;
    }
  },
  mounted() {
    // console.log(`stats`, this.stats);
  },
  watch: {
    dateRanges() {
      // this.setReport();
    },
    stats(value) {
      if (value.length > 0) {
        this.setChart();
      }
    }
  },
  created() {
    this.initData();
    window.addEventListener('resize', this.resizeChart);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
    this.initChart();
  },
  methods: {
    initChart() {
      if (this.chart != null && this.chart != '' && this.chart != undefined) {
        this.chart.dispose();
      }
    },
    async setChart() {
      const chartData = await this.getStats();
      this.drawChart(chartData);
    },
    resizeChart() {
      if (this.chart != null && this.chart != undefined) {
        this.chart.resize();
      }
    },
    initData() {
      const chartTypes = [
        {
          text: '데이터',
          value: 'allow_location_total',
          color: '#0770FF'
        },
        {
          text: '사용자',
          value: 'allow_location_reach',
          color: '#0BC904'
        }
      ];
      this.chartTypes = chartTypes;
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },

    async getStats() {
      this.setDates();
      try {
        this.loading = true;
        const { dates, stats } = this;
        // console.log(stats);
        // 일별 데이터 체크 후 undefined 처리
        const chartData = dates.map(date => {
          const data = stats.filter(v => v.date === date.value);
          // console.log(`data`, data);
          if (data.length === 0) {
            return {
              date: date.value,
              allow_location_reach: 0,
              allow_location_total: 0
            };
          } else {
            return {
              date: date.value,
              allow_location_reach: data.reduce((acc, cur) => {
                return acc + cur.allow_location_reach;
              }, 0),
              allow_location_total: data.reduce((acc, cur) => {
                return acc + cur.allow_location_total;
              }, 0)
            };
          }
        });
        // console.log(`chartData`, chartData);
        return chartData;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    drawChart(chartData) {
      this.initChart();
      // 설정값 셋업
      const { chartTypes, title, $echarts, dates } = this;

      // 차트 생성
      const chartDom = this.$refs[this.chartRef];
      const myChart = $echarts.init(chartDom);

      const colors = chartTypes.map(chart => chart.color);

      const xAxis = [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          boundaryGap: false,
          data: dates.map(date => date.value) // 날짜
        }
      ];

      const legend = {
        data: chartTypes.map(chart => chart.text),
        bottom: 0,
        selected: {}
      };

      const series = [
        {
          name: '데이터',
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#0770FF'
          },
          areaStyle: {
            color: new $echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(58,77,233,0.8)'
              },
              {
                offset: 1,
                color: 'rgba(58,77,233,0.3)'
              }
            ])
          },
          data: chartData.map(data => data.allow_location_total)
        },
        {
          name: '사용자',
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#0BC904'
          },
          // areaStyle: {
          //   color: new $echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //     {
          //       offset: 0,
          //       color: 'rgba(11,201,4,0.3)'
          //     },
          //     {
          //       offset: 1,
          //       color: 'rgba(11,201,4,0.1)'
          //     }
          //   ])
          // },
          data: chartData.map(data => data.allow_location_reach)
        }
      ];

      //최솟값
      // const min = Math.min(
      //   ...[
      //     ...chartData
      //       .filter(v => v.background_reach > 0)
      //       .map(v => v.background_reach),
      //     ...chartData
      //       .filter(v => v.allow_location_reach > 0)
      //       .map(v => v.allow_location_reach)
      //   ]
      // ).toString();

      const option = {
        title: {
          text: this.showTitle ? this.title : null
        },
        color: colors,
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '3%',
          containLabel: true
        },
        toolbox: {
          right: '20px',
          bottom: '20px',
          feature: {
            dataView: { readOnly: true, title: '로우 데이터' },
            saveAsImage: {
              show: true,
              title: '이미지 저장',
              name: `${title} 차트`
            }
          }
        },
        legend: legend,
        xAxis: xAxis,
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true
            },
            axisLabel: {
              formatter: '{value}'
            }
            // min: min[0].padEnd(min.length, 0)
          }
        ],
        series: series
      };
      this.chart = myChart;
      option && myChart.setOption(option);
    },

    // 해당 값의 자릿수 단위별 최댓값
    getMax(chartData, type) {
      const max = Math.max(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );

      let digits = 0;
      if (max > 1) {
        digits = Math.pow(10, Math.ceil(max).toString().length);
        return Math.ceil(max / digits) * digits;
      } else if (max > 0 && max < 1) {
        // 소수점 자릿수 구하기
        const subMax = Number(
          max
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      } else {
        return 0;
      }
    },
    // 해당 값의 자릿수 단위별 최솟값
    getMin(chartData, type) {
      const min = Math.min(
        ...chartData.filter(data => data[type] > 0).map(data => data[type])
      );
      let digits = 0;
      if (min >= 1) {
        digits = Math.pow(10, Math.ceil(min).toString().length);
        // console.log(Math.ceil(min / digits) * digits);
        return Math.ceil(min / digits) * digits;
      } else {
        // 소수점 자릿수 구하기
        const subMax = Number(
          min
            .toFixed(2)
            .toString()
            .split('.')[1]
        );

        digits = Math.pow(10, Math.ceil(subMax).toString().length);
        return 0.01 * digits;
      }
    }
  }
};
</script>

<style></style>
