import axios from 'axios';

/**
 * @description: DID 매체 목록 전체 조회
 * */
export async function getDidBranchesApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/branches`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 목록 전체 조회
 * */
export async function getDidBranchApi(branchId) {
  try {
    const { data } = await axios.get(`/admin/did/branch/${branchId}`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 등록
 * */
export async function createDidBranchApi(params) {
  try {
    const { data } = await axios.post(`/admin/did/branch`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 수정
 * */
export async function updateDidBranchApi(params, branchId) {
  try {
    const { data } = await axios.put(`/admin/did/branch/${branchId}`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 활성상태 변경 - active
 * @param branchId: string
 * */
export async function updateDidBranchActiveApi(branchId, params) {
  try {
    const { data } = await axios.patch(
      `/admin/did/branch/${branchId}/active`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

/**
 * @description: DID 매체 활성상태 변경 - active
 * @param branchId: string
 * */
export async function updateDidBranchInactiveApi(branchId, params) {
  try {
    const { data } = await axios.patch(
      `/admin/did/branch/${branchId}/inactive`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 목록 전체 조회
 * */
export async function getDidBranchOptionsApi(params) {
  try {
    const { data } = await axios.get(`/admin/did/branches-combo`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: DID 매체 캠페인 동기화
 * */
export async function playSyncByBranchApi(branchId) {
  try {
    const { data } = await axios.get(`/admin/did/branch/${branchId}/play-sync`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
