import { getUserPointDetailApi } from '@/api/advertiser/UserAPI';

export default {
  namespaced: true,

  state: {
    loading: false,
    dialog: false,
    buckets: [],
    columns: [
      {
        title: '날짜',
        align: 'center',
        dataIndex: 'date',
      },
      {
        title: '소진 유형',
        dataIndex: 'type',
        align: 'center',
      },
      {
        title: '광고 횟수',
        dataIndex: 'count',
        align: 'center',
      },
      {
        title: '소진 금액',
        dataIndex: 'cost',
        align: 'right',
      },
    ],
    payload: {
      page: 1,
      perPage: 20,
      codes: ['CC000', 'CD000', 'CDR00'],
      campaignName: '',
      campaignType: ['push', 'message', 'rolling'],
      statusFlag: ['+', '-'],
      amount: [0, 100000000],
      dates: [],
    },
    pagination: {
      totalPage: 1,
      total: 0,
    },
    record: {},
    point: {},
  },
  getters: {
    columns(state) {
      return state.columns;
    },
    payload(state) {
      return state.payload;
    },
    pagination(state) {
      return state.pagination;
    },
    buckets(state) {
      return state.buckets;
    },
    loading(state) {
      return state.loading;
    },
    dialog(state) {
      return state.dialog;
    },
    record(state) {
      return state.record;
    },
    point(state) {
      return state.point;
    },
  },
  mutations: {
    SET_BUCKETS(state, value) {
      state.buckets = value;
    },
    SET_PAGINATION(state, value) {
      state.pagination = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_DIALOG(state, value) {
      state.dialog = value;
    },
    SET_RECORD(state, value) {
      state.record = value;
    },
    SET_POINT(state, value) {
      state.point = value;
    },
  },
  actions: {
    async index({ commit, getters }) {
      commit('SET_LOADING', true);

      const payload = {
        params: {
          campaignId: getters.record.campaignId,
          date: getters.record.transactionAt,
        },
      };

      const [error, data] = await getUserPointDetailApi(payload);
      if (error) {
        console.error(error);
      } else {
        const {
          detail: { data: points, last_page, total },
          point,
        } = data;

        const items = points.map((item, index) => {
          return {
            key: index,
            date: item.date,
            type: item.type,
            count: (item.sum_count * 1).toLocaleString(),
            cost: (item.sum_cost * 1).toLocaleString() + ' 원',
          };
        });

        commit('SET_PAGINATION', {
          totalPage: last_page,
          total: total,
        });

        commit('SET_POINT', point);
        commit('SET_BUCKETS', items);
        commit('SET_LOADING', false);
      }
    },
    openDialog({ commit, dispatch }, record) {
      commit('SET_RECORD', record);
      dispatch('index');
      commit('SET_DIALOG', true);
    },
    closeDialog({ commit }) {
      commit('SET_DIALOG', false);
    },
  },
};
