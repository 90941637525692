<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="{ ...on }"
        fab
        small
        :loading="loading"
        @click="excelExport"
        color="secondary"
      >
        <v-icon>mdi-table-arrow-down</v-icon>
      </v-btn>
    </template>
    <span class="headline"> 엑셀 다운로드 </span>
  </v-tooltip>
</template>

<script>
import { utils, writeFile } from 'xlsx';

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    campaigns: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      default: () => {}
    },
    searchString: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async excelExport() {
      if (this.campaigns.length > 0 === false) {
        this.errorAlert();
        return false;
      }
      // 서버 통신에서 로컬로 변경함
      // const { hashedCampaigns } = await this.getCampaigns();
      const { hashedCampaigns } = this.hashing(this.campaigns);
      // console.log(hashedCampaigns);
      // Using same variables as the above answer
      const Heading = [this.headers.map(v => v.title)];
      const headerKeys = this.headers.map(v => v.dataIndex);
      const Data = hashedCampaigns.map(campaigns => {
        const item = {};
        headerKeys.forEach(key => {
          item[key] = campaigns[key];
        });
        return item;
      });

      var wb = utils.book_new();

      var ws = utils.json_to_sheet(Data, {
        origin: 'A2',
        skipHeader: true
      });
      utils.sheet_add_aoa(ws, Heading); //heading: array of arrays

      utils.book_append_sheet(wb, ws);

      writeFile(wb, `캠페인_${this.$moment().format('YYYY-MM-DD')}.xlsx`);
    },
    async getCampaigns() {
      this.loading = true;
      const params = {
        ss: this.searchString,
        page: null,
        perPage: null,
        filters: this.filters
      };

      try {
        const { data } = await this.$axios.get(`/admin/campaigns-media`, {
          params: params
        });
        const { campaigns } = data;
        return this.hashing(campaigns);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    errorAlert() {
      this.$Swal.fire({
        html: '<h3>캠페인이 존재하지 않습니다.</h3>',
        icon: 'error',
        iconHtml: '!',
        confirmButtonText: '확인'
      });
    },
    hashing(campaigns) {
      const hashedCampaigns = campaigns.map(campaign => {
        const {
          media_groups: mediaGroups,
          campaign_type_push: push,
          advertiser_group: advertiserGroup,
          tags: tags,
          target_tags: targetTags
        } = campaign;

        const { advertiser } = advertiserGroup;

        const messageDateTime =
          (push?.message_date || false) && (push?.message_time || false)
            ? this.$moment(`${push.message_date} ${push.message_time}`).format(
                'YYYY-MM-DD HH:mm'
              )
            : '';

        return {
          origin: campaign,
          id: campaign.id,
          advertiserGroupName: advertiserGroup.name,
          advertiserName: advertiser.name,
          advertiserGroupIconUrl: advertiserGroup.icon?.url || null,
          mediaGroups: mediaGroups.map(mediaGroup => mediaGroup.name).join(','),
          activeStatus: campaign.active_status ? '라이브' : '중지',
          name: campaign.name,
          type: campaign.type,
          impressionTime: campaign.impression_time,
          impressionTimeType: campaign.impression_time_type,
          impressionFrequency: campaign.impression_frequency,
          adsGoalCount: campaign.ads_goal_count,
          adsLimitInterval: campaign.ads_limit_interval,
          messageDateTime: messageDateTime,
          displayPeriodStart: campaign.display_period_start,
          issetDisplayPeriodEnd: campaign.isset_display_period_end,
          displayPeriodEnd: campaign.display_period_end,
          dailyAdvertisingBudget: campaign.daily_advertising_budget,
          totalAdvertisingBudget: campaign.total_advertising_budget,
          reachCount: campaign.reach_count,
          impressionCount: campaign.impression_count,
          clickCount: campaign.click_count,
          ctr: campaign.ctr,
          ctrReach: campaign.ctr_reach,
          frequency: campaign.frequency,
          cpc: campaign.cpc,
          cpm: campaign.cpm,
          expenditure: campaign.expenditure,
          createdAt: this.$moment(campaign.created_at).format('YYYY-MM-DD'),
          targetTags: targetTags.map(tag => tag.name).join(','),
          targetTagsType: `${campaign.target_tags_mode}, ${campaign.target_tags_type}`,
          tags: tags.map(tag => tag.name).join(','),
          targetTagsCount: campaign.target_tags_count,
          targetAppsType: `${campaign.target_apps_type} @${campaign.target_apps
            .map(app => app.package)
            .join('@')}`,
          targetLocations:
            campaign.target_locations.length > 0 ? '활성' : '비활성'
        };
      });

      return {
        hashedCampaigns: hashedCampaigns
      };
    }
  }
};
</script>

<style></style>
