<template>
  <div>
    {{ date }}
    <span
      :class="{
        'red--text': isWeekend
      }"
      >({{ dayOfWeek }})</span
    >
  </div>
</template>

<script>
export default {
  props: ['date'],
  data() {
    return {
      dayOfWeekString: ['일', '월', '화', '수', '목', '금', '토'],
      weekend: ['일', '토']
    };
  },
  computed: {
    isWeekend() {
      return this.weekend.includes(this.dayOfWeek);
    },
    dayOfWeek() {
      return this.dayOfWeekString[this.$moment(this.date).day()];
    }
  }
};
</script>

<style></style>
