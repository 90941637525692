<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>쿠폰 카테고리 {{ buttonTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver :ref="refKey">
          <v-row dense class="ma-0 mt-5" v-if="form">
            <CustomGridItem title="이름" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true
                  }"
                >
                  <v-text-field
                    v-model="form.name"
                    placeholder="카테고리 이름을 입력해 주세요"
                    outlined
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="KEY" outlined>
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: form.id !== 1
                  }"
                >
                  <v-text-field
                    v-model="form.categoryKey"
                    placeholder="카테고리 Key를 입력해 주세요"
                    outlined
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="이미지" outlined>
              <template v-slot:content>
                <v-col v-if="!form.url">
                  <ImageUploadForm
                    :styleObj="{ height: '200px' }"
                    @setImage="file => (form.file = file)"
                  />
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-input
                      type="hidden"
                      v-model="form.file"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-row v-else align="center" class="ma-0">
                  <v-col>
                    <v-img
                      :src="form.url"
                      :alt="form.file_name || '이미지'"
                      :style="{
                        maxHeight: '80px',
                        maxWidth: '80px',
                        backgroundSize: 'contain'
                      }"
                      class="mx-auto"
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      color="secondary"
                      class="white--text"
                      small
                      depressed
                      icon
                      outlined
                      @click="() => (form.url = null)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </CustomGridItem>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="#00d1b2"
                class="white--text"
                depressed
                @click="handleSaveBtnClick"
              >
                {{ buttonTitle }}
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import ImageUploadForm from '@/components/ImageUploadForm.vue';
import {
  createCouponCategoryAPI,
  updateCouponCategoryAPI
} from '@/api/admin/CampaignAPI';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CustomGridItem,
    ImageUploadForm
  },
  computed: {
    ...mapGetters({
      dialog: 'couponCategory/dialog',
      couponCategory: 'couponCategory/couponCategory'
    }),
    buttonTitle() {
      return this.form?.id ? '수정' : '등록';
    }
  },
  watch: {},
  data() {
    return {
      loading: false,
      refKey: 'couponCategoryForm',
      form: null
    };
  },
  mounted() {
    this.init();
    if (this.couponCategory) {
      this.form = { ...this.couponCategory };
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'couponCategory/closeDialog'
    }),
    init() {
      this.form = {
        id: null,
        name: '',
        categoryKey: null,
        url: null,
        file_name: null,
        file: null
      };
      console.log(this.form);
    },
    async handleSaveBtnClick() {
      const valid = await this.$refs[this.refKey].validate();

      if (!valid || this.loading) return;

      this.loading = true;

      const formData = new FormData();

      this.form?.id && formData.append('_method', 'put');

      for (const key in this.form) {
        let val = this.form[key];
        if (!val) continue;

        formData.append(key, val);
      }

      const [error] = !this.form?.id
        ? await createCouponCategoryAPI(formData)
        : await updateCouponCategoryAPI(this.form?.id, formData);

      this.loading = false;

      if (error) {
        console.error(error?.data?.message);
        this.$axiosErrorAlert(error?.data?.message || `요청 실패`);
      } else {
        this.$Swal
          .fire({
            icon: 'success',
            html: `<h3>${this.buttonTitle} 되었습니다.</h3>`,
            showConfirmButton: true
          })
          .then(() => this.close());
        this.$emit('refresh');
      }

      // this.close();
    },
    close() {
      this.closeDialog();
    }
  }
};
</script>
