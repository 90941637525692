<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="캠페인 타입">
        <a-select
          style="width: 250px"
          v-model="filters.campaignType"
          mode="multiple"
        >
          <a-select-option
            v-for="(item, index) in CampaignResourceTypeList"
            :key="index"
            :value="item.value"
          >
            <span :style="`color: ${item.color};`">{{ item.text }} </span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="소재 타입">
        <a-select
          style="width: 250px"
          v-model="filters.dataType"
          mode="multiple"
        >
          <a-select-option
            v-for="(item, index) in CampaignResourceDataTypeList"
            :key="index"
            :value="item.value"
          >
            <span :style="`color: ${item.color};`">{{ item.text }} </span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="상태">
        <a-select
          style="width: 250px"
          v-model="filters.approveStatus"
          mode="multiple"
        >
          <a-select-option
            v-for="(item, index) in CampaignResourceStatusList"
            :key="index"
            :value="item.value"
          >
            <span :style="`color: ${item.color};`">{{ item.text }} </span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="회원 타입">
        <a-select
          style="width: 250px"
          v-model="filters.userBusinessClass"
          mode="multiple"
        >
          <a-select-option
            v-for="(item, index) in BusinessClassList"
            :key="index"
            :value="item.value"
          >
            <span :style="`color: ${item.color};`">{{ item.text }} </span>
          </a-select-option>
        </a-select>
      </a-descriptions-item>

      <a-descriptions-item label="검색" :span="2">
        <a-input-group compact>
          <a-select style="width: 150px" v-model="filters.searchKey">
            <a-select-option value="name"> 소재 이름 </a-select-option>
            <a-select-option value="userName"> 회원 이름 </a-select-option>
          </a-select>

          <a-input-search
            v-model="filters.searchText"
            placeholder="검색할 회원 이름을 입력해 주세요."
            @search="$emit('search')"
            style="width: calc(100% - 150px); max-width: 1000px"
          />
        </a-input-group>
      </a-descriptions-item>
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>

      <v-btn
        class="ml-3"
        color="#00d1b2"
        outlined
        @click="handleInitClick()"
        :disabled="loading"
      >
        초기화
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  CampaignResourceStatusList,
  CampaignResourceTypeList,
  CampaignResourceDataTypeList,
  BusinessClassList
} from '@/config/common';

export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      CampaignResourceStatusList: CampaignResourceStatusList,
      CampaignResourceTypeList: CampaignResourceTypeList,
      CampaignResourceDataTypeList: CampaignResourceDataTypeList,
      BusinessClassList: BusinessClassList
    };
  },
  computed: {
    ...mapGetters({
      filters: 'campaignResource/filters'
    })
  },
  methods: {
    ...mapActions({
      initFilters: 'campaignResource/initFilters'
    }),
    handleInitClick() {
      this.initFilters();
      this.$emit('search');
    }
  }
};
</script>
