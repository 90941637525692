<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="pa-3">
          <div class="filters mt-5 mb-8">
            <a-descriptions bordered>
              <a-descriptions-item label="상태">
                <a-select
                  v-model="filters.statusFlag"
                  :style="{ minWidth: '200px' }"
                >
                  <a-select-option value="">
                    <a-tag> 전체 </a-tag>
                  </a-select-option>
                  <a-select-option
                    v-for="(item, index) in paymentStatusList"
                    :key="index"
                    :value="item.value"
                  >
                    <a-tag :color="item.color"> {{ item.text }} </a-tag>
                  </a-select-option>
                </a-select>
              </a-descriptions-item>

              <a-descriptions-item label="결제 방식" :span="2">
                <a-select
                  v-model="filters.paymentMethod"
                  :style="{ minWidth: '200px' }"
                >
                  <a-select-option value=""> 전체 </a-select-option>
                  <a-select-option value="creditcard">
                    카드결제
                  </a-select-option>
                  <a-select-option value="banktransfer">
                    직접입금
                  </a-select-option>
                </a-select>
              </a-descriptions-item>

              <a-descriptions-item label="등록일">
                <a-range-picker
                  :ranges="{
                    오늘: [$moment(), $moment()]
                  }"
                  format="YYYY-MM-DD"
                  v-model="filters.dates"
                />
              </a-descriptions-item>

              <a-descriptions-item :span="2">
                <template slot="label">주문자 정보</template>

                <a-input-group compact>
                  <a-select v-model="filters.searchType" style="width: 200px">
                    <a-select-option value="order_no">
                      주문번호
                    </a-select-option>
                    <a-select-option value="uid"> 주문자 ID </a-select-option>
                    <a-select-option value="name">
                      주문자 이름
                    </a-select-option>
                    <a-select-option value="phone">
                      주문자 연락처
                    </a-select-option>
                  </a-select>
                  <a-input-search
                    v-model="filters.searchString"
                    @search="fetchPointOrders(1)"
                    style="width: calc(100% - 200px); max-width: 1000px"
                  />
                </a-input-group>
              </a-descriptions-item>
            </a-descriptions>

            <div class="text-center mt-3">
              <v-btn
                color="#00d1b2"
                class="white--text"
                depressed
                :loading="loading"
                @click="fetchPointOrders(1)"
              >
                검색
              </v-btn>
            </div>
          </div>

          <v-row class="mb-3">
            <v-col cols="12" class="text-center">
              <a-spin :spinning="loading">
                <a-descriptions layout="vertical" bordered>
                  <a-descriptions-item label="조회 건수">
                    {{ (summary.count * 1).toLocaleString() }} 건
                  </a-descriptions-item>
                  <a-descriptions-item>
                    <template slot="label">
                      승인 금액 합계
                      <v-tooltip right color="rgba(0,0,0,1)">
                        <template #activator="{ on: onTooltip }">
                          <v-icon v-on="{ ...onTooltip }" small class="ml-1">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span class="text-subTitle-1">
                          승인 금액의 합계입니다. (VAT 포함)
                        </span>
                      </v-tooltip>
                    </template>
                    <b class="mr-1 ext-subTitle-1">
                      {{ (summary.amount * 1).toLocaleString() }} 원
                    </b>

                    <!-- <v-tooltip right color="rgba(0,0,0,1)">
                      <template #activator="{ on: onTooltip }">
                        <v-chip label small>
                          <v-icon v-on="{ ...onTooltip }" small class="mr-1">
                            mdi-information-outline
                          </v-icon>
                          {{
                            (summary.advertising_amount * 1).toLocaleString()
                          }}
                          원
                        </v-chip>
                      </template>
                      <span class="text-subTitle-1"> 부가세 제외금액 </span>
                    </v-tooltip> -->
                  </a-descriptions-item>

                  <a-descriptions-item
                    v-for="(status, index) in summary.status_amount"
                    :key="index"
                    :label="`${flagFormat(status.status_flag, true)} 합계`"
                  >
                    {{ (status.sum_amount * 1).toLocaleString() }} 원
                  </a-descriptions-item>

                  <a-descriptions-item label="직접입금 미처리건">
                    {{ (summary.depositWaitCount * 1).toLocaleString() }} 건
                  </a-descriptions-item>
                </a-descriptions>
              </a-spin>
            </v-col>
          </v-row>

          <Table
            v-show="!loading"
            class="table-striped-rows"
            style="white-space: nowrap"
            size="small"
            :columns="columns"
            bordered
            :data-source="pointOrders"
            :scroll="{ x: true }"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            :pagination="false"
            :customRow="
              record => {
                return {
                  on: {
                    click: event => {
                      openDialog(record);
                    }
                  }
                };
              }
            "
          >
            <template slot="statusFlag" slot-scope="value, record">
              <v-chip
                :color="record.statusColor"
                outlined
                label
                small
                @click.stop="
                  () =>
                    isValidStatus(record)
                      ? handlePaymentCancelClick(record)
                      : ''
                "
              >
                {{ record.statusText }}

                <v-avatar right class="ml-0" v-if="isValidStatus(record)">
                  <span>
                    <v-tooltip bottom color="rgba(0,0,0,0.8)">
                      <template #activator="{ on: onTooltip }">
                        <!-- 결제 취소가 아닌 충전완료건만 상태 변경 가능 -->
                        <v-btn
                          v-on="{ ...onTooltip }"
                          icon
                          x-small
                          :color="record.statusColor"
                        >
                          <v-icon>mdi-sync</v-icon>
                        </v-btn>
                      </template>
                      <span class="text-subtitle-2">결제 취소 요청</span>
                    </v-tooltip>
                  </span>
                </v-avatar>
              </v-chip>

              <!-- <a-tag small :color="record.statusColor">
                {{ record.statusText }}
              </a-tag> -->
            </template>
            <template slot="userName" slot-scope="value, record">
              <v-col class="py-0">
                {{ value }}
                <v-btn
                  icon
                  v-if="record.advertiserId"
                  class="text-body font-weight-regular align-center ma-0"
                  @click.stop="
                    openAdvertiserDialog({
                      record: {
                        advertiserId: record.advertiserId
                      },
                      type: 'detail'
                    })
                  "
                >
                  <v-icon class="ml-1 mb-1" color="grey darken-3">
                    mdi-application-edit-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </template>

            <template slot="advertiserType" slot-scope="value">
              <div class="text-center px-2">
                {{ value }}
              </div>
            </template>

            <template slot="amount" slot-scope="value, record">
              <span
                small
                :style="`${
                  record.status === 'C' ? `color: ${record.statusColor}` : ''
                }`"
              >
                {{ (value * 1).toLocaleString() }}원
              </span>
            </template>

            <template slot="cancelAmount" slot-scope="value">
              {{ (value * 1).toLocaleString() }}원
            </template>

            <template slot="validAmount" slot-scope="value">
              {{ (value * 1).toLocaleString() }}원
            </template>
          </Table>
        </v-card>
      </v-col>
    </v-row>

    <v-skeleton-loader v-show="loading" class="mx-auto mb-3" type="table" />

    <v-row>
      <v-col>
        <v-pagination
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
        />
      </v-col>
    </v-row>

    <StatusChangeDialog
      v-if="isStatusDialog"
      :record="selectRecord"
      :dialog="!!selectRecord"
      :status="requestStatus"
      @index="fetchPointOrders()"
      @close="handleCloseDialog"
    />
    <DetailDialog v-if="!isStatusDialog" @index="fetchPointOrders()" />
    <AdvertiserDetailDialog v-if="dialog" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Table } from 'ant-design-vue';
import MenuTitle from '@/components/MenuTitle.vue';
import DetailDialog from './components/PaymentDetailDialog';
import StatusChangeDialog from '@/components/payment/StatusChangeDialog';
import { PaymentMethods, DirectOrderStatusFlags } from '@/config/charge';
import { getPointOrdersApi } from '@/api/admin/PointAPI';
import AdvertiserDetailDialog from '@/views/admin/pages/advertiser/components/dialog/AdvertiserDetailDialog.vue';

export default {
  components: {
    MenuTitle,
    Table,
    DetailDialog,
    StatusChangeDialog,
    AdvertiserDetailDialog
  },
  computed: {
    ...mapGetters({
      filters: 'payment/filters',
      dialog: 'advertiser/dialog'
    })
  },
  data() {
    return {
      paymentStatusList: DirectOrderStatusFlags,
      isStatusDialog: false,
      selectRecord: null,
      requestStatus: 'C',
      columns: [
        {
          title: '번호',
          dataIndex: 'id',
          width: 80,
          key: 'id',
          align: 'center'
        },
        {
          title: '상태',
          dataIndex: 'statusFlag',
          key: 'statusFlag',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'statusFlag'
          }
        },
        {
          title: '회원명',
          dataIndex: 'userName',
          key: 'userName',
          scopedSlots: {
            customRender: 'userName'
          },
          width: 250
        },
        {
          title: '광고주 유형',
          dataIndex: 'advertiserType',
          key: 'advertiserType',
          scopedSlots: {
            customRender: 'advertiserType'
          },
          width: 100
        },

        {
          title: '결제 방식',
          dataIndex: 'paymentMethod',
          key: 'paymentMethod',
          align: 'center',
          width: 100
        },
        // 충전된 포인트 금액
        {
          title: '충전 포인트',
          dataIndex: 'chargeAmount',
          key: 'chargeAmount',
          scopedSlots: {
            customRender: 'chargeAmount'
          },
          align: 'right',
          width: 150
        },
        // 충전시 결제한 금액 (vat 포함금액)
        {
          title: '결제 금액',
          dataIndex: 'amount',
          key: 'amount',
          scopedSlots: {
            customRender: 'amount'
          },
          align: 'right',
          width: 150
        },
        // 실제 결제 금액 (결제 금액 - 취소금액)
        {
          title: '승인 금액',
          dataIndex: 'validAmount',
          key: 'validAmount',
          scopedSlots: {
            customRender: 'validAmount'
          },
          align: 'right',
          width: 150
        },
        // 취소 요청된 금액
        {
          title: '취소 금액',
          dataIndex: 'cancelAmount',
          key: 'cancelAmount',
          scopedSlots: {
            customRender: 'cancelAmount'
          },
          align: 'right',
          width: 150
        },
        {
          title: '등록일',
          dataIndex: 'orderAt',
          key: 'orderAt',
          align: 'center',
          width: 200
        },
        {
          title: '입금 승인일',
          dataIndex: 'confirmAt',
          key: 'confirmAt',
          align: 'center',
          width: 200
        }
      ],
      pointOrders: [],
      pagination: {
        page: 1,
        perPage: 15,
        totalPage: 1
      },
      loading: true,
      summary: {
        amount: 0,
        count: 0,
        depositWaitCount: 0,
        advertising_amount: 0,
        status_amount: 0
      }
    };
  },
  watch: {
    'pagination.page'() {
      this.fetchPointOrders();
    }
  },
  async mounted() {
    this.fetchPointOrders();
  },
  methods: {
    ...mapActions({
      openDialog: 'payment/openDialog',
      openAdvertiserDialog: 'advertiser/openDialog' // 광고주 정보
    }),
    // 취소 가능한 상태값
    isValidStatus(record) {
      const isValidAmount =
        record.status !== 'W' ? record.validAmount > 0 : true;
      return ['S', 'W', 'PC'].includes(record.status) && isValidAmount;
    },
    async fetchPointOrders(page = 0) {
      this.loading = true;
      this.pagination.page = page || this.pagination.page;

      const params = {
        params: {
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          pagination: 'Y',
          statusFlag: this.filters.statusFlag
            ? [this.filters.statusFlag]
            : null,
          paymentMethod: this.filters.paymentMethod,
          dates: this.filters.dates.map(date => {
            return date.format('YYYY-MM-DD');
          }),
          searchType: this.filters.searchType,
          searchString: this.filters.searchString
        }
      };
      const [error, data] = await getPointOrdersApi(params);
      if (error) {
        console.error(error);
      } else {
        const {
          point_orders: { data: items, last_page: totalPage },
          summary
        } = data;

        this.pagination.totalPage = totalPage;
        this.summary = summary;

        this.pointOrders = items.map((pointOrder, index) => {
          let statusFlag = this.flagFormat(pointOrder.status_flag);

          return {
            id: pointOrder.id,
            key: index,
            advertiserId: pointOrder.advertiser_id,
            advertiserType:
              pointOrder.user?.role === 'cashnote'
                ? '캐시노트 광고주'
                : '내부 생성 광고주',
            userId: pointOrder.user_id,
            userName: pointOrder.user?.name,
            userEmail: pointOrder.user?.email,
            orderNo: pointOrder.order_no,
            paymentType: pointOrder.payment_type,
            paymentMethod:
              PaymentMethods.find(v => v.value === pointOrder.payment_method)
                ?.text || 'N/A',
            status: pointOrder.status_flag,
            statusText: statusFlag.text,
            statusColor: statusFlag.color,
            amount: pointOrder.amount,
            amountString: pointOrder.amount.toLocaleString() + ' 원',
            chargeAmount:
              (pointOrder.amount - pointOrder.tax_amount).toLocaleString() +
              ' 원',
            advertisingAmount:
              pointOrder.advertising_amount.toLocaleString() + ' 원',
            taxAmount: pointOrder.tax_amount.toLocaleString() + ' 원',

            validAmount: pointOrder.valid_amount ?? 0, // 승인 금액
            cancelAmount: pointOrder.cancel_amount ?? 0, // 결제 취소 금액

            taxInvoiceDate: pointOrder.tax_invoice_date,
            accountName: pointOrder.account_name,
            remark: pointOrder.remark,
            orderAt: this.$moment(pointOrder.order_at).format(
              'YYYY-MM-DD HH:mm'
            ),
            createdAt: this.$moment(pointOrder.created_at).format(
              'YYYY-MM-DD HH:mm'
            ),
            updatedAt: this.$moment(pointOrder.updated_at).format(
              'YYYY-MM-DD HH:mm'
            ),
            confirmAt: pointOrder.confirm_at
              ? this.$moment(pointOrder.confirm_at).format('YYYY-MM-DD HH:mm')
              : '-',
            cancelAt: pointOrder.cancel_at
              ? this.$moment(pointOrder.cancel_at).format('YYYY-MM-DD HH:mm')
              : '-'
          };
        });
      }

      this.loading = false;
    },
    flagFormat(flag, isText = false) {
      let defaultVal = '';
      const status = this.paymentStatusList.find(item => item.value === flag);
      const flagText = status ? status.text : defaultVal;
      return isText
        ? flagText
        : {
            text: flagText,
            color: status ? status.color : defaultVal
          };
    },
    // 충전완료(결제대기) -> 결제 취소(결제 삭제) 요청 변경
    handlePaymentCancelClick(record) {
      let msg = '';
      // 충전완료(결제대기),부분취소 상태
      if (!['S', 'W', 'PC'].includes(record.status)) {
        msg = '변경이 불가능한 상태입니다.';
      }
      if (!msg && record.status !== 'W' ? record.validAmount <= 0 : false) {
        // 승인 가능 금액이 0인 경우
        msg = '결제 취소 요청 가능 금액이 없습니다.';
      }

      if (msg) {
        this.$Swal.fire({
          html: `<h3>${msg}</h3>`,
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return false;
      }

      this.requestStatus = record.status === 'W' ? 'D' : 'C';
      this.isStatusDialog = true;
      this.selectRecord = record;
    },
    handleCloseDialog() {
      this.isStatusDialog = false;
      this.selectRecord = null;
      this.statusList = null;
    }
  }
};
</script>
