var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.noticeDialog,"max-width":"1200","scrollable":"","persistent":"","eager":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("공지사항 "+_vm._s(_vm.dialogTitle))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5 py-3"},[_c('ValidationObserver',{ref:"noticeDialogObs"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.CategoryList,"label":"공지 유형","required":"","outlined":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.notice.category),callback:function ($$v) {_vm.$set(_vm.notice, "category", $$v)},expression:"notice.category"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.UserRoleList,"label":"공지 그룹","required":"","outlined":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.notice.group),callback:function ($$v) {_vm.$set(_vm.notice, "group", $$v)},expression:"notice.group"}})]}}])})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"공지 제목","required":"","outlined":"","clearable":""},model:{value:(_vm.notice.title),callback:function ($$v) {_vm.$set(_vm.notice, "title", $$v)},expression:"notice.title"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"공지 내용","required":"","outlined":"","clearable":"","rows":"15"},model:{value:(_vm.notice.content),callback:function ($$v) {_vm.$set(_vm.notice, "content", $$v)},expression:"notice.content"}})]}}])}),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.isDialogTypeRegister)?_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-switch',{attrs:{"color":"primary","inset":""},model:{value:(_vm.notice.activeNow),callback:function ($$v) {_vm.$set(_vm.notice, "activeNow", $$v)},expression:"notice.activeNow"}}),_c('span',{staticClass:"subtitle-1 mr-2"},[_vm._v("바로활성: "+_vm._s(_vm.notice.activeNow ? 'ON' : 'OFF')+" ")])],1)]):_vm._e(),(_vm.activeNow)?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","label":"시작일","error-messages":errors,"persistent-hint":"","readonly":"","clearable":"","outlined":""},model:{value:(_vm.notice.startDate),callback:function ($$v) {_vm.$set(_vm.notice, "startDate", $$v)},expression:"notice.startDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,3292922808),model:{value:(_vm.modal.startDate),callback:function ($$v) {_vm.$set(_vm.modal, "startDate", $$v)},expression:"modal.startDate"}},[(_vm.modal.startDate)?_c('v-date-picker',{attrs:{"min":_vm.today,"locale":"ko-KR"},on:{"input":function($event){_vm.modal.startDate = false}},model:{value:(_vm.notice.startDate),callback:function ($$v) {_vm.$set(_vm.notice, "startDate", $$v)},expression:"notice.startDate"}}):_vm._e()],1)],1):_vm._e(),(_vm.activeNow)?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"prepend-inner-icon":"mdi-clock-time-four-outline","label":"시작시간","readonly":"","outlined":"","clearable":""},model:{value:(_vm.notice.startTime),callback:function ($$v) {_vm.$set(_vm.notice, "startTime", $$v)},expression:"notice.startTime"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,2200520692),model:{value:(_vm.modal.startTime),callback:function ($$v) {_vm.$set(_vm.modal, "startTime", $$v)},expression:"modal.startTime"}},[(_vm.modal.startTime)?_c('v-time-picker',{attrs:{"format":"24hr","allowed-minutes":_vm.allowedMinutes,"full-width":"","scrollable":""},model:{value:(_vm.notice.startTime),callback:function ($$v) {_vm.$set(_vm.notice, "startTime", $$v)},expression:"notice.startTime"}}):_vm._e()],1)],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-switch',{attrs:{"color":"primary","inset":""},model:{value:(_vm.notice.issetEnd),callback:function ($$v) {_vm.$set(_vm.notice, "issetEnd", $$v)},expression:"notice.issetEnd"}}),_c('span',{staticClass:"subtitle-1 mr-2"},[_vm._v("종료일: "+_vm._s(_vm.notice.issetEnd ? 'ON' : 'OFF')+" ")])],1)]),(_vm.notice.issetEnd === true)?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","label":"종료일","error-messages":errors,"persistent-hint":"","readonly":"","clearable":"","outlined":""},model:{value:(_vm.notice.endDate),callback:function ($$v) {_vm.$set(_vm.notice, "endDate", $$v)},expression:"notice.endDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,995060691),model:{value:(_vm.modal.endDate),callback:function ($$v) {_vm.$set(_vm.modal, "endDate", $$v)},expression:"modal.endDate"}},[(_vm.modal.endDate)?_c('v-date-picker',{attrs:{"min":_vm.notice.startDate == null ? _vm.today : _vm.notice.startDate,"locale":"ko-KR"},on:{"input":function($event){_vm.modal.endDate = false}},model:{value:(_vm.notice.endDate),callback:function ($$v) {_vm.$set(_vm.notice, "endDate", $$v)},expression:"notice.endDate"}}):_vm._e()],1)],1):_vm._e(),(_vm.notice.issetEnd === true)?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"prepend-inner-icon":"mdi-clock-time-four-outline","label":"종료시간","readonly":"","outlined":"","clearable":""},model:{value:(_vm.notice.endTime),callback:function ($$v) {_vm.$set(_vm.notice, "endTime", $$v)},expression:"notice.endTime"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,674952511),model:{value:(_vm.modal.endTime),callback:function ($$v) {_vm.$set(_vm.modal, "endTime", $$v)},expression:"modal.endTime"}},[(_vm.modal.endTime)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedMinutes,"format":"24hr","full-width":"","scrollable":""},model:{value:(_vm.notice.endTime),callback:function ($$v) {_vm.$set(_vm.notice, "endTime", $$v)},expression:"notice.endTime"}}):_vm._e()],1)],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-switch',{attrs:{"color":"error","inset":""},model:{value:(_vm.notice.isUrgent),callback:function ($$v) {_vm.$set(_vm.notice, "isUrgent", $$v)},expression:"notice.isUrgent"}}),_c('span',{staticClass:"subtitle-1 mr-2"},[_vm._v("긴급공지: "+_vm._s(_vm.notice.isUrgent ? 'ON' : 'OFF')+" ")])],1)])],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v(" 취소 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }