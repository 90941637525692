<template>
  <v-card style="background-color: #e5edf1">
    <v-row class="ma-0">
      <v-col cols="12" class="mx-auto">
        <div class="box">
          <v-alert
            border="top"
            colored-border
            color="secondary"
            elevation="2"
            class="text-center my-0"
          >
            <a class="btn btn-sm btn-primary" @click="$refs.file.click()">
              <v-icon color="primary">mdi-cursor-default-click</v-icon>
              파일 선택
            </a>
            <span> 또는 마우스로 드레그 하세요.</span>
          </v-alert>

          <form ref="fileform" class="white elevation-2">
            <div
              style="overflow: auto; overflow-x: hidden; height: 100%"
              class="white"
            >
              <input type="file" ref="file" style="display: none" />

              <v-row
                v-show="files.length == false"
                class="ma-0 white"
                style="height: 100%"
                justify="center"
                align="center"
              >
                <div class="drop-files mx-auto">
                  <v-icon x-large> mdi-tray-plus </v-icon>
                </div>
              </v-row>

              <v-row
                v-for="(file, index) in files"
                class="ma-0"
                :key="index"
                justify="center"
                align="center"
              >
                <video
                  v-if="file.fileType && file.fileType === 'video'"
                  class="preview col-12"
                  v-bind:ref="'preview' + parseInt(index)"
                  controls
                ></video>
                <img
                  v-else
                  class="preview col-12"
                  v-bind:ref="'preview' + parseInt(index)"
                />
                <v-row justify="center" align="center">
                  <span class="text-caption">
                    {{ file.file.name }}
                  </span>
                  <div class="remove-container">
                    <v-btn icon @click="removeFile(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-row>
              </v-row>
            </div>
          </form>
        </div>
      </v-col>
    </v-row>
    <div></div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dragAndDropCapable: false,
      files: [],
      divs: '',
      fileLen: '',
      loading: false
    };
  },
  props: {
    resourceType: {
      type: String,
      default() {
        return null;
      }
    },
    campaignType: {
      type: String,
      default() {
        return null;
      }
    },
    fileLabel: {
      type: String,
      default() {
        return '';
      }
    },
    fileMax: {
      type: Number,
      default() {
        return 10;
      }
    },
    fileType: {
      type: String,
      default() {
        return 'image';
      }
    },
    fileDiv: {},
    fileMaxSize: {
      type: Number,
      default() {
        return 1024 * 100;
      }
    },
    exampleImg: {},
    maxCount: {
      require: false,
      default: 0
    }
  },
  computed: {
    allowFileType() {
      const allowFileType = this.fileType;

      const res = {
        regExp: /\.(jpe?g|png)$/i,
        message: ''
      };

      if (this.fileType === 'image') {
        res.regExp = /\.(jpe?g|png)$/i;
        res.message =
          '<h3>이미지 ( .jpg .jpeg .png ) 파일만 업로드 가능합니다.</h3>';
      }
      if (allowFileType === 'pdf') {
        res.regExp = /\.(mp4)$/i;
        res.message = '<h3>PDF ( .pdf ) 파일만 업로드 가능합니다.</3>';
      }
      if (allowFileType === 'all') {
        res.regExp = /\.(jpe?g|png|gif|pdf)$/i;
        res.message =
          '<h3>확장자가 (.jpg .jpeg .png .gif .pdf ) 인 파일만 업로드 가능합니다.</h3>';
      }

      if (allowFileType === 'video') {
        res.regExp = /\.(mpg|avi|wmv|mov|ogg|mp4)$/i;
        res.message =
          '<h3>확장자가 (.mpg .wmv .mov .ogg .mp4 ) 인 파일만 업로드 가능합니다.</h3>';
      }
      return res;
    }
  },
  created() {
    this.divs = this.fileDiv;
    // this.$emit('sendUploadImage', this.files, this.fileDiv);
  },
  mounted() {
    this.bindingDrag();
  },
  watch: {
    files(val) {
      this.$emit('addImage', val[0]);
    }
  },
  methods: {
    bindingDrag() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        [
          'drag',
          'dragstart',
          'dragend',
          'dragover',
          'dragenter',
          'dragleave',
          'drop'
        ].forEach(
          function (evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function (e) {
                e.preventDefault();
                e.stopPropagation();
              }.bind(this),
              false
            );
          }.bind(this)
        );

        ['dragover', 'dragenter'].forEach(
          function (evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function () {
                this.$refs.fileform.classList.add('is-dragover');
              }.bind(this),
              false
            );
          }.bind(this)
        );

        ['dragover', 'dragenter'].forEach(
          function (evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function () {
                this.$refs.fileform.classList.add('is-dragover');
              }.bind(this),
              false
            );
          }.bind(this)
        );

        ['dragleave', 'dragend'].forEach(
          function (evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function () {
                this.$refs.fileform.classList.remove('is-dragover');
              }.bind(this),
              false
            );
          }.bind(this)
        );

        this.$refs.fileform.addEventListener(
          'drop',
          function (e) {
            if (
              this.maxCount &&
              (this.maxCount === this.files.length ||
                e.dataTransfer.files.length > this.maxCount)
            ) {
              this.$Swal.fire({
                type: 'warning',
                html: `최대 ${this.maxCount}개 선택 가능합니다.`,
                showConfirmButton: true,
                confirmButtonText: '확인'
              });
              return false;
            }

            this.$refs.fileform.classList.remove('is-dragover');
            let sizeFile = e.dataTransfer.files.length + this.files.length;
            if (sizeFile > this.fileMax) {
              this.showFileMaxMessage();
              return false;
            } else {
              for (let i = 0; i < e.dataTransfer.files.length; i++) {
                //console.log(e.dataTransfer.files[i]);
                const validName = this.checkFileType(
                  e.dataTransfer.files[i].name
                );
                if (validName.status) {
                  this.$Swal.fire({
                    html: validName.message,
                    showConfirmButton: true,
                    confirmButtonText: '확인'
                  });
                  return false;
                }
                const fileType = this.getFileType(e.dataTransfer.files[i].name);
                const validSize = this.getFileSize(
                  e.dataTransfer.files[i].name,
                  e.dataTransfer.files[i].size
                );
                if (validSize.status) {
                  this.$Swal.fire({
                    type: 'warning',
                    html: validSize.message,
                    showConfirmButton: true,
                    confirmButtonText: '확인'
                  });
                  return false;
                }
                const convFile = {
                  file: e.dataTransfer.files[i],
                  size: e.dataTransfer.files[i].size,
                  fileType: fileType,
                  fileLabel: this.fileLabel
                };

                this.files.push(convFile);

                this.getImagePreviews();
              }
            }
          }.bind(this)
        );
      }

      /* !! bind(this) 를 통해 vue component 의 this 를 사용가능함, bind 없이 내부에서 this 사용하면 input element 를 받아옴*/
      this.$refs.file.addEventListener(
        'change',
        function (e) {
          if (
            this.maxCount &&
            (this.maxCount === this.files.length ||
              e.target.files.length > this.maxCount)
          ) {
            this.$Swal.fire({
              type: 'warning',
              html: `최대 ${this.maxCount}개 선택 가능합니다.`,
              showConfirmButton: true,
              confirmButtonText: '확인'
            });
            return false;
          }

          let f = e.target.files || e.dataTransfer.files;
          let sizeFile = f.length + this.files.length;
          //console.log(this.fileMax);
          if (sizeFile > this.fileMax) {
            this.showFileMaxMessage();
            return false;
          } else {
            for (let i = 0; i < e.target.files.length; i++) {
              console.log(e.target.files[i]);
              const validName = this.checkFileType(e.target.files[i].name);
              if (validName.status) {
                this.$Swal.fire({
                  type: 'warning',
                  html: validName.message,
                  showConfirmButton: true,
                  confirmButtonText: '확인'
                });
                return false;
              }
              const fileType = this.getFileType(e.target.files[i].name);
              const validSize = this.getFileSize(
                e.target.files[i].name,
                e.target.files[i].size
              );
              if (validSize.status) {
                this.$Swal.fire({
                  type: 'warning',
                  html: validSize.message,
                  showConfirmButton: true,
                  confirmButtonText: '확인'
                });
                return false;
              }

              const convFile = {
                file: e.target.files[i],
                size: e.target.files[i].size,
                fileType: fileType,
                fileLabel: this.fileLabel
              };
              this.files.push(convFile);

              this.getImagePreviews();
            }
          }
        }.bind(this)
      );
    },
    determineDragAndDropCapable() {
      let div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },

    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (
          /\.(jpe?g|png|gif)$/i.test(this.files[i].file.name) ||
          this.files[i].fileType === 'video'
        ) {
          let reader = new FileReader();
          reader.addEventListener(
            'load',
            function () {
              this.$refs['preview' + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );

          reader.readAsDataURL(this.files[i].file);
        } else {
          this.$nextTick(function () {
            this.$refs['preview' + parseInt(i)][0].src = '/images/pdf.png';
          });
        }
      }
    },
    checkFileType(name) {
      const { regExp, message } = this.allowFileType;
      const res = {
        status: true,
        message: ''
      };

      if (regExp.test(name)) {
        res.status = false;
      } else {
        res.message = message;
      }

      console.log(name);
      return res;
    },
    getFileType(name) {
      if (/\.(jpe?g|png|gif)$/i.test(name)) {
        return 0;
      } else if (/\.(mpg|avi|wmv|mov|ogg|mp4)$/i.test(name)) {
        return this.fileType;
      } else {
        return '';
      }
    },
    getFileSize(name, size) {
      const fileMaxSize = this.fileMaxSize;
      const sizeString = `${parseFloat(size / 1024, 2).toFixed(1)} KB`;
      const res = {
        status: true,
        message: ''
      };

      if (size / 1024 > fileMaxSize) {
        res.message = `<h3>${fileMaxSize / 1024}MB 이하의 파일만 등록가능합니다.<br><br>파일명: ${name}<br>파일사이즈: ${sizeString}</h3>`;
      } else {
        res.status = false;
      }
      return res;
    },
    async submitFiles() {
      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        this.loading = true;

        formData.append('file', this.files[i].file);
        formData.append('dataType', this.fileType);
        formData.append('campaignType', this.campaignType);

        console.dir(formData);
        await this.$axios
          .post('/utils/campaign-images', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            // console.log(res);
            this.$emit('addImage', res.data.image);
          })
          .catch(err => {
            console.log(err);
          });
      }

      this.loading = false;
      this.files = [];
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },
    showFileMaxMessage() {
      this.$Swal.fire({
        html: `<h3>업로드 가능한 파일 개수는 최대 ${this.fileMax} 개 입니다.</h3>`,
        showConfirmButton: true,
        confirmButtonText: '확인'
      });
    },
    showFileSizeMessage() {},
    showCustomMessage() {}
  }
};
</script>

<style scoped>
.box {
  padding: 20px;
}

.box form.is-dragover {
  background-color: white;
  outline-offset: -20px;
}
.box form {
  height: 350px;
  border-radius: 4px;
  background-color: #c8dadf;
  font-size: 1.25rem;
  position: relative;
  padding: 15px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}

.form-box {
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  height: 98%;
  width: 98%;
  overflow: auto;
}

.box div.file-listing {
  display: block;
  flex-direction: column;
  padding: 10px;
}

.box div.file-listing img {
  height: 100px;
}

.box div.remove-container {
  text-align: center;
}

.box div.remove-container a {
  color: red;
  cursor: pointer;
}

.box a.submit-button {
  display: block;
  margin: auto;
  text-align: center;
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  background-color: #ccc;
  color: white;
  font-weight: bold;
  margin-top: 20px;
}

.box progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
img {
  image-orientation: from-image;
}
.exampleImg {
  cursor: pointer;
  position: relative;
}
.exampleImg span {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 45%;
  left: 25%;
  border: 2px solid #fff;
  border-radius: 10%;
  letter-spacing: 3px;
  padding: 5px;
  transform: rotate(45deg);
}
</style>
