import { DidMediaGroup } from '@/model/admin/did';

export default {
  namespaced: true,
  state: {
    loading: {
      index: false,
      dialog: false
    },
    didMediaGroup: new DidMediaGroup(),
    dialog: false,
    filters: {
      isActive: [0, 1],
      approveStatusFlag: ['W', 'Y'],
      type: '',
      searchKey: 'name',
      searchText: ''
    }
  },
  getters: {
    didMediaGroup(state) {
      return state.didMediaGroup;
    },
    dialog(state) {
      return state.dialog;
    },
    loading(state) {
      return state.loading;
    },
    filters(state) {
      return state.filters;
    },
    filterParams(state) {
      let params = {
        isActive: state.filters.isActive,
        type: state.filters.type,
        approveStatusFlag: state.filters.approveStatusFlag
      };
      params[`${state.filters.searchKey}`] = state.filters.searchText;

      return params;
    }
  },
  mutations: {
    SET_Dialog(state, dialog) {
      state.dialog = dialog;
    },
    SET_DidMediaGroup(state, didMediaGroup) {
      state.didMediaGroup = new DidMediaGroup(didMediaGroup, true);
    },
    SET_LOADING(state, props) {
      state.loading[props] = props.value;
    },
    SET_Filters(state) {
      state.filters = {
        isActive: [0, 1],
        type: '',
        approveStatusFlag: ['Y', 'W'],
        searchKey: 'name',
        searchText: ''
      };
    }
  },
  actions: {
    initFilters({ commit }) {
      commit('SET_Filters');
    },
    async openDialog({ commit }, didMediaGroup = undefined) {
      commit('SET_DidMediaGroup', new DidMediaGroup());

      if (didMediaGroup) {
        commit('SET_DidMediaGroup', didMediaGroup);
      }

      commit('SET_Dialog', true);
    },
    closeDialog({ commit }, dialogType = '') {
      commit(`SET_${dialogType}Dialog`, false);
    }
  }
};
