import axios from 'axios';

export async function getPartnersApi(params) {
  try {
    const { data } = await axios.get(`/admin/partners`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
