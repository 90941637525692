<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="{ ...on }"
        fab
        :small="!$isMobile"
        :x-small="$isMobile"
        :loading="loading"
        :disabled="loading"
        @click="excelExport"
        color="secondary"
      >
        <v-icon>mdi-table-arrow-down</v-icon>
      </v-btn>
    </template>
    <span class="headline"> 엑셀 다운로드 </span>
  </v-tooltip>
</template>

<script>
import { utils, writeFile } from 'xlsx';
import { getCampaignsApi } from '@/api/admin/CampaignAPI';
import { CampaignTypeList } from '@/config/admin/campaign';
import { Campaign } from '@/model/admin/campaign';

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    campaigns: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      default: () => {}
    },
    searchString: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      campaignTypeList: CampaignTypeList
    };
  },
  methods: {
    async excelExport() {
      if (this.campaigns.length > 0 === false) {
        this.errorAlert();
        return false;
      }
      // 서버 통신에서 로컬로 변경함
      const { hashedCampaigns } = await this.getCampaigns();
      // const { hashedCampaigns } = this.hashing(this.campaigns);
      // console.log(hashedCampaigns);
      // Using same variables as the above answer
      const Heading = [this.headers.map(v => v.title)];
      const headerKeys = this.headers.map(v => v.dataIndex);
      // console.log('hashedCampaigns', hashedCampaigns);
      const Data = hashedCampaigns.map(campaigns => {
        const item = {};
        headerKeys.forEach(key => {
          item[key] = campaigns[key];
        });
        return item;
      });

      var wb = utils.book_new();

      var ws = utils.json_to_sheet(Data, {
        origin: 'A2',
        skipHeader: true
      });
      utils.sheet_add_aoa(ws, Heading); //heading: array of arrays

      utils.book_append_sheet(wb, ws);

      writeFile(wb, `캠페인_${this.$moment().format('YYYY-MM-DD')}.xlsx`);
    },
    async getCampaigns() {
      this.loading = true;

      const params = {
        ss: this.searchString,
        page: null,
        perPage: null,
        filters: this.filters,
        pagination: 'N'
      };

      const [error, data] = await getCampaignsApi({ params: params });
      let campaignList = [];

      if (error) {
        console.error(error);
      } else {
        // console.log(`data`, data);
        const { campaigns } = data;

        campaignList = campaigns;
      }
      this.loading = false;

      return await this.hashing(campaignList);
      // try {
      //   const { data } = await this.$axios.get(`/admin/campaigns-media`, {
      //     params: params
      //   });
      //   const { campaigns } = data;
      //   return this.hashing(campaigns);
      // } catch (error) {
      //   console.log(error);
      // } finally {
      //   this.loading = false;
      // }
    },
    errorAlert() {
      this.$Swal.fire({
        html: '<h3>캠페인이 존재하지 않습니다.</h3>',
        icon: 'error',
        iconHtml: '!',
        confirmButtonText: '확인'
      });
    },
    hashing(campaigns) {
      const hashedCampaigns = campaigns.map(campaign => {
        const campaignObj = new Campaign(campaign, true);
        return {
          ...new Campaign(campaign),
          mediaGroups: campaignObj.mediaGroups
            ?.map(mediaGroup => mediaGroup.name)
            .join(','),
          activeStatus: campaignObj.activeStatus ? '라이브' : '중지',
          type: this.campaignTypeList.find(
            item => item.value === campaignObj.type
          ).text,
          createdAt: this.$moment(campaignObj.createdAt).format('YYYY-MM-DD'),
          targetTags: campaignObj.targetTags?.map(tag => tag.name).join(','),
          targetTagsType: campaignObj.targetTagsType
            ? `${campaignObj.targetTagsMode}, ${campaignObj.targetTagsType}`
            : '',
          tags: campaignObj.tags?.map(tag => tag.name).join(','),
          targetAppsType: `${
            campaignObj.targetAppsType
          } @${campaignObj.targetApps.map(app => app.package).join('@')}`,
          targetTagsCount: campaignObj.targetTagsCount ?? 0,
          targetLocations:
            campaignObj.targetLocations.length > 0 ? '활성' : '비활성',
          remainDeposit: campaignObj.remainDeposit,
          costTypes: campaignObj.costTypes
            ?.map(costType => costType.text)
            .join(',')
        };
      });

      return {
        hashedCampaigns: hashedCampaigns
      };
    }
  }
};
</script>

<style></style>
