<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    scrollable
    persistent
    eager
    max-width="1300"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>캠페인 {{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-3 px-0">
        <DidCampaignServiceRegisterMain
          v-if="!didCampaignService.id"
          @refresh="handleRefreshList"
        />
        <DidCampaignServiceDetail v-else @refresh="handleRefreshList" />
      </v-card-text>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DidCampaignServiceRegisterMain from '../register/DidCampaignServiceRegisterMain.vue';
import DidCampaignServiceDetail from '../detail/DidCampaignServiceDetail.vue';

export default {
  components: {
    DidCampaignServiceRegisterMain,
    DidCampaignServiceDetail
  },
  data() {
    return {
      refKey: 'didCampaignServiceDialog'
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didCampaignService/dialog',
      loading: 'didCampaignService/loading',
      didCampaignService: 'didCampaignService/didCampaignService'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return !this.didCampaignService.id ? '등록' : '상세';
    },
    minEndTimeHour() {
      if (!this.didCampaignService.scheduleOpenAt) return 0;
      const minHour = this.didCampaignService.scheduleOpenAt?.split(':')[0];
      return Number(minHour) + 1;
    }
  },
  watch: {
    dialog() {
      this.loading.dialog = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'didCampaignService/closeDialog'
    }),
    /** @description: 닫기 */
    handleCloseClick() {
      // 변경사항 있는경우 확인
      if (!this.didCampaignService.id && this.didCampaignService.isChanged()) {
        this.$Swal
          .fire({
            icon: 'info',
            title: `캠페인 ${this.titleText} `,
            html: '<h3>변경 사항이 있습니다.<br/> 저장하지 않고 닫으시겠습니까?</h3>',
            showConfirmButton: true,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.closeDialog();
            }
          });
      } else {
        this.closeDialog();
      }
    },
    handleRefreshList() {
      this.$emit('refresh');
    }
  }
};
</script>
