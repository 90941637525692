export const MediaActive = [
  {
    text: '전체',
    value: '',
    color: '',
  },
  {
    text: '활성',
    value: 'Y',
    color: 'green',
  },
  {
    text: '비활성',
    value: 'N',
    color: 'red',
  },
];

export const MediaGroupTypeList = [
  {
    text: '전체',
    value: '',
    color: '',
  },
  {
    text: '직매체',
    value: 'D',
    color: 'blue',
  },
  {
    text: '대행사',
    value: 'A',
    color: 'purple',
  },
];
