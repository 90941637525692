<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="ml-auto">
            <!-- <v-btn depressed dark @click="openDialog()"> 앱 등록 </v-btn> -->
          </div>
        </div>
      </v-col>
    </v-row>
    <a-card class="d-flex" style="flex-direction: column">
      <v-row id="schedule-selected-wrap">
        <v-col>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="(item, index) in selectOptions"
              :key="index"
              class="mt-2 location-expansion"
              active-class="active-location-expansion"
            >
              <v-expansion-panel-header class="font-weight-medium py-1">
                {{ item.title }}
                <v-col v-if="item.selected" cols="auto">
                  <v-chip
                    label
                    outlined
                    small
                    color="primary"
                    class="ml-1 font-weight-bold"
                  >
                    ( {{ item.selected.name }} )
                  </v-chip>
                </v-col>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <a-table
                  :loading="indexLoading"
                  class="table-striped-rows"
                  style="white-space: nowrap"
                  size="small"
                  rowKey="id"
                  :columns="columns"
                  bordered
                  :data-source="item.list"
                  :scroll="{ x: true }"
                  :locale="{
                    emptyText: '데이터가 없습니다.'
                  }"
                  :pagination="false"
                  :customRow="
                    record => {
                      return {
                        on: {
                          click: event => {
                            handleRowClick(record, item.type);
                          }
                        }
                      };
                    }
                  "
                >
                  <template slot="rawData" slot-scope="value, record">
                    <v-row
                      :class="` ${
                        item.selected && record.id === item.selected.id
                          ? 'primary white--text'
                          : ''
                      }`"
                    >
                      <v-col>
                        {{ value }}
                      </v-col>
                    </v-row>
                  </template>
                </a-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <template v-if="selectedData.didSettopBoxId">
        <v-row class="ma-0 mt-4">
          <v-divider />
        </v-row>

        <DidScheduleCalendar />
      </template>

      <!-- <v-row justify="end">
        <v-col cols="auto">
          <v-btn
            v-if="!showDidSchedule"
            color="primary"
            :disabled="selectedStep !== 4"
            @click="showDidSchedule = true"
          >
            스케줄 확인
          </v-btn>
          <v-btn
            v-else
            color="primary"
            outlined
            @click="showDidSchedule = false"
          >
            이전
          </v-btn>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-pagination
            v-if="pagination.totalCount"
            v-model="pagination.page"
            :length="pagination.totalPage"
            :total-visible="pagination.perPage"
            color="secondary"
          />
        </v-col>
      </v-row>
    </a-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MenuTitle from '@/components/MenuTitle.vue';
import DidScheduleCalendar from './components/DidScheduleCalendar';
import { Pagination } from '@/model/Pagination';

import { getDidMediaGroupOptionsApi } from '@/api/admin/DidMediaGroupAPI';
import { getDidBranchOptionsApi } from '@/api/admin/DidBranchAPI';
import { getDidSettopBoxOptionsApi } from '@/api/admin/DidSettopBoxAPI';
// import { getDidSchedulesApi } from '@/api/admin/DidScheduleAPI';

export default {
  components: {
    MenuTitle,
    DidScheduleCalendar
  },
  data() {
    return {
      mediaGroups: [],
      didBranches: [],
      settopBoxes: [],
      pagination: new Pagination(1, 20),
      showDidSchedule: false,
      panel: [0]
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didSchedule/dialog',
      filters: 'didSchedule/filters',
      filterParams: 'didSchedule/filterParams',
      loading: 'didSchedule/loading',
      selectedData: 'didSchedule/selectedData',
      selectedStep: 'didSchedule/selectedStep'
    }),
    // 컬럼
    columns() {
      return [
        {
          title: '번호',
          align: 'center',
          dataIndex: 'id',
          scopedSlots: {
            customRender: 'rawData'
          }
        },
        {
          title: '이름',
          align: 'center',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'rawData'
          }
        }
      ];
    },
    // 재생 스케줄 선택 옵션
    selectOptions() {
      return [
        {
          title: '매체 그룹',
          selected: this.mediaGroups.find(
            item => item.id === this.selectedData.didMediaGroupId
          ),
          type: 'didMediaGroup',
          list: this.mediaGroups
        },
        {
          title: '매체',
          type: 'didBranch',
          selected: this.didBranches.find(
            item => item.id === this.selectedData.didBranchId
          ),
          list: this.didBranches
        },
        {
          title: '셋톱박스',
          selected: this.settopBoxes.find(
            item => item.id === this.selectedData.didSettopBoxId
          ),
          type: 'didSettopBox',
          list: this.settopBoxes
        }
      ];
    },
    tableData() {
      let res = [];
      switch (this.selectedStep) {
        case 2:
          res = this.didBranches;
          break;
        case 3:
          res = this.settopBoxes;
          break;
        default:
          res = this.mediaGroups;
      }
      return res;
    },
    indexLoading() {
      return this.loading.index;
    }
  },
  watch: {
    'pagination.page': function () {
      this.fetchDidSchedules();
    }
    // '$route.query': function (newVal) {
    //   let tempStep = 0;
    //   const { didMediaGroupId, didBranchId, didSettopBoxId } = newVal;

    //   if (!didMediaGroupId) tempStep = 1;
    //   if (!tempStep && !didBranchId) tempStep = 2;
    //   if (!tempStep && !didSettopBoxId) tempStep = 3;
    //   if (!tempStep) tempStep = 4;

    //   this.loading.index = false;

    //   this.handleRoutePath(newVal, tempStep === this.selectedStep);
    // }
  },
  async created() {
    this.loading.index = false;
    // this.initFilters();
    this.initSelectedData(); // 선택된 정보 모두 초기화
    this.handleRoutePath(this.$route.query);
  },
  methods: {
    ...mapActions({
      initFilters: 'didSchedule/initFilters',
      initSelectedData: 'didSchedule/initSelectedData',
      openDialog: 'didSchedule/openDialog'
    }),
    // 매체사 그룹 목록
    async fetchMediaGroupOptions() {
      if (this.loading.index) return;
      this.loading.index = true;
      this.mediaGroups = this.didBranches = this.settopBoxes = [];

      const [error, data] = await getDidMediaGroupOptionsApi();

      this.loading.index = false;

      if (error) {
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_media_groups } = data;
        this.mediaGroups = did_media_groups.map(group => ({
          ...group,
          name: `${group.name} (${group.type === 'A' ? '대행사' : '직매체'})`
        }));
      }
    },
    // 매체사 목록
    async fetchgetDidBranchOptions() {
      if (this.loading.index) return;
      this.loading.index = true;
      this.didBranches = this.settopBoxes = [];

      const [error, data] = await getDidBranchOptionsApi({
        params: { mediaGroupCode: [this.selectedData.didMediaGroupId] }
      });

      this.loading.index = false;

      if (error) {
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { branches } = data;
        this.didBranches = [...branches];
      }
    },
    // 셋톱박스 목록
    async fetchgetDidSettopBoxOptions() {
      if (this.loading.index) return;
      this.loading.index = true;
      this.settopBoxes = [];

      const [error, data] = await getDidSettopBoxOptionsApi({
        params: {
          didBranchIds: [this.selectedData.didBranchId]
        }
      });

      this.loading.index = false;

      if (error) {
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { settop_boxes } = data;
        this.settopBoxes = [...settop_boxes];
      }
    },
    async handleRoutePath(query, isSameStep = false) {
      const { didMediaGroupId, didBranchId, didSettopBoxId } = query;

      this.selectedData.didMediaGroupId = didMediaGroupId ?? null;
      this.selectedData.didBranchId =
        this.selectedData.didMediaGroupId && didBranchId ? didBranchId : null;
      this.selectedData.didSettopBoxId =
        this.selectedData.didBranchId && didSettopBoxId ? didSettopBoxId : null;

      // 동일한 스텝이면 목록 조회 안함
      if (isSameStep) return;

      // 목록 조회
      switch (this.selectedStep) {
        case 1:
          await this.fetchMediaGroupOptions();
          break;
        case 2:
          await this.fetchgetDidBranchOptions();
          break;
        case 3:
          await this.fetchgetDidSettopBoxOptions();
          break;
      }
    },
    async handleRowClick(record, type = 'didMediaGroup') {
      if (!record || this.loading.index) return;
      this.loading.index = true;
      const tempData = {
        didMediaGroupId: null,
        didBranchId: null,
        didSettopBoxId: null
      };

      // switch (this.selectedStep) {
      switch (type) {
        case 'didBranch':
          tempData.didMediaGroupId = this.selectedData.didMediaGroupId;
          tempData.didBranchId = record.id;
          this.panel = [2];
          // this.$router.push({
          //   name: 'DidSchedule',
          //   query: {
          //     didMediaGroupId: this.selectedData.didMediaGroupId,
          //     didBranchId: record.id
          //   }
          // });
          break;
        case 'didSettopBox':
          tempData.didMediaGroupId = this.selectedData.didMediaGroupId;
          tempData.didBranchId = this.selectedData.didBranchId;
          tempData.didSettopBoxId = record.id;
          // this.$router.push({
          //   name: 'DidSchedule',
          //   query: {
          //     didMediaGroupId: this.selectedData.didMediaGroupId,
          //     didBranchId: this.selectedData.didBranchId,
          //     didSettopBoxId: record.id
          //   }
          // });
          break;
        default:
          tempData.didMediaGroupId = record.id;
          this.panel = [1];
        // this.$router.push({
        //   name: 'DidSchedule',
        //   query: {
        //     didMediaGroupId: record.id
        //   }
        // });
      }

      let tempStep = 0;

      if (!tempData.didMediaGroupId) tempStep = 1;
      if (!tempStep && !tempData.didBranchId) tempStep = 2;
      if (!tempStep && !tempData.didSettopBoxId) tempStep = 3;
      if (!tempStep) tempStep = 4;

      this.loading.index = false;
      this.handleRoutePath(tempData, this.selectedData === tempStep);
    }
  }
};
</script>
<style lang="scss">
#schedule-selected-wrap {
  .location-expansion {
    background-color: transparent;
    border: 1px solid #999;
    border-radius: 8px;
  }
  .active-location-expansion {
    background-color: rgba(255, 255, 255, 0.8) !important;
    border: 1.5px solid #1976d2;

    > button {
      // min-height: 16px;
      color: #1976d2;
    }
  }

  .v-btn-toggle--group > .v-btn.button_selected {
    border: 2px solid #1976d2;
    border-radius: 10px;

    &.off {
      background: rgba(153, 153, 153, 0.5) !important;
      border-color: rgb(153, 153, 153);
      color: #333 !important;
    }
  }
  .v-btn__content,
  .v-chip__content {
    max-width: 100% !important;

    span {
      max-width: 100% !important;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.75rem !important;
    }
  }

  .v-chip__content {
    span,
    button {
      color: #fff !important;
      font-weight: 500 !important;
    }
  }
}
</style>
