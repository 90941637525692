import axios from 'axios';
/**
 * @description: 관리자 fcm 토큰 저장
 * */
export async function setFcmTokenApi(params) {
  try {
    const { data } = await axios.post(`/save-token`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: task 목록 전체 조회
 * */
export async function getTaskApi(params) {
  try {
    const { data } = await axios.get(`/admin/tasks`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: task 목록 미할당건 조회
 * @params { page: number }
 * */
export async function getUnsignedTaskApi(params) {
  try {
    const { data } = await axios.get(`/admin/tasks/unsigned`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: task 할당
 * @param taskId: string
 * */
export async function assignTaskApi(taskId) {
  try {
    const { data } = await axios.patch(`/admin/task/${taskId}/assign`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
/**
 * @description: task 삭제
 * */
export async function deleteTaskApi(params) {
  try {
    const { data } = await axios.delete(`/admin/task`, params);
    return [null, data];
  } catch (error) {
    return [error];
  }
}
