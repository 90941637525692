<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ReportFilter @applyFilters="applyFilters" :loading="loading" />
      </v-col>
      <v-col cols="12">
        <MediaLiveChart
          v-if="!loading"
          title="차트"
          :showTitle="false"
          :dateRanges="dateRanges"
          :stats="chartStats"
          :loading="loading"
        />
        <v-skeleton-loader v-show="loading" type="card"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col>
        <v-card>
          <v-row>
            <v-col cols="12">
              <Table
                class="px-3"
                style="white-space:nowrap"
                :data-source="stats"
                :columns="columns"
                :rowClassName="
                  (record, index) => {
                    if (index === 0) {
                      return 'table-summary-rows';
                    }
                  }
                "
                :rowKey="
                  (record, index) => {
                    return `${record.date}`;
                  }
                "
                bordered
                :pagination="false"
                :loading="loading"
                size="small"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
              >
                <DayOfWeek
                  v-for="(date, dateIndex) in dates"
                  :key="dateIndex"
                  :slot="date.value"
                  :date="date.value"
                />
                <template
                  v-for="(date, dateIndex) in dates"
                  :slot="date.value"
                  slot-scope="data, record"
                >
                  <span
                    :key="`${dateIndex}`"
                    :class="
                      `${getGapIcon(record, dateIndex).color}--text darken-3`
                    "
                  >
                    <span class="title">{{ data.toLocaleString() }}</span>
                    <div class="d-flex align-center justify-end">
                      <v-icon :color="getGapIcon(record, dateIndex).color">{{
                        getGapIcon(record, dateIndex).icon
                      }}</v-icon>
                      {{ getGapIcon(record, dateIndex).percent }}
                    </div>
                  </span>
                </template>
                <template slot="localeString" slot-scope="value">
                  {{ value > 0 ? value.toLocaleString() : '-' }}
                </template>
                <template slot="percent" slot-scope="value">
                  {{ value }} %
                </template>
                <template slot="money" slot-scope="text">
                  {{ text | KRW }}
                </template>
              </Table>
              <!-- <div class="d-flex justify-end pa-3" v-if="stats.length > 0">
                <a-pagination
                  v-model="pagination.page"
                  :defaultPageSize="10"
                  :page-size.sync="pagination.perPage"
                  size="large"
                  :show-size-changer="true"
                  :pageSizeOptions="['10', '20', '50', '100']"
                  :total="pagination.totalCount"
                  @change="fetchStats()"
                  @showSizeChange="fetchStats(1)"
                >
                </a-pagination>
              </div> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- <SpeedDialMenu
      v-if="stats.length > 0"
      :dateRanges="dateRanges"
      :liveType="filters.liveType"
    /> -->
  </v-container>
</template>
<script>
import MenuTitle from '@/components/MenuTitle.vue';
// import CampaignLiveChart from './components/CampaignLiveChart.vue';
import MediaLiveChart from './components/MediaLiveChart.vue';
import ReportFilter from './components/ReportFilter.vue';
import { Table } from 'ant-design-vue';
import DayOfWeek from '@/components/DayOfWeek.vue';
// import SpeedDialMenu from './components/SpeedDialMenu.vue';

// import ReportExcelExport from './components/ReportExcelExport.vue';
import { getReportStatsAPI } from '@/api/admin/partners/NetworkAPI';
import { Pagination } from '@/model/Pagination';
export default {
  components: {
    MenuTitle,
    // CampaignLiveChart,
    MediaLiveChart,
    ReportFilter,
    Table,
    DayOfWeek
    // SpeedDialMenu
    // ReportExcelExport
  },
  data() {
    return {
      pagination: new Pagination(1, 10),
      day: this.$moment().format('YYYY-MM-DD'),
      dateRanges: [],
      dates: [],
      columns: [
        {
          title: '날짜',
          dataIndex: 'date'
        },
        {
          title: '노출',
          dataIndex: 'imp',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '클릭',
          dataIndex: 'click',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: 'CTR',
          dataIndex: 'ctr',
          align: 'right',
          scopedSlots: {
            customRender: 'percent'
          }
        },
        {
          title: '수익',
          dataIndex: 'revenue',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          }
        }
      ],

      stats: [],
      chartStats: [],
      loading: true,
      filters: null
    };
  },
  // created() {
  //   this.fetchStats();
  // },
  methods: {
    async applyFilters(filters) {
      this.filters = filters;
      this.dateRanges = filters.dateRanges;

      this.fetchStats(1);
    },
    initStats() {
      this.stats = [];
    },
    async fetchStats(page = this.pagination.page) {
      this.loading = true;
      this.setDates();
      this.initStats();

      this.pagination.page = page;
      const params = {
        params: {
          ss: this.searchString,
          page: page,
          perPage: this.pagination.perPage,
          dateRanges: this.filters.dateRanges,
          networkIds: this.filters.networkIds
        }
      };
      const [error, data] = await getReportStatsAPI(params);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);

        const { stats } = data;

        const imp = stats.reduce((acc, cur) => {
          return acc + Number(cur.imp);
        }, 0);
        const click = stats.reduce((acc, cur) => {
          return acc + Number(cur.click);
        }, 0);

        const ctr = parseFloat((click / imp) * 100).toFixed(2);

        const revenue = stats.reduce((acc, cur) => {
          return acc + Number(cur.revenue);
        }, 0);

        this.summaryStat = {
          date: '전체',
          imp: imp,
          click: click,
          ctr: ctr,
          revenue: revenue,
          summary: true
        };

        const ctrStats = stats.map(stat => {
          const click = Number(stat.click);
          const imp = Number(stat.imp);
          const ctr = ((click / imp) * 100).toFixed(2);
          return {
            key: stat.date,
            date: stat.date,
            revenue: stat.revenue,
            imp: imp,
            click: click,
            ctr: ctr
          };
        });
        this.chartStats = ctrStats;
        this.stats = [this.summaryStat, ...ctrStats];
        // this.pagination.totalCount = total;
        // this.pagination.totalPage = totalPage;
        this.loading = false;
      }
    },
    setDates() {
      this.dates = [];

      const startDate = this.$moment(this.dateRanges[0]);
      const endDate = this.$moment(this.dateRanges[1]);

      for (let current = startDate; current <= endDate; current.add(1, 'd')) {
        this.dates.push({
          value: current.format('YYYY-MM-DD'),
          slotName: 'item.' + current.format('YYYY-MM-DD')
        });
      }
    },
    test(record) {
      console.log(record);
    }
  }
};
</script>
<style>
.table-summary-rows {
  background-color: #faf4c0;
  font-size: '16px';
  font-weight: 800;
}
</style>
