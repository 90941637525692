export const ActiveStatusList = [
  { text: '활성', value: true },
  { text: '비활성', value: false }
];

export const CampaignFilterType = [
  {
    text: '캠페인 생성기간',
    value: 'dateRanges'
  },
  {
    text: '광고유형',
    value: 'campaignTypes'
  },
  {
    text: '게재',
    value: 'approveStatus'
  },
  {
    text: '상태',
    value: 'activeStatus'
  },
  {
    text: '태그 타겟',
    value: 'tagFilter'
  },
  {
    text: '광고주 계정',
    value: 'advertiserIds'
  },
  {
    text: '광고그룹',
    value: 'advertiserGroupIds'
  },
  {
    text: '캠페인',
    value: 'campaignIds'
  },
  {
    text: '매체그룹',
    value: 'mediaGroupIds'
  },
  {
    text: '캠페인 생성소',
    value: 'isAdmin'
  },
  {
    text: '광고주 유형',
    value: 'advertiserType'
  },
  // 리포트에서 사용
  {
    text: '측정기준',
    value: 'measureStandard'
  }
];
