var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.titles.advertiser))]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.advertisers,"error-messages":errors,"item-text":"name","item-value":"id","hide-no-data":"","placeholder":_vm.placeholder.advertiser,"clearable":"","required":"","outlined":"","return-object":"","auto-select-first":""},on:{"change":_vm.handleAdvertiserChange},model:{value:(_vm.campaign.advertiser),callback:function ($$v) {_vm.$set(_vm.campaign, "advertiser", $$v)},expression:"campaign.advertiser"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.titles.advertiserGroup))]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.advertiserGroups,"error-messages":errors,"item-text":"name","item-value":"id","placeholder":_vm.placeholder.advertiserGroup,"no-data-text":"계정에 광고 그룹이 존재 하지않습니다. 광고 그룹을 추가하세요.","clearable":"","required":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticStyle:{"border":"none !important"},attrs:{"input-value":data.selected,"outlined":"","label":"","pill":""},on:{"click":data.select}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"tile":"","left":""}},[(data.item.icon)?_c('v-img',{staticClass:"rounded-lg",attrs:{"src":data.item.icon.url}}):_vm._e()],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [(typeof data.item === 'object')?[_c('v-list-item-avatar',{attrs:{"tile":"","width":"40"}},[(data.item.icon)?_c('v-img',{staticClass:"rounded-lg",attrs:{"src":data.item.icon.url}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]:_vm._e()]}}],null,true),model:{value:(_vm.campaign.advertiserGroup),callback:function ($$v) {_vm.$set(_vm.campaign, "advertiserGroup", $$v)},expression:"campaign.advertiserGroup"}})]}}])})],1),(!_vm.campaign.isTypeCoupon)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.titles.mediaGroup))]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.mediaGroups,"error-messages":errors,"item-text":"name","hide-no-data":"","placeholder":_vm.placeholder.mediaGroup,"clearable":"","required":"","outlined":"","multiple":"","return-object":"","auto-select-first":""},model:{value:(_vm.campaign.mediaGroups),callback:function ($$v) {_vm.$set(_vm.campaign, "mediaGroups", $$v)},expression:"campaign.mediaGroups"}})]}}],null,false,3344838076)})],1):_vm._e(),(!_vm.campaign.isTypeCoupon)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.titles.tracker))]),_c('validation-provider',{attrs:{"rules":{
          required: false
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.trackers,"error-messages":errors,"item-text":"name","hide-no-data":"","placeholder":_vm.placeholder.tracker,"clearable":"","required":"","outlined":"","return-object":"","auto-select-first":""},model:{value:(_vm.campaign.tracker),callback:function ($$v) {_vm.$set(_vm.campaign, "tracker", $$v)},expression:"campaign.tracker"}})]}}],null,false,3080593162)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }