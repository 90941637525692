<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="{
      required: true,
      max_krw: maxKrw ? maxKrw : false,
      min_value_krw: minValueKrw ? minValueKrw : false
    }"
  >
    <v-text-field
      v-model="formattedAmount"
      :required="required"
      outlined
      @focus="inputFocus($event.target)"
      @change="handleAmountChange"
      :prefix="prefix"
      :error-messages="errors"
      :disabled="disabled"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
export default {
  components: {
    ValidationProvider
  },
  props: {
    value: {
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    round: {
      type: Number,
      default: 1000
    },
    maxKrw: {
      type: Number,
      default: null
    },
    minValueKrw: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(newValue) {
      this.handleAmountChange(newValue ?? 0);
    }
  },
  data() {
    return {
      formattedAmount: null
    };
  },
  mounted() {
    if (this.value) {
      this.handleAmountChange(this.value);
    }
  },
  methods: {
    handleAmountChange(price) {
      if (price == null) {
        return false;
      }

      let amount = Number(String(price).replaceAll(',', ''));
      if (isNaN(amount)) {
        this.$emit('setAmount', null);
        this.formattedAmount = null;
      } else {
        // 반올림처리
        if (amount > this.round) {
          amount = Math.round(amount / this.round) * this.round;
        }
        this.$emit('setAmount', amount);
        this.formattedAmount = amount.toLocaleString();
      }
    },
    inputFocus(target) {
      target.select();
    }
  }
};
</script>

<style></style>
