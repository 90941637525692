import moment from 'moment';

export default {
  namespaced: true,

  state: {
    dialog: false,
    commentDialog: false,
    record: {},
    filters: {
      statusFlag: '',
      userName: '',
      userEmail: '',
      paymentMethod: '',
      dates: [moment().subtract(3, 'month'), moment()],
      searchString: '',
      searchType: 'order_no'
    }
  },
  getters: {
    dialog(state) {
      return state.dialog;
    },
    commentDialog(state) {
      return state.commentDialog;
    },
    record(state) {
      return state.record;
    },
    filters(state) {
      return state.filters;
    }
  },
  mutations: {
    setDialog(state, dialog) {
      state.dialog = dialog;
    },
    setCommentDialog(state, dialog) {
      state.commentDialog = dialog;
    },
    setRecord(state, record) {
      state.record = record;
    }
  },
  actions: {
    openDialog({ commit }, record) {
      commit('setRecord', record);
      commit('setDialog', true);
    },
    closeDialog({ commit }) {
      commit('setRecord', {});
      commit('setDialog', false);
    },
    openCommentDialog({ commit }) {
      commit('setCommentDialog', true);
    },
    closeCommentDialog({ commit }) {
      commit('setCommentDialog', false);
    }
  }
};
