var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a-card',[_c('div',{staticClass:"row ma-0 justify-end mb-5"},[_c('v-btn',{attrs:{"color":"secondary","small":_vm.$isMobile},on:{"click":function($event){return _vm.handleOpenDialogClick()}}},[_vm._v(" 목록 추가 ")])],1),_c('a-table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"loading":_vm.loading,"rowKey":"id","size":"small","columns":_vm.columns,"bordered":"","data-source":_vm.filterList,"scroll":{ x: true },"locale":{
            emptyText: '캠페인 목록을 추가해 주세요.'
          },"pagination":false,"customRow":function (record) {
              return {
                on: {
                  click: function (event) {
                    _vm.handleOpenDialogClick(record);
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"name",fn:function(value){return [_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(_vm._s(value))])]}},{key:"filterOptions",fn:function(value, record){return [_c('v-row',{staticClass:"ma-0",staticStyle:{"max-width":"600px","overflow-y":"auto"}},_vm._l((record.filtersText),function(filter,idx){return _c('v-chip',{key:idx,staticClass:"mr-2 mt-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(filter)+" ")])}),1)]}},{key:"actions",fn:function(value, record){return [_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeNameDialog(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("캠페인 이름 변경")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"type":"primary","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveClick(record)}}},Object.assign({}, tooltipOn)),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v("캠페인 목록 삭제")])])]}}])}),(_vm.filterList.length > 0)?_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('a-pagination',{attrs:{"defaultPageSize":10,"page-size":_vm.pagination.perPage,"size":"large","show-size-changer":true,"pageSizeOptions":['10', '20', '50', '100'],"total":_vm.pagination.totalCount},on:{"update:pageSize":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"change":function($event){return _vm.fetchFiltersApi()},"showSizeChange":function($event){return _vm.fetchFiltersApi(1)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)],1)],1),(_vm.isUpdateDialog)?_c('FilterNameChangeDialog',{attrs:{"openDialog":_vm.filterNameChangeDialog,"filter":_vm.selectFilter},on:{"closeDialog":_vm.handleCloseFilterNameDialog,"refresh":_vm.fetchFiltersApi}}):_vm._e(),(_vm.isCampaignsDialog)?_c('AdminCampaignsDialog',{attrs:{"dialog":_vm.campaignsDialog,"selectFilter":_vm.selectFilter,"campaignFilterList":_vm.filterList},on:{"closeDialog":_vm.handleCloseCampaignDialog,"update":_vm.handleFilterUpdate,"refresh":_vm.fetchFiltersApi,"changeFilter":_vm.handleSelectFilterChange}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }