export default {
  namespaced: true,

  state: {
    menus: [
      {
        text: 'DID',
        active: true,
        items: [
          {
            icon: 'mdi-projector-screen',
            text: '캠페인 목록',
            link: '/did/campaign'
          }
        ]
      }
    ],
    bottomMenus: [
      //   {
      //     icon: 'mdi-account-question',
      //     text: '1:1 문의',
      //     name: 'PartnerMember',
      //     params: {
      //       menu: 'inquiry'
      //     }
      //   },
      // {
      //   icon: 'mdi-account',
      //   text: 'My Page',
      //   name: 'DidMember',
      //   params: {
      //     menu: 'mypage'
      //   }
      // }
    ]
  },
  getters: {
    menus(state) {
      return state.menus;
    },
    bottomMenus(state) {
      return state.bottomMenus;
    }
  }
};
