<template>
  <v-app
    id="inspire"
    :style="{
      backgroundColor: '#f2f2f2'
    }"
  >
    <!-- <v-app
    id="inspire"
    :style="{
      backgroundImage: 'url(' + require('@/assets/bg-1.jpeg') + ')',
      backgroundSize: 'cover'
    }" -->
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center" style="max-width: initial">
        <v-col cols="12" sm="8" md="4" class="px-6" style="max-width: 480px">
          <v-card class="elevation-12">
            <v-toolbar dark flat>
              <v-img
                max-height="50"
                max-width="100"
                :src="$mainLogoPath"
              ></v-img>
            </v-toolbar>
            <v-card-text>
              <ValidationObserver ref="loginRef">
                <v-form ref="form" lazy-validation @keyup.native.enter="submit">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="email"
                      label="아이디 또는 이메일"
                      prepend-icon="mdi-account-circle"
                      :error-messages="errors"
                      required
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|min:6"
                  >
                    <v-text-field
                      v-model="password"
                      label="패스워드"
                      type="password"
                      prepend-icon="lock"
                      :error-messages="errors"
                      required
                    />
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>
            <v-card-actions class="py-0">
              <!-- <RegisterDialog /> -->
              <v-spacer />
              <v-btn color="primary" text @click="submit" :loading="loading"
                >로그인</v-btn
              >
            </v-card-actions>
            <!-- <ResetPasswordDialog /> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import store from '../../store';
// import ResetPasswordDialog from './components/ResetPasswordDialog.vue';
// import RegisterDialog from './components/RegisterDialog.vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver
    // ResetPasswordDialog,
    // RegisterDialog
  },
  data() {
    return {
      email: null,
      password: null,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated'
    }),

    form() {
      return {
        email: this.email,
        password: this.password
      };
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    async submit() {
      const isValid = await this.$refs.loginRef.validate();
      if (!isValid) {
        return false;
      }

      // key enter 로 로그인 요청시 중복 요청을 방지하기위해 loading 상태를 확인합니다.
      if (this.loading === true) {
        return false;
      }
      this.loading = true;
      const home = {
        1: 'MediaHome',
        2: 'AdvertiserReport',
        3: 'AdminDashboard',
        4: 'AdminDashboard',
        5: 'MediaHome',
        6: 'AdminCampaign', //운영관리자
        8: 'PartnerReport', //파트너스
        9: 'DidCampaign', //didMediaGroup
        10: 'DidCampaign' //didBranch
      };

      if (this.$refs.form.validate()) {
        this.loading = true;
        this.login(this.form)
          .then(() => {
            if (this.authenticated) {
              const user = store.getters['auth/user'];

              if (home[user.level] == undefined) {
                alert('접근 권한이 없습니다.');
                return false;
              }
              this.$router.replace({
                name: home[user.level]
              });
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
