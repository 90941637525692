export class Tag {
    constructor(id, name, tagTypeId, latestCount, totalCount) {
        this.id = id
        this.name = name;
        this.tagTypeId = tagTypeId;
        this.tagType = null;
        this.latestCount = latestCount;
        this.totalCount = totalCount;
        this.createdAt = null;
        this.category = null;
        this.count = null;
        this.origin = null;
        this.campaignTags = [];
    }
}