var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"obs4",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"pa-5",staticStyle:{"background-color":"white"}},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('text-field-title',{attrs:{"title":_vm.titles.rs4.impressionTime}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.rs4.impressionTime),callback:function ($$v) {_vm.$set(_vm.rs4, "impressionTime", $$v)},expression:"rs4.impressionTime"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.UnitOfTimeList,"item-text":"text","item-value":"value","item-disabled":"disable","outlined":"","label":"단위"},model:{value:(_vm.rs4.impressionTimeType),callback:function ($$v) {_vm.$set(_vm.rs4, "impressionTimeType", $$v)},expression:"rs4.impressionTimeType"}})],1)],1),_c('text-field-title',{attrs:{"title":_vm.titles.rs4.impressionFrequency,"subTitle":_vm.SubTitles.rs4.impressionFrequency}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              numeric: true,
              min_value: Math.min.apply(Math, _vm.impressionFrequency),
              max_value: Math.max.apply(Math, _vm.impressionFrequency)
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"suffix":"순위","required":"","outlined":"","items":_vm.impressionFrequency,"hide-no-data":""},model:{value:(_vm.rs4.impressionFrequency),callback:function ($$v) {_vm.$set(_vm.rs4, "impressionFrequency", $$v)},expression:"rs4.impressionFrequency"}})]}}],null,true)})],1)],1),_c('text-field-title',{attrs:{"title":_vm.titles.rs4.adsLimitInterval,"subTitle":_vm.SubTitles.rs4.adsLimitInterval}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","suffix":"일","outlined":""},model:{value:(_vm.rs4.adsLimitInterval),callback:function ($$v) {_vm.$set(_vm.rs4, "adsLimitInterval", $$v)},expression:"rs4.adsLimitInterval"}})]}}],null,true)})],1)],1),_c('text-field-title',{attrs:{"title":_vm.titles.rs4.adsGoalCount,"subTitle":_vm.SubTitles.rs4.adsGoalCount}}),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.rs4.adsGoalCount),callback:function ($$v) {_vm.$set(_vm.rs4, "adsGoalCount", $$v)},expression:"rs4.adsGoalCount"}})]}}],null,true)})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('campaign-content-footer',{attrs:{"invalid":invalid}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }