import axios from 'axios';

export async function getCampaignDataAPI(params) {
  try {
    const { data } = await axios.get(
      `/admin/dashboard/campaign/summary`,
      params
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getUserDataAPI() {
  try {
    const { data } = await axios.get(`/admin/dashboard/user/summary`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}
