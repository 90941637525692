<template>
  <v-card outlined>
    <v-card-title> 일정 </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <div v-if="campaign.isTypeMessage">
        <alert-message
          :value="!isEditableMessageDatetime"
          border="left"
          icon="mdi-alert"
          color="red"
          message="광고 전송 일시가 지났거나 남은 시간이 3시간 미만인 경우 수정이
          불가합니다."
        />
        <h3>{{ titles.messageDateTime }}</h3>
        <v-row no-gutters>
          <v-col cols="12" lg="6">
            <v-menu
              ref="messageDateMenu"
              v-model="modal.messageDate"
              :close-on-content-click="false"
              :nudge-right="40"
              :nudge-bottom="40"
              :return-value.sync="messageDate"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  :rules="{
                    required: true,
                  }"
                >
                  <v-text-field
                    v-model="campaign.messageDate"
                    prepend-inner-icon="mdi-calendar"
                    persistent-hint
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :outlined="isEditableMessageDatetime"
                    :filled="!isEditableMessageDatetime"
                    :disabled="!isEditableMessageDatetime"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-if="modal.messageDate"
                v-model="messageDate"
                :min="today"
                color="secondary"
                locale="ko-KR"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="setMessageDate()">
                  적용
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" lg="6">
            <v-menu
              ref="messageTimeMenu"
              v-model="modal.messageTime"
              :close-on-content-click="false"
              :nudge-right="40"
              :nudge-bottom="40"
              :return-value.sync="messageTime"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  :rules="{
                    required: true,
                  }"
                >
                  <v-text-field
                    v-model="campaign.messageTime"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :outlined="isEditableMessageDatetime"
                    :filled="!isEditableMessageDatetime"
                    :disabled="!isEditableMessageDatetime"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-time-picker
                v-if="modal.messageTime"
                v-model="messageTime"
                :allowed-minutes="allowedMinutes"
                format="24hr"
                full-width
                color="secondary"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="setMessageTime()">
                  적용
                </v-btn>
              </v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <alert-message
          border="left"
          icon="mdi-information"
          color="blue"
          message="광고방식이 메시지 타입인 경우 광고 시작일 종료일이 자동 지정 됩니다."
          localStorageKey="displayPeriodAlert"
          dismissible
        />
      </div>

      <h3>{{ titles.displayPeriodStart }}</h3>
      <v-row>
        <v-col cols="12">
          <v-menu
            v-model="modal.displayPeriod[0]"
            :close-on-content-click="false"
            :nudge-right="40"
            :nudge-bottom="40"
            transition="scale-transition"
            bottom
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="campaign.displayPeriodStart"
                prepend-inner-icon="mdi-calendar"
                persistent-hint
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                :outlined="!(campaign.isTypeMessage || isDialogTypeEdit)"
                :filled="campaign.isTypeMessage || isDialogTypeEdit"
                :disabled="campaign.isTypeMessage || isDialogTypeEdit"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="campaign.displayPeriodStart"
              @input="modal.displayPeriod[0] = false"
              :max="campaign.displayPeriodEnd"
              :min="displayPeriod[0]"
              color="green lighten-1"
              locale="ko-KR"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <h3>{{ titles.displayPeriodEnd }}</h3>
      <v-row>
        <v-col cols="12">
          <v-menu
            v-if="campaign.isTypeMessage"
            v-model="modal.displayPeriod[1]"
            :close-on-content-click="false"
            :nudge-right="40"
            :nudge-bottom="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider>
                <v-text-field
                  v-model="campaign.displayPeriodEnd"
                  prepend-inner-icon="mdi-calendar"
                  persistent-hint
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :outlined="!campaign.isTypeMessage"
                  :filled="campaign.isTypeMessage"
                  :disabled="campaign.isTypeMessage"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="campaign.displayPeriodEnd"
              @input="modal.displayPeriod[1] = false"
              :min="displayPeriod[0]"
              color="red lighten-1"
              locale="ko-KR"
            ></v-date-picker>
          </v-menu>
          <div v-else>
            <validation-provider v-slot="{ errors }" rules="required">
              <v-radio-group
                v-model="campaign.issetDisplayPeriodEnd"
                class="d-inline-block"
                :error-messages="errors"
              >
                <v-radio v-if="!isTagFilter" :value="false">
                  <template v-slot:label>
                    <div>
                      <h4>
                        설정안함
                        <information-hover
                          title="설정안함"
                          subTitle="광고종료일을 설정안함으로 할 경우, 총 예산이 모두 소진 될때까지 광고가 진행됩니다."
                        />
                      </h4>
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="true">
                  <template v-slot:label>
                    <v-menu
                      v-model="modal.displayPeriod[1]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :nudge-bottom="60"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          :rules="{
                            required: campaign.issetDisplayPeriodEnd,
                          }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="campaign.displayPeriodEnd"
                            class="mt-5"
                            prepend-inner-icon="mdi-calendar"
                            :error-messages="errors"
                            persistent-hint
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            :outlined="!campaign.isTypeMessage"
                            :filled="campaign.isTypeMessage"
                            :disabled="campaign.isTypeMessage"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="campaign.displayPeriodEnd"
                        @input="modal.displayPeriod[1] = false"
                        :min="displayPeriod[0]"
                        color="red lighten-1"
                        locale="ko-KR"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-radio>
              </v-radio-group>
            </validation-provider>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Titles } from '@/config/admin/campaign';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import InformationHover from '../../../../../../components/InformationHover.vue';
import AlertMessage from '@/components/AlertMessage.vue';
export default {
  components: {
    ValidationProvider,
    InformationHover,
    AlertMessage,
  },
  data() {
    return {
      displayPeriod: [
        this.$moment().format('YYYY-MM-DD'),
        this.$moment().add(1, 'month').format('YYYY-MM-DD'),
      ],
      labels: {},
      messageDate: null,
      messageTime: null,
      modal: {
        messageDate: false,
        messageTime: false,
        liveTime: [false, false],
        displayPeriod: [false, false],
      },
      today: this.$moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      isDialogTypeEdit: 'campaign/isDialogTypeEdit',
    }),
    isTagFilter() {
      return this.campaign.isTagFilter;
    },
    isEditableMessageDatetime() {
      if (!this.isDialogTypeEdit) {
        return true;
      }

      const messageDate = this.campaign.messageDate || null;
      const messageTime = this.campaign.messageTime || null;
      const now = this.$moment();

      if (messageDate && messageTime) {
        const diff = now.diff(
          this.$moment(`${messageDate} ${messageTime}`).format(
            'YYYY-MM-DD HH:mm'
          ),
          'hours'
        );
        if (diff > -3) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    titles() {
      return Titles.rs5.schedule;
    },
    allowedMinutes() {
      return Array.from(Array(60).keys()).filter(v => v % 5 === 0);
    },
  },
  created() {
    // 캠페인 등록일 경우 기본값 설정
    if (!this.isDialogTypeEdit) {
      this.campaign.displayPeriodStart = this.displayPeriod[0];
      this.campaign.displayPeriodEnd = this.displayPeriod[1];
    }
  },
  mounted() {
    if (this.isTagFilter) {
      this.campaign.issetDisplayPeriodEnd = true;
    }
  },
  methods: {
    validateDateTime() {
      const messageDate = this.messageDate || null;
      const messageTime = this.messageTime || null;

      if (!messageDate && !messageTime) {
        return false;
      }

      const datetime = this.$moment();
      const messageDateTime = this.$moment(
        messageDate + ' ' + messageTime
      ).format('YYYY-MM-DD HH:mm');

      const diffHours = datetime.diff(messageDateTime, 'hours', true);
      if (diffHours <= -3) {
        this.$refs.messageDateMenu.save(this.messageDate);
        this.$refs.messageTimeMenu.save(this.messageTime);
        this.setSchedule();
        this.modal.messageDate = false;
        this.modal.messageTime = false;
      } else {
        this.$Swal.fire({
          html: `<h3>잘못된 범위입니다.<br>현재 시간보다 최소 3시간 이후로 설정해주세요.<br><br>( ${this.$moment()
            .add(3, 'hours')
            .add(1, 'minute')
            .format('YYYY-MM-DD HH:mm')} ~ 이후 가능합니다. )</h3>`,
          confirmButtonText: '확인',
        });
      }
    },
    setMessageDate() {
      if (this.messageTime) {
        this.validateDateTime();
      } else {
        this.$refs.messageDateMenu.save(this.messageDate);
        this.setSchedule();
      }
    },
    setMessageTime() {
      if (this.messageDate) {
        this.validateDateTime();
      } else {
        this.$refs.messageTimeMenu.save(this.messageTime);
        this.setSchedule();
      }
    },
    setSchedule() {
      const messageDate = this.messageDate || null;
      const messageTime = this.messageTime || null;
      console.log(messageDate, messageTime);

      const startDay = messageDate
        ? this.$moment(messageDate).subtract(2, 'days').format('YYYY-MM-DD')
        : null;

      const endDay = messageDate
        ? this.$moment(messageDate).add(2, 'days').format('YYYY-MM-DD')
        : null;

      // 현재 날짜보다 이전일 경우 현재날짜를 시작날짜로 처리한다.
      this.campaign.displayPeriodStart =
        startDay < this.today ? this.today : startDay;
      this.campaign.displayPeriodEnd = endDay;
      this.campaign.messageDate = messageDate;
      this.campaign.messageTime = messageTime;
    },
    dismissAlert(key) {
      const data = {
        value: false,
        expire: this.$moment().add(1, 'days').unix(),
      };
      localStorage.setItem(key, JSON.stringify(data));
    },
  },
};
</script>

<style></style>
