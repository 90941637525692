<template>
  <v-dialog v-model="dialog" max-width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>앱 {{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-5 py-3">
        <ValidationObserver :ref="refKey">
          <v-row dense>
            <CustomGridItem title="앱 이름" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="app.name"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="앱 이름"
                    @focus="inputFocus($event.target)"
                    required
                    outlined
                    clearable
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="패키지 이름" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="app.package"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="패키지 이름"
                    @focus="inputFocus($event.target)"
                    required
                    outlined
                    clearable
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="버전" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="app.version"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="버전"
                    @focus="inputFocus($event.target)"
                    required
                    outlined
                    clearable
                    dense
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="버전 코드" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ numeric: true }"
                >
                  <v-text-field
                    v-model="app.versionCode"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    placeholder="버전 코드"
                    @focus="inputFocus($event.target)"
                    required
                    outlined
                    clearable
                    dense
                    type="number"
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title=".apk 파일 업로드"
              outlined
              :md="12"
              v-if="!app.id"
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-file-input
                    v-model="file"
                    color="accent-4"
                    placeholder=".apk 형식의 파일을 업로드해 주세요."
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    persistent-placeholder
                    outlined
                    show-size
                    dense
                    accept="application/vnd.android.package-archive"
                    @click:clear="handleRemoveClick"
                    @change="handleFileChange"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip color="deep-purple accent-4" dark label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem outlined :md="12" titleClass="col-12" v-if="app.id">
              <template v-slot:title>
                <v-row align="center" class="title-text ma-0">
                  링크
                  <v-spacer />
                  <v-tooltip v-if="app.link" bottom>
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        v-on="{ ...onTooltip }"
                        small
                        outlined
                        @click="copyToClipboard('appLink')"
                      >
                        <v-icon size="medium">mdi-content-duplicate</v-icon>
                        복사
                      </v-btn>
                    </template>
                    <span>링크 복사하기</span>
                  </v-tooltip>
                </v-row>
              </template>

              <template v-slot:content>
                <v-col>
                  {{ app.link || '-' }}
                  <input type="text" v-model="app.link" id="appLink" />
                </v-col>
              </template>
            </CustomGridItem>

            <CustomGridItem title="갱신일" outlined :md="12" v-if="app.id">
              <template v-slot:content>
                <v-col>
                  {{ app.updatedAt ? app.updatedAt : '-' }}
                </v-col>
              </template>
            </CustomGridItem>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn secondary outlined @click="handleCloseClick"> 닫기 </v-btn>
        <v-btn
          color="secondary"
          @click="handleSaveClick"
          :loading="dialogLoading"
          :disabled="dialogLoading || (app.id ? !this.app.isChanged() : false)"
        >
          {{ titleText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import { updateDidAppApi, createDidAppApi } from '@/api/admin/DidAppAPI';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomGridItem
  },
  data() {
    return {
      refKey: 'didAppDialog',
      file: null
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didApp/dialog',
      loading: 'didApp/loading',
      app: 'didApp/app'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return !this.app.id ? '등록' : '수정';
    },
    minEndTimeHour() {
      if (!this.app.scheduleOpenAt) return 0;
      const minHour = this.app.scheduleOpenAt?.split(':')[0];
      return Number(minHour) + 1;
    }
  },
  watch: {
    dialog() {
      this.loading.dialog = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'didApp/closeDialog'
    }),
    async handleSaveClick() {
      if (this.dialogLoading) return;

      const valid = await this.$refs[this.refKey].validate();

      if (!valid) return;

      if (!this.app.isChanged()) {
        this.$Swal.fire({
          icon: 'info',
          title: `앱 ${this.titleText} `,
          html: '<h3>변경 사항이 없습니다.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      this.loading.dialog = true;
      const formData = new FormData();

      const param = this.app.id
        ? this.app.getUpdatePayload()
        : this.app.getCreatePayload();

      if (!this.app.id) {
        for (const key in param) {
          let value = param[key];

          if (value) {
            formData.append(key, value);
          }
        }
      }

      const [error] = this.app.id
        ? await updateDidAppApi(this.app.id, param)
        : await createDidAppApi(formData);

      this.loading.dialog = false;

      if (error) {
        console.log(error);
        this.$Swal.fire({
          icon: 'error',
          title: `앱 ${this.titleText} 요청 실패`,
          html: `<h3>${
            error?.data.message ?? '잠시 후 다시 시도해주세요'
          }</h3>`,
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
      } else {
        this.$emit('refresh');

        this.$Swal
          .fire({
            icon: 'success',
            title: `앱 ${this.titleText} `,
            html: `<h3>앱이 ${this.titleText}되었습니다.</h3>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          })
          .then(() => this.closeDialog());
      }
    },
    /** @description: 닫기 */
    handleCloseClick() {
      // 변경사항 있는경우 확인
      if (this.app.isChanged()) {
        this.$Swal
          .fire({
            icon: 'info',
            title: `앱 ${this.titleText} `,
            html: '<h3>변경 사항이 있습니다.<br/> 저장하지 않고 닫으시겠습니까?</h3>',
            showConfirmButton: true,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.closeDialog();
            }
          });
      } else {
        this.closeDialog();
      }
    },
    inputFocus(target) {
      target.select();
    },
    // 파일 선택시
    handleFileChange() {
      this.$nextTick(() => {
        const file = this.file;
        console.log(file);
        if (file == 'undefined' || file == null) return;

        const [nameError, nameErrorMessage] = this.validateFileType(file);
        if (nameError) {
          this.$Swal.fire({
            icon: 'error',
            html: '<h4>' + nameErrorMessage + '</h4>',
            showConfirmButton: true,
            confirmButtonText: '확인'
          });
          this.file = null;
          return;
        }
        const [sizeError, sizeErrorMessage] = this.validateFileSize(file);
        if (sizeError) {
          this.$Swal.fire({
            icon: 'error',
            html: `<h4>${sizeErrorMessage}</h4>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          });
          this.file = null;
          return;
        }
        // 화인 다 됐으면 app.file 정보 저장
        this.app.apk = this.file;
      });
    },
    // file type 체크
    validateFileType(file) {
      let error = false,
        message = null;

      if (file.type !== 'application/vnd.android.package-archive') {
        error = true;
        message = '<h3>.apk 파일만 업로드 가능합니다.</h3>';
      }

      return [error, message];
    },
    validateFileSize(file) {
      const { size } = file;
      const maxSize = 100; // MB
      const fileSizeMax = maxSize * 1024 * 1024;
      let error = false,
        message = null;

      if (size > fileSizeMax) {
        error = true;
        message = `${maxSize}MB 이하의 파일만 등록 가능합니다.<br/>파일의 용량을 확인주세요.`;
      }
      return [error, message];
    },
    handleRemoveClick() {
      this.updateImage = null;
      this.file = null;
      this.app.apk = null;
    },
    // 복사
    copyToClipboard(id) {
      const copy = document.querySelector(`#${id}`);
      copy.select();
      try {
        document.execCommand('copy');
        this.$Swal.fire({
          icon: 'success',
          title: '복사 완료.',
          timer: 1200
        });
      } catch (err) {
        this.$Swal.fire({
          icon: 'error',
          title: '복사 오류',
          showConfirmButton: false,
          timer: 1000
        });
      }
      /* unselect the range */
      window.getSelection().removeAllRanges();
    }
  }
};
</script>
