<template>
  <div>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <div class="mr-auto">
            <v-btn dark @click="openEditDialog()">
              태그 등록
            </v-btn>
          </div>
          <SearchForm
            placeholder="태그명, 태그유형으로 검색하세요."
            @setSearchString="
              v => {
                searchString = v;
                fetchTags(1);
              }
            "
          />
          <!-- <TagExcelExport :columns="columns" :stats="originTags" /> -->
        </div>
      </v-col>
    </v-row>
    <v-card class="my-3">
      <v-row>
        <v-col>
          <a-table
            class="px-3 table-striped-rows"
            :columns="columns"
            :data-source="tags"
            :loading="loading"
            size="small"
            :locale="{
              emptyText: '데이터가 없습니다.'
            }"
            bordered
            rowKey="id"
            :pagination="false"
            :scroll="{ x: 800, y: 1200 }"
            :customRow="
              record => {
                return {
                  on: {
                    mouseenter: event => {
                      hoverItem = record.id;
                    },
                    mouseleave: event => {
                      hoverItem = null;
                    }
                  }
                };
              }
            "
          >
            <template slot="name" slot-scope="value, record">
              <div
                class="d-inline-flex align-center"
                style="min-height:30px !important"
              >
                <span>{{ value }}</span>
                <div style="min-width:40px;">
                  <v-tooltip
                    v-if="hoverItem === record.id"
                    bottom
                    color="rgba(0,0,0,1)"
                  >
                    <template #activator="{ on: onTooltip }">
                      <v-btn
                        class="ml-2"
                        v-on="{ ...onTooltip }"
                        icon
                        small
                        @click="openEditDialog(record.origin)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>수정</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template slot="localeString" slot-scope="text">
              {{ text.toLocaleString() }}
            </template>
            <template slot="campaignTags" slot-scope="tags">
              <v-menu
                v-if="tags.length > 0"
                open-on-hover
                open-delay="100"
                close-delay="100"
                bottom
                offset-y
                nudge-left=""
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-0 font-weight-bold"
                    label
                    color="default"
                    small
                  >
                    <span
                      ># {{ tags[0].name }}
                      {{
                        tags.length > 1 ? `외 ${tags.length - 1}개` : ''
                      }}</span
                    >
                  </v-chip>
                </template>
                <v-card
                  width="300"
                  min-height="150"
                  max-height="300"
                  class="overflow-y-auto"
                >
                  <v-card-text>
                    <v-chip
                      v-for="(tag, index) in tags"
                      :key="index"
                      class="ma-1"
                      label
                      outlined
                      color="primary darken-3"
                    >
                      <v-icon>mdi-pound</v-icon>
                      <span>
                        {{ tag.name }}
                      </span>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </a-table>
          <div class="d-flex justify-end pa-3" v-if="hasTags">
            <a-pagination
              v-model="pagination.page"
              :defaultPageSize="pagination.defaultPageSize"
              :page-size.sync="pagination.perPage"
              size="large"
              :show-size-changer="true"
              :pageSizeOptions="['10', '20', '50', '100']"
              :total="pagination.totalCount"
              @change="fetchTags()"
              @showSizeChange="fetchTags(1)"
            >
            </a-pagination>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row>
      <v-col>
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="fetchTags()"
        ></v-pagination>
      </v-col>
    </v-row> -->
    </v-card>
  </div>
</template>

<script>
import { getTagsApi } from '@/api/admin/TagAPI';
import { Tag } from '@/model/admin/Tag';
import { Pagination } from '@/model/Pagination';
// import TagExcelExport from './TagExcelExport.vue';
import SearchForm from '@/components/SearchForm.vue';

export default {
  components: {
    // TagExcelExport,
    SearchForm
  },
  data() {
    return {
      columns: [],
      hoverItem: null,
      tags: [],
      originTags: [],
      loading: false,
      searchString: null,
      pagination: new Pagination(1, 20)
    };
  },
  created() {
    this.setHeaders();
    this.fetchTags();
  },
  computed: {
    hasTags() {
      return this.tags.length > 0;
    }
  },
  methods: {
    setHeaders() {
      const columns = [
        {
          title: '태그 번호',
          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => a.id - b.id
        },
        {
          title: '태그 명',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: {
            customRender: 'name'
          },
          sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
          title: '태그 유형',
          dataIndex: 'tagType',
          key: 'tagType',
          sorter: (a, b) => a.tagType.localeCompare(b.tagType)
        },
        {
          title: '수집 태그',
          dataIndex: 'campaignTags',
          key: 'campiagnTags',
          scopedSlots: {
            customRender: 'campaignTags'
          }
        },
        {
          title: '최근 30일 모수',
          dataIndex: 'latestCount',
          key: 'latestCount',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          sorter: (a, b) => a.latestCount - b.latestCount
        },
        {
          title: '전체 모수',
          dataIndex: 'count',
          key: 'count',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          },
          sorter: (a, b) => a.count - b.count
        },
        {
          title: '생성일',
          dataIndex: 'createdAt',
          key: 'createdAt',
          align: 'center',
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        }
      ];
      this.columns = columns;
    },
    async fetchTags(page = null) {
      if (page) {
        this.pagination.page = page;
      }

      this.loading = true;

      const params = {
        params: {
          ss: this.searchString,
          page: this.pagination.page,
          perPage: this.pagination.perPage
        }
      };
      const [error, data] = await getTagsApi(params);

      if (error) {
        console.error(error);
      } else {
        const { tags, total_count } = data;

        this.originTags = tags;
        this.tags = tags.map(tag => {
          const newTag = new Tag();
          newTag.origin = tag;
          newTag.id = tag.id;
          newTag.name = tag.name;
          newTag.category = tag.category;
          newTag.count = tag.count;
          newTag.tagType = tag.tag_type_name;
          newTag.campaignTags = tag.campaign_tags;
          newTag.latestCount = tag.latest_count;
          newTag.createdAt = this.$moment(tag.created_at, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
          );

          return newTag;
        });

        const { perPage } = this.pagination;

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    },
    openEditDialog(tag = null) {
      this.$emit('openEditDialog', tag);
    }
  }
};
</script>

<style></style>
