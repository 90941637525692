<template>
  <v-container fluid class="py-0">
    <v-row class="ma-0">
      <v-col cols="12" lg="5">
        <v-container class="px-5">
          <v-row>
            <v-col cols="12">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                캠페인 이름
              </h3>
              <v-text-field
                :value="didCampaignService.name"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                광고주 계정
              </h3>
              <v-text-field
                :value="didCampaignService.advertiser.name"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                캠페인 등록일
              </h3>
              <v-text-field
                :value="didCampaignService.createdDate"
                outlined
                readonly
                dense
                hide-details
              />
            </v-col>
          </v-row>

          <v-row v-if="didCampaignService.campaignResource" align="center">
            <v-col class="pb-0">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                소재
              </h3>
            </v-col>

            <PreviewResourceDialog
              v-if="didCampaignService.invalidChangeResource"
            />
            <v-col cols="12" class="px-0">
              <v-alert
                border="left"
                class="mx-3 primary--text subtitle-2"
                icon="mdi-information"
                colored-border
                color="info"
                elevation="2"
                dense
              >
                소재 변경시, 변경 적용은 셋톱박스 갱신 시간에 따라 상이하니,
                유의해 주십시오.
              </v-alert>
              <video
                preload="metadata"
                :src="`${didCampaignService.campaignResource.url}#t=0.5`"
                controls
                class="mx-auto col-12"
              />
              <v-col cols="12" class="subtitle-2 font-weight-bold px-3 pt-0">
                - 이름: {{ didCampaignService.campaignResource.name }}<br />
                - 해상도: {{ didCampaignService.campaignResource.width }} X
                {{ didCampaignService.campaignResource.height }}
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-divider vertical />

      <v-col cols="12" lg="7">
        <v-container class="px-5">
          <v-row align="center">
            <v-col cols="auto" class="px-0">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                캠페인 추가
              </h3>
            </v-col>
            <v-col class="pt-0 text-right">
              <v-btn
                color="secondary"
                class="mr-2"
                small
                :disabled="loading.dialog"
                @click="
                  () => {
                    updateDialog = true;
                    updateDialogType = 'settopBox';
                  }
                "
              >
                <v-icon small class="mr-1">mdi-plus</v-icon>
                셋톱박스
              </v-btn>

              <v-btn
                color="secondary"
                :disabled="loading.dialog"
                small
                @click="
                  () => {
                    updateDialog = true;
                    updateDialogType = 'date';
                  }
                "
              >
                <v-icon small class="mr-1">mdi-plus</v-icon>
                일정
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="auto" class="px-0">
              <h3 class="subtitle-2 font-weight-bold">
                <v-icon color="error" x-small>mdi-asterisk</v-icon>
                송출 내역 ( {{ didCampaignService.didCampaigns.length }} 건 )
              </h3>
            </v-col>
            <v-col class="pt-0 text-right">
              <v-btn
                color="secondary"
                small
                :disabled="loading.dialog"
                @click="handleWholeDailyScheduleClick"
              >
                전체 취소
              </v-btn>
              <v-btn
                color="secondary"
                class="ml-2"
                outlined
                small
                :disabled="loading.dialog || !selectedSchedules.length"
                @click="handleDailyScheduleClick"
              >
                선택 취소
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="row ma-0 px-0 pt-0"
              v-if="selectedDateOptions.length > 0"
            >
              <v-col class="pa-0 pr-3 pt-1">
                <v-menu
                  v-model="showDateFilter"
                  :close-on-content-click="false"
                  :return-value.sync="filterDates"
                  transition="scale-transition"
                  ref="tableFiltersDateMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-autocomplete
                      v-on="on"
                      v-model="tableFilters.dates"
                      :items="selectedDateOptions"
                      multiple
                      outlined
                      placeholder="날짜를 선택하세요"
                      no-data-text="조회 가능한 날짜가 없습니다."
                      hide-details
                      class="custom-filter-selectbox"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      readonly
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-if="data.index === 0"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          label
                          style="border: none; padding: 0"
                          @click="() => (showDateFilter = true)"
                        >
                          <v-chip small>
                            {{ data.item }}
                            {{
                              tableFilters.dates.length > 1
                                ? ` 외 ${tableFilters.dates.length - 1} 개`
                                : ''
                            }}
                          </v-chip>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </template>

                  <v-card>
                    <v-toolbar flat dark>
                      <v-toolbar-title>기간</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn icon dark @click="showDateFilter = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <div class="d-flex">
                      <v-date-picker
                        v-model="filterDates"
                        :allowed-dates="
                          val => selectedDateOptions.includes(val)
                        "
                        :min="selectedDateOptions[0]"
                        :max="
                          selectedDateOptions[selectedDateOptions.length - 1]
                        "
                        locale="ko-kr"
                        no-title
                        color="secondary"
                        reactive
                        scrollable
                        multiple
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="secondary"
                          @click="handleFilterDateSelectAll()"
                        >
                          전체 선택
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="handleFilterDateChange()"
                        >
                          적용
                        </v-btn>
                      </v-date-picker>
                    </div>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col class="pa-0 pr-3 pt-1">
                <v-autocomplete
                  v-model="tableFilters.didMediaGroups"
                  :items="selectedMediaGroupOptions"
                  multiple
                  outlined
                  clearable
                  placeholder="매체 그룹을 선택하세요"
                  no-data-text="조회 가능한 매체 그룹이 없습니다."
                  hide-details
                  class="custom-filter-selectbox"
                  prepend-inner-icon="mdi-group"
                  dense
                >
                  <template
                    v-slot:prepend-item
                    v-if="selectedMediaGroupOptions.length > 0"
                  >
                    <v-list-item ripple @click="toggleSelectedDidMediaGroups">
                      전체선택
                    </v-list-item>
                    <v-divider />
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="data.index === 0"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      label
                      style="border: none; padding: 0"
                      @click="data.select"
                    >
                      <v-chip small>
                        {{ data.item }}
                        {{
                          tableFilters.didMediaGroups.length > 1
                            ? ` 외 ${tableFilters.didMediaGroups.length - 1} 개`
                            : ''
                        }}
                      </v-chip>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pa-0 pt-1">
                <v-autocomplete
                  v-model="tableFilters.didBranches"
                  :items="selectedBranchOptions"
                  multiple
                  outlined
                  clearable
                  placeholder="매체를 선택하세요"
                  no-data-text="조회 가능한 매체가 없습니다."
                  hide-details
                  class="custom-filter-selectbox"
                  prepend-inner-icon="mdi-store"
                  dense
                >
                  <template
                    v-slot:prepend-item
                    v-if="selectedBranchOptions.length > 0"
                  >
                    <v-list-item ripple @click="toggleSelectedDidBranches">
                      전체선택
                    </v-list-item>
                    <v-divider />
                  </template>
                  <template v-slot:selection="data">
                    <v-chip
                      v-if="data.index === 0"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      label
                      style="border: none; padding: 0"
                      @click="data.select"
                    >
                      <v-chip small>
                        {{ data.item }}
                        {{
                          tableFilters.didBranches.length > 1
                            ? ` 외 ${tableFilters.didBranches.length - 1} 개`
                            : ''
                        }}
                      </v-chip>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-col>
            <v-col
              cols="12"
              style="min-height: 500px; overflow: auto; border: 1px solid #ddd"
            >
              <a-table
                class="table-striped-rows"
                style="white-space: nowrap"
                size="small"
                rowKey="id"
                :columns="columns"
                bordered
                :data-source="filteredDidCampaigns"
                :scroll="{ x: true }"
                :row-selection="{
                  selectedRowKeys: selectedSchedules,
                  onChange: onSelectChange,
                  getCheckboxProps: getCheckboxProps
                }"
                :locale="{
                  emptyText: '데이터가 없습니다.'
                }"
              >
                <!-- <template slot="status" slot-scope="value, record"> -->

                <template slot="statusInfo" slot-scope="value">
                  <v-chip small outlined label :color="value.color">
                    {{ value.text }}
                  </v-chip>
                </template>

                <template slot="point" slot-scope="value">
                  <div class="text-right">
                    {{ value ? value.toLocaleString() : 0 }}
                    원
                  </div>
                </template>

                <template slot="action" slot-scope="value, record">
                  <v-icon
                    v-if="!record.isStatusCancel"
                    left
                    @click.stop="handleDeleteClick(record.id)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </a-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0" cols="12">
              <v-divider />
            </v-col>
            <!-- <v-col cols="auto">
              <h3 class="pl-3 subtitle-1 font-weight-bold text-right">
                승인 금액
              </h3>
            </v-col> -->
            <v-col>
              <h3 class="title text-right">
                {{
                  didCampaignService.totalPoint
                    ? didCampaignService.totalPoint.toLocaleString()
                    : 0
                }}
                원
              </h3>
            </v-col>
          </v-row>
          <!-- <v-row
            align="center"
            class="mt-0"
            v-if="didCampaignService.canclePoint > 0"
          >
            <v-col class="py-0 text-right black--text" cols="12">
              결제 금액 : {{ didCampaignService.costPoint.toLocaleString() }} 원
            </v-col>
            <v-col class="pt-0 text-right red--text" cols="12">
              취소 금액 :
              {{ didCampaignService.canclePoint.toLocaleString() }} 원
            </v-col>
          </v-row> -->
        </v-container>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-actions class="pb-0">
      <v-spacer></v-spacer>

      <v-btn
        color="secondary"
        @click="closeDialog"
        :loading="loading.dialog"
        outlined
      >
        닫기
      </v-btn>
    </v-card-actions>
    <DidCampaignUpdateDialog
      v-if="updateDialog"
      v-model="updateDialog"
      :type="updateDialogType"
      :defaultSettopBoxIds="defaultSettopBoxIds"
      :defaultBranchIds="defaultBranchIds"
      :defaultDates="selectedDateOptions"
      :loading="loading.dialog"
      @addSettopBox="
        settopBoxIds => addCampaigns(settopBoxIds, selectedDateOptions)
      "
      @addDate="dates => addCampaigns(defaultSettopBoxIds, dates)"
      @closeDialog="
        () => {
          updateDialog = false;
          updateDialogType = '';
        }
      "
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PreviewResourceDialog from '@/components/did/PreviewResourceDialog.vue';
import DidCampaignUpdateDialog from '../dialog/DidCampaignUpdateDialog.vue';
import {
  cancelWholeDailySchedule,
  cancelManyDailySchedule,
  addSubscribeDaily,
  getDidCampaignServiceApi
} from '@/api/admin/DidCampaignAPI';

export default {
  props: ['invalid'],
  components: { PreviewResourceDialog, DidCampaignUpdateDialog },
  computed: {
    ...mapGetters({
      didCampaignService: 'didCampaignService/didCampaignService',
      loading: 'didCampaignService/loading'
    }),
    columns() {
      return [
        {
          title: 'ID',
          align: 'center',
          dataIndex: 'id',
          sorter: (a, b) => new Date(a.id) - new Date(b.id)
        },
        {
          title: '날짜',
          align: 'center',
          dataIndex: 'scheduleDate',
          sorter: (a, b) => new Date(a.scheduleDate) - new Date(b.scheduleDate)
        },
        {
          title: '매체 그룹',
          align: 'center',
          dataIndex: 'didMediaGroupName',
          scopedSlots: {
            customRender: 'didMediaGroupName'
          },
          sorter: (a, b) =>
            a.didMediaGroupName.localeCompare(b.didMediaGroupName)
        },
        {
          title: '매체',
          align: 'center',
          dataIndex: 'didBranchName',
          scopedSlots: {
            customRender: 'didBranchName'
          },
          sorter: (a, b) => a.didBranchName.localeCompare(b.didBranchName)
        },
        {
          title: '셋톱박스',
          align: 'center',
          dataIndex: 'settopBoxName',
          sorter: (a, b) => a.settopBoxName.localeCompare(b.settopBoxName)
        },
        {
          title: '결제금액',
          align: 'center',
          dataIndex: 'point',
          scopedSlots: {
            customRender: 'point'
          },
          sorter: (a, b) => Number(a.point) - Number(b.point)
        },
        {
          title: '상태',
          align: 'center',
          dataIndex: 'statusInfo',
          scopedSlots: {
            customRender: 'statusInfo'
          },
          sorter: (a, b) => a.status.localeCompare(b.status)
        }
      ];
    },
    filteredDidCampaigns() {
      return this.didCampaignService.didCampaigns.filter(
        item =>
          this.tableFilters.dates.includes(item.scheduleDate) &&
          this.tableFilters.didBranches.includes(item.didBranch.name)
      );
    },
    // 상세기간 날짜 목록
    selectedDateOptions() {
      const dates = this.didCampaignService.didCampaigns.map(
        item => item.scheduleDate
      );
      return Array.from(new Set(dates));
    },
    // 상세기간 매체 목록
    selectedBranchOptions() {
      const didBranches = this.didCampaignService.didCampaigns.map(
        item => item.didBranch.name
      );
      return Array.from(new Set(didBranches));
    },
    // 상세기간 매체 그룹 목록
    selectedMediaGroupOptions() {
      const didBranches = this.didCampaignService.didCampaigns.map(
        item => item.didMediaGroupName
      );
      return Array.from(new Set(didBranches));
    },
    //  셋톱박스 ID 목록
    defaultSettopBoxIds() {
      const settopBoxIds = this.didCampaignService.didCampaigns.map(
        item => item.settopBoxId
      );
      return Array.from(new Set(settopBoxIds));
    },
    //  지점 ID
    defaultBranchIds() {
      const didBranches = this.didCampaignService.didCampaigns.map(
        item => item.didBranch.id
      );
      return Array.from(new Set(didBranches));
    }
  },
  data() {
    return {
      today: this.$moment().format('YYYY-MM-DD'),
      tableFilters: {
        dates: [],
        didBranches: [],
        didMediaGroups: []
      },
      reviewDialog: false,
      selectedSchedules: [],
      showDateFilter: false,
      filterDates: [], // 필터 날짜 temp value
      updateDialog: false,
      updateDialogType: ''
    };
  },
  created() {
    this.tableFilters.dates = this.filterDates = this.selectedDateOptions;
    this.tableFilters.didBranches = this.selectedBranchOptions;
    this.tableFilters.didMediaGroups = this.selectedMediaGroupOptions;
  },
  methods: {
    ...mapActions({
      closeDialog: 'didCampaignService/closeDialog',
      setDidCampaignService: 'didCampaignService/setDidCampaignService'
    }),
    //선택 초기화
    initSelect() {
      this.selectedSchedules = [];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedSchedules = selectedRowKeys;
    },
    // 날짜 전체 선택
    toggleSelectedDates() {
      this.$nextTick(() => {
        if (
          this.tableFilters.dates.length === this.selectedDateOptions.length
        ) {
          this.tableFilters.dates = [];
        } else {
          this.tableFilters.dates = [...this.selectedDateOptions];
        }
      });
    },
    // 매체 전체 선택
    toggleSelectedDidBranches() {
      this.$nextTick(() => {
        if (
          this.tableFilters.didBranches.length ===
          this.selectedBranchOptions.length
        ) {
          this.tableFilters.didBranches = [];
        } else {
          this.tableFilters.didBranches = [...this.selectedBranchOptions];
        }
      });
    },
    toggleSelectedDidMediaGroups() {
      this.$nextTick(() => {
        if (
          this.tableFilters.didMediaGroups.length ===
          this.selectedMediaGroupOptions.length
        ) {
          this.tableFilters.didMediaGroups = [];
        } else {
          this.tableFilters.didMediaGroups = [
            ...this.selectedMediaGroupOptions
          ];
        }
      });
    },
    //선택가능한 체크박스인지 확인
    getCheckboxProps(record) {
      // TODO :: 취소 정책 필요함
      // 취소상태인 체크박스 || 이미 지난 날 ()
      const diabled = record.status === 'C' || record.scheduleDate < this.today;
      return {
        props: {
          disabled: diabled
        }
      };
    },
    /** @description: 일정 전체 취소 */
    async handleWholeDailyScheduleClick() {
      this.$Swal
        .fire({
          icon: 'info',
          html: `<h3> 남은 모든 일정을 취소하시겠습니까? </h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.loading.dialog = true;

            const [error] = await cancelWholeDailySchedule(
              this.didCampaignService.id
            );

            this.loading.dialog = false;
            if (error) {
              console.error(error);
              this.$axiosErrorAlert();
            } else {
              this.$emit('refresh');
              this.$Swal
                .fire({
                  icon: 'success',
                  html: '일정 취소 완료 되었습니다.',
                  confirmButtonText: '확인'
                })
                .then(() => this.closeDialog());
            }
          }
        });
    },
    /** @description: 일정 부분 취소 */
    async handleDailyScheduleClick() {
      if (!this.selectedSchedules.length) {
        this.$Swal.fire({
          icon: 'info',
          html: '취소할 일정을 선택해 주세요.',
          confirmButtonText: '확인'
        });
        return;
      }

      this.$Swal
        .fire({
          icon: 'info',
          html: `<h3> 선택한 일정을 취소하시겠습니까? </h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.loading.dialog = true;
            const [error] = await cancelManyDailySchedule(
              this.didCampaignService.id,
              {
                scheduleIds: this.selectedSchedules
              }
            );

            this.loading.dialog = false;
            if (error) {
              console.error(error);
              this.$axiosErrorAlert();
            } else {
              this.$emit('refresh');
              this.$Swal
                .fire({
                  icon: 'success',
                  html: '일정 취소 완료 되었습니다.',
                  confirmButtonText: '확인'
                })
                .then(() => this.closeDialog());
            }
          }
        });
    },
    /** @description: 필터 일정 전체 선택 & 취소 */
    handleFilterDateSelectAll() {
      this.filterDates =
        this.filterDates.length === this.selectedDateOptions.length
          ? []
          : [...this.selectedDateOptions];
    },
    /** @description: 필터 일정 적용 */
    handleFilterDateChange() {
      this.$refs.tableFiltersDateMenu.save(this.filterDates);
      this.tableFilters.dates = [...this.filterDates];
    },
    async addCampaigns(settopBoxIds = [], dates = []) {
      if (this.loading.dialog) return;

      if (!settopBoxIds.length || !dates.length) {
        this.$Swal.fire({
          icon: 'error',
          html: '추가 가능한 셋톱박스 또는 날자가 없습니다.',
          confirmButtonText: '확인'
        });
        return;
      }

      const newCampaigns = [];
      let addDateOptions = dates.filter(
        date => date >= this.$moment().format('YYYY-MM-DD')
      );

      addDateOptions.map(item => {
        settopBoxIds.map(settopBox => {
          newCampaigns.push({
            scheduleDate: item,
            settopBoxId: settopBox
          });
        });
      });

      this.$Swal
        .fire({
          icon: 'info',
          html: `<h3> 선택한 셋톱박스를 추가하시겠습니까?
                  <br/>
                  셋톱 박스: ${settopBoxIds.length} 건  ${addDateOptions.length} 일씩 추가
                </h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.loading.dialog = true;
            const [error, data] = await addSubscribeDaily(
              this.didCampaignService.id,
              {
                didCampaigns: newCampaigns
              }
            );

            this.loading.dialog = false;
            if (error) {
              console.error(error);
              this.$axiosErrorAlert();
            } else {
              const { failed_list } = data;
              let msg = '캠페인이 추가 되었습니다.';
              if (failed_list.length > 0) {
                msg = '';
                failed_list.forEach(el => (msg += `<li>${el}</li>`));
                msg = `
                  ${failed_list.length}건 외 캠페인이 추가 되었습니다.
                  <ul style="
                    max-height: 100px; 
                    overflow:auto; 
                    border: 1px solid #333; 
                    font-size: 12px;
                    padding: 5px; 
                    margin: 10px auto;">
                    ${msg}
                  </ul>
                `;
              }
              this.$emit('refresh');
              await this.fetchDidCampaignService(this.didCampaignService.id);
              this.$Swal
                .fire({
                  icon: 'success',
                  html: msg,
                  confirmButtonText: '확인'
                })
                .then(() => (this.updateDialog = false));
            }
          }
        });
    },

    /**@description: DID 캠페인 상세 조회 */
    async fetchDidCampaignService(id) {
      if (!id || this.loading.dialog) return;
      this.loading.dialog = true;

      const [error, data] = await getDidCampaignServiceApi(id);
      if (error) {
        console.error(error);
        this.$axiosErrorAlert(error?.data?.message);
      } else {
        const { did_campaign_service } = data;
        this.setDidCampaignService(did_campaign_service);
      }

      this.loading.dialog = false;
    }
  }
};
</script>

<style lang="scss"></style>
