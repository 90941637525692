<template>
  <div>
    <div :style="{ marginBottom: '1rem', textAlign: 'right' }">
      <a-button @click="fetchPoint" class="mr-3">포인트 새로고침</a-button>
      <a-button @click="freePointDialog = true" type="primary">
        무상포인트 지급
      </a-button>

      <v-dialog
        title="무상포인트 지급"
        v-model="freePointDialog"
        :confirm-loading="loading"
        @cancel="close"
        :width="650"
        @click:outside="close"
        @keydown.esc="close"
      >
        <v-card>
          <v-toolbar flat dark>
            <v-toolbar-title>무상포인트 지급</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text class="mt-10">
            <a-alert
              message="즉시 지급을 제외한 무상 지급된 포인트는 광고주가 활성화 후 사용 가능한 포인트로 전환 됩니다."
              type="info"
              show-icon
              class="mb-5"
            />

            <a-descriptions bordered>
              <a-descriptions-item label="즉시 지급 여부" :span="3">
                <a-switch v-model="isPayNow" />
              </a-descriptions-item>

              <a-descriptions-item label="무상 지급할 포인트" :span="3">
                <a-input
                  addon-before=""
                  :style="{ textAlign: 'right' }"
                  v-model="freePointInput"
                />
              </a-descriptions-item>
            </a-descriptions>

            <v-row>
              <v-col cols="12" class="text-center mt-5 mb-2">
                <v-btn
                  color="#00d1b2"
                  class="white--text"
                  depressed
                  @click="createFreePointOrder"
                >
                  지급
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <a-spin :spinning="loading">
      <a-descriptions
        bordered
        layout="vertical"
        :style="{ textAlign: 'center' }"
      >
        <a-descriptions-item label="전체 포인트" :span="3">
          <div :style="{ textAlign: 'center', fontSize: '20px' }">
            {{ Number(totalPoint).toLocaleString() }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item label="유상 포인트">
          <div :style="{ textAlign: 'center', fontSize: '20px' }">
            {{ Number(point.credit).toLocaleString() }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item label="무상 포인트">
          <div :style="{ textAlign: 'center', fontSize: '20px' }">
            {{ Number(point.free).toLocaleString() }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item label="비활성 무상 포인트">
          <div :style="{ textAlign: 'center', fontSize: '20px' }">
            {{ Number(point.inactiveFree).toLocaleString() }}
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>

    <a-descriptions :style="{ marginTop: '3rem' }">
      <div slot="title">
        <span>포인트내역</span>
      </div>
    </a-descriptions>

    <a-tabs type="card">
      <a-tab-pane key="campaignDeposit" tab="포인트 내역">
        <CampaignDepositTable />
      </a-tab-pane>
      <a-tab-pane key="campaignCost" tab="보증금 내역">
        <CampaignCostTable />
      </a-tab-pane>
      <a-tab-pane key="free" tab="무상지급 내역">
        <PointFreeChargeTable />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CampaignDepositTable from './AdvertiserCampaignDepositTable';
import CampaignCostTable from './AdvertiserCampaignCostTable';
import PointFreeChargeTable from './AdvertiserPointFreeChargeTable';
import { getPointApi, createFreePointOrderApi } from '@/api/admin/UserAPI';
export default {
  props: {
    type: String,
    tab: String
  },
  components: {
    CampaignDepositTable,
    CampaignCostTable,
    PointFreeChargeTable
  },
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    }),
    totalPoint() {
      return Number(this.point.credit) + Number(this.point.free);
    }
  },
  data() {
    return {
      loading: false,
      freePointDialog: false,
      point: {
        credit: 0,
        free: 0,
        inactiveFree: 0
      },
      isPayNow: false,
      freePointInput: 0
    };
  },
  watch: {
    advertiser() {
      this.fetchPoint();
    }
  },
  mounted() {
    this.fetchPoint();
  },
  methods: {
    async fetchPoint() {
      this.loading = true;

      const userId = this.advertiser.origin.user.id;

      const [error, data] = await getPointApi(userId);
      if (error) {
        console.error(error);
      } else {
        // console.log(data);
        const { credit_point, free_point, inactive_free_point } = data;
        this.point = {
          credit: credit_point,
          free: free_point,
          inactiveFree: inactive_free_point
        };
      }

      this.loading = false;
    },
    async createFreePointOrder() {
      if (this.freePointInput <= 0) {
        return;
      }

      const params = {
        point: this.freePointInput,
        isPayNow: this.isPayNow
      };

      const userId = this.advertiser.origin.user.id;

      this.loading = true;

      const [error] = await createFreePointOrderApi(params, userId);

      if (error) {
        console.error(error);
      } else {
        this.fetchPoint();
        this.close();
      }

      this.loading = false;
    },
    close() {
      this.freePointInput = 0;
      this.isPayNow = false;
      this.freePointDialog = false;
    }
  }
};
</script>
