<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <MenuTitle />
      </v-col>
    </v-row>
    <v-row v-show="!loading">
      <v-col cols="12" class="d-inline-flex justify-end">
        <InquiryDialog
          v-if="inquiries.length > 0"
          @getInquiryApi="getInquiryApi()"
        />
      </v-col>
      <v-col cols="12">
        <v-expansion-panels
          v-model="panel"
          focusable
          v-if="inquiries.length > 0"
        >
          <v-expansion-panel v-for="(inquiry, index) in inquiries" :key="index">
            <v-expansion-panel-header disable-icon-rotate>
              <div>
                <h3>
                  {{ inquiry.created_at.substr(0, 10).replaceAll('-', '.') }}
                </h3>
                <div class="text-truncate" style="max-width: 600px;">
                  {{ inquiry.content }}
                </div>
              </div>

              <template v-slot:actions>
                <div
                  v-if="inquiry.replied_at !== null"
                  class="d-inline-flex align-center"
                  style="white-space:nowrap"
                >
                  <v-icon color="success">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <span class="ml-2">답변완료</span>
                </div>
                <div
                  v-if="inquiry.replied_at === null"
                  class="d-inline-flex align-center"
                  style="white-space:nowrap"
                >
                  <v-icon color="secondary">
                    mdi-circle-slice-6
                  </v-icon>
                  <span class="ml-2">답변대기</span>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="py-3">
              <h3>문의 내용</h3>
              <v-textarea
                class="mb-5"
                readonly
                :value="inquiry.content"
                auto-grow
                solo
                flat
                hide-details
                outlined
              >
              </v-textarea>
              <v-card-actions v-if="inquiry.replied_at === null">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="cancelInquiryApi(inquiry.id)"
                >
                  취소하기
                </v-btn>
              </v-card-actions>
              <v-card v-else flat style="outline: 2px dashed #92b0b3">
                <v-card-title class="subtitle-1"
                  >NOTI-i 에서 문의에 대한 답변드립니다🤗
                  <small class="ml-2">{{
                    inquiry.replied_at.substr(0, 16).replaceAll('-', '.')
                  }}</small></v-card-title
                >
                <v-card-text>
                  <v-textarea
                    :value="inquiry.reply"
                    readonly
                    solo
                    flat
                    auto-grow
                  >
                  </v-textarea>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else>
          <h2 class="text-center">
            무엇을 도와드릴까요? <br /><InquiryDialog
              @getInquiryApi="getInquiryApi()"
            />를 눌러 무엇이든 물어보세요 🤗
          </h2>
        </div>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-if="pagination.totalPage > 1"
          v-model="pagination.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
          @input="getInquiryApi()"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-show="loading"
      type="article, article, article, article, article"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
import MenuTitle from '@/components/MenuTitle.vue';
import InquiryDialog from './components/InquiryDialog.vue';
import { getInquiriesApi } from '@/api/advertiser/UserAPI';

export default {
  components: {
    MenuTitle,
    InquiryDialog
  },
  data() {
    return {
      columns: [],
      data: [],
      panel: [],
      inquiries: [],
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      loading: true
    };
  },
  async mounted() {
    await this.fetchInquiries();

    this.panel = parseInt(this.$route.query.panel);
  },
  methods: {
    async fetchInquiries(page = this.pagination.page) {
      this.loading = true;
      const params = {
        params: {
          page: page,
          perPage: this.pagination.perPage
        }
      };

      const [error, data] = await getInquiriesApi(params);

      if (error) {
        console.error(error);
        return [];
      } else {
        const { inquiries, total_count } = data;
        this.inquiries = inquiries;

        const { perPage } = this.pagination;
        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    },
    async cancelInquiryApi(id) {
      this.$Swal
        .fire({
          html: `<h3>해당 문의를 취소 하시겠습니까?</h3>`,
          confirmButtonText: '확인',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          icon: 'error',
          iconHtml: '!',
          preConfirm: async () => {
            //수정버튼 동작
            await this.$axios
              .delete('/c/u/inquiry', {
                data: {
                  inquiryId: id
                }
              })
              .then(res => {
                if (res.data.error) {
                  throw new Error(res.statusText);
                }
                return res.data;
              })
              .catch(err => {
                console.dir(err);
                this.$Swal.showValidationMessage(`요청 실패`);
              });
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            const index = this.inquiries.map(v => v.id).indexOf(id);
            this.inquiries.splice(index, 1);
            this.panel = [];
          }
        });
    }
  }
};
</script>

<style></style>
