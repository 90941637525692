<template>
  <validation-observer ref="reportFilters">
    <v-card class="pa-5 my-5">
      <v-row align="center">
        <v-col cols="12" lg="3">
          <div class="d-flex align-center">
            <span
              style="min-width: 80px; white-space: nowrap"
              class="text-right mr-2"
            >
              네트워크
            </span>
            <validation-provider style="width: 100%" v-slot="{ errors }">
              <v-autocomplete
                v-model="networkIds"
                ref="didBrachIdRef"
                outlined
                hide-no-data
                hide-details
                full-width
                multiple
                clearable
                placeholder="네트워크를 선택하세요."
                :items="networks"
                item-text="name"
                dense
                item-value="id"
                :disabled="loading"
                :error-messages="errors"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span v-if="networkIds.length === networks.length"
                      >전체선택</span
                    >
                    <span v-else
                      >{{ item.name }}
                      {{
                        networkIds.length > 1
                          ? ` 외 ${networkIds.length - 1} 개`
                          : ''
                      }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleMedia">
                    <v-list-item-action>
                      <v-icon :color="networkIds.length > 0 ? 'primary' : ''">
                        {{ networkIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> 전체선택 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="ml-auto text-lg-right">
          <div class="d-inline-flex align-center">
            <date-picker
              ref="datePicker"
              class="ml-auto"
              title="기간"
              :prefix="3"
              @setDateRanges="
                v => {
                  dateRanges = v;
                  emitFilters();
                }
              "
            ></date-picker>
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn
                  v-on="{ ...onTooltip }"
                  icon
                  @click="emitFilters"
                  :disabled="loading"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span class="title">새로고침</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DatePicker from '@/components/DatePicker.vue';
import { getNetworksApi } from '@/api/admin/partners/NetworkAPI';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    DatePicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      networkIds: [],
      networks: [],
      dateRanges: []
    };
  },
  computed: {
    allMedia() {
      return this.networkIds.length === this.filteredMedias.length;
    },
    someMedia() {
      return this.networkIds.length > 0 && !this.allMedia;
    },
    filteredMedias() {
      return this.$refs.didBrachIdRef?.filteredItems || this.networks;
    },
    networkIcon() {
      if (this.allMedia) return 'mdi-close-box';
      if (this.someMedia) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    networkIds(newVal) {
      if (newVal) {
        this.emitFilters();
      }
    }
  },
  created() {
    this.fetchMedias();
  },
  methods: {
    async fetchMedias() {
      const [error, data] = await getNetworksApi();
      if (error) {
        console.error(error);
      } else {
        this.networks = data.networks;
      }
    },
    toggleMedia() {
      this.$nextTick(() => {
        if (this.allMedia) {
          this.networkIds = [];
        } else {
          this.networkIds = this.filteredMedias.slice().map(v => v.id);
        }
      });
    },
    emitFilters() {
      const filters = {
        networkIds: this.networkIds,
        dateRanges: this.dateRanges
      };
      this.$emit('applyFilters', filters);
    }
  }
};
</script>

<style></style>
