<template>
  <div class="filters mt-5 mb-8">
    <a-descriptions bordered>
      <a-descriptions-item label="상태">
        <a-select :style="{ minWidth: '150px' }" v-model="filters.status">
          <a-select-option
            v-for="(item, index) in UserApproveStatusList"
            :key="index"
            :value="item.value"
          >
            <a-tag :color="item.color"> {{ item.text }} </a-tag>
          </a-select-option>
        </a-select>
      </a-descriptions-item>
      <a-descriptions-item label="이메일">
        <a-input v-model="filters.email" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <a-descriptions-item label="ID">
        <a-input v-model="filters.uid" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <a-descriptions-item label="광고주명">
        <a-input v-model="filters.name" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <a-descriptions-item label="핸드폰">
        <a-input v-model="filters.phone" @pressEnter="$emit('search')" />
      </a-descriptions-item>

      <!-- <a-descriptions-item label="가입일">
        <a-range-picker
          v-model="filters.dates"
          :ranges="{
            오늘: [$moment(), $moment()],
          }"
          format="YYYY-MM-DD"
        />
      </a-descriptions-item> -->
    </a-descriptions>

    <div class="text-center mt-3">
      <v-btn
        color="#00d1b2"
        class="white--text"
        depressed
        :loading="loading"
        :disabled="loading"
        @click="$emit('search')"
      >
        검색
      </v-btn>
    </div>
  </div>
</template>
<script>
import { UserApproveStatusList } from '@/config/user';

export default {
  props: {
    filters: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      UserApproveStatusList: UserApproveStatusList
    };
  },
  computed: {},
  watch: {},
  methods: {}
};
</script>
