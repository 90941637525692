var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":"","persistent":"","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titleText))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleCloseClick}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5 py-3"},[_c('ValidationObserver',{ref:_vm.refKey},[(!_vm.campaignResource.id)?_c('v-row',{attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"회원 타입","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-btn-toggle',{attrs:{"color":"#00d1b2","mandatory":""},on:{"change":function($event){_vm.campaignResource.userId = ''}},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[_c('v-btn',{attrs:{"value":"media","height":"30px"}},[_vm._v(" DID 매체 그룹")]),_c('v-btn',{attrs:{"value":"advertiser","height":"30px"}},[_vm._v(" 광고주 ")])],1),(errors.length > 0)?_c('div',[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,13378705)})]},proxy:true}],null,false,1359344937)}),_c('CustomGridItem',{attrs:{"title":"이름","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"소재 이름 (선택)","error-messages":errors,"hide-details":!errors.length,"outlined":"","dense":""},model:{value:(_vm.campaignResource.name),callback:function ($$v) {_vm.$set(_vm.campaignResource, "name", $$v)},expression:"campaignResource.name"}})]}}],null,false,1076162913)})]},proxy:true}],null,false,2900314779)}),_c('CustomGridItem',{attrs:{"title":"회원 계정","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":!errors.length,"items":_vm.userType === 'advertiser' ? _vm.advertisers : _vm.mediaUsers,"item-text":"name","item-value":"id","placeholder":"회원 계정 선택","required":"","outlined":"","dense":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.campaignResource.userId),callback:function ($$v) {_vm.$set(_vm.campaignResource, "userId", $$v)},expression:"campaignResource.userId"}})]}}],null,false,268709724)})]},proxy:true}],null,false,2345665403)}),_c('CustomGridItem',{attrs:{"title":"캠페인 타입","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":!errors.length,"items":_vm.CampaignResourceTypeList,"placeholder":"캠페인 타입 선택","required":"","outlined":"","dense":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.campaignResource.campaignType),callback:function ($$v) {_vm.$set(_vm.campaignResource, "campaignType", $$v)},expression:"campaignResource.campaignType"}})]}}],null,false,80170072)})]},proxy:true}],null,false,431411847)}),_c('CustomGridItem',{attrs:{"title":"소재 타입","outlined":"","md":12},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"hide-details":!errors.length,"items":_vm.CampaignResourceDataTypeList,"placeholder":"소재 타입 선택","required":"","outlined":"","dense":"","menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.campaignResource.dataType),callback:function ($$v) {_vm.$set(_vm.campaignResource, "dataType", $$v)},expression:"campaignResource.dataType"}})]}}],null,false,1674657030)})]},proxy:true}],null,false,3096325521)}),_c('CustomGridItem',{attrs:{"title":"소재","outlined":"","md":12,"titleClass":"col-12  py-3"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(
                  _vm.campaignResource.campaignType && _vm.campaignResource.dataType
                )?_c('UploadResource',{attrs:{"fileType":_vm.campaignResource.dataType,"campaignType":_vm.campaignResource.campaignType,"maxCount":1},on:{"addImage":function (v) {
                    _vm.campaignResource.file = v;
                  }}}):_c('v-row',{staticClass:"subtitle-1 font-weight-bold ma-0 col",attrs:{"justify":"center","align":"center"}},[_vm._v(" 캠페인 타입과, 소재 타입을 선택해 주세요. ")])]},proxy:true}],null,false,3186861703)})],1):_vm._e(),(_vm.campaignResource.id)?_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center","align":"center"}},[(_vm.campaignResource.isExpired || _vm.campaignResource.isExtendable)?_c('v-col',{staticClass:"pt-0",style:(("color: " + (_vm.campaignResource.isExpired
                ? 'red'
                : _vm.campaignResource.isExtendable
                ? '#FF6D00'
                : ''))),attrs:{"cols":12}},[_c('v-icon',{attrs:{"color":_vm.campaignResource.isExpired
                  ? 'red'
                  : _vm.campaignResource.isExtendable
                  ? '#FF6D00'
                  : ''}},[_vm._v(" mdi-information-outline ")]),_vm._v(" "+_vm._s(_vm.campaignResource.isExpired ? '만료된 소재입니다.' : _vm.campaignResource.isExtendable ? '소재 만료일이 얼마남지 않았습니다. 만료일을 연장해 주세요.' : '')+" ")],1):_vm._e(),(_vm.campaignResource.dataType === 'video')?_c('video',{staticClass:"col-12",attrs:{"preload":"metadata","src":((_vm.campaignResource.url) + "#t=0.5"),"controls":""}}):_c('img',{staticClass:"col-12",attrs:{"src":_vm.campaignResource.url}}),_c('CustomGridItem',{attrs:{"title":"재생 시간(ms)","outlined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  min: 0
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"파일 사이즈","outlined":"","color":"green","type":"number","error-messages":errors,"hide-details":!errors.length,"dense":""},model:{value:(_vm.playtime),callback:function ($$v) {_vm.playtime=$$v},expression:"playtime"}})]}}],null,false,2938715368)})]},proxy:true}],null,false,298689844)}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"secondary":"","outlined":""},on:{"click":_vm.handleCloseClick}},[_vm._v(" 닫기 ")]),(!_vm.campaignResource.id)?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary","loading":_vm.dialogLoading,"disabled":_vm.dialogLoading ||
          (_vm.campaignResource.id ? !this.campaignResource.isChanged() : false)},on:{"click":_vm.handleSaveClick}},[_vm._v(" 등록 ")]):[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"error"},on:{"click":_vm.handleDeleteClick}},[_vm._v(" 삭제 ")]),(!_vm.campaignResource.isExpired && _vm.campaignResource.isExtendable)?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"secondary"},on:{"click":_vm.handleValidClick}},[_vm._v(" 연장 ")]):_vm._e(),(("" + (_vm.campaignResource.filePlaytime)) !== ("" + _vm.playtime))?_c('v-btn',{staticClass:"white--text",attrs:{"color":"#00d1b2","depressed":""},on:{"click":_vm.handleChangePlaytimeClick}},[_vm._v(" 변경 ")]):_vm._e()]],2)],1),_c('v-overlay',{attrs:{"value":_vm.dialogLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }