<template>
  <v-dialog v-model="dialog" max-width="700" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>매체 그룹 {{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="handleCloseClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="py-5">
        <ValidationObserver :ref="refKey">
          <v-row dense class="align-end ma-0">
            <h4 class="subtitle-1 font-weight-bold">계정 정보</h4>
            <v-col class="text-right ml-auto" cols="auto" sm="12" md="auto">
              <v-btn
                color="secondary"
                outlined
                v-if="this.didMediaGroup.id"
                :loading="dialogLoading"
                :style="{ marginLeft: '10px' }"
                @click="editPasswordDialog = true"
              >
                비밀번호 변경
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="mb-5">
            <CustomGridItem title="계정 활성 여부" outlined :md="12" dense>
              <template v-slot:content>
                <ValidationProvider
                  v-if="!didMediaGroup.id"
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="didMediaGroup.user.approveStatusFlag"
                    :items="userApproveList"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="계정 활성 여부 선텍"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        small
                        outlined
                        label
                        secondary
                        class="ml-1 mr-0 font-weight-bold"
                        :color="item.color"
                      >
                        {{ item.text }}
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
                <v-col v-else class="py-2">
                  <v-chip
                    small
                    outlined
                    label
                    secondary
                    class="ml-1 mr-0 font-weight-bold"
                    :color="didMediaGroup.userApproveStatusInfo.color"
                  >
                    {{ didMediaGroup.userApproveStatusInfo.text }}
                  </v-chip>
                </v-col>
              </template>
            </CustomGridItem>

            <CustomGridItem title="ID" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="didMediaGroup.user.uid"
                    required
                    :readText="!!didMediaGroup.id"
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="로그인 ID를 입력해 주세요."
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem
              title="비밀번호"
              outlined
              :md="12"
              v-if="!didMediaGroup.id"
            >
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="didMediaGroup.user.password"
                    required
                    type="password"
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="로그인 비밀번호를 입력해 주세요."
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="이메일" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="didMediaGroup.user.email"
                    required
                    type="email"
                    :readText="!!didMediaGroup.id"
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="이메일를 입력해 주세요."
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="연락처" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="didMediaGroup.user.phone"
                    required
                    type="phone"
                    :readText="!!didMediaGroup.id"
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="연락처를 입력해 주세요."
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>

          <h4 class="subtitle-1 font-weight-bold">
            매체 그룹 정보
            <!-- <span class="info--text text-caption ml-2">
              매체 그룹에 내려보낼 앱 설정값입니다.
            </span> -->
          </h4>
          <v-row dense class="mb-5">
            <CustomGridItem title="광고 송출 상태" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-if="!didMediaGroup.id"
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="didMediaGroup.isActive"
                    :items="ActiveOptions"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="광고 송출 상태 선텍"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        small
                        outlined
                        label
                        secondary
                        class="ml-1 mr-0 font-weight-bold"
                        :color="item.color"
                      >
                        {{ item.text }}
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
                <v-col v-else class="py-2">
                  <v-chip
                    small
                    outlined
                    label
                    secondary
                    class="ml-1 mr-0 font-weight-bold"
                    :color="didMediaGroup.isActiveInfo.color"
                  >
                    {{ didMediaGroup.isActiveInfo.text }}
                  </v-chip>
                </v-col>
              </template>
            </CustomGridItem>

            <CustomGridItem title="매체 그룹명" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <CustomInput
                    v-model="didMediaGroup.name"
                    required
                    :errorMessages="errors"
                    :hideDetails="!errors.length"
                    placeholder="매체 그룹명을 입력해 주세요."
                    focusable
                  />
                </ValidationProvider>
              </template>
            </CustomGridItem>

            <CustomGridItem title="매체 유형" outlined :md="12">
              <template v-slot:content>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="didMediaGroup.type"
                    :items="MediaGroupTypeList"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    item-text="text"
                    item-value="value"
                    item-disabled="disable"
                    outlined
                    dense
                    placeholder="매체 유형 선텍"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        small
                        outlined
                        label
                        secondary
                        class="ml-1 mr-0 font-weight-bold"
                        :color="item.color"
                      >
                        {{ item.text }}
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
              </template>
            </CustomGridItem>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-center">
        <v-btn secondary outlined @click="handleCloseClick"> 닫기 </v-btn>
        <v-btn
          color="secondary"
          @click="handleSaveClick()"
          :loading="dialogLoading"
          :disabled="
            dialogLoading ||
            (didMediaGroup.id ? !didMediaGroup.isChanged() : false)
          "
        >
          {{ titleText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="dialogLoading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <ChangePasswordDialog
      v-if="editPasswordDialog"
      :originPassword="didMediaGroup.user.password"
      :dialog="editPasswordDialog"
      @changePassword="handleChangePassword"
      @close="editPasswordDialog = false"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CustomGridItem from '@/components/CustomGridItem.vue';
import { MediaGroupTypeList } from '@/config/media';
import { ActiveOptions } from '@/config/admin/campaign-did';
import {
  createDidMediaGroupApi,
  updateDidMediaGroupApi
} from '@/api/admin/DidMediaGroupAPI';
import { changePasswordAPI } from '@/api/admin/UserAPI';
import CustomInput from '@/components/common/CustomInput.vue';
import ChangePasswordDialog from '@/components/common/ChangePasswordDialog.vue';
// import ChangePasswordDialog from './dialog/AdvertiserChangePasswordDialog.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomGridItem,
    CustomInput,
    ChangePasswordDialog
  },
  data() {
    return {
      editPasswordDialog: false,
      userApproveList: [
        {
          text: '활성',
          value: 'Y',
          color: 'green'
        },
        {
          text: '비활성',
          value: 'W',
          color: 'red'
        }
      ],
      ActiveOptions: ActiveOptions.filter(item => item.value !== ''),
      MediaGroupTypeList: MediaGroupTypeList.filter(item => item.value !== ''),
      refKey: 'didMediaGroupDialog'
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'didMediaGroup/dialog',
      loading: 'didMediaGroup/loading',
      didMediaGroup: 'didMediaGroup/didMediaGroup'
    }),
    dialogLoading() {
      return this.loading.dialog;
    },
    titleText() {
      return !this.didMediaGroup.id ? '등록' : '수정';
    }
  },
  watch: {
    dialog() {
      this.loading.dialog = false;

      if (this.$refs[this.refKey]) {
        this.$refs[this.refKey].reset();
      }
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'didMediaGroup/closeDialog'
    }),
    /** @description: 매체 그룹 저장 */
    async handleSaveClick() {
      if (this.dialogLoading) return;

      const valid = await this.$refs[this.refKey].validate();

      if (!valid) return;

      if (!this.didMediaGroup.isChanged()) {
        this.$Swal.fire({
          icon: 'info',
          title: `매체 그룹 ${this.titleText} `,
          html: '<h3>변경 사항이 없습니다.</h3>',
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      this.loading.dialog = true;

      const saveData = this.didMediaGroup.id
        ? this.didMediaGroup.getUpdatePayload()
        : this.didMediaGroup.getCreatePayload();

      const [error] = this.didMediaGroup.id
        ? await updateDidMediaGroupApi(this.didMediaGroup.id, saveData)
        : await createDidMediaGroupApi(saveData);

      if (error) {
        console.log(error);
        const { data } = error;
        this.$Swal.fire({
          icon: 'error',
          title: `매체 그룹 ${this.titleText} 요청 실패`,
          html: `${data?.message ?? '<h3>잠시 후 다시 시도해주세요.</h3>'}`,
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
      } else {
        this.$emit('refresh');

        this.$Swal
          .fire({
            icon: 'success',
            title: `매체 그룹 ${this.titleText} `,
            html: `<h3>매체 그룹이 ${this.titleText}되었습니다.</h3>`,
            showConfirmButton: true,
            confirmButtonText: '확인'
          })
          .then(() => this.closeDialog());
      }

      this.loading.dialog = false;
    },
    /** @description: 비밀번호 변경 */
    async handleChangePassword(params) {
      if (!this.didMediaGroup.user.id) {
        this.$Swal.fire({
          icon: 'error',
          title: `매체 그룹의 정보 변경 요청 실패`,
          html: `${'<h3>매체 그룹의 정보가 올바르지 않습니다.<br/>잠시 후 다시 시도해주세요.</h3>'}`,
          showConfirmButton: true,
          confirmButtonText: '확인'
        });
        return;
      }

      const [error] = await changePasswordAPI(
        this.didMediaGroup.user.id,
        params
      );
      let result = 'error';
      let message = '변경 실패';
      let description = '비밀번호 변경에 실패했습니다.';

      if (error) {
        console.error(error);
      } else {
        result = 'success';
        message = '변경 성공';
        description = '비밀번호가 변경되었습니다.';

        this.editPasswordDialog = false;
      }
      // 알림 노티
      this.$notification[result]({
        message: message,
        description: description
      });
    },
    /** @description: 닫기 */
    handleCloseClick() {
      // 변경사항 있는경우 확인
      if (this.didMediaGroup.isChanged()) {
        this.$Swal
          .fire({
            icon: 'info',
            title: `매체 그룹 ${this.titleText} `,
            html: '<h3>변경 사항이 있습니다.<br/> 저장하지 않고 닫으시겠습니까?</h3>',
            showConfirmButton: true,
            confirmButtonText: '확인',
            showCancelButton: true,
            cancelButtonText: '취소'
          })
          .then(result => {
            if (result.isConfirmed) {
              this.closeDialog();
            }
          });
      } else {
        this.closeDialog();
      }
    }
  }
};
</script>
