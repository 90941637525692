<template>
  <v-row class="px-5 ma-0 mt-5">
    <v-col :cols="12" md="6">
      <h3 class="subtitle-1 font-weight-bold">
        <v-icon color="error" x-small>mdi-asterisk</v-icon>
        소재 선택
      </h3>

      <v-card outlined elevation="0">
        <v-card-text class="preview-resource-box">
          <template v-if="campaignResources.length > 0">
            <v-row dense>
              <v-col
                v-for="(resource, index) in campaignResources"
                :key="index"
                cols="12"
                sm="12"
                :md="resource.isDataTypeIcon ? 4 : 6"
              >
                <v-card class="preview-resource-card">
                  <v-img
                    v-if="resource.isDataTypeImage || resource.isDataTypeIcon"
                    :src="resource.url"
                    class="mx-auto white--text"
                    contain
                    @click="selectedResource(resource)"
                  />

                  <template v-if="resource.isDataTypeVideo">
                    <v-img
                      v-if="resource.thumbnailUrl"
                      :src="
                        resource.thumbnailUrl
                          ? resource.thumbnailUrl
                          : resource.url
                      "
                      class="mx-auto white--text col-12 pa-1"
                      @click="selectedResource(resource)"
                    />
                    <video
                      v-if="!resource.thumbnailUrl"
                      preload="metadata"
                      :src="`${resource.url}#t=0.5`"
                      class="mx-auto white--text col-12 pa-1"
                      @click="selectedResource(resource)"
                    />
                  </template>

                  <v-overlay
                    absolute
                    :value="campaignResourceId.includes(resource.id)"
                  >
                    <v-btn
                      color="red lighten-1"
                      @click="selectedResource(resource)"
                    >
                      <v-icon x-large>mdi-check</v-icon>
                    </v-btn>
                  </v-overlay>

                  <v-card-text class="preview-resource-text pb-2 subtitle-2">
                    <v-row>
                      <v-col cols="12" @click="selectedResource(resource)">
                        <h4 class="font-weight-bold">
                          이름: {{ resource.name }}
                          <br />
                          해상도: {{ resource.width }} X {{ resource.height }}
                        </h4>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="pagination.totalPage > 1">
              <v-col>
                <div class="d-flex">
                  <v-pagination
                    color="secondary"
                    v-model="pagination.page"
                    :length="pagination.totalPage"
                    :total-visible="10"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </template>

          <v-row v-else align="center" justify="center" class="pt-10">
            <v-col cols="12" class="text-center">
              <v-icon> mdi-alert-outline </v-icon>
            </v-col>
            <h3 class="text-center" style="line-height: 1.5rem">
              선택 할 수 있는 소재가 없습니다. <br />
              소재를 업로드해주세요.
            </h3>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <slot name="preview">
      <v-col :cols="12" md="6">
        <h3 class="subtitle-1 font-weight-bold">
          <v-icon color="error" x-small>mdi-asterisk</v-icon>
          소재 미리보기
        </h3>

        <video
          v-if="lastSelectResource"
          preload="metadata"
          class="preview col-12"
          :src="`${lastSelectResource.url}#t=0.5`"
          controls
        ></video>
      </v-col>
    </slot>

    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { Pagination } from '@/model/Pagination';
import { CampaignResource } from '@/model/common';

import { getCampaignResourcesApi } from '@/api/admin/CampaignResourceAPI';

export default {
  props: {
    selectedId: {
      default: () => []
    },
    campaignType: {
      type: String,
      default: null
    },
    // 리소스 조회시 회원 ID(매체사, 광고주 회원 아이디)
    userId: {
      default: null
    },
    // 캠페인 수정시 or 매체 정보 수정시 해당 ID가 있겠지
    originId: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      campaignResourceId: [],
      campaignResource: [],
      campaignResources: [],
      pagination: new Pagination(1, 6),
      searchUserId: null
    };
  },
  computed: {
    ...mapGetters({}),
    // 마지막 선택 영상
    lastSelectResource() {
      return this.campaignResource.length > 0
        ? this.campaignResource[this.campaignResource.length - 1]
        : null;
    }
  },
  created() {
    if (this.userId) {
      this.fetchDidCampaignResources().then(() => {
        // 캠페인 리소스 수정시에
        if (this.originId) {
          this.campaignResourceId = this.selectedId ? [...this.selectedId] : [];
          this.campaignResource = this.campaignResources.filter(item =>
            this.selectedId.includes(item.id)
          );
        }
      });
    }
  },
  watch: {
    selectedId(value) {
      this.campaignResourceId = value ? [...value] : [];
    },
    campaignResource: {
      deep: true,
      handler(newData) {
        this.$emit('setResource', this.multiple ? newData : newData[0] ?? null);
      }
    },
    'pagination.page': function () {
      this.fetchDidCampaignResources();
    },
    userId: function () {
      this.fetchDidCampaignResources().then(() => {
        this.campaignResourceId = this.selectedId ? [...this.selectedId] : [];
      });
    }
  },
  methods: {
    selectedResource(resource) {
      const idx = this.campaignResourceId.findIndex(
        item => item === resource.id
      );

      let tempIds = [...this.campaignResourceId];
      let temps = [...this.campaignResource];

      if (idx < 0) {
        // 추가
        if (!this.multiple) {
          tempIds = [];
          temps = [];
        }

        tempIds = [...tempIds, resource.id];
        temps = this.campaignResources.filter(item =>
          tempIds.includes(item.id)
        );
      } else {
        // 제거
        temps.splice(idx, 1);
        tempIds.splice(idx, 1);
      }
      this.campaignResource = [...temps];
      this.campaignResourceId = [...tempIds];
    },
    /**@description:  캠페인 소재 목록 */
    async fetchDidCampaignResources() {
      this.loading = true;
      const { perPage } = this.pagination;

      const [error, data] = await getCampaignResourcesApi({
        params: {
          page: this.pagination.page,
          perPage: perPage,
          userId: this.userId, // 해당 광고주 소재만 조회
          filters: {
            campaignType: ['did'],
            approveStatus: ['A'], // 승인된 광고만 조회
            hideExpired: true // 만료된 영상 제외하고 조회
          },
          pagination: 'Y'
        }
      });

      if (error) {
        console.error(error);
        this.$axiosErrorAlert();
      } else {
        const { resources, total_count } = data;

        this.campaignResources = resources?.map(resource => {
          return new CampaignResource(resource);
        });

        const totalPage = parseInt(total_count / perPage);
        this.pagination.totalCount = total_count;
        this.pagination.totalPage =
          total_count % perPage ? totalPage + 1 : totalPage;
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.preview-resource-box {
  min-height: 250px;
  max-height: 450px;
  overflow: auto;

  .preview-resource-card {
    min-height: 200px;
    max-height: 200px;

    .preview-resource-text {
      position: absolute;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}

img {
  image-orientation: from-image;
}
</style>
