var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SearchFilter',{attrs:{"loading":_vm.loading},on:{"applyFilters":_vm.applyFilters}})],1)],1),_c('v-row',_vm._l((_vm.chartList),function(chart){return _c('v-col',{key:chart.value,attrs:{"cols":"12","lg":"4"}},[_c('ReportCharts',{attrs:{"campaignType":chart.value,"title":chart.text,"dateRanges":_vm.dateRanges,"countLabel":chart.countLabel}})],1)}),1),_c('v-row',[_c('v-col',[(false)?_c('v-card',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"float-right"},[_c('ReportExcelExport',{attrs:{"headers":_vm.headers,"tableOptions":_vm.tableOptions,"filters":_vm.filters,"chartList":_vm.chartList,"issetLive":_vm.issetLive}})],1)])],1)],1):_vm._e(),(_vm.headers && !_vm.loading)?_c('v-data-table',{staticClass:"px-3 elevation-2",staticStyle:{"white-space":"nowrap"},attrs:{"headers":_vm.headers,"items":_vm.stats,"item-key":"id","loading":_vm.loading,"hide-default-footer":"","loading-text":"데이터 로딩...","no-data-text":"데이터가 없습니다.","mobile-breakpoint":"0","server-items-length":_vm.pagination.totalCount,"disable-sort":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('DayOfWeek',{attrs:{"date":item.date}})]}},{key:"item.impressionCount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('impressionCount', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('impressionCount', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(item.impressionCount.toLocaleString())+" ")]}},{key:"item.reachCount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('reachCount', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('reachCount', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(item.reachCount.toLocaleString())+" ")]}},{key:"item.clickCount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('clickCount', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('clickCount', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(item.clickCount.toLocaleString())+" ")]}},{key:"item.ctr",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('ctr', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('ctr', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(item.ctr)+" % ")]}},{key:"item.ctrReach",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('ctrReach', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('ctrReach', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(item.ctrReach)+" % ")]}},{key:"item.cpmiExpenditure",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('cpmiExpenditure', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('cpmiExpenditure', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("KRW")(item.cpmiExpenditure))+" ")]}},{key:"item.cpmrExpenditure",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.issetGapIcon)?_c('v-icon',{attrs:{"color":_vm.getGapIcon('cpmrExpenditure', index).color}},[_vm._v(_vm._s(_vm.getGapIcon('cpmrExpenditure', index).icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("KRW")(item.cpmrExpenditure))+" ")]}}],null,true)},[(!_vm.loading && _vm.totalStats)?_c('template',{slot:"body.prepend"},[_c('tr',{staticStyle:{"background-color":"rgba(244, 244, 244, 1)"}},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.totalRowColSpan}},[_vm._v("전체")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.totalStats.impression.toLocaleString())+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.totalStats.reach.toLocaleString())+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.totalStats.click.toLocaleString())+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.totalStats.ctr)+" % ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.totalStats.ctrReach)+" % ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("KRW")(_vm.totalStats.cpmiExpenditure))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("KRW")(_vm.totalStats.cpmrExpenditure))+" ")])])]):_vm._e()],2):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.pagination.totalPage > 1)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":10,"color":"secondary"},on:{"input":function($event){return _vm.getTypeReportStatsApi()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }