import { utils, writeFile } from 'xlsx';

export function ExcelExport(header, data, title) {
  const wb = utils.book_new();

  const ws = utils.json_to_sheet(data, {
    origin: 'A2',
    skipHeader: true
  });
  utils.sheet_add_aoa(ws, header); //heading: array of arrays

  utils.book_append_sheet(wb, ws);

  writeFile(wb, `${title}.xlsx`);
}

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.textContent = text;
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';

  document.body.appendChild(textArea);
  // Avoid scrolling to bottom
  var range, selection;
  if (isOS()) {
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }

  try {
    var successful = document.execCommand('copy');
    return successful;
  } catch (error) {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export function isOS() {
  return navigator.userAgent.match(/ipad|iphone|Macintosh/i);
}

export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  const result = await navigator.clipboard
    .writeText(text)
    .then(
      () => {
        return true;
      },
      () => {
        return false;
      }
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });

  return result;
}

// 프린트
export function printDiv(el, title) {
  // Get HTML to print from element
  const prtHtml = document.getElementById(el).innerHTML;

  // Get all stylesheets HTML
  let stylesHtml = '';
  for (const node of [
    ...document.querySelectorAll('link[rel="stylesheet"], style')
  ]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const WinPrint = window.open(
    '',
    '',
    'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
  );

  WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
    <title>${title}</title>
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
}

/**@description: 공통 속성 */
export function getAttributes(attributes, type = 'text-field') {
  let res = {
    required: true,
    outlined: true,
    dense: true
  };

  switch (type) {
    case 'select':
      res = {
        ...res,
        menuProps: { bottom: true, offsetY: true }
      };
      break;
    default:
      // text-field
      res = {
        ...res,
        clearable: true
      };
      break;
  }

  const root = this;

  for (const key in attributes) {
    const val = attributes[key];

    if (key === 'errors') {
      root.$set(res, 'hideDetails', !val?.length);
      root.$set(res, 'errorMessages', val);
      continue;
    }

    root.$set(res, `${key}`, val);
  }

  return res;
}
