<template>
  <div>
    <a-descriptions bordered>
      <v-row slot="title">
        <v-col cols="auto" sm="12" md="auto"> 기본정보 </v-col>

        <v-col class="text-right ml-auto" cols="auto" sm="12" md="auto">
          <v-btn
            small
            color="secondary"
            outlined
            :loading="loading"
            :style="{ marginLeft: '10px' }"
            @click="editPasswordDialog = true"
          >
            비밀번호 변경
          </v-btn>
          <v-btn
            small
            color="secondary"
            :loading="loading"
            :style="{ marginLeft: '10px', color: 'white' }"
            @click="editInfoDialog = true"
          >
            광고주 정보 변경
          </v-btn>
        </v-col>
      </v-row>

      <a-descriptions-item label="권한">
        {{ user.role === 'cashnote' ? '캐시노트' : '일반 관리자' }}
      </a-descriptions-item>

      <a-descriptions-item label="광고주 유형" :span="2">
        {{
          advertiser.type
            ? AdvertiserTypeList.find(v => v.value === advertiser.type).text
            : '-'
        }}
      </a-descriptions-item>

      <a-descriptions-item label="이메일">
        {{ user.email }}
      </a-descriptions-item>

      <a-descriptions-item label="활성여부" :span="2">
        <a-tag :color="status.color"> {{ status.text }} </a-tag>
      </a-descriptions-item>

      <a-descriptions-item label="광고주명">
        {{ user.name }}
      </a-descriptions-item>

      <a-descriptions-item label="연락처" :span="2">
        {{ phone }}
      </a-descriptions-item>

      <a-descriptions-item label="담당자명">
        {{ user.manager_name }}
      </a-descriptions-item>

      <a-descriptions-item label="담당자 연락처" :span="2">
        {{ user.manager_tel }}
      </a-descriptions-item>

      <a-descriptions-item label="추천인 코드">
        {{ user.referrer_code }}
      </a-descriptions-item>

      <a-descriptions-item label="가입일" :span="2">
        {{ createdAt }}
      </a-descriptions-item>
      <a-descriptions-item
        label="승인 거절 사유"
        :span="3"
        v-if="status.text === 'N' && user.deny_reason"
      >
        <h4 class="red--text">
          {{ user.deny_reason }}
        </h4>
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="회사정보" bordered class="mt-10 mb-10">
      <a-descriptions-item label="대표자 명" :span="2">
        {{ user.business_president ? user.business_president : '-' }}
      </a-descriptions-item>
      <a-descriptions-item label="사업자등록증">
        <a-button
          size="small"
          class="ml-2"
          @click="showImageDialog(businessImage)"
          v-if="businessImage"
        >
          사업자등록증
        </a-button>
        <div v-else>-</div>
      </a-descriptions-item>

      <a-descriptions-item label="사업자 상호" :span="2">
        {{ user.business_name }}
      </a-descriptions-item>

      <a-descriptions-item label="사업자 등록번호">
        {{ user.business_num }}
      </a-descriptions-item>

      <a-descriptions-item label="업태" :span="2">
        {{ user.business_type }}
      </a-descriptions-item>

      <a-descriptions-item label="업종">
        {{ user.business_category }}
      </a-descriptions-item>

      <a-descriptions-item label="주소" :span="2">
        <b>[ {{ user.business_postcode }} ] {{ user.business_address }}</b>

        {{ user.business_detail_address }}
        {{ user.business_extra_address }}
      </a-descriptions-item>

      <a-descriptions-item label="세금계산서 이메일">
        {{ user.business_tax_email }}
      </a-descriptions-item>
    </a-descriptions>

    <a-descriptions title="결제정보" bordered class="mt-10 mb-10">
      <a-descriptions-item label="예금주" :span="3">
        {{ user.bank_account_holder || '-' }}
      </a-descriptions-item>

      <a-descriptions-item label="계좌번호" :span="3">
        <template v-if="!user.bank_name"> '-' </template>
        <template v-else>
          [{{ user.bank_name }}] {{ user.bank_account_num }}
        </template>
      </a-descriptions-item>

      <a-descriptions-item label="통장 사본" :span="3">
        <a-button
          size="small"
          class="ml-2"
          @click="showImageDialog(bankbookImage)"
          v-if="bankbookImage"
        >
          통장 사본
        </a-button>
      </a-descriptions-item>
    </a-descriptions>

    <AdvertiserChangePasswordDialog
      v-if="editPasswordDialog"
      :dialog="editPasswordDialog"
      @close="editPasswordDialog = false"
    />

    <AdvertiserUpdateDialog
      v-if="editInfoDialog"
      :dialog="editInfoDialog"
      @refresh="refreshClose"
      @close="editInfoDialog = false"
    />
  </div>
</template>

<script>
import AdvertiserChangePasswordDialog from '../dialog/AdvertiserChangePasswordDialog.vue';
import AdvertiserUpdateDialog from '../dialog/AdvertiserUpdateDialog.vue';
import { mapGetters, mapActions } from 'vuex';
import { UserApproveStatusList, AdvertiserTypeList } from '@/config/user';

export default {
  components: {
    AdvertiserChangePasswordDialog,
    AdvertiserUpdateDialog
  },
  computed: {
    ...mapGetters({
      advertiser: 'advertiser/record'
    }),
    user() {
      return this.advertiser.origin.user;
    },
    createdAt() {
      return this.$moment(this.user.created_at).format('YYYY년 MM월 DD일');
    },
    status() {
      let status = this.user
        ? UserApproveStatusList.find(v => {
            return v.value === this.user.approve_status_flag;
          })
        : null;

      return status;
    },
    businessImage() {
      return this.user?.business_image?.url || '';
    },
    bankbookImage() {
      return this.user?.bankbook_image?.url || '';
    }
  },
  data() {
    return {
      editPasswordDialog: false,
      editInfoDialog: false,
      previewImageUrl: '',
      loading: false,
      confirm: false,
      approve: false,
      UserApproveStatusList: UserApproveStatusList,
      AdvertiserTypeList: AdvertiserTypeList
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'advertiser/closeDialog'
    }),
    refreshClose() {
      this.$emit('refresh');
      this.close();
      this.closeDialog('detail');
    },
    close() {
      this.imageDialog = this.editPasswordDialog = this.editInfoDialog = false;
    },
    showImageDialog(url) {
      window.open(
        url,
        'asdfo8or',
        'scrollbars=yes,width=600,height=800,top=10,left=20'
      );
    }
  }
};
</script>
