export default {
    namespaced: true,

    state: {
        menus: [{
            text: '캠페인 관리',
            active: true,
            items: [{
                    icon: 'mdi-bookmark-multiple',
                    text: '캠페인 광고 그룹',
                    link: '/admin/advertiser-group'
                },
                {
                    icon: 'mdi-projector-screen',
                    text: '캠페인 목록',
                    link: '/admin/campaign'
                },
                {
                    icon: 'mdi-pound-box',
                    text: '태그',
                    link: '/admin/tag'
                },
            ]
        }, ]
    },
    getters: {
        menus(state) {
            return state.menus
        },
    },
};