<template>
  <v-dialog v-model="dialog" max-width="500" scrollable>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>필터 이름 변경</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-3">
        <b>{{ filter.name || ` 캠페인 필터 ${filter.id}` }}</b>
        <v-text-field
          v-model="changeName"
          class="mt-3"
          required
          outlined
          hide-details
          placeholder="변경할 이름을 입력해 주세요."
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn class="mr-2" depressed :disabled="loading" @click="closeDialog">
          닫기
        </v-btn>
        <v-btn
          color="#00d1b2"
          class="white--text"
          depressed
          :disabled="loading"
          @click="handleUpdateClick()"
        >
          변경
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { changeNameAPI } from '@/api/common/MenusAPI';

export default {
  components: {},
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({}),
    dialog: {
      get() {
        console.log(this.openDialog);
        return this.openDialog;
      },
      set() {
        this.closeDialog();
      }
    }
  },
  watch: {
    openDialog() {
      console.log(this.openDialog);

      this.changeName = this.filter.name;
    }
  },
  data() {
    return {
      loading: false,
      changeName: ''
    };
  },
  methods: {
    ...mapActions({}),

    // 필터 이름 변경
    async handleChangeNameClick(filterId, name) {
      if (!this.filter || !name) return;

      const filter = this.filterList.find(item => item.id === filterId);

      if (filter.name === name) {
        this.$Swal.fire({
          html: '<h3>이전과 동일한 이름입니다.</h3>',
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return;
      }
      const [error] = await changeNameAPI(filterId, {
        name: name
      });

      if (error) {
        console.log(error);
      } else {
        filter.name = name;
      }
    },
    async handleUpdateClick() {
      let errorMsg = '';
      if (!this.changeName) {
        errorMsg = '입력항목을 확인해 주세요.';
      }

      if (!errorMsg && this.filter.name === this.changeName) {
        errorMsg = '입력항목을 확인해 주세요.';
      }

      if (errorMsg) {
        this.$Swal.fire({
          html: `<h3>${errorMsg}</h3>`,
          icon: 'error',
          iconHtml: '!',
          confirmButtonText: '확인'
        });
        return;
      }

      const [error] = await changeNameAPI(this.filter.id, {
        name: this.changeName
      });

      if (error) {
        console.log(error);
      } else {
        this.$Swal.fire({
          icon: 'success',
          title: '저장 되었습니다.',
          showConfirmButton: false,
          timer: 1000
        });
        this.closeDialog(this.changeName);
        this.$emit('refresh');
      }
    },
    closeDialog(name) {
      this.$emit('closeDialog', name);
    }
  }
};
</script>

<style></style>
