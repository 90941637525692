<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        eager
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" dark>
            이미지 업로드
          </v-btn>
        </template>
        <v-card style="background-color: #e5edf1;">
          <v-toolbar dark>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>이미지 업로드</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="submitFiles()"
                :icon="loading"
                :loading="loading"
              >
                저장
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="mx-auto">
              <div class="box">
                <v-alert
                  border="top"
                  colored-border
                  color="secondary"
                  elevation="2"
                  class="text-center my-0"
                >
                  <a class="btn btn-sm btn-primary" @click="$refs.file.click()"
                    ><v-icon color="primary">mdi-cursor-default-click</v-icon>
                    파일 선택</a
                  >
                  <span> 또는 마우스로 드레그 하세요.</span>
                </v-alert>

                <form ref="fileform">
                  <div class="form-box">
                    <input type="file" ref="file" style="display: none" />
                    <div
                      class="drop-files mx-auto"
                      v-show="files.length == false"
                    >
                      <v-icon x-large>
                        mdi-tray-plus
                      </v-icon>
                    </div>
                    <div
                      v-for="(file, index) in files"
                      class="file-listing"
                      :key="index"
                    >
                      <img
                        class="preview"
                        v-bind:ref="'preview' + parseInt(index)"
                      />
                      <h6>{{ file.file.name }}</h6>
                      <div class="remove-container">
                        <v-btn icon @click="removeFile(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </v-col>
          </v-row>
          <div></div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dragAndDropCapable: false,
      files: [],
      divs: '',
      fileLen: '',
      loading: false
    };
  },
  props: {
    imageType: {
      type: String,
      default() {
        return null;
      }
    },
    campaignType: {
      type: String,
      default() {
        return null;
      }
    },
    fileLabel: {
      type: String,
      default() {
        return '';
      }
    },
    fileMax: {
      type: Number,
      default() {
        return 10;
      }
    },
    fileType: {
      type: String,
      default() {
        return 'image';
      }
    },
    fileDiv: {},
    fileMaxSize: {
      type: Number,
      default() {
        return 500;
      }
    },
    exampleImg: {}
  },
  created() {
    this.divs = this.fileDiv;
    // this.$emit('sendUploadImage', this.files, this.fileDiv);
  },
  mounted() {
    this.bindingDrag();
  },

  methods: {
    bindingDrag() {
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        [
          'drag',
          'dragstart',
          'dragend',
          'dragover',
          'dragenter',
          'dragleave',
          'drop'
        ].forEach(
          function(evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function(e) {
                e.preventDefault();
                e.stopPropagation();
              }.bind(this),
              false
            );
          }.bind(this)
        );

        ['dragover', 'dragenter'].forEach(
          function(evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function() {
                this.$refs.fileform.classList.add('is-dragover');
              }.bind(this),
              false
            );
          }.bind(this)
        );

        ['dragover', 'dragenter'].forEach(
          function(evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function() {
                this.$refs.fileform.classList.add('is-dragover');
              }.bind(this),
              false
            );
          }.bind(this)
        );

        ['dragleave', 'dragend'].forEach(
          function(evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function() {
                this.$refs.fileform.classList.remove('is-dragover');
              }.bind(this),
              false
            );
          }.bind(this)
        );

        this.$refs.fileform.addEventListener(
          'drop',
          function(e) {
            this.$refs.fileform.classList.remove('is-dragover');
            let sizeFile = e.dataTransfer.files.length + this.files.length;
            if (sizeFile > this.fileMax) {
              this.showFileMaxMessage();
              return false;
            } else {
              for (let i = 0; i < e.dataTransfer.files.length; i++) {
                //console.log(e.dataTransfer.files[i]);
                const validName = this.checkFileType(
                  e.dataTransfer.files[i].name
                );
                if (validName.status) {
                  this.$Swal.fire({
                    html: validName.message,
                    showConfirmButton: true,
                    confirmButtonText: '확인'
                  });
                  return false;
                }
                const fileType = this.getFileType(e.dataTransfer.files[i].name);
                const validSize = this.getFileSize(
                  e.dataTransfer.files[i].name,
                  e.dataTransfer.files[i].size
                );
                if (validSize.status) {
                  this.$Swal.fire({
                    type: 'warning',
                    html: validSize.message,
                    showConfirmButton: true,
                    confirmButtonText: '확인'
                  });
                  return false;
                }

                this.files.push({
                  file: e.dataTransfer.files[i],
                  size: e.dataTransfer.files[i].size,
                  fileType: fileType,
                  fileLabel: this.fileLabel
                });
                this.getImagePreviews();
                //this.$emit('sendUploadImage', e.dataTransfer.files[i]);
              }
            }
            //this.$emit('sendUploadImage', this.files);
            //this.submitFiles();
          }.bind(this)
        );
      }

      /* !! bind(this) 를 통해 vue component 의 this 를 사용가능함, bind 없이 내부에서 this 사용하면 input element 를 받아옴*/
      this.$refs.file.addEventListener(
        'change',
        function(e) {
          let f = e.target.files || e.dataTransfer.files;
          let sizeFile = f.length + this.files.length;
          //console.log(this.fileMax);
          if (sizeFile > this.fileMax) {
            this.showFileMaxMessage();
            return false;
          } else {
            for (let i = 0; i < e.target.files.length; i++) {
              // console.log(e.dataTransfer.files[i]);
              const validName = this.checkFileType(e.target.files[i].name);
              if (validName.status) {
                this.$Swal.fire({
                  type: 'warning',
                  html: validName.message,
                  showConfirmButton: true,
                  confirmButtonText: '확인'
                });
                return false;
              }
              const fileType = this.getFileType(e.target.files[i].name);
              const validSize = this.getFileSize(
                e.target.files[i].name,
                e.target.files[i].size
              );
              if (validSize.status) {
                this.$Swal.fire({
                  type: 'warning',
                  html: validSize.message,
                  showConfirmButton: true,
                  confirmButtonText: '확인'
                });
                return false;
              }
              this.files.push({
                file: e.target.files[i],
                size: e.target.files[i].size,
                fileType: fileType,
                fileLabel: this.fileLabel
              });
              this.getImagePreviews();
              //this.$emit('sendUploadImage', e.target.files[i]);
            }
          }
          //console.log(this.files);
        }.bind(this)
      );
    },
    determineDragAndDropCapable() {
      let div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },

    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].file.name)) {
          let reader = new FileReader();
          reader.addEventListener(
            'load',
            function() {
              this.$refs['preview' + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );

          reader.readAsDataURL(this.files[i].file);
        } else {
          this.$nextTick(function() {
            this.$refs['preview' + parseInt(i)][0].src = '/images/pdf.png';
          });
        }
      }
    },
    checkFileType(name) {
      //  console.log(this.fileType);
      const allowFileType = this.fileType;

      const res = {
        status: true,
        message: ''
      };
      //  console.log(name);
      if (allowFileType === 'image') {
        if (/\.(jpe?g|png)$/i.test(name)) {
          res.status = false;
        } else {
          res.message =
            '<h3>이미지 ( .jpg .jpeg .png ) 파일만 업로드 가능합니다.</h3>';
        }
        return res;
      }
      if (allowFileType === 'pdf') {
        if (/\.(pdf)$/i.test(name)) {
          res.status = false;
        } else {
          res.message = '<h3>PDF ( .pdf ) 파일만 업로드 가능합니다.</3>';
        }
        return res;
      }
      if (allowFileType === 'all') {
        if (/\.(jpe?g|png|gif|pdf)$/i.test(name)) {
          res.status = false;
        } else {
          res.message =
            '<h3>확장자가 (.jpg .jpeg .png .gif .pdf ) 인 파일만 업로드 가능합니다.</h3>';
        }
        return res;
      }
    },
    getFileType(name) {
      if (/\.(jpe?g|png|gif)$/i.test(name)) {
        return 0;
      } else {
        return '';
      }
    },
    getFileSize(name, size) {
      const fileMaxSize = this.fileMaxSize;
      const sizeString = `${parseFloat(size / 1024, 2).toFixed(1)} KB`;
      const res = {
        status: true,
        message: ''
      };

      if (size / 1024 > fileMaxSize) {
        res.message = `<h3>${fileMaxSize}KB 이하의 파일만 등록가능합니다.<br><br>파일명: ${name}<br>파일사이즈: ${sizeString}</h3>`;
      } else {
        res.status = false;
      }
      return res;
    },
    async submitFiles() {
      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        this.loading = true;

        formData.append('file', this.files[i].file);
        formData.append('imageType', this.imageType);
        formData.append('campaignType', this.campaignType);

        console.dir(formData);
        await this.$axios
          .post('/utils/campaign-images', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res => {
            // console.log(res);
            this.$emit('addImage', res.data.image);
          })
          .catch(err => {
            console.log(err);
          });
      }

      this.loading = false;
      this.dialog = false;
      this.files = [];
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },
    showFileMaxMessage() {
      this.$Swal.fire({
        html: `<h3>업로드 가능한 파일 개수는 최대 ${this.fileMax} 개 입니다.</h3>`,
        showConfirmButton: true,
        confirmButtonText: '확인'
      });
    },
    showFileSizeMessage() {},
    showCustomMessage() {}
  }
};
</script>

<style scoped>
.box {
  margin-top: 50px;
  padding: 20px;
}

.box form.is-dragover {
  background-color: white;
  outline-offset: -20px;
}
.box form {
  height: 350px;
  border-radius: 4px;
  background-color: #c8dadf;
  font-size: 1.25rem;
  position: relative;
  padding: 15px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}

.form-box {
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  height: 98%;
  width: 98%;
  overflow: auto;
}

.box div.file-listing {
  display: block;
  flex-direction: column;
  padding: 10px;
}

.box div.file-listing img {
  height: 100px;
}

.box div.remove-container {
  text-align: center;
}

.box div.remove-container a {
  color: red;
  cursor: pointer;
}

.box a.submit-button {
  display: block;
  margin: auto;
  text-align: center;
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  background-color: #ccc;
  color: white;
  font-weight: bold;
  margin-top: 20px;
}

.box progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
img {
  image-orientation: from-image;
}
.exampleImg {
  cursor: pointer;
  position: relative;
}
.exampleImg span {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 45%;
  left: 25%;
  border: 2px solid #fff;
  border-radius: 10%;
  letter-spacing: 3px;
  padding: 5px;
  transform: rotate(45deg);
}
</style>
