<template>
  <v-dialog v-if="campaign" v-model="dialog" width="600" persistent scrollable>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title
          >{{ campaign.id }}번 {{ campaign.name }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3 py-3">
        <v-row>
          <v-col cols="12">
            <v-radio-group
              v-model="selectedApproveStatus"
              class="d-inline-block mt-0"
              label="게재 상태 선택"
            >
              <div
                v-for="(m, index) in ApproveStatusList"
                :key="index"
                class="ma-3"
              >
                <v-radio :value="m.value" class="ma-0" :color="m.colorCode">
                  <template v-slot:label>
                    <v-badge dot inline :color="m.colorCode">
                      {{ m.text }}
                    </v-badge>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="selectedApproveStatus === 'N'" dense>
          <v-col cols="12">
            <v-select
              v-model="denyCode"
              label="승인 거절 사유"
              :items="ApproveDenyReasonList"
              item-text="text"
              item-value="value"
              outlined
              @input="setApproveDenyReason"
            ></v-select>
          </v-col>
          <v-col v-if="denyCode === 'D000'" cols="12">
            <v-textarea
              v-model="denyReason"
              outlined
              placeholder="거절 사유를 입력해주세요"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn @click="$emit('close')" text>
          취소
        </v-btn>
        <v-btn
          color="primary"
          @click.stop="updateApprovedStatusApi()"
          text
          :loading="loading"
          :disabled="selectedApproveStatus === campaign.approveStatus"
        >
          적용
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ApproveStatusList,
  ApproveDenyReasonList
} from '@/config/admin/campaign';
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    campaign: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      campaignId: null,
      selectedApproveStatus: null,
      denyCode: null,
      denyReason: null
    };
  },
  computed: {
    ApproveStatusList() {
      return ApproveStatusList;
    },
    ApproveDenyReasonList() {
      return ApproveDenyReasonList;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.selectedApproveStatus = this.campaign.approveStatus;
      this.campaignId = this.campaign.id;
    },
    async updateApprovedStatusApi() {
      this.loading = true;
      try {
        const { data } = await this.$axios.patch(
          `/admin/campaigns/${this.campaignId}/approve`,
          {
            status: this.selectedApproveStatus,
            denyCode: this.denyCode,
            denyReason: this.denyReason
          }
        );

        if (data.success) {
          this.campaign.approveStatus = this.selectedApproveStatus;
          this.$Swal.fire({
            icon: 'success',
            title: '변경사항이 적용되었습니다.',
            confirmButtonText: '확인'
          });
          this.$emit('close');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    // 거절 사유를 선택합니다.
    setApproveDenyReason(value) {
      this.denyReason = null;
      if (value !== 'D000') {
        // 거절 사유가 직접입력이 아닌경우
        this.denyReason = this.ApproveDenyReasonList.find(
          v => v.value === value
        ).text;
      }
    }
  }
};
</script>

<style></style>
