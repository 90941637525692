<template>
  <v-col
    :cols="cols"
    :md="`${md}`"
    :sm="`${sm}`"
    :class="`custom-row-box row align-center pa-0 ma-0 ${
      outlined ? 'outlined' : ''
    }`"
  >
    <div :class="`row-box-title ${titleClass ? titleClass : 'col-5'}`">
      <slot name="title">
        <div :style="titleStyle" class="title-text">
          <v-icon v-if="required" color="error" x-small>mdi-asterisk</v-icon>
          {{ title }}

          <template v-if="titleDescription">
            <InformationHover
              :title="dense ? '' : title"
              :subTitle="titleDescription"
              :dense="dense"
            />
          </template>
        </div>
      </slot>
    </div>
    <div class="row-box-content col">
      <slot name="content">
        <div :style="contentStyle">
          {{ content }}
        </div>
      </slot>
    </div>
  </v-col>
</template>

<script>
import InformationHover from '@/components/InformationHover.vue';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    titleDescription: {
      type: String,
      default: ''
    },
    titleStyle: {
      require: false,
      type: Object,
      default: null
    },
    titleClass: {
      require: false,
      type: String,
      default: null
    },
    content: {
      type: String,
      default: ''
    },
    contentStyle: {
      require: false,
      type: Object,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    cols: {
      require: false,
      type: Number,
      default: 12
    },
    sm: {
      require: false,
      type: Number,
      default: 12
    },
    md: {
      require: false,
      type: Number,
      default: 12
    },
    required: {
      require: false,
      type: Boolean,
      default: false
    },
    dense: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    InformationHover
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
<style lang="scss">
.custom-row-box {
  background-color: #fafafa;

  &.outlined {
    border: 0.5px solid #e8e8e8;
    // border-top: 0px solid #e8e8e8;

    &:first-child {
      border: 1px solid #e8e8e8;
    }
    .row-box-content {
      border-left: 1px solid #e8e8e8;
    }
  }
  .row-box-content {
    padding: 5px;
    background-color: #fff;
    font-size: 14px !important;

    input,
    div,
    label {
      font-size: 14px !important;
    }
    .v-text-field__details {
      margin-bottom: 0 !important;
      .v-messages__message {
        font-size: 12px !important;
      }
    }
  }

  .row-box-title {
    padding: 5px 15px;

    .title-text {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
