<template>
  <v-dialog
    v-model="dialog.store"
    max-width="600"
    scrollable
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>매체그룹 등록</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <media-group-store-form
          :dialog="dialog.store"
          @refresh="$emit('refresh')"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MediaGroupStoreForm from '../MediaGroupStoreForm';

export default {
  components: {
    MediaGroupStoreForm,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      dialog: 'mediaGroup/dialog',
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      closeDialog: 'mediaGroup/closeDialog',
    }),
    close() {
      this.closeDialog('store');
    },
  },
};
</script>
