<template>
  <validation-observer tag="form" ref="obs4" v-slot="{ invalid }">
    <v-row class="pa-5" style="background-color:white;">
      <v-col sm="12" lg="6">
        <TextFieldTitle :title="Titles.rs4.impressionTime" />
        <v-row>
          <v-col cols="10">
            <validation-provider v-slot="{ errors }" rules="required|numeric">
              <v-text-field
                v-model="campaign.impressionTime"
                :error-messages="errors"
                required
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="campaign.impressionTimeType"
              :items="UnitOfTimeList"
              item-text="text"
              item-value="value"
              item-disabled="disable"
              outlined
              label="단위"
            ></v-select>
          </v-col>
        </v-row>

        <AlertMessage
          class="mt-3"
          border="left"
          icon="mdi-alert"
          color="red"
          message="위치 타겟을 설정한 경우 광고 노출 우선순위 값을 60 으로 설정해주세요."
          localStorageKey="impressionFrequencyAlert"
          dismissible
        />
        <TextFieldTitle
          :title="Titles.rs4.impressionFrequency"
          :subTitle="SubTitles.rs4.impressionFrequency"
        />

        <v-row>
          <v-col cols="10">
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: true,
                numeric: true,
                min_value: Math.min(...impressionFrequency),
                max_value: Math.max(...impressionFrequency)
              }"
            >
              <v-autocomplete
                v-model="campaign.impressionFrequency"
                :error-messages="errors"
                suffix="순위"
                required
                outlined
                :items="impressionFrequency"
                hide-no-data
              >
              </v-autocomplete>
            </validation-provider>
          </v-col>
          <!-- <v-col cols="2">
              <v-text-field value="우선순위" readonly disabled></v-text-field>
            </v-col> -->
        </v-row>

        <TextFieldTitle
          :title="Titles.rs4.adsLimitInterval"
          :subTitle="SubTitles.rs4.adsLimitInterval"
        />
        <v-row>
          <v-col cols="10">
            <validation-provider v-slot="{ errors }" rules="required|numeric">
              <v-text-field
                v-model="campaign.adsLimitInterval"
                :error-messages="errors"
                required
                suffix="일"
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <TextFieldTitle
          :title="Titles.rs4.adsGoalCount"
          :subTitle="SubTitles.rs4.adsGoalCount"
        />
        <v-row>
          <v-col cols="10">
            <validation-provider v-slot="{ errors }" rules="required|numeric">
              <v-text-field
                v-model="campaign.adsGoalCount"
                :error-messages="errors"
                required
                outlined
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <div class="d-inline-flex align-center">
              <h3 class="mr-2 mb-0">
                실시간 SDK 전용

                <v-switch
                  v-model="campaign.isOnlyRealtime"
                  class="ma-0"
                  hide-details
                  color="#F15F5F"
                  inset
                />
                <v-chip class="mt-1" color="#F15F5F" x-small outlined>
                  {{ campaign.isOnlyRealtime ? '활성' : '비활성' }}</v-chip
                >
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" lg="4">
        <campaign-content-footer :invalid="invalid" />
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CampaignContentFooter from './CampaignContentFooter.vue';
import { mapGetters } from 'vuex';
import TextFieldTitle from '@/components/TextFieldTitle.vue';
import { SubTitles, Titles } from '@/config/admin/campaign';
import AlertMessage from '@/components/AlertMessage.vue';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CampaignContentFooter,
    TextFieldTitle,
    AlertMessage
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign',
      dialog: 'campaign/dialog'
    }),
    Titles() {
      return Titles;
    },
    impressionFrequency() {
      return Array.from(Array(60).keys(), n => n + 1);
    },
    SubTitles() {
      return SubTitles;
    }
  },
  watch: {
    dialog: function(newVal) {
      if (newVal) {
        this.$refs.obs4.reset();
      }
    }
  },
  data() {
    return {
      // impressionIntervalType: 's',
      impressionTimeType: 's',
      UnitOfTimeList: [
        {
          text: '초',
          value: 's',
          disable: false
        },
        {
          text: '분',
          value: 'm',
          disable: false
        }
        // {
        //   text: '시간',
        //   value: 'h',
        //   disable: true
        // },
        // {
        //   text: '일',
        //   value: 'd',
        //   disable: true
        // }
      ]
    };
  }
};
</script>

<style></style>
