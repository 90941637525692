<template>
  <div v-if="show">
    <v-card
      :style="{
        maxWidth: '300px',
        width: '100vw',
        borderRadius: '10px',
        background: '#fff',
        color: '#000'
      }"
    >
      <v-row class="ma-0 grey font-weight-black text-center">
        <v-col :cols="6" class="px-0 white--text"> 시 </v-col>
        <v-col :cols="6" class="px-0 white--text"> 분 </v-col>
      </v-row>
      <v-divider />
      <v-row class="ma-0">
        <v-col :cols="6" class="pa-0">
          <v-list dense style="height: 200px; overflow: auto">
            <v-list-item-group v-model="time.hour" mandatory>
              <v-list-item
                v-for="(hour, index) in hourOptions"
                :key="index"
                :value="hour"
                class="text-center"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ hour }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col :cols="6" class="pa-0">
          <v-list dense style="height: 200px; overflow: auto">
            <v-list-item-group v-model="time.minute" mandatory>
              <template v-if="max > 24 && time.hour === 24">
                <v-list-item value="00" class="text-center">
                  <v-list-item-content>
                    <v-list-item-title>00</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item
                  v-for="(minute, index) in minuteOptions"
                  :key="index"
                  :value="minute"
                  class="text-center"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ minute }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="text-right ma-0">
        <v-col cols="12">
          <v-btn text color="#999" @click="$emit('close')"> 취소 </v-btn>
          <v-btn text color="#00b1bb" @click="setTime"> 적용 </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 24
    },
    minuteUnit: {
      require: false,
      type: Number,
      default: 1
    },
    includeMain: {
      require: false,
      type: Boolean,
      default: false
    },
    includeMax: {
      require: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timePicker: false,
      time: {
        type: 'am',
        hour: 1,
        minute: 0
      },
      hourOptions: [],
      minuteOptions: [],
      hours: []
    };
  },
  watch: {
    show(v) {
      if (v) {
        this.timePicker = v;
      }
    }
  },
  async created() {
    this.init();
    this.minuteOptions = this.initOptions(60);

    if (this.value) {
      const valueList = this.value ? this.value.split(':') : ['00', '00'];
      // 입력된 값 설정
      this.time.hour = valueList[0];
      this.time.minute = valueList[1];
    }
  },
  methods: {
    init() {
      this.hourOptions = [];
      const minTime = this.includeMin
        ? this.min < 1
          ? this.min
          : this.min - 1
        : this.min;
      const maxTime = this.includeMax
        ? this.max > 23
          ? this.max
          : this.max + 1
        : this.max;
      this.hours = this.range(minTime, maxTime);

      this.hourOptions = this.hours.map(hour => {
        if (hour < 10) {
          hour = '0' + hour;
        }
        return `${hour}`;
      });
    },
    range(start, stop, step = 1) {
      return Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);
    },
    initOptions(length) {
      const options = Array.from({ length: length }, (_, index) => {
        return index < 10 ? '0' + index : index.toString();
      });

      return options.filter(item => item % this.minuteUnit === 0);
    },
    setTime() {
      let time = this.time.hour + ':' + this.time.minute;
      this.$emit('input', time);
      this.$emit('close');

      this.timePicker = false;
    }
  }
};
</script>
