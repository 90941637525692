var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authenticated)?_c('div',[(_vm.authenticated)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","mini-variant":!_vm.$isMobile && !_vm.mini,"mobile-breakpoint":"600"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.menus),function(menu,mIndex){return _c('div',{key:mIndex},[(menu.items)?_c('v-list-group',{attrs:{"sub-group":"","color":"dark","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(menu.text)}})],1)]},proxy:true}],null,true),model:{value:(menu.active),callback:function ($$v) {_vm.$set(menu, "active", $$v)},expression:"menu.active"}},_vm._l((menu.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":item.link,"exact":""}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)","disabled":_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, onTooltip)),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.text))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)}),1):_c('v-list-item',{attrs:{"link":"","to":menu.link,"exact":""}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,1)","disabled":_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-icon',_vm._g({},Object.assign({}, onTooltip)),[_vm._v(_vm._s(menu.icon))])]}}],null,true)},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(menu.text))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(menu.text))])],1)],1)],1)}),0)],1):_vm._e(),(_vm.authenticated)?_c('v-app-bar',{attrs:{"app":"","clipped-left":"","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openNav()}}}),_c('v-toolbar-title',[_c('v-img',{attrs:{"max-height":"50","max-width":"100","src":_vm.$mainLogoPath}})],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"value":_vm.isTask,"color":"error","overlap":"","dot":"","offset-x":"17","offset-y":"17"}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-clipboard-check-outline")])],1)],1)]}}],null,false,1949032823),model:{value:(_vm.admin_task_menu),callback:function ($$v) {_vm.admin_task_menu=$$v},expression:"admin_task_menu"}},[_c('div',{staticStyle:{"height":"500px"}},[(_vm.admin_task_menu)?_c('AdminTaskDialog',{on:{"close":function($event){_vm.admin_task_menu = false},"refresh":_vm.fetchUnsignedTasks}}):_vm._e()],1)]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,2166920326)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"primary","size":"48"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(" "+_vm._s(_vm.user.name.slice(0, 2))+" ")])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.user.name)+" 님")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-list-item',{attrs:{"disabled":_vm.loading},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("로그아웃")])],1)],1)],1)],1)],1):_vm._e(),_c('v-main',[_c('router-view',{attrs:{"name":"auth"}})],1),_c('v-snackbar',{attrs:{"value":_vm.errors,"timeout":2000}},[_vm._v(" "+_vm._s(_vm.errors)+" "),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.errors = false}}},[_vm._v(" 닫기 ")])],1),(_vm.userIFrameDialog)?_c('UserIFrameDialog'):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }