<template>
  <ValidationObserver tag="form" ref="obs5" v-slot="{ invalid }">
    <v-row class="pa-5" style="background-color:white;">
      <v-col>
        <v-row>
          <v-col cols="12" lg="6">
            <CampaignSchedule :schedule.sync="rs5.schedule" />
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" lg="6">
            <CampaignBudget
              :schedule="rs5.schedule"
              :budget.sync="rs5.budget"
            />
          </v-col>
        </v-row>
      </v-col>

      <!-- <v-col cols="4">
          <validation-provider></validation-provider>
          <v-card elevation="10">
            <v-card-title>
              예산
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              컨텐츠
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <validation-provider></validation-provider>
          <v-card elevation="10">
            <v-card-title>
              결제 수단
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              컨텐츠
            </v-card-text>
          </v-card>
        </v-col> -->
    </v-row>

    <v-row>
      <v-col sm="12" lg="6">
        <CampaignContentFooter :invalid="invalid" />
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import CampaignContentFooter from './CampaignContentFooter.vue';
import CampaignSchedule from './CampaignStep5Schedule.vue';
import CampaignBudget from './CampaignStep5Budget.vue';

export default {
  components: {
    // ValidationProvider,
    ValidationObserver,
    CampaignContentFooter,
    CampaignSchedule,
    CampaignBudget
  },
  props: ['rs5']
};
</script>

<style></style>
