var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('MenuTitle')],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openNoticeDialog()}}},[_vm._v(" 등록하기 ")])],1)])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3"},[_c('Table',{staticClass:"table-striped-rows",staticStyle:{"white-space":"nowrap"},attrs:{"size":"small","columns":_vm.columns,"bordered":"","data-source":_vm.notices,"scroll":{ x: true },"locale":{
            emptyText: '데이터가 없습니다.'
          },"pagination":{
            defaultPageSize: 15,
            showSizeChanger: true,
            pageSizeOptions: ['15', '30', '50', '100']
          },"customRow":function (record) {
              return {
                on: {
                  mouseenter: function (event) {
                    _vm.hoverItem = record.id;
                  },
                  mouseleave: function (event) {
                    _vm.hoverItem = null;
                  }
                }
              };
            }},scopedSlots:_vm._u([{key:"noticeTitle",fn:function(value, record){return [_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"min-height":"30px !important"}},[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticStyle:{"min-width":"40px"}},[(_vm.hoverItem === record.id)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openNoticeDialog(record.origin)}}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("수정")])]):_vm._e()],1)])]}},{key:"isActive",fn:function(value, record){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{staticClass:"ma-0",attrs:{"color":"success","inset":"","hide-details":"","readonly":""},on:{"click":function($event){return _vm.updateIsActive(record)}},model:{value:(record.isActive),callback:function ($$v) {_vm.$set(record, "isActive", $$v)},expression:"record.isActive"}})],1)]}},{key:"noticeGroup",fn:function(value){return [_vm._v(" "+_vm._s(_vm.UserRoleList.find(function (v) { return v.value === value; }).text)+" ")]}},{key:"isUrgent",fn:function(value){return [(value)?_c('v-chip',{attrs:{"color":"error","label":"","outlined":""}},[_vm._v("긴급")]):_vm._e()]}},{key:"endAt",fn:function(value, record){return [(record.issetEnd === false)?_c('div',[_vm._v(" 설정안함 ")]):_c('div',[_vm._v(" "+_vm._s(value)+" ")])]}}])})],1)],1)],1),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mx-auto",attrs:{"type":"table"}}),(_vm.noticeDialog)?_c('NoticeDialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }