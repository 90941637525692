import axios from 'axios';

export async function getUserPointApi() {
  try {
    const { data } = await axios.get(`/c/u/point`);
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPointOrdersApi(params) {
  try {
    const { data } = await axios.get(`/c/u/point-orders`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPointOrderApi(orderNo) {
  try {
    const { data } = await axios.get(`/c/u/point-order/${orderNo}`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getUserPointDayApi(params) {
  try {
    const { data } = await axios.get(`/c/u/point-days`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getUserPointDetailApi(params) {
  try {
    const { data } = await axios.get(`/c/u/point-detail`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getPointsApi(params = null) {
  try {
    const { data } = await axios.get(`/c/u/points`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function refundPointApi() {
  try {
    const { data } = await axios.post(`/c/u/point-refund`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function settleFreePointApi() {
  try {
    const { data } = await axios.post(`/c/u/point-settle`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createBankTransferApi(params) {
  try {
    const { data } = await axios.post(`/c/u/point-order/banktransfer`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateUserPointRefundApi() {
  try {
    const { data } = await axios.put(`/c/u/point-refund`);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function confirmPasswordApi(params) {
  try {
    const { data } = await axios.post(`/c/u/confirm-password`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function changePasswordApi(params, option = null) {
  try {
    const { data } = await axios.post(`/c/u/change-password`, params, option);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function updateProfileApi(params, option = null) {
  try {
    const { data } = await axios.post(`/c/u/profile`, params, option);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getProfileApi(params) {
  try {
    const { data } = await axios.get(`/c/u/profile`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function getInquiriesApi(params) {
  try {
    const { data } = await axios.get(`/c/u/inquiries`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function createInquiryApi(inquiry) {
  try {
    const { data } = await axios.post(
      `
    `,
      inquiry
    );

    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function cancelInquiryApi(params) {
  try {
    const { data } = await axios.delete(`/c/u/inquiry`, params);

    return [null, data];
  } catch (error) {
    return [error];
  }
}
