export default {
    namespaced: true,

    state: {
        menus: [
            // {
            //     icon: 'mdi-view-dashboard',
            //     text: '대시보드',
            //     link: '/advertiser'
            // },
            {
                text: '광고 관리',
                active: true,
                items: [{
                        icon: 'mdi-projector-screen',
                        text: '캠페인 관리',
                        link: '/advertiser/campaign'
                    },
                    {
                        icon: 'mdi-chart-areaspline',
                        text: '보고서',
                        link: '/advertiser/report'
                    },
                ]
            },
        ],
        bottomMenus: [
            // {
            //     text: '마이페이지',
            //     active: true,
            //     items: [{
            //             icon: 'mdi-credit-card-settings-outline',
            //             text: '결제 관리',
            //             link: '/advertiser/payment'
            //         },
            //         {
            //             icon: 'mdi-account-cog',
            //             text: '프로필 관리',
            //             link: '/advertiser/profile'
            //         },
            //     ]
            // },
            {
                icon: 'mdi-account-question',
                text: '1:1 문의',
                name: 'AdvertiserMember',
                params: {
                    menu: 'inquiry'
                }
            },
            {
                icon: 'mdi-account',
                text: 'My Page',
                name: 'AdvertiserMember',
                params: {
                    menu: 'mypage'
                }
            },
        ],
    },
    getters: {
        menus(state) {
            return state.menus
        },
        bottomMenus(state) {
            return state.bottomMenus
        }
    },
}