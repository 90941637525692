<template>
  <v-dialog v-model="dialog" width="800" scrollable persistent eager>
    <template v-slot:activator="{ on: dialogOn }">
      <v-btn color="primary" text v-on="{ ...dialogOn }"
        ><u>약관 내용 보기</u></v-btn
      >
    </template>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5 py-3">
        <PolicyPaymentContent />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn text color="primary" @click="submit()">
          동의하기
        </v-btn>
        <v-btn text @click="dialog = false">
          취소
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PolicyPaymentContent from './PolicyPaymentContent.vue';
export default {
  components: {
    PolicyPaymentContent
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    submit() {
      this.$emit('agreePolicy');
      this.dialog = false;
    }
  }
};
</script>

<style></style>
