// import AdvertiserHome from "../../views/advertiser/pages/home/AdvertiserHome.vue";
import AdvertiserCampaign from "../../views/advertiser/pages/campaign/AdvertiserCampaign.vue";
import AdvertiserReport from "../../views/advertiser/pages/report/AdvertiserReport.vue";
import AdvertiserMember from "../../views/advertiser/pages/member/AdvertiserMember.vue";


const advertiserCampaign = [{
    path: '/advertiser/campaign',
    name: 'AdvertiserCampaign',
    components: {
        auth: AdvertiserCampaign
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [2],
    }
}];

const advertiserMember = [{
    path: '/advertiser/member/:menu',
    name: 'AdvertiserMember',
    components: {
        auth: AdvertiserMember
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [2],
    }
}];

const advertiserReport = [{
    path: '/advertiser/report',
    name: 'AdvertiserReport',
    components: {
        auth: AdvertiserReport
    },
    meta: {
        requiresAuth: true,
        requiresAuthLevel: [2],
    }
}];


const routes = [
    // {
    //     path: '/advertiser',
    //     name: 'AdvertiserHome',
    //     components: {
    //         auth: AdvertiserHome
    //     },
    //     meta: {
    //         requiresAuth: true,
    //         requiresAuthLevel: [2],
    //     },
    // },
    ...advertiserCampaign,
    ...advertiserReport,
    ...advertiserMember
];

export default routes;