var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","scrollable":""},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("쿠폰 카테고리 "+_vm._s(_vm.buttonTitle))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('ValidationObserver',{ref:_vm.refKey},[(_vm.form)?_c('v-row',{staticClass:"ma-0 mt-5",attrs:{"dense":""}},[_c('CustomGridItem',{attrs:{"title":"이름","outlined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"카테고리 이름을 입력해 주세요","outlined":"","error-messages":errors,"hide-details":!errors.length,"dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,false,4056511083)})]},proxy:true}],null,false,2748424502)}),_c('CustomGridItem',{attrs:{"title":"KEY","outlined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ValidationProvider',{attrs:{"rules":{
                  required: _vm.form.id !== 1
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"카테고리 Key를 입력해 주세요","outlined":"","error-messages":errors,"hide-details":!errors.length,"dense":""},model:{value:(_vm.form.categoryKey),callback:function ($$v) {_vm.$set(_vm.form, "categoryKey", $$v)},expression:"form.categoryKey"}})]}}],null,false,1787079188)})]},proxy:true}],null,false,376784917)}),_c('CustomGridItem',{attrs:{"title":"이미지","outlined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [(!_vm.form.url)?_c('v-col',[_c('ImageUploadForm',{attrs:{"styleObj":{ height: '200px' }},on:{"setImage":function (file) { return (_vm.form.file = file); }}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-input',{attrs:{"type":"hidden","error-messages":errors},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})]}}],null,false,1966249319)})],1):_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-img',{staticClass:"mx-auto",style:({
                      maxHeight: '80px',
                      maxWidth: '80px',
                      backgroundSize: 'contain'
                    }),attrs:{"src":_vm.form.url,"alt":_vm.form.file_name || '이미지'}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"secondary","small":"","depressed":"","icon":"","outlined":""},on:{"click":function () { return (_vm.form.url = null); }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]},proxy:true}],null,false,2605842939)})],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#00d1b2","depressed":""},on:{"click":_vm.handleSaveBtnClick}},[_vm._v(" "+_vm._s(_vm.buttonTitle)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }