var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","eager":"","maxWidth":_vm.type === 'date' ? 700 : 500},model:{value:(_vm.localDialog),callback:function ($$v) {_vm.localDialog=$$v},expression:"localDialog"}},[_c('v-row',{staticClass:"did-campaign-update-dialog ma-0",staticStyle:{"min-height":"100%"}},[_c('v-card',{staticStyle:{"width":"100%"}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("캠페인 추가")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleCloseClick}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"py-3 px-0"},[_c('ValidationObserver',[(_vm.type === 'settopBox')?_c('v-row',{staticClass:"ma-0 fit",staticStyle:{"min-height":"500px","flex-direction":"column"}},[_c('v-col',{staticClass:"pb-0 pl-5",attrs:{"cols":"auto"}},[_c('h3',{staticClass:"subtitle-2 font-weight-bold"},[_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v("mdi-asterisk")]),_vm._v(" 노출 매체 그룹 ")],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","hide-no-data":"","multiple":"","clearable":"","placeholder":"광고를 노출할 매체그룹을 선택해 주세요","items":_vm.didMediaGroups,"item-text":"name","item-value":"id","dense":"","disabled":_vm.dialogLoading,"error-messages":errors,"hide-details":!errors.length},on:{"change":_vm.handleMediaGroupId},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[(
                            _vm.didCampaignService.didMediaGroupId.length ===
                            _vm.didMediaGroups.length
                          )?_c('span',[_vm._v(" 전체선택 ")]):_c('span',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.didCampaignService.didMediaGroupId.length > 1 ? (" 외 " + (_vm.didCampaignService.didMediaGroupId.length - 1) + " 개") : '')+" ")])]):_vm._e()]}},{key:"item",fn:function(ref){
                          var item = ref.item;
return [(item.did_media_group_name)?_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","small":"","color":"secondary","outlined":""}},[_vm._v(" "+_vm._s(item.did_media_group_name)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"ml-2 caption"},[_vm._v(" ("+_vm._s(item.type === 'D' ? '직매채' : '대행사')+") ")])]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleDidMediaGroupIds}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 전체선택 ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(_vm.didCampaignService.didMediaGroupId),callback:function ($$v) {_vm.$set(_vm.didCampaignService, "didMediaGroupId", $$v)},expression:"didCampaignService.didMediaGroupId"}})]}}],null,false,4060532531)})],1)],1),_c('v-col',{staticClass:"pb-0 pl-5",attrs:{"cols":"auto"}},[_c('h3',{staticClass:"subtitle-2 font-weight-bold"},[_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v("mdi-asterisk")]),_vm._v(" 노출 매체 ")],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":12}},[_c('ValidationProvider',{attrs:{"rules":{
                    required:
                      !!_vm.didCampaignService.didMediaGroupId &&
                      _vm.didCampaignService.didMediaGroupId.length > 0
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","hide-no-data":"","multiple":"","clearable":"","placeholder":"광고를 노출할 매체을 선택해 주세요","items":_vm.filteredDidBranches,"item-text":"name","item-value":"id","dense":"","disabled":_vm.dialogLoading || !_vm.didCampaignService.didMediaGroupId,"error-messages":errors,"hide-details":!errors.length},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [(index === 0)?_c('v-chip',[(
                            _vm.didCampaignService.didBranchIds.length ===
                            _vm.didBranches.length
                          )?_c('span',[_vm._v(" 전체선택 ")]):_c('span',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.didCampaignService.didBranchIds.length > 1 ? (" 외 " + (_vm.didCampaignService.didBranchIds.length - 1) + " 개") : '')+" ")])]):_vm._e()]}},{key:"item",fn:function(ref){
                          var item = ref.item;
return [(item.did_media_group_name)?_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","small":"","color":"secondary","outlined":""}},[_vm._v(" "+_vm._s(item.did_media_group_name)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleDidBranchIds}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" 전체선택 ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(_vm.didCampaignService.didBranchIds),callback:function ($$v) {_vm.$set(_vm.didCampaignService, "didBranchIds", $$v)},expression:"didCampaignService.didBranchIds"}})]}}],null,false,311429481)})],1)],1),_c('v-col',{staticClass:"row ma-0 flex-column",staticStyle:{"flex-grow":"1"},attrs:{"cols":12}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 1 }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSettopBoxCount),expression:"selectedSettopBoxCount"}],attrs:{"hidden":""},domProps:{"value":(_vm.selectedSettopBoxCount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedSettopBoxCount=$event.target.value}}})]),_c('v-col',{staticStyle:{"height":"auto"},attrs:{"cols":"auto"}},[_c('h3',{staticClass:"row ma-0 subtitle-2 font-weight-bold"},[_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v("mdi-asterisk")]),_vm._v(" 사용가능 셋톱 박스 ( 선택: "+_vm._s(_vm.selectedSettopBoxCount)+" ) "),_c('v-spacer'),_c('v-chip',{attrs:{"outlined":"","small":"","color":_vm.filteredSettopBoxes.length === _vm.selectedSettopBoxCount
                        ? '#00d1b2'
                        : 'secondary'},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.selectedAllSettopBox(); })($event)}}},[_vm._v(" 전체 "+_vm._s(_vm.filteredSettopBoxes.length === _vm.selectedSettopBoxCount ? '해제' : '선택')+" ")])],1)]),(_vm.filteredSettopBoxes.length > 0)?_c('v-col',{staticClass:"invalid_settop_box row",staticStyle:{"max-height":"100%","min-height":"100%","overflow":"auto"}},[_c('a-table',{staticClass:"pl-3 table-striped-rows",staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"data-source":_vm.filteredSettopBoxes,"size":"small","locale":{
                    emptyText: '데이터가 없습니다.'
                  },"bordered":"","rowKey":"id","row-selection":{
                    selectedRowKeys: _vm.didCampaignService.didSettopBoxIds,
                    onChange: function (selected) { return _vm.onSelectChange(selected); }
                  },"pagination":{ pageSize: 10 },"scroll":{ x: true }},scopedSlots:_vm._u([{key:"settopBoxCostPerDay",fn:function(value){return [_c('div',{staticClass:"text-right subtitle-body font-weight-600 red--text"},[_vm._v(" "+_vm._s((value * 1).toLocaleString())+" ")])]}}],null,false,2135369099)})],1):_c('v-col',{staticClass:"row align-center justify-center ma-0",staticStyle:{"border":"1px solid #ddd"}},[_c('h4',{staticClass:"font-weight-bold text-center"},[_vm._v(" 노출 매체를 선택해 주세요. ")])])],1)],1):_vm._e(),(_vm.type === 'date')?_c('v-row',{staticClass:"ma-0 fit",staticStyle:{"min-height":"500px","flex-direction":"column"}},[_c('v-col',{staticClass:"pb-0 pl-5",attrs:{"cols":"auto"}},[_c('h3',{staticClass:"subtitle-2 font-weight-bold"},[_c('v-icon',{attrs:{"color":"error","x-small":""}},[_vm._v("mdi-asterisk")]),_vm._v(" 캠페인 연장일 선택 ")],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [(_vm.datepickerLoading)?[_c('v-skeleton-loader',{staticClass:"mb-0",attrs:{"type":"list-item, card-heading, date-picker-days"}}),_c('v-skeleton-loader',{staticClass:"mb-6",attrs:{"type":"date-picker-days, actions"}})]:_vm._e(),_c('v-date-picker',{directives:[{name:"show",rawName:"v-show",value:(!_vm.datepickerLoading),expression:"!datepickerLoading"}],staticClass:"custom-date-picker",attrs:{"allowed-dates":_vm.checkAllowedDates,"min":_vm.minDate,"max":_vm.maxDate,"full-width":"","no-title":"","color":"#00d1b2","multiple":"","locale":"ko-KR"},on:{"update:picker-date":_vm.fetchDidCampaignServiceDailySchedule},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}}),(errors.length)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(errors[0]),expression:"errors[0]"}],attrs:{"type":"hidden"},domProps:{"value":(errors[0])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(errors, 0, $event.target.value)}}}):_vm._e()]}}],null,false,158367544)})],1)],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.handleCloseClick()}}},[_vm._v(" 취소 ")]),(_vm.type === 'settopBox')?_c('v-btn',{attrs:{"disabled":!_vm.selectedSettopBoxCount,"loading":_vm.dialogLoading,"color":"secondary"},on:{"click":function($event){return _vm.handleAddSettopBox(_vm.didCampaignService.didSettopBoxIds)}}},[_vm._v(" 추가 ")]):_vm._e(),(_vm.type === 'date')?_c('v-btn',{attrs:{"disabled":!_vm.selectedDateCount,"loading":_vm.dialogLoading,"color":"secondary"},on:{"click":function($event){return _vm.handleAddDate(_vm.selectedDates)}}},[_vm._v(" 연장 ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }