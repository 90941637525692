<template>
  <span>
    <v-col v-if="readText" class="py-2">
      {{ currentVal }}
    </v-col>
    <v-text-field
      v-else
      :label="label"
      v-model="currentVal"
      persistent-placeholder
      :placeholder="placeholder"
      :readonly="readonly"
      :outlined="outlined"
      :filled="filled"
      :suffix="suffix"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      :append-icon="appendIcon"
      :prefix="prefix"
      :type="type"
      :clearable="clearable"
      :dense="dense"
      :full-width="fullWidth"
      :required="required"
      :max="max"
      @input="handleUpdateInputData()"
      @focus="handleFocusInputData($event.target)"
      @blur="handleFocusOutData($event.target)"
    >
      <slot name="appen"></slot>
      <slot name="append-outer"></slot>
      <slot name="counter"></slot>
      <slot name="label"></slot>
      <slot name="message"></slot>
      <slot name="prepend"></slot>
      <slot name="prepend-inner"></slot>
      <slot name="progress"></slot>
    </v-text-field>
  </span>
</template>

<script>
export default {
  props: {
    label: {
      require: false,
      default: null
    },
    value: {
      require: false,
      default: ''
    },
    placeholder: {
      require: false,
      default: ''
    },
    readonly: {
      type: Boolean,
      require: false,
      default: false
    },
    // input 없이 문자열로
    readText: {
      type: Boolean,
      require: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      require: false,
      default: false
    },
    suffix: {
      type: String,
      require: false,
      default: ''
    },
    prefix: {
      require: false,
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      require: false,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    errorMessages: {
      require: false,
      default: []
    },
    appendIcon: {
      require: false,
      type: String,
      default: ''
    },
    type: {
      require: false,
      type: String,
      default: 'text'
    },
    focusable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    max: {
      default: null
    }
  },
  watch: {
    value(newVal) {
      this.currentVal = newVal;
    }
  },
  data() {
    return {
      currentVal: null
    };
  },
  mounted() {
    if (this.value) {
      this.currentVal = this.value;
    }
  },
  methods: {
    /** @description: 양방향 바인딩  */
    handleUpdateInputData() {
      this.$emit('input', this.currentVal);
    },
    /** @description: 포커스 아웃 바인딩  */
    handleFocusOutData() {
      this.$emit('blur');
    },
    /** @description: focus 이벤트 사용여부  */
    handleFocusInputData(target) {
      if (this.focusable) {
        target.select();
      }
    }
  }
};
</script>
<style lang="scss"></style>
