<template>
  <v-dialog :value="noticeDialog" max-width="1200" scrollable persistent eager>
    <v-card>
      <v-toolbar flat dark>
        <v-toolbar-title>공지사항 {{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5 py-3">
        <ValidationObserver ref="noticeDialogObs">
          <v-row dense>
            <v-col cols="12" lg="6">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                  v-model="notice.category"
                  :error-messages="errors"
                  :items="CategoryList"
                  label="공지 유형"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="6">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                  v-model="notice.group"
                  :error-messages="errors"
                  :items="UserRoleList"
                  label="공지 그룹"
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>

          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="notice.title"
              :error-messages="errors"
              label="공지 제목"
              required
              outlined
              clearable
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-textarea
              v-model="notice.content"
              :error-messages="errors"
              label="공지 내용"
              required
              outlined
              clearable
              rows="15"
            ></v-textarea>
          </ValidationProvider>

          <v-row no-gutters>
            <v-col cols="12" lg="2" v-if="isDialogTypeRegister">
              <div class="d-inline-flex align-center">
                <v-switch v-model="notice.activeNow" color="primary" inset />
                <span class="subtitle-1 mr-2"
                  >바로활성: {{ notice.activeNow ? 'ON' : 'OFF' }}
                </span>
              </div>
            </v-col>
            <v-col v-if="activeNow" cols="6" lg="4">
              <v-menu
                v-model="modal.startDate"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="notice.startDate"
                      prepend-inner-icon="mdi-calendar"
                      label="시작일"
                      :error-messages="errors"
                      persistent-hint
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-if="modal.startDate"
                  v-model="notice.startDate"
                  @input="modal.startDate = false"
                  :min="today"
                  locale="ko-KR"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="activeNow" cols="6" lg="4">
              <v-menu
                v-model="modal.startTime"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="notice.startTime"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      label="시작시간"
                      readonly
                      outlined
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="modal.startTime"
                  v-model="notice.startTime"
                  format="24hr"
                  :allowed-minutes="allowedMinutes"
                  full-width
                  scrollable
                >
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" lg="2">
              <div class="d-inline-flex align-center">
                <v-switch v-model="notice.issetEnd" color="primary" inset />
                <span class="subtitle-1 mr-2"
                  >종료일: {{ notice.issetEnd ? 'ON' : 'OFF' }}
                </span>
              </div>
            </v-col>
            <v-col v-if="notice.issetEnd === true" cols="6" lg="4">
              <v-menu
                v-model="modal.endDate"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="notice.endDate"
                      prepend-inner-icon="mdi-calendar"
                      label="종료일"
                      :error-messages="errors"
                      persistent-hint
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-if="modal.endDate"
                  v-model="notice.endDate"
                  @input="modal.endDate = false"
                  :min="notice.startDate == null ? today : notice.startDate"
                  locale="ko-KR"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="notice.issetEnd === true" cols="6" lg="4">
              <v-menu
                v-model="modal.endTime"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-text-field
                      v-model="notice.endTime"
                      :error-messages="errors"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      label="종료시간"
                      readonly
                      outlined
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="modal.endTime"
                  v-model="notice.endTime"
                  :allowed-minutes="allowedMinutes"
                  format="24hr"
                  full-width
                  scrollable
                >
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <div class="d-inline-flex align-center">
                <v-switch
                  v-model="notice.isUrgent"
                  color="error"
                  inset
                ></v-switch>
                <span class="subtitle-1 mr-2"
                  >긴급공지: {{ notice.isUrgent ? 'ON' : 'OFF' }}
                </span>
              </div>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn text color="primary" @click="submit()" :loading="loading">
          {{ dialogTitle }}
        </v-btn>
        <v-btn text @click="closeDialog" :disabled="loading">
          취소
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { UserRoleList } from '@/config/user';
import { CategoryList } from '@/config/notice';
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      notice: 'notice/notice',
      noticeDialog: 'notice/dialog',
      isDialogTypeRegister: 'notice/isDialogTypeRegister'
    }),
    dialogTitle() {
      return this.isDialogTypeRegister ? '등록' : '수정';
    },
    allowedMinutes() {
      return Array.from(Array(60).keys()).filter(v => v % 5 === 0);
    },
    activeNow() {
      if (this.isDialogTypeRegister) {
        return this.notice.activeNow === false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      today: this.$moment().format('YYYY-MM-DD'),
      modal: {
        startDate: false,
        startTime: false,
        endDate: false,
        endTime: false
      },
      UserRoleList: UserRoleList,
      CategoryList: CategoryList
    };
  },
  mounted() {
    this.$refs.noticeDialogObs.reset();
  },
  methods: {
    ...mapActions({
      initNotice: 'notice/init',
      closeDialog: 'notice/closeDialog'
    }),
    async submit() {
      const valid = await this.$refs.noticeDialogObs.validate();
      if (valid) {
        const { notice } = this;
        const newNotice = {
          id: notice.id,
          title: notice.title,
          content: notice.content,
          category: notice.category,
          startAt: this.$moment(
            `${notice.startDate} ${notice.startTime}`
          ).format('YYYY-MM-DD HH:mm:00'),
          endAt: notice.issetEnd
            ? this.$moment(`${notice.endDate} ${notice.endTime}`).format(
                'YYYY-MM-DD HH:mm:00'
              )
            : '9999-12-31 23:59:59',

          group: notice.group,
          isUrgent: notice.isUrgent,
          activeNow: notice.activeNow,
          issetEnd: notice.issetEnd
        };

        if (this.isDialogTypeRegister) {
          this.createApi(newNotice);
        } else {
          this.updateApi(newNotice);
        }
      }
    },
    async createApi(notice) {
      this.loading = true;
      try {
        const { status } = await this.$axios.post(`/admin/notice`, notice);
        if (status === 201) {
          this.$root.$emit('getNoticeApiEvent');
          this.closeDialog();
        }
      } catch (err) {
        this.$axiosErrorAlert();
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async updateApi(notice) {
      this.loading = true;
      try {
        const { status } = await this.$axios.put(`/admin/notice`, notice);
        if (status === 201) {
          this.$root.$emit('getNoticeApiEvent');
          this.closeDialog();
        }
      } catch (err) {
        this.$axiosErrorAlert();
        console.error(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
